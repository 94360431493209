<template>
  <Popup
    ref="popup"
    slot="default"
    :anchor="!$q.screen.gt.xs ? 'top' : undefined"
    :anchorOffsets="anchorOffsets"
    :closeOnClick="false"
    :coordinates="coordinates"
    data-t="popup"
    :shown="true"
    @close="onClose"
  >
    <div class="roboto-medium" :class="{ 'has-dashcam': asset.hasDashcam }">
      <div class="nickname q-mr-lg row zubie-font" data-t="nickname">
        <div class="col-auto self-center" :class="{ 'text-grey-7 text-italic': !nickname }">
          {{ nickname || `Unnamed ${isVehicle ? 'Vehicle' : 'Asset'}` }}
        </div>
        <SharedVehicleIndicator v-if="asset.share" :asset-key="asset.key" class="col" />
      </div>

      <div v-if="yearMakeModel" class="text-caption text-grey-7 q-mr-lg zubie-font">{{ yearMakeModel }}</div>

      <div class="location-details text-grey-9 q-mt-sm" data-t="locationDetails">
        {{ asset.statusDisplay }}
        <span v-if="!asset.isMoving && tripEndTime">
          (Here {{ parkedDuration }})
          <q-tooltip anchor="top middle" self="center middle"
            >{{ isOffline ? '' : 'Since ' }}{{ tripEndTimeFormatted }}</q-tooltip
          >
        </span>
        <span v-if="asset.isMoving && speed" data-t="speed">
          ({{ speedLocalized(speed, 'mph') }})
          <br />
        </span>
        <AddressString data-t="addressString" :latitude="latitude" :longitude="longitude" />
      </div>

      <div v-if="asset.hasDashcam" class="q-my-sm">
        <DashcamMessageButton size="11px" :vehicle-key="asset.key" />
      </div>

      <DashcamLivePreviewWithPlaceholder
        v-if="asset.hasDashcam && mayAccessDashcamMedia"
        :asset="asset"
        :imageUrl="lastTripCabinImageUrl"
        @resize="notifyMapOfPopupResize"
      />

      <div class="selected-asset-popup-buttons-group row justify-between q-mt-xs">
        <div class="row items-center q-pr-xs">
          <VehicleFollowButton v-if="canFollow" class="popup-button" short :size="11" :vehicle="asset" />
          <AssetZoomButton v-if="!canFollow && isConnected" :asset="asset" class="popup-button" short :size="11" />
          <TrackDeviceButton v-if="!isConnected" class="popup-button" short :size="11" :vehicle="asset" />
        </div>

        <div class="row items-end self-end">
          <MappedItemButton
            class="popup-button min-height-none"
            icon-right="arrow_forward"
            :icon-size="13"
            label="Overview"
            :size="11"
            :to="overview"
          />
          <MappedItemButton
            class="popup-button min-height-none"
            icon-right="arrow_forward"
            :icon-size="13"
            :label="asset.hasTripsDevice ? 'Trips' : 'Locations'"
            :size="11"
            :to="locations"
          />
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import _get from 'lodash/get';
import { mapActions, mapGetters } from 'vuex';
import AddressString from 'components/AddressString.vue';
import DashcamLivePreviewWithPlaceholder from 'components/dashcam/DashcamLivePreviewWithPlaceholder.vue';
import DashcamMessageButton from 'components/dashcam/DashcamMessageButton.vue';
import AssetZoomButton from 'components/map/AssetZoomButton.vue';
import MappedItemButton from 'components/map/MappedItemButton.vue';
import Popup from 'components/map/Popup.vue';
import VehicleFollowButton from 'components/map/VehicleFollowButton.vue';
import TrackDeviceButton from 'components/track-device/TrackDeviceButton.vue';
import SharedVehicleIndicator from 'components/vehicle/SharedVehicleIndicator.vue';
import navigateToUrl from 'src/mixins/navigateToUrl';
import Vehicle from 'src/models/Vehicle';
import { statuses, simpleStatuses } from 'src/services/constants';
import { dayjs } from 'src/services/date';
import { speedLocalized } from 'src/services/locale';

export default {
  mixins: [navigateToUrl],
  components: {
    AddressString,
    AssetZoomButton,
    DashcamLivePreviewWithPlaceholder,
    DashcamMessageButton,
    MappedItemButton,
    Popup,
    SharedVehicleIndicator,
    TrackDeviceButton,
    VehicleFollowButton,
  },
  computed: {
    ...mapGetters('assets', {
      asset: 'selectedAsset',
    }),
    ...mapGetters('session', ['mayAccessDashcamMedia']),
    ...mapGetters('trips', ['lastTripCabinImageUrl', 'lastTripRoadImageUrl', 'selectedVehicleTrip']),
    assetStatus() {
      return this.asset.status;
    },
    canFollow() {
      return this.isVehicle && [statuses.MOVING, statuses.STOPPED].includes(this.assetStatus);
    },
    coordinates() {
      return [this.longitude, this.latitude];
    },
    isConnected() {
      return this.assetStatus !== statuses.DISCONNECTED;
    },
    isMoving() {
      return this.status === simpleStatuses.MOVING;
    },
    isOffline() {
      return this.assetStatus === statuses.OFFLINE;
    },
    isVehicle() {
      return this.asset instanceof Vehicle;
    },
    key() {
      return this.asset.key;
    },
    latitude() {
      return this.asset.location.latitude;
    },
    locations() {
      return { name: this.asset.tripsRoute, params: { key: this.key } };
    },
    longitude() {
      return this.asset.location.longitude;
    },
    nickname() {
      return this.asset.nickname;
    },
    overview() {
      const name = this.isVehicle ? 'vehicle' : 'asset';
      return { name, params: { key: this.key } };
    },
    anchorOffsets() {
      return {
        bottom: {
          minTop: this.isMoving ? -24 : -54,
          maxTop: this.isMoving ? -30 : -73,
        },
        'bottom-left': {
          minTop: this.isMoving ? -18 : -46,
          maxTop: this.isMoving ? -22 : -65,
        },
        'bottom-right': {
          minTop: this.isMoving ? -18 : -46,
          maxTop: this.isMoving ? -22 : -65,
        },
        left: {
          minLeft: 15,
          maxLeft: 21,
          minTop: this.isMoving ? 0 : -30,
          maxTop: this.isMoving ? 0 : -43,
        },
        right: {
          minLeft: -17,
          maxLeft: -22,
          minTop: this.isMoving ? 0 : -30,
          maxTop: this.isMoving ? 0 : -43,
        },
        top: {
          minTop: this.isMoving ? 16 : 0,
          maxTop: this.isMoving ? 22 : 0,
        },
        'top-left': {
          minTop: this.isMoving ? 16 : 0,
          maxTop: this.isMoving ? 22 : 0,
        },
        'top-right': {
          minTop: this.isMoving ? 16 : 0,
          maxTop: this.isMoving ? 22 : 0,
        },
      };
    },
    speed() {
      return this.asset.location.speedMph;
    },
    status() {
      return this.asset.location.motionStatus;
    },
    yearMakeModel() {
      return this.asset.yearMakeModel;
    },
    tripEndTime() {
      return _get(this.asset, 'lastTrip.endPoint.timestamp') || null;
    },
    tripEndTimeFormatted() {
      return dayjs(this.tripEndTime).format('M/D/YY h:mm:ss A');
    },
    parkedDuration() {
      if (this.isOffline) {
        return dayjs().to(this.tripEndTime);
      }
      return dayjs(this.tripEndTime).toNow(true);
    },
  },
  data() {
    return {
      anchor: null,
      currentAnchor: null,
      areDashcamImagesLoaded: false,
    };
  },
  methods: {
    ...mapActions('map', ['triggerMapMove']),
    async notifyMapOfPopupResize() {
      /// wait 2 render cycles to let subcomponents render
      await this.$nextTick();
      await this.$nextTick();
      const map = _get(this.$refs, 'popup.popup._map');
      if (map) {
        // wait for the map to stop panning to the vehicle
        map.once('idle', () => {
          const { _canvas: mapCanvas } = map;
          const popupElement = _get(this.$refs, 'popup.popupElement');
          if (!popupElement) {
            return; // component removed
          }
          const { height: mapHeight, top: mapTop } = mapCanvas.getBoundingClientRect();
          const { top: popupTop } = popupElement.getBoundingClientRect();
          const margin = 4; // aligning the popup at the top of the map canvas looks weird b/c of drop shadow
          const isPopupTooNearMapTop = popupTop < mapTop;
          const distanceToPan = popupTop - (mapTop + margin);
          const minMapHeight = 420; // so everything fits
          const isMapTallEnough = mapHeight >= minMapHeight;
          if (!this.areDashcamImagesLoaded && isPopupTooNearMapTop && isMapTallEnough) {
            map.panBy([0, distanceToPan]); // distanceToPan needs to be negative
            this.areDashcamImagesLoaded = true;
          }
        });
      }
    },
    onClose(buttonClicked) {
      this.areDashcamImagesLoaded = false;
      if (buttonClicked) {
        // navigate, user clicked the close button
        this.navigateToUrl({
          name: 'map',
        });
      }
    },
    speedLocalized,
  },
  mounted() {
    this.triggerMapMove();
  },
};
</script>

<style lang="scss" scoped>
.nickname {
  font-size: 16px;
}

.location-details {
  font-size: 12px;
  line-height: 1.5;
}

.has-dashcam {
  min-width: 250px;
}

.selected-asset-popup-buttons-group {
  left: 0;
  right: 0;
  padding: 5px 0 0;
}

.popup-button {
  padding: 3px 5px;
  font-family: 'Roboto', sans-serif;
  line-height: 1;
}

:deep(.button-text) {
  padding: 2px 0;
  line-height: 1;
}
</style>
