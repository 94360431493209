import GeoJsonSource from 'src/services/geoJson/GeoJsonSource';

export default class NearbyVehiclesSource extends GeoJsonSource {
  /** @type {String} */
  static id = 'nearby-vehicles';

  /** @type {Object[]} */
  features = [];

  /**
   * @returns {Object} A GeoJson source config object.
   */
  getConfig() {
    return Object.freeze({
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        id: this.getId(),
        features: this.features,
      },
      promoteId: 'key',
    });
  }
}
