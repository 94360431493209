<template>
  <ZubieDialog
    :action-button-disable="(!useDefaultDriveScorings && scoreWeightsSum !== 100) || !this.nickname"
    :action-button-text="saveInProgress ? 'Saving' : 'Save'"
    :dirty="isDirty"
    :maximized="$q.screen.xs"
    :processing="saveInProgress"
    title="Account Preferences"
    :visible="accountSettingsDialogOpen"
    @hide="setAccountSettingsDialogState(false)"
    @submit="saveSettings"
  >
    <div v-if="error" class="bg-negative text-white q-pa-md q-mb-lg">{{ error }}</div>
    <div class="form-fields-wrapper">
      <q-input
        class="q-mt-sm"
        filled
        :hide-bottom-space="true"
        label="Name*"
        :model-value="nickname"
        :rules="[(val) => !!val || 'Account name is required']"
        @update:model-value="
          (value) => {
            nickname = value;
            isDirty = true;
          }
        "
      />
      <q-input
        class="q-mt-md"
        filled
        label="Address"
        :model-value="street"
        @update:model-value="
          (value) => {
            street = value;
            isDirty = true;
          }
        "
      />
      <q-input
        class="q-mt-md"
        filled
        label="City"
        :model-value="city"
        @update:model-value="
          (value) => {
            city = value;
            isDirty = true;
          }
        "
      />
      <q-select
        v-capacitor-native-select
        class="q-mt-md"
        display-value-sanitize
        emit-value
        filled
        label="State"
        map-options
        :model-value="state"
        optionLabel="name"
        :options="usStatesList"
        options-sanitize
        optionValue="code"
        @update:model-value="
          (value) => {
            state = value;
            isDirty = true;
          }
        "
      />
      <q-input
        class="q-mt-md"
        filled
        label="Zip"
        :model-value="zipcode"
        @update:model-value="
          (value) => {
            zipcode = value;
            isDirty = true;
          }
        "
      />
      <q-select
        v-model="ownerKey"
        class="q-mt-md"
        display-value-sanitize
        emit-value
        filled
        hint="We will send billing and service reminders to this person."
        label="Primary Contact"
        map-options
        optionLabel="label"
        :options="getAccountOwnerSelectOptions"
        options-sanitize
        optionValue="value"
        use-input
        @update:model-value="isDirty = true"
      />
      <h6 class="text-h6 zubie-font q-mt-lg">Notifications</h6>
      <div class="text-body2">
        The thresholds below apply to all account users. Users will receive email or push notifications only if they
        have enabled them in User Settings.
      </div>
      <q-item class="q-pt-lg" flat>
        <q-item-section>
          <span>High Speed Threshold ({{ velocityUnits }})</span>
        </q-item-section>
        <q-item-section>
          <q-slider
            v-model="preferences.overSpeedThreshold"
            label
            label-always
            :max="speedThresholds.max"
            :min="speedThresholds.min"
            :step="5"
            @update:model-value="isDirty = true"
          />
        </q-item-section>
      </q-item>
      <q-item class="q-pt-lg" flat>
        <q-item-section>
          <span>Low Fuel Threshold (%)</span>
        </q-item-section>
        <q-item-section>
          <q-slider
            v-model="preferences.lowFuelThreshold"
            label
            label-always
            :max="50"
            :min="1"
            :step="1"
            @update:model-value="isDirty = true"
          />
        </q-item-section>
      </q-item>
      <q-item class="q-pt-lg" flat>
        <q-item-section>
          <span>Idle Duration Threshold (seconds)</span>
        </q-item-section>
        <q-item-section>
          <q-slider
            v-model="preferences.idleDurationThreshold"
            label
            label-always
            :max="1800"
            :min="120"
            :step="10"
            @update:model-value="isDirty = true"
          />
        </q-item-section>
      </q-item>
      <h6 class="text-h6 zubie-font q-mt-md">Driver Scoring</h6>
      <div class="text-body2">
        Customize how driver scores are calculated. This applies to the dashboard, in addition to new fleet, leaderboard
        and driver scorecard reports in the Reports Library.
      </div>
      <q-item class="toggle-control-item" flat tag="label">
        <q-item-section>
          <q-item-label>Use Defaults</q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-toggle
            checked-icon="check"
            color="primary"
            :model-value="useDefaultDriveScorings"
            unchecked-icon="clear"
            @update:model-value="
              (value) => {
                useDefaultDriveScorings = value;
                isDirty = true;
              }
            "
          />
        </q-item-section>
      </q-item>
      <q-separator v-if="!useDefaultDriveScorings" class="q-mb-sm" inset />
      <div
        v-if="!useDefaultDriveScorings && scoreWeightsSum !== 100"
        class="text-body2 text-negative text-weight-bold text-center"
      >
        Score weights must add up to 100. They currently add up to {{ scoreWeightsSum }}.
      </div>
      <q-item v-if="!useDefaultDriveScorings" class="q-pt-lg" flat>
        <q-item-section>
          <span>Hard Braking</span>
        </q-item-section>
        <q-item-section>
          <q-slider
            v-model="preferences.scoreWeightHardBrake"
            label
            label-always
            :max="100"
            :min="0"
            :step="1"
            @update:model-value="isDirty = true"
          />
        </q-item-section>
      </q-item>
      <q-item v-if="!useDefaultDriveScorings" class="q-pt-lg" flat>
        <q-item-section>
          <span>Rapid Acceleration</span>
        </q-item-section>
        <q-item-section>
          <q-slider
            v-model="preferences.scoreWeightRapidAccel"
            label
            label-always
            :max="100"
            :min="0"
            :step="1"
            @update:model-value="isDirty = true"
          />
        </q-item-section>
      </q-item>
      <q-item v-if="!useDefaultDriveScorings" class="q-pt-lg" flat>
        <q-item-section>
          <span>High Speed Driving</span>
        </q-item-section>
        <q-item-section>
          <q-slider
            v-model="preferences.scoreWeightOverSpeed"
            label
            label-always
            :max="100"
            :min="0"
            :step="1"
            @update:model-value="isDirty = true"
          />
        </q-item-section>
      </q-item>
      <q-item v-if="!useDefaultDriveScorings" class="q-pt-lg" flat>
        <q-item-section>
          <span>Night Driving</span>
        </q-item-section>
        <q-item-section v-if="!useDefaultDriveScorings">
          <q-slider
            v-model="preferences.scoreWeightNightDriving"
            label
            label-always
            :max="100"
            :min="0"
            :step="1"
            @update:model-value="isDirty = true"
          />
        </q-item-section>
      </q-item>
      <q-item v-if="!useDefaultDriveScorings" class="q-pt-lg" flat>
        <q-item-section>
          <span>Idle Time</span>
        </q-item-section>
        <q-item-section>
          <q-slider
            v-model="preferences.scoreWeightIdleTime"
            label
            label-always
            :max="100"
            :min="0"
            :step="1"
            @update:model-value="isDirty = true"
          />
        </q-item-section>
      </q-item>
      <h6 class="text-h6 zubie-font q-mt-lg">Driver Check-In</h6>
      <div class="text-body2">
        Control how and who can "check-in" (create temporary driver assignments) to vehicles.
      </div>
      <div class="form-fields-wrapper">
        <q-select
          v-model="preferences.driverCheckInMode"
          v-capacitor-native-select
          class="q-mt-md text-capitalize"
          display-value-sanitize
          emit-value
          filled
          label="Check-In Mode"
          map-options
          optionLabel="label"
          :options="checkInModes"
          options-sanitize
          optionValue="value"
        >
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-item-label class="text-capitalize">{{ scope.opt.label }}</q-item-label>
                <q-item-label caption>{{ scope.opt.description }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
    </div>
  </ZubieDialog>
</template>

<script>
import _isNil from 'lodash/isNil';
import { mapState, mapGetters, mapActions } from 'vuex';
import { CHECK_IN_MODES, CHECK_IN_MODES_DESCRIPTIONS, usStatesList } from 'src/services/constants';
import { getVelocityUnits } from 'src/services/locale';
import ImperialUnitLocale from 'src/services/locale/ImperialUnitLocale';
import MetricUnitLocale from 'src/services/locale/MetricUnitLocale';

export default {
  name: 'AccountSettings',
  data() {
    return {
      accountType: '',
      nickname: '',
      street: '',
      city: '',
      state: '',
      zipcode: '',
      preferences: {
        driverCheckInMode: CHECK_IN_MODES.UNRESTRICTED,
        idleDurationThreshold: 120,
        overSpeedThreshold: 90,
        lowFuelThreshold: 0,
        scoreWeightHardBrake: 0,
        scoreWeightRapidAccel: 0,
        scoreWeightOverSpeed: 0,
        scoreWeightNightDriving: 0,
        scoreWeightIdleTime: 0,
      },
      error: false,
      usStatesList,
      ownerKey: null,
      saveInProgress: false,
      isDirty: false,
    };
  },
  computed: {
    ...mapState('app', ['accountSettingsDialogOpen']),
    ...mapState('session', ['account']),
    ...mapGetters('users', ['admins']),
    checkInModes() {
      return Object.keys(CHECK_IN_MODES_DESCRIPTIONS).map((mode) => ({
        description: CHECK_IN_MODES_DESCRIPTIONS[mode],
        label: mode.toLowerCase(),
        value: mode,
      }));
    },
    getAccountOwnerSelectOptions() {
      const options = this.admins.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.key,
      }));
      return options.sort((a, b) => (a.label > b.label ? 1 : -1));
    },
    speedThresholds() {
      if (this.velocityUnits === 'kph') {
        return {
          min: 40,
          max: 320,
        };
      }

      return {
        min: 25,
        max: 200,
      };
    },
    scoreWeightsSum() {
      return (
        this.preferences.scoreWeightHardBrake +
        this.preferences.scoreWeightRapidAccel +
        this.preferences.scoreWeightOverSpeed +
        this.preferences.scoreWeightNightDriving +
        this.preferences.scoreWeightIdleTime
      );
    },
    velocityUnits() {
      return getVelocityUnits();
    },
    useDefaultDriveScorings: {
      get() {
        return (
          _isNil(this.preferences.scoreWeightHardBrake) &&
          _isNil(this.preferences.scoreWeightRapidAccel) &&
          _isNil(this.preferences.scoreWeightOverSpeed) &&
          _isNil(this.preferences.scoreWeightNightDriving) &&
          _isNil(this.preferences.scoreWeightIdleTime)
        );
      },

      set(value) {
        this.preferences.scoreWeightHardBrake = value ? null : 0;
        this.preferences.scoreWeightRapidAccel = value ? null : 0;
        this.preferences.scoreWeightOverSpeed = value ? null : 0;
        this.preferences.scoreWeightNightDriving = value ? null : 0;
        this.preferences.scoreWeightIdleTime = value ? null : 0;
      },
    },
  },
  methods: {
    ...mapActions('app', ['setAccountSettingsDialogState']),
    ...mapActions('users', ['getUsers']),
    ...mapActions('session', ['updateAccount']),
    initializeData() {
      this.accountType = this.account.accountType;
      this.nickname = this.account.nickname;
      this.street = this.account.street;
      this.city = this.account.city;
      this.state = this.account.state;
      this.zipcode = this.account.zipcode;
      this.preferences.driverCheckInMode = this.account.preferences.driverCheckInMode;
      this.preferences.idleDurationThreshold = this.account.preferences.idleDurationThreshold;
      this.preferences.lowFuelThreshold = this.account.preferences.lowFuelThreshold;
      this.preferences.overSpeedThreshold = this.account.preferences.overSpeedThreshold;
      this.preferences.scoreWeightHardBrake = this.account.preferences.scoreWeightHardBrake;
      this.preferences.scoreWeightRapidAccel = this.account.preferences.scoreWeightRapidAccel;
      this.preferences.scoreWeightOverSpeed = this.account.preferences.scoreWeightOverSpeed;
      this.preferences.scoreWeightNightDriving = this.account.preferences.scoreWeightNightDriving;
      this.preferences.scoreWeightIdleTime = this.account.preferences.scoreWeightIdleTime;
      this.ownerKey = this.account.ownerKey;

      // Load speed threshold based on locale
      const { overSpeedThreshold } = this.account.preferences;
      this.preferences.overSpeedThreshold = Math.round(
        this.velocityUnits === 'kph' ? MetricUnitLocale.miToKm(overSpeedThreshold) : overSpeedThreshold
      );
    },
    async saveSettings() {
      const { overSpeedThreshold } = this.preferences;
      let overSpeedThresholdMph = overSpeedThreshold;

      if (this.velocityUnits === 'kph') {
        // Convert kph to mph
        overSpeedThresholdMph = ImperialUnitLocale.kmToMiles(overSpeedThreshold);
        if (overSpeedThresholdMph < 25) {
          // Lowest value results in a value lower than the minimum
          overSpeedThresholdMph = 25;
        }
      }

      const payload = {
        nickname: this.nickname,
        street: this.street,
        city: this.city,
        state: this.state,
        zipcode: this.zipcode,
        billingUserKey: this.ownerKey,
        preferences: {
          driverCheckInMode: this.preferences.driverCheckInMode,
          idleDurationThreshold: this.preferences.idleDurationThreshold || 120,
          overSpeedThreshold: overSpeedThresholdMph || 0,
          lowFuelThreshold: this.preferences.lowFuelThreshold || 0,
          scoreWeightHardBrake: this.preferences.scoreWeightHardBrake,
          scoreWeightRapidAccel: this.preferences.scoreWeightRapidAccel,
          scoreWeightOverSpeed: this.preferences.scoreWeightOverSpeed,
          scoreWeightNightDriving: this.preferences.scoreWeightNightDriving,
          scoreWeightIdleTime: this.preferences.scoreWeightIdleTime,
        },
      };

      this.saveInProgress = true;

      await this.updateAccount(payload);
      this.setAccountSettingsDialogState(false);

      this.saveInProgress = false;
    },
  },
  mounted() {
    this.getUsers();
    this.initializeData();
  },
};
</script>
