<template>
  <q-btn :class="buttonClasses" flat padding="xs sm" :size="`${size}px`" square :text-color="textColor" unelevated>
    <div class="row items-center">
      <slot name="icon" />
      <div class="button-text">{{ label }}</div>
      <q-icon v-if="iconRight" :name="iconRight" :size="`${iconSize}px`" />
    </div>
  </q-btn>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    bgColor?: string;
    iconRight?: string;
    iconSize?: number;
    label: string;
    short?: boolean;
    size: number;
    textColor?: string;
  }>(),
  {
    textColor: 'primary',
  }
);

const iconSize = props.iconSize || props.size;

const buttonClasses = computed(() => {
  const classes = <{ [key: string]: boolean }>{};

  if (props.bgColor) {
    classes[`bg-${props.bgColor}`] = true;
  }

  if (props.short) {
    classes['q-py-none'] = true;
    classes['min-height-none'] = true;
  }

  return classes;
});
</script>
