import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const tripMarkerConfig = Object.freeze({
  0: {
    anchor: 'top-left',
    offset: [9, 1],
    style: {
      top: '-6px',
      left: '-7px',
      transform: 'rotate(120deg)',
      filter: 'drop-shadow(1px 2px 1px rgba(0,0,0,0.3))',
    },
  },
  4: {
    anchor: 'top',
    offset: [-5, 13],
    style: {
      top: '-15px',
      left: '50%',
      transform: 'rotate(180deg)',
      filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.3))',
    },
  },
  1: {
    anchor: 'top-right',
    offset: [-9, 1],
    style: {
      top: '-6px',
      right: '-7px',
      transform: 'rotate(240deg)',
      filter: 'drop-shadow(-1px 2px 1px rgba(0,0,0,0.3))',
    },
  },
  2: {
    anchor: 'bottom-right',
    offset: [-7, -4],
    style: {
      bottom: '-10px',
      right: '-5px',
      transform: 'rotate(300deg)',
      filter: 'drop-shadow(-1px 2px 1px rgba(0,0,0,0.3))',
    },
  },
  5: {
    anchor: 'bottom',
    offset: [-5, -16],
    style: {
      bottom: '-19px',
      left: '50%',
      transform: 'rotate(0deg)',
      filter: 'drop-shadow(0px 2px 1px rgba(0,0,0,0.3))',
    },
  },
  3: {
    anchor: 'bottom-left',
    offset: [6, -4],
    style: {
      bottom: '-10px',
      left: '-5px',
      transform: 'rotate(60deg)',
      filter: 'drop-shadow(1px 2px 1px rgba(0,0,0,0.3))',
    },
  },
});

export function getTripMarkerDirection(timestampUtc) {
  const minutes = dayjs
    .utc(timestampUtc)
    .local()
    .get('minute');
  return minutes % 6;
}

export function getTripMarkerConfig(timestampUtc) {
  const direction = getTripMarkerDirection(timestampUtc);
  return tripMarkerConfig[direction];
}
