<template>
  <q-btn
    :color="color"
    :dense="dense"
    :disable="disable"
    :flat="flat"
    :icon="icon"
    :label="label"
    :no-caps="noCaps"
    :padding="padding"
    :size="size"
    square
    :text-color="textColor"
    :type="type"
  >
    <slot />
  </q-btn>
</template>

<script>
export default {
  name: 'NegativeButton',
  props: {
    dense: Boolean,
    disable: Boolean,
    flat: Boolean,
    icon: String,
    label: String,
    noCaps: Boolean,
    padding: String,
    size: String,
    type: String,
  },
  computed: {
    color() {
      return !this.flat ? 'negative' : undefined;
    },
    textColor() {
      return !this.flat ? 'white' : 'negative';
    },
  },
};
</script>
