<template>
  <q-btn
    color="white"
    dense
    :disable="disable"
    :loading="loading"
    :padding="disable ? 'xs md' : 'xs md xs sm'"
    text-color="primary"
    @click="$emit('click', $event)"
    ><q-icon v-if="!disable" class="q-mr-xs" name="keyboard_double_arrow_down" />{{ label }}</q-btn
  >
</template>

<script>
export default {
  name: 'LoadMoreButton',
  props: {
    disable: Boolean,
    label: String,
    loading: Boolean,
  },
};
</script>
