<template>
  <div />
</template>

<script setup lang="ts">
import type { CustomLayerInterface, GeoJSONSource, GeoJSONSourceSpecification, Map, MapMouseEvent } from 'mapbox-gl';
import { inject, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';

const props = defineProps<{
  before?: string;
  layer: CustomLayerInterface & { source?: string };
  removeSource?: boolean;
  source: GeoJSONSourceSpecification & { data: { id: string } };
}>();

const emit = defineEmits(['add', 'adding', 'mousedown']);

const map = <Map>inject('map');

function addLayer() {
  if (!props.layer.id) {
    throw new Error('Layer ID is missing!');
  }

  if (!map.getSource(props.source.data.id)) {
    map.addSource(props.source.data.id, props.source);
  }

  const beforeLayer = props.before;
  if (beforeLayer) {
    // Make sure "before" layer exists
    if (!map.getLayer(props.before)) {
      map.on('styledata', waitForBeforeLayer);
      return;
    }
    // before layer exists, proceed
  }

  if (!map.getLayer(props.layer.id)) {
    emit('adding');

    // Remove previously added listeners, just in case
    map.off('styledata', waitForLayerAdd);
    map.off('mousedown', props.layer.id, onMouseDown);

    map.addLayer(Object.assign({ ...props.layer }, { source: props.source.data.id }), props.before);
    map.on('styledata', waitForLayerAdd);
    map.on('mousedown', props.layer.id, onMouseDown);
  }
}

function onMouseDown(event: MapMouseEvent) {
  emit('mousedown', { map, event });
}

async function waitForLayerAdd() {
  if (map.getLayer(props.layer.id)) {
    // Add layer if before layer is not defined or found
    map.off('styledata', waitForLayerAdd);
    await nextTick();
    emit('add');
  }
}

async function waitForBeforeLayer() {
  if (!props.before || map.getLayer(props.before)) {
    // Add layer if before layer is not defined or found
    map.off('styledata', waitForBeforeLayer);
    await nextTick();
    addLayer();
  }
}

onMounted(() => {
  addLayer();
});

onUnmounted(() => {
  if (props.removeSource) {
    map.removeSource(props.source.data.id);
  }
  map.removeLayer(props.layer.id);

  map.off('mousedown', props.layer.id, onMouseDown);
});

watch(
  () => props.source,
  () => {
    const source = map.getSource<GeoJSONSource>(props.source.data.id);
    if (source) {
      source.setData(props.source.data);
    }
  }
);

let lastMapStyle = ref(map.style.globalId);
map.on<'style.load'>('style.load', ({ target }) => {
  const styleId = target.style.globalId;
  if (styleId && styleId !== lastMapStyle.value) {
    lastMapStyle.value = styleId;
    addLayer();
  }
});
</script>
