<template>
  <q-field
    class="cursor-pointer"
    :class="{ 'q-field--focused q-field--highlighted': menuOpen }"
    dense
    filled
    for="zubieTreeButton"
    :label="headerLabel"
    :stack-label="Boolean(selected.length || menuOpen)"
  >
    <template v-if="$slots.prepend" v-slot:prepend>
      <slot name="prepend" />
    </template>
    <template v-slot:control>
      <q-chip v-for="option in simplifiedSelections" dense removable @remove="onUnselect(option[nodeKey])">
        {{ option[labelKey] }}
        <ToolTip v-if="option.hierarchy.length" @mouseover.stop @update:model-value="hoveringSelectedChip = $event">{{
          [...option.hierarchy, option[labelKey]].join(' > ')
        }}</ToolTip>
      </q-chip>
    </template>
    <template v-if="$slots.append" v-slot:append>
      <slot name="append" />
    </template>
    <ToolTip v-if="tooltip && !hoveringSelectedChip">{{ tooltip }}</ToolTip>
    <q-menu
      ref="menu"
      v-model="menuOpen"
      fit
      @before-hide="$emit('before-hide')"
      @before-show="$emit('before-show')"
      @show="onTreeMounted"
    >
      <q-tree
        ref="tree"
        v-model:expanded="expanded"
        v-model:ticked="selected"
        :default-expand-all="defaultExpandAll"
        :label-key="labelKey"
        :node-key="nodeKey"
        :nodes="options"
        tick-strategy="leaf"
        @update:ticked="update"
      >
      </q-tree>
    </q-menu>
  </q-field>
</template>

<script setup lang="ts">
import type { QMenu, QTree, QTreeNode } from 'quasar';
import { computed, nextTick, ref, useTemplateRef, watch } from 'vue';
import ToolTip from 'components/ToolTip.vue';

const hoveringSelectedChip = ref(false);

const props = withDefaults(
  defineProps<{
    defaultExpandAll?: boolean;
    expanded?: string[];
    headerLabel: string;
    labelKey?: string;
    modelValue: string[];
    nodeKey?: string;
    options: QTreeNode[];
    tooltip?: string;
  }>(),
  {
    expanded: () => [],
  }
);

const emit = defineEmits(['before-hide', 'before-show', 'update:expanded', 'update:model-value']);

const selected = ref<string[]>([]);
function modelToTreeValues() {
  const selectedKeys = [...props.modelValue];

  const updateMatchingOptions = (options: QTreeNode[]) =>
    options.forEach(({ key, children }) => {
      if (selectedKeys.includes(key)) {
        // If option is selected, select all children
        children.forEach((child) => {
          if (!selectedKeys.includes(child.key)) {
            selectedKeys.push(child.key);
          }
        });
      }
      // Check for children of children
      updateMatchingOptions(children);
    });

  updateMatchingOptions([...props.options]);

  selected.value = selectedKeys;
}
modelToTreeValues();
watch(
  () => props.modelValue,
  () => {
    modelToTreeValues();
  }
);

const flattenedOptions = computed(() => {
  const flatOptions = [];

  const flatten = (options) => {
    options.forEach((option) => {
      flatOptions.push(option);
      if (option.children) {
        flatten(option.children);
      }
    });
  };
  flatten([...props.options]);

  return flatOptions;
});

function removeItem(array, optionKey) {
  const childIndex = array.indexOf(optionKey);
  array.splice(childIndex, 1);
}

const simplifiedSelections = computed(() => {
  // Find every option and flag it as selected or not
  const findSelectedOptions = (options, hierarchy = []) =>
    options.map((option) => {
      option.hierarchy = hierarchy;

      if (!option.children?.length) {
        // No children, set selected state
        return {
          ...option,
          selected: selected.value.includes(option[props.nodeKey]),
        };
      }

      const selectedChildren = findSelectedOptions(option.children, [...hierarchy, option[props.labelKey]]);
      const everyChildSelected = selectedChildren.every(({ selected }) => selected);
      if (everyChildSelected) {
        // Every child is selected, only select parent item and throw out results from children
        return {
          ...option,
          selected: true,
        };
      } else {
        // Not every child is selected, update parent item children with results
        return {
          ...option,
          children: selectedChildren,
        };
      }
    });

  const optionsWithSelections = findSelectedOptions([...props.options]);

  // Get flat list of selected items
  const onlySelectedOptions = [];
  const flattenOptions = (options) => {
    options.forEach((option) => {
      if (option.selected) {
        onlySelectedOptions.push(option);
      } else if (option.children) {
        flattenOptions(option.children);
      }
    });
  };
  flattenOptions(optionsWithSelections);

  return onlySelectedOptions;
});

function onUnselect(optionKey) {
  const selectedKeys = [...selected.value];

  // Easy part, remove option from selected
  removeItem(selectedKeys, optionKey);

  // Hard part, remove all of the options children
  const unselectedOption = flattenedOptions.value.find(({ key }) => key === optionKey);
  const unselectChildren = (options) => {
    options.forEach(({ key, children }) => {
      removeItem(selectedKeys, key);
      unselectChildren(children);
    });
  };
  unselectChildren(unselectedOption.children);

  selected.value = selectedKeys;

  update();
}

const menuOpen = ref(false);
const menu = useTemplateRef<QMenu>('menu');
async function update() {
  const selectedBranches = [...selected.value];

  const findSelectedParents = (options, hierarchy = []) =>
    options.map((option) => {
      option.hierarchy = hierarchy;

      if (!option.children?.length) {
        // No children, set selected state
        return {
          ...option,
          selected: selectedBranches.includes(option[props.nodeKey]),
        };
      }

      const selectedChildren = findSelectedParents(option.children, [...hierarchy, option[props.labelKey]]);
      const everyChildSelected = selectedChildren.every(({ selected }) => selected);

      return {
        ...option,
        children: selectedChildren,
        selected: everyChildSelected,
      };
    });

  const optionsWithSelections = findSelectedParents([...props.options]);

  // Get flat list of selected items and their children
  const selectedKeys = [];
  const findSelectedKeys = (options) => {
    options.forEach((option) => {
      if (option.selected) {
        selectedKeys.push(option[props.nodeKey]);
      }
      if (option.children) {
        findSelectedKeys(option.children);
      }
    });
  };
  findSelectedKeys(optionsWithSelections);

  emit('update:model-value', selectedKeys);
  await nextTick();
  menu.value.updatePosition();
}

// Custom click behavior
const treeEl = useTemplateRef<QTree>('tree');
function onTreeMounted() {
  treeEl.value.$el.querySelectorAll('.q-tree__node-header-content').forEach((node) => {
    const checkbox = node.parentNode.querySelector('.q-checkbox');
    node.parentNode.classList.add('q-hoverable');
    node.addEventListener('click', (event) => {
      event.stopPropagation();
      checkbox.click();
    });
  });
}

// @update:expanded on q-tree doesn't work, so we're doing it here
const expanded = ref(props.expanded);
watch(
  () => expanded.value,
  () => {
    emit('update:expanded', expanded.value);
  }
);
</script>

<style scoped lang="scss">
:deep(.q-field__control) {
  background: none;
}

:deep(.q-tree__node-header) {
  padding: 0;
}

:deep(.q-tree__arrow) {
  padding: 8px;
}

:deep(.q-tree__children) {
  padding-left: 28px;
}

:deep(.q-checkbox) {
  height: 40px;
}

:deep(.q-tree__node-header-content) {
  height: 40px;
  cursor: pointer;
}
</style>
