<template>
  <q-btn
    v-if="hasHiddenPlaces && !$q.platform.is.mobile"
    class="q-ml-sm"
    color="accent"
    dense
    outline
    padding="0 xs 0 sm"
    size="sm"
  >
    {{ hiddenPlacesCount }} Hidden <q-icon class="arrow" :class="{ 'rotate-180': menuShown }" name="arrow_drop_down" />
    <q-menu v-model="menuShown">
      <q-list style="min-width: 100px">
        <q-item v-for="place in hiddenPlaces" :key="place.key" clickable @click="zoomToPlace(place)">
          <q-item-section>
            <div class="row justify-between items-center no-wrap text-no-wrap">
              <div>{{ place.name }}</div>
              <q-btn
                class="q-ml-md"
                dense
                flat
                icon="visibility"
                padding="sm"
                size="sm"
                square
                @click="showPlace(place)"
              >
                <q-tooltip><div class="text-body2">Show Place</div></q-tooltip>
              </q-btn>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Bounds from 'src/models/Bounds';
import Map from 'src/services/map/Map';

export default {
  computed: {
    ...mapGetters('places', ['hasHiddenPlaces', 'hiddenPlaces']),
    hiddenPlacesCount() {
      return this.hiddenPlaces.length;
    },
  },
  data() {
    return {
      menuShown: false,
    };
  },
  methods: {
    ...mapActions('map', ['fitToBounds']),
    ...mapActions('places', ['setPlaceVisibility']),
    showPlace(place) {
      this.setPlaceVisibility({ [place.key]: true });
      this.zoomToPlace(place);
      this.menuShown = false;
    },
    zoomToPlace(place) {
      const { geometry = {} } = place;

      const coordinates = geometry?.geometry?.coordinates?.[0];

      const bounds = Map.calculateBoundsByPoints(coordinates);

      this.fitToBounds(new Bounds(bounds));
    },
  },
};
</script>

<style lang="scss" scoped>
.arrow {
  transition: transform 0.28s;
}
</style>
