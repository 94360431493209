<template>
  <LocatingSpinner v-if="!resolvedAddress" data-t="locatingSpinner" />
  <div v-else class="geocoded-address roboto-medium" data-t="address">
    <div class="geocoded-street q-mb-xs" data-t="street">{{ street }}</div>
    <div class="geocoded-city-state q-mt-xs" data-t="cityState">{{ city }}{{ statePrefix }}{{ state }}</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LocatingSpinner from 'components/map/LocatingSpinner.vue';

export default {
  props: {
    latitude: Number,
    longitude: Number,
  },
  components: { LocatingSpinner },
  data() {
    return {
      resolvedAddress: null,
    };
  },
  computed: {
    city() {
      return this.resolvedAddress.city;
    },
    key() {
      if (this.latitude && this.longitude) {
        return `${this.latitude.toFixed(5)},${this.longitude.toFixed(5)}`;
      }
      return '';
    },
    state() {
      return this.resolvedAddress.state;
    },
    statePrefix() {
      return this.resolvedAddress.statePrefix;
    },
    street() {
      return this.resolvedAddress.street;
    },
  },
  methods: {
    ...mapActions('geocoding', ['reverseGeocode']),
    async geocode() {
      if (this.latitude && this.longitude) {
        this.resolvedAddress = null;
        const address = await this.reverseGeocode({
          latitude: this.latitude,
          longitude: this.longitude,
        });
        this.resolvedAddress = address;
        this.$emit('load', this.resolvedAddress);
      }
    },
  },
  watch: {
    key() {
      this.geocode();
    },
  },
  mounted() {
    this.geocode();
  },
};
</script>

<style lang="scss" scoped>
.geocoded-city-state {
  opacity: 0.54;
}
</style>
