/**
 * Retrieves groups from the API.
 *
 * @param {ActionContext} context
 * @returns {Object[]}
 */
export async function getGroups(context) {
  if (context.state.groupsLoading === true) {
    return context.state.groups;
  }

  context.commit('setGroupsLoading', true);

  try {
    const groups = await context.rootState.app.broker.fetchAndTransform({
      fn: 'getGroups',
      params: {
        showInactive: false,
      },
      transformationFn: 'transformArray',
    });

    if (groups.error) {
      // Keep UI in loading state
      return null;
    }

    groups.forEach(setParent);

    context.commit('setGroupsLoading', false);
    context.commit('setGroups', groups);

    return groups;
  } catch {
    context.commit('setGroupsLoading', false);
    context.commit('setGroups', []);
    return null;
  }
}

/**
 * Adds a reference to the given group's parent.
 *
 * @param {Object} group
 */
function setParent(group) {
  group.children.forEach((child) => {
    child.parent = group;
    setParent(child);
  });
}

/**
 * Creates a new group via the API.
 *
 * @param {ActionContext} context
 * @returns {Object}
 */
export async function createNewGroup(context) {
  context.commit('setSavingGroup', true);

  const data = {
    name: context.state.groupBeingEdited.name,
  };

  if (context.state.groupBeingEdited.parentGroupKey) {
    data.parent_group_key = context.state.groupBeingEdited.parentGroupKey;
  }

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'createNewGroup',
    params: {
      data,
      name: context.state.groupBeingEdited.name,
    },
    transformationFn: 'camelCaseKeysDeep',
  });

  context.commit('setSavingGroup', false);

  if (response.error) {
    return null;
  }

  if (response.errors) {
    context.commit('setGroupsError', response.errors);
    return null;
  }

  context.commit('clearGroupInModification');

  context.dispatch('getGroups');

  return response;
}

/**
 * Sets the group currently being modified.
 *
 * @param {ActionContext} context
 * @param {Object} group
 */
export async function selectGroupToEdit(context, group) {
  context.commit('setGroupInModification', group);
}

/**
 * Clears the group currently being modified and clears groups errors.
 *
 * @param {ActionContext} context
 */
export async function resetEditingState(context) {
  context.commit('clearGroupInModification');
  context.commit('setGroupsError', false);
}

export async function updateGroup(context, key) {
  context.commit('setSavingGroup', true);

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'updateGroup',
    params: {
      key,
      name: context.state.groupBeingEdited.name,
    },
    transformationFn: 'camelCaseKeysDeep',
  });

  context.commit('setSavingGroup', false);

  if (response.error) {
    return null;
  }

  if (response.errors) {
    context.commit('setGroupsError', response.errors);
    return null;
  }

  context.commit('clearGroupInModification');

  context.dispatch('getGroups');

  return response;
}

/**
 * Deletes the given group via the API.
 *
 * @param {ActionContext} context
 * @param {String} key
 * @returns {Object}
 */
export async function deleteGroup(context, key) {
  context.commit('setSavingGroup', true);

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'deleteGroup',
    params: {
      key,
      name: context.getters.getGroupByKey(key).name,
    },
    transformationFn: 'camelCaseKeysDeep',
  });

  context.commit('setSavingGroup', false);

  context.dispatch('getGroups');

  return response;
}

/**
 * Bulk applies a change to a set of members' groups.
 *
 * @param {ActionContext} context
 * @param {Object} payload
 * @param {String[]} payload.groupKeys
 * @param {String[]} payload.memberKeys
 * @param {String} payload.action
 * @returns {Object}
 */
export async function bulkApplyGroups(context, { action, groupKeys, memberKeys }) {
  return context.rootState.app.broker.bulkApplyGroups({
    action,
    groupKeys,
    memberKeys,
  });
}
