<template>
  <component :is="inlineIcon[icon]" v-if="icon.startsWith('component:')" :height="sizeToPx" :width="sizeToPx" />
  <q-icon v-else :name="icon" :size="size" />
</template>

<script>
import GolfCartIcon from 'src/assets/golf-cart-icon.svg?component';

export default {
  name: 'AssetTypeIcon',
  props: {
    icon: String,
    size: {
      type: [String, Number],
      default: 'md',
    },
  },
  computed: {
    inlineIcon() {
      return {
        'component:golf_cart': GolfCartIcon,
      };
    },
    sizeToPx() {
      switch (this.size) {
        case 'md':
          return '32px';
        default:
          return this.size;
      }
    },
  },
};
</script>
