import storage from 'src/services/storage';

export default () => {
  storage.onReady((store) => {
    const estimatedSubCount = storage.get('subscriptions/estimatedSubCount');
    if (estimatedSubCount) {
      store.commit('subscriptions/setEstimatedSubCount', parseInt(estimatedSubCount, 10));
    }
  });

  return {
    estimatedSubCount: 1,
    /** @type {SubscriptionData[]} */
    subscriptions: [],
    subscriptionsLoaded: false,
    /** @type {string | null} */
    subUpdateError: null,
    /** @type {SubscriptionPlan[]} */
    plans: [],
    subscriptionsLoading: false,
    plansLoading: false,
  };
};
