import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  ASSET_TYPES,
  GL300_REGEX,
  GL500_REGEX,
  LOW_BATTERY_THRESHOLD,
  simpleStatuses,
  statuses,
} from 'src/services/constants';
import { dayjs } from 'src/services/date';
import errorService from 'src/services/error';

const UNKNOWN_STATUS = 'Vehicle has an unknown status.';

const YYYYMMFormat = /^\d{4}-\d{2}(?:-\d{2})?$/;

/**
 * Returns the "heading" if the vehicle is moving.
 *
 * @returns {Number|null}
 */
export function getHeading({ heading, motionStatus, speedMph }) {
  return motionStatus === simpleStatuses.MOVING && speedMph > 0 ? heading : null;
}

// this is used for assets, too
export function getStatus() {
  const { batteryVolts, devices = [], isConnected, key, lastTrip = {}, location = {}, majorType, sensorData } = this;
  const { motionStatus, timestamp } = location || {};
  const lastTripStart = _get(lastTrip, 'startPoint.timestamp');
  const hasTripAfterLastLocation = lastTripStart && dayjs(lastTripStart).isAfter(dayjs(timestamp));

  // assets get ASSET_OK
  if (sensorData || majorType === ASSET_TYPES.ASSET) {
    return statuses.ASSET_OK;
  }

  if (devices.length === 0) {
    return statuses.NO_DEVICE;
  }

  const hasObsolete = devices.every(({ serial }) => GL300_REGEX.test(serial) || GL500_REGEX.test(serial));
  if (hasObsolete) {
    return statuses.OBSOLETE;
  }

  if (!isConnected) {
    return statuses.DISCONNECTED;
  }

  const hasOnlyOffline = devices.every((device) => device.isOffline); // device last trans > 24h old
  if (hasOnlyOffline) {
    if (batteryVolts && batteryVolts <= LOW_BATTERY_THRESHOLD + 0.2) {
      return statuses.POWERED_DOWN;
    }
    return statuses.OFFLINE;
  }

  if (_isEmpty(location)) {
    return statuses.NO_GPS;
  }

  if (motionStatus === 'stale' && hasTripAfterLastLocation) {
    return statuses.NO_GPS;
  }

  switch (motionStatus) {
    case 'moving':
      return statuses.MOVING;
    case 'stopped':
    case 'stale':
      return statuses.STOPPED;
    default:
      errorService.log(new Error(UNKNOWN_STATUS), {
        metaData: {
          vehicle: {
            key,
            devices,
            location,
          },
        },
      });
      return statuses.UNKNOWN;
  }
}

/**
 * Returns whether the current driver is the result of an active vehicle checkin
 *
 * @return {Boolean}
 */
export function hasDriverCheckin(driver) {
  const { checkInExpiration } = driver || {};

  // driver is default, not a checkin
  if (!checkInExpiration) {
    return false;
  }

  // is the checkin still valid between API requests
  return dayjs(checkInExpiration).isAfter(dayjs());
}

/**
 * Validates the given expiration date.
 * - it's in YYYY-MM
 * - it's at list the current month or beyond
 *
 * @param {string} plateExpiration
 */
export function validateLicensePlateExpiration(plateExpiration) {
  if (YYYYMMFormat.test(plateExpiration) === false) {
    return {
      isValid: false,
      error: 'Please enter the expiration date in YYYY-MM format.',
    };
  }

  const expiration = dayjs(plateExpiration, 'YYYY-MM');
  if (expiration.isBefore(dayjs().startOf('month'))) {
    return {
      isValid: false,
      error: 'The expiration date must be a date in the future.',
    };
  }

  return {
    isValid: true,
    expiration,
  };
}
