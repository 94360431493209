/**
 * Updates the auto-renew status of the given subscription (by id).
 *
 * @param {SubscriptionsStoreActionContext} context
 * @param {Object} payload
 * @param {String} payload.id
 * @param {Boolean} payload.autoRenew
 */
export async function changeAutoRenew(context, { id, autoRenew }) {
  const subscription = await context.rootState.app.broker.fetchAndTransform({
    fn: 'updateSubscription',
    params: {
      id,
      changes: {
        auto_renew: autoRenew,
      },
    },
  });

  if (!subscription.error) {
    context.commit('updateSubscriptionById', { id, subscription });
  }
}

/**
 * Updates the plan associated with the given subscription.
 *
 * @param {SubscriptionsStoreActionContext} context
 * @param {Object} payload
 * @param {String} payload.id
 * @param {String} payload.sku
 * @param {String} payload.timeframe
 */
export async function changePlan(context, { id, sku, timeframe }) {
  let success;

  context.commit('setSubUpdateError', null);

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'updateSubscription',
    params: {
      id,
      changes: {
        sku,
        timeframe,
      },
    },
  });

  if (response.errors || response.error) {
    context.commit('setSubUpdateError', response.errors || response.error);
    success = false;
  } else {
    context.commit('updateSubscriptionById', { id, subscription: response });
    success = true;
  }

  return success;
}

/**
 * Clears the subscription update error.
 *
 * @param {SubscriptionsStoreActionContext} context
 */
export function clearSubError(context) {
  context.commit('setSubUpdateError', null);
}

/**
 * Retrieves subscriptions from the Data Broker.
 *
 * @param {SubscriptionsStoreActionContext} context
 */
export async function getSubscriptions(context) {
  if (context.state.subscriptionsLoading === true) {
    return;
  }

  context.commit('setLoading', true);

  const subscriptions = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getSubscriptions',
    params: {
      showInactive: true,
    },
    transformationFn: 'transformArray',
  });

  context.commit('setLoading', false);

  if (!subscriptions.error) {
    context.commit('setSubscriptions', subscriptions);
    context.commit('subscriptionsLoaded');
  }
}

/**
 * Retrieves subscription plans from the Data Broker.
 *
 * @param {SubscriptionsStoreActionContext} context
 */
export async function getSubscriptionPlans(context) {
  if (context.state.plansLoading === true) {
    return;
  }

  context.commit('setPlansLoading', true);

  const plans = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getSubscriptionPlans',
    params: {
      showInactive: true,
    },
    transformationFn: 'transformArray',
  });

  context.commit('setPlansLoading', false);

  if (!plans.error) {
    context.commit('setPlans', plans);
  }
}
