import SimpleModel from './SimpleModel';

class Account extends SimpleModel {
  /** @type {string} */
  id = '';

  /** @type {string} */
  key = '';

  /** @type {boolean} */
  locked = false;

  /** @type {string} */
  nickname = '';

  /** @type {Partner} */
  partner = {};

  /** @type {string} */
  partnerKey = '';

  /** @type {Object} */
  partnerTier = {};

  /** @type {Object} */
  preferences = {};

  /** @type {"Active" | "Inactive" | "Closed"} */
  status = 'Active';

  /** @type {AccountData["subscription"] | null} */
  subscription = null;

  constructor(data = {}) {
    super();
    const preparedData = {
      ...data,
    };
    this.insert(preparedData);
  }
}

export default Account;
