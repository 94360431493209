import _intersectionBy from 'lodash/intersectionBy';
import { SEARCH_TYPES } from 'src/services/constants';
import { trackEvent } from 'src/services/intercom';
import { getSearchContext } from 'src/services/router';
import { sortResults } from 'src/services/search';
import SearchResultTransformer from 'src/transformers/SearchResultTransformer';

/**
 * @param {SearchStoreState} state
 * @param {SearchStoreGetters} getters
 */
export function additionalOptions(state, getters) {
  /** @type {SearchItem[]} */
  const moreOptions = [];

  if (getters.noResults && state.searchedTerms.length > 0) {
    moreOptions.push({
      description: 'Search a location on the map.',
      icon: 'explore',
      label: 'Search by address / location',
      type: 'address',
      onClick() {
        trackEvent('univ_search_address_clicked');
      },
    });
  }

  return moreOptions;
}

/**
 * @param {SearchStoreState} state
 * @param {SearchStoreGetters} getters
 * @returns {SearchItem[]}
 */
export function allOptionsIndexed(_, getters) {
  return [
    ...getters.currentlyViewing,
    ...getters.recentOptionsWithoutCurrent,
    ...getters.uniqueOptions,
    ...getters.additionalOptions,
  ];
}

/**
 * @param {SearchStoreState} state
 * @param {SearchStoreGetters} getters
 */
export function allResultOptions(_, getters) {
  return [...getters.recentOptions, ...getters.nonRecentOptions];
}

/**
 * @param {SearchStoreState} state
 * @param {SearchStoreGetters} getters
 */
export function currentlyViewing(_, getters) {
  return getters.allResultOptions.filter(({ isCurrentRoute }) => isCurrentRoute);
}

/**
 * @param {SearchStoreState} state
 * @param {SearchStoreGetters} getters
 */
export function nonRecentOptions(state, getters) {
  if (state.isSearching) {
    return [];
  }

  const recentOptionKeys = getters.recentOptions.map(({ key }) => key);
  return getters.options.filter((option) => !recentOptionKeys.includes(option.key));
}

/**
 * @param {SearchStoreState} state
 * @param {SearchStoreGetters} getters
 */
export function noResults(state, getters) {
  return !getters.allResultOptions.length && !state.isSearching;
}

/**
 * @param {SearchStoreState} state
 */
export function options(state) {
  return sortResults(state.searchItems);
}

/**
 * Returns a processed version of filterTerms for use in table filtering
 *
 * @param {SearchStoreState} state
 */
export function processedFilterTerms(state) {
  const { filterTerms = '' } = state;
  return filterTerms
    .toLowerCase()
    .split(',')
    .map((term) => term.trim())
    .filter((term) => term.length);
}

/**
 * @param {SearchStoreState} state
 */
export function recentOptions(state) {
  let results = state.history.map((result) => SearchResultTransformer.transform(result));

  // Only show relevant recent results if they exist in the search results
  if (state.searchedTerms.length) {
    results = _intersectionBy(results, state.searchItems, 'key');
  }

  const limit = (value) => [...value].slice(0, 3);
  const sort = (value) => sortResults(value, state.priority);

  const searchContext = getSearchContext();

  if (searchContext?.join('') !== 'live-map') {
    /**
     * Sort results first if contextual search applies (excluding "live-map" context)
     *
     * Example (if "vehicle" context):
     * [place, user, vehicle, vehicle, vehicle]
     * -> [vehicle, vehicle, vehicle, place, user]
     * -> [vehicle, vehicle, vehicle]
     */
    results = sort(results);
    results = limit(results);
  } else {
    /**
     * Limit results first if not contextual
     *
     * [place, user, vehicle, vehicle, vehicle]
     * -> [place, user, vehicle]
     * -> [vehicle, place, user]
     */
    results = limit(results);
    results = sort(results);
  }

  return results;
}

/**
 * @param {SearchStoreState} state
 * @param {SearchStoreGetters} getters
 */
export function recentOptionsWithoutCurrent(_, getters) {
  return getters.recentOptions.filter(({ isCurrentRoute }) => !isCurrentRoute);
}

/**
 * Returns the search type (that also considers keyword-only routes).
 *
 * @param {SearchStoreState} state
 * @param {SearchStoreGetters} _
 * @param {RootStoreState} rootState
 */
export function searchType(state, _, rootState) {
  const { isFilterRoute } = rootState.app;
  return !isFilterRoute ? SEARCH_TYPES.KEYWORD : state.searchType;
}

/**
 * Returns the selected result.
 *
 * @param {SearchStoreState} state
 * @param {SearchStoreGetters} getters
 */
export function selectedResult(state, getters) {
  return getters.allOptionsIndexed[state.activeIndex] || null;
}

/**
 * @param {SearchStoreState} state
 * @param {SearchStoreGetters} getters
 */
export function uniqueOptions(_, getters) {
  return getters.nonRecentOptions.filter(({ isCurrentRoute }) => !isCurrentRoute);
}
