import _get from 'lodash/get';
import _nth from 'lodash/nth';
import { colors } from 'quasar';

// help webpack tree shaking: https://quasar.dev/quasar-utils/color-utils#helping-tree-shake
const { getPaletteColor } = colors;

const primary = getPaletteColor('primary');
const secondary = getPaletteColor('secondary');
const info = getPaletteColor('info');
// const positive = getPaletteColor('positive');
const negative = getPaletteColor('negative');
const warning = getPaletteColor('warning');
const light = getPaletteColor('white');
const dark = getPaletteColor('dark');

export const speedingMajorColor = negative;
export const speedingMinorColor = info;
export const hardBrakeColor = warning;
export const rapidAccelColor = dark;
export const defaultColor = primary;
export const distractedDrivingColor = '#f3d34a';

const tripLineWidthExpression = [
  'interpolate',
  ['exponential', 1.5],
  ['zoom'],
  3,
  ['case', ['==', ['feature-state', 'isHidden'], true], 1.8, ['==', ['feature-state', 'isHidden'], false], 2.4, 1.8],
  18,
  ['case', ['==', ['feature-state', 'isHidden'], true], 14, ['==', ['feature-state', 'isHidden'], false], 24, 14],
];
const tripLineOpacityExpression = ['case', ['to-boolean', ['feature-state', 'isHidden']], 0.33, 1];

export const zoomTripPointVisibilityThreshold = 12;
export const zoomSpeedingPointVisibilityThreshold = 11;

export const speedingMajor = Object.freeze({
  id: 'speeding-major',
  type: 'line',
  layout: {
    'line-cap': 'round',
  },
  paint: {
    'line-color': speedingMajorColor,
    'line-width': tripLineWidthExpression,
    'line-opacity': tripLineOpacityExpression,
  },
});

export const speedingMinor = Object.freeze({
  id: 'speeding-minor',
  type: 'line',
  layout: {
    'line-cap': 'round',
  },
  paint: {
    'line-color': speedingMinorColor,
    'line-width': tripLineWidthExpression,
    'line-opacity': tripLineOpacityExpression,
  },
});

export const tripPoints = Object.freeze({
  id: 'trip-points',
  filter: ['!', ['to-boolean', ['get', 'icon']]],
  type: 'circle',
  paint: {
    'circle-opacity': [
      'interpolate',
      ['exponential', 1.5],
      ['zoom'],
      zoomSpeedingPointVisibilityThreshold - 0.5,
      0,
      zoomSpeedingPointVisibilityThreshold,
      [
        'case',
        [
          'all',
          ['!', ['to-boolean', ['feature-state', 'isHidden']]],
          ['any', ['to-boolean', ['get', 'isAccelOrBrake']], ['to-boolean', ['get', 'isSpeeding']]],
        ],
        1,
        [
          'all',
          ['to-boolean', ['feature-state', 'isHidden']],
          ['any', ['to-boolean', ['get', 'isAccelOrBrake']], ['to-boolean', ['get', 'isSpeeding']]],
        ],
        0.33,
        0,
      ],
      zoomTripPointVisibilityThreshold,
      ['case', ['!', ['to-boolean', ['feature-state', 'isHidden']]], 1, 0.4],
    ],
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 14, 2, 16, 8],
    'circle-color': [
      'case',
      ['to-boolean', ['get', 'isRapidAccel']],
      rapidAccelColor,
      ['to-boolean', ['get', 'isHardBrake']],
      hardBrakeColor,
      ['to-boolean', ['get', 'isMajorSpeeding']],
      speedingMajorColor,
      ['to-boolean', ['get', 'isMinorSpeeding']],
      speedingMinorColor,
      defaultColor,
    ],
    'circle-stroke-color': ['case', ['to-boolean', ['get', 'isHardBrake']], dark, light],
    'circle-stroke-width': [
      'interpolate',
      ['exponential', 1.5],
      ['zoom'],
      14,
      0,
      15,
      ['case', ['!', ['to-boolean', ['feature-state', 'isHidden']]], 1, 0],
      17,
      ['case', ['!', ['to-boolean', ['feature-state', 'isHidden']]], 2, 0],
    ],
  },
});

export const events = Object.freeze({
  id: 'events',
  filter: ['to-boolean', ['get', 'icon']],
  type: 'symbol',
  layout: {
    'icon-allow-overlap': true,
    'icon-image': '{icon}',
    'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.1, 20, 0.6],
  },
  paint: {
    'icon-opacity': ['case', ['to-boolean', ['feature-state', 'isHidden']], 0.33, 1],
  },
});

export const tripDirectionArrows = Object.freeze({
  id: 'trip-direction-arrows',
  type: 'symbol',
  layout: {
    'symbol-placement': 'line',
    'symbol-spacing': 50,
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-image': 'trip-direction-arrow',
    'icon-size': 1,
  },
  paint: {
    'icon-opacity': 1,
  },
});

export const tripLine = Object.freeze({
  id: 'trip-line',
  type: 'line',
  layout: {
    'line-cap': 'round',
  },
  paint: {
    'line-color': primary,
    'line-width': tripLineWidthExpression,
    'line-opacity': tripLineOpacityExpression,
  },
});

export const tripLineInProgress = Object.freeze({
  id: 'trip-line-in-progress',
  type: 'line',
  layout: {
    'line-cap': 'round',
  },
  paint: {
    'line-color': primary,
    'line-width': tripLineWidthExpression,
    'line-opacity': tripLineOpacityExpression,
  },
});

export const stopsCircle = Object.freeze({
  id: 'stops-circle',
  type: 'circle',
  paint: {
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 12, 10, 20, 30],
    'circle-color': ['case', ['to-boolean', ['get', 'color']], ['get', 'color'], light],
    'circle-opacity': ['case', ['to-boolean', ['feature-state', 'isHidden']], 0.33, 1],
    'circle-stroke-color': ['case', ['to-boolean', ['get', 'strokeColor']], ['get', 'strokeColor'], secondary],
    'circle-stroke-width': 1,
  },
});

export const stopsLabel = Object.freeze({
  id: 'stops-label',
  type: 'symbol',
  layout: {
    'text-allow-overlap': true,
    'text-field': '{label}',
    'text-size': 14,
  },
  paint: {
    'text-color': ['case', ['to-boolean', ['get', 'textColor']], ['get', 'textColor'], dark],
    'text-opacity': ['case', ['to-boolean', ['feature-state', 'isHidden']], 0.33, 1],
  },
});

export const locationHistoryPoints = Object.freeze({
  id: 'location-history-points',
  type: 'circle',
  paint: {
    'circle-opacity': ['case', ['to-boolean', ['feature-state', 'isHidden']], 0.33, 1],
    'circle-radius': [
      'interpolate',
      ['exponential', 1.5],
      ['zoom'],
      3,
      ['case', ['to-boolean', ['feature-state', 'isFocused']], 7, 4],
      18,
      ['case', ['to-boolean', ['feature-state', 'isFocused']], 26, 16],
    ],
    'circle-color': defaultColor,
    'circle-stroke-color': 'white',
    'circle-stroke-width': ['interpolate', ['exponential', 1.5], ['zoom'], 6, 0, 7, 1, 17, 3],
  },
});

export function getPopupOffset({ layer = 'trip-points', zoom = 20 }) {
  // mapbox exponential function if we want to go back to that
  // const MAX_ZOOM = 20;
  const SVG_SIZE = 63; // the SVGs are 75px but have a 6px transparent margin all around to fit the video icon
  const layers = { events, 'stops-circle': stopsCircle, 'trip-points': tripPoints };
  const paths = {
    events: ['layout', 'icon-size'],
    'stops-circle': ['paint', 'circle-radius'], // -1, -3
    'trip-points': ['paint', 'circle-radius'],
  };
  const multipliers = {
    events: SVG_SIZE / 2, // SVG size in px
    'stops-circle': 1, // this is already the radius of the circle
    'trip-points': 1, // this is already the radius of the circle
  };
  const style = _get(layers[layer], paths[layer]);
  const maxSize = _nth(style, -1);
  const maxSizeZoom = _nth(style, -2);
  const minSize = _nth(style, -3);
  const minSizeZoom = _nth(style, -4);
  let size;
  if (zoom <= minSizeZoom) {
    size = minSize;
  } else if (zoom >= maxSizeZoom) {
    size = maxSize;
  } else if (zoom > minSizeZoom && zoom <= maxSizeZoom) {
    const zoomDelta = maxSizeZoom - minSizeZoom;
    const sizeDelta = maxSize - minSize;
    const zoomRatio = (zoom - minSizeZoom) / zoomDelta;
    size = zoomRatio * sizeDelta + minSize;
  }
  size *= multipliers[layer];
  return {
    top: [0, size],
    'top-left': [size, size],
    'top-right': [-size, size],
    bottom: [0, -size],
    'bottom-left': [size, -size],
    'bottom-right': [-size, -size],
    left: [size, 0],
    right: [-size, 0],
  };
}
