export async function hasVideoDevice() {
  // Check if the browser can use the mediaDevices API
  if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
    try {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      return mediaDevices.some(({ kind }) => kind === 'videoinput');
    } catch {
      // do nothing
    }
  }
  return false;
}
