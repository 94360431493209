<template>
  <div>
    <GroupChip
      v-if="prioritizedGroup"
      class="q-mr-xs q-mb-xs"
      :dense="dense"
      :group="prioritizedGroup"
      :no-copy="noCopy"
      @remove="$emit('remove', $event)"
    />
    <q-btn
      v-if="remainingGroups.length"
      class="text-capitalize"
      :class="{ 'q-ml-xs': remainingGroups.length > 0 }"
      dense
      flat
      :label="remainingGroupsLabel"
      @click.stop="() => {}"
    >
      <q-tooltip v-if="!isMenuOpen">{{ remainingGroupsNames }}</q-tooltip>
      <q-menu anchor="bottom middle" fit self="top middle" @hide="isMenuOpen = false" @show="isMenuOpen = true">
        <div class="q-pa-sm" style="max-width: 400px">
          <GroupChip
            v-for="(group, index) in remainingGroups"
            :key="group.key"
            :class="{ 'q-mr-xs q-mb-xs': index < remainingGroups.length - 1 }"
            :dense="dense"
            :group="group"
          />
        </div>
      </q-menu>
    </q-btn>
  </div>
</template>

<script>
import GroupChip from 'components/chips/GroupChip.vue';

export default {
  name: 'CondensedGroupList',
  components: {
    GroupChip,
  },
  props: {
    dense: Boolean,
    filter: {
      type: String,
    },
    groups: {
      required: true,
      type: Array,
    },
    noCopy: Boolean,
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  computed: {
    prioritizedGroup() {
      const firstGroup = this.groups.length > 0 ? this.groups[0] : null;
      if (this.filter) {
        const query = this.filter.toLowerCase();
        const match = this.groups.find((group) => (group.name || '').toLowerCase().includes(query));
        return match || firstGroup;
      }
      return firstGroup;
    },
    remainingGroups() {
      return this.prioritizedGroup ? this.groups.filter((group) => group.key !== this.prioritizedGroup.key) : [];
    },
    remainingGroupsLabel() {
      return `${this.remainingGroups.length} more`;
    },
    remainingGroupsNames() {
      return this.remainingGroups.map((group) => group.name).join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
.q-btn {
  color: rgba(0, 0, 0, 0.54);
}
</style>
