export default () => ({
  points: {},
  /** @type {string | null} */
  selectedFeedTripKey: null,
  /** @type {TripData | null} */
  selectedTripKey: null,
  /** @type {TripData} */
  selectedVehicleTrip: {},
  /** @type {{ [key: string]: TripData }} */
  trips: {},
  tripsLoading: false,
  tripsPromise: null,
  viewedVehicleTripKey: null,
  viewedVehicleTrip: {},
});
