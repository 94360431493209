import polyline from '@mapbox/polyline';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import _first from 'lodash/first';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _kebabCase from 'lodash/kebabCase';
import _startCase from 'lodash/startCase';
import DistractedDrivingCellphoneSvg from 'src/assets/trip-distracted-driving-cellphone.svg?raw';
import DistractedDrivingDrinkingSvg from 'src/assets/trip-distracted-driving-drinking.svg?raw';
import DistractedDrivingEatingSvg from 'src/assets/trip-distracted-driving-eating.svg?raw';
import DistractedDrivingSmokingSvg from 'src/assets/trip-distracted-driving-smoking.svg?raw';
import DistractedDrivingTiredSvg from 'src/assets/trip-distracted-driving-tired.svg?raw';
// import DistractedDrivingSvg from 'src/assets/trip-distracted-driving.svg?raw';
import GeofenceEnterGeofenceExitSvg from 'src/assets/trip-geofence-enter-exit.svg?raw';
import GeofenceEnterSvg from 'src/assets/trip-geofence-enter.svg?raw';
import GeofenceExitSvg from 'src/assets/trip-geofence-exit.svg?raw';
import HardBrakeSvg from 'src/assets/trip-hard-brake.svg?raw';
import IdleSvg from 'src/assets/trip-idle.svg?raw';
import PointWithVideoSvg from 'src/assets/trip-point.svg?raw';
import RapidAccelSvg from 'src/assets/trip-rapid-accel.svg?raw';
import VideoSvg from 'src/assets/trip-video.svg?raw';
import { ALPHABET } from 'src/services/constants';
import {
  speedingMajorColor,
  speedingMinorColor,
  hardBrakeColor,
  rapidAccelColor,
  defaultColor,
  distractedDrivingColor,
} from 'src/services/geoJson/layers/TripsMapLayers';
import { milesToKm } from 'src/services/locale';
import SvgMarker from 'src/services/svg/SvgMarker';
import { addVideoIconToTripSvg } from 'src/services/svg/tools';
import store from 'src/store';

dayjs.extend(utc);

export function getNextAvailableDate(selectedDate, disabledDates, goForward = true) {
  const today = dayjs().hour(23).minute(59).second(59);
  const direction = goForward ? 'add' : 'subtract';
  let date = dayjs(selectedDate)[direction](1, 'day');
  while (isDateAllowed(date.format('YYYY/MM/DD'), disabledDates) === false) {
    date = dayjs(date[direction](1, 'day'));
    if (date.isAfter(today)) {
      return null;
    }
  }
  return date;
}

export function isDateAllowed(date, disabledDates) {
  const isNotFuture = date <= dayjs().format('YYYY/MM/DD');
  const hasNoTrips = Object.prototype.hasOwnProperty.call(disabledDates, date) && disabledDates[date] === 0;
  return isNotFuture === true && hasNoTrips === false;
}

/**
 * @param {string} key
 * @returns {TripData}
 */
export async function fetchTrip(key) {
  if (!key) {
    return null;
  }
  return store().state.app.broker.fetchAndTransform({
    fn: 'getTrip',
    params: {
      key,
    },
  });
}

/**
 * @param {string} key
 * @param {[string, string] | null} dateRange
 * @param {number} size
 * @param {boolean} isAsset
 * @returns {Promise<Trip[]>}
 */
export async function fetchTrips(key, dateRange = null, size = 200, isAsset = false) {
  const keyName = isAsset ? 'asset_key' : 'vehicle_key';

  const params = {
    [keyName]: key,
    size,
    onlyOne: size === 1,
  };

  if (dateRange) {
    const [from, to] = dateRange;
    const start = dayjs(from).hour(0).minute(0).second(0).format();
    const end = dayjs(to).hour(23).minute(59).second(59).format();
    params.started_after = start;
    params.started_before = end;
  }

  return store().state.app.broker.fetchAndTransform({
    fn: 'getTrips',
    params,
    transformationFn: 'transformArray',
  });
}

/**
 * @param {string} key
 * @param {boolean} isAsset
 * @returns {Trip | null}
 */
export async function fetchLastTrip(key, isAsset = false) {
  const [lastTrip] = await fetchTrips(key, null, 1, isAsset);
  return lastTrip || null;
}

/**
 * Retrieves trip points.
 *
 * @param {Object} payload
 * @param {string} payload.key
 * @param {number} payload.level
 * @param {number} payload.size
 * @returns {Promise<TripPoint[]>}
 */
export async function fetchPoints({ key, level = 10, size = 1000 }) {
  return store().state.app.broker.fetchAndTransform({
    fn: 'getTripPoints',
    params: {
      key,
      params: { level, size },
    },
    transformationFn: 'transformArray',
  });
}

export async function fetchTripTally(date, vehicleKey) {
  const params = {
    month: date,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    vehicle_key: vehicleKey,
  };
  return store().state.app.apiWorker.getTripTally(params);
}

export function isTripInProgress(trip) {
  const hasDuration = Number.isFinite(trip.durationSeconds);
  if (hasDuration) {
    return false;
  }
  const hasEndPoint = Boolean(trip.endPoint);
  if (hasEndPoint) {
    return false;
  }
  if (_get(trip, 'startPoint.timestampUtc')) {
    const startTimestampUtc = trip.startPoint.timestampUtc;
    const start = dayjs.utc(startTimestampUtc).local();
    const now = dayjs();
    const isRecent = start.add(1, 'day').isAfter(now);
    return isRecent;
  }
  return false;
}

export function getTripStopLabel(index) {
  if (index >= 0) {
    const alphabetLoops = Math.floor(index / ALPHABET.length) - 1;
    const mod = index % ALPHABET.length;
    const letters = ALPHABET.split('');
    const firstChar = alphabetLoops >= 0 ? letters[alphabetLoops] : '';
    const label = `${firstChar}${letters[mod]}`;
    return label;
  }
  return '';
}

export function isValidPoint(point) {
  return Boolean(point && point.latitude && point.longitude && point.timestampUtc);
}

export function hasValidSpeed(point) {
  // no-speed points are excluded from the server polyline and should be omitted unless they have events (except trip-start/trip-end) or media
  const { events = [], gpsSpeed = 0, media = [], speed = 0, speedUm = 'mi' } = point;
  const filteredEvents = events.filter((evt) => evt !== 'trip-start' && evt !== 'trip-end');
  if (filteredEvents.length > 0 || media.length > 0) {
    return true;
  }
  if (speed > 0) {
    return true;
  }
  if (gpsSpeed > 0) {
    const gpsKmh = speedUm === 'km' ? gpsSpeed : milesToKm(gpsSpeed);
    return gpsKmh >= 0.8; // 0.5 MPH in KMH
  }
  return false;
}

export function getTripSpeedingSegments(eventPoints, trip, speedingType = 'speeding-major') {
  // note that polyline.decode returns coordinate pairs with latitude first!
  const linePoints = trip.encodedPolyline ? polyline.decode(trip.encodedPolyline) : [];
  const speedingPoints = getValidRoundedSpeedingPoints(eventPoints, speedingType);
  const segments = [];

  if (speedingPoints.length === 0) {
    return segments;
  }

  const speedingIndexes = getSpeedingIndexes(linePoints, speedingPoints);

  speedingIndexes.forEach((lineIndex) => {
    const previousPoint = linePoints[lineIndex - 1];
    if (previousPoint) {
      const point = linePoints[lineIndex];
      const previousPointWasSpeeding = speedingIndexes.includes(lineIndex - 1);
      if (previousPointWasSpeeding) {
        const segmentToContinue = segments[segments.length - 1];
        if (segmentToContinue) {
          // decoded polyline points are lat,lng while we need lng,lat
          segmentToContinue.geometry.coordinates.push(point.reverse());
        }
      } else {
        segments.push({
          type: 'Feature',
          properties: {
            tripKey: trip.key,
            lineIndex,
          },
          geometry: {
            type: 'LineString',
            // decoded polyline points are lat,lng while we need lng,lat
            coordinates: [previousPoint.reverse(), point.reverse()],
          },
        });
      }
    }
  });
  return segments;
}

function getValidRoundedSpeedingPoints(points, speedingType) {
  const results = [];
  points.forEach((point) => {
    if (point.events.includes(speedingType) && isValidPoint(point) && hasValidSpeed(point)) {
      // polyline points are rounded to 5 decimal points
      results.push([Number(point.longitude.toFixed(5)), Number(point.latitude.toFixed(5))]);
    }
  });
  return results;
}

function getSpeedingIndexes(linePoints, speedingPoints) {
  const results = [];
  // skip orphaned speeding events in the very first trip point
  let lastMatchedIndex = 0;
  speedingPoints.forEach(([lng, lat]) => {
    for (let i = lastMatchedIndex + 1; i < linePoints.length; i += 1) {
      const [lineLat, lineLng] = linePoints[i];
      if (lng === lineLng && lat === lineLat) {
        results.push(i);
        lastMatchedIndex = i;
      }
    }
  });
  return results;
}

export function hasNonEventPoints(points = []) {
  return points.some(({ events }) => events && events.length === 0);
}

export function shouldLoadPointsWithoutUserInteraction(tripDate) {
  const date = dayjs.isDayjs(tripDate) ? tripDate : dayjs(tripDate);
  const twoMonthsAgo = dayjs().subtract(2, 'month');
  return date.isAfter(twoMonthsAgo, 'day');
}

export function cancelPointsRequests() {
  store().state.app.apiWorker.cancelTripPointRequests();
}

export function findMatchingMedia({
  desiredLabels = ['event:trip-start', 'camera:front'],
  desiredType = 'image',
  desiredStatus = 'ready',
  labelsLogicalOperator = 'and',
  media = [],
}) {
  return media.filter(({ labels = [], status = '', mediaType }) => {
    const labelsFn = labelsLogicalOperator === 'and' ? 'every' : 'some';
    const isLabelsMatch = desiredLabels[labelsFn]((label) => (labels || []).includes(label));
    const isStatusMatch = (status || '') === desiredStatus;
    const isTypeMatch = (mediaType || '').includes(desiredType);
    return isLabelsMatch && isStatusMatch && isTypeMatch;
  });
}

export function getTripStartMedia(media) {
  return findMatchingMedia({ media, desiredLabels: ['event:trip-start'] });
}

export function getTripEndMedia(media) {
  return findMatchingMedia({ media, desiredLabels: ['event:trip-end'] });
}

export function getTripStartRoadImage(media) {
  const matches = findMatchingMedia({ media, desiredLabels: ['event:trip-start', 'camera:front'] });
  return _first(matches) || null;
}

export function getTripStartCabinImage(media) {
  const matches = findMatchingMedia({ media, desiredLabels: ['event:trip-start', 'camera:cabin'] });
  return _first(matches) || null;
}

export function getTripEndRoadImage(media) {
  const matches = findMatchingMedia({ media, desiredLabels: ['event:trip-end', 'camera:front'] });
  return _first(matches) || null;
}

export function getTripEndCabinImage(media) {
  const matches = findMatchingMedia({ media, desiredLabels: ['event:trip-end', 'camera:cabin'] });
  return _first(matches) || null;
}

export function getRoadImage(media) {
  const matches = findMatchingMedia({ media, desiredLabels: ['camera:front'] });
  return _first(matches) || null;
}

export function getCabinImage(media) {
  const matches = findMatchingMedia({ media, desiredLabels: ['camera:cabin'] });
  return _first(matches) || null;
}

export function shouldUseTripEndPoint(trip) {
  return _has(trip, 'endPoint');
}

export function shouldUseTripStartPoint(trip) {
  const hasStartPoint = _has(trip, 'startPoint');
  const hasEndPoint = shouldUseTripEndPoint(trip);
  return hasStartPoint && !hasEndPoint;
}

/**
 * We now draw any point with media with video marker on the map
 * @param {Array} media
 * @returns {Boolean}
 */
export function hasDiscoverableMedia(media = []) {
  return media.length > 0;
}

export function hasDiscoverableImages(media = []) {
  return findMatchingMedia({ desiredLabels: [], desiredType: 'image', media }).length > 0;
}

export function hasDiscoverableVideos(media = []) {
  return findMatchingMedia({ desiredLabels: [], desiredType: 'video', media }).length > 0;
}

export function getTripPointFeature(point) {
  if (!isValidPoint(point) || !hasValidSpeed(point)) {
    return null;
  }
  const {
    hasMedia,
    isAccelOrBrake,
    isDistractedDriving,
    isGeofenceEvent,
    isHardBrake,
    isIdle,
    isMajorSpeeding,
    isMinorSpeeding,
    isRapidAccel,
    isSpeeding,
  } = getPointEventMediaInfo(point);
  // could zIndex with circle-sort-key if necessary
  const feature = {
    type: 'Feature',
    properties: {
      ...point,
      hasMedia,
      isAccelOrBrake,
      isDistractedDriving,
      isGeofenceEvent,
      isHardBrake,
      isIdle,
      isMajorSpeeding,
      isMinorSpeeding,
      isRapidAccel,
      isSpeeding,
    },
    geometry: {
      type: 'Point',
      coordinates: [point.longitude, point.latitude],
    },
  };
  const icon = getPointIcon(point);
  if (icon) {
    feature.properties.icon = icon;
  }
  return feature;
}

function getPointEventMediaInfo({ events = [], media = [] }) {
  const hasMedia = media && media.length > 0;
  const isGeofenceEvent = events.includes('geofence-enter') || events.includes('geofence-exit');
  const isHardBrake = events.includes('hard-brake');
  const isIdle = events.includes('idle-start');
  const isMajorSpeeding = events.includes('speeding-major');
  const isMinorSpeeding = events.includes('speeding-minor');
  const isRapidAccel = events.includes('rapid-accel');
  const isDistractedDriving = events.includes('distracted-driving');

  const isAccelOrBrake = Boolean(isHardBrake || isRapidAccel);
  const isSpeeding = Boolean(isMinorSpeeding || isMajorSpeeding);
  return {
    hasMedia,
    isAccelOrBrake,
    isDistractedDriving,
    isGeofenceEvent,
    isHardBrake,
    isIdle,
    isMajorSpeeding,
    isMinorSpeeding,
    isRapidAccel,
    isSpeeding,
  };
}

function getPointIcon({ events = [], media = [], distractionType }) {
  const { hasMedia, isDistractedDriving, isHardBrake, isIdle, isRapidAccel } = getPointEventMediaInfo({
    events,
    media,
  });

  const videoTemplate = hasMedia ? '-video' : '';
  let eventsTemplate = '';
  if (isHardBrake) {
    eventsTemplate = 'hard-brake';
  } else if (isRapidAccel) {
    eventsTemplate = 'rapid-accel';
  } else if (isIdle) {
    eventsTemplate = 'idle';
  } else if (isDistractedDriving) {
    eventsTemplate = `distracted-driving-${distractionType}`;
  } else {
    // speeding points don't get icons
    const filtered = getFilteredSortedPointEvents(events).filter((evt) => !evt.includes('speeding'));
    eventsTemplate = _kebabCase(filtered.toString());
  }

  const icon = `${eventsTemplate}${videoTemplate}`;

  return icon;
}

export function getFilteredSortedPointEvents(allEvents = []) {
  const allowedEvents = [
    'hard-brake',
    'rapid-accel',
    'geofence-enter',
    'geofence-exit',
    'idle-start',
    'speeding-minor',
    'speeding-major',
    'distracted-driving',
  ];
  const filtered = allEvents.filter((event) => allowedEvents.includes(event));
  const sorted = sortEvents(filtered);
  return sorted;
}

function sortEvents(events = []) {
  return events.sort((a, b) => {
    if (a === 'hard-brake') {
      return -1;
    }
    if (b === 'hard-brake') {
      return 1;
    }
    if (a === 'rapid-accel') {
      return -1;
    }
    if (b === 'rapid-accel') {
      return 1;
    }
    return a <= b ? -1 : 1;
  });
}

export function getMapImages() {
  const pointColors = [
    { label: '', innerColor: defaultColor },
    { label: 'speeding-major', innerColor: speedingMajorColor },
    { label: 'speeding-minor', innerColor: speedingMinorColor },
  ];
  const rawImages = [
    {
      name: 'HardBrake',
      svg: HardBrakeSvg,
      permutations: { colors: [{ label: '', innerColor: hardBrakeColor }], video: true },
    },
    {
      name: 'RapidAccel',
      svg: RapidAccelSvg,
      permutations: { colors: [{ label: '', innerColor: rapidAccelColor }], video: true },
    },
    { name: 'Point', svg: PointWithVideoSvg, permutations: { colors: pointColors, video: true } },
    {
      name: 'Idle',
      svg: IdleSvg,
      permutations: { colors: [{ label: '', innerColor: defaultColor }], video: true },
    },
    {
      name: 'DistractedDrivingCellphone',
      svg: DistractedDrivingCellphoneSvg,
      permutations: {
        colors: [{ label: '', borderColor: '#d4aa00', outerColor: '#56511d', innerColor: distractedDrivingColor }],
        video: true,
        height: 58,
        width: 58,
      },
    },
    {
      name: 'DistractedDrivingDrinking',
      svg: DistractedDrivingDrinkingSvg,
      permutations: {
        colors: [{ label: '', borderColor: '#d4aa00', outerColor: '#56511d', innerColor: distractedDrivingColor }],
        video: true,
        height: 58,
        width: 58,
      },
    },
    {
      name: 'DistractedDrivingEating',
      svg: DistractedDrivingEatingSvg,
      permutations: {
        colors: [{ label: '', borderColor: '#d4aa00', outerColor: '#56511d', innerColor: distractedDrivingColor }],
        video: true,
        height: 58,
        width: 58,
      },
    },
    {
      name: 'DistractedDrivingSmoking',
      svg: DistractedDrivingSmokingSvg,
      permutations: {
        colors: [{ label: '', borderColor: '#d4aa00', outerColor: '#56511d', innerColor: distractedDrivingColor }],
        video: true,
        height: 58,
        width: 58,
      },
    },
    {
      name: 'DistractedDrivingTired',
      svg: DistractedDrivingTiredSvg,
      permutations: {
        colors: [{ label: '', borderColor: '#d4aa00', outerColor: '#56511d', innerColor: distractedDrivingColor }],
        video: true,
        height: 58,
        width: 58,
      },
    },
  ];

  const geofenceImages = {
    GeofenceEnterGeofenceExitSvg,
    GeofenceEnterSvg,
    GeofenceExitSvg,
  };

  Object.entries(geofenceImages).forEach(([name, svg]) => {
    const geofenceExitColors = [...pointColors];
    geofenceExitColors[0] = { ...geofenceExitColors[0], innerColor: '#ffffff', outerColor: defaultColor };
    const colors = name === 'GeofenceExitSvg' ? geofenceExitColors : pointColors;
    rawImages.push({ name: name.replace('Svg', ''), svg, permutations: { colors, video: true } });
  });

  const imageOptions = {
    height: 72,
    width: 72,
  };

  const images = [];
  rawImages.forEach(({ name, permutations: { colors, height, width, video }, svg }) => {
    colors.forEach(({ label: colorLabel, borderColor, innerColor, outerColor = '#ffffff' }) => {
      const formattedName = colorLabel ? `${name}-${colorLabel}` : name;
      images.push({
        ...getColorizedSvgAndName({ borderColor, innerColor, outerColor, name: formattedName, svg }),
        options: {
          ...imageOptions,
          height,
          width,
        },
      });
      if (video) {
        images.push({
          ...getColorizedSvgAndName({
            borderColor,
            innerColor,
            outerColor,
            name: `${formattedName}Video`,
            svg: addVideoIconToTripSvg(svg, VideoSvg),
          }),
          options: {
            ...imageOptions,
            height,
            width,
          },
        });
      }
    });
  });

  // remove points without video images
  const filtered = images.filter(({ name }) => !(name.includes('point') && !name.includes('video')));

  return filtered;
}

function getColorizedSvgAndName({ borderColor, innerColor, outerColor = '#ffffff', name, svg }) {
  const markerOptions = {
    borderColor,
    innerColor,
    outerColor,
  };
  return {
    name: _kebabCase(name),
    svg: new SvgMarker(svg, markerOptions).colorize(),
  };
}

export function humanizeEventLabel(eventName) {
  let event = eventName;
  if (event.includes('speeding')) {
    event = event.split('-').reverse().join('-');
  }
  if (event.includes('geofence')) {
    event = event.replace('geofence', 'place');
    event = event.replace('enter', 'entered');
    event = event.replace('exit', 'exited');
  }
  if (event === 'idle-start') {
    event = 'idle';
  }
  return _startCase(event);
}

export function hasEndLocation(trip) {
  const { encodedPolyline, endPoint } = trip;
  return Boolean(
    endPoint?.address || endPoint?.place || (endPoint?.latitude && endPoint?.longitude) || encodedPolyline
  );
}
