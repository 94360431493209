<template>
  <MapboxControl :disable="matchesIdealBounds" tooltip="Zoom to listed vehicles" @click="fitToIdealBounds">
    <FitToIdealSvg />
  </MapboxControl>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MapboxControl from 'components/map/MapboxControl.vue';
import FitToIdealSvg from 'src/assets/fit-to-ideal.svg';

export default {
  name: 'IdealFitControl',
  components: {
    FitToIdealSvg,
    MapboxControl,
  },
  computed: {
    ...mapState('map', ['matchesIdealBounds']),
  },
  methods: {
    ...mapActions('map', ['fitToIdealBounds']),
  },
};
</script>
