<template>
  <div class="drawer__container fixed z-index-2 row justify-center full-width">
    <div
      ref="drawer"
      v-touch-pan.vertical.prevent.mouse="handleDrag"
      :class="`drawer relative full-width bg-white overflow-hidden-y shadow-4 ${isAnimating ? 'animate' : ''} ${isDragging ? 'is-dragging' : ''}`"
      :style="{ height: `${drawerHeight}px` }"
      @touchend="isDragging = false"
      @touchstart="onDragStart"
    >
      <div class="row justify-center q-px-sm q-pt-sm q-pb-sm">
        <q-separator class="drawer__handle" color="grey-7" />
      </div>
      <div
        ref="drawerContent"
        class="drawer__content q-px-sm q-pb-sm"
        :class="{
          'expand-threshold': reachedExpandedHeights,
          'closed-threshold': this.reachedClosedHeights,
        }"
        @touchmove="onContentPull"
        @touchstart="onContentGrab"
      >
        <MappedItem
          ref="content"
          :asset="cachedAsset"
          is-expanded
          menu-btn-padding="md"
          :show-locations-in-menu="false"
          :show-overview-in-menu="false"
          @icon-click="fullyExpand"
          @nickname-click="fullyExpand"
          @zoom-click="condense"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import { mapActions, mapGetters, mapState } from 'vuex';
import MappedItem from 'components/map/MappedItem.vue';
import Asset from 'src/models/Asset';
import { setTimeoutPromise } from 'src/services/setTimeout';

// 76 = list button + action buttons + padding
const ACTION_BUTTONS_AREA = 88;
const CLOSED_VIEW_HEIGHT = 20;
const DRAWER_HANDLE_AREA = 30;
const THRESHOLD_DIFF = 20;

const STATES = Object.freeze({
  EXITED: 'EXITED',
  FULLY_EXPANDED: 'FULLY_EXPANDED',
  CONDENSED: 'CONDENSED',
  CLOSED: 'CLOSED',
});

export default {
  name: 'SelectedAssetDrawer',
  components: {
    MappedItem,
  },
  computed: {
    ...mapState('assets', ['selectedKey']),
    ...mapState('map', ['selectedDrawerShouldUpdate']),
    ...mapGetters('assets', ['selectedAsset']),
    drawerCloseThreshold() {
      return this.condensedSize - THRESHOLD_DIFF;
    },
    drawerExpandThreshold() {
      return this.condensedSize + THRESHOLD_DIFF;
    },
    isClosed() {
      return this.drawerState === STATES.CLOSED;
    },
    isCondensed() {
      return this.drawerState === STATES.CONDENSED;
    },
    isFullyExpanded() {
      return this.drawerState === STATES.FULLY_EXPANDED;
    },
  },
  data() {
    return {
      baseDrawerHeight: 0,
      contentTouchStartY: 0,
      cachedAsset: new Asset(), // asset is cached so UI doesn't break when asset is unselected
      condensedResizeObserver: null,
      condensedSize: 0,
      drawerHeight: 0,
      drawerState: STATES.EXITED,
      expandedHeight: 0,
      initialCondensedSet: false,
      isAnimating: false,
      isDragging: false,
      reachedClosedHeights: false,
      reachedExpandedHeights: false,
    };
  },
  methods: {
    ...mapActions('map', ['selectedDrawerUpdated']),
    animateToHeight(height, quick = false) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        if (!quick) {
          this.isAnimating = true;
        }
        await this.$nextTick();
        this.baseDrawerHeight = height;
        this.drawerHeight = height;
        await setTimeoutPromise(quick ? 0 : 300);
        this.isAnimating = false;
        resolve();
      });
    },
    async exit() {
      this.drawerState = STATES.EXITED;
      this.reachedExpandedHeights = false;
      this.reachedClosedHeights = true;
      this.$emit('open', false);
      await this.animateToHeight(0);
      this.cachedAsset = this.selectedAsset;
    },
    async close(quick = false) {
      this.drawerState = STATES.CLOSED;
      this.reachedExpandedHeights = false;
      this.reachedClosedHeights = true;
      this.$emit('open', false);
      await this.animateToHeight(CLOSED_VIEW_HEIGHT, quick); // just enough room for handle
    },
    async condense() {
      this.drawerState = STATES.CONDENSED;
      this.reachedExpandedHeights = false;
      this.reachedClosedHeights = false;
      this.$emit('open', true);
      await this.animateToHeight(this.condensedSize);
    },
    async fullyExpand() {
      this.expandedHeight = this.$refs.content.$el.offsetHeight + ACTION_BUTTONS_AREA;

      // Make sure expanded height isn't out of view
      const availableScreenHeight = window.innerHeight - ACTION_BUTTONS_AREA - DRAWER_HANDLE_AREA;
      if (this.expandedHeight > availableScreenHeight) {
        this.expandedHeight = availableScreenHeight;
      }

      this.drawerState = STATES.FULLY_EXPANDED;
      this.reachedExpandedHeights = true;
      this.reachedClosedHeights = false;
      this.$emit('open', true);
      await this.animateToHeight(this.expandedHeight);
    },
    async handleDrag({ distance, duration, isFinal, offset }) {
      this.drawerHeight = this.baseDrawerHeight - offset.y;

      this.reachedExpandedHeights = this.drawerHeight > this.condensedSize;
      this.reachedClosedHeights = this.drawerHeight < this.condensedSize;

      if (isFinal) {
        this.baseDrawerHeight = this.drawerHeight;

        if (
          /**
           * User is pulling drawer down below condensed size.
           */
          (this.isCondensed && this.drawerHeight <= this.drawerCloseThreshold) ||
          /**
           * User rapidly pulled drawer down from the expanded state.
           */
          (this.isFullyExpanded && distance.y > 150 && duration < 200)
        ) {
          this.close();
          return;
        }

        if (this.isFullyExpanded && this.drawerHeight <= this.expandedHeight - THRESHOLD_DIFF) {
          /**
           * Drawer is expanded and user is pulling drawer down towards
           * condensed size.
           */
          this.condense();
        } else if (this.drawerHeight >= this.drawerExpandThreshold) {
          /**
           * User is pulling drawer towards expanded size or pull-down from
           * expanded state hasn't reached the threshold.
           * - Calculated: content height + handle + content mask (bottom div) + extra padding
           */
          this.fullyExpand();
        } else if (this.isClosed && this.drawerHeight <= CLOSED_VIEW_HEIGHT) {
          /**
           * User is pulling drawer to closed state when already in closed state.
           */
          this.close();
        } else {
          /**
           * Default state, drawer was just shown or pull-up/pull-down
           * thresholds haven't been reached.
           */
          this.condense();
        }
      }
    },
    onContentGrab(event) {
      if (this.isFullyExpanded) {
        event.stopPropagation(); // allow scrolling when fully expanded
      }
      this.contentTouchStartY = event.touches?.[0]?.clientY || 0;
    },
    onContentPull(event) {
      if (this.isDragging) {
        return;
      }

      const { clientY } = event.touches?.[0] || {};

      /**
       * Trigger drawer pull if:
       */
      if (
        this.isFullyExpanded && // drawer is fully expanded
        this.$refs.drawerContent.scrollTop === 0 && // drawer content is scrolled to the top
        clientY > this.contentTouchStartY // user is dragging down
      ) {
        const touchEvent = new TouchEvent('touchstart', {
          touches: event.touches,
        });
        this.$refs.drawer.dispatchEvent(touchEvent);
      }
    },
    onDragStart() {
      this.isDragging = true;
      this.$refs.drawerContent.scrollTo({ top: 0, behavior: 'smooth' });
    },
    onSelectedAssetChange() {
      this.$nextTick(async () => {
        if (!this.selectedAsset.isEmpty) {
          if (this.selectedAsset.key !== this.cachedAsset.key) {
            this.close(true);
            await setTimeoutPromise(500);
          }
          this.condense();
          this.cachedAsset = this.selectedAsset;
        } else if (this.selectedAsset.isEmpty) {
          this.exit();
        }
        this.selectedDrawerUpdated();
      });
    },
    updateExpandedHeight() {
      if (this.isFullyExpanded) {
        this.fullyExpand();
      }
    },
  },
  created() {
    this.onWindowResize = _debounce(() => this.updateExpandedHeight(), 500);
  },
  mounted() {
    this.condensedResizeObserver = new ResizeObserver((entries) => {
      const { contentRect } = entries[0];
      this.condensedSize = contentRect.height + ACTION_BUTTONS_AREA;
      if (this.isCondensed && !this.selectedAsset.isEmpty) {
        this.condense(); // re-apply size
      }
    });

    // start observing for resize
    this.condensedResizeObserver.observe(this.$el.querySelector('.condensed-details'));
    this.onSelectedAssetChange();

    window.addEventListener('resize', this.onWindowResize);
  },
  beforeUnmount() {
    this.condensedResizeObserver.unobserve(this.$el.querySelector('.condensed-details'));
    window.removeEventListener('resize', this.onWindowResize);
  },
  watch: {
    selectedKey() {
      this.onSelectedAssetChange();
    },
    selectedDrawerShouldUpdate() {
      if (this.selectedDrawerShouldUpdate) {
        this.onSelectedAssetChange();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.animate {
  transition: height 0.3s ease-out;
}

.drawer__container {
  bottom: calc(env(safe-area-inset-bottom) + 53px);
}

.drawer {
  max-width: 400px;
  will-change: height;
}

.drawer__handle {
  height: 6px;
  width: 50px;
}

.drawer__content {
  height: calc(100% - 70px);
}

.expand-threshold {
  overflow: auto;
}

.is-dragging .drawer__content {
  overflow: hidden;
}

.drawer__content-mask {
  height: 19px;
  max-width: 500px;
}

.slideHeight-enter-active,
.slideHeight-leave-active {
  max-height: 300px;
  overflow: hidden;
  transition: max-height 0.3s;
  will-change: max-height;
}

.slideHeight-enter,
.slideHeight-leave-to {
  max-height: 0px;
}

:deep(.details-container) {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

:deep(.expand-threshold .details-container) {
  opacity: 1;
}

:deep(.actions) {
  opacity: 1;
  transition: all 0.3s ease-out;
  will-change: padding;
}

:deep(.closed-threshold .actions) {
  padding-bottom: 0;
  opacity: 0;
}
</style>
