<template>
  <div class="marker" @click.stop>
    <div class="relative-position">
      <div class="marker-content shadow-2 q-py-xs q-px-sm row no-wrap items-center no-wrap">
        <div>
          <div v-if="arrivalLoading" class="text-center">
            <q-spinner-puff color="primary" size="1em" />
          </div>
          <div v-show="showArrival" class="row justify-center text-center">{{ timestampArrival }}</div>
          <div v-if="showBoth">&nbsp;&ndash;&nbsp;</div>
          <div v-show="showDeparture" class="row justify-center text-center text-no-wrap">{{ timestampDeparture }}</div>
          <q-tooltip anchor="top middle" self="bottom middle">
            <span v-if="timestampArrival">
              <strong>Arrived</strong>
              {{ timestampArrivalPrecise }}
              <br />
            </span>
            <span v-if="timestampDeparture">
              <strong>Left</strong>
              {{ timestampDeparturePrecise }}
              <br />
            </span>
            <em v-if="duration">{{ duration }}</em>
          </q-tooltip>
        </div>
        <q-btn
          v-if="showCamera && focusablePoint"
          color="primary"
          flat
          icon="zoom_in"
          size="xs"
          square
          @click="$emit('point-focused', focusablePoint)"
        >
          <q-tooltip>View details</q-tooltip>
        </q-btn>
      </div>
      <svg class="arrow" height="18" :style="arrowStyle" width="10">
        <polygon class="shadow-2" points="0 1, 5 1, 10 1, 5 15" />
      </svg>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import _get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import { secondsToDaysHoursMinutes, timestampsToDaysHoursMinutes, utcToLocalDateTime } from 'src/services/date';
import { tripMarkerConfig } from 'src/services/markers';

dayjs.extend(utc);

export default {
  name: 'TripMarker',
  props: {
    arrivalLoading: {
      type: Boolean,
      default: false,
    },
    point: {
      required: true,
      type: Object,
    },
    hoveredTripKey: {
      type: String,
      default: null,
    },
    showCamera: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('vehicles', ['vehicles']),
    arrowStyle() {
      const config = tripMarkerConfig[this.point.direction];
      let styles = '';
      Object.entries(config.style).forEach(([prop, value]) => {
        styles += ` ${prop}: ${value};`;
      });
      return styles;
    },
    duration() {
      if (this.point.arrival && this.point.arrival.timestampUtc) {
        if (this.point.departure && this.point.departure.timestampUtc) {
          return timestampsToDaysHoursMinutes(this.point.arrival.timestampUtc, this.point.departure.timestampUtc);
        }
        const vehicleKey = this.$route.name === 'vehicleTrips' && this.$route.params.key;
        if (vehicleKey) {
          const vehicle = this.vehicles[vehicleKey];
          const lastTripKey = _get(vehicle, 'lastTrip.key');
          if (lastTripKey && lastTripKey === this.point.arrival.tripKey) {
            const now = dayjs();
            const arrived = dayjs.utc(this.point.arrival.timestampUtc).local();
            const durationInSeconds = now.diff(arrived, 'second');
            return secondsToDaysHoursMinutes(durationInSeconds);
          }
        }
      }
      return '';
    },
    focusablePoint() {
      if (this.showArrival) {
        return { ...this.point.arrival, events: ['trip-end'] };
      }
      if (this.showDeparture) {
        return { ...this.point.departure, events: ['trip-start'] };
      }
      return null;
    },
    showArrival() {
      return this.point.arrival;
    },
    showDeparture() {
      return this.point.departure;
    },
    showBoth() {
      return this.showArrival && this.showDeparture;
    },
    timestampArrival() {
      return this.timestampArrivalPrecise.replace(/:\d{2} /, ' ');
    },
    timestampArrivalPrecise() {
      if (this.point.arrival && this.point.arrival.timestampUtc) {
        return utcToLocalDateTime(this.point.arrival.timestampUtc, true);
      }
      return '';
    },
    timestampDeparture() {
      return this.timestampDeparturePrecise.replace(/:\d{2} /, ' ');
    },
    timestampDeparturePrecise() {
      if (this.point.departure && this.point.departure.timestampUtc) {
        return utcToLocalDateTime(this.point.departure.timestampUtc, true);
      }
      return '';
    },
  },
  methods: {
    ...mapActions('lightbox', ['openItems']),
    openLightbox() {
      this.openItems({ items: ['/dashcam-cabin.jpg', '/dashcam-road.jpg'] });
    },
  },
  data() {
    return {
      camera: 'front',
    };
  },
};
</script>

<style lang="scss" scoped>
.marker-content {
  position: relative;
  top: 2px;
  background: $white;
  color: $dark;
  border-radius: 1000px; /* large value to approximate 50% of height for both X and Y */
  line-height: 12px;
  border: 1px solid $white;

  .both-points {
    padding-right: 8px;
    margin-right: 8px;
    border-right: solid 1px $dark;
  }

  .row {
    min-width: 60px;
  }
}

.arrow {
  position: absolute;
  // filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.3));
  // transform: rotate(60deg);
  // left: 0;
  // bottom: 0;
}

/* 50deg: bottom: 12px, top: 9px */
.arrow polygon {
  fill: $white;
  stroke-width: 1px;
  stroke: $white;
  stroke-dasharray: 0 10 15 0 15 0;
}

.shadow {
  fill: rgba(0, 0, 0, 0.24);
}
</style>
