import storage from 'src/services/storage';

export default () => {
  storage.onReady((store) => {
    const recentConnections = storage.get('assets/recentConnections');
    if (recentConnections) {
      store.commit('assets/setRecentConnections', recentConnections.split(','));
    }
  });

  return {
    /** @type {{ [key: string]: import('src/models/Asset').default }} */
    assets: {},
    batteryHistory: {},
    batteryHistoryLoading: false,
    hoveredLocationFromList: false,
    locationHistory: [],
    locationHistoryLoading: false,
    temperatureHistory: {},
    temperatureHistoryLoading: false,
    editDialogKey: null,
    editDialogOpen: false,
    hoveredLocationHistoryId: null,
    locations: {},
    recentConnections: [],
    selectedKey: null,
    assetTypes: {},
    assetTypesLoading: false,
  };
};
