class SimpleModel {
  isEmpty = true;

  /**
   * Creates a new instance and populates properties with given data values (if prop() method is used).
   *
   * @constructor
   * @param {Object} data
   */
  constructor(data = {}) {
    // Apply properties based on props() definition
    if (this.props instanceof Function) {
      const definedProps = this.props();
      Object.keys(definedProps).forEach((propName) => {
        if (data[propName] !== undefined) {
          this.isEmpty = false;
          this[propName] = data[propName];
        } else {
          this[propName] = definedProps[propName];
        }
      });
    }
  }

  /**
   * Returns all properties associated with the object.
   *
   * @returns {Object}
   */
  get allProperties() {
    const allProps = {};

    const propertyDescriptors = {
      ...Object.getOwnPropertyDescriptors(this),
      ...Object.getOwnPropertyDescriptors(this.constructor.prototype),
    };

    // Include non-getter properies
    const definedProps = Object.getOwnPropertyDescriptors(this);
    Object.keys(definedProps).forEach((key) => {
      allProps[key] = definedProps[key].value;
    });

    // Add getter properties
    Object.entries(propertyDescriptors)
      .filter((entry) => typeof entry[1].get === 'function')
      .forEach(([key]) => {
        allProps[key] = this[key];
      });

    return allProps;
  }

  /**
   * Updates properties of object with the given object's properties.
   *
   * @param {Object} data
   */
  insert(data = {}) {
    // Only insert properties that are defined
    Object.keys(data).forEach((keyName) => {
      if (typeof this[keyName] !== 'undefined') {
        this.isEmpty = false;
        this[keyName] = data[keyName];
      }
    });
  }
}

export default SimpleModel;
