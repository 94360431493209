<template>
  <div class="driver-quick-navigation row justify-between bg-secondary">
    <q-btn-group class="driver-quick-navigation__group full-width">
      <q-btn
        :class="{ 'bg-white': isReportRoute, 'bg-secondary': !isReportRoute }"
        flat
        :padding="$q.screen.gt.xs ? '20px 16px' : '20px 8px'"
        square
        text-color="black"
        :to="{ name: 'metabase-single', params: { type: 'dashboard', id: '106' } }"
      >
        {{ $q.screen.width > 600 ? 'Dashboard' : 'Dash' }}
      </q-btn>
      <q-btn
        v-if="hasPermission('isAllowedToCheckIn')"
        :class="{ 'text-no-wrap': true, 'bg-white': isCheckInRoute, 'bg-secondary': !isCheckInRoute }"
        flat
        square
        text-color="black"
        :to="{ name: 'checkIn' }"
      >
        Check In
      </q-btn>
      <q-btn
        :class="{ 'bg-white': isFeedRoute, 'bg-secondary': !isFeedRoute }"
        flat
        :padding="$q.screen.gt.xs ? '20px 16px' : '20px 8px'"
        square
        text-color="black"
        :to="{ name: 'feed' }"
      >
        Feed
      </q-btn>
    </q-btn-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DriverQuickNavigation',
  computed: {
    ...mapGetters('session', ['hasPermission']),
    isFeedRoute() {
      return this.$route.name === 'feed';
    },
    isCheckInRoute() {
      return this.$route.name === 'checkIn';
    },
    isReportRoute() {
      return this.$route.name === 'metabase-single';
    },
  },
  created() {
    document.body.classList.add('driver');
  },
  destroyed() {
    document.body.classList.remove('driver');
  },
};
</script>

<style lang="scss" scoped>
.driver-quick-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-top: 1px solid black;
  margin-right: env(safe-area-inset-right) !important;
  min-width: auto;
  width: 100%;

  :deep(.q-btn) {
    flex: 1;
  }
}

.driver-quick-navigation__group {
  box-shadow: none;
}
</style>

<style lang="scss">
.ios-notch-bottom .driver-quick-navigation {
  padding-bottom: calc(env(safe-area-inset-bottom) - 8px);
}
</style>
