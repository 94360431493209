import _takeRight from 'lodash/takeRight';
import storage from 'src/services/storage';

const MAX_RECENT_CONNECTIONS = 2;

export function getMaxConnections() {
  return MAX_RECENT_CONNECTIONS;
}

export function addRecentConnection(state, assetKey) {
  const recentConnections = [...state.recentConnections];

  if (!recentConnections.includes(assetKey)) {
    recentConnections.push(assetKey);
  }

  state.recentConnections =
    recentConnections.length > MAX_RECENT_CONNECTIONS
      ? _takeRight(recentConnections, MAX_RECENT_CONNECTIONS)
      : recentConnections;

  storage.set('assets/recentConnections', state.recentConnections.join(','));
}

export function deleteAssetType(state, assetTypeKey) {
  const assetTypes = { ...state.assetTypes };
  delete assetTypes[assetTypeKey];
  state.assetTypes = assetTypes;
}

export function insertAsset(state, asset) {
  state.assets = {
    ...state.assets,
    [asset.key]: asset,
  };
}

export function insertAssetType(state, assetType) {
  state.assetTypes = {
    ...state.assetTypes,
    [assetType.key]: assetType,
  };
}

export function remove(state, assetKey) {
  const assets = { ...state.assets };
  delete assets[assetKey];
  state.assets = assets;
}

export function selectAsset(state, assetKey) {
  state.selectedKey = assetKey;
}

export function setAssetLocations(state, { assetKey, locations }) {
  state.locations = {
    ...state.locations,
    [assetKey]: locations,
  };
}

export function setAssets(state, assets) {
  state.assets = assets;
}

export function setAssetTypes(state, assetTypes) {
  state.assetTypes = assetTypes;
}

export function setAssetTypesLoading(state, isLoading) {
  state.assetTypesLoading = isLoading;
}

/**
 * @param {AssetsStoreState} state
 * @param {string | undefined} key
 */
export function openEditDialog(state, key) {
  state.editDialogOpen = true;
  state.editDialogKey = key;
}

/**
 * @param {AssetsStoreState} state
 */
export function closeEditDialog(state) {
  state.editDialogOpen = false;
  state.editDialogKey = null;
}

export function setHoveredFromList(state, isFromList) {
  state.hoveredLocationFromList = isFromList;
}

export function updateAsset(state, asset) {
  state.assets = {
    ...state.assets,
    [asset.key]: asset,
  };
}

export function updateAssets(state, updates) {
  state.assets = {
    ...state.assets,
    ...updates,
  };
}

export function updateAssetType(state, assetType) {
  state.assetTypes = {
    ...state.assetTypes,
    [assetType.key]: assetType,
  };
}

export function setLocationHistoryLoading(state, isLoading) {
  state.locationHistoryLoading = isLoading;
}

// this is a flat array, not an object with asset key properties
export function setLocationHistory(state, { value }) {
  state.locationHistory = value;
}

export function setOneLocationSelection(state, { index, value }) {
  const clone = [...state.locationHistory];
  const location = clone[index];
  if (location) {
    location.selected = value;
    state.locationHistory = clone;
  }
}

export function setAllLocationsSelection(state, isSelected) {
  const locationHistory = state.locationHistory.map((loc) => ({
    ...loc,
    selected: isSelected,
  }));
  state.locationHistory = locationHistory;
}

export function setLocationSelectionToOneLocation(state, indexToSelect) {
  const locationHistory = state.locationHistory.map((loc, index) => ({
    ...loc,
    selected: index === indexToSelect,
  }));
  state.locationHistory = locationHistory;
}

export function clearHoveredLocation(state) {
  state.hoveredLocationHistoryId = null;
}

export function setHoveredLocation(state, id) {
  state.hoveredLocationHistoryId = id;
}

export function setBatteryHistoryLoading(state, value) {
  state.batteryHistoryLoading = value;
}

export function setBatteryHistory(state, { key, value }) {
  state.batteryHistory = { ...state.batteryHistory, [key]: value };
}

export function setTemperatureHistoryLoading(state, value) {
  state.temperatureHistoryLoading = value;
}

export function setTemperatureHistory(state, { key, value }) {
  state.temperatureHistory = { ...state.temperatureHistory, [key]: value };
}

/**
 * Sets the recent asset connections.
 *
 * @param {AssetsStoreState} state
 * @param {string[]} connections
 */
export function setRecentConnections(state, connections) {
  state.recentConnections = connections;
  storage.set('assets/recentConnections', connections.join(','));
}
