/* eslint class-methods-use-this:0 */

import UnitLocale from './UnitLocale';

export default class ImperialUnitLocale extends UnitLocale {
  /** @type {String} */
  FUEL_ECONOMY_UNITS = 'mpg';

  /** @type {String} */
  VELOCITY_UNITS = 'mph';

  /**
   * Converts the given value (kph) to mph.
   *
   * @param {Number} velocity
   * @returns {Number}
   */
  static kmToMiles(velocity) {
    return velocity * 0.62137119223733;
  }

  /**
   * Converts the given value to the imperial equivalent.
   *
   * @param {Number} value
   * @param {String} fromUnit
   * @returns {Number}
   */
  convertDistance(value, fromUnit) {
    return fromUnit === 'km' ? ImperialUnitLocale.kmToMiles(value) : value;
  }

  /**
   * Converts the given fuel economy to the imperial equivalent.
   *
   * @param {Number} value
   * @param {String} fromUnit
   * @returns {Number}
   */
  convertFuelEconomy(value, fromUnit) {
    switch (fromUnit) {
      case 'km/L':
        return value * 2.35214583;
      case 'L/100 km':
        return 235.2137783 / value;
      default:
        return value;
    }
  }

  /**
   * Converts the given velocity to the imperial equivalent.
   *
   * @param {Number} velocity
   * @param {String} fromUnit
   * @returns {Number}
   */
  convertVelocity(velocity, fromUnit) {
    return fromUnit === 'kph' ? ImperialUnitLocale.kmToMiles(velocity) : velocity;
  }

  /**
   * Returns the imperial unit of measurement.
   *
   * @param {Number} value
   * @param {Boolean} long
   * @returns {String}
   */
  getDistanceUnits(value = 0, long = false) {
    let unit = 'mi';

    if (long) {
      unit = 'mile';
      if (!Number.isFinite(value) || value < 1 || value > 1) {
        unit += 's';
      }
    }

    return unit;
  }
}
