/**
 * Retrives disconnected location history for a given serial number.
 * - also keeps track of the last transmission date/time
 *
 * @param {ActionContext} context
 * @param {Object} action
 * @param {String} action.serial
 * @param {Dayjs} actions.lastTransmission
 */
export async function getDisconnectedLocationHistory(context, { serial, lastTransmission }) {
  const history = await context.rootState.app.broker.getDisconnectedLocationHistory(serial);

  if (history.error) {
    return;
  }

  context.commit('setDisconnectedLocationHistory', { serial, lastTransmission, history });
}

/**
 *
 * @param {ActionContext} context
 * @param {Object} payload
 * @param {String} payload.serial
 * @param {import('src/models/Address').default} payload.address
 */
export async function updateDisconnectedLocationHistory(context, { serial, address }) {
  const { history, lastTransmission } = context.state.disconnectedLocationHistory[serial] || {};

  if (!history) {
    // No history, nothing to update
    return;
  }

  let hasUpdates = false;
  const updatedHistory = history.map((item) => {
    if (item.longitude === address.longitude && item.latitude === address.latitude) {
      hasUpdates = true;
      return { ...item, address };
    }
    return { ...item };
  });

  if (hasUpdates) {
    context.commit('setDisconnectedLocationHistory', { serial, lastTransmission, history: updatedHistory });
  }
}

/**
 * Retrives a device on the account.
 *
 * @param {DevicesStoreActionContext} context
 * @param {string} serial
 */
export async function getDevice(context, serial) {
  /** @type {DeviceDataTransformed | { error: string }} */
  const device = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getDevice',
    params: {
      serial,
    },
  });

  if (!device.error) {
    context.commit('setDevice', device);
  }

  return device;
}

/**
 * Retrives the list of devices on the account
 *
 * @param {ActionContext} context
 */
export async function getDevices(context) {
  if (context.devicesPromise) {
    return context.devicesPromise;
  }

  const promise = context.rootState.app.broker.getDevices();

  context.commit('setDevicesPromise', promise);

  const devices = await promise;

  context.commit('setDevicesPromise', null);

  if (devices.error) {
    context.commit('setDevices', {});
    return devices;
  }

  context.commit('setDevices', devices);

  return devices;
}

/**
 * Updates the device with the given serial # with the given asset key.
 *
 * @param {ActionContext} context
 * @param {Object} payload
 * @param {String} payload.assetKey
 * @param {String} payload.serial
 */
export function updateDeviceAsset(context, { assetKey, serial }) {
  context.commit('setDevice', {
    ...context.state.devices[serial],
    assetKey,
  });
}
