import SimpleModel from './SimpleModel';

class AssetType extends SimpleModel {
  /** @type {String} */
  iconUri = null;

  /** @type {String} */
  key = null;

  /** @type {String} */
  name = null;

  constructor(data = {}) {
    super();
    this.insert(data);
  }

  /**
   * Generates a punctuated representation of the asset type
   *
   * @returns {String}
   */
  toString() {
    return `Asset: ${this.name}`;
  }
}

export default AssetType;
