import { toRaw } from 'vue';
import ErrorPayload from 'src/models/ErrorPayload';
import Invite from 'src/models/Invite';
import Transformer from 'src/transformers/Transformer';
import UserTransformer from 'src/transformers/UserTransformer';

/**
 * @param {UsersStoreActionContext} context
 */
export async function init(context) {
  await context.dispatch('getUsers');
  context.dispatch('search/verifyHistory', 'user', { root: true });
}

/**
 * Retrieves users via Data Broker.
 *
 * @param {UsersStoreActionContext} context
 */
export async function getUsers(context) {
  if (context.state.usersLoading === true) {
    return context.state.users;
  }

  context.commit('loadingUsers');

  let users = {};
  try {
    users = await context.rootState.app.broker.getUsers();
  } catch (error) {
    console.error(error);
  }

  context.commit('setUsers', users);
  context.commit('usersLoaded');

  return users;
}

/**
 * Retrieves extended account user information from the API.
 *
 * @param {UsersStoreActionContext} context
 * @param {String} key
 * @returns {Object}
 */
export async function getExtendedUserDetails(context, key) {
  context.commit('setExtendedUserLoading', true);

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getAccountUser',
    params: {
      key,
      extended: true,
    },
  });

  if (!response.error) {
    context.commit('setExtendedUserDetails', response);
  }

  context.commit('setExtendedUserLoading', false);

  return response;
}

/**
 * @param {UsersStoreActionContext} context
 */
export async function getAlertSuggestions(context) {
  const promise = context.rootState.app.broker.fetchAndTransform({
    fn: 'getAlertSuggestions',
  });
  const response = await promise;

  if (response.errors || response.error) {
    context.commit('setAlertSuggestions', {});
  } else {
    context.commit('setAlertSuggestions', response);
  }

  return response;
}

/**
 * @param {UsersStoreActionContext} context
 * @param {UserData} userData
 */
export async function createUserInAccount(context, userData) {
  const data = Transformer.snakeCaseKeysDeep(userData);

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'createUserInCurrentAccount',
    params: data,
    transformationFn: 'camelCaseKeysDeep',
  });

  context.dispatch('getUsers'); // trigger user keywords refresh and update user list

  if (response.errors || response.error) {
    context.commit('setUsersError', response.errors || response.error);
    return null;
  }

  return response;
}

/**
 * @param {UsersStoreActionContext} context
 * @param {UserData} userData
 */
export async function updateUser(context, userData) {
  const data = UserTransformer.transformForApi(userData);

  if (typeof data.tag_keys === 'object') {
    data.tag_keys = toRaw(data.tag_keys);
  }

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'updateUser',
    params: {
      key: userData.key,
      data,
    },
    transformationFn: 'camelCaseKeysDeep',
  });

  context.dispatch('getUsers'); // trigger user keywords refresh and update user list

  if (response.errors || response.error) {
    context.commit('setUsersError', response.errors || response.error);
    return null;
  }

  return response;
}

/**
 * @param {UsersStoreActionContext} context
 * @param {string} key
 */
export async function deleteUser(context, key) {
  const response = await context.rootState.app.broker.deleteUser({ key });

  context.dispatch('getUsers'); // trigger user keywords refresh and update user list

  if (response.error) {
    context.commit('setUsersError', response.error);
  }

  return response;
}

/**
 * @param {UsersStoreActionContext} context
 * @param {string} invitationKey
 */
export async function resendInvitation(context, invitationKey) {
  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'renotifyUser',
    params: {
      invitationKey,
    },
    transformationFn: 'camelCaseKeysDeep',
  });

  if (response.errors || response.error) {
    context.commit('setUsersError', response.errors || response.error);
    return null;
  }

  return response;
}

/**
 * @param {UsersStoreActionContext} context
 * @param {string} error
 */
export function setUsersError(context, error) {
  context.commit('setUsersError', error);
}

/**
 * @param {UsersStoreActionContext} context
 * @param {string} error
 */
export function setChangePasswordError(context, error) {
  context.commit('setChangePasswordError', error);
}

/**
 * @param {UsersStoreActionContext} context
 * @param {Object} payload
 * @param {string} payload.currentPassword,
 * @param {string} payload.newPassword
 */
export async function changePassword(context, payload) {
  context.commit('setChangePasswordError', false);

  const response = await context.rootState.app.apiWorker.changePassword(payload);

  let error = response.errors || response.error;
  if (error) {
    if (error.includes('Incorrect Password')) {
      error = 'Current password is incorrect';
    }
    context.commit('setChangePasswordError', error);
    return null;
  }

  return response;
}

/**
 * Retrieves an invite using the given token.
 *
 * @param {UsersStoreActionContext} context
 * @param {String} token
 * @returns {Invite|ErrorPayload}
 */
export async function getInvite(context, token) {
  const { status, data } = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getInvite',
    params: token,
  });

  if (status === 404) {
    return new ErrorPayload('NO_INVITE');
  }

  const invite = new Invite(data);
  context.commit('setInvite', invite);

  return invite;
}

/**
 * Accepts an invitation using the given token.
 *
 * @param {UsersStoreActionContext} context
 * @param {String} token
 * @returns {Promise<Object>}
 */
export async function acceptInvite(context, token) {
  return context.rootState.app.apiWorker.acceptInvite(token);
}
