<template>
  <div
    class="flex column full-width full-height no-wrap"
    :class="{ 'justify-center': !mobileSize, 'justify-end': mobileSize }"
  >
    <div class="full-height" :class="{ 'q-mt-xl': mobileSize, 'row items-center': !mobileSize }">
      <q-form
        ref="form"
        class="full-width q-mb-md"
        :class="{ 'column justify-between full-height no-wrap': mobileSize }"
        @submit.prevent.stop="preLoginCheck"
      >
        <div :class="{ 'full-height full-width content-center': mobileSize }">
          <div class="text-h5 text-center q-mb-md full-width zubie-font">Log In</div>
          <q-input
            ref="username"
            v-model.trim="username"
            autocomplete="username"
            autofocus
            class="full-width"
            dark
            data-t="loginUsername"
            :disable="isSubmitting"
            :error="Boolean(usernameError)"
            :error-message="usernameError"
            :hide-bottom-space="!Boolean(usernameError)"
            label="Email"
            outlined
            type="email"
            @update:model-value="usernameError = null"
          />
          <q-input
            ref="password"
            v-model.trim="password"
            autocomplete="current-password"
            class="full-width q-mt-xs"
            dark
            data-t="loginPassword"
            :disable="isSubmitting"
            :error="Boolean(passwordError)"
            :error-message="passwordError"
            :hide-bottom-space="!Boolean(passwordError)"
            label="Password"
            outlined
            :type="showPassword ? 'text' : 'password'"
            @update:model-value="passwordError = null"
          >
            <template #append>
              <q-icon
                class="cursor-pointer"
                :name="showPassword ? 'visibility_off' : 'visibility'"
                @click="showPassword = !showPassword"
              />
            </template>
          </q-input>
          <q-btn
            align="left"
            class="q-mb-sm"
            dense
            :disable="isSubmitting"
            flat
            label="Forgot password?"
            no-caps
            :to="{ name: 'resetPassword' }"
            unelevated
          />
        </div>
        <div class="row">
          <PrimaryButton
            class="login-button full-width"
            data-t="loginButton"
            label="Log In"
            :loading="isSubmitting && !$q.platform.is.capacitor"
            padding="md"
            type="submit"
          >
            <template #loading>
              <q-spinner />
            </template>
          </PrimaryButton>
          <q-btn
            v-if="checkinAppUrl"
            id="checkInLink"
            class="check-in-link full-width"
            :disable="isSubmitting"
            flat
            no-caps
            square
            unelevated
            @click="openURL(checkinAppUrl)"
          >
            Need to check in to a vehicle? <q-icon class="q-ml-xs" name="open_in_new" size="xs" />
          </q-btn>
        </div>
      </q-form>
    </div>
  </div>
</template>

<script>
import { Dialog, openURL, Platform } from 'quasar';
import { mapActions, mapState } from 'vuex';
import { hasValueFactory, isValidEmailFactory } from 'src/services/forms';
import { isOnline } from 'src/services/network';

const validationHasValue = hasValueFactory();
const validationIsValidEmail = isValidEmailFactory();

export default {
  name: 'PageLogin',
  data() {
    return {
      dialog: null,
      isSubmitting: false,
      password: '',
      passwordError: null,
      showPassword: false,
      username: '',
      usernameError: null,
    };
  },
  computed: {
    ...mapState('users', ['invite']),
    ...mapState('env', ['checkinAppUrl']),
    mobileSize() {
      return this.$q.screen.width < 768;
    },
  },
  methods: {
    ...mapActions('app', {
      appLogin: 'login',
    }),
    openURL,
    stopSubmission() {
      this.isSubmitting = false;
      if (this.dialog) {
        try {
          this.dialog.hide();
        } catch {
          // throws error if already hidden
        }
      }
    },
    async logIn() {
      this.isSubmitting = true;

      if (Platform.is.capacitor) {
        this.dialog = Dialog.create({
          message: 'Logging in...',
          persistent: true,
          progress: true,
          ok: false,
        });
      }

      try {
        const { username, password, invite } = this;

        if (!isOnline()) {
          this.stopSubmission();
          throw new Error('Unable to log in, not connected to the internet.');
        }

        await this.appLogin({ username, password, invite });
      } catch (error) {
        if (error.type === 2) {
          // just throwing an error because of a redirect
          return;
        }

        this.$q.notify({
          icon: 'error',
          color: 'negative',
          message: error.message,
        });

        this.passwordError = error.message;

        this.stopSubmission();

        if (this.$refs.password) {
          await this.$nextTick();
          this.$refs.password.focus();
        }
      }
    },
    preLoginCheck() {
      this.usernameError = null;
      this.passwordError = null;

      const usernameHasValue = validationHasValue(this.username);
      const usernameIsEmail = validationIsValidEmail(this.username);
      if (usernameHasValue !== true) {
        this.usernameError = usernameHasValue;
      } else if (usernameIsEmail !== true) {
        this.usernameError = usernameIsEmail;
      }

      const passwordHasValue = validationHasValue(this.password);
      if (passwordHasValue !== true) {
        this.passwordError = passwordHasValue;
      }

      if (this.usernameError !== null) {
        this.$refs.username.focus();
      } else if (this.passwordError !== null) {
        this.$refs.password.focus();
      } else {
        this.logIn();
      }
    },
  },
  beforeUnmount() {
    this.stopSubmission();
  },
  watch: {
    $route() {
      this.stopSubmission();
    },
  },
};
</script>

<style scoped lang="scss">
.login-button {
  @media (max-width: 767px) {
    order: 2;
  }
}

.check-in-link {
  margin-top: map-get($space-md, 'y');

  @media (max-width: 767px) {
    order: 1;
    margin-bottom: map-get($space-md, 'y');
  }
}
</style>
