import router from 'src/router';
import store from 'src/store';

/**
 * Verifies that the current user has required permissions to access the given route.
 *
 * @param {string} path
 */
export function canAccessRoute(path) {
  const { matched } = router().resolve(path);
  return (matched || []).every(({ meta }) => {
    const routePermissions = meta?.permissions || [];
    return routePermissions.every((permission) => store().getters['session/hasPermission'](permission));
  });
}

/**
 * Returns the asset/vehicle equivalent of the given route name.
 *
 * @param {string} routeName
 * @param {'asset' | 'vehicle'} toType
 * @param {string} tripsRoute
 * @returns
 */
export function getEquivalentRoute(routeName, toType, tripsRoute) {
  switch (routeName) {
    case 'vehicleTrips':
    case 'assetTrips':
    case 'assetLocations':
      return tripsRoute;
    case 'vehicleHealth':
      return toType === 'vehicle' ? 'vehicleHealth' : 'asset';
    case 'vehicleMaintenance':
      return toType === 'vehicle' ? 'vehicleMaintenance' : 'asset';
    case 'asset':
    case 'vehicle':
    default:
      return toType === 'vehicle' ? 'vehicle' : 'asset';
  }
}

/**
 * Retrieves the search context for the current route.
 *
 * @returns {SearchResultType | null}
 */
export function getSearchContext() {
  const matchingRoute = router().currentRoute?.matched?.find((route) => route.meta?.searchContext);
  return matchingRoute?.meta?.searchContext || null;
}
