<template>
  <div class="relative row items-center no-wrap">
    <div ref="sizer" class="chips-sizer absolute-left">
      <q-resize-observer @resize="onResize" />
    </div>
    <div
      ref="chips"
      class="chips row no-wrap"
      :style="{ 'max-width': lockedWidth !== null ? `${lockedWidth}px` : '100%' }"
    >
      <q-chip
        v-for="filter in limitedActiveFilters"
        :key="`${filter.key}_${filter.value}`"
        class="chip"
        color="dark"
        outline
        removable
        text-color="white"
        @remove="remove(filter)"
      >
        {{ filter.label }}
        <ToolTip v-if="filter.tooltip">{{ filter.tooltip }}</ToolTip>
      </q-chip>
    </div>
    <q-btn
      class="show-all q-ml-xs line-height-1 text-no-wrap bg-white"
      :class="{ hidden: (activeFilters.length <= 4 && !chipsHidden) || filtersNotShown === activeFilters.length }"
      color="white"
      dense
      no-caps
      padding="6px 12px 6px 8px"
      square
      text-color="primary"
      @click="$emit('show-all', $event)"
    >
      and {{ filtersNotShown }} more...
    </q-btn>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import { mapActions, mapGetters, mapState } from 'vuex';
import ToolTip from 'components/ToolTip.vue';
import { statusDisplays, HEALTH_STATUS_DISPLAYS } from 'src/services/constants';

export default {
  name: 'MapFiltersActive',
  components: {
    ToolTip,
  },
  data() {
    return {
      chipsHidden: 0,
      lockedWidth: null,
    };
  },
  computed: {
    ...mapState('assets', ['assetTypes']),
    ...mapState('filtering', ['filterOutOfBounds', 'filters', 'filterShared']),
    ...mapState('groups', {
      groupsArray: 'groups',
    }),
    ...mapState('search', ['filterTerms']),
    ...mapGetters('assets', ['tags']),
    ...mapGetters('groups', {
      groups: 'getFlatGroups',
    }),
    activeFilters() {
      const filters = [];
      const filtersByKey = { ...this.filters };

      if (filtersByKey.group.length) {
        filtersByKey.group = this.simplifyGroups(filtersByKey.group);
      }

      Object.keys(filtersByKey).forEach((key) => {
        const values = filtersByKey[key];
        values.forEach((value) => {
          const label = this.getValueLabel(key, value);
          filters.push({
            key,
            label,
            value,
            tooltip: this.getTooltip(key, value, label),
          });
        });
      });

      if (this.filterTerms.length) {
        filters.push({
          key: 'filterTerms',
          label: `containing: "${this.filterTerms}"`,
          value: this.filterTerms,
        });
      }

      if (this.filterOutOfBounds) {
        filters.push({
          key: 'outOfBounds',
          label: 'only in view',
          value: 'Only showing items in view',
        });
      }

      if (this.filterShared) {
        filters.push({
          key: 'shared',
          label: 'only shared/recovery',
          value: 'Only showing shared/recovery items',
        });
      }

      return filters;
    },
    filtersNotShown() {
      return this.activeFilters.length - this.limitedActiveFilters.length + this.chipsHidden;
    },
    limitedActiveFilters() {
      if (this.activeFilters.length < 5) {
        return this.activeFilters;
      }
      return [...Array(4)].map((_, index) => this.activeFilters[index]);
    },
  },
  methods: {
    ...mapActions('filtering', [
      'filterByTerm',
      'setFilteredTerms',
      'setOutOfBoundsFilter',
      'setSharedFilter',
      'updateFilters',
    ]),
    ...mapActions('search', ['updateFilterTerms']),
    chipUpdate() {
      /** @type {HTMLDivElement} */
      const chipsEl = this.$refs.chips;
      /** @type {HTMLDivElement} */
      const sizerEl = this.$refs.sizer;

      if (!sizerEl) {
        return;
      }

      const maxWidth = sizerEl.offsetWidth;

      this.chipsHidden = 0;

      let lockedWidth = maxWidth;
      let lastChipEnd = 0;
      chipsEl.querySelectorAll('.chip').forEach((chip) => {
        const chipStart = chip.offsetLeft;
        const chipEnd = chipStart + chip.offsetWidth;
        if (chipEnd > maxWidth) {
          if (lockedWidth === maxWidth) {
            lockedWidth = lastChipEnd;
          }
          this.chipsHidden += 1;
        }
        lastChipEnd = chipEnd;
      });

      this.lockedWidth = lockedWidth;
    },
    getMatchingTagValue(value) {
      const tag = this.tags.find(({ key }) => key === value);
      if (!tag) {
        return null;
      }
      return tag.value;
    },
    getTooltip(filterName, value, label) {
      switch (filterName) {
        case 'group': {
          const { hierarchy } = this.groups[value] || {};
          return hierarchy && hierarchy.length ? [...hierarchy, label].join(' > ') : null;
        }
        default:
          return null;
      }
    },
    getValueLabel(filterName, value) {
      switch (filterName) {
        case 'assetType':
          if (value === 'vehicle') {
            return value;
          }
          return this.assetTypes[value]?.name;
        case 'group':
          return this.groups[value]?.name || 'Unnamed Group';
        case 'health':
          return HEALTH_STATUS_DISPLAYS[value];
        case 'status':
          return statusDisplays[value];
        case 'tag':
          return this.getMatchingTagValue(value);
        default:
          return value;
      }
    },
    onResize() {
      this.debouncedChipUpdate();
    },
    remove(option) {
      if (option.key === 'filterTerms') {
        this.updateFilterTerms('');
        return;
      }

      if (option.key === 'outOfBounds') {
        this.setOutOfBoundsFilter(false);
        return;
      }

      if (option.key === 'shared') {
        this.setSharedFilter(false);
        return;
      }

      if (option.key === 'group') {
        // Easy part, remove unselected filter
        this.removeFilter('group', option.value);

        // Hard part, remove all of the group's children
        const unselectedGroup = this.groups[option.value];
        const unselectChildren = (groups) => {
          groups.forEach(({ key, children }) => {
            this.removeFilter('group', key);
            unselectChildren(children);
          });
        };
        unselectChildren(unselectedGroup.children);
        return;
      }

      this.removeFilter(option.key, option.value);
    },
    removeFilter(filterType, filterValue) {
      const filters = { ...this.filters };
      filters[filterType] = [...filters[filterType]].filter((value) => value !== filterValue);
      this.updateFilters({ filters });
    },
    /**
     * @param {string[]} groupKeys
     */
    simplifyGroups(groupKeys) {
      function findGroups(groups) {
        return groups.map((group) => {
          if (!group.children?.length) {
            // No children, set selected state
            return {
              ...group,
              selected: groupKeys.includes(group.key),
            };
          }

          const selectedChildren = findGroups(group.children);
          const everyChildSelected = selectedChildren.every(({ selected }) => selected);
          if (everyChildSelected) {
            // Every child is selected, only select parent item and throw out results from children
            return {
              ...group,
              selected: true,
            };
          } else {
            // Not every child is selected, update parent item children with results
            return {
              ...group,
              children: selectedChildren,
            };
          }
        });
      }
      const selectedGroups = findGroups([...this.groupsArray]);

      // Get flat list of selected items
      const simplifiedSelections = [];
      const flattenGroups = (groups) => {
        groups.forEach((group) => {
          if (group.selected) {
            simplifiedSelections.push(group);
          }
          if (group.children) {
            flattenGroups(group.children);
          }
        });
      };
      flattenGroups(selectedGroups);

      return simplifiedSelections.map(({ key }) => key);
    },
  },
  created() {
    this.debouncedChipUpdate = _debounce(() => this.chipUpdate(), 50);
  },
  watch: {
    limitedActiveFilters() {
      this.debouncedChipUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.chips-sizer {
  width: calc(100vw - 450px);
}

.chips {
  overflow: hidden;
}

.chip {
  height: auto;
  max-width: max-content;
  padding: 6px 12px 6px 8px;
  line-height: 1;
}

.show-all {
  :deep(.q-btn__wrapper) {
    min-height: 0;
  }
}
</style>
