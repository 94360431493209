<template>
  <div id="q-app">
    <router-view />
    <div class="hidden">
      <GolfCartIcon id="svg_icon_golf_cart" />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import GolfCartIcon from 'src/assets/golf-cart-icon.svg?component';

export default {
  name: 'App',
  components: { GolfCartIcon },
  methods: {
    ...mapActions('env', ['init']),
  },
  created() {
    if (window.location.hostname.includes('zubie.com')) {
      document.domain = 'zubie.com';
    } else if (window.location.hostname.includes('zubiecar.com')) {
      document.domain = 'zubiecar.com';
    }

    if (!window.location.pathname.startsWith('/frame')) {
      this.init();
    }

    // Redirect old style invite links to correct location. We have to do this
    // in Javascript because the hash fragment is never seen by the server. :-\
    if (!this.$q.platform.is.capacitor && window.location.hash.startsWith('#/invite?token')) {
      window.location.replace(window.location.hash.substring(1));
    }

    // Add iOS padding for Capacitor build (iosStatusBarPadding doesn't work in prod build)
    const { is } = this.$q.platform;
    if (is.ios && is.capacitor && !document.body.classList.contains('q-ios-padding')) {
      document.body.classList.add('q-ios-padding');
    }
  },
};
</script>

<style src="animate.css/animate.min.css"></style>
