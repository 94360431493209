export function setAppConfigDetails(state, config) {
  state.configDetails = config;
}

export function setAppConnectDetails(state, details) {
  state.appConnectDetails = details;
}

export function setApps(state, apps) {
  state.apps = apps;
}

export function setAppViewed(state, appId) {
  state.appViewed = appId;
}

export function setConnectedApps(state, apps) {
  state.connectedApps = apps;
}

export function setLoading(state, isLoading) {
  state.isLoading = isLoading;
}

export function setLoadingConnectedApps(state, isLoading) {
  state.isLoadingConnectedApps = isLoading;
}

export function setSmartcarVins(state, vins) {
  state.smartcarVins = vins;
}

export function setAllowDirectConnect(state, value) {
  state.allowDirectConnect = value;
}
