import {
  matAdjust as adjust,
  matAgriculture as agriculture,
  matAirplanemodeActive as airplanemode_active,
  matAttachMoney as attach_money,
  matAudiotrack as audiotrack,
  matBatteryAlert as battery_alert,
  matBikeScooter as bike_scooter,
  matBuild as build,
  matBusinessCenter as business_center,
  matCamera as camera,
  matCameraAlt as camera_alt,
  matCarCrash as car_crash,
  matCarpenter as carpenter,
  matCategory as category,
  matCenterFocusStrong as center_focus_strong,
  matConstruction as construction,
  matControlCamera as control_camera,
  matDeveloperBoard as developer_board,
  matDirectionsBike as directions_bike,
  matDirectionsBoat as directions_boat,
  matDirectionsCar as directions_car,
  matDone as done,
  matEditLocation as edit_location,
  matEventBusy as event_busy,
  matEventRepeat as event_repeat,
  matEventSeat as event_seat,
  matFastForward as fast_forward,
  matFastRewind as fast_rewind,
  matFlag as flag,
  matFlare as flare,
  matFontDownload as font_download,
  matGpsFixed as gps_fixed,
  matGpsNotFixed as gps_not_fixed,
  matHomeRepairService as home_repair_service,
  matHourglassEmpty as hourglass_empty,
  matInsertEmoticon as insert_emoticon,
  matLabel as label,
  matLaptop as laptop,
  matLens as lens,
  matLocalGasStation as local_gas_station,
  matLocationOn as location_on,
  matLock as lock,
  matMemory as memory,
  matModeOfTravel as mode_of_travel,
  matMonetizationOn as monetization_on,
  matMyLocation as my_location,
  matNavigation as navigation,
  matNearMe as near_me,
  matNearMeDisabled as near_me_disabled,
  matNewLabel as new_label,
  matPersonAdd as person_add,
  matPower as power,
  matPowerOff as power_off,
  matPushPin as push_pin,
  matRouter as router,
  matSecurity as security,
  matSettingsInputAntenna as settings_input_antenna,
  matSettingsRemote as settings_remote,
  matSpeakerPhone as speaker_phone,
  matSpeed as speed,
  matStarRate as star_rate,
  matStars as stars,
  matStop as stop,
  matSupport as support,
  matSwapHoriz as swap_horiz,
  matTour as tour,
  matToys as toys,
  matTwoWheeler as two_wheeler,
  matWeekend as weekend,
  matWhereToVote as where_to_vote,
  matWork as work,
} from '@quasar/extras/material-icons';
import pinAsset from 'src/assets/pin-asset.svg?raw';
import { isLightOrDark } from 'src/services/color';
import { ASSET_TYPES, ASSET_ICONS, ASSET_ICON_MARKER_TRANSFORM, COLOR_VALUES } from 'src/services/constants';
import SvgMarker from 'src/services/svg/SvgMarker';

export const materialIcons = {
  adjust,
  agriculture,
  airplanemode_active,
  attach_money,
  audiotrack,
  battery_alert,
  bike_scooter,
  build,
  business_center,
  camera_alt,
  camera,
  car_crash,
  carpenter,
  category,
  center_focus_strong,
  construction,
  control_camera,
  developer_board,
  directions_bike,
  directions_boat,
  directions_car,
  done,
  edit_location,
  event_busy,
  event_repeat,
  event_seat,
  fast_forward,
  fast_rewind,
  flag,
  flare,
  font_download,
  gps_fixed,
  gps_not_fixed,
  home_repair_service,
  hourglass_empty,
  insert_emoticon,
  label,
  laptop,
  lens,
  local_gas_station,
  location_on,
  lock,
  memory,
  mode_of_travel,
  monetization_on,
  my_location,
  navigation,
  near_me_disabled,
  near_me,
  new_label,
  person_add,
  power_off,
  power,
  push_pin,
  router,
  security,
  settings_input_antenna,
  settings_remote,
  speaker_phone,
  speed,
  star_rate,
  stars,
  stop,
  support,
  swap_horiz,
  tour,
  toys,
  two_wheeler,
  weekend,
  where_to_vote,
  work,
};

export default class AssetSvgMarker extends SvgMarker {
  static PIN_SVG = `<g>
    <g transform="translate(2,2)">
      <path id="pin" d="M28 84C34.553993 64.751295 39.198861 54.333741 41.934604 52.747339 50.339874 47.873291 56 38.725358 56 28.243916 56 12.645232 43.463973 0 28 0 12.536027 0 0 12.645232 0 28.243916 0 38.958397 5.9146121 48.279412 14.630069 53.066069 17.2787 54.520736 21.735344 64.832046 28 84Z" stroke="${COLOR_VALUES.WHITE}" stroke-width="3" fill="${COLOR_VALUES.PRIMARY}"></path>
    </g>
  </g>`;

  static OUTLINE_SVG = `<g>
    <g transform="translate(2,2)">
      <path fill="${COLOR_VALUES.WHITE}" stroke-width="4" stroke="${COLOR_VALUES.DARK}" d="M28 84C34.553993 64.751295 39.198861 54.333741 41.934604 52.747339 50.339874 47.873291 56 38.725358 56 28.243916 56 12.645232 43.463973 0 28 0 12.536027 0 0 12.645232 0 28.243916 0 38.958397 5.9146121 48.279412 14.630069 53.066069 17.2787 54.520736 21.735344 64.832046 28 84Z"></path>
    </g>
  </g>`;

  /** @type {String} */
  iconUri = null;

  constructor(iconUri) {
    super(pinAsset, {
      height: 91,
      width: 60,
      innerColor: COLOR_VALUES.WHITE,
      outerColor: COLOR_VALUES.PRIMARY,
    });
    this.iconUri = iconUri;
  }

  /**
   * Creates and loads an HTML Image from the SVG.
   *
   * @param {String} colorOverride
   * @param {Boolean} outline
   * @returns {Promise<Image>}
   */
  toImage(colorOverride, outline) {
    return new Promise((resolve) => {
      const iconUri = this.iconUri || ASSET_ICONS[ASSET_TYPES.ASSET];
      let iconSvg;

      // // Handle marker colors
      let iconColor = 'white';
      if (colorOverride && colorOverride !== 'none') {
        iconColor = isLightOrDark(colorOverride) === 'light' ? 'black' : 'white';
      }

      if (iconUri.startsWith('component:')) {
        // do component thing
        iconSvg = AssetSvgMarker.getSvgFromComponent(iconUri, iconColor);
      } else {
        iconSvg = AssetSvgMarker.getSvgFromShortCode(iconUri, iconColor);
      }

      const markerSvg = AssetSvgMarker.createPin(iconUri, iconSvg, colorOverride, outline);

      const image = new Image(this.width, this.height);
      image.onload = () => {
        resolve(image);
      };
      image.onerror = () => {
        console.error(`Invalid SVG: ${image.src}`);
      };

      image.src = `data:image/svg+xml;base64,${btoa(markerSvg)}`;
    });
  }

  /**
   * Creates a map pin using the given icon, color, and outline.
   *
   * @param {String} iconUri
   * @param {String} iconSvg
   * @param {String} colorOverride
   * @param {Boolean} outline
   * @returns {String}
   */
  static createPin(iconUri, iconSvg, colorOverride, outline) {
    // <svg>
    const svgEl = document.createElement('svg');
    svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svgEl.setAttribute('viewbox', '0 0 60 91');
    svgEl.setAttribute('height', '91px'); // needed for Firefox
    svgEl.setAttribute('width', '60px'); // needed for Firefox

    // outline <svg>
    if (outline) {
      const outlineSvg = document.createElement('svg');
      outlineSvg.innerHTML = AssetSvgMarker.OUTLINE_SVG;
      svgEl.appendChild(outlineSvg.childNodes[0]);
    }

    // pin <svg>
    const pinSvg = document.createElement('svg');
    pinSvg.innerHTML = AssetSvgMarker.PIN_SVG;
    svgEl.appendChild(pinSvg.childNodes[0]);

    // Handle marker colors
    if (colorOverride && colorOverride !== 'none') {
      svgEl.querySelector('#pin').setAttribute('fill', colorOverride);
    }

    // <g>
    const g = document.createElement('g');
    let svgStyle = { scale: 1.8, translate: { x: 4.6, y: 4.6 } };
    if (iconUri.startsWith('component:')) {
      svgStyle = ASSET_ICON_MARKER_TRANSFORM[iconUri.substring('component:'.length)];
    }
    g.setAttribute(
      'style',
      `transform:scale(${svgStyle.scale}) translate(${svgStyle.translate.x}px, ${svgStyle.translate.y}px)`
    );

    // Inject icon SVG
    g.innerHTML = iconSvg;

    svgEl.appendChild(g);

    return svgEl.outerHTML;
  }

  /**
   * Uses the given icon shortcode to create a colorized SVG.
   *
   * @param {String} iconUri
   * @param {"white" | "black"} iconColor
   * @returns {String}
   */
  static getSvgFromShortCode(iconUri, iconColor = 'white') {
    const iconCode = materialIcons[iconUri];
    const [def] = iconCode.split('|');

    const g = document.createElement('g');

    def.split('&&').forEach((path) => {
      const [d, style, transform] = path.split('@@');

      // <path>
      const pathEl = document.createElement('path');
      pathEl.setAttribute('d', d);
      pathEl.setAttribute('fill', iconColor);

      if (style) {
        pathEl.setAttribute('style', style);
      }

      if (transform) {
        pathEl.setAttribute('transform', transform);
      }

      g.appendChild(pathEl);
    });

    return g.innerHTML;
  }

  /**
   * Uses the given icon component to create a colorized SVG.
   *
   * @param {String} iconUri
   * @param {"white" | "black"} iconColor
   * @returns {String}
   */
  static getSvgFromComponent(iconUri, iconColor) {
    const componentName = iconUri.substring('component:'.length);
    const componentHtml = document.getElementById(`svg_icon_${componentName}`).innerHTML;

    // Colorize SVG
    const svgHtml = componentHtml.replace(/fill="currentColor"/gi, `fill="${iconColor}"`);

    // Return converted HTML
    return svgHtml;
  }
}
