<template>
  <q-badge v-if="filterCount" class="badge row justify-center" color="dark" floating text-color="white">{{
    filterCount
  }}</q-badge>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FiltersBadge',
  computed: {
    ...mapGetters('filtering', ['filterCount']),
  },
};
</script>

<style lang="scss" scoped>
.badge {
  height: 1em;
  padding: 2px;
  font-size: 14px;
}
</style>
