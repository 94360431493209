import { dayjs } from 'src/services/date';

/**
 * Returns the subscription with the closest period end out of all dunning subscriptions.
 *
 * @param {SubscriptionsStoreState} state
 * @returns {SubscriptionData | null}
 */
export function closestDunningSub(state) {
  let closestPeriodEnd = null;
  let closestSubscription = null;

  state.subscriptions.forEach((subscription) => {
    const { currentPeriodEndsAt, status } = subscription;

    if (status !== 'dunning') {
      return;
    }

    const subscriptionPeriod = dayjs(currentPeriodEndsAt);
    if (closestPeriodEnd === null || dayjs(currentPeriodEndsAt).isBefore(closestPeriodEnd)) {
      closestPeriodEnd = subscriptionPeriod;
      closestSubscription = subscription;
    }
  });

  return closestSubscription;
}
