import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import Asset from 'src/models/Asset';
import AssetHistoryCluster from 'src/models/AssetHistoryCluster';
import AssetType from 'src/models/AssetType';
import Tag from 'src/models/Tag';
import Vehicle from 'src/models/Vehicle';
import chain from 'src/services/chain';
import { statuses } from 'src/services/constants';
import { dayjs } from 'src/services/date';

/**
 * Returns all assets as Asset objects.
 * - also associates assetTypeKey with Asset.type
 *
 * @param {Object} state
 * @param {Object} getters
 * @param {Object} rootState
 * @param {Object} rootGetters
 */
export function assets(state, getters, rootState, rootGetters) {
  // Check if user can view assets before doing anything
  if (!rootGetters['session/hasPermission']('assetView')) {
    return [];
  }

  const allGroups = rootGetters['groups/getFlatGroups'];
  const allTags = rootGetters['tags/allTags'];

  return Object.values(state.assets).map((asset) => {
    const type = _find(getters.assetTypes, { key: asset.assetTypeKey });

    let groups;
    if (!_isEmpty({ ...allGroups })) {
      // Note: must spread allGroups to prevent getter from ignoring changes
      groups = asset.groupKeys.map((groupKey) => allGroups[groupKey]);
    }

    let assetTags;
    if (!_isEmpty([...allTags])) {
      // Note: must spread allTags to prevent getter from ignoring changes
      assetTags = asset.tagKeys.map((tagKey) => _find(allTags, { key: tagKey }));
    }

    return new Asset({
      ...asset,
      groups,
      tags: assetTags,
      type,
    });
  });
}

/**
 * Returns an object of device keys (as the keys) and their associated asset.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Object.<string, Asset|Vehicle>}
 */
export function assetsByDeviceKey(_, getters) {
  const assetsObj = {};

  [...getters.vehicles, ...getters.assets].forEach((asset) => {
    const { deviceKey, devices } = asset;

    if (!devices.length) {
      return;
    }

    if (deviceKey) {
      assetsObj[deviceKey] = asset;
    } else {
      devices.forEach((device) => {
        assetsObj[device.key] = asset;
      });
    }
  });

  return assetsObj;
}

/**
 * Returns all assets stored in a key-value pair by assetTypeKey.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Object.<string, Asset>}
 */
export function assetsByType(state, getters) {
  const assetsObj = {};

  getters.assets.forEach((asset) => {
    const { assetTypeKey } = asset;
    const currentAssets = assetsObj[assetTypeKey] || [];
    assetsObj[assetTypeKey] = [...currentAssets, asset];
  });

  return assetsObj;
}

/**
 * Returns all assets and vehicles (combined).
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Array<Asset|Vehicle>}
 */
export function assetsVehicles(state, getters) {
  return [...getters.vehicles, ...getters.assets];
}

/**
 * Returns all asset types as AssetType objects.
 *
 * @param {Object} state
 * @returns {AssetType[]}
 */
export function assetTypes(state) {
  return Object.values(state.assetTypes).map((assetType) => new AssetType(assetType));
}

/**
 * Returns true if any visible asset has a YMM value.
 *
 * @param {import('src/types/assets-store').AssetsStoreState} state
 * @param {import('src/types/assets-store').AssetsStoreGetters} getters
 */
export function hasVisibleAssetsWithYmm(_, getters) {
  return getters.visibleAssets.some((asset) => asset.yearMakeModel);
}

/**
 * Returns all visible assets/vehicles that are also mappable.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Array<Vehicle|Asset>}
 */
export function mappableAssets(state, getters) {
  return getters.visibleAssets.filter((asset) => asset.isMappable);
}

/**
 * Returns all mappable assets that are Vehicles.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Vehicle[]}
 */
export function mappableVehicles(state, getters) {
  return getters.mappableAssets.filter((asset) => asset instanceof Vehicle);
}

/**
 * Returns the selected Asset or Vehicle.
 *
 * @param {Object} state
 * @param {Object} getters
 * @param {Object} rootState
 * @returns {Asset|Vehicle}
 */
export function selectedAsset(state, getters, rootState) {
  const key = state.selectedKey;

  if (!key) {
    return new Asset();
  }

  if (state.assets[key]) {
    return new Asset(state.assets[key]);
  }

  return rootState.vehicles.vehicles[state.selectedKey] || new Asset();
}

/**
 * Returns the selected Asset or Vehicle address.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {import('src/models/Address').default}
 */
export function selectedAssetAddress(state, getters) {
  return getters.selectedAsset.lastAddress;
}

/**
 * Returns all vehicles (from vehicles store) as Vehicle objects.
 *
 * @param {Object} state
 * @param {Object} getters
 * @param {Object} rootState
 * @returns {Vehicle[]}
 */
export function vehicles(state, getters, rootState) {
  const rootStateVehicles = rootState?.vehicles?.vehicles || {};
  return Object.values(rootStateVehicles);
}

/**
 * Returns all visible assets/vehicles.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Array<Vehicle|Asset>}
 */
export function visibleAssets(_, getters) {
  return getters.assetsVehicles
    .filter((asset) => asset.isVisible && !asset.isOutOfBounds)
    .sort((vehicle1, vehicle2) => {
      const nickname1 = vehicle1.nickname || '';
      const nickname2 = vehicle2.nickname || '';
      return nickname1.toLowerCase() < nickname2.toLowerCase() ? -1 : 1;
    });
}

/**
 * Returns all visible assets/vehicles that are also in bounds.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Array<Vehicle|Asset>}
 */
export function visibleAssetsInBounds(state, getters) {
  return getters.visibleAssets
    .filter((asset) => !asset.isOutOfBounds)
    .sort((vehicle1, vehicle2) => {
      const nickname1 = vehicle1.nickname || '';
      const nickname2 = vehicle2.nickname || '';
      return nickname1.toLowerCase() < nickname2.toLowerCase() ? -1 : 1;
    });
}

/**
 * Returns all mappable vehicles, filtered, but including vehicles that do not match the current search terms.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Vehicle[]}
 */
export function visibleVehiclesWithoutTerm(state, getters) {
  return getters.mappableVehicles.filter((vehicle) => vehicle.isVisibleWithoutTerm);
}

/**
 * Returns the current location history as AssetHistoryCluster
 *
 * @param {Object} state
 * @returns {AssetHistoryCluster[]}
 */
export function locations(state) {
  const locs = state.locationHistory.map(
    (loc) =>
      new AssetHistoryCluster({
        ...loc,
      })
  );

  return locs;
}

/**
 * Returns locations in the current location history that are selected
 *
 * @param {Object} state
 * @returns {AssetHistoryCluster[]}
 */
export function selectedLocations(state, getters) {
  return getters.locations.filter((loc) => loc.selected === true);
}

export function selectedLocationsGeoJson(state, getters) {
  const features = getters.selectedLocations.map((loc) => loc.toGeoJson());
  return {
    type: 'geojson',
    lineMetrics: true,
    data: {
      type: 'FeatureCollection',
      features,
      id: 'locations',
    },
    promoteId: 'key',
  };
}

/**
 * Returns whether all locations in the current location history are selected
 *
 * @param {Object} state
 * @returns {Boolean}
 */
export function areAllLocationsSelected(state, getters) {
  return getters.locations.every((loc) => loc.selected === true);
}

/**
 * Returns the location corresponding to state.hoveredLocationHistoryId
 *
 * @param {Object} state
 * @returns {AssetHistoryCluster}
 */
export function focusedLocation(state, getters) {
  const location = getters.selectedLocations.find(({ key }) => key === state.hoveredLocationHistoryId);
  if (location) {
    return {
      events: [],
      key: location.key,
      latitude: location.point.lat,
      longitude: location.point.lon,
      timestampEarliest: location.timestampEarliest,
      timestampLatest: location.timestampLatest,
      timestampUtc: dayjs.utc(location.timestamp).toISOString(),
    };
  }
  return null;
}

/**
 * Returns all unique tags being used by both assets and vehicles.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Tag[]}
 */
export function tags(_, getters) {
  return chain(getters.assetsVehicles)
    .filter((asset) => asset.tags)
    .map('tags')
    .flatten()
    .filter((tag) => tag.value)
    .uniqBy('value')
    .sortBy('value')
    .map((tag) => new Tag(tag))
    .value();
}

/**
 * Returns vehicles grouped by driver (vehicles without drivers excluded).
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Object.<string, Vehicle>}
 */
export function vehiclesByDriver(state, getters) {
  /** @type {Object.<string, Vehicle>} */
  const groupedVehicles = {};

  getters.vehicles.forEach(
    /** @param {Vehicle} vehicle */
    (vehicle) => {
      if (vehicle.driver.key) {
        groupedVehicles[vehicle.driver.key] = vehicle;
      }
    }
  );

  return groupedVehicles;
}

/**
 * Returns an object of statuses that are "true" if any vehicle status matches.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Object.<string, Boolean>}
 */
export function vehicleStatuses(state, getters) {
  const activeStatuses = {
    [statuses.DISCONNECTED]: false,
    [statuses.NO_DEVICE]: false,
    [statuses.NO_GPS]: false,
    [statuses.OBSOLETE]: false,
    [statuses.OFFLINE]: false,
    [statuses.POWERED_DOWN]: false,
  };

  const statusKeys = Object.keys(activeStatuses);
  getters.vehicles.some(({ status }) => {
    const index = statusKeys.indexOf(status);
    if (index > -1) {
      activeStatuses[status] = true;
      // Remove status to indicate it was found
      statusKeys.splice(index, 1);
      // returns true and exits loop if all statuses are found
      return Boolean(!statusKeys.length);
    }
    return false;
  });

  return activeStatuses;
}

/**
 * Returns "true" if there are any "disconnected" vehicle statuses.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Boolean}
 */
export function hasDisconnectedVehicles(state, getters) {
  return getters.vehicleStatuses[statuses.DISCONNECTED];
}

/**
 * Returns "true" if there are any "no device" vehicle statuses.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Boolean}
 */
export function hasNoDeviceVehicles(state, getters) {
  return getters.vehicleStatuses[statuses.NO_DEVICE];
}

/**
 * Returns "true" if there are any "no GPS" vehicle statuses.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Boolean}
 */
export function hasNoGpsVehicles(state, getters) {
  return getters.vehicleStatuses[statuses.NO_GPS];
}

/**
 * Returns "true" if there are any "offline" vehicle statuses.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Boolean}
 */
export function hasOfflineVehicles(state, getters) {
  return getters.vehicleStatuses[statuses.OFFLINE];
}

/**
 * Returns "true" if there are any "powered down" vehicle statuses.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Boolean}
 */
export function hasPoweredDownVehicles(state, getters) {
  return getters.vehicleStatuses[statuses.POWERED_DOWN];
}

/**
 * Returns "true" if there are any vehicles with obsolete devices.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Boolean}
 */
export function hasObsoleteDeviceVehicles(state, getters) {
  return getters.vehicleStatuses[statuses.OBSOLETE];
}
