<template>
  <q-menu
    ref="menu"
    anchor="bottom right"
    :auto-close="false"
    fit
    max-height="40vh"
    :max-width="$q.screen.width > 440 ? '300px' : 'calc(100vw - 133px)'"
    :model-value="show"
    no-focus
    no-parent-event
    no-refocus
    self="top right"
  >
    <q-list ref="list">
      <q-item v-if="searchResultsLoading" class="items-center">
        <q-item-label class="text-grey-7"><q-spinner class="q-mr-xs" /> Loading</q-item-label>
      </q-item>
      <q-item v-else-if="!searchResults.length" class="items-center">
        <q-item-label class="text-grey-7">Begin typing to search for a location</q-item-label>
      </q-item>
      <q-item
        v-for="(result, index) in searchResults"
        :key="index"
        :active="index === selectedSearchResultIndex"
        active-class="map-search-control__selected-result bg-blue-2 text-dark"
        class="column"
        clickable
        @click="onClick($event, index)"
      >
        <q-item-label>{{ result.name }}</q-item-label>
        <q-item-label caption>{{ getExternalType(result.type) }}</q-item-label>
      </q-item>
    </q-list>
  </q-menu>
</template>

<script>
import _capitalize from 'lodash/capitalize';
import { mapActions, mapState } from 'vuex';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  name: 'SearchControlResults',
  props: {
    show: Boolean,
  },
  computed: {
    ...mapState('map', ['searchResults', 'searchResultsLoading', 'selectedSearchResultIndex']),
  },
  methods: {
    ...mapActions('map', ['selectSearchResult']),
    getExternalType(type) {
      if (type === 'poi') {
        return 'Point of Interest';
      }
      return _capitalize(type);
    },
    onClick(event, index) {
      this.selectSearchResult(index);
      this.$emit('click', event);
    },
  },
  watch: {
    async selectedSearchResultIndex() {
      await setTimeoutPromise(1);
      const selectedResult = document.querySelector('.map-search-control__selected-result');
      const scrollableList = this.$refs.list?.$el?.parentNode;

      if (selectedResult && scrollableList) {
        const { offsetHeight: listHeight, scrollTop: viewportTop } = scrollableList;
        const viewportBottom = listHeight + viewportTop;
        const { offsetTop: resultTop, offsetHeight: resultHeight } = selectedResult;
        const resultBottom = resultTop + resultHeight;

        let scrollToTop;
        if (resultBottom > viewportBottom) {
          scrollToTop = resultBottom - viewportBottom + viewportTop;
        } else if (resultTop < viewportTop) {
          scrollToTop = resultTop;
        }

        if (scrollToTop !== viewportTop) {
          scrollableList.scrollTo({
            top: scrollToTop,
            behavior: 'smooth',
          });
        }
      }
    },
  },
};
</script>
