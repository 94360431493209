/**
 * Sorts a given array by the given property, lowercased.
 *
 * @param {T[]} array
 * @param {string} property
 */
function sortByLower(array, property) {
  return [...array].sort((item1, item2) => {
    const value1 = item1[property] || '';
    const value2 = item2[property] || '';
    return value1.toLowerCase() > value2.toLowerCase() ? 1 : -1;
  });
}

export { sortByLower };
