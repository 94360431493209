export function isLightOrDark(hexColor) {
  const color = +`0x${hexColor.slice(1).replace(hexColor.length < 5 && /./g, '$&$&')}`;

  const red = color >> 16;
  const green = (color >> 8) & 255;
  const blue = color & 255;

  // HSP (Hue, Saturation, "Perceived brightness") equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue));

  return hsp > 127.5 ? 'light' : 'dark';
}
