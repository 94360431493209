import GeoJsonLayer from 'src/services/geoJson/GeoJsonLayer';
import NearbyLocationSource from 'src/services/geoJson/sources/NearbyLocationSource';

export default class NearbyLocationLayer extends GeoJsonLayer {
  /** @type {String} */
  static id = 'nearby-location';

  /**
   * @returns {Object} A GeoJson layer config object.
   */
  getConfig() {
    return Object.freeze({
      id: this.getId(),
      type: 'symbol',
      source: NearbyLocationSource.id,
      layout: {
        'text-size': ['interpolate', ['linear'], ['zoom'], 4.9, 0, 5, 10, 16, 13],
        'text-radial-offset': 1.2,
        'text-variable-anchor': ['bottom', 'top'],
        'icon-image': 'nearby-location',
        'symbol-avoid-edges': true,
        'text-font': ['Open Sans SemiBold', 'Arial Unicode MS Regular'],
        'icon-size': 0.4,
        'text-anchor': 'bottom',
        'text-field': '{address}',
        'icon-padding': 0,
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'icon-offset': [0, -15],
      },
      paint: {
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
        'text-color': 'hsl(189, 0%, 37%)',
      },
    });
  }
}
