<template>
  <MappedItemButton
    v-bind="$attrs"
    bg-color="info"
    label="Track Device"
    :size="size"
    text-color="dark"
    @click.stop="showDialog = true"
  >
  </MappedItemButton>
  <TrackDeviceDialog v-if="showDialog" :vehicle="vehicle" @hide="showDialog = false" />
</template>

<script>
import MappedItemButton from 'components/map/MappedItemButton.vue';
import TrackDeviceDialog from 'components/track-device/TrackDeviceDialog.vue';
import Vehicle from 'src/models/Vehicle';

export default {
  name: 'TrackDeviceButton',
  props: {
    size: {
      required: true,
      type: Number,
    },
    vehicle: {
      required: true,
      type: Vehicle,
    },
  },
  components: {
    MappedItemButton,
    TrackDeviceDialog,
  },
  data() {
    return {
      showDialog: false,
    };
  },
};
</script>
