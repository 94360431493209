<template>
  <div>
    <div v-if="errorMessage" class="bg-negative text-white q-pa-md q-mb-lg">{{ errorMessage }}</div>
    <div class="q-gutter-md">
      <div class="form-fields-wrapper">
        <div class="row" :class="{ 'no-wrap': $q.screen.gt.xs, 'justify-center': !$q.screen.gt.xs }">
          <div class="full-width">
            <q-input
              ref="nickname"
              v-model="vehicle.nickname"
              class="q-mb-md"
              :error="Boolean(errors.nickname)"
              :error-message="errors.nickname ? errors.nickname[0] : validations[0]('')"
              filled
              hide-bottom-space
              label="Nickname"
              lazy-rules
              :readonly="!allowEditVehicle && !isViewerAndDriver"
              :rules="validations"
              @update:model-value="
                onChange($event);
                errors.nickname = false;
              "
            >
              <template #after>
                <ScannerButton @scan="(event, value) => onScan(event, 'nickname', value)" />
              </template>
            </q-input>
            <q-input
              ref="vin"
              v-model="vehicle.vin"
              class="q-mb-md"
              :class="{
                'has-hint': vehicle.key && !vehicle.isVinEditable,
              }"
              :error="Boolean(errors.vin)"
              :error-message="errors.vin ? errors.vin[0] : validations[0]('')"
              filled
              hide-bottom-space
              :hint="vehicle.key && !vehicle.isVinEditable ? 'Reported by vehicle and cannot be edited' : undefined"
              label="VIN"
              lazy-rules
              :readonly="(!allowEditVehicle && !isViewerAndDriver) || (vehicle.key && !vehicle.isVinEditable)"
              :rules="validations"
              @update:model-value="
                onChange($event);
                errors.vin = false;
              "
            >
              <template #after>
                <ScannerButton
                  v-if="!vehicle.key || vehicle.isVinEditable"
                  validation="vin"
                  @scan="(event, value) => onScan(event, 'vin', value)"
                />
              </template>
            </q-input>
          </div>
          <AssetPicture
            v-if="vehicleKey"
            :class="{
              'q-ml-md col-auto column': $q.screen.gt.xs,
              'row justify-center q-mb-md': !$q.screen.gt.xs,
            }"
            :image-url="vehicle.imageUrl"
            :major-type="ASSET_TYPES.VEHICLE"
            :readonly="!allowEditVehicle && !isViewerAndDriver"
            :type="ASSET_TYPES.VEHICLE"
            @remove="
              vehicle.imageUrl = '';
              onChange($event);
            "
            @update="
              vehicle.imageUrl = $event;
              $forceUpdate();
              onChange($event);
            "
          />
        </div>

        <VehicleStyleField
          v-if="vehicleKey"
          class="q-mb-md"
          :readonly="!allowEditVehicle && !isViewerAndDriver"
          :vehicle="vehicle"
          @update:model-value="
            ({ style, hasInitialized }) => {
              vehicle.styleId = style.vehicleId;
              vehicle.styleProvider = 'dataone';

              if (hasInitialized) {
                onChange($event);
              }
            }
          "
        />
        <VehiclePlateField
          ref="licensePlate"
          class="q-mb-md"
          :errors="errors"
          :readonly="!allowEditVehicle && !isViewerAndDriver"
          :vehicle="vehicle"
          @clear-error="clearError"
          @update:model-value="onFieldChange"
        />
        <q-input
          v-model="vehicle.odometer"
          :class="{ 'q-mb-md': !vehicle.odometerIsActual && !simple }"
          :disable="vehicle.odometerIsActual"
          filled
          :hint="vehicle.odometerIsActual ? 'Reported by vehicle and cannot be edited' : undefined"
          label="Odometer"
          :readonly="!allowEditVehicle && !isViewerAndDriver"
          @update:model-value="onChange($event)"
        />
        <q-select
          v-if="!simple"
          v-model="vehicle.primaryDriverKey"
          v-capacitor-native-select
          class="q-mb-md"
          display-value-sanitize
          emit-value
          filled
          for="editVehiclePrimaryDriver"
          :hide-dropdown-icon="!allowEditVehicle && !isViewerAndDriver"
          :hint="driverHint"
          label="Primary Driver"
          map-options
          :options="driversSelectOptions"
          options-sanitize
          :readonly="!allowEditVehicle && !isViewerAndDriver"
          @update:model-value="onChange($event)"
        />
        <ZubieSelect
          v-if="hasPermission('tagListView') && !simple"
          v-model="vehicle.tagKeys"
          class="q-mb-md"
          display-value-sanitize
          emit-value
          filled
          for="editVehicleTags"
          :hide-dropdown-icon="!allowEditVehicle && !isViewerAndDriver"
          label="Tags"
          map-options
          multiple
          option-label="value"
          option-value="key"
          :options="vehicleTagsTaggable"
          options-sanitize
          :readonly="!allowEditVehicle && !isViewerAndDriver"
          tooltip="Selected Tags"
          use-chips
          @update:model-value="onChange($event)"
        >
          <template #selected-item="scope">
            <TagChip
              dense
              :removable="allowEditVehicle || isViewerAndDriver"
              :tabindex="scope.tabindex"
              :tag="scope.opt"
              @remove="vehicle.tagKeys.splice(scope.index, 1)"
            />
          </template>
        </ZubieSelect>
        <!--
              Intentionally chose to not allow group changes for non-Admins
              to avoid the complexity of that action.
            -->
        <TreeViewMultiSelect
          v-if="hasPermission('groupListView') && !simple"
          v-model:value="vehicle.groupKeys"
          :data-tree="groupsWithAccount"
          filled
          for-id="editVehicleGroup"
          :hide-dropdown-icon="!allowEditVehicle"
          hint="Restricts visibility of this vehicle by assigning a group below the top-level account."
          label="Group"
          node-label-key="name"
          node-value-key="key"
          :readonly="!allowEditVehicle"
          :single-selection="true"
          @change="
            (value) => {
              vehicle.groupKeys = value;
              onChange(value);
            }
          "
        />

        <PermittedDrivingHours
          v-if="!simple"
          class="q-mb-md"
          for-id="editVehicleDrivingHours"
          :model-value="vehicle.scheduleKey"
          :readonly="!hasPermission('vehicleScheduleCreate') && !isViewerAndDriver"
          @update:model-value="
            vehicle.scheduleKey = $event;
            onChange($event);
          "
        />

        <FuelEconomyField
          v-if="!simple"
          :economy="vehicle.fuelEconomy ? Math.round(vehicle.fuelEconomy) : Math.round(vehicle.styleFuelEconomy)"
          :economy-um="vehicle.fuelEconomy ? vehicle.fuelEconomyUm : vehicle.styleFuelEconomyUm"
          :readonly="!allowEditVehicle && !isViewerAndDriver"
          :type="vehicle.fuelType || vehicle.styleFuelType"
          @update:model-value="
            onFuelFieldChange($event);
            onChange($event);
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import { mapGetters, mapActions, mapState } from 'vuex';
import AssetPicture from 'components/assets/AssetPicture.vue';
import TagChip from 'components/chips/TagChip.vue';
import ScannerButton from 'components/scanner/ScannerButton.vue';
import TreeViewMultiSelect from 'components/TreeViewMultiSelect.vue';
import FuelEconomyField from 'components/vehicles-page/FuelEconomyField.vue';
import PermittedDrivingHours from 'components/vehicles-page/PermittedDrivingHours.vue';
import VehiclePlateField from 'components/vehicles-page/VehiclePlateField.vue';
import VehicleStyleField from 'components/vehicles-page/VehicleStyleField.vue';
import ZubieSelect from 'components/ZubieSelect.vue';
import Vehicle from 'src/models/Vehicle';
import { ASSET_TYPES, ASSET_TYPE_NAMES } from 'src/services/constants';
import { utcToLocalTimestamp } from 'src/services/date';
import { hasValueFactory } from 'src/services/forms';
import { hasDriverCheckin, validateLicensePlateExpiration } from 'src/services/vehicles';

const DEFAULT_VEHICLE_ODOMETER_UM = 'mi';

export default {
  name: 'VehicleForm',
  props: {
    simple: Boolean,
    vehicleKey: {
      type: String,
      default: null,
    },
  },
  emits: ['change', 'error', 'ready', 'saved', 'saving', 'update:model-value'],
  components: {
    AssetPicture,
    FuelEconomyField,
    PermittedDrivingHours,
    ScannerButton,
    TagChip,
    TreeViewMultiSelect,
    VehiclePlateField,
    VehicleStyleField,
    ZubieSelect,
  },
  computed: {
    ...mapGetters('groups', ['groupsWithAccount']),
    ...mapGetters('session', ['allowEditVehicle', 'currentUser', 'hasPermission']),
    ...mapGetters('tags', ['vehicleTagsTaggable']),
    ...mapGetters('users', ['usersAsArray']),
    ...mapState('vehicles', ['detailedVehicles']),
    ASSET_TYPES() {
      return ASSET_TYPES;
    },
    ASSET_TYPE_NAMES() {
      return ASSET_TYPE_NAMES;
    },
    driverHint() {
      if (this.hasDriverCheckin) {
        const { checkInExpiration, fullName } = this.vehicle.driver;
        return `${fullName} is checked in to this vehicle until ${utcToLocalTimestamp(checkInExpiration)}`;
      }
      // the different return types are annoying here, but QSelect renders an empty hint if given anything (incl. null) but undefined
      return undefined;
    },
    driversSelectOptions() {
      const options = this.usersAsArray.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.key,
      }));
      options.sort((a, b) => (a.label > b.label ? 1 : -1));
      options.unshift({
        label: 'Unassigned',
        value: '',
      });
      return options;
    },
    hasDriverCheckin() {
      return hasDriverCheckin(this.vehicle.driver);
    },
    isViewerAndDriver() {
      return this.currentUser.isViewer() && this.originalDriverKey === this.currentUser.key;
    },
    originalVehicle() {
      if (this.vehicleKey) {
        return this.detailedVehicles[this.vehicleKey];
      }
      return null;
    },
    title() {
      if (this.allowEditVehicle || this.isViewerAndDriver) {
        return this.vehicleKey ? 'Edit Vehicle' : 'Add Vehicle';
      }
      return 'Vehicle Settings';
    },
    validations() {
      return [hasValueFactory()];
    },
  },
  data() {
    return {
      errors: {},
      errorMessage: '',
      newImage: null,
      originalDriverKey: null,
      vehicle: new Vehicle(),
    };
  },
  methods: {
    ...mapActions('groups', ['getGroups']),
    ...mapActions('users', ['getUsers']),
    ...mapActions('tags', ['getTags']),
    ...mapActions('vehicles', ['getDetailedVehicle', 'saveVehicle']),
    ...mapActions('vehicleStyle', ['clearStyle']),
    clearError(property) {
      this.errors = {
        ...this.errors,
        [property]: undefined,
      };
    },
    async save() {
      this.errors = {};
      this.errorMessage = '';

      this.$emit('saving', true);

      const requiredFields = [this.$refs.nickname, this.$refs.vin];
      const validationResults = requiredFields.map((field) => ({
        field,
        result: field.validate(),
      }));
      const fieldErrors = validationResults.filter(({ result }) => !result);
      if (fieldErrors.length) {
        this.$emit('saving', false);
        const [firstError] = fieldErrors;
        firstError.field.$el.scrollIntoView();
        firstError.field.focus();
        return null;
      }

      // schedule, fuel not implemented :(
      const payload = _pick(
        this.vehicle,
        'fuelEconomy',
        'fuelEconomyUm',
        'fuelType',
        'groupKeys',
        'imageUrl',
        'nickname',
        'plateExpiration',
        'plateJurisdiction',
        'plateNumber',
        'tagKeys',
        'vin'
      );

      // Validate license plate (if provided)
      if (payload.plateExpiration) {
        const { error, isValid, expiration } = validateLicensePlateExpiration(payload.plateExpiration);
        if (!isValid) {
          this.errors.plateExpiration = [error];
          this.$emit('saving', false);
          this.$refs.licensePlate?.show();
          this.$refs.licensePlate?.$el?.scrollIntoView();
          return null;
        }
        payload.plateExpiration = expiration.format('YYYY-MM');
      }

      // create vs. edit vehicle end points take different property names
      // new vehicle
      if (!this.vehicleKey) {
        payload.odometer = this.vehicle.odometer;
        payload.odometerUm = this.vehicle.odometerUm;

        // Set the primary driver if one is selected
        if (this.vehicle.primaryDriverKey) {
          payload.primaryDriverKey = this.vehicle.primaryDriverKey;
        }

        // create vehicle API doesn't accept empty schedule keys, so only include if set
        if (this.vehicle.scheduleKey) {
          payload.scheduleKey = this.vehicle.scheduleKey;
        }
      } else {
        payload.key = this.vehicleKey;
        payload.vehiclePrimaryUserKey = this.vehicle.primaryDriverKey;
        // only post odometer info if it has changed b/c saving it turns off true odometer until the next trip
        if (
          this.originalVehicle.odometer !== this.vehicle.odometer ||
          this.originalVehicle.odometerUm !== this.vehicle.odometerUm
        ) {
          payload.vehicleOdometer = this.vehicle.odometer;
          payload.vehicleOdometerUm = this.vehicle.odometerUm;
        }

        // only post zubieStyleId if someone has edited the vehicle's style
        if (this.vehicle.styleId !== this.originalVehicle.styleId) {
          payload.styleId = this.vehicle.styleId;
          payload.styleProvider = this.vehicle.styleProvider;
        }

        // Save fuel economy settings
        payload.fuelEconomy = this.vehicle.fuelEconomy;
        payload.fuelType = this.vehicle.fuelType;
        if (this.vehicle.fuelEconomyUm) {
          payload.fuelEconomyUm = this.vehicle.fuelEconomyUm;
        }

        // Save schedule
        payload.scheduleKey = this.vehicle.scheduleKey;
      }

      const response = await this.saveVehicle(payload);
      if (response.error) {
        this.errors = response.error;
        if (typeof response.error !== 'object') {
          this.errorMessage = this.error;
        }
        this.$emit('saving', false);
        return null;
      }

      this.$emit('saved');
      this.$emit('saving', false);

      return response;
    },
    onChange(event) {
      this.$emit('change', event, this.vehicle);
    },
    onFieldChange(event, properties) {
      this.vehicle = {
        ...this.vehicle,
        ...properties,
      };
      this.onChange(event);
    },
    onFuelFieldChange({ economy, economyUm, type }) {
      this.vehicle.fuelEconomy = economy;
      this.vehicle.fuelEconomyUm = economyUm;
      this.vehicle.fuelType = type;
    },
    onScan(event, property, value) {
      this.vehicle = {
        ...this.vehicle,
        [property]: value,
      };
      this.onChange(event);
    },
    /**
     * Sets the value of a Vehicle property.
     * - mostly for public/external calls
     * @param {string} property
     * @param {unknown} value
     */
    setValue(property, value) {
      this.vehicle = {
        ...this.vehicle,
        [property]: value,
      };
    },
  },
  async mounted() {
    if (!this.usersAsArray.length) {
      this.getUsers();
    }
    if (!this.vehicleTagsTaggable.length) {
      this.getTags();
    }

    if (this.groupsWithAccount.length === 0) {
      await this.getGroups();
    }

    if (this.vehicleKey) {
      if (!this.detailedVehicles[this.vehicleKey]) {
        await this.getDetailedVehicle(this.vehicleKey);
      }
      this.originalDriverKey = this.detailedVehicles[this.vehicleKey]?.primaryDriverKey;
      const clone = _cloneDeep(this.detailedVehicles[this.vehicleKey]);
      // must define schedule key so it can be watched by PermittedDrivingHours
      if (!clone || !clone.scheduleKey) {
        clone.scheduleKey = '';
      }
      this.vehicle = clone;
    } else {
      this.vehicle = {
        odometerUm: DEFAULT_VEHICLE_ODOMETER_UM,
        primaryDriverKey: '',
        scheduleKey: '',
      };
    }

    this.$emit('ready');
  },
  watch: {
    errors() {
      this.$emit('error', Boolean(Object.keys(this.errors)));
    },
  },
  unmounted() {
    this.clearStyle();
  },
};
</script>

<style lang="scss" scoped>
:deep(.q-field--with-bottom) {
  padding-bottom: 28px;
}

:deep(.q-field__bottom) {
  min-height: 0;
}
</style>
