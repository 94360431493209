import SimpleModel from './SimpleModel';

class Device extends SimpleModel {
  /** @type {Number} */
  batteryChargeLevel = null;

  /** @type {String} */
  friendlyLastTransmission = null;

  /** @type {String} */
  friendlyStatus = null;

  /** @type {Boolean} */
  hasMeaningfulConnectionStatus = null;

  /** @type {Boolean} */
  hasTransmittedWithin30Days = null;

  /** @type {Boolean} */
  isConnected = null;

  /** @type {Boolean} */
  isOffline = null;

  /** @type {String} */
  key = null;

  /** @type {String} */
  lastTransmission = null;

  /** @type {String} */
  model = null;

  /** @type {String} */
  serial = null;

  constructor(data = {}) {
    super();
    this.insert(data);
  }

  /**
   * Returns whether the curent device was made by Xirgo
   *
   * @return {Boolean}
   */
  get isXirgo() {
    const { model } = this;
    const lowerModel = (model || '').toLowerCase();
    return lowerModel.startsWith('xt2469') || lowerModel.startsWith('xt2594');
  }
}

export default Device;
