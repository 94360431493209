import _map from 'lodash/map';
import { colors as quasarColors } from 'quasar';
import GolfCartIcon from 'src/assets/golf-cart-icon.svg?component';
import checkEngineIcon from 'src/assets/noun-check-engine-4637053.svg?raw';
import distanceSvg from 'src/assets/noun_distance_236772.svg?raw';
import videocamAlert from 'src/assets/videocam_alert.svg?raw';
import zubieLogoSmall from 'src/assets/zubie-logo-small.svg?raw';
import chain from 'src/services/chain';
import SvgMarker from 'src/services/svg/SvgMarker';
import { svgToBase64 } from 'src/services/svg/tools';

export const colors = Object.freeze({
  ACCENT: 'accent',
  DARK: 'dark',
  INFO: 'info',
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
  POSITIVE: 'positive',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WARNING: 'warning',
  WHITE: 'white',
});

/* MUST MATCH quasar.variables.style */
export const COLOR_VALUES = Object.freeze({
  ACCENT: '#42D49C',
  DARK: '#000000',
  INFO: '#D9D900',
  NEGATIVE: '#ca054d',
  NEUTRAL: '#E0E0E0',
  POSITIVE: '#42D49C',
  PRIMARY: '#5742E0',
  SECONDARY: '#E0E0E0',
  WARNING: '#ff9b71',
  WHITE: '#ffffff',
});

export const userRoles = Object.freeze({
  ADMIN: 'admin',
  DRIVER: 'driver',
  INDIVIDUAL: 'individual',
  VIEWER: 'viewer',
});

export const userRoleDisplays = Object.freeze({
  ADMIN: 'Admin',
  DRIVER: 'Driver',
  INDIVIDUAL: 'Driver (with Access)',
  VIEWER: 'Viewer',
});

export const tagTypes = Object.freeze({
  GENERIC: 'Generic',
  CAR: 'Car',
  USER: 'User',
  TRIP: 'Trip',
});

export const simpleStatuses = Object.freeze({
  MOVING: 'moving',
  STALE: 'stale',
  STOPPED: 'stopped',
});

export const statuses = Object.freeze({
  ASSET_OK: 'ASSET_OK',
  DISCONNECTED: 'DISCONNECTED',
  MOVING: 'MOVING',
  NO_DEVICE: 'NO_DEVICE',
  NO_GPS: 'NO_GPS',
  OBSOLETE: 'OBSOLETE',
  OFFLINE: 'OFFLINE',
  POWERED_DOWN: 'POWERED_DOWN',
  STALE_UNKNOWN: 'STALE_UNKNOWN',
  STOPPED: 'STOPPED',
  UNKNOWN: 'UNKNOWN',
});

export const statusDisplays = Object.freeze({
  ASSET_OK: 'Healthy Asset',
  DISCONNECTED: 'Disconnected',
  MOVING: 'Driving',
  NO_DEVICE: 'No Device',
  NO_GPS: 'No GPS',
  OBSOLETE: 'Obsolete (3G)',
  OFFLINE: 'Offline',
  POWERED_DOWN: 'Powered Down',
  STALE_UNKNOWN: 'Stale',
  STOPPED: 'Parked',
  UNKNOWN: 'Unknown',
});

export const statusColorClasses = Object.freeze({
  ASSET_OK: 'primary',
  DISCONNECTED: 'negative',
  MOVING: 'positive',
  NO_DEVICE: 'dark',
  NO_GPS: 'dark',
  OBSOLETE: 'negative',
  OFFLINE: 'negative',
  POWERED_DOWN: 'dark',
  STALE_UNKNOWN: 'dark',
  STOPPED: 'primary',
  UNKNOWN: 'dark',
});

export const statusOptions = Object.freeze(
  chain(statuses)
    .map((value, key) => ({ label: statusDisplays[key], value }))
    .filter(({ value }) => ![statuses.OBSOLETE, statuses.STALE_UNKNOWN, statuses.UNKNOWN].includes(value))
    .sortBy('label')
    .value()
);

export const statusValueToLabel = statusOptions.reduce(
  (mappings, option) => ({
    ...mappings,
    [option.value]: option.label,
  }),
  {}
);

export const HEALTH_STATUSES = Object.freeze({
  CHECK_ENGINE: 'CHECK_ENGINE',
  LOW_BATTERY: 'LOW_BATTERY',
  LOW_FUEL: 'LOW_FUEL',
  MAINTENANCE_DUE: 'MAINTENANCE_DUE',
});

export const HEALTH_STATUS_DISPLAYS = Object.freeze({
  CHECK_ENGINE: 'Check Engine',
  LOW_BATTERY: 'Low Battery',
  LOW_FUEL: 'Low Fuel',
  MAINTENANCE_DUE: 'Maintenance Due',
});

export const healthStatusOptions = Object.freeze(
  _map(HEALTH_STATUSES, (value, key) => ({ label: HEALTH_STATUS_DISPLAYS[key], value }))
);

export const healthStatusValueToLabel = healthStatusOptions.reduce(
  (mappings, option) => ({
    ...mappings,
    [option.value]: option.label,
  }),
  {}
);

export const mapStyles = Object.freeze({
  DEFAULT: 'mapbox://styles/zubiecar/ckbmgfy0v1e3l1iqimtc00xe0',
  SATELLITE: 'mapbox://styles/zubiecar/ckbmgi4ej1e011hjync10ri8j',
  LIGHT: 'mapbox://styles/zubiecar/ckbmggvqj0q331ikkitefrj7h',
  DARK: 'mapbox://styles/zubiecar/ckbmgdmn40ue61iqrk9vncrt6',
});

export const mapIcons = Object.freeze({
  DARK: 'https://res.cloudinary.com/zubie/image/upload/v1711746598/apps/web/map-styles/dark.png',
  DEFAULT: 'https://res.cloudinary.com/zubie/image/upload/v1711746598/apps/web/map-styles/default.png',
  LIGHT: 'https://res.cloudinary.com/zubie/image/upload/v1711746598/apps/web/map-styles/light.png',
  PLACE_POINTS: 'https://res.cloudinary.com/zubie/image/upload/v1574796710/portal/map-icons/places.png',
  SATELLITE: 'https://res.cloudinary.com/zubie/image/upload/v1711746598/apps/web/map-styles/satellite.png',
  TRAFFIC: 'https://res.cloudinary.com/zubie/image/upload/v1711746598/apps/web/map-styles/traffic.png',
});

// from https://emailregex.com/
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// default palette from quasar https://github.com/quasarframework/quasar/blob/dev/ui/src/components/color/QColor.js#L23
const rgbColors = [
  'rgb(255,204,204)',
  'rgb(255,230,204)',
  'rgb(255,255,204)',
  'rgb(204,255,204)',
  'rgb(204,255,230)',
  'rgb(204,255,255)',
  'rgb(204,230,255)',
  'rgb(204,204,255)',
  'rgb(230,204,255)',
  'rgb(255,204,255)',
  'rgb(255,153,153)',
  'rgb(255,204,153)',
  'rgb(255,255,153)',
  'rgb(153,255,153)',
  'rgb(153,255,204)',
  'rgb(153,255,255)',
  'rgb(153,204,255)',
  'rgb(153,153,255)',
  'rgb(204,153,255)',
  'rgb(255,153,255)',
  'rgb(255,102,102)',
  'rgb(255,179,102)',
  'rgb(255,255,102)',
  'rgb(102,255,102)',
  'rgb(102,255,179)',
  'rgb(102,255,255)',
  'rgb(102,179,255)',
  'rgb(102,102,255)',
  'rgb(179,102,255)',
  'rgb(255,102,255)',
  'rgb(255,51,51)',
  'rgb(255,153,51)',
  'rgb(255,255,51)',
  'rgb(51,255,51)',
  'rgb(51,255,153)',
  'rgb(51,255,255)',
  'rgb(51,153,255)',
  'rgb(51,51,255)',
  'rgb(153,51,255)',
  'rgb(255,51,255)',
  'rgb(255,0,0)',
  'rgb(255,128,0)',
  'rgb(255,255,0)',
  'rgb(0,255,0)',
  'rgb(0,255,128)',
  'rgb(0,255,255)',
  'rgb(0,128,255)',
  'rgb(0,0,255)',
  'rgb(128,0,255)',
  'rgb(255,0,255)',
  'rgb(245,0,0)',
  'rgb(245,123,0)',
  'rgb(245,245,0)',
  'rgb(0,245,0)',
  'rgb(0,245,123)',
  'rgb(0,245,245)',
  'rgb(0,123,245)',
  'rgb(0,0,245)',
  'rgb(123,0,245)',
  'rgb(245,0,245)',
  'rgb(214,0,0)',
  'rgb(214,108,0)',
  'rgb(214,214,0)',
  'rgb(0,214,0)',
  'rgb(0,214,108)',
  'rgb(0,214,214)',
  'rgb(0,108,214)',
  'rgb(0,0,214)',
  'rgb(108,0,214)',
  'rgb(214,0,214)',
  'rgb(163,0,0)',
  'rgb(163,82,0)',
  'rgb(163,163,0)',
  'rgb(0,163,0)',
  'rgb(0,163,82)',
  'rgb(0,163,163)',
  'rgb(0,82,163)',
  'rgb(0,0,163)',
  'rgb(82,0,163)',
  'rgb(163,0,163)',
  'rgb(92,0,0)',
  'rgb(92,46,0)',
  'rgb(92,92,0)',
  'rgb(0,92,0)',
  'rgb(0,92,46)',
  'rgb(0,92,92)',
  'rgb(0,46,92)',
  'rgb(0,0,92)',
  'rgb(46,0,92)',
  'rgb(92,0,92)',
  'rgb(255,255,255)',
  'rgb(205,205,205)',
  'rgb(178,178,178)',
  'rgb(153,153,153)',
  'rgb(127,127,127)',
  'rgb(102,102,102)',
  'rgb(76,76,76)',
  'rgb(51,51,51)',
  'rgb(25,25,25)',
  'rgb(0,0,0)',
];
const hexColors = rgbColors.map((color) => quasarColors.rgbToHex(quasarColors.textToRgb(color)));
const brandColors = Object.values(COLOR_VALUES);
export const TAGS_COLOR_PALETTE = Object.freeze(Array.from(new Set(brandColors.concat(hexColors))));

export const placeCategories = Object.freeze([
  'airport',
  'branch',
  'business',
  'customer',
  'gas station',
  'headquarters',
  'high risk zone',
  'home',
  'impound',
  'maintenance',
  'other',
  'out of pool',
  'overflow',
  'primary',
  'rental agency',
  'school',
  'vendor',
  'work',
]);

export const usStatesList = Object.freeze([
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'District Of Columbia', code: 'DC' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
]);

const checkEngineIconSvg = svgToBase64(new SvgMarker(checkEngineIcon, { outerColor: COLOR_VALUES.DARK }).colorize());
export const eventIconMap = {
  'Arriving at a Place': 'location_on',
  'Asset Geofence Arrival': 'location_on',
  'Asset Geofence Departure': 'location_on',
  'Battery Level Notification': 'battery_alert', // or https://thenounproject.com/icon/auto-battery-2715807/
  'Car Maintenance Reminder': 'build',
  'Car Schedule Exception': 'event_busy',
  'Car Style Disambiguation': 'directions_car',
  Default: `img:${svgToBase64(zubieLogoSmall)}`,
  'Device Moved to New Vehicle': 'swap_horiz',
  'Device Reconnect Alert': 'power',
  'Device Registration Attempt Expired': 'event_repeat',
  'Device Registration Completed': 'done',
  'Device Unplugged Alert': 'power_off',
  'Device Unplugged': 'power_off',
  'Diagnostic Code': `img:${checkEngineIconSvg}`,
  'Distracted Driving': `img:${svgToBase64(videocamAlert)}`,
  'Hard Brake': 'fast_rewind',
  Idle: 'hourglass_empty',
  'Leaving from a Place': 'mode_of_travel',
  'Low Battery': 'battery_alert', // or https://thenounproject.com/icon/auto-battery-2715807/
  'Low Fuel Alert': 'local_gas_station',
  'Low Fuel': 'local_gas_station',
  'Fuel Refill Alert': 'local_gas_station',
  'New Device Activated': 'power',
  'Over Speeding': 'speed',
  'Place Arrival': 'where_to_vote',
  'Place Departure': 'mode_of_travel',
  'Possible Crash Alert': 'car_crash',
  'Rapid Accelerations': 'fast_forward',
  'Severe Hard Braking': 'car_crash',
  'Trip Completed': 'navigation',
  'Trip End': `img:${svgToBase64(distanceSvg)}`,
  'Trip Start': 'near_me',
  'Trip Tagged': 'new_label', //
  'Trouble Code Cleared': `img:${checkEngineIconSvg}`,
  'Trouble Code': `img:${checkEngineIconSvg}`,
  'User Accepted Invite Notification': 'person_add',
  'User Invite Notification': 'person_add',
  'User Location Geofence Notification': 'my_location',
  'Vehicle Change': 'directions_car',
  'Vehicle Location Updated': 'edit_location',
  'Vehicle Motion Monitor Alert': 'directions_car',
  'Vehicle Schedule Exception': 'event_busy',
};

export const LOW_BATTERY_THRESHOLD = 11.8;

export const LOW_BATTERY_EVENT_COUNT_THRESHOLD = 4;

export const IDB_COLLECTIONS = {
  ASSET: 'ASSET',
  ASSETS: 'assets',
  GEOCODED_ADDRESSES: 'geocodedAddresses',
  METADATA: 'metadata',
  PLACE_AT_POINT: 'placeAtPoint',
  VEHICLE: 'VEHICLE',
  VEHICLES: 'vehicles', // deprecated
};

export const IDB_METADATA = {
  PLACE_CACHE_ORIGINATION: 'placeCacheOrigination',
  PLACES_COUNT: 'placesCount',
};

export const FUEL_TYPES_STANDARD = Object.freeze(['Diesel', 'Regular', 'Premium']);

export const FUEL_TYPES_O2 = Object.freeze(['Diesel', 'Super E10', 'Super E5', 'Super Plus']);

export const CONTOURS = [5, 15, 30, 60];

// Applies to contours in reverse order (furthest to closest)
export const CONTOUR_COLORS = {
  DEFAULT: ['#facc53', '#e2641f', '#6f2f1d', '#222224'],
  SATELLITE: ['#fdb36a', '#de837f', '#e54059', '#30043d'],
  LIGHT: [
    quasarColors.lighten('#888', 40),
    quasarColors.lighten('#888', 0),
    quasarColors.lighten('#888', -40),
    quasarColors.lighten('#888', -90),
  ],
  DARK: [
    quasarColors.lighten('#aaa', -40),
    quasarColors.lighten('#aaa', 0),
    quasarColors.lighten('#aaa', 40),
    quasarColors.lighten('#aaa', 90),
  ],
};

export const CONTOUR_LEGEND_OPACITY = {
  DEFAULT: 0.5,
  SATELLITE: 0.7,
  LIGHT: 0.5,
  DARK: 1,
};

// Applies to contours in reverse order (furthest to closest)
export const CONTOUR_OUTLINE_COLORS = {
  DEFAULT: [
    quasarColors.lighten('#facc53', -20),
    quasarColors.lighten('#e2641f', -20),
    quasarColors.lighten('#6f2f1d', -20),
    quasarColors.lighten('#222224', -20),
  ],
  SATELLITE: [
    quasarColors.lighten('#fdb36a', -20),
    quasarColors.lighten('#de837f', -50),
    quasarColors.lighten('#e54059', -40),
    quasarColors.lighten('#30043d', -40),
  ],
  LIGHT: [
    quasarColors.lighten('#888', 50),
    quasarColors.lighten('#888', 20),
    quasarColors.lighten('#888', -20),
    quasarColors.lighten('#888', -60),
  ],
  DARK: [
    quasarColors.lighten('#aaa', 0),
    quasarColors.lighten('#aaa', 20),
    quasarColors.lighten('#aaa', 50),
    quasarColors.lighten('#aaa', 70),
  ],
};

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const ASSET_TYPES = {
  ASSET: 'ASSET',
  VEHICLE: 'VEHICLE',
};

export const ASSET_TYPE_NAMES = {
  ASSET: 'Asset',
  VEHICLE: 'Vehicle',
};

export const ASSET_ICONS = {
  ASSET: 'gps_fixed',
  VEHICLE: 'directions_car',
};

export const ASSET_ICONS_SET = {
  simple: [
    'label',
    'lens',
    'star_rate',
    'stop',
    'flag',
    'push_pin',
    'adjust',
    'center_focus_strong',
    'control_camera',
    'category',
  ],
  generic: [
    'font_download',
    'attach_money',
    'monetization_on',
    'business_center',
    'developer_board',
    'flare',
    'tour',
    'gps_not_fixed',
    'laptop',
    'memory',
    'router',
    'settings_input_antenna',
    'settings_remote',
    'speaker_phone',
    'stars',
    'insert_emoticon',
  ],
  specific: [
    'agriculture',
    'airplanemode_active',
    'audiotrack',
    'bike_scooter',
    'camera',
    'camera_alt',
    'carpenter',
    'construction',
    'directions_bike',
    'directions_boat',
    'event_seat',
    'component:golf_cart',
    'home_repair_service',
    'lock',
    'security',
    'support',
    'toys',
    'two_wheeler',
    'work',
    'weekend',
  ],
};

export const ASSET_ICON_COMPONENTS = {
  golf_cart: GolfCartIcon,
};

export const ASSET_ICON_MARKER_TRANSFORM = {
  // TODO: Determine if this needs to be done per icon once we add more icons
  golf_cart: { scale: 2.3, translate: { x: 5, y: 5 } },
};

export const LOW_BATTERY_LEVEL_THRESHOLD = 10;

// continental US according to https://en.wikipedia.org/wiki/List_of_extreme_points_of_the_United_States
export const CONTINENTAL_US_BOUNDS = {
  southWest: [-124.7844079, 24.7433195],
  northEast: [-66.9513812, 49.3457868],
};

export const ALERT_TYPES = Object.freeze({
  DISCONNECTED: 'disconnected',
  LOW_BATTERY: 'low_battery',
  LOW_BATTERY_LEVEL: 'low_battery_level',
  NO_DEVICE: 'no_device',
  POSSIBLE_TOW: 'possible_tow',
  TROUBLE_CODES: 'trouble_codes',
});

export const CANCEL_REASONS = {
  USER_ABORTED: 'USER_ABORTED',
};

export const ORIENTATION = {
  'landscape-secondary': 'landscape-secondary',
  'landscape-primary': 'landscape-primary',
  'portrait-primary': 'portrait-primary',
};

export const GL300_REGEX = /^g\w{7}$/i;
export const GL500_REGEX = /^at\w{6}$/i;
export const SIMULATED_GL_REGEX = /^SM\w{6}$/i;
export const XIRGO_REGEX = /^\d{9}$/;

export const DEVICE_MODELS = {
  XIRGO_WITHOUT_BATTERY: ['XT2469A31100281T', 'XT2469A32100281T'],
  XIRGO_WITH_BATTERY: ['XT2469A311002A1T', 'XT2469A321002A1T'],
};

const SECONDS_PER_MINUTE = 60;
const MINUTES_PER_HOUR = 60;
const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR;

export const CHECKIN_DURATION_OPTIONS = Object.freeze([
  {
    label: '1 hour',
    value: SECONDS_PER_HOUR,
  },
  {
    label: '4 hours',
    value: 4 * SECONDS_PER_HOUR,
  },
  {
    label: '8 hours',
    value: 8 * SECONDS_PER_HOUR,
  },
  {
    label: 'Today',
    value: 1,
  },
]);

export const SEARCH_TYPES = {
  ADDRESS: 'ADDRESS',
  KEYWORD: 'KEYWORD',
  FILTER: 'FILTER',
};

export const ROWS_PER_PAGE_OPTIONS = Object.freeze([10, 25, 50, 0]);

export const ROWS_PER_PAGE_LOWEST = ROWS_PER_PAGE_OPTIONS[0];

export const ROWS_PER_PAGE_DEFAULT = ROWS_PER_PAGE_OPTIONS[1];

export const SHARE_TYPES = {
  DEFAULT: 'location-share',
  RECOVERY: 'recovery-mode',
  REPOSSESSION: 'paid-repossession',
};

export const SHARE_DATE_MASK = 'ddd M/D/YY [at] h:mm a';

export const SHARE_KEYS_DELIMITER = ',;_';

export const SHARE_TYPE_TO_INTERCOM_EVENT_MAP = {
  'location-share': 'vehicle_location_shared',
  'recovery-mode': 'vehicle_recovery_mode_enabled',
  'paid-repossession': 'vehicle_paid_repossession_requested',
};

export const APP_INSTALL_LEVELS = {
  ACCOUNT: 'ACCOUNT',
  USER: 'USER',
};

export const DEFAULT_USER_DATA = Object.freeze({
  accountRole: userRoles.VIEWER,
  appIds: [],
  bulletingFrequency: '',
  crashEvents: false,
  deviceEvents: false,
  distractedDrivingEvents: false,
  distractedDrivingCellphoneEvents: false,
  distractedDrivingEatingEvents: false,
  distractedDrivingDrinkingEvents: false,
  distractedDrivingSmokingEvents: false,
  distractedDrivingTiredEvents: false,
  email: '',
  emailNotification: false,
  firstName: '',
  groupKeys: [],
  groups: [],
  hardBrakingEvents: false,
  idleEvents: false,
  lastName: '',
  locationEvents: false,
  lowFuelEvents: 'none',
  marketingOptOut: false,
  overSpeedEvents: false,
  preferredLocale: null,
  pushNotification: false,
  speedLimitEvents: false,
  smsPhoneNumber: '',
  tagKeys: [],
  tags: [],
  tripEvents: 'none',
  vehicleHealthEvents: false,
  vehicleLocationEvents: 'none',
  vehicleMaintenanceEvents: 'none',
  vehicleScheduleEvents: 'none',
});

export const PARTNER_NAME_MAP = {
  'Zubie Business': 'Zubie Fleet Connect',
  'Zubie Rental Connect': 'Zubie Rental Connect',
  Zubie: 'Zubie Car Connect',
};

export const CHECK_IN_MODES = {
  UNRESTRICTED: 'UNRESTRICTED',
  RESTRICTED: 'RESTRICTED',
  DISABLED: 'DISABLED',
};

export const CHECK_IN_MODES_DESCRIPTIONS = {
  UNRESTRICTED: 'All users may create check-ins',
  RESTRICTED: 'Check-ins may only be created by the Zubie device or by admins',
  DISABLED: 'No check-ins may be created',
};

export const DASHCAM_MEDIA_ACCESS_MODES = {
  ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
  ADMIN: 'ADMIN',
  DEFAULT: 'DEFAULT',
  DISABLED: 'DISABLED',
};

export const DASHCAM_MEDIA_LOOKUP_CACHE_SECONDS = 60;
