<template>
  <ZubieDialog
    :action-button-text="actionButtonText"
    :cancel-button-text="sendSuccess ? 'Close' : 'Cancel'"
    content-class="column justify-start no-wrap"
    :max-width="400"
    :maximized="$q.screen.xs"
    :ok-only="sendSuccess"
    :processing="sendInProgress"
    @hide="onHide"
    @submit="onSubmit"
  >
    <template #header>
      <div class="q-mb-xs text-h6 line-height-1">Send message to {{ vehicle.nickname }}</div>
      <div class="row items-center text-subtitle1 text-caption line-height-1 roboto">
        <div>{{ checkedInDriver ? 'Checked In' : 'Primary' }} Driver:</div>
        <DriverChip
          :class="{ 'q-ml-xs': !isUnassigned }"
          color="white"
          dense
          :driver="driver"
          :outline="isUnassigned"
        />
      </div>
    </template>
    <div v-if="!vehicle?.isInTrip">
      <q-banner class="q-mb-md q-pa-sm bg-warning text-dark">
        <div class="row no-wrap">
          <q-icon name="warning" size="sm" />
          <div class="q-ml-sm">
            Note: This vehicle is not currently in-trip, so they may not be in view of the dashcam and/or may not
            receive the message until they start their next trip.
          </div>
        </div>
      </q-banner>
    </div>
    <q-banner v-if="apiError" class="q-mb-md text-white bg-negative"
      ><q-icon name="warning" /> An error occurred when trying to send dashcam message</q-banner
    >
    <transition appear name="height-fade-in">
      <DashcamMessageAnimation
        v-if="sendInProgress || sendSuccess"
        class="col"
        :message="message"
        :send-in-progress="sendInProgress"
        :send-status="sendStatus"
        :send-success="sendSuccess"
      />
    </transition>
    <transition appear name="height-fade">
      <div v-if="!sendInProgress && !sendSuccess">
        <div class="q-mb-md">
          Enter short message (up to 15 characters) to be sent to this dashcam. The message will be displayed on the
          dashcam screen for 10 minutes.
        </div>
        <div class="row no-wrap">
          <q-input
            ref="message-input"
            v-model="message"
            autofocus
            class="col q-mb-sm q-mr-sm"
            counter
            :disable="sendInProgress"
            :error="Boolean(messageError)"
            :error-message="messageError"
            filled
            :hide-bottom-space="!Boolean(messageError)"
            label="Message"
            :loading="sendInProgress"
            maxlength="15"
            type="text"
            @update:model-value="messageError = ''"
          />
        </div>
      </div>
    </transition>
  </ZubieDialog>
</template>

<script setup lang="ts">
import type { QInput } from 'quasar';
import { computed, ref, useTemplateRef } from 'vue';
import { useStore } from 'vuex';
import DriverChip from 'components/chips/DriverChip.vue';
import DashcamMessageAnimation from 'components/dashcam/DashcamMessageAnimation.vue';
import Driver from 'src/models/Driver';
import { setTimeoutPromise } from 'src/services/setTimeout';
import { getFullName } from 'src/services/users';
import type { RootStore } from 'src/types/store';

const props = defineProps<{ vehicleKey: string }>();

const emit = defineEmits(['hide']);

const message = ref('');

const store = <RootStore>useStore();

const vehicle = computed(() => store.state.vehicles.vehicles[props.vehicleKey]);

const actionButtonText = computed(() => {
  if (sendSuccess.value) {
    return 'Sent';
  }
  return sendInProgress.value ? 'Sending' : 'Send';
});

const checkedInDriver = computed(() => {
  const driver = vehicle.value?.driver;
  if (!driver || driver.checkInExpiration === null) {
    return null;
  }
  return new Driver({
    ...driver,
    fullName: getFullName(driver.firstName, driver.lastName),
  });
});

const primaryDriver = computed(() => {
  const primaryDriverKey = vehicle.value?.primaryDriverKey;
  if (!primaryDriverKey) {
    return null;
  }

  const user = store.state.users.users[primaryDriverKey];
  if (user) {
    return new Driver({ ...user, fullName: getFullName(user.firstName, user.lastName) });
  }
});

const driver = computed(() => {
  if (checkedInDriver.value) {
    return checkedInDriver.value;
  }
  return primaryDriver.value || new Driver({ fullName: 'Unassigned' });
});

const isUnassigned = computed(() => driver.value.fullName === 'Unassigned');

const apiError = ref(false);
const messageInput = useTemplateRef<QInput>('message-input');
const messageError = ref('');
const sendInProgress = ref(false);
const sendStatus = ref<'not sent' | 'sending' | 'sent'>('not sent');
const sendSuccess = ref(false);
async function onSubmit() {
  apiError.value = false;

  if (!message.value.trim()) {
    messageError.value = 'Please provide a message to send';
    messageInput.value.focus();
    return;
  }
  sendInProgress.value = true;
  await setTimeoutPromise(250);
  sendStatus.value = 'sending';
  const result = await store.state.app.apiWorker.sendDashcamMessage({
    key: vehicle.value.key,
    message: message.value,
    duration: 600, // 10 minutes
  });

  if (result.error) {
    sendStatus.value = 'not sent';
    apiError.value = true;
  } else {
    sendStatus.value = 'sent';
  }

  await setTimeoutPromise(500);

  if (!result.error) {
    sendSuccess.value = true;
  }

  sendInProgress.value = false;
}

async function onHide() {
  await setTimeoutPromise(250);
  emit('hide');
}
</script>

<style scoped lang="scss">
.height-fade-in-enter-active,
.height-fade-in-leave-active {
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.height-fade-in-enter-to,
.height-fade-in-leave,
.height-fade-in-leave-from {
  max-height: calc(100vh - 200px);
}

.height-fade-in-enter,
.height-fade-in-leave-to,
.height-fade-in-enter-from {
  max-height: 0;
}

.height-fade-enter-active,
.height-fade-leave-active {
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.height-fade-enter-to,
.height-fade-leave,
.height-fade-leave-from {
  max-height: 300px;
}

.height-fade-enter,
.height-fade-leave-to,
.height-fade-enter-from {
  max-height: 0;
}
</style>
