<template>
  <q-btn
    align="left"
    class="action no-btn-wrapper-padding row col-grow q-py-sm q-px-sm"
    :class="{ 'cursor-default action--disabled': isDisabled }"
    color="primary"
    flat
    no-caps
    :ripple="!isDisabled && !result.isCurrentRoute"
    @click.stop="onClick"
  >
    <q-item-section avatar class="col-shrink q-pa-none" style="min-width: 33px">
      <q-icon :name="result.icon" />
    </q-item-section>
    <q-item-section>
      <div class="row items-center no-wrap text-left">
        <div class="col q-pr-sm">
          <q-item-label class="text-weight-regular">
            <slot name="label">{{ result.label }}</slot>
          </q-item-label>
          <q-item-label caption>
            <slot name="description">{{ result.description }}</slot>
          </q-item-label>
        </div>
        <div v-if="actionLabel" class="row items-center">
          <q-btn
            class="inner-btn"
            :color="isDisabled ? 'transparent' : 'primary'"
            :disable="isDisabled"
            padding="xs sm"
            :ripple="false"
            square
            :text-color="isDisabled ? 'dark' : 'white'"
          >
            <div class="row items-center no-wrap">
              <div class="line-height-1 text-uppercase">
                <div class="action__label">{{ actionLabel }}</div>
                <ToolTip
                  v-if="tooltip && !$q.platform.is.mobile"
                  anchor="center left"
                  :offset="[10, 0]"
                  self="center right"
                >
                  {{ tooltip }}
                </ToolTip>
              </div>
            </div>
          </q-btn>
        </div>
        <q-icon v-if="navArrow" class="q-mr-xs" color="grey-7" name="arrow_forward_ios" size="18px" />
      </div>
    </q-item-section>
  </q-btn>
</template>

<script>
import { mapState } from 'vuex';
import ToolTip from 'components/ToolTip.vue';

export default {
  components: { ToolTip },
  name: 'PrimarySearchButton',
  props: {
    navArrow: Boolean,
    result: Object,
  },
  emits: ['click'],
  computed: {
    ...mapState('app', ['isLiveMapRoute', 'isOnDetailsPage']),
    action() {
      if (this.isLiveMapRoute && this.result.isMappable) {
        return 'live-map-click';
      }
      if (this.result.type === 'address') {
        return 'click';
      }
      return 'details-click';
    },
    actionLabel() {
      if (this.result.isCurrentRoute) {
        return '';
      }

      if (this.isOnMapWithoutLocation) {
        return 'No Location';
      }

      if (this.isOnDetailsPage && this.result.isVehicleAsset) {
        return this.currentTabLabel;
      }

      return this.action === 'live-map-click' ? this.mapLabel : this.detailsLabel;
    },
    currentTabLabel() {
      const tripsLabel = this.result.hasTripsDevice ? 'Trips' : 'Locations';

      if (this.result.type === 'asset' && ['vehicleHealth', 'vehicleMaintenance'].includes(this.$route.name)) {
        return 'Overview';
      }

      switch (this.$route.name) {
        case 'assetLocations':
        case 'vehicleTrips':
          return tripsLabel;
        case 'vehicleHealth':
          return 'Health';
        case 'vehicleMaintenance':
          return 'Maintenance';
        default:
          return 'Overview';
      }
    },
    detailsLabel() {
      if (this.result.isVehicleAsset) {
        return this.result.hasTripsDevice ? 'Trips' : 'Locations';
      }
      if (this.result.type === 'address') {
        return '';
      }
      return 'Details';
    },
    isDisabled() {
      return this.isOnMapWithoutLocation || this.result.isCurrentRoute;
    },
    isOnMapWithoutLocation() {
      return this.isLiveMapRoute && this.result.isMappable && !this.result.isOnMap;
    },
    mapLabel() {
      return this.$q.screen.gt.xs ? 'Live Map' : 'Map';
    },
    tooltip() {
      return this.isOnMapWithoutLocation ? `This ${this.result.type} does not have a location` : null;
    },
  },
  methods: {
    onClick() {
      this.$emit('click', this.action);
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-right: 1px solid $grey-4;
  border-left: 1px solid $grey-4;
  border-radius: 0;
}

.q-item__label,
.q-item__section--avatar {
  color: $dark;
}

.inner-btn {
  transition: all 0.2s ease-in;
}

:deep(.q-item__label) {
  max-width: calc(100vw - 160px);
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}
</style>
