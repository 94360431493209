<template>
  <LocatingSpinner v-if="address.isEmpty && !resolvedAddress" data-t="locatingSpinner" />
  <div v-else data-t="address">
    <q-icon v-if="showIcon" name="location_on" />
    <text-highlight v-if="splitLines && !address.isEmpty" :query="termsString" split-by-space>
      {{ addressToUse.street }} </text-highlight
    ><br v-if="splitLines && !address.isEmpty" />
    <text-highlight v-if="splitLines && !address.isEmpty" :query="termsString" split-by-space>
      {{ addressToUse.line2 }}
    </text-highlight>
    <text-highlight v-else :query="termsString" split-by-space>
      {{ addressToUse }}
    </text-highlight>
    <slot />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LocatingSpinner from 'components/map/LocatingSpinner.vue';
import Address from 'src/models/Address';

export default {
  props: {
    address: {
      type: Address,
      default() {
        return new Address();
      },
    },
    latitude: Number,
    longitude: Number,
    searchTerms: {
      type: Array,
      default: () => [],
    },
    showIcon: Boolean,
    splitLines: {
      type: Boolean,
      default: false,
    },
  },
  components: { LocatingSpinner },
  data() {
    return {
      resolvedAddress: null,
    };
  },
  computed: {
    addressToUse() {
      return this.address.isEmpty ? this.resolvedAddress : this.address;
    },
    key() {
      if (this.latitude && this.longitude) {
        return `${this.latitude.toFixed(5)},${this.longitude.toFixed(5)}`;
      }
      return '';
    },
    shouldGeocode() {
      return this.address.isEmpty;
    },
    termsString() {
      return this.searchTerms.join(' ');
    },
  },
  methods: {
    ...mapActions('geocoding', ['reverseGeocode']),
    async geocode() {
      if (this.shouldGeocode) {
        this.resolvedAddress = null;
        const address = await this.reverseGeocode({
          latitude: this.latitude,
          longitude: this.longitude,
        });
        this.resolvedAddress = address;
        this.$emit('load', this.resolvedAddress);
      }
    },
  },
  watch: {
    key() {
      this.geocode();
    },
  },
  mounted() {
    this.geocode();
  },
};
</script>

<style scoped lang="scss">
:deep(.q-icon) {
  font-size: 0.9em;
}
</style>
