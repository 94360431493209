import _debounce from 'lodash/debounce';
import _noop from 'lodash/noop';
import raf from 'raf';
import Bounds from 'src/models/Bounds';
import { setTimeoutPromise } from 'src/services/setTimeout';
import GeoJsonLayer from '../geoJson/GeoJsonLayer';
import GeoJsonSource from '../geoJson/GeoJsonSource';
import { ClusterNotFoundError } from './ClusterNotFoundError';
import Map from './Map';

/**
 * @typedef {Object} LngLat
 * @property {Number} lng - Longitude
 * @property {Number} lat - Latitude
 */

class Mapbox extends Map {
  /** @var {Number} RESIZE_DEBOUNCE Number of milliseconds to use for map resize debounce. */
  RESIZE_DEBOUNCE = 200;

  /** @var {Number} ON_MAP_CHANGE_DEBOUNCE Debounce wait time. */
  ON_MAP_CHANGE_DEBOUNCE = 300;

  /** @type {Number} Maximum programmatic zoom level. */
  MAX_AUTO_ZOOM = 17.5;

  /** @var {Number} MAX_ZOOM Maximum zoom level restriction. */
  MAX_ZOOM = 20;

  /** @var {Number} MIN_ZOOM Minimum zoom level restriction. */
  MIN_ZOOM = 3;

  /** @type {Number} Zoom level that results in a "state" sized viewport. */
  STATE_LEVEL_ZOOM = 7;

  /** @type {Number} Zoom level that resuilts in a "city" sized viewport. */
  CITY_LEVEL_ZOOM = 11;

  /** @type {Number} Zoom level that resuilts in a "city" sized viewport. */
  BLOCK_LEVEL_ZOOM = 14;

  /** @var {Number} TRIP_DEFAULT_ZOOM Default zoom level for trips maps. */
  TRIP_DEFAULT_ZOOM = 15;

  getDoubleClickFocus = () => {};

  /**
   * Returns "true" if the map is still defined and functional.
   *
   * @returns {Boolean}
   */
  get isAlive() {
    return Boolean(this.map && this.map.style);
  }

  /**
   * Adds a MapboxControl to the map, calling control.onAdd(this).
   *
   * @param {import('./MapboxControl').default} control
   * @param {String} position
   */
  addControl(control, position) {
    this.map.addControl(control, position);
  }

  /**
   * Adds an image to the map style.
   *
   * @param {String} id
   * @param {Image} image
   * @param {Object} options
   */
  addImage(id, image, options = {}) {
    this.map.addImage(id, image, options);
  }

  /**
   * Adds a GeoJSON layer to the map.
   *
   * @param {GeoJsonLayer} layer
   * @param {GeoJsonLayer} layerBefore The ID of an existing layer to insert the new layer before.
   * @param {unknown} configOptions Arguments passed directly to the Layer's "getConfig" method
   * @throws {Error}
   */
  addLayer(layer, layerBefore, configOptions) {
    if (layer instanceof GeoJsonLayer !== true) {
      throwTypeError({
        type: 'GeoJsonLayer',
        method: 'addLayer',
        log: [layer, 'is not a GeoJsonLayer object.'],
      });
    }

    const layerBeforeId = getLayerId(layerBefore);

    if (this.isAlive && !this.getLayer(layer.getId())) {
      this.map.addLayer(layer.getConfig(configOptions), layerBeforeId);
    }
  }

  /**
   * Adds a GeoJSON data source to the map.
   *
   * @param {GeoJsonSource} source
   * @throws {Error}
   */
  addSource(source) {
    if (source instanceof GeoJsonSource !== true) {
      throwTypeError({
        type: 'GeoJsonSource',
        method: 'addSource',
        log: [source, 'is not a GeoJsonSource object.'],
      });
    }

    if (this.isAlive && !this.map.getSource(source.getId())) {
      this.map.addSource(source.getId(), source.getConfig());
    }
  }

  /**
   * Returns true if the given coordinates are within the map bounds.
   *
   * @param {Number[]} coordinates
   * @returns {Boolean}
   */
  boundsContains(coordinates) {
    return this.map.getBounds().contains(coordinates);
  }

  /**
   * Disables default double-click zoom and applies custom handlers.
   */
  enableDoubleClickZoom() {
    // Disable Mapbox double-click zoom feature
    this.map.doubleClickZoom.disable();

    // Enable double-touch support
    this.enableDoubleTouch();

    this.on('dblclick', async (event) => {
      const currentZoom = this.getZoom();
      const { lng, lat } = this.getDoubleClickFocus() || event.lngLat;

      await setTimeoutPromise(0); // allow event to pass

      let zoom;
      switch (true) {
        case currentZoom < this.STATE_LEVEL_ZOOM - 1:
          zoom = this.STATE_LEVEL_ZOOM;
          break;
        case currentZoom < this.CITY_LEVEL_ZOOM - 1:
          zoom = this.CITY_LEVEL_ZOOM;
          break;
        case currentZoom < this.BLOCK_LEVEL_ZOOM - 1:
          zoom = this.BLOCK_LEVEL_ZOOM;
          break;
        default:
          zoom = this.MAX_AUTO_ZOOM;
      }

      this.zoomTo(zoom, lng, lat);
    });
  }

  /**
   * Adds a touch equivalent to a double-click, triggering the dblclick event.
   */
  enableDoubleTouch() {
    let multiTouch = false;
    let touchCount = 0;
    let lastTouch = Date.now();

    this.on('touchstart', () => {
      touchCount += 1;
      if (touchCount > 1) {
        multiTouch = true; // enter multi-touch zone
      }
    });
    this.on('touchend', (event) => {
      const now = Date.now();
      if (now - lastTouch <= 500 && !multiTouch && !this.map.isMoving()) {
        this.trigger('dblclick', event);
      }
      touchCount -= 1;
      lastTouch = now;
      if (touchCount === 0) {
        multiTouch = false; // multi-touch has ended
      }
    });
  }

  /**
   * Pans and zooms the map to contain its visible area within the specified geographical bounds.
   *
   * @param {Bounds} bounds A Bounds object to focus map around.
   * @param {import('mapbox-gl').EasingOptions} options
   */
  fitBounds(bounds, options = {}) {
    const fitOptions = {
      padding: 100,
      duration: this.MAP_VIEW_CHANGE_DURATION,
      maxZoom: this.MAX_AUTO_ZOOM,
      ...options,
    };

    if (this.isAlive) {
      this.map.fitBounds(bounds.toArray(), fitOptions);
    }
  }

  /**
   * Returns the current bounding box from the Mapbox instance.
   *
   * @returns {Bounds} The pair of lat/long bounds coordinates from Mapbox.
   */
  getBounds() {
    const bounds = this.map.getBounds();
    return new Bounds({
      northEast: {
        latitude: bounds.getNorthEast().lat,
        longitude: bounds.getNorthEast().lng,
      },
      southWest: {
        latitude: bounds.getSouthWest().lat,
        longitude: bounds.getSouthWest().lng,
      },
    });
  }

  /**
   * Returns the lat/long found at the center of the map.
   *
   * @returns {LngLat}
   */
  getCenter() {
    return this.map.getCenter();
  }

  /**
   * Retrieves the layer with the given ID.
   *
   * @param {String} id
   * @returns {Object}
   */
  getLayer(id) {
    return this.isAlive ? this.map.getLayer(id) : null;
  }

  /**
   * Retrieves a map source by id.
   *
   * @param {String} id
   */
  getSource(id) {
    return this.isAlive ? this.map.getSource(id) : null;
  }

  /**
   * Returns the map's current zoom level.
   *
   * @returns {Number}
   */
  getZoom() {
    return this.map.getZoom();
  }

  /**
   * True if the image with the given ID exists in the style.
   *
   * @param {String} id
   */
  hasImage(id) {
    return this.isAlive && this.map.hasImage(id);
  }

  /**
   * Returns whether or not the map is moving.
   *
   * @returns {Boolean} True if the map is moving.
   */
  isMoving() {
    return this.isAlive && this.map.isMoving();
  }

  /**
   * Adds a listener for events of a specified type, optionally limited to features in a specified style layer.
   *
   * @param {String} type
   * @param {String} layerId (or callback)
   * @param {Function} callback
   * @returns {Mapbox}
   */
  on(eventName, ...layerIdAndCallback) {
    this.map.on(eventName, ...layerIdAndCallback);
    return this;
  }

  /**
   * Adds a one-time listener for events of a specified type, optionally limited to features in a specified style layer.
   *
   * @param {String} type
   * @param {String} layerId (or callback)
   * @param {Function} callback
   * @returns {Mapbox}
   */
  once(eventName, ...layerIdAndCallback) {
    this.map.once(eventName, ...layerIdAndCallback);
    return this;
  }

  /**
   * Adds an "on click" listener for a given layer ID.
   *
   * @param {GeoJsonLayer} layer
   * @param {Function} callback
   * @throws {Error}
   */
  onLayerClick(layer, callback) {
    if (layer instanceof GeoJsonLayer !== true) {
      throwTypeError({
        type: 'GeoJsonLayer',
        method: 'onLayerClick',
        log: [layer, 'is not a GeoJsonLayer object.'],
      });
    }

    this.map.on('click', layer.getId(), callback);
  }

  /**
   * Adds event handlers to handle map panning/zooming.
   *
   * @param {Function} callback Callback to be added as event handler for each event.
   */
  onMapChange(callback) {
    let controlClicked = false;

    const decoratedCallback = (event) => {
      event.isUserInteraction = controlClicked || isUserInteraction(event);
      controlClicked = false;
      callback(event);
    };
    const debouncedCallback = _debounce(decoratedCallback, this.ON_MAP_CHANGE_DEBOUNCE);
    this.map.on('dblclick', debouncedCallback);
    this.map.on('zoomend', debouncedCallback);
    this.map.on('moveend', debouncedCallback);

    raf(() => {
      const controls = this.map.getContainer().querySelectorAll('.mapboxgl-ctrl-zoom-in,.mapboxgl-ctrl-zoom-out');
      controls.forEach((control) => {
        control.addEventListener('click', () => {
          controlClicked = true;
        });
      });
    });
  }

  /**
   * Pans to the given lat/long coordinates.
   *
   * @param {Number} longitude
   * @param {Number} latitude
   * @param {Object} options
   * @param {Number[]} options.offset
   */
  panTo(longitude, latitude, options) {
    this.map.panTo([longitude, latitude], options);
  }

  /**
   * Removes the state of a feature, setting it back to the default behavior.
   *
   * @param {Object} target
   * @param {string|Number} target.id
   * @param {string} target.source
   * @param {string} target.sourceLayer
   * @param {string} key
   */
  removeFeatureState(...targetAndKey) {
    if (this.isAlive) {
      this.map.removeFeatureState(...targetAndKey);
    }
  }

  /**
   * Removed the given image (by ID) from the style.
   *
   * @param {string} id
   * @returns {string?}  string describing an error if the operation was not successful, empty otherwise.
   */
  removeImage(id) {
    if (this.hasImage(id)) {
      this.map.removeImage(id);
    }
  }

  /**
   * Removes a GeoJSON layer from the map.
   *
   * @param {GeoJsonLayer} layer
   * @throws {Error}
   */
  removeLayer(layer) {
    if (layer instanceof GeoJsonLayer !== true) {
      throwTypeError({
        type: 'GeoJsonLayer',
        method: 'removeLayer',
        log: [layer, 'is not a GeoJsonLayer object.'],
      });
    }

    const layerId = getLayerId(layer);

    if (this.isAlive && this.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
  }

  /**
   * Removes a GeoJSON data source from the map.
   *
   * @param {GeoJsonSource} source
   * @throws {Error}
   */
  removeSource(source) {
    if (source instanceof GeoJsonSource !== true) {
      throwTypeError({
        type: 'GeoJsonSource',
        method: 'removeSource',
        log: [source, 'is not a GeoJsonSource object.'],
      });
    }

    if (this.getSource(source.getId())) {
      this.map.removeSource(source.getId());
    }
  }

  /**
   * Resizes the map to fit the available space.
   */
  resize() {
    if (this.isAlive) {
      this.map.resize();
    }
  }

  /**
   * Sets the longitude/latitude to zoom into when double-click occurs.
   * - uses same property names as the Mapbox double-click event
   *
   * @param {Number} lng
   * @param {Number} lat
   */
  onDoubleClickFocus(callback) {
    this.getDoubleClickFocus = callback;
  }

  /**
   * Sets the state of the given feature.
   *
   * @param {Object} feature
   * @param {string|Number} feature.id
   * @param {string} feature.source
   * @param {string} feature.sourceLayer
   * @param {Object.<string, any>} state
   */
  setFeatureState(feature, state) {
    if (this.isAlive) {
      this.map.setFeatureState(feature, state);
    }
  }

  /**
   * Shows/hides a given layer (by ID).
   *
   * @param {GeoJsonLayer} layer
   * @param {Boolean} show
   * @throws {Error}
   */
  showLayer(layer, show) {
    if (layer instanceof GeoJsonLayer !== true) {
      throwTypeError({
        type: 'GeoJsonLayer',
        method: 'showLayer',
        log: [layer, 'is not a GeoJsonLayer object.'],
      });
    }

    const layerExists = this.getLayer(layer.getId());

    if (layerExists) {
      this.map.setLayoutProperty(layer.getId(), 'visibility', show ? 'visible' : 'none');
    }
  }

  /**
   * Triggers an event on the map.
   *
   * @param {String} eventName
   */
  trigger(...args) {
    if (this.isAlive) {
      this.map.fire(...args);
    }
  }

  /**
   * Replaces the double-click focus handler with one that returns undefined.
   */
  unsetDoubleClickFocus() {
    this.getDoubleClickFocus = _noop;
  }

  /** `
   * Adds short-lived event handlers to detect when a user clicks
   * on the map while in motion.
   *
   * @param {Function} callback Callback to be called in the event that a click occurs during a move
   */
  watchOnceForClickInterruption(callback) {
    const onLockToIdealClick = (event) => {
      callback(event);
    };
    const onLockToIdealMoveEnd = () => {
      this.map.off('mousedown', onLockToIdealClick);
      this.map.off('moveend', onLockToIdealMoveEnd);
    };
    this.map.on('mousedown', onLockToIdealClick);
    this.map.on('moveend', onLockToIdealMoveEnd);
  }

  /**
   * Zooms and centers on the given coordinates.
   *
   * @param {Number} zoom
   * @param {Number} longitude
   * @param {Number} latitude
   */
  zoomTo(zoom, longitude, latitude) {
    this.map.flyTo({
      zoom,
      center: [longitude, latitude],
      duration: this.MAP_VIEW_CHANGE_DURATION,
    });
  }

  /**
   * Retrieves all features that reside within a given cluster for a given source.
   *
   * @param {String} sourceId
   * @param {String} clusterId
   * @returns {Bounds}
   * @throws {ClusterNotFoundError}
   */
  static async getClusterBounds(map, sourceId, clusterId) {
    const clusterResult = map.querySourceFeatures(sourceId, { filter: ['==', 'cluster_id', clusterId] });
    const cluster = clusterResult[0];

    const clusterFeatures = await new Promise((resolve, reject) => {
      try {
        map.getSource(sourceId).getClusterLeaves(clusterId, cluster.properties.point_count, 0, (error, features) => {
          if (error) {
            if (error.message.includes('No cluster with the specified id')) {
              reject(new ClusterNotFoundError(error.message));
              return;
            }
            throw error;
          }
          resolve(features);
        });
      } catch (error) {
        reject(error);
      }
    });

    // Collect cluster coordinates and points
    const points = clusterFeatures.map((feature) => feature.geometry.coordinates);

    // Get bounds for each cluster
    return new Bounds(Map.calculateBoundsByPoints(points));
  }

  /**
   * Watches for changes to the element with given selector and resizes the map
   * when the element is resized.
   *
   * @param {Object} map Mapbox instance
   * @param {String} fitToSelector Selector that identifies the element which retains the height the map is expected to match.
   * @returns {ResizeSensor}
   */
  static resizeFix(map, fitToSelector) {
    const fitToElement = document.querySelector(fitToSelector);
    if (!fitToElement) {
      return null;
    }

    /**
     * Note: the sensor object is used to allow the ResizeSensor callback
     * to be more testable. We can control the existence of the "map"
     * from outside this method.
     */
    const sensor = {
      map,
      resizeObserver: null,
    };

    sensor.resizeObserver = new ResizeObserver(
      _debounce(() => {
        if (sensor.map?.style) {
          sensor.map.resize();
        }
      }, Mapbox.RESIZE_DEBOUNCE)
    );

    sensor.resizeObserver.observe(fitToElement);

    return sensor;
  }
}

export default Mapbox;

/**
 * Retrieves the layer ID from the given GeoJsonLayer or string.
 *
 * @param {GeoJsonLayer|String}
 * @returns {String|undefined}
 */
function getLayerId(layer) {
  if (layer) {
    // Support using a GeoJsonLayer or a string representing the layer ID
    return layer instanceof GeoJsonLayer ? layer.getId() : layer;
  }
  return undefined;
}

/**
 * Returns true if the event is considered a user interaction.
 *
 * @param {Event} event
 * @returns {Boolean}
 */
function isUserInteraction(event) {
  // Note: WheelEvents are instances of MouseEvent
  return (event.originalEvent || window.event) instanceof MouseEvent;
}

/**
 * Throws an error along with a log for debugging.
 *
 * @param {String} errorMessage
 * @param {String} log
 * @throws {Error}
 */
function throwTypeError({ type, method, log }) {
  console.error(...log);
  throw new Error(`A non ${type} was given to Mapbox.${method}()!`);
}
