export default () => {
  let usersReadyResolve;
  const usersReady = new Promise((resolve) => {
    usersReadyResolve = resolve;
  });

  return {
    invite: null,
    /** @type {{ [key: string]: import('src/types/users-store').UserData }} */
    users: {},
    usersLoading: false,
    usersReady,
    extendedUserDetails: null,
    extendedUserLoading: false,
    usersError: {},
    usersErrorMessage: null,
    changePasswordError: false,
    alertSuggestions: {},
    usersReadyResolve,
  };
};
