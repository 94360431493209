/**
 * @param {string} vinText
 */
export function sanitizeVin(vinText) {
  let vin = vinText.toUpperCase();

  if (vin.length > 17) {
    if (vin.startsWith('I')) {
      vin = vin.substring(1);
    }
  }

  return {
    valid: vin.length === 17,
    text: vin,
  };
}

/**
 * VIN validator.
 *
 * kevinboutin on 3/11/18.
 *
 * Adapted from: https://gist.github.com/kevboutin/3ac029e336fc7cafd20c05adda42ffa5
 */

/**
 * Transliterate VIN characters. This is useful in validating VINs.
 *
 * @param {string} c The character to transliterate.
 * @return {number} The result of transliterated.
 */
// const transliterate = (c) => '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(c) % 10;

/**
 * Determine the check digit, which is needed to validate the VIN.
 *
 * @param {string} vin The entire VIN.
 * @return {string} The check digit character.
 */
// const getCheckDigit = (vin) => {
//   const map = '0123456789X';
//   const weights = '8765432X098765432';
//   let sum = 0;
//   for (let i = 0; i < 17; i += 1) {
//     sum += transliterate(vin.charAt(i)) * map.indexOf(weights.charAt(i));
//   }
//   return map.charAt(sum % 11);
// };

// export function validateVin(vin) {
//   const vinUpper = vin.toUpperCase();
//   if (vinUpper.length !== 17) return false;
//   return getCheckDigit(vinUpper) === vinUpper.charAt(8);
// }
