<template>
  <q-expansion-item
    :class="{ 'readonly-field': readonly }"
    :disable="loading || readonly"
    expand-icon="arrow_drop_down"
    expand-separator
    :header-class="`q-pa-none ${!readonly ? 'bg-grey-3' : ''}`"
    @before-show="initData"
  >
    <template #header>
      <div class="q-item__section column q-item__section--main justify-center" style="padding: 3px 12px">
        <div class="q-item__label q-item__label--caption text-caption">Vehicle Style</div>
        <div class="q-item__label">
          <div v-if="style && style.styleId">{{ style.year }} {{ style.make }} {{ style.model }} {{ style.trim }}</div>
          <div v-else-if="vehicle.makeName">
            {{ vehicle.makeName }} {{ vehicle.modelName }} {{ vehicle.modelYear }} {{ vehicle.trimName }}
          </div>
          <div v-else>Please specify a vehicle style.</div>
        </div>
      </div>
    </template>
    <q-card>
      <q-card-section>
        <q-select
          v-model="formData.year"
          v-capacitor-native-select
          class="q-mb-sm"
          display-value-sanitize
          emit-value
          filled
          label="Year"
          :loading="loading"
          :options="availableYears"
          options-sanitize
          @update:model-value="selectYear(formData.year)"
        />
        <q-select
          v-model="formData.make"
          v-capacitor-native-select
          class="q-mb-sm"
          :disable="!availableMakes || availableMakes.length === 0"
          display-value-sanitize
          emit-value
          filled
          label="Make"
          :loading="loading"
          option-label="make"
          option-value="make"
          :options="availableMakes || []"
          options-sanitize
          @update:model-value="selectMake(formData.make)"
        />
        <q-select
          v-model="formData.model"
          v-capacitor-native-select
          class="q-mb-sm"
          :disable="!availableModels || availableModels.length === 0"
          display-value-sanitize
          emit-value
          filled
          label="Model"
          :loading="loading"
          option-label="model"
          option-value="model"
          :options="availableModels || []"
          options-sanitize
          @update:model-value="selectModel(formData.model)"
        />
        <q-select
          v-model="formData.trim"
          v-capacitor-native-select
          class="q-mb-sm"
          :disable="!availableTrims || availableTrims.length === 0"
          display-value-sanitize
          emit-value
          filled
          label="Trim"
          :loading="loading"
          option-label="trim"
          option-value="trim"
          :options="availableTrims || []"
          options-sanitize
          @update:model-value="selectTrim(formData.trim)"
        />
        <q-select
          v-model="formData.style"
          v-capacitor-native-select
          :disable="!availableStyles || availableStyles.length === 0"
          display-value-sanitize
          emit-value
          filled
          label="Style"
          :loading="loading"
          option-label="style"
          :option-value="optionsValue"
          :options="availableStyles || []"
          options-sanitize
          @update:model-value="selectStyle(formData.style)"
        />
      </q-card-section>
    </q-card>
  </q-expansion-item>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    vehicle: {
      required: true,
      type: Object,
    },
    readonly: Boolean,
  },
  computed: {
    ...mapState('vehicleStyle', [
      'year',
      'make',
      'model',
      'trim',
      'style',
      'availableYears',
      'availableModels',
      'availableMakes',
      'availableTrims',
      'availableStyles',
      'loading',
    ]),
  },
  emits: ['update:model-value'],
  data() {
    return {
      formData: {
        make: null,
        model: null,
        trim: null,
        year: null,
        style: null,
      },
      hasInitialized: false,
    };
  },
  methods: {
    ...mapActions('vehicleStyle', [
      'clearStyle',
      'getStyleDetails',
      'selectYear',
      'selectMake',
      'selectModel',
      'selectTrim',
      'selectStyle',
    ]),
    async initData() {
      const { make, model, trim, year } = this.formData;
      const { styleId, styleProvider } = this.vehicle;

      if (!year) {
        return;
      }

      await this.selectYear(year);

      if (!make) {
        return;
      }

      await this.selectMake(make);

      if (!model) {
        return;
      }

      await this.selectModel(model);

      if (!trim || !this.availableTrims.some(({ trim: t }) => trim === t)) {
        this.formData.trim = null;
        this.explainUpdate();
        return;
      }

      await this.selectTrim(trim);

      if (styleProvider !== 'dataone') {
        this.explainUpdate();
      } else if (styleId) {
        const [{ name }] = await this.getStyleDetails(styleId);
        const start = name.indexOf(trim);
        if (start >= 0) {
          const style = name.substring(start);
          await this.selectStyle({ style, vehicleId: styleId });
        }
      }

      this.hasInitialized = true;
    },
    explainUpdate() {
      this.$q.notify({
        color: 'primary',
        icon: 'lightbulb',
        message: 'Please provide more information about your vehicle for future functionality.',
      });
    },
    onUpdate() {
      const { hasInitialized, style } = this;
      this.$emit('update:model-value', { style, hasInitialized });
    },
    optionsValue(opt) {
      return opt;
    },
  },
  async mounted() {
    if (this.vehicle) {
      const { makeName: make = '', modelName: model = '', trimName: trim = '', modelYear: year = '' } = this.vehicle;
      this.formData = { make, model, style: null, trim, year };
    }
    this.hasInitialized = false;
  },
  beforeUnmount() {
    this.clearStyle();
  },
  watch: {
    make() {
      this.formData.make = this.make;
    },
    model() {
      this.formData.model = this.model;
    },
    style(style) {
      this.formData.style = this.style;
      if (style) {
        this.onUpdate();
      }
    },
    trim() {
      this.formData.trim = this.trim;
    },
    year() {
      this.formData.year = this.year;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.q-item) {
  padding: 8px 12px 8px 0px;
}
</style>
