<template>
  <div class="row full-height">
    <q-btn
      class="secondary-action no-btn-wrapper-padding text-weight-regular line-height-1"
      color="silver"
      :disable="isDisabled"
      flat
      padding="0"
      :ripple="!isDisabled"
      @click.stop="$emit('click', $event)"
      @touchstart.stop
    >
      <q-btn
        class="inner-btn line-height-1"
        :color="isDisabled ? 'white' : 'silver'"
        padding="xs sm"
        :ripple="false"
        square
        text-color="dark"
      >
        <div v-if="isMapOption" class="line-height-1">Live Map</div>
        <div v-else-if="isDetailsOption && isVehicleAsset">Trips</div>
        <div v-else>{{ $q.screen.width >= 420 ? 'Details' : 'Info' }}</div>
        <ToolTip v-if="tooltip" :offset="[0, 0]">{{ tooltip }}</ToolTip>
      </q-btn>
    </q-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ToolTip from 'components/ToolTip.vue';

export default {
  components: { ToolTip },
  name: 'SearchResultButton',
  props: {
    action: String,
    result: Object,
  },
  computed: {
    ...mapState('app', ['isLiveMapRoute']),
    isDetailsOption() {
      return this.action === 'details-click';
    },
    isDisabled() {
      return this.isMapOption && this.isMappable && !this.isOnMap;
    },
    isMapOption() {
      return this.action === 'live-map-click';
    },
    isMappable() {
      return this.isVehicleAsset || this.result.type === 'place';
    },
    isOnMap() {
      return Boolean(this.result.hasLocation && (this.result.liveMapKey || this.result.bounds));
    },
    isVehicleAsset() {
      return ['asset', 'vehicle'].includes(this.result.type);
    },
    tooltip() {
      return this.isDisabled ? `This ${this.result.type} does not have a location` : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.secondary-action {
  &:hover:not(.disabled) {
    background: $grey-3 !important;

    :deep(.inner-btn) {
      background: white !important;
    }
  }
}

.inner-btn {
  transition: all 0.2s ease-in;

  &:hover :deep(.q-focus-helper),
  &:focus :deep(.q-focus-helper) &:active :deep(.q-focus-helper) {
    display: none;
  }

  :deep(.q-btn__wrapper:before) {
    box-shadow: none !important;
  }
}
</style>
