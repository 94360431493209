export default class MapboxControl {
  /** @property {Component} */
  component = null;

  /** @property {Mapbox} */
  map = null;

  /**
   *
   * @param {Component} component
   */
  constructor(component) {
    this.component = component;
  }

  /**
   * Called by Mapbox, creates a control (container) with a button and returns the control.
   *
   * @param {Mapbox} map
   * @returns {HTMLElement}
   */
  onAdd(map) {
    this.map = map;
    return this.component;
  }

  /**
   * Detaches the container from the DOM and clears the map reference.
   */
  onRemove() {
    const { parentNode } = this.component || {};
    if (parentNode) {
      parentNode.removeChild(this.component);
    }
    this.map = undefined;
  }
}
