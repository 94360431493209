<template>
  <ZubieDialog
    ref="dialog"
    action-button-data-t="editAssetAdd"
    :action-button-text="saveButtonText"
    cancel-button-data-t="editAssetCancel"
    :content-style="`max-width: ${$q.screen.xs ? '100%;' : '500px'}`"
    :dirty="isDirty"
    :maximized="$q.screen.xs"
    :processing="isSaving"
    :title="`${assetKey ? 'Edit' : 'Add'} Asset`"
    @submit="$refs.assetForm.save($event)"
  >
    <AssetForm
      ref="assetForm"
      :asset-key="assetKey"
      class="form"
      @change="isDirty = true"
      @ready="isReady = true"
      @saved="onSave"
      @saving="isSaving = $event"
    />
  </ZubieDialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AssetForm from 'components/assets/AssetForm.vue';

export default {
  name: 'EditAssetDialog',
  props: {
    assetKey: String,
    visible: Boolean,
  },
  components: {
    AssetForm,
  },
  computed: {
    ...mapState('assets', ['assetTypes']),
    ...mapGetters('assets', ['assets']),
    saveButtonText() {
      if (this.assetKey) {
        return this.isSaving ? 'Saving' : 'Save';
      }
      return this.isSaving ? 'Adding' : 'Add';
    },
  },
  data() {
    return {
      isDirty: false,
      isSaving: false,
    };
  },
  methods: {
    onSave(asset) {
      const { nickname } = asset;
      const action = this.assetKey ? 'updated' : 'created new';
      const subject = `asset${nickname ? `: ${nickname}` : ''}`;
      this.$q.notify({
        color: 'positive',
        icon: 'check_circle',
        message: `Successfully ${action} ${subject}`,
      });
      this.$refs.dialog.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  min-width: 0;

  @media (min-width: 420px) {
    min-width: 420px;
  }
}
</style>
