<template>
  <q-btn class="notifications" color="white" dense flat square @click="onClick">
    <q-icon name="notifications" size="2em" />
    <NotificationsBadge v-if="areRecentUnread" />
    <NotificationsMenu :show="showMenu" @hide="showMenu = false" />
  </q-btn>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import NotificationsBadge from 'components/notifications/NotificationsBadge.vue';
import NotificationsMenu from 'components/notifications/NotificationsMenu.vue';

export default {
  name: 'NotificationsButton',
  components: {
    NotificationsBadge,
    NotificationsMenu,
  },
  computed: {
    ...mapState('maps', ['listDialogOpen']),
    ...mapGetters('search', ['searchingOnMobile']),
    ...mapGetters('notifications', ['areRecentUnread']),
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    ...mapActions('map', ['closeListDialog']),
    async onClick() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        await this.closeListDialog();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  height: 34px;
  width: 34px;
}

:deep(.notification-badge) {
  left: 6px;
  bottom: 4px;
}
</style>
