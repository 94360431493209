import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { Screen } from 'quasar';
import raf from 'raf';
import { toRaw } from 'vue';
import LocationOff from 'src/assets/location-off.svg?raw';
import LocationOn from 'src/assets/location-on.svg?raw';
import SearchResultMarker from 'src/assets/search-result-marker.svg?raw';
import AnimationInstruction from 'src/models/AnimationInstruction';
import Bounds from 'src/models/Bounds';
import GeoJsonFeature from 'src/models/GeoJsonFeature';
import MapImage from 'src/models/MapImage';
import { isFullscreen, onFullscreenChange } from 'src/services/fullscreen';
import layers from 'src/services/geoJson/layers';
import sources from 'src/services/geoJson/sources';
import PlaceFencesSource from 'src/services/geoJson/sources/PlaceFencesSource';
import PlacePointsSource from 'src/services/geoJson/sources/PlacePointsSource';
import SearchResultsSource from 'src/services/geoJson/sources/SearchResultsSource';
import { trackEvent } from 'src/services/intercom';
import { ClusterNotFoundError } from 'src/services/map/ClusterNotFoundError';
import MapService from 'src/services/map/index';
import Map from 'src/services/map/Map';
import Mapbox from 'src/services/map/Mapbox';
import { navigateToUrl } from 'src/services/navigation';
import { rafSequence } from 'src/services/raf';
import { setTimeoutPromise } from 'src/services/setTimeout';
import { svgToImage } from 'src/services/svg/tools';

const {
  assetsClusterLayer,
  assetsClusterCountLayer,
  assetsLayer,
  assetsTextLayer,
  searchResultsLayer,
  placeFencesBoundaryLayer,
  placeFencesLayer,
  placePointsLayer,
  selectedAssetLayer,
  selectedAssetByTagLayer,
  assetsByTagLayer,
  trafficLayer,
} = layers;

const { assetsSource, searchResultsSource, placeFencesSource, placePointsSource, selectedAssetSource } = sources;

/**
 * Initializes the map service.
 *
 * @param {MapStoreActionContext} context
 * @param {Mapbox} map
 */
export async function init(context, map) {
  MapService.setMap(map);

  // Store the starting zoom level
  context.commit('setZoomLevel', MapService.getZoom());

  // Start listening for layer click events
  context.dispatch('addMapClickHandler');

  // Start listening for double-click events
  MapService.enableDoubleClickZoom();

  // Start listening for map change events
  context.dispatch('addMapChangeHandler');

  // Start listening for vehicle change events
  context.dispatch('addAssetChangeHandlers');

  // Start listening for fullscreen changes
  context.dispatch('addFullscreenChangeHandlers');

  // Might be Mapbox specific, fixes issues with map not resizing appropriately
  // Mapbox.resizeFix(map, '.mapboxgl-map');

  /**
   * Fit to ideal bounds on first load
   * - check if anything is reserving onLoad focus first
   */
  if (!context.state.reservingOnLoadFocus) {
    context.dispatch('fitToIdealBounds');
  }

  // Add search result marker (for map search)
  MapService.addImage('search_result_marker', await svgToImage(SearchResultMarker, 40, 40));

  // Add hidden place image
  MapService.addImage('location_on', await svgToImage(LocationOn, 24, 24));
  MapService.addImage('location_off', await svgToImage(LocationOff, 24, 24));

  // Add text-box image
  await context.dispatch('updateTextBoxImage');

  // Add layers
  assetsTextLayer.setUsingTextbox(context.state.mapStyle === 'SATELLITE');
  await context.dispatch('addLayers');

  // Map is ready, remove focus reservation
  context.commit('reserveOnLoadFocus', null);

  // Flag map as initialized
  context.commit('setLiveMapInitialized', true);

  // Trigger the focus, once idle
  MapService.once('idle', async () => {
    await setTimeoutPromise(250); // Give map some time to settle
    MapService.trigger('focus');
  });
}

/**
 * Adds a handler for route changes and selects/unselects based on the route.
 *
 * @param {MapStoreActionContext} context
 * @param {import('vue-router').Router} router
 */
export function initRouterHandler(context, router) {
  router.afterEach(async (to) => {
    // Handle vehicle/asset selection
    if (to.name === 'map-selected') {
      // Select asset base on route
      context.dispatch('assets/selectAsset', to.params.key, { root: true });
    } else if (to.name === 'map') {
      // Unselect asset whenever navigating directly to Live Map
      context.dispatch('assets/unselectAsset', null, { root: true });
    }
  });
}

/**
 * Adds and establishes GeoJSON layers and data.
 *
 * @param {MapStoreActionContext} context
 */
export async function addLayers(context) {
  MapService.addSource(placeFencesSource);
  MapService.addSource(placePointsSource);
  MapService.addSource(selectedAssetSource);
  MapService.addSource(searchResultsSource);

  MapService.addLayer(placeFencesBoundaryLayer, null, true);
  MapService.addLayer(placeFencesLayer, null, true);
  MapService.addLayer(placePointsLayer);
  MapService.addLayer(searchResultsLayer);

  context.dispatch('applyLayerVisibility');
  context.dispatch('updatePlacesLayer');
  await context.dispatch('addAssetLayers');
}

/**
 * Adds/updates the vehicle/asset related sources & layers.
 *
 * @param {MapStoreActionContext} context
 */
export async function addAssetLayers(context) {
  // Enable/disable clustering
  assetsSource.setClusterEnabled(context.state.clusterAssets);

  // Add sources
  MapService.addSource(assetsSource);

  // Add cluster layers
  MapService.addLayer(assetsClusterCountLayer);
  MapService.addLayer(assetsClusterLayer, assetsClusterCountLayer);

  // Add/update vehicle layer
  await context.dispatch('updateAssetsLayer', context.rootGetters['assets/visibleAssets']);
}

/**
 * Adds event handlers for clicks on assets & clusters.
 *
 * @param {MapStoreActionContext} context
 */
export function addMapClickHandler(context) {
  let isSelectingAsset = false;

  const selectAsset = async (event) => {
    const selectedKey = _get(event, 'features.0.properties.key');
    if (selectedKey) {
      navigateToUrl({
        name: 'map-selected',
        params: {
          key: selectedKey,
        },
      });
    }
    isSelectingAsset = true;
    await setTimeoutPromise();
    isSelectingAsset = false;
  };

  const reselectAsset = (event) => {
    const [first] = event?.features || [];
    const selectedKey = first?.properties?.key;
    if (selectedKey) {
      context.commit('setSelectedDrawerShouldUpdate', true);
      context.dispatch('assets/selectAsset', selectedKey, { root: true });
    }
  };

  MapService.onLayerClick(assetsLayer, selectAsset);
  MapService.onLayerClick(assetsByTagLayer, selectAsset);

  MapService.onLayerClick(selectedAssetLayer, reselectAsset);
  MapService.onLayerClick(selectedAssetByTagLayer, reselectAsset);

  MapService.onLayerClick(assetsClusterLayer, (event) => {
    if (!isSelectingAsset) {
      context.dispatch('zoomToCluster', event);
    }
  });

  MapService.onLayerClick(searchResultsLayer, (event) => {
    const { index } = event.features?.[0]?.properties || {};
    if (index !== undefined) {
      if (context.state.selectedSearchResultIndex === index) {
        context.dispatch('zoomToSearchResult');
      } else {
        context.dispatch('selectSearchResult', index);
      }
    }
  });
}

/**
 * Zooms map into the event's cluster bounds.
 *
 * @param {MapStoreActionContext} context
 * @param {Event} event
 */
export async function zoomToCluster(context, event) {
  const clusterId = _get(event, 'features.0.properties.cluster_id');
  const source = _get(event, 'features.0.source');

  // Do not proceed if cluster ID or source is missing
  if (!clusterId || !source) {
    return;
  }

  let bounds = null;
  try {
    bounds = await Mapbox.getClusterBounds(MapService.map, source, clusterId);
  } catch (error) {
    if (error instanceof ClusterNotFoundError) {
      // Do nothing, these things do happen
    } else {
      throw error;
    }
  }

  if (bounds !== null) {
    MapService.fitBounds(bounds);
    context.commit('lockToIdealBounds', false);
  }
}

/**
 * Adds a change handler to the map to handle publishing of bounds
 * and ideal bounds locks.
 *
 * @param {MapStoreActionContext} context
 */
export function addMapChangeHandler(context) {
  let publishBoundsThrottle;
  let publishInProgress = Promise.resolve();
  let waitingForPublish = false;

  async function throttledPublishBounds() {
    if (publishBoundsThrottle && publishBoundsThrottle.isCanceled !== true) {
      publishBoundsThrottle.cancel(); // canceling previous call that's waiting for throttle
    }

    if (waitingForPublish) {
      return; // next update already waiting, throw this away
    }

    try {
      // Wait for throttle
      publishBoundsThrottle = setTimeoutPromise(context.state.publishBoundsThrottleDelay);
      await publishBoundsThrottle;
      // Clear throttle
      publishBoundsThrottle = null;

      // Wait for publish in progress
      waitingForPublish = true;
      await publishInProgress;
      waitingForPublish = false;
    } catch (e) {
      if (!e.isCanceled) {
        throw e;
      }
      return;
    }

    publishInProgress = context.dispatch('publishMapBounds');
  }

  MapService.onMapChange(async (event) => {
    if (event.isUserInteraction) {
      context.commit('lockToIdealBounds', false);
      context.commit('matchesIdealBounds', false);
    }

    await throttledPublishBounds();

    context.commit('fittingTo', new Bounds());

    context.commit('setZoomLevel', MapService.getZoom());
  });
}

/**
 * Adds watchers on the vehicle/asset related GeoJSON data to automatically update
 * the map source data and animate any vehicles in motion.
 *
 * @param {Object} context
 */
export function addAssetChangeHandlers(context) {
  this.watch(
    (state) => state.map.clusterableAssetsGeoJson,
    (newValue, oldValue) => {
      context.dispatch('animateMarkers', {
        source: MapService.getSource(assetsSource.getId()),
        updatedCollection: newValue,
        existingCollection: oldValue,
      });
    }
  );
  this.watch(
    (state) => state.map.selectedAssetGeoJson,
    (newValue, oldValue) => {
      context.dispatch('animateMarkers', {
        source: MapService.getSource(selectedAssetSource.getId()),
        updatedCollection: newValue,
        existingCollection: oldValue,
      });
    }
  );
}

/**
 * Adds an event listener that updates the asset GeoJSON when the fullscreen
 * state changes.
 *
 * @param {Object} context
 */
export function addFullscreenChangeHandlers(context) {
  onFullscreenChange(async () => {
    // Assets layers will change based on fullscreen state
    MapService.removeLayer(assetsByTagLayer);
    MapService.removeLayer(assetsLayer);
    MapService.removeLayer(assetsTextLayer);
    context.dispatch('updateTextBoxImage');
    context.dispatch('updateAssetsLayer', context.rootGetters['assets/visibleAssets']);
  });
}

/**
 *
 * @param {Object} context
 * @param {Object} params
 * @param {Object | undefined} params.source GeoJSON source object.
 * @param {Object | null} params.updatedCollection Updated GeoJSON data.
 * @param {Object} params.existingCollection Existing GeoJSON data.
 */
export async function animateMarkers(context, { source, updatedCollection, existingCollection }) {
  const vehiclesThatMoved = {};

  if (!updatedCollection || !source) {
    // Updated collection is empty or source no longer exists, abort
    return;
  }

  if (!_isEmpty(existingCollection) && MapService.getZoom() >= 4) {
    Object.values(updatedCollection).forEach((updated) => {
      const { key } = updated.properties;
      const { coordinates: updatedCoords } = updated?.geometry || {};
      const existing = existingCollection[key];
      const { coordinates: existingCoords } = existing?.geometry || {};

      if (GeoJsonFeature.hasMovedFrom(updated, existing) && MapService.boundsContains(updatedCoords)) {
        vehiclesThatMoved[key] = new AnimationInstruction({
          key,
          from: existingCoords,
          to: updatedCoords,
        });
      }
    });
  }

  // Immediately update vehicles that will not be animated
  const nonMovingVehicles = Object.values(updatedCollection).filter(
    (feature) => !vehiclesThatMoved[feature.properties.key]
  );

  /**
   * If there are some non-moving vehicles or if our update empties out
   * the collection (e.g. when selected vehicle is deselected), update
   * the source data immediately.
   */
  if (!_isEmpty(nonMovingVehicles) || _isEmpty(updatedCollection)) {
    source.setData({
      type: 'FeatureCollection',
      // if updated collection is empty, this array will be empty
      features: nonMovingVehicles,
    });
  }

  if (!_isEmpty(vehiclesThatMoved)) {
    /** @var {Array} animatedVehicles */
    let animatedVehicles = await context.rootState.app.broker.generateAnimationFrames({
      items: Object.values(vehiclesThatMoved),
      zoom: MapService.getZoom(),
    });

    // Animate moving vehicles
    await rafSequence({
      onFrame() {
        // Update the coordinates for each vehicle in motion
        animatedVehicles.forEach(({ key, motionSteps }) => {
          const coordinates = motionSteps.shift();
          updatedCollection[key].geometry.coordinates = coordinates;
        });

        // Remove animation collections that have been completed
        animatedVehicles = animatedVehicles.filter(({ motionSteps }) => !_isEmpty(motionSteps));

        // Update the Mapbox source data
        source.setData({
          type: 'FeatureCollection',
          features: Object.values(updatedCollection),
        });
      },
      doneCondition() {
        return animatedVehicles.length === 0;
      },
    });
  }
}

/**
 * Shows layers based on the state.
 *
 * @param {Object} context
 */
export function applyLayerVisibility(context) {
  if (MapService.isLoaded()) {
    MapService.showLayer(trafficLayer, context.state.showTraffic);
    MapService.showLayer(placePointsLayer, context.state.showPlaces);
    MapService.showLayer(placeFencesLayer, context.state.showPlaces);
    MapService.showLayer(placeFencesBoundaryLayer, context.state.showPlaces);
    MapService.showLayer(assetsLayer, !context.state.showTagColors);
    MapService.showLayer(assetsByTagLayer, context.state.showTagColors);
    MapService.showLayer(assetsTextLayer, true);
    MapService.showLayer(selectedAssetLayer, !context.state.showTagColors);
    MapService.showLayer(selectedAssetByTagLayer, context.state.showTagColors);
  }
}

/**
 * Focuses map on the given asset.
 *
 * @param {MapStoreActionContext} context
 * @param {Object} payload
 * @param {Asset|Vehicle} payload.asset
 * @param {Boolean} payload.zoom
 */
export function focusOnAsset(context, { asset, zoom = false, ignoreTrip = false } = {}) {
  let bounds;

  const { encodedPolyline } = context.rootState.trips.selectedVehicleTrip;
  if (!ignoreTrip && encodedPolyline) {
    bounds = Map.calculateBoundsByPolyline(encodedPolyline);
  }

  const location = asset?.location;
  if (location) {
    const { latitude, longitude } = location;
    if (latitude && longitude) {
      context.dispatch('focusOnCoords', {
        bounds,
        longitude,
        latitude,
        zoom,
      });
    }
  }
}

export async function setIdealBounds(context, idealBoundsValues) {
  // Do not update ideal bounds unless there's a value
  if (idealBoundsValues === null) {
    return;
  }

  const idealBounds = new Bounds(idealBoundsValues);

  // Set ideal bounds if it doesn't match what we have in the store
  if (!idealBounds.equals(context.state.idealBounds)) {
    context.commit('setIdealBounds', idealBounds);
  }

  if (context.state.pendingFitToIdeal || context.state.lockToIdealBounds) {
    context.dispatch('fitToIdealBounds');
    context.commit('setPendingFitToIdeal', false);
  } else if (MapService.isLoaded()) {
    context.commit('matchesIdealBounds', MapService.getBounds().equals(idealBounds));
  }
}

/**
 * Fits the map viewport to the given bounds.
 *
 * @param {MapStoreActionContext} context
 * @param {Bounds} bounds
 */
export async function fitToBounds(context, bounds) {
  if (!MapService.isLoaded()) {
    context.commit('reserveOnLoadFocus', 'fitToBounds');
  }

  MapService.onReadyOnce('fitToBounds', () => {
    context.commit('fittingTo', bounds);
    MapService.fitBounds(bounds);
  });
}

/**
 * Fits the map viewport to ideal bounds and locks to ideal bounds if specified.
 *
 * @param {MapStoreActionContext} context
 * @param {Object} options
 * @param {Boolean} options.lock
 */
export async function fitToIdealBounds(context, { lock } = {}) {
  context.commit('matchesIdealBounds');

  // TODO: see if we can use a Map.onReady callback instead of "pendingFitToIdeal"
  if (MapService.isLoaded() && !context.state.idealBounds.isEmpty) {
    // Tell map to fit to the ideal bounds, unless it's already in the process of fitting
    if (context.state.fittingTo.isEmpty || !context.state.fittingTo.equals(context.state.idealBounds)) {
      context.dispatch('fitToBounds', context.state.idealBounds);
    }

    if (lock !== undefined) {
      context.dispatch('lockToIdealBounds', lock);
    }
  } else {
    context.commit('setPendingFitToIdeal', true);
  }
}

/**
 * Pans/zooms the map to the given lat/long.
 *
 * @param {MapStoreActionContext} context
 * @param {Object} options
 * @param {Array<Number[]>} options.bounds
 * @param {Number} options.longitude
 * @param {Number} options.latitude
 * @param {Boolean} options.zoom
 */
export function focusOnCoords(context, { bounds, longitude, latitude, offset = [0, 0], zoom }) {
  /**
   * If the map hasn't loaded yet, reserve the onLoad focus
   * - this prevents things like locking to ideal bounds from overriding
   *   the zooming or panning
   */
  if (!MapService.isLoaded()) {
    context.commit('reserveOnLoadFocus', 'focusOnCoords');
  }

  MapService.onReadyOnce('focusOnCoords', () => {
    if (zoom) {
      if (bounds) {
        MapService.fitBounds(bounds, {
          padding: {
            top: 100,
            right: 100,
            bottom: Screen.gt.sm ? 100 : 140, // padding for drawer
            left: 100,
          },
        });
      } else {
        MapService.zoomTo(MapService.MAX_AUTO_ZOOM, longitude, latitude);
      }
    } else {
      MapService.panTo(longitude, latitude, { offset });
    }
  });
}

export function lockToIdealBounds(context, lock) {
  context.commit('lockToIdealBounds', lock);
  if (lock === true) {
    context.dispatch('vehicles/toggleFollow', null, { root: true });

    // Might be Mapbox specific
    MapService.watchOnceForClickInterruption(() => {
      // If a click interrupts a lock-to-ideal transition, unlock
      context.dispatch('lockToIdealBounds', false);
    });
  }
}

/**
 * Publishes current map bounds to the Data Broker.
 *
 * @param {MapStoreActionContext} context
 */
export async function publishMapBounds(context) {
  if (MapService.isLoaded()) {
    await context.rootState.app.broker.publishMapBounds(MapService.getBounds());
    await context.dispatch('updatePlacesLayer');
  }
}

export function setContextualMenuOpen(context, isOpen) {
  context.commit('setContextualMenuOpen', isOpen);
}

/**
 * Resizes the map (if loaded).
 */
export function resize() {
  if (MapService.isLoaded()) {
    MapService.resize();
  }
}

/**
 * Sets the Live Map styles.
 *
 * @param {MapStoreActionContext} context
 * @param {'DEFAULT' | 'SATELLITE' | 'LIGHT' | 'DARK'} mapStyle
 */
export function setMapStyle(context, mapStyle) {
  context.commit('mapStyle', mapStyle);
}

export function setTripsMapStyle(context, mapStyle) {
  context.commit('setTripsMapStyle', mapStyle);
}

/**
 * Sets the Asset Overview - Most Recent Location map style.
 *
 * @param {MapStoreActionContext} context
 * @param {String} mapStyle
 */
export function setAssetRecentMapStyle(context, mapStyle) {
  context.commit('setAssetRecentMapStyle', mapStyle);
}

/**
 * Sets "show Places" flag.
 *
 * @param {MapStoreActionContext} context
 * @param {boolean} show
 */
export function setShowPlaces(context, show) {
  context.commit('showPlaces', show);
  context.dispatch('applyLayerVisibility');
}

/**
 * Sets the showing of tag colors on map.
 *
 * @param {MapStoreActionContext} context
 * @param {boolean} show
 */
export function setShowTagColors(context, show) {
  context.commit('showTagColors', show);
  context.dispatch('updateAssetsLayer', context.rootGetters['assets/visibleAssets']); // mostly to generate icons
  context.dispatch('applyLayerVisibility');
}

/**
 * Sets the showing of traffic on map.
 *
 * @param {MapStoreActionContext} context
 * @param {boolean} show
 */
export function setShowTraffic(context, show) {
  context.commit('showTraffic', show);
  context.dispatch('applyLayerVisibility');
}

/**
 * Sets the nearby map style to the given style.
 *
 * @param {MapStoreActionContext} context
 * @param {String} mapStyle
 */
export function setNearbyMapStyle(context, mapStyle) {
  context.commit('setNearbyMapStyle', mapStyle);
}

export function togglePlaces(context) {
  const visibility = !context.state.showPlaces;
  context.dispatch('setShowPlaces', visibility);
}

/**
 * Toggles showing tag colors on map.
 *
 * @param {MapStoreActionContext} context
 */
export function toggleTagColors(context) {
  const visibility = !context.state.showTagColors;
  context.dispatch('setShowTagColors', visibility);
}

export function toggleTraffic(context) {
  const visibility = !context.state.showTraffic;
  context.dispatch('setShowTraffic', visibility);
}

/**
 * Toggles clustered vehicles visibility and replaces vehicle layers/source.
 *
 * @param {MapStoreActionContext} context
 */
export async function toggleClusterAssets(context) {
  const visibility = !context.state.clusterAssets;
  context.commit('setClusterEnabled', visibility);

  MapService.removeLayer(assetsClusterLayer);
  MapService.removeLayer(assetsClusterCountLayer);
  MapService.removeLayer(assetsLayer);
  MapService.removeLayer(assetsTextLayer);
  MapService.removeLayer(assetsByTagLayer);
  MapService.removeSource(assetsSource);

  await context.dispatch('addAssetLayers');
  context.dispatch('applyLayerVisibility');
}

export function triggerMapMove() {
  raf(() => {
    MapService.trigger('move');
  });
}

/**
 * Updates the search result layer to display the selected search result.
 *
 * @param {MapStoreActionContext} context
 */
export function updateSearchResultLayer(context) {
  const { searchResults } = context.state;

  const features = searchResults.map((result, index) => {
    const { center, shortName: label } = result;
    const geoJsonFeature = new GeoJsonFeature(center, {
      label,
      index,
    });
    return geoJsonFeature;
  });

  const source = MapService.getSource(SearchResultsSource.id);
  if (source) {
    source.setData({
      type: 'FeatureCollection',
      features,
    });
  }
}

/**
 * Updates the place fences/points sources with place data.
 *
 * @param {MapStoreActionContext} context
 */
export async function updatePlacesLayer(context) {
  if (!MapService.isLoaded()) {
    return;
  }

  const placeFences = MapService.getSource(PlaceFencesSource.id);
  const placePoints = MapService.getSource(PlacePointsSource.id);

  if (!placeFences || !placePoints) {
    return; // no updates are needed, at least one of the sources do not exist
  }

  const placesGeoJson = context.rootGetters['places/placesGeoJson'];
  const { features } = placesGeoJson.fences;
  const liveMapBounds = MapService.getBounds();
  const currentFeatures = placeFences.serialize().data.features;

  // Check for visibility differences
  let featuresDiffer = !_isEqual(
    currentFeatures.map(({ properties }) => properties?.isVisible),
    features.map(({ properties }) => properties?.isVisible)
  );

  // Filter out Places that cover the viewport
  const { features: inViewportFences, hash } = await context.rootState.app.broker.onlyInViewportFeatures({
    viewportBounds: liveMapBounds.toPolygon(),
    features: features.map((feature) => ({
      ...feature,
      geometry: toRaw(feature.geometry),
    })),
  });

  if (hash !== context.state.inViewportFencesHash) {
    featuresDiffer = true;
    context.commit('setInViewportFencesHash', hash);
  }

  // If updated Places and map data differ in some way, update the data
  if (featuresDiffer) {
    placeFences.setData({
      ...placesGeoJson.fences,
      features: inViewportFences,
    });
  }

  placePoints.setData(placesGeoJson.points);

  context.commit('setPlacesLayerReady', true);
}

/**
 * Updates the vehicles/assets GeoJSON source with the given set of vehicles/assets.
 *
 * @param {Object} context
 * @param {Array<import('src/models/Vehicle').default|import('src/models/Asset').default>} assets
 */
export async function updateAssetsLayer(context, assets) {
  if (!MapService.isAlive || context.state.updateInProgress) {
    return;
  }

  context.commit('updateInProgress', true);

  await Map.addSvgMarkerImages(MapService, assets, context.state.showTagColors);

  if (!MapService.isAlive) {
    return;
  }

  const geoJsonVehicles = assets.map((vehicle) => {
    const geoJson = vehicle.toGeoJson();

    if (['moving', 'movingNoHeading', 'idle'].includes(geoJson.properties.motionStatus)) {
      geoJson.properties.textOffset = isFullscreen() ? [0, 1.35] : [0, 1.1];
    } else {
      geoJson.properties.textOffset = isFullscreen() && context.state.mapStyle === 'SATELLITE' ? [0, 0.2] : [0, 0];
    }

    return geoJson;
  });

  // Update the selected status of the selected vehicle GeoJSON feature
  const selectedAssetGeoJson = {};
  const clusterableAssetsGeoJson = {};
  geoJsonVehicles.forEach(
    /** @param {import('src/models/GeoJsonFeature').default} asset */ (asset) => {
      const isSelectedAsset = asset.properties.key === context.rootState.assets.selectedKey;

      if (isSelectedAsset) {
        selectedAssetGeoJson[asset.properties.key] = asset;
      } else {
        clusterableAssetsGeoJson[asset.properties.key] = asset;
      }
    }
  );

  context.commit('setClusterableAssetsGeoJson', clusterableAssetsGeoJson);
  context.commit('setSelectedAssetGeoJson', selectedAssetGeoJson);

  MapService.addLayer(assetsLayer, assetsClusterCountLayer);
  MapService.addLayer(assetsTextLayer, assetsClusterCountLayer);
  MapService.addLayer(assetsByTagLayer, assetsClusterCountLayer);

  MapService.addLayer(selectedAssetLayer);
  MapService.addLayer(selectedAssetByTagLayer);

  context.commit('updateInProgress', false);
}

/**
 * Updates the text-box image being used by the assets text layer.
 *
 * @param {MapStoreActionContext} context
 */
export async function updateTextBoxImage() {
  const TEXT_BOX_ID = 'text-box';

  await MapService.removeImage(TEXT_BOX_ID);

  const textBoxImage = new MapImage(TEXT_BOX_ID, 'img:text-box.png', {
    height: 56,
    width: 60,
  });

  const { image } = await textBoxImage.load(MapService.map);
  const contentRange = isFullscreen() ? [10, 5, 45, 50] : [7, 0, 48, 45];
  await MapService.addImage(TEXT_BOX_ID, image, {
    stretchX: [[10, 40]],
    stretchY: [[15, 49]],
    content: contentRange,
  });
}

/**
 * Retrieves travel times to a given location for each coordinate given.
 *
 * @param {MapStoreActionContext} context
 * @param {Object} payload
 * @param {Number[]} payload.location
 * @param {Array<Number[]>} payload.coordinates
 * @returns {Array}
 */
export async function getTravelTimes(context, { location, coordinates }) {
  return context.rootState.app.broker.getTravelTimes({ location: toRaw(location), coordinates });
}

/**
 * Retrieves an isochrone from the Data Broker.
 *
 * @param {MapStoreActionContext} context
 * @param {Object} payload
 * @param {Number[]} payload.location
 * @param {Number[]} payload.contours
 * @returns {Object}
 */
export async function getIsochrone(context, { location, contours }) {
  return context.rootState.app.broker.fetchAndTransform({
    fn: 'getIsochrone',
    params: {
      location: toRaw(location),
      contours,
    },
  });
}

/**
 * Retrieves distances (as the crow flies) for each of the coordinates to the given location.
 *
 * @param {MapStoreActionContext} context
 * @param {Object} payload
 * @param {Number[]} payload.location
 * @param {Array<Number[]>} payload.coordinateSets
 * @returns {Promise<Number[]>}
 */
export async function getDistancesFromLocation(context, { location, coordinates }) {
  return context.rootState.app.broker.getDistancesFromLocation({ location: toRaw(location), coordinates });
}

/**
 * Sets the nearby vehicles filtered flag.
 *
 * @param {MapStoreActionContext} context
 * @param {Boolean} isFiltered
 */
export function setNearbyVehiclesFiltered(context, isFiltered) {
  context.commit('setNearbyVehiclesFiltered', isFiltered);
}

/**
 * Toggles the display of driver names in the Live Map assets list.
 *
 * @param {MapStoreActionContext} context
 */
export function toggleDriverNameInList(context) {
  const show = !context.state.showDriverNameInList;
  context.commit('setShowDriverNameInList', show);
}

/**
 * Toggles the display of addresses in the Live Map assets list.
 *
 * @param {MapStoreActionContext} context
 */
export function toggleAddressInList(context) {
  const show = !context.state.showAddressInList;
  context.commit('setShowAddressInList', show);
}

/**
 * Toggles the display of nicknames in the Live Map assets list.
 *
 * @param {MapStoreActionContext} context
 */
export function toggleNicknameInList(context) {
  const show = !context.state.showNicknameInList;
  context.commit('setShowNicknameInList', show);
}

/**
 * Opens the map legend.
 *
 * @param {MapStoreActionContext} context
 */
export function openLegend(context) {
  context.commit('setLegendOpened', true);
}

/**
 * Closes the map legend.
 *
 * @param {MapStoreActionContext} context
 */
export function closeLegend(context) {
  context.commit('setLegendOpened', false);
}

/**
 * Provides the Map service with a double-click focus handler.
 *
 * @param {MapStoreActionContext} context
 * @param {Function} callback
 */
export function onDoubleClickFocus(context, callback) {
  MapService.onDoubleClickFocus(callback);
}

/**
 * Unsets the Map service's double-click focus handler.
 */
export function unsetDoubleClickFocus() {
  MapService.unsetDoubleClickFocus();
}

/**
 * Unsets the selected drawer "should update" flag.
 *
 * @param {MapStoreActionContext} context
 */
export function selectedDrawerUpdated(context) {
  context.commit('setSelectedDrawerShouldUpdate', false);
}

/**
 * Opens the Live Map list dialog.
 *
 * @param {MapStoreActionContext} context
 */
export function openListDialog(context) {
  context.commit('setListDialogOpen', true);
}

/**
 * Closes the Live Map list dialog.
 *
 * @param {MapStoreActionContext} context
 */
export async function closeListDialog(context) {
  context.commit('setListDialogOpen', false);
}

/**
 * Sets "trip end details minimized" flag.
 *
 * @param {MapStoreActionContext} context
 * @param {Boolean} isMinimized
 */
export async function setTripEndDetailsMinimized(context, isMinimized) {
  context.commit('setTripEndDetailsMinimized', isMinimized);
}

/**
 * Sets "trip end has media" flag.
 *
 * @param {MapStoreActionContext} context
 * @param {Boolean} hasMedia
 */
export async function setTripEndHasMedia(context, hasMedia) {
  context.commit('setTripEndHasMedia', hasMedia);
}

/**
 * Clears the search results.
 *
 * @param {MapStoreActionContext} context
 */
export function clearSearchResults(context) {
  context.commit('clearSearchResults');
  context.dispatch('updateSearchResultLayer');
}

/**
 * Selects the search result with the given index.
 *
 * @param {MapStoreActionContext} context
 * @param {number} index
 */
export async function selectSearchResult(context, index) {
  context.commit('setSelectedSearchResultIndex', index);

  const { bounds, center } = context.getters.selectedSearchResult || {};

  if (!bounds && !center) {
    return;
  }

  if (bounds) {
    context.dispatch('fitToBounds', new Bounds(bounds));
  } else if (center) {
    const [longitude, latitude] = center;
    context.dispatch('focusOnCoords', { longitude, latitude, zoom: 15 });
  }

  trackEvent('address_search_result_chosen');

  await setTimeoutPromise(250);

  context.dispatch('zoomToSearchResult');
}

/**
 * Zooms to the current search result.
 *
 * @param {MapStoreActionContext} context
 */
export function zoomToSearchResult(context) {
  const { center } = context.getters.selectedSearchResult || {};
  const [longitude, latitude] = center;

  MapService.trigger('contextmenu', {
    lngLat: { lng: longitude, lat: latitude },
    point: MapService.map.project([longitude, latitude]),
  });
}

/**
 * Retrieves results from the Map Places Search using the given terms.
 *
 * @param {MapStoreActionContext} context
 * @param {string} terms
 * @returns {Promise<void>}
 */
export async function addressSearch(context, terms) {
  return new Promise((resolve) => {
    MapService.onReady(async () => {
      // Get the center of the map to orient the POIs around a given location
      const { lng, lat } = MapService.getCenter();
      const proximity = `${lng},${lat}`;

      /** @type {FormattedAddressObject[]} */
      const results = await context.rootState.app.broker.mapPlacesSearch({
        terms,
        proximity,
      });

      trackEvent('address_search');

      context.commit('setSelectedSearchResultIndex', 0);
      context.commit('setSearchResults', results);
      context.commit('setSearchResultsLoading', false);

      context.dispatch('updateSearchResultLayer');

      resolve();
    });
  });
}

/**
 * Sets the "search results loading" flag.
 *
 * @param {MapStoreActionContext} context
 * @param {boolean} isLoading
 */
export function setSearchResultsLoading(context, isLoading) {
  context.commit('setSearchResultsLoading', isLoading);
}

/**
 * Sets the selected search result index.
 *
 * @param {MapStoreActionContext} context
 * @param {number} index
 */
export function setSelectedSearchResultIndex(context, index) {
  let newIndex = index;

  if (newIndex < 0) {
    newIndex = 0;
  }

  if (newIndex >= context.state.searchResults.length) {
    newIndex = context.state.searchResults.length - 1;
  }

  context.commit('setSelectedSearchResultIndex', newIndex);
}

/**
 * Sets the search terms used for the map search.
 *
 * @param {MapStoreActionContext} context
 * @param {string} terms
 */
export function setSearchTerms(context, terms) {
  context.commit('setSearchTerms', terms);
}
