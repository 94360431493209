<template>
  <ZubieDialog
    ref="dialog"
    :max-width="800"
    :maximized="$q.screen.xs"
    ok-only
    :title="`Visits to ${place.name}`"
    @before-show="fetchVisits"
    @show="onShow"
  >
    <div class="row justify-start items-center q-mb-sm q-ml-sm">
      <div class="q-mr-sm text-grey-7">Date Range:</div>
      <DateRangeButton
        :label="dateRangeLabel"
        :model-value="dateRangeValue"
        @update:model-value="debouncedOnDateChange"
      />
    </div>
    <q-table
      v-model:pagination="pagination"
      color="primary"
      :columns="[
        {
          name: 'nickname',
          label: 'Vehicle Name',
          align: 'left',
          classes: (row) => `${!row.vehicle.nickname ? 'text-italic' : ''}`,
          field: (visit) => visit.vehicle.nickname,
          format: (value) => value || 'Unnamed Vehicle',
          sortable: true,
        },
        {
          name: 'driver',
          label: 'Driver',
          field: (visit) => visit,
          align: 'left',
          sortable: true,
        },
        {
          name: 'entryTime',
          label: 'Arrival',
          field: 'entryTimestamp',
          format: (entryTimestamp) => utcToLocalDateTime(entryTimestamp),
          align: 'left',
          sortable: true,
        },
        {
          name: 'dwellTime',
          label: 'Dwell Time',
          field: 'dwellTime',
          align: 'left',
          sortable: true,
        },
        {
          name: 'exitTimestamp',
          label: 'Departure',
          field: 'exitTimestamp',
          format: (exitTimestamp) => (exitTimestamp ? utcToLocalDateTime(exitTimestamp) : 'Still here'),
          align: 'left',
          sortable: true,
        },
      ]"
      flat
      :grid="$q.screen.lt.lg"
      :loading="isLoading"
      row-key="key"
      :rows="placeVisits"
    >
      <template #no-data>
        <div class="text-center text-grey-9">
          <div v-if="isLoading">Retrieving Place visits...</div>
          <div v-else>
            <div v-if="dateRange">No visits during this time period for this place.</div>
            <div v-else>No recent visits found for this place.</div>
          </div>
        </div>
      </template>
      <template #body-cell-nickname="props">
        <q-td class="ellipsis text-wrap" :props="props" style="max-width: 200px">
          <PrimaryInlineButton dense flat no-caps padding="0 xs" @click="onVehicleClick($event, props.row.vehicle.key)">
            <VehicleDefaultImage class="q-mr-xs" :size="16" /> {{ props.value }}
          </PrimaryInlineButton>
        </q-td>
      </template>
      <template #body-cell-driver="props">
        <q-td :props="props">
          <DriverChip v-if="props.value.driverArrival" :dense="true" :driver="props.value.driverArrival" />
          <DriverChip
            v-if="props.value.driverDeparture && !props.value.hasMatchingDriver"
            :dense="true"
            :driver="props.value.driverDeparture"
          />
          <div v-if="!props.value.driverArrival && !props.value.driverDeparture" class="text-grey-7">--</div>
        </q-td>
      </template>
      <template #body-cell-entryTime="props">
        <q-td :props="props">
          <PrimaryInlineButton dense flat no-caps padding="0 xs" :to="getTripKey(props.row, 'entry')">
            <q-icon class="q-mr-xs" size="xs" v-html="distanceSvg" /> {{ props.value }}
          </PrimaryInlineButton>
        </q-td>
      </template>
      <template #body-cell-exitTimestamp="props">
        <q-td :props="props">
          <div v-if="!props.row.exitTimestamp">{{ props.value }}</div>
          <PrimaryInlineButton v-else dense flat no-caps padding="0 xs" :to="getTripKey(props.row, 'exit')">
            <q-icon class="q-mr-xs" size="xs" v-html="distanceSvg" /> {{ props.value }}
          </PrimaryInlineButton>
        </q-td>
      </template>
      <template #item="props">
        <PlaceVisitsCard :visit="props.row" @vehicle-click="onVehicleClick" />
      </template>
      <template #bottom>
        <TableBottom
          v-model:page="pagination.page"
          v-model:rows-per-page="pagination.rowsPerPage"
          load-more-label="Load More Visits"
          :more-results="hasMorePlaceVisits"
          :total="placeVisits.length"
          @request="onRequest"
        />
      </template>
    </q-table>
  </ZubieDialog>
</template>

<script>
import _debounce from 'lodash/debounce';
import _first from 'lodash/first';
import _last from 'lodash/last';
import { useMeta } from 'quasar';
import { mapActions, mapGetters } from 'vuex';
import PrimaryInlineButton from 'components/buttons/PrimaryInlineButton.vue';
import DriverChip from 'components/chips/DriverChip.vue';
import DateRangeButton from 'components/DateRangeButton.vue';
import PlaceVisitsCard from 'components/places/PlaceVisitsCard.vue';
import TableBottom from 'components/table/TableBottom.vue';
import VehicleDefaultImage from 'components/vehicle/VehicleDefaultImage.vue';
import DistanceSvg from 'src/assets/noun_distance_236772.svg?raw';
import Place from 'src/models/Place';
import { ROWS_PER_PAGE_LOWEST } from 'src/services/constants';
import { todayStart, todayEnd, utcToLocalDateTime, dayjs } from 'src/services/date';
import { trackEvent } from 'src/services/intercom';

export default {
  name: 'PlaceVisitsDialog',
  props: {
    place: Place,
  },
  components: {
    DateRangeButton,
    DriverChip,
    PlaceVisitsCard,
    PrimaryInlineButton,
    TableBottom,
    VehicleDefaultImage,
  },
  computed: {
    ...mapGetters('assets', ['vehicles']),
    ...mapGetters('places', ['placeVisits', 'hasMorePlaceVisits']),
    ...mapGetters('session', ['partnerName']),
    dateRangeLabel() {
      return this.dateRange ? undefined : 'Most Recent';
    },
    dateRangeValue() {
      return this.dateRange || this.defaultDateRange;
    },
  },
  data() {
    return {
      dateRange: null,
      isLoading: false,
      debouncedOnDateChange: null,
      defaultDateRange: [todayStart(), todayEnd()],
      distanceSvg: DistanceSvg,
      hasMoreResults: false,
      pagination: {
        sortBy: 'arrivalTimestamp',
        descending: false,
        page: 1,
        rowsPerPage: ROWS_PER_PAGE_LOWEST,
      },
    };
  },
  methods: {
    utcToLocalDateTime,
    ...mapActions('places', ['getPlaceVisits']),
    getTripKey(visit, type) {
      const date = dayjs.utc(visit[`${type}Timestamp`]).local().format('YYYY-MM-DD');
      return `/vehicle/${visit.vehicle.key}/trips/${date}?tripKey=${visit[`${type}TripKey`]}`;
    },
    onDateChange(value) {
      const [start, end] = value;
      this.dateRange = [start, end];
      this.fetchVisits();
    },
    onVehicleClick(event, key) {
      event.preventDefault();
      this.$emit('vehicle-select', key);
      return false;
    },
    async fetchVisits(followCursor = false) {
      const payload = {
        key: this.place.key,
        followCursor,
        size: this.pagination.rowsPerPage,
      };

      this.isLoading = true;

      if (this.dateRange) {
        payload.from = _first(this.dateRange);
        payload.to = _last(this.dateRange);
      }

      try {
        const visits = await this.getPlaceVisits(payload);

        if (visits.error) {
          this.error = visits.error;
        }
      } catch (error) {
        this.error = error.message;
      }

      this.isLoading = false;
    },
    async onRequest() {
      this.fetchVisits(true);
    },
    onShow() {
      trackEvent('place_visits_opened', { page: this.$route.name });
    },
  },
  created() {
    this.debouncedOnDateChange = _debounce(this.onDateChange, 250);
    useMeta({
      title: `Visits - ${this.place.name} - ${this.partnerName}`,
    });
  },
};
</script>

<style lang="scss" scoped>
:deep(.q-table__bottom--nodata) {
  justify-items: center;
  border: 1px solid $grey-4;
  border-radius: 4px;
}
</style>
