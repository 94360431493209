<template>
  <ZubieChip
    :clickable="!isUnassigned"
    :dense="dense"
    :icon="icon"
    :icon-right="iconRight"
    :no-copy="isUnassigned"
    :outline="outline && !isDriverCheckin"
    :search-terms="searchTerms"
    :transparent-outline="isUnassigned"
  >
    {{ name }}
    <template #append>
      <ToolTip
        v-if="isDriverCheckin"
        anchor="bottom middle"
        :offset="[0, 5]"
        self="top middle"
        transition-hide="jump-up"
        transition-show="jump-down"
        >Checked in until {{ expiration }}</ToolTip
      >
    </template>
  </ZubieChip>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ZubieChip from 'components/chips/ZubieChip.vue';
import ToolTip from 'components/ToolTip.vue';
import Driver from 'src/models/Driver';
import { utcToLocalTimestamp } from 'src/services/date';
import { hasDriverCheckin } from 'src/services/vehicles';

const props = withDefaults(
  defineProps<{ dense?: boolean; driver: Driver; outline?: boolean; searchTerms?: string[] }>(),
  {
    outline: true,
  }
);

const expiration = computed(() => {
  const { checkInExpiration } = props.driver;
  return checkInExpiration ? utcToLocalTimestamp(checkInExpiration) : '';
});

const isDriverCheckin = computed(() => hasDriverCheckin(props.driver));

const name = computed(() => {
  const { fullName } = props.driver;
  return fullName || 'Unassigned';
});

const icon = computed(() => {
  if (isDriverCheckin.value) {
    return 'how_to_reg';
  }
  return name.value === 'Unassigned' ? 'person_off' : 'person';
});

const iconRight = computed(() => (isDriverCheckin.value ? 'hourglass_bottom' : ''));

const isUnassigned = computed(() => name.value === 'Unassigned');
</script>
