/**
 * @param {NotificationsStoreState} state
 * @param {import("src/models/Notification").default[]} notifications
 */
export function addNotifications(state, notifications) {
  state.notifications = [...notifications, ...state.notifications];
}

/**
 * @param {NotificationsStoreState} state
 */
export function finishedLoading(state) {
  state.isLoadingAllNotifications = false;
}

/**
 * @param {NotificationsStoreState} state
 */
export function loadingAllNotifications(state) {
  state.isLoadingAllNotifications = true;
}

/**
 * @param {NotificationsStoreState} state
 * @param {boolean} cancelling
 */
export function setCancellingGetAll(state, cancelling) {
  state.isCancellingGetAll = cancelling;
}

/**
 * @param {NotificationsStoreState} state
 * @param {string} id
 */
export function setGetAllRequestId(state, id) {
  state.getAllRequestId = id;
}

/**
 *
 * @param {NotificationsStoreState} state
 * @param {string} hash
 */
export function setLastWebNotificationHash(state, hash) {
  state.lastWebNotificationHash = hash;
}

/**
 * @param {NotificationsStoreState} state
 * @param {Notification[]} notifications
 */
export function setNotifications(state, notifications) {
  state.notifications = notifications;
}

/**
 * @param {NotificationsStoreState} state
 * @param {Dayjs} date
 */
export function setNotificationsSinceDate(state, date) {
  state.sinceDate = date;
}

/**
 * @param {NotificationsStoreState} state
 * @param {keyof typeof import('src/services/notification').NOTIFICATION_SUPPORT} supportedStatus
 */
export function setNotificationSupport(state, supportedStatus) {
  state.notificationSupport = supportedStatus;
}
