<template>
  <q-list bordered>
    <div v-if="!hideMapStyles">
      <q-item class="q-pb-none">
        <q-item-section class="zubie-font text-subtitle3">Map Style</q-item-section>
      </q-item>

      <MapStyleOptions :map-style="mapStyle" @set-map-style="$emit('set-map-style', $event)" />

      <MapOptionToggle
        class="q-mt-sm q-ml-md"
        data-t="trafficToggle"
        :icon="mapIcons.TRAFFIC"
        label="Traffic"
        :model-value="showTraffic"
        @update:model-value="$emit('toggle-traffic')"
      />
    </div>

    <div :class="{ 'q-mb-md': hideMapStyles && hideMarkers }">
      <q-item class="q-py-none">
        <q-item-section class="zubie-font text-subtitle3">Place Visibility</q-item-section>
      </q-item>
      <MapOptionToggle
        class="q-ml-md"
        data-t="placesToggle"
        :icon="mapIcons.PLACE_POINTS"
        :model-value="showPlaces"
        @update:model-value="$emit('toggle-places')"
      >
        Saved Places
        <template #toggle-prepend>
          <HiddenPlacesButton v-if="!hideHiddenPlacesButton" />
        </template>
      </MapOptionToggle>
    </div>

    <div v-if="!hideMarkers">
      <q-item class="q-py-none">
        <q-item-section class="zubie-font text-subtitle3">Marker Options</q-item-section>
      </q-item>
      <MapOptionToggle
        class="q-ml-md q-pb-sm"
        data-t="tagColorsToggle"
        :debounce="true"
        label="Tag Colors"
        :model-value="showTagColors"
        @update:model-value="$emit('toggle-tag-colors')"
      />
      <MapOptionToggle
        class="q-ml-md q-pb-sm"
        data-t="clustersToggle"
        :debounce="true"
        label="Clusters"
        :model-value="clusterAssets"
        @update:model-value="$emit('toggle-cluster-assets')"
      />
    </div>
  </q-list>
</template>

<script>
import { mapGetters } from 'vuex';
import HiddenPlacesButton from 'components/map/HiddenPlacesButton.vue';
import MapOptionToggle from 'components/map/MapOptionToggle.vue';
import MapStyleOptions from 'components/map/MapStyleOptions.vue';
import { mapIcons } from 'src/services/constants';

export default {
  props: {
    clusterAssets: Boolean,
    hideHiddenPlacesButton: Boolean,
    hideMapStyles: Boolean,
    hideMarkers: Boolean,
    hideShowHiddenPlaces: Boolean,
    mapStyle: String,
    showHiddenPlaces: Boolean,
    showPlaces: Boolean,
    showTagColors: Boolean,
    showTraffic: Boolean,
  },
  components: { MapStyleOptions, MapOptionToggle, HiddenPlacesButton },
  computed: {
    ...mapGetters('places', ['hiddenPlaces']),
    hiddenPlacesCount() {
      return this.hiddenPlaces.length;
    },
    mapIcons() {
      return mapIcons;
    },
  },
};
</script>
