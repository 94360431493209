/* eslint class-methods-use-this:0 */

import UnitLocale from './UnitLocale';

export default class MetricUnitLocale extends UnitLocale {
  /** @type {String} */
  FUEL_ECONOMY_UNITS = 'L/100 km';

  /** @type {String} */
  VELOCITY_UNITS = 'kph';

  /**
   * Converts the given miles to kilometers.
   *
   * @param {Number} value
   * @returns {Number}
   */
  static miToKm(value) {
    return value * 1.609344;
  }

  /**
   * Converts the given value to the metric equivalent.
   *
   * @param {Number} value
   * @param {String} fromUnit
   * @returns {Number}
   */
  convertDistance(value, fromUnit) {
    return fromUnit === 'mi' ? MetricUnitLocale.miToKm(value) : value;
  }

  /**
   * Converts the given fuel economy to the metric equivalent.
   *
   * @param {Number} value
   * @param {String} fromUnit
   * @returns {Number}
   */
  convertFuelEconomy(value, fromUnit) {
    switch (fromUnit) {
      case 'km/L':
        return value * 100;
      case 'mpg':
        return 235.2137783 / value;
      default:
        return value;
    }
  }

  /**
   * Converts the given velocity to the metric equivalent.
   *
   * @param {Number} velocity
   * @param {String} fromUnit
   * @returns {Number}
   */
  convertVelocity(velocity, fromUnit) {
    return fromUnit === 'mph' ? MetricUnitLocale.miToKm(velocity) : velocity;
  }

  /**
   * Returns the metric unit of measurement.
   *
   * @param {Number} value
   * @param {Boolean} long
   * @returns {String}
   */
  getDistanceUnits(value = 0, long = false) {
    let unit = 'km';

    if (long) {
      unit = 'kilometer';
      if (!Number.isFinite(value) || value < 1 || value > 1) {
        unit += 's';
      }
    }

    return unit;
  }
}
