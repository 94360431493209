import GeoJsonLayer from 'src/services/geoJson/GeoJsonLayer';
import SearchResultsSource from 'src/services/geoJson/sources/SearchResultsSource';

export default class SearchResultsLayer extends GeoJsonLayer {
  /** @var {String} id */
  static id = 'search-results';

  /**
   * @returns {Object} A GeoJson layer config object.
   */
  getConfig() {
    return Object.freeze({
      id: this.getId(),
      type: 'symbol',
      source: SearchResultsSource.id,
      layout: {
        'icon-anchor': 'center',
        'icon-image': 'search_result_marker',
        'icon-ignore-placement': true,
        'icon-offset': ['interpolate', ['linear'], ['zoom'], 4.9, ['literal', [0, 10]], 5, ['literal', [0, 5]]],
        'icon-padding': 0,
        'icon-size': ['interpolate', ['linear'], ['zoom'], 4.9, 0.5, 5, 0.75, 12, 0.85, 15, 1],
        'symbol-avoid-edges': true,
        'text-anchor': 'top',
        'text-field': '{label}',
        'text-font': ['Open Sans SemiBold', 'Arial Unicode MS Regular'],
        'text-max-width': 20,
        'text-offset': [
          'interpolate',
          ['linear'],
          ['zoom'],
          4.9,
          ['literal', [0, 1.5]],
          12,
          ['literal', [0, 1.7]],
          15,
          ['literal', [0, 1.9]],
        ],
        'text-optional': true,
        'text-size': ['interpolate', ['linear'], ['zoom'], 4.9, 10, 5, 12, 16, 14],
      },
      paint: {
        'text-halo-width': 1,
        'text-color': 'hsl(189, 0%, 37%)',
      },
    });
  }
}
