import _get from 'lodash/get';
import { dayjs } from 'src/services/date';
import SimpleModel from './SimpleModel';
import Vehicle from './Vehicle';

class PlaceVisit extends SimpleModel {
  /** @type {string} */
  carKey = null;

  /** @type {object} */
  driverArrival = null;

  /** @type {object} */
  driverDeparture = null;

  /** @type {string} */
  dwellTime = null;

  /** @type {string} */
  entryTimestamp = null;

  /** @type {string | null} */
  entryTripKey = null;

  /** @type {string} */
  exitTimestamp = null;

  /** @type {string | null} */
  exitTripKey = null;

  /** @type {string} */
  key = null;

  /** @type {Vehicle} */
  vehicle = new Vehicle();

  constructor(data = {}) {
    super();

    const insertData = {};

    if (data.exitTimestamp) {
      insertData.dwellTime = dayjs(data.exitTimestamp).from(data.entryTimestamp, true);
    } else {
      insertData.dwellTime = dayjs().from(data.entryTimestamp, true);
    }

    this.insert({
      ...data,
      ...insertData,
    });
  }

  /**
   * Returns whether the same user drove during the arrival and departure trips
   *
   * @returns {Boolean}
   */
  get hasMatchingDriver() {
    const arrival = _get(this.driverArrival, 'key', '');
    const departure = _get(this.driverDeparture, 'key', '');
    return arrival === departure;
  }
}

export default PlaceVisit;
