import { Screen } from 'quasar';
import Bounds from 'src/models/Bounds';
import storage from 'src/services/storage';

/** @typedef {'DEFAULT' | 'LIGHT' | 'DARK' | 'SATELLITE'} MapStyle */

export default () => {
  storage.onReady((store) => {
    store.commit('map/mapStyle', storage.get('map/mapStyle') || 'LIGHT');
    store.commit('map/setNearbyMapStyle', storage.get('map/nearbyMapStyle') || 'LIGHT');
    store.commit('map/setAssetRecentMapStyle', storage.get('map/assetRecentMapStyle') || 'LIGHT');
    store.commit('map/setTripsMapStyle', storage.get('map/tripsMapStyle') || 'LIGHT');

    if (storage.get('map/clusterAssets') === 'false') {
      store.commit('map/setClusterEnabled', false);
    }
    if (storage.get('map/legendOpened') === 'false') {
      store.dispatch('map/closeLegend');
    }
    if (storage.get('map/tripEndDetailsMinimized') === 'true') {
      store.commit('map/setTripEndDetailsMinimized', true);
    }
    if (storage.get('map/showDriverNameInList') === 'true') {
      store.commit('map/setShowDriverNameInList', true);
    }
    if (storage.get('map/showAddressInList') === 'true') {
      store.commit('map/setShowAddressInList', true);
    }
    if (storage.get('map/showNicknameInList') === 'false') {
      store.commit('map/setShowNicknameInList', false);
    }
    if (storage.get('map/showTagColors') === 'true') {
      store.dispatch('map/setShowTagColors', true);
    }
    if (storage.get('map/showPlaces') === 'false') {
      store.dispatch('map/setShowPlaces', false);
    }
    if (storage.get('map/showTraffic') === 'true') {
      store.dispatch('map/setShowTraffic', true);
    }
  });

  return {
    assetRecentMapStyle: 'LIGHT',
    contextualMenuOpen: false,
    clusterableAssetsGeoJson: null,
    clusteredBounds: {},
    clusterAssets: true,
    fittingTo: new Bounds(),
    idealBounds: new Bounds(),
    inViewportFencesHash: null,
    legendOpened: true,
    listDialogOpen: false,
    liveMapInitialized: false,
    lockToIdealBounds: false,
    /** @type {MapStyle} */
    mapStyle: 'LIGHT',
    matchesIdealBounds: false,
    /** @type {MapStyle} */
    nearbyMapStyle: 'LIGHT',
    nearbyVehiclesFiltered: true,
    pendingFitToIdeal: false,
    placesLayerReady: false,
    publishBoundsThrottleDelay: 500,
    reservingOnLoadFocus: null,
    /** @type {FormattedAddressObject[]} */
    searchResults: [],
    searchResultsLoading: false,
    searchTerms: '',
    selectedAssetGeoJson: null,
    selectedDrawerShouldUpdate: false,
    selectedSearchResultIndex: -1,
    showAddPlace: false,
    showDriverNameInList: false,
    showAddressInList: false,
    showHiddenPlaces: false,
    showNicknameInList: true,
    showTagColors: false,
    showPlaces: true,
    showTraffic: false,
    tripEndDetailsMinimized: !Screen.gt.xs,
    tripEndHasMedia: false,
    tripsMapStyle: 'LIGHT',
    zoomLevel: 0,
  };
};
