/**
 * Class that represents vehicle alerts.
 */
export default class AssetAlert {
  /**
   * @constructor
   * @param {String} type
   * @param {Object} data
   */
  constructor(type, data = {}) {
    this.type = type;
    this.data = data;
  }
}
