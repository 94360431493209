export function clearGroupInModification(state) {
  state.groupBeingEdited = {
    name: '',
    parentGroupKey: '',
  };
}

export function setGroups(state, groups) {
  state.groups = groups;
}

export function setGroupsLoading(state, loading) {
  state.groupsLoading = loading;
}

export function setGroupInModification(state, group) {
  Object.assign(state.groupBeingEdited, group);
}

export function setGroupsError(state, error) {
  if (!error) {
    state.groupsError = {};
    state.groupsErrorMessage = false;
    return;
  }

  if (typeof error === 'object') {
    state.groupsError = error;
  } else {
    state.groupsErrorMessage = error;
  }
}

export function setSavingGroup(state, savingGroup) {
  state.savingGroup = savingGroup;
}
