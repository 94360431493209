import _orderBy from 'lodash/orderBy';

/**
 * @param {import('src/types/groups-store').GroupsStoreState} state
 */
export function getFlatGroups(state) {
  const groups = {};

  const process = (nodes = [], hierarchy = []) => {
    nodes.forEach((node) => {
      groups[node.key] = {
        ...node,
        hierarchy,
      };
      return process(node.children, [...hierarchy, node.name]);
    });
  };
  process(state.groups);

  return groups;
}

/**
 * @param {import('src/types/groups-store').GroupsStoreState} state
 */
export function getGroupByKey(state) {
  return (key) => getFlatGroups(state)[key];
}

/**
 * @param {import('src/types/groups-store').GroupsStoreState} state
 */
export function groups(state) {
  const processGroups = (groups) =>
    _orderBy([...groups], [({ name }) => name.toLowerCase()]).map((group) => ({
      ...group,
      children: group.children ? processGroups(group.children) : [],
    }));
  return processGroups(state.groups);
}

/**
 * @param {import('src/types/groups-store').GroupsStoreState} state
 */
export function groupsWithAccount(state) {
  return [
    {
      key: 'root',
      name: '(Your Account)',
      children: state.groups,
      treeDepth: 0,
      disable: true,
      selectionForbidden: true,
    },
  ];
}
