import UserTransformer from 'src/transformers/UserTransformer';

export function setUsers(state, users) {
  state.users = users;
  Object.keys(state.users).forEach((key) => {
    state.users[key] = UserTransformer.transform(state.users[key]);
  });
}

export function loadingUsers(state) {
  state.usersLoading = true;
}

export function usersLoaded(state) {
  state.usersLoading = false;
  state.usersReadyResolve();
}

export function setExtendedUserDetails(state, user) {
  state.extendedUserDetails = user;
}

export function setAlertSuggestions(state, alertSuggestions) {
  state.alertSuggestions = alertSuggestions;
}

export function setUsersError(state, error) {
  if (!error) {
    state.usersError = {};
    state.usersErrorMessage = null;
    return;
  }

  if (typeof error === 'object') {
    state.usersError = error;
    state.usersErrorMessage = null;
  } else {
    state.usersError = {};
    state.usersErrorMessage = error;
  }
}

export function setChangePasswordError(state, error) {
  state.changePasswordError = error || false;
}

export function setInvite(state, invite) {
  state.invite = invite;
}

export function clearInvite(state) {
  state.invite = null;
}

export function setExtendedUserLoading(state, isLoading) {
  state.extendedUserLoading = isLoading;
}
