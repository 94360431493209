import { LOW_BATTERY_LEVEL_THRESHOLD } from 'src/services/constants';

/**
 * Class used to determine if an alert should be raised.
 */
export default class AlertChecks {
  /**
   * Returns true if the given value is <= the low battery level threshold.
   *
   * @param {Number} batteryLevel
   * @returns {Boolean}
   */
  static isLowBatteryLevel(batteryLevel) {
    if (Number.isFinite(batteryLevel)) {
      return batteryLevel <= LOW_BATTERY_LEVEL_THRESHOLD && batteryLevel > 0;
    }
    return false;
  }
}
