<template>
  <q-layout class="flex flex-center full-image-bg" :style="{ 'background-image': bgUrl }" view="hHh lpR fFf">
    <q-drawer
      behavior="desktop"
      content-class="bg-dark text-white"
      dark
      :model-value="true"
      persistent
      side="right"
      :width="375"
    >
      <div class="auth-page-content flex column q-pa-md justify-between full-height no-wrap">
        <ZubieLogo class="logo text-white" height="70px" title="Zubie" />

        <router-view v-slot="{ Component }">
          <transition name="page-change">
            <component :is="Component" />
          </transition>
        </router-view>

        <LoginFooter class="q-mt-md" />
      </div>
    </q-drawer>
  </q-layout>
</template>

<script>
import { quality } from '@cloudinary/url-gen/actions/delivery';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { autoEco } from '@cloudinary/url-gen/qualifiers/quality';
import LoginFooter from 'components/LoginFooter.vue';
import ZubieLogo from 'src/assets/zubie-logo.svg?component';
import * as cloudinary from 'src/services/cloudinary';

export default {
  name: 'AuthLayout',
  components: { LoginFooter, ZubieLogo },
  computed: {
    bgUrl() {
      const image = cloudinary.image('apps/web/login-bg.png');
      image.resize(scale().width(1920));
      image.delivery(quality(autoEco()));
      image.setVersion(1572274200);
      return `url(${image.toURL()})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.full-image-bg {
  background: no-repeat bottom left fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.logo {
  min-height: 70px;
}

.logo g {
  height: 100%;
}

:deep(.q-separator) {
  background-color: $white;
}

:deep(.q-field--filled .q-field__control:before) {
  background-color: rgba(255, 255, 255, 0.1);
}

:deep(.q-field--filled.q-field--focused .q-field__control:before) {
  background-color: rgba(255, 255, 255, 0.05);
}

:deep(.q-drawer) {
  margin-bottom: 0;

  @media only screen and (max-width: 767px) {
    width: 100% !important;
  }
}

:deep(.q-drawer__content) {
  padding-bottom: env(safe-area-inset-bottom) !important;
}
</style>

<style lang="scss">
.ios-notch-right .auth-page-content {
  padding-right: env(safe-area-inset-right);
}
.ios-notch-top .auth-page-content .logo {
  margin-top: env(safe-area-inset-top);
}
</style>
