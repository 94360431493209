import SimpleModel from './SimpleModel';

class MaintenanceCategory extends SimpleModel {
  props() {
    return {
      category: null,
      description: null,
      summary: null,
      maintenanceActionKey: null,
      milesInterval: null,
      monthsInterval: null,
      updated: null,
    };
  }
}

export default MaintenanceCategory;
