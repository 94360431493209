<template>
  <ZubieMap
    :chooser-control="false"
    :map-style="mapStyle"
    :style="{ height: `${height}px`, width: '100%' }"
    :zoom="zoom"
    @load="onMapLoad"
  >
    <MapboxLayer
      :layer="historyLayer"
      :source="source"
      @click="onHistoryClick"
      @mouseenter="onLayerMouseEnter"
      @mouseleave="onLayerMouseLeave"
    />

    <MapboxLayer :layer="lastLocationLayer" :source="source" />

    <Popup v-if="popupShown" :close-on-click="false" :coordinates="popupLatLng" @close="popupShown = false">
      <div class="row popup">
        <div class="col-grow roboto block text-dark q-pr-md">
          <span v-if="selectedAddress">{{ selectedAddress }}</span>
          <AddressString v-else :latitude="popupLatLng[1]" :longitude="popupLatLng[0]" />
        </div>
        <div class="text-grey-7">{{ selectedTimestamp }}</div>
      </div>
    </Popup>
  </ZubieMap>
</template>

<script>
import _get from 'lodash/get';
import { colors, getCssVar } from 'quasar';
import { mapState } from 'vuex';
import AddressString from 'components/AddressString.vue';
import MapboxLayer from 'components/map/MapboxLayer.vue';
import Popup from 'components/map/Popup.vue';
import ZubieMap from 'components/map/ZubieMap.vue';
import Vehicle from 'src/models/Vehicle';
import { dayjs } from 'src/services/date';
import layers from 'src/services/geoJson/layers';
import Map from 'src/services/map/Map';

const { assetsLayer } = layers;
// help webpack tree shaking: https://quasar.dev/quasar-utils/color-utils#helping-tree-shake
const { lighten } = colors;

const SOURCE_ID = 'disconnected-device';

export default {
  props: {
    height: {
      type: Number,
      default: 350,
    },
    selectedLocation: Object,
    vehicle: Vehicle,
  },
  components: {
    AddressString,
    MapboxLayer,
    Popup,
    ZubieMap,
  },
  computed: {
    ...mapState('devices', ['disconnectedLocationHistory']),
    ...mapState('env', ['mapboxAccessToken']),
    ...mapState('map', ['mapStyle']),
    features() {
      const features = [];

      if (this.lastLocationFeature) {
        features.push(this.lastLocationFeature);
      }

      return [...features, ...this.historyFeatures];
    },
    historyFeatures() {
      const { history } = this.disconnectedLocationHistory[this.serial] || { history: [] };

      const historyFeatures = history.map((item) => ({
        type: 'Feature',
        properties: {
          address: (item.address || '').toString(),
          timestamp: dayjs(item.timestamp).format('M/D/YY H:m A'),
        },
        geometry: {
          type: 'Point',
          coordinates: [item.longitude, item.latitude],
        },
      }));

      return historyFeatures;
    },
    historyLayer() {
      const primaryColor = getCssVar('primary');

      return {
        id: 'historyLayer',
        type: 'circle',
        source: SOURCE_ID,
        filter: ['!=', 'isLastLocation', true],
        paint: {
          'circle-radius': 4.5,
          'circle-color': lighten(primaryColor, -20),
          'circle-stroke-color': primaryColor,
          'circle-stroke-width': 4,
        },
      };
    },
    lastLatitude() {
      return this.vehicle.location.latitude;
    },
    lastLongitude() {
      return this.vehicle.location.longitude;
    },
    lastLocationLayer() {
      return {
        ...assetsLayer.getConfig(),
        id: 'lastLocationLayer',
        source: SOURCE_ID,
        filter: ['==', 'isLastLocation', true],
      };
    },
    serial() {
      return this.vehicle.firstDisconnectedDevice.serial;
    },
    source() {
      return {
        type: 'geojson',
        data: {
          id: SOURCE_ID,
          type: 'FeatureCollection',
          features: [],
        },
      };
    },
  },
  data() {
    return {
      // This is a data prop to prevent vehicle marker from rendering before icon is added to the map
      centerCoordinates: [0, 0],
      lastLocationFeature: {},
      popupLatLng: [0, 0],
      popupShown: false,
      selectedAddress: null,
      selectedTimestamp: null,
      zoom: 15,
    };
  },
  methods: {
    centerMap() {
      const mapInstance = this.$noObserve.get('mapInstance');

      if (mapInstance) {
        mapInstance.panTo(this.centerCoordinates);
      }
    },
    onHistoryClick({ mapboxEvent }) {
      const { geometry = {}, properties = {} } = _get(mapboxEvent, 'features.0') || {};
      const { address, timestamp } = properties;
      this.centerCoordinates = geometry.coordinates;
      this.showPopup(address, timestamp);
    },
    onLayerMouseEnter() {
      const mapInstance = this.$noObserve.get('mapInstance');
      mapInstance.getCanvas().style.cursor = 'pointer';
    },
    onLayerMouseLeave() {
      const mapInstance = this.$noObserve.get('mapInstance');
      mapInstance.getCanvas().style.cursor = '';
    },
    async onMapLoad({ map }) {
      this.map = map;

      // Center on last location
      this.centerMap();
      await Map.addSvgMarkerImages(map, [this.vehicle]);

      const geoJson = this.vehicle.toGeoJson();
      this.lastLocationFeature = {
        ...geoJson,
        properties: {
          ...geoJson.properties,
          isLastLocation: true,
        },
      };

      this.updateSource();
    },
    showPopup(address, timestamp) {
      this.selectedAddress = address;
      this.selectedTimestamp = timestamp;
      this.popupLatLng = this.centerCoordinates;
      this.popupShown = true;
    },
    updateSource() {
      if (this.map) {
        const source = this.map.getSource(SOURCE_ID);

        if (source) {
          source.setData({
            type: 'FeatureCollection',
            features: this.features,
          });
        }
      }
    },
  },
  created() {
    const { latitude, longitude } = this.selectedLocation;
    this.centerCoordinates = [longitude, latitude];
  },
  watch: {
    disconnectedLocationHistory() {
      this.updateSource();
    },
    selectedLocation({ address, latitude, longitude, timestamp }) {
      this.centerCoordinates = [longitude, latitude];
      this.centerMap();
      this.showPopup(address, timestamp);
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  font-size: 14px;
  line-height: 1.5;
}
</style>
