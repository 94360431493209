import { dayjs } from 'src/services/date';
import { celsiusToFahreheit, fahreheitToCelsius } from 'src/services/locale';
import GeoJsonFeature from './GeoJsonFeature';
import SimpleModel from './SimpleModel';

class AssetHistory extends SimpleModel {
  /** @type {Number} */
  ambientTemperature = null;

  /** @type {Number} */
  deviceBatteryChargeLevel = null;

  /** @type {String} */
  key = null;

  /** @type {Object} */
  point = null;

  /** @type {Boolean} */
  selected = true;

  /** @type {Date} */
  timestamp = null;

  /** @type {String} */
  tripKey = null;

  constructor(data = {}) {
    super();
    const { timestamp } = data;
    this.insert({
      ...data,
      timestamp: dayjs(timestamp),
    });
  }

  /**
   * Returns ambientTemperature in degrees farhenheit
   *
   * @returns {Boolean}
   */
  get ambientTemperatureFahreheit() {
    return Number.isFinite(this.ambientTemperature)
      ? celsiusToFahreheit(this.ambientTemperature).toFixed(0)
      : this.ambientTemperature;
  }

  /**
   * Sets ambientTemperature in degrees farhenheit
   */
  set ambientTemperatureFahreheit(degreesF) {
    this.ambientTemperature = fahreheitToCelsius(degreesF);
  }

  /**
   * Generates a GeoJSON "Feature" to be used in a GeoJSON layer.
   *
   * @returns {Object} GeoJSON Feature
   */
  toGeoJson() {
    const { key, point, timestamp, tripKey } = this;
    const { lat, lon } = point;

    return new GeoJsonFeature([lon, lat], {
      key,
      timestamp,
      tripKey,
    });
  }
}

export default AssetHistory;
