<template>
  <ZubieSelect
    ref="nodesSelect"
    :bg-color="bgColor"
    :class="{ 'has-hint': hint }"
    :dense="dense"
    :disable="disable"
    display-value-sanitize
    :filled="filled"
    :for="forId"
    :hide-dropdown-icon="readonly"
    :hint="hint"
    :label="label"
    map-options
    :model-value="selectedNodes"
    multiple
    :optionLabel="nodeLabelKey"
    :optionValue="nodeValueKey"
    :options="availableNodes"
    options-sanitize
    :readonly="readonly"
    :tooltip="tooltip"
    use-chips
    @add="onNodeSelected"
    @remove="onNodeDeselected"
  >
    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend" />
    </template>
    <template v-slot:option="scope">
      <div>
        <TreeNode
          v-if="isVisible(scope.opt[nodeValueKey])"
          v-bind="scope"
          :expanded="scope.opt.expanded"
          :node="scope.opt"
          :nodeLabelKey="nodeLabelKey"
          :selected="scope.itemProps.active"
          :selection-forbidden="scope.opt.selectionForbidden"
          @toggle-expanded-state="toggleExpandState"
        ></TreeNode>
      </div>
    </template>
    <template v-slot:selected-item="scope">
      <CondensedGroupList
        dense
        :groups="[scope.opt]"
        no-copy
        removable
        :tabindex="scope.tabindex"
        @remove="scope.removeAtIndex(scope.index)"
      />
    </template>
  </ZubieSelect>
</template>
<script>
import _cloneDeep from 'lodash/cloneDeep';
import CondensedGroupList from 'components/groups/CondensedGroupList.vue';
import TreeNode from 'components/TreeNode.vue';
import ZubieSelect from 'components/ZubieSelect.vue';

export default {
  name: 'TreeViewMultiSelect',
  props: [
    'bgColor',
    'dataTree',
    'dense',
    'filled',
    'forId',
    'label',
    'labelFallback',
    'nodeLabelKey',
    'nodeValueKey',
    'tooltip',
    'value',
    'hint',
    'disable',
    'readonly',
    'singleSelection',
  ],
  components: {
    CondensedGroupList,
    TreeNode,
    ZubieSelect,
  },
  emits: ['change'],
  data() {
    return {
      availableNodes: [],
      expandedNodesMap: {},
      nodesTreeClone: [],
      selectedNodes: [],
    };
  },
  methods: {
    initializeNodesState() {
      this.expandedNodesMap = {};
      this.availableNodes = [];
      this.selectedNodes = [];
      let baseDepth = 0;
      const process = (nodes, parent) => {
        nodes.forEach((node) => {
          if (parent === null) {
            baseDepth = node.treeDepth;
          }
          node.treeLevel = node.treeDepth - baseDepth;
          node.parent = parent;
          node.selectedChildrenCount = 0;
          node.expanded = true;
          this.expandedNodesMap[node[this.nodeValueKey]] = node;

          if (!node[this.nodeLabelKey]) {
            node[this.nodeLabelKey] = this.labelFallback;
          }
          this.availableNodes.push(node);

          if (this.value && this.value.includes(node[this.nodeValueKey])) {
            this.selectedNodes.push(node[this.nodeValueKey]);
            this.updateParentsCount(node);
            node.selected = true;
            node.selectedChildrenCount = 0;
          }

          return process(node.children, node);
        });
      };
      process(this.nodesTreeClone, null);
    },
    toggleExpandState(node) {
      node.expanded = !node.expanded;
      // We need to reload the options in order to hide/show the children
      this.$refs.nodesSelect.$forceUpdate();
    },
    onNodeSelected(details) {
      if (this.singleSelection) {
        this.deselectAll();
      }
      this.selectedNodes.push(details.value[this.nodeValueKey]);
      if (details.value.parent) {
        this.deselectAllParents(details.value, true);
      }
      this.deselectAllChildren(details.value);
      this.updateParentsCount(details.value);
      details.value.selectedChildrenCount = 0;
      details.value.selected = true;

      this.$emit('change', [...this.selectedNodes]);
    },
    onNodeDeselected(details) {
      this.deselectNode(this.expandedNodesMap[details.value]);
      this.$emit('change', [...this.selectedNodes]);
    },
    deselectNode(node) {
      this.selectedNodes = this.selectedNodes.filter((value) => value !== node[this.nodeValueKey]);
      node.selected = false;
      this.updateParentsCount(node, true);
    },
    deselectAllChildren(node) {
      const traverseRecursive = (nodes) => {
        nodes.forEach((child) => {
          if (child.selected) {
            this.deselectNode(child);
          }
          return traverseRecursive(child.children);
        });
      };
      traverseRecursive(node.children);
    },
    deselectAllParents(node) {
      while (node.parent) {
        if (node.parent.selected) {
          this.deselectNode(node.parent);
        }

        node = node.parent;
      }
    },
    updateParentsCount(node, decrement) {
      while (node.parent) {
        node.parent.selectedChildrenCount += decrement ? -1 : 1;
        node = node.parent;
      }
    },
    isVisible(nodeKey) {
      let node = this.expandedNodesMap[nodeKey];
      while (node.parent) {
        if (!node.parent.expanded) {
          return false;
        }
        node = node.parent;
      }
      return true;
    },
    deselectAll() {
      this.selectedNodes.forEach((nodeKey) => this.deselectNode(this.expandedNodesMap[nodeKey]));
    },
  },
  mounted() {
    this.nodesTreeClone = _cloneDeep(this.dataTree);
    this.initializeNodesState();
  },
  watch: {
    dataTree() {
      this.nodesTreeClone = _cloneDeep(this.dataTree);
      this.initializeNodesState();
    },
    value() {
      this.initializeNodesState();
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.q-field__native) {
  padding-top: map-get($space-sm, 'y') !important;
}
</style>
