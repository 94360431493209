import { mapActions, mapState } from 'vuex';

const mobileTitle = {
  computed: {
    ...mapState('app', ['mobileTitle', 'mobileTitlePending']),
    mobileTitleDisplayed() {
      return (this.mobileTitle || this.mobileTitlePending) && (this.$q.screen.xs || this.$q.screen.mobileHeight);
    },
  },
  methods: {
    ...mapActions('app', ['setMobileTitle', 'setMobileTitlePending']),
  },
};

export default mobileTitle;
