import _range from 'lodash/range';

export default () => ({
  year: null,
  make: null,
  model: null,
  trim: null,
  style: null,
  availableYears: _range(1996, new Date().getFullYear() + 2).reverse(),
  availableMakes: [],
  availableModels: [],
  availableTrims: [],
  availableStyles: [],
  vehicleStyle: {},
  loading: false,
});
