import { Dialog, Notify, Platform } from 'quasar';
import { dayjs } from 'src/services/date';
import errorService from 'src/services/error';
import { navigateToUrl } from 'src/services/navigation';

export const NOTIFICATION_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ssZZ';

export const NOTIFICATION_SUPPORT = Object.freeze({
  DEFERRED: 'DEFERRED',
  DENIED: 'DENIED',
  GRANTED: 'GRANTED',
  NONE: 'NONE',
  PROMPT: 'PROMPT',
  'PROMPT-WITH-RATIONALE': 'PROMPT-WITH-RATIONALE',
  UNKNOWN: 'UNKNOWN',
});

export const config = {
  /** @type {import('src/types/notifications-store').NotificationsStoreActionContext} */
  context: null,
  /** @type {import('./NotificationsHandler').default} */
  handler: null,
  /** @type {import('vue').Component} */
  simpleDialog: null,
};

const unhandledNotificationKinds = [];

/**
 * Initializes native notifications.
 */
export async function initNativeNotifications(context) {
  config.context = context;
  await config.handler.init();
  const shouldRequest = await config.handler.check(true);
  if (shouldRequest) {
    requestPermission();
  }
}

/**
 * @param {import('src/models/Notification').default['action']} action
 */
export async function doAction(action) {
  switch (action.action) {
    case 'navigate':
      navigateToUrl(action.path);
      break;
    case 'dialog':
      simpleDialog(action);
      break;
    case 'navigateToVehicleByTrip': {
      const trip = await config.context.rootState.app.broker.fetchAndTransform({
        fn: 'getTrip',
        params: {
          key: action.tripKey,
        },
      });
      navigateToUrl(`/vehicle/${trip.vehicle.key}/trips/${action.occurrenceTime}?tripKey=${action.tripKey}`);
      break;
    }
    default:
      navigateToUrl('/');
  }
}

/**
 * Determines and returns the deep link for a notification.
 *
 * @param {NotificationApiItem} notification
 * @param {boolean} isIndividual
 */
export function getNotificationAction(notification, isIndividual = false) {
  const { created, kind } = notification;
  const payload = JSON.parse(notification.payload);
  const occurrenceTime = getOccurrenceTime(notification);
  const { asset_key: assetKey, geofence_key: placeKey, trip_key: tripKey, vehicle_key: vehicleKey } = payload;

  if (isIndividual) {
    // Always redirect to feed
    return { action: 'navigate', path: '/feed' };
  }

  switch (kind) {
    // Vehicle overview
    case 'Vehicle Door Lock':
    case 'Vehicle Door Unlock':
    case 'Device Unplugged Alert':
    case 'Device Unplugged':
    case 'Vehicle Change':
    case 'Device Moved to New Vehicle':
      return { action: 'navigate', path: `/vehicle/${vehicleKey}` };
    // Vehicle edit
    case 'Car Style Disambiguation':
      return { action: 'navigate', path: `/vehicle/${vehicleKey}/edit` };
    // Health
    case 'Battery Level Notification':
    case 'Diagnostic Code':
    case 'Low Battery':
    case 'Low Fuel Alert':
    case 'Low Fuel':
    case 'Trouble Code Cleared':
    case 'Trouble Code':
    case 'Fuel Refill Alert':
      return { action: 'navigate', path: `/vehicle/${vehicleKey}/health` };
    // Maintenance
    case 'Car Maintenance Reminder':
      return { action: 'navigate', path: `/vehicle/${vehicleKey}/maintenance` };
    // Devices
    case 'Device Registration Attempt Expired':
    case 'Device Registration Completed':
    case 'New Device Activated':
    case 'Device Reconnect Alert':
      return { action: 'navigate', path: '/devices' };
    // Trips
    case 'Hard Brake':
    case 'Over Speeding':
    case 'Possible Crash Alert':
    case 'Rapid Accelerations':
    case 'Severe Hard Braking':
    case 'Trip Completed':
    case 'Trip End':
    case 'Trip Start':
    case 'Trip Tagged':
    case 'Idle':
    case 'Car Schedule Exception':
    case 'Vehicle Motion Monitor Alert':
    case 'Speed Limit Violation':
      return {
        action: 'navigate',
        path: `/vehicle/${vehicleKey}/trips/${occurrenceTime.format('YYYY-MM-DD')}?tripKey=${tripKey}`,
      };
    case 'Arriving at a Place':
    case 'Leaving from a Place':
      return {
        action: 'navigate',
        path: `/vehicle/${vehicleKey}/trips/${occurrenceTime.format('YYYY-MM-DD')}?placeKey=${placeKey}`,
      };
    // Users
    case 'User Accepted Invite Notification':
    case 'User Invite Notification':
      return { action: 'navigate', path: '/users' };
    // Places
    case 'User Location Geofence Notification':
      return { action: 'navigate', path: '/places' };
    // App marketplace
    case 'Companion App Installed':
    case 'Companion App Removed':
    case 'App Installed':
    case 'App Removed':
      return { action: 'navigate', path: '/apps' };
    // Assets
    case 'Asset Geofence Arrival':
    case 'Asset Geofence Departure':
    case 'Device Low Battery':
      return { action: 'navigate', path: `/asset/${assetKey}/locations` };
    case 'User Direct Message':
      return {
        action: 'dialog',
        created,
        body: payload.message_text,
        title: payload.message_summary.split(':')[0],
      };
    case 'Distracted Driving':
      return {
        action: 'navigateToVehicleByTrip',
        occurrenceTime: occurrenceTime.format('YYYY-MM-DD'),
        tripKey,
      };
    // Send to live map by default
    default:
      if (!unhandledNotificationKinds.includes(kind)) {
        errorService.log(new Error(`Unhandled notification kind: "${kind}"`), {
          metaData: {
            notification,
          },
        });
        unhandledNotificationKinds.push(kind);
      }
      return { action: 'navigate', path: '/' };
  }
}

/**
 * Returns the notification occurrence time based on the reported time in the payload.
 *
 * @param {NotificationApiItem} notification
 * @returns {String}
 */
export function getOccurrenceTime(notification) {
  const payload = JSON.parse(notification.payload);
  const { reported = `${notification.created}-0000` } = payload;
  return dayjs(reported, NOTIFICATION_DATE_FORMAT);
}

/**
 * Displays a notification that asks the user if they'd like to open
 * the settings app to allow notifications.
 */
export function enableViaSettings() {
  let actions = [
    {
      label: 'Not Now',
      class: 'q-mr-sm',
      color: 'white',
      flat: true,
      handler() {
        dismiss();
      },
    },
    {
      label: 'Open Settings',
      color: 'white',
      textColor: 'primary',
      flat: false,
      rounded: true,
      handler() {
        config.handler.openSettings();
        dismiss();
      },
    },
  ];

  const dismiss = Notify.create({
    icon: 'info',
    color: 'primary',
    message: 'Enable Notifications via Settings',
    caption: 'Turn on notifications within the app settings on your device.',
    timeout: 0,
    actions,
  });
}

/**
 * Sets the handler that makes the permissions request.
 *
 * @param {import('./NotificationsHandler').default} handler
 */
export function setHandler(handler) {
  config.handler = handler;
}

/**
 * Displays a Quasar notification requesting to enable web notifications.
 */
export async function requestPermission(direct = false) {
  if (direct) {
    config.handler.request();
    return;
  }

  if (config.context.getters.deferred) {
    // Do not display prompt if user already said "Not Now"
    return;
  }

  const type = Platform.is.capacitor ? 'Push' : 'Web';

  const dismiss = Notify.create({
    icon: 'help',
    color: 'primary',
    message: `Enable ${type} Notifications?`,
    caption: `Allow Zubie to send ${type.toLowerCase()} notifications for events that occur on your account?`,
    timeout: 0,
    actions: [
      {
        label: 'Not Now',
        class: 'q-mr-sm',
        color: 'white',
        flat: true,
        handler() {
          config.context.dispatch('setNotificationSupport', NOTIFICATION_SUPPORT.DEFERRED);
          dismiss();
        },
      },
      {
        label: 'Enable',
        color: 'white',
        textColor: 'primary',
        flat: false,
        rounded: true,
        handler() {
          config.handler.request();
          dismiss();
        },
      },
    ],
  });
}

/**
 * Retrieves the current notification support status.
 *
 * @returns {Promise<'GRANTED' | 'DENIED' | 'PROMPT' | null>}
 */
export function getStatus() {
  return config.handler.getNotificationState();
}

/**
 * Disables notifications handling.
 */
export function disable() {
  config.handler.disable();
}

/**
 * Sets the dialog component to be used for the simple notification dialog.
 * NOTE: The app won't compile if the component is in this service file, so this is a workaround.
 *
 * @param {import('vue').Component} component
 */
export function setDialog(component) {
  config.simpleDialog = component;
}

/**
 * Displays a simple dialog using the notification action data.
 *
 * @param {import('src/models/Notification').default['action']} action
 */
export function simpleDialog(action) {
  Dialog.create({
    component: config.simpleDialog,
    componentProps: action,
  });
}
