import AssetsBaseLayer from './AssetsBaseLayer';

export default class AssetsLayer extends AssetsBaseLayer {
  /** @var {String} id */
  static id = 'assets';

  /**
   * @returns {Object} A GeoJson layer config object.
   */
  getConfig() {
    const parentConfig = super.constructor.getConfig();

    return Object.freeze({
      id: this.getId(),
      ...parentConfig,
      layout: {
        ...parentConfig.layout,
        'icon-image': '{icon}',
      },
    });
  }
}
