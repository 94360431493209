import { colors } from 'quasar';
import { tagTypes } from 'src/services/constants';
import store from 'src/store';
import SimpleModel from './SimpleModel';

// help webpack tree shaking: https://quasar.dev/quasar-utils/color-utils#helping-tree-shake
const { brightness } = colors;

export default class Tag extends SimpleModel {
  /** @type {String} */
  key = null;

  /** @type {String} */
  value = null;

  /** @type {String} */
  type = null;

  /** @type {String} */
  color = null;

  constructor(data) {
    super();
    this.insert(data);
  }

  /**
   * Returns true if the tag color is "bright".
   *
   * @returns {Boolean}
   */
  get isBright() {
    return brightness(this.color) >= 128;
  }

  /**
   * Returns true if the tag color is "very bright".
   *
   * @returns {Boolean}
   */
  get isVeryBright() {
    return brightness(this.color) >= 245;
  }

  /**
   * Returns the number of vehicles/assets/users using the tag.
   *
   * @returns {Number}
   */
  get memberCount() {
    let entities;

    switch (this.type) {
      case tagTypes.ASSET:
        entities = store().getters['assets/assets'];
        break;
      case tagTypes.CAR:
        entities = store().getters['assets/vehicles'];
        break;
      case tagTypes.USER:
        entities = store().getters['users/usersAsArray'];
        break;
      default:
        entities = [
          ...store().getters['assets/assets'],
          ...store().getters['assets/vehicles'],
          ...store().getters['users/usersAsArray'],
        ];
    }

    return entities.filter((entity) => {
      if (entity.tags) {
        return entity.tags.some((tag) => tag.key === this.key);
      }
      return entity.tagKeys.includes(this.key);
    }).length;
  }

  /**
   * Returns a proper label of the type.
   */
  get typeLabel() {
    switch (this.type) {
      case tagTypes.CAR:
        return 'Vehicle';
      case tagTypes.TRIP:
        return 'Trip';
      case tagTypes.USER:
        return 'User';
      default:
        return 'Generic';
    }
  }
}
