<template>
  <div class="q-pt-xs">
    <MappedItemDetailsRow v-if="isVehicle" data-t="vin" label="VIN">
      <ZubieChip color="transparent" dense flat :outline="false" :search-terms="searchTerms">
        {{ asset.vin || '--' }}
      </ZubieChip>
    </MappedItemDetailsRow>

    <MappedItemDetailsRow v-if="asset.firstConnectedDevice.serial" class="q-mt-xs" label="Device #">
      <DeviceChip dense :device="asset.firstConnectedDevice" :search-terms="searchTerms" />
    </MappedItemDetailsRow>

    <MappedItemDetailsRow v-if="!isRental && isVehicle" class="q-mt-xs" data-t="driver" label="Driver">
      <DriverChip data-t="driverHighlight" dense :driver="asset.driver" :search-terms="searchTerms" />
    </MappedItemDetailsRow>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DeviceChip from 'components/chips/DeviceChip.vue';
import DriverChip from 'components/chips/DriverChip.vue';
import ZubieChip from 'components/chips/ZubieChip.vue';
import MappedItemDetailsRow from 'components/vehicles-list/MappedItemDetailsRow.vue';
import Asset from 'src/models/Asset';
import Vehicle from 'src/models/Vehicle';

export default {
  name: 'MappedItemIdsAndDriver',
  components: {
    DeviceChip,
    MappedItemDetailsRow,
    DriverChip,
    ZubieChip,
  },
  props: {
    asset: {
      required: true,
      type: [Vehicle, Asset],
    },
    searchTerms: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('session', ['isRental']),
    isVehicle() {
      return this.asset instanceof Vehicle;
    },
  },
};
</script>
