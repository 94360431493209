import _isNil from 'lodash/isNil';
import _remove from 'lodash/remove';
import { SEARCH_TYPES } from 'src/services/constants';
import { getKeywordsByKey } from 'src/services/search';

/**
 * Opens/closes the list dialog on the Live Map.
 *
 * @param {SearchStoreActionContext} context
 */
export async function prepareMapForSearch(context) {
  const { listDialogOpen } = context.rootState.map;

  if (context.state.searchType === SEARCH_TYPES.FILTER) {
    if (!listDialogOpen) {
      // Open list dialog if we're in filter mode
      await context.dispatch('map/openListDialog', null, { root: true });
    }
  } else if (listDialogOpen) {
    /**
     * Close list dialog if we're leaving filter mode and the dialog
     * isn't visible at the current viewport size.
     */
    await context.dispatch('map/closeListDialog', null, { root: true });
  }
}

/**
 * Resets the selected result to the first item.
 *
 * @param {SearchStoreActionContext} context
 */
export function resetResultSelection(context) {
  context.commit('setActiveIndex', -1);
}

/**
 * Saves a result to the recent search history.
 *
 * @param {SearchStoreActionContext} context
 * @param {SearchItem} searchResult
 */
export function saveHistory(context, searchResult) {
  let history = [...context.state.history];

  // Remove an existing result if found
  _remove(history, { key: searchResult.key });

  // Prepend result to history
  history.unshift(searchResult);

  // Limit to 3 items per type
  let resultsOfType = 0;
  history = history.filter((result) => {
    if (result.type === searchResult.type) {
      resultsOfType += 1;
      if (resultsOfType > 3) {
        return false;
      }
    }
    return true;
  });

  context.commit('setHistory', history);
}

/**
 * Selects the next result in the search results.
 *
 * @param {SearchStoreActionContext} context
 */
export function selectNextResult(context) {
  const { activeIndex } = context.state;
  let nextIndex = activeIndex + 1;

  const nextResult = context.getters.allOptionsIndexed[nextIndex] || {};
  if (nextResult.isCurrentRoute) {
    // First item is current route, skip it
    nextIndex += 1;
  }

  if (nextIndex > context.getters.allOptionsIndexed.length - 1) {
    return;
  }

  context.commit('setActiveIndex', nextIndex);
}

/**
 * Selects the previous result in the search results.
 *
 * @param {SearchStoreActionContext} context
 */
export function selectPreviousResult(context) {
  const { activeIndex } = context.state;
  const previousIndex = activeIndex - 1;

  if (activeIndex <= 0) {
    return;
  }

  const previousResult = context.getters.allOptionsIndexed[previousIndex] || {};
  if (previousResult.isCurrentRoute) {
    return;
  }

  context.commit('setActiveIndex', previousIndex);
}

/**
 * Sets the "is searching" flag.
 *
 * @param {SearchStoreActionContext} context
 * @param {boolean} isSearching
 */
export function setIsSearching(context, isSearching) {
  context.commit('setIsSearching', isSearching);
}

/**
 * Sets the "type" of search to use for Universal Search.
 *
 * @param {SearchStoreActionContext} context
 * @param {keyof typeof import('src/services/constants').SEARCH_TYPES} type
 */
export async function setSearchType(context, type) {
  context.commit('setSearchType', type);
  context.dispatch('prepareMapForSearch');
}

/**
 * Sets the items that will appear in the search results.
 *
 * @param {SearchStoreActionContext} context
 * @param {SearchItem[]} items
 */
export function setSearchItems(context, items) {
  context.commit('setSearchItems', items);
}

/**
 * Sets the keyword search terms.
 *
 * @param {SearchStoreActionContext} context
 * @param {string} terms
 */
export async function setSearchedTerms(context, terms) {
  context.commit('setSearchedTerms', terms);
}

/**
 * Sets the search priority (affects search result sorting).
 *
 * @param {SearchStoreActionContext} context
 * @param {SearchResultType} priority
 */
export function setSearchPriority(context, priority) {
  context.commit('setSearchPriority', priority);
}

/**
 * Sets the Universal Search terms.
 *
 * @param {SearchStoreActionContext} context
 * @param {string} terms
 */
export async function updateSearchTerms(context, terms) {
  const items = await context.rootState.app.broker.performUniversalSearch(terms);
  context.dispatch('setSearchItems', items);
}

/**
 * Sets the page filter terms.
 *
 * @param {SearchStoreActionContext} context
 * @param {String} filterTerms
 */
export async function updateFilterTerms(context, filterTerms) {
  const validTerms = filterTerms
    .split(',')
    .filter((term) => (term || '').trim().length > 0)
    .join(',');

  context.commit('setFilterTerms', validTerms);
}

/**
 * Verifies unverified history items based on type.
 *
 * @param {SearchStoreActionContext} context
 * @param {SearchResultType} type
 */
export async function verifyHistory(context, type) {
  const keywords = await Promise.all(
    context.state.history.map((item) => {
      const itemType = item.type.toLowerCase();
      if (itemType === type && item.unverified) {
        return getKeywordsByKey(item.key, itemType);
      }
      return item;
    })
  );

  // Remove empty results
  const history = keywords.filter((result) => !_isNil(result));

  context.commit('setHistory', history);
}
