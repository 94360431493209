import { polygon } from '@turf/helpers';
import _merge from 'lodash/merge';
import { LngLatBounds } from 'mapbox-gl';

class Bounds {
  /** @property {Boolean} */
  isEmpty = false;

  /** @property {Object} */
  northEast = {};

  /** @property {Object} */
  southWest = {};

  /**
   * Constructor for Bounds
   *
   * @param {Bounds|Array[]|String[]|null} bounds Bounds object or array (nested or flat) defining lat/long bounds
   * @example
   *   new Bounds(boundsObj)
   *   new Bounds([[minLong, minLat], [maxLong, maxLat]])
   *   new Bounds([minLong, minLat, maxLong, maxLat])
   */
  constructor(bounds) {
    if (isBoundsObject(bounds)) {
      _merge(this, bounds);
    } else if (Array.isArray(bounds)) {
      convertArrayToBounds(this, bounds);
    } else if (!bounds) {
      this.isEmpty = true;
    } else if (typeof bounds.toArray === 'function') {
      convertArrayToBounds(this, bounds.toArray());
    } else {
      throw new Error(
        `Cannot convert ${typeof bounds === 'object' ? JSON.stringify(bounds) : bounds} to a Bounds instance.`
      );
    }
  }

  /**
   * Returns the eastern longitude of the bounds.
   *
   * @returns {Number}
   */
  getEast() {
    return this.northEast.longitude;
  }

  /**
   * Returns the northern latitude of the bounds.
   *
   * @returns {Number}
   */
  getNorth() {
    return this.northEast.latitude;
  }

  /**
   * Returns the southern latitude of the bounds.
   *
   * @returns {Number}
   */
  getSouth() {
    return this.southWest.latitude;
  }

  /**
   * Returns the western longitude of the bounds.
   *
   * @returns {Number}
   */
  getWest() {
    return this.southWest.longitude;
  }

  /**
   * Comparse this Bounds instance to another Bounds instance.
   *
   * @param {Bounds} value
   * @returns {Boolean}
   */
  equals(value) {
    if (value instanceof Bounds !== true) {
      return false;
    }

    return (
      this.northEast.longitude === value.northEast.longitude &&
      this.northEast.latitude === value.northEast.latitude &&
      this.southWest.longitude === value.southWest.longitude &&
      this.southWest.latitude === value.southWest.latitude
    );
  }

  /**
   * Converts Bounds values to a pair lat/long coordinates.
   *
   * @returns {Array}
   */
  toArray() {
    return [
      [this.southWest.longitude, this.southWest.latitude],
      [this.northEast.longitude, this.northEast.latitude],
    ];
  }

  /**
   * Converts Bounds values to a Mapbox GL LngLatBounds.
   */
  toLngLat() {
    const southWest = [this.southWest.longitude, this.southWest.latitude];
    const northEast = [this.northEast.longitude, this.northEast.latitude];
    return new LngLatBounds(southWest, northEast);
  }

  /**
   * Converts the bounds coordinates into a polygon.
   *
   * Note: this is sorted to match the polygon created during
   * a Turf intersection comparison.
   *
   * @returns {Array[]}
   */
  toPolygon() {
    return polygon([
      [
        [this.southWest.longitude, this.southWest.latitude],
        [this.northEast.longitude, this.southWest.latitude],
        [this.northEast.longitude, this.northEast.latitude],
        [this.southWest.longitude, this.northEast.latitude],
        [this.southWest.longitude, this.southWest.latitude],
      ],
    ]);
  }

  /**
   * Converts Bounds values to a string representation of the bounds.
   *
   * @returns {String}
   */
  toString() {
    return `${this.southWest.longitude},${this.southWest.latitude},${this.northEast.longitude},${this.northEast.latitude}`;
  }
}

export default Bounds;

/**
 * Private functions
 */

/**
 * Tests if the given object is similar to the properties of a Bounds instance.
 *
 * @param {Object} object Object that's tested to see if it is Bounds-like.
 */
function isBoundsObject(object) {
  return object && object.northEast && object.southWest;
}

/**
 * Converts a pair of lat/long coordinates into a Bounds instance.
 *
 * @param {Bounds} boundsInstance The Bounds instance to update.
 * @param {Array} boundsArray A pair of lat/long coordinates used to set the north-east & south-west properties of a Bounds instance.
 */
function convertArrayToBounds(boundsInstance, boundsArray = []) {
  const isNestedArray = Array.isArray(boundsArray[0]);

  if (isNestedArray) {
    const [southWest, northEast] = boundsArray;
    setLatLong(boundsInstance, 'northEast', northEast);
    setLatLong(boundsInstance, 'southWest', southWest);
  } else {
    const [southWestLong, southWestLat, northEastLong, northEastLat] = boundsArray;
    setLatLong(boundsInstance, 'northEast', [northEastLong, northEastLat]);
    setLatLong(boundsInstance, 'southWest', [southWestLong, southWestLat]);
  }

  return boundsInstance;
}

/**
 * Sets the given edge of a Bounds instance to the given lat/long coordinates.
 *
 * @param {Bounds} boundsInstance The Bounds instance to update.
 * @param {String} edge The bound point to update (north-east or south-west).
 * @param {Array} latLongArray Lat/long coordinates used to update the given edge of the Bounds instance.
 */
function setLatLong(boundsInstance, edge, latLongArray = []) {
  const [longitude, latitude] = latLongArray;
  boundsInstance[edge] = {
    latitude,
    longitude,
  };
}
