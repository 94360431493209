<template>
  <div v-if="share">
    <q-btn
      :color="color"
      dense
      flat
      :href="url"
      icon="share"
      round
      :size="size"
      target="_blank"
      type="a"
      @click.stop="() => {}"
    >
      <q-tooltip
        :anchor="tooltipAnchor"
        :self="tooltipSelf"
        :transition-hide="tooltipTransitionHide"
        :transition-show="tooltipTransitionShow"
      >
        {{ tooltip }}
      </q-tooltip>
    </q-btn>
  </div>
</template>

<script setup lang="ts">
import type { QTooltipProps } from 'quasar';
import _sortBy from 'lodash/sortBy';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { SHARE_TYPES } from 'src/services/constants';
import { dayjs, utcToLocalDateTime } from 'src/services/date';
import type { RootStore } from 'src/types/store';

const props = withDefaults(
  defineProps<{
    assetKey: string;
    size?: string;
    tooltipAnchor?: QTooltipProps['anchor'];
    tooltipSelf?: QTooltipProps['self'];
    tooltipTransitionHide?: string;
    tooltipTransitionShow?: string;
  }>(),
  {
    size: '12px',
    tooltipAnchor: 'bottom middle',
    tooltipSelf: 'top middle',
    tooltipTransitionHide: 'jump-up',
    tooltipTransitionShow: 'jump-down',
  }
);

const store = <RootStore>useStore();

const shares = computed(() => {
  const itemShares = store.state.shares.shares.filter(
    ({ assetKeys, vehicleKeys }) => assetKeys.includes(props.assetKey) || vehicleKeys.includes(props.assetKey)
  );
  return _sortBy(itemShares, ['expirationTimestamp']).reverse();
});

const share = computed(() => shares.value[0]);

const color = computed(() => {
  const { source } = share.value || {};
  return source === SHARE_TYPES.DEFAULT ? 'primary' : 'warning';
});

const expirationString = computed(() => {
  const { expirationTimestamp } = share.value || {};
  return expirationTimestamp ? utcToLocalDateTime(expirationTimestamp) : '';
});

const tooltip = computed(() => {
  const { expirationTimestamp, source: shareType } = share.value || {};
  const type = shareType === SHARE_TYPES.DEFAULT ? 'Shared' : 'Vehicle recovery';

  if (dayjs().add(50, 'years').valueOf() < expirationTimestamp) {
    return `${type} forever`;
  }
  return `${type} until ${expirationString.value}`;
});

const url = computed(() => `${store.state.env.locatorUrl}/${share.value?.id}`);
</script>

<style lang="scss" scoped>
:deep(.q-btn__wrapper) {
  min-height: 0 !important;
  min-width: 0 !important;
}
</style>
