/**
 * Uploads an image to Cloudinary in File object format.
 *
 * @param {ActionContext} context
 * @param {Object} payload
 * @param {File} payload.data
 * @param {string} payload.metadata
 * @param {string} payload.publicId
 * @param {string} payload.url
 * @returns {Promise<import('cloudinary').UploadApiResponse>}
 */
export async function uploadImage(context, { data, metadata, publicId, url }) {
  return context.rootState.app.broker.fetchAndTransform({
    fn: 'uploadImageToCloudinary',
    params: {
      data,
      metadata,
      publicId,
      url,
    },
  });
}
