<template>
  <ZubieDialog
    ref="dialog"
    :action-button-text="`${addInProgress ? 'Adding' : 'Add'} Schedule`"
    :processing="addInProgress"
    title="Add a New Schedule"
    :visible="visible"
    width="350px"
    @hide="onHide"
    @submit="onAdd"
  >
    <q-input v-model="name" autofocus :disable="addInProgress" filled label="Schedule Name" />
    <div class="q-mt-md">
      <div>Weekdays</div>
      <div class="row q-col-gutter-sm items-end">
        <q-checkbox v-model="weekdayEnabled" :disable="addInProgress" />
        <div class="col">
          <div class="text-caption text-grey-7">From</div>
          <div class="row no-wrap">
            <ClockButton
              class="full-width"
              :disable="!weekdayEnabled || addInProgress"
              :time="weekdayStart"
              @update:model-value="weekdayStart = $event"
            />
          </div>
        </div>
        <div class="col">
          <div class="text-caption text-grey-7">To</div>
          <ClockButton
            class="full-width"
            :disable="!weekdayEnabled || addInProgress"
            :time="weekdayEnd"
            @update:model-value="weekdayEnd = $event"
          />
        </div>
      </div>
    </div>
    <div class="q-mt-md">
      <div>Weekends</div>
      <div class="row q-col-gutter-sm items-end">
        <q-checkbox v-model="weekendEnabled" :disable="addInProgress" />
        <div class="col">
          <div class="text-caption text-grey-7">From</div>
          <div class="row no-wrap">
            <ClockButton
              class="full-width"
              :disable="!weekendEnabled || addInProgress"
              :time="weekendStart"
              @update:model-value="weekendStart = $event"
            />
          </div>
        </div>
        <div class="col">
          <div class="text-caption text-grey-7">To</div>
          <ClockButton
            class="full-width"
            :disable="!weekendEnabled || addInProgress"
            :time="weekendEnd"
            @update:model-value="weekendEnd = $event"
          />
        </div>
      </div>
    </div>
    <q-banner v-if="error" class="q-mt-md text-white bg-negative">{{ error }}</q-banner>
  </ZubieDialog>
</template>

<script>
import { mapActions } from 'vuex';
import ClockButton from 'components/ClockButton.vue';

const INITIAL_DATA = Object.freeze({
  addInProgress: false,
  error: null,
  name: 'Daily: 8:00 AM-5:00 PM',
  scheduleAdded: false,
  weekdayStart: '8:00 AM',
  weekdayEnd: '5:00 PM',
  weekdayEnabled: true,
  weekendStart: '8:00 AM',
  weekendEnd: '5:00 PM',
  weekendEnabled: true,
});

export default {
  name: 'NewScheduleDialog',
  components: {
    ClockButton,
  },
  emits: ['cancel', 'hide', 'update:model-value'],
  data() {
    return {
      ...INITIAL_DATA,
      visible: true,
    };
  },
  computed: {
    nameProps() {
      const { weekdayStart, weekdayEnd, weekdayEnabled, weekendStart, weekendEnd, weekendEnabled } = this;
      return `${weekdayStart}${weekdayEnd}${weekdayEnabled}${weekendStart}${weekendEnd}${weekendEnabled}`;
    },
  },
  methods: {
    ...mapActions('vehicles', ['addSchedule']),
    updateName() {
      const { weekdayStart, weekdayEnd, weekdayEnabled, weekendStart, weekendEnd, weekendEnabled } = this;
      let name = '';
      if (weekdayEnabled && weekendEnabled && weekdayStart === weekendStart && weekdayEnd === weekendEnd) {
        name = `Daily: ${weekdayStart}-${weekdayEnd}`;
      } else {
        if (weekdayEnabled) {
          name += `M-F: ${weekdayStart}-${weekdayEnd}`;
        }
        if (weekendEnabled) {
          name += `${weekdayEnabled ? ', ' : ''}Sa-Su: ${weekendStart}-${weekendEnd}`;
        }
      }
      this.name = name;
    },
    async onAdd() {
      const payload = {
        name: this.name,
        weekdayEnabled: this.weekdayEnabled,
        weekendEnabled: this.weekendEnabled,
      };

      this.addInProgress = true;

      if (this.weekdayEnabled) {
        payload.weekdayStart = this.weekdayStart;
        payload.weekdayEnd = this.weekdayEnd;
      }

      if (this.weekendEnabled) {
        payload.weekendStart = this.weekendStart;
        payload.weekendEnd = this.weekendEnd;
      }

      const result = await this.addSchedule(payload);

      if (result.error) {
        this.error = result.error;
      } else {
        this.scheduleAdded = true;
        this.$emit('update:model-value', result.key);
        this.visible = false;
      }

      this.$nextTick(() => {
        this.addInProgress = false;
      });
    },
    onHide() {
      if (!this.scheduleAdded) {
        this.$emit('cancel');
      }
      this.$emit('hide');
    },
  },
  mounted() {
    this.data = {
      ...INITIAL_DATA,
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        this.data = {
          ...INITIAL_DATA,
        };
      }
    },
    nameProps() {
      this.updateName();
    },
  },
};
</script>
