export async function getMakes(context) {
  context.commit('setLoading', true);

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getVehicleMakes',
    params: context.state.year,
    transformationFn: 'transformArray',
  });

  context.commit('setLoading', false);

  if (!response.errors && !response.error) {
    context.commit('setAvailableMakes', response);
  }

  return response;
}

export async function getModels(context) {
  context.commit('setLoading', true);

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getVehicleModels',
    params: {
      year: context.state.year,
      make: context.state.make,
    },
    transformationFn: 'transformArray',
  });

  context.commit('setLoading', false);

  if (!response.errors && !response.error) {
    context.commit('setAvailableModels', response);
  }

  return response;
}

export async function getTrims(context) {
  context.commit('setLoading', true);

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getVehicleTrims',
    params: {
      year: context.state.year,
      make: context.state.make,
      model: context.state.model,
    },
    transformationFn: 'transformArray',
  });

  context.commit('setLoading', false);

  if (!response.errors && !response.error) {
    context.commit('setAvailableTrims', response);
    if (response.length === 1) {
      context.dispatch('selectTrim', response[0].trim);
    }
  }

  return response;
}

export async function getStyles(context) {
  context.commit('setLoading', true);

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getVehicleStyles',
    params: {
      year: context.state.year,
      make: context.state.make,
      model: context.state.model,
      trim: context.state.trim,
    },
    transformationFn: 'transformArray',
  });

  context.commit('setLoading', false);

  if (!response.errors && !response.error) {
    context.commit('setAvailableStyles', response);
    if (response.length === 1) {
      context.dispatch('selectStyle', response[0]);
    }
  }

  return response;
}

export async function getStyleDetails(context, styleId) {
  context.commit('setLoading', true);

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getVehicleStyleDetails',
    params: {
      id: styleId,
    },
    transformationFn: 'transformArray',
  });

  context.commit('setLoading', false);

  return response;
}

export async function selectYear(context, year) {
  context.commit('selectYear', year);
  await context.dispatch('getMakes');
}

export async function selectMake(context, make) {
  context.commit('selectMake', make);
  await context.dispatch('getModels');
}

export async function selectModel(context, model) {
  context.commit('selectModel', model);
  await context.dispatch('getTrims');
}
export async function selectTrim(context, trim) {
  context.commit('selectTrim', trim);
  if (trim) {
    await context.dispatch('getStyles');
  }
}

export async function selectStyle(context, style) {
  context.commit('selectStyle', style);
}

export async function clearStyle(context) {
  context.commit('resetSelected');
}
