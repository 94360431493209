import _camelCase from 'lodash/camelCase';
import _isArray from 'lodash/isArray';
import _isNil from 'lodash/isNil';
import _isPlainObject from 'lodash/isPlainObject';
import _keyBy from 'lodash/keyBy';
import _mapValues from 'lodash/mapValues';
import _omitBy from 'lodash/omitBy';
import _snakeCase from 'lodash/snakeCase';

class Transformer {
  static arrayToObject(array, keyName = 'key') {
    return _mapValues(_keyBy(array, keyName));
  }

  static camelCaseKeysDeep(originalObject) {
    return transformKeys(originalObject, _camelCase);
  }

  static snakeCaseKeysDeep(originalObject) {
    return transformKeys(originalObject, _snakeCase);
  }

  static mapKeysDeep(originalObject, keyMapping) {
    return transformKeys(originalObject, (key) => keyMapping[key] || key);
  }

  static transform(data) {
    return this.camelCaseKeysDeep(_omitBy(data, _isNil));
  }

  static transformArray(data = []) {
    return data.map((item) => this.transform(item));
  }

  static transformArrayToObject(array = [], keyName = 'key') {
    const transformedData = array.map((item) => this.transform(item));
    return this.arrayToObject(transformedData, keyName);
  }

  static none(data) {
    return data;
  }
}

export default Transformer;

function transformKeys(originalObject, strategy, newObj = {}) {
  return Object.keys(originalObject).reduce((newObject, key) => {
    const keyMappedName = strategy(key);
    const value = originalObject[key];

    let newValue;
    if (_isArray(originalObject[key])) {
      newValue = transformKeys(value, strategy, []);
    } else if (_isPlainObject(originalObject[key])) {
      newValue = transformKeys(value, strategy);
    } else {
      newValue = value;
    }
    newObject[keyMappedName] = newValue;

    return newObject;
  }, newObj);
}
