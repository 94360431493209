<template>
  <ZubieDialog
    ref="dialog"
    :action-button-text="loading ? 'Deleting' : 'Delete'"
    action-button-type="negative"
    :content-class="`row ${$q.screen.gt.xs ? 'items-center' : ''}`"
    :maximized="$q.screen.xs"
    :processing="loading"
    :title="`Delete ${majorType}?`"
    :visible="visible"
    @submit="handleDelete"
  >
    <span>
      You are about to delete
      <span class="bold">{{ asset.nickname || `this ${majorTypeLower}` }}</span
      >. Do you want to continue?
    </span>
  </ZubieDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Asset from 'src/models/Asset';
import ErrorPayload from 'src/models/ErrorPayload';
import { ASSET_TYPE_NAMES, ASSET_TYPES } from 'src/services/constants';

export default {
  name: 'DeleteAssetDialog',
  props: {
    assetKey: {
      required: true,
      default: null,
    },
  },
  emits: ['archived', 'error', 'update:visible'],
  computed: {
    ...mapGetters('assets', ['assetsVehicles']),
    asset() {
      return this.assetsVehicles.find(({ key }) => key === this.assetKey) || new Asset();
    },
    majorType() {
      return ASSET_TYPE_NAMES[this.asset.majorType];
    },
    majorTypeLower() {
      return this.majorType.toLowerCase();
    },
  },
  data() {
    return {
      loading: false,
      visible: true,
    };
  },
  methods: {
    ...mapActions('assets', ['deleteAsset']),
    ...mapActions('vehicles', ['deleteVehicle']),
    async handleDelete() {
      this.loading = true;

      const { majorType, nickname } = this.asset;

      let response;
      if (majorType === ASSET_TYPES.VEHICLE) {
        response = await this.deleteVehicle(this.assetKey);
      } else {
        response = await this.deleteAsset(this.assetKey);
      }

      this.loading = false;

      if (response.error) {
        this.$emit(
          'error',
          new ErrorPayload(
            `An unexpected error occurred when trying to delete "${
              nickname ? `"${nickname}"` : `this ${this.majorTypeLower}`
            }".`
          )
        );
        return;
      }

      this.$emit('archived');

      this.$q.notify({
        color: 'positive',
        icon: 'delete',
        message: `Successfully deleted${nickname ? `: ${nickname}` : ` the ${this.majorTypeLower}`}`,
      });

      this.visible = false;
    },
  },
};
</script>
