/**
 * @param {import('src/types/shares-store').SharesStoreState} state
 * @param {string} shareId
 */
export function removeShare(state, shareId) {
  state.shares = state.shares.filter(({ id }) => id !== shareId);
}

/**
 * @param {import('src/types/shares-store').SharesStoreState} state
 * @param {import('src/types/shares-store').LocationShare[]} shares
 */
export function insertShares(state, shares) {
  const updatedShares = [...state.shares];

  // Replace existing shares
  shares.forEach((share) => {
    const existingShare = updatedShares.find(({ id }) => id === share.id);
    if (existingShare) {
      Object.assign(existingShare, share);
    } else {
      updatedShares.push(share);
    }
  });

  state.shares = updatedShares;
}

/**
 * @param {import('src/types/shares-store').SharesStoreState} state
 * @param {boolean} isLoading
 */
export function setSharesLoading(state, isLoading) {
  state.sharesLoading = isLoading;
}
