import PCancelable from 'p-cancelable';

/**
 * Calls setTimeout and returns a cancelable promise that fulfills when the timeout is reached.
 *
 * @param {Number} delay in milliseconds
 * @return {Promise}
 */
export function setTimeoutPromise(delay = 0) {
  return new PCancelable((resolve) => {
    setTimeout(resolve, delay);
  });
}
