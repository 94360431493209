import { dayjs } from 'src/services/date';
import { NOTIFICATION_SUPPORT } from 'src/services/notification';

export default () => ({
  /** @type {string | null} */
  getAllRequestId: null,
  isCancellingGetAll: false,
  isLoadingAllNotifications: false,
  /** @type {string | null} */
  lastWebNotificationHash: null,
  /** @type {ReturnType<typeof dayjs>} */
  sinceDate: dayjs().utc().subtract(2, 'day'),
  /** @type {import('src/models/Notification').default[]} */
  notifications: [],
  /** @type {keyof typeof NOTIFICATION_SUPPORT} */
  notificationSupport: NOTIFICATION_SUPPORT.UNKNOWN,
});
