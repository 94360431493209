import pinWarningSvg from 'src/assets/pin-warning.svg?raw';
import { COLOR_VALUES } from 'src/services/constants';
import SvgMarker from './SvgMarker';

export default class WarningSvgMarker extends SvgMarker {
  constructor() {
    super(pinWarningSvg, {
      height: 81,
      width: 60,
      innerColor: COLOR_VALUES.DARK,
      outerColor: COLOR_VALUES.WARNING,
    });
  }
}
