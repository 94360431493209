/* eslint class-methods-use-this:0 */

export default class ClipboardHandler {
  /**
   * @param {String} text
   */
  static copy() {
    throw new Error('copy() method is not implemented!');
  }
}
