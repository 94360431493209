<template>
  <div class="text-negative" @click.stop="triggerNavigation">
    <span data-t="brief" @mouseout="onMouseOut" @mouseover="onMouseOver" @touchstart="onTouch">{{ brief }}</span>
    <q-icon
      class="q-ml-xs"
      data-t="infoIcon"
      name="info"
      size="18px"
      :style="{ marginTop: '-3px' }"
      @mouseout="onMouseOut"
      @mouseover="onMouseOver"
      @touchstart="onTouch"
    />
    <q-popup-proxy
      data-t="popup"
      :model-value="showInfo"
      :offset="[0, 10]"
      transition-hide="fade"
      transition-show="fade"
    >
      <q-banner>
        <template #avatar>
          <q-icon color="negative" name="warning" />
        </template>
        {{ details }}
      </q-banner>
    </q-popup-proxy>
  </div>
</template>

<script>
import Asset from 'src/models/Asset';
import Vehicle from 'src/models/Vehicle';
import {
  LOW_BATTERY_LEVEL_THRESHOLD,
  LOW_BATTERY_THRESHOLD,
  ALERT_TYPES,
  ASSET_TYPE_NAMES,
} from 'src/services/constants';
import { setTimeoutPromise } from 'src/services/setTimeout';

const { DISCONNECTED, NO_DEVICE, LOW_BATTERY, LOW_BATTERY_LEVEL, POSSIBLE_TOW, TROUBLE_CODES } = ALERT_TYPES;

export default {
  props: {
    data: {
      required: true,
      type: Object,
    },
    type: {
      required: true,
      type: String,
    },
    asset: {
      required: true,
      type: [Asset, Vehicle],
    },
  },
  computed: {
    brief() {
      switch (this.type) {
        case DISCONNECTED:
          return 'Device Disconnected';
        case NO_DEVICE:
          return 'No Device';
        case POSSIBLE_TOW:
          return 'Possible Tow Alert';
        case TROUBLE_CODES:
          return 'Check Engine Light On';
        case LOW_BATTERY:
          return 'Low Battery Voltage';
        case LOW_BATTERY_LEVEL:
          return 'Low Battery Level';
        default:
          return null;
      }
    },
    details() {
      switch (this.type) {
        case DISCONNECTED:
          return 'The device associated to this vehicle has been disconnected but is still reporting on battery backup.';
        case NO_DEVICE:
          return `There is no device associated with this ${ASSET_TYPE_NAMES[this.asset.majorType].toLowerCase()}.`;
        case POSSIBLE_TOW:
          return `The last location reported by the vehicle is ${this.data.distance} miles away from where the trip last ended. The vehicle may have been towed, or the device unplugged.`;
        case TROUBLE_CODES:
          return 'The vehicle is reporting an active Diagnostic Code. View the Vehicle Health screen for details.';
        case LOW_BATTERY:
          return `The vehicle is reporting a battery level below ${LOW_BATTERY_THRESHOLD} volts.`;
        case LOW_BATTERY_LEVEL:
          return `The asset is reporting a battery level at or below ${LOW_BATTERY_LEVEL_THRESHOLD}%.`;
        default:
          return null;
      }
    },
  },
  data() {
    return {
      showInfo: false,
    };
  },
  methods: {
    onMouseOut() {
      if (!this.$q.platform.has.touch) {
        this.showInfo = false;
      }
    },
    onMouseOver() {
      if (!this.$q.platform.has.touch) {
        this.showInfo = true;
      }
    },
    async onTouch() {
      await setTimeoutPromise(250);
      switch (this.type) {
        case TROUBLE_CODES:
        case LOW_BATTERY:
        case LOW_BATTERY_LEVEL:
          break;
        default:
          this.showInfo = true;
      }
    },
    triggerNavigation() {
      const { asset, type } = this;
      let navigateTo = null;

      switch (type) {
        case TROUBLE_CODES:
          navigateTo = { name: 'vehicleHealth', params: { key: asset.key }, query: { focus: 'diagnostic' } };
          break;
        case LOW_BATTERY:
          navigateTo = { name: 'vehicleHealth', params: { key: asset.key } };
          break;
        case LOW_BATTERY_LEVEL:
          navigateTo = { name: 'asset', params: { key: asset.key }, query: { focus: 'batteryHistory' } };
          break;
        default:
      }

      if (navigateTo) {
        this.$router.push(navigateTo);
      }
    },
  },
};
</script>
