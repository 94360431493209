<template>
  <q-input
    :bottom-slots="true"
    class="cursor-pointer"
    dense
    :disable="disable"
    filled
    :label="label"
    :model-value="modelValue"
    required
    type="time"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <template v-slot:append>
      <q-icon name="access_time">
        <q-popup-proxy transition-hide="scale" transition-show="scale">
          <q-time :model-value="modelValue" @update:model-value="$emit('update:model-value', $event)">
            <div class="row justify-end">
              <q-btn v-close-popup>Done</q-btn>
            </div>
          </q-time>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script>
export default {
  name: 'TimeInput',
  props: {
    disable: Boolean,
    label: String,
    modelValue: String,
  },
};
</script>
