/* eslint max-classes-per-file:0 */

import _isNil from 'lodash/isNil';
import MaintenanceCategory from 'src/models/MaintenanceCategory';
import MaintenanceReminder from 'src/models/MaintenanceReminder';
import ServiceLog from 'src/models/ServiceLog';
import { dayjs } from 'src/services/date';
import { trackEvent } from 'src/services/intercom';
import { kmToMiles } from 'src/services/locale';
import ImperialUnitLocale from 'src/services/locale/ImperialUnitLocale';

export class ServiceLogError extends Error {
  constructor(errorObj, message = 'An unexpected error occurred when trying to create service log.') {
    super(message);
    this.details = errorObj;
  }
}

/**
 * Creates an account maintenance category.
 *
 * @async
 * @param {ActionContext} context
 * @param {Object} action
 * @param {String} action.description
 * @param {Number} action.interval
 * @param {String} action.intervalType
 * @param {String} action.summary
 * @returns {MaintenanceCategory}
 */
export async function createAccountMaintenanceCategory(context, action) {
  const { description, interval, intervalType, summary } = action;

  const newCategoryData = await context.rootState.app.broker.fetchAndTransform({
    fn: 'createAccountMaintenanceAction',
    params: {
      summary,
      description,
      [`${intervalType}_interval`]: interval,
    },
  });

  if (newCategoryData.error) {
    return newCategoryData;
  }

  const newCategory = new MaintenanceCategory(newCategoryData);
  context.commit('addAccountMaintenanceCategory', newCategory);
  return newCategory;
}

/**
 *
 * @param {ActionContext} context
 * @param {Object} serviceLog
 * @param {String} serviceLog.categoryKey
 * @param {String} serviceLog.cost
 * @param {String} serviceLog.notes
 * @param {Number} serviceLog.odometer
 * @param {String} serviceLog.provider
 * @param {String} serviceLog.serviceDate
 * @param {String} serviceLog.vehicleKey
 * @returns {ServiceLog|ServiceLogError}
 */
export async function createMaintenanceHistory(context, serviceLog) {
  trackEvent('maintenance_history_added');

  const { categoryKey, cost, notes, provider, odometer, odometerUm, serviceDate, vehicleKey } = serviceLog;

  if (serviceLog.categoryKey === 'miscellaneous') {
    // check whether the category needs to be created; you will get a categoryKey of 'miscellaneous' if the category exists in the account but there's no associated car action/reminder/schedule)
    const needsCategory = _isNil(findMiscellaneousCategory(context.state.accountMaintenanceCategories));

    if (needsCategory) {
      await context.dispatch('createAccountMaintenanceCategory', {
        description: 'Unscheduled service',
        interval: 0,
        intervalType: 'miles',
        summary: 'Miscellaneous',
      });
    }

    // add the car action/reminder/schedule
    const { maintenanceActionKey } = findMiscellaneousCategory(context.state.accountMaintenanceCategories);

    const { key: reminderKey } = await context.dispatch('createMaintenanceReminder', {
      categoryKey: maintenanceActionKey,
      interval: 0,
      intervalType: 'miles',
      lastServicedDate: serviceDate,
      lastServicedOdometer: odometer,
      odometerUm,
      summary: 'Miscellaneous',
      vehicleKey,
    });

    await context.dispatch('getMaintenanceHistory', vehicleKey);

    const [{ key: historyKey }] = context.state.maintenanceHistory[vehicleKey];

    // the API automatically creates a history item when a new action is created, so we need to update it instead of creating a new one
    return context.dispatch('updateServiceLog', {
      categoryKey: reminderKey,
      cost,
      key: historyKey,
      notes,
      odometer,
      odometerUm,
      serviceProvider: provider,
      servicedDate: serviceDate,
      vehicleKey,
    });
  }

  const odometerMiles = Math.round(odometerUm === 'km' ? ImperialUnitLocale.kmToMiles(odometer) : odometer);

  const newServiceLogData = await context.rootState.app.broker.fetchAndTransform({
    fn: 'createVehicleMaintenanceHistory',
    params: {
      vehicleKey,
      payload: {
        car_maintenance_action_key: categoryKey,
        serviced_date: serviceDate,
        odometer_miles: odometerMiles,
        service_provider: provider,
        cost,
        currency_code: 'USD',
        notes,
      },
    },
  });

  if (newServiceLogData.error) {
    const { error } = newServiceLogData;
    return new ServiceLogError(error);
  }

  const newServiceLog = new ServiceLog(newServiceLogData);
  context.commit('addMaintenanceHistoryForKey', { vehicleKey, serviceLog: newServiceLog });

  return newServiceLog;
}

function findMiscellaneousCategory(categories) {
  return categories.find(({ milesInterval, summary }) => summary === 'Miscellaneous' && milesInterval === 0);
}

/**
 * Creates a maintenance reminder.
 *
 * @async
 * @param {ActionContext} context
 * @param {Object} reminder
 * @param {String} reminder.categoryKey
 * @param {Number} reminder.interval
 * @param {String} reminder.intervalType
 * @param {String} reminder.lastServicedDate
 * @param {Number} reminder.lastServicedOdometer
 * @param {String} reminder.odometerUm
 * @param {String} reminder.vehicleKey
 * @returns {MaintenanceReminder|Error}
 */
export async function createMaintenanceReminder(context, reminder) {
  trackEvent('maintenance_reminder_added');

  const { vehicleKey } = reminder;
  const newReminder = await context.dispatch('upsertMaintenanceAction', { action: 'create', reminder });

  if (newReminder instanceof Error) {
    return newReminder;
  }

  // Get existing maintenance action keys
  const existingActionKeys = (context.state.maintenanceReminders[vehicleKey] || []).map(
    ({ maintenanceActionKey }) => maintenanceActionKey
  );

  if (existingActionKeys.includes(newReminder.maintenanceActionKey)) {
    // A reminder for a stock category was added, replace it instead of adding
    context.commit('updateMaintenanceReminder', {
      vehicleKey,
      reminder: newReminder,
      byKey: 'maintenanceActionKey',
    });
  } else {
    context.commit('addMaintenanceReminderForKey', { vehicleKey, reminder: newReminder });
  }

  return newReminder;
}

/**
 * Deletes a maintenance category.
 *
 * @async
 * @param {ActionContext} context
 * @param {String} categoryKey
 */
export async function deleteMaintenanceCategory(context, categoryKey) {
  const { error } = await context.rootState.app.apiWorker.deleteAccountMaintenanceAction(categoryKey);

  if (!error) {
    context.commit('deleteAccountMaintenanceCategory', categoryKey);
  }
}

/**
 * Deletes a maintenance reminder for a given vehicle.
 *
 * @async
 * @param {ActionContext} context
 * @param {Object} keys
 * @param {String} keys.vehicleKey
 * @param {String} keys.reminderKey
 */
export async function deleteMaintenanceReminder(context, { vehicleKey, reminderKey }) {
  const { error } = await context.rootState.app.apiWorker.deleteVehicleMaintenanceAction({
    vehicleKey,
    actionKey: reminderKey,
  });

  if (!error) {
    const reminders = context.state.maintenanceReminders[vehicleKey] || [];
    const reminder = reminders.find(({ key }) => key === reminderKey);
    if (reminder && reminder.category !== 'custom') {
      /**
       * A reminder for a stock category was removed, empty out the key instead of removing it
       * - the empty key will signify that the reminder has not been added (which is the default state of stock reminders/categories)
       * - if you don't do this, the only way to get the deleted reminder/category option back is to do another API call
       */
      context.commit('updateMaintenanceReminder', {
        vehicleKey,
        reminder: new MaintenanceReminder({
          ...reminder,
          key: '',
        }),
        byKey: 'maintenanceActionKey',
      });
    } else {
      context.commit('deleteMaintenanceReminderForKey', { vehicleKey, reminderKey });
    }
  }
}

/**
 * Deletes a vehicle maintenance history entry.
 *
 * @async
 * @param {ActionContext} context
 * @param {Object} keys
 * @param {String} keys.vehicleKey
 * @param {String} keys.reminderKey
 */
export async function deleteMaintenanceHistory(context, { vehicleKey, serviceLogKey }) {
  const { error } = await context.rootState.app.apiWorker.deleteVehicleMaintenanceHistory({
    vehicleKey,
    carMaintenanceHistoryKey: serviceLogKey,
  });

  if (!error) {
    context.commit('deleteMaintenanceHistoryForKey', { vehicleKey, serviceLogKey });
  }
}

/**
 * Retrieves account maintenance categories.
 *
 * @async
 * @param {ActionContext} context
 */
export async function getMaintenanceCategories(context) {
  const categories = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getAccountMaintenanceActions',
    transformationFn: 'transformArray',
  });

  if (!categories.error) {
    context.commit(
      'setAccountMaintenanceCategories',
      categories.map((category) => new MaintenanceCategory(category))
    );
  }
}

/**
 * Retrieves maintenance history for a given vehicle key.
 *
 * @async
 * @param {ActionContext} context
 * @param {String} vehicleKey
 */
export async function getMaintenanceHistory(context, vehicleKey) {
  context.commit('setMaintenanceHistoryLoading', true);
  const historyData = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getMaintenanceHistory',
    params: vehicleKey,
    transformationFn: 'transformArray',
  });

  if (!historyData.error) {
    const history = historyData.map((data) => new ServiceLog(data));
    context.commit('setMaintenanceHistoryForKey', { key: vehicleKey, history });
  }

  context.commit('setMaintenanceHistoryLoading', false);
}

/**
 * Retrieves maintenance reminders for a given vehicle.
 *
 * @async
 * @param {ActionContext} context
 * @param {String} vehicleKey
 */
export async function getMaintenanceReminders(context, vehicleKey) {
  if (context.state.maintenanceRemindersLoading === true) {
    return;
  }

  context.commit('setMaintenanceRemindersLoading', true);

  let reminders = [];
  const remindersData = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getVehicleMaintenanceActions',
    params: vehicleKey,
    transformationFn: 'transformArray',
    transformationKey: 'maintenanceActionKey',
  });

  if (!remindersData.error) {
    reminders = remindersData.map((reminder) => {
      const { key, milesInterval, summary } = reminder;
      // act as though the Miscellaneous schedule is always active for all cars
      if (!key && milesInterval === 0 && summary === 'Miscellaneous') {
        reminder.key = 'miscellaneous';
      }
      return new MaintenanceReminder(reminder);
    });

    // add a placeholder Miscellaneous action so people can always log this sort of service
    const hasExistingMaintenanceCategory = reminders.some(
      ({ milesInterval, summary }) => summary === 'Miscellaneous' && milesInterval === 0
    );
    if (!hasExistingMaintenanceCategory) {
      reminders = [
        ...reminders,
        new MaintenanceReminder({
          description: 'Unscheduled service',
          key: 'miscellaneous',
          milesInterval: 0,
          summary: 'Miscellaneous',
        }),
      ];
    }
  }

  if (reminders.length) {
    context.commit('setMaintenanceRemindersForKey', {
      key: vehicleKey,
      reminders,
    });
  }

  context.commit('setMaintenanceRemindersLoading', false);
}

/**
 * Updates a maintenance reminder.
 *
 * @async
 * @param {ActionContext} context
 * @param {Object} reminder
 * @param {String} reminder.categoryKey
 * @param {Number} reminder.interval
 * @param {String} reminder.intervalType
 * @param {String} reminder.lastServicedDate
 * @param {Number} reminder.lastServicedOdometer
 * @param {String} reminder.odometerUm
 * @param {String} reminder.vehicleKey
 * @returns {MaintenanceReminder|Error}
 */
export async function updateMaintenanceReminder(context, reminder) {
  const { vehicleKey } = reminder;
  const newReminder = await context.dispatch('upsertMaintenanceAction', { action: 'update', reminder });

  if (newReminder instanceof Error === false) {
    context.commit('updateMaintenanceReminder', { vehicleKey, reminder: newReminder });
  }

  return newReminder;
}

/**
 * Updates a service log entry.
 *
 * @async
 * @param {ActionContext} context
 * @param {Object} reminder
 * @param {String} reminder.categoryKey
 * @param {String} reminder.cost
 * @param {String} reminder.key
 * @param {Number} reminder.odometer
 * @param {String} reminder.odometerUm
 * @param {String} reminder.serviceProvider
 * @param {String} reminder.servicedDate
 * @param {String} reminder.notes
 * @param {String} reminder.vehicleKey
 * @returns {ServiceLog|Error}
 */
export async function updateServiceLog(context, serviceLog) {
  const {
    categoryKey,
    cost,
    key: serviceLogKey,
    odometer,
    odometerUm,
    serviceProvider,
    servicedDate,
    notes,
    vehicleKey,
  } = serviceLog;

  const odometerMiles = odometerUm === 'km' ? ImperialUnitLocale.kmToMiles(odometer) : odometer;

  const payload = {
    car_maintenance_action_key: categoryKey,
    cost,
    notes,
    odometer_miles: odometerMiles,
    serviced_date: dayjs(servicedDate).format('YYYY-MM-DD'),
    service_provider: serviceProvider,
  };

  const newServiceLogData = await context.rootState.app.broker.fetchAndTransform({
    fn: 'updateVehicleMaintenanceHistory',
    params: {
      vehicleKey,
      serviceLogKey,
      payload,
    },
  });

  if (newServiceLogData.error) {
    const { error } = newServiceLogData;
    return new Error(error);
  }

  const newServiceLog = new ServiceLog(newServiceLogData);

  context.commit('updateServiceLog', { vehicleKey, serviceLog: newServiceLog });

  return newServiceLog;
}

/**
 * Creates/updates the given reminder via the API.
 *
 * @param {ActionContext} context
 * @param {Object} payload
 * @param {String} payload.action
 * @param {Object} payload.reminder
 * @param {String} payload.reminder.categoryKey
 * @param {Number} payload.reminder.interval
 * @param {String} payload.reminder.intervalType
 * @param {String} payload.reminder.lastServicedDate
 * @param {Number} payload.reminder.lastServicedOdometer
 * @param {String} payload.reminder.odometerUm
 * @param {String} payload.reminder.vehicleKey
 * @returns {MaintenanceReminder|Error}
 */
export async function upsertMaintenanceAction(context, { action, reminder }) {
  const { categoryKey, lastServicedDate, lastServicedOdometer, vehicleKey } = reminder;
  let { lastServicedMiles } = reminder;
  let { interval, intervalType } = reminder;

  if (intervalType === 'kilometers') {
    // Convert kilometers to miles
    interval = Math.round(kmToMiles(interval));
  }

  if (!lastServicedMiles) {
    lastServicedMiles = Math.round(
      intervalType === 'kilometers' ? Math.round(kmToMiles(lastServicedOdometer)) : lastServicedOdometer
    );
  }

  const isMilesInterval = intervalType !== 'months';
  const milesInterval = isMilesInterval ? interval : 0;
  const monthsInterval = isMilesInterval ? 0 : interval;

  const payload = {
    last_serviced_date: dayjs(lastServicedDate).format('YYYY-MM-DD'),
    last_serviced_miles: lastServicedMiles,
    miles_interval: milesInterval,
    months_interval: monthsInterval,
  };

  if (action === 'create') {
    payload.maintenance_action_key = categoryKey;
  }

  const params = {
    vehicleKey,
    payload,
  };

  if (action === 'update') {
    params.categoryKey = categoryKey;
  }

  const apiMethod = action === 'create' ? 'createVehicleMaintenanceAction' : 'updateVehicleMaintenanceAction';
  const newReminderData = await context.rootState.app.broker.fetchAndTransform({
    fn: apiMethod,
    params,
  });

  if (newReminderData.error) {
    const { error } = newReminderData;
    return new Error(error);
  }

  return new MaintenanceReminder(newReminderData);
}
