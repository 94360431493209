import GeoJsonLayer from 'src/services/geoJson/GeoJsonLayer';
import NearbyVehiclesSource from 'src/services/geoJson/sources/NearbyVehiclesSource';

export default class NearbyVehiclesLayer extends GeoJsonLayer {
  /** @type {String} */
  static id = 'nearby-vehicles';

  /**
   * @returns {Object} A GeoJson layer config object.
   */
  getConfig() {
    return Object.freeze({
      type: 'symbol',
      id: this.getId(),
      source: NearbyVehiclesSource.id,
      layout: {
        'text-allow-overlap': true,
        'text-anchor': 'top',
        'text-field': '{nickname}',
        'text-font': ['Open Sans SemiBold', 'Arial Unicode MS Regular'],
        'text-offset': [0, 0.2],
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        'icon-image': '{letterId}-pin',
        'icon-offset': [0, -45],
        'icon-size': 0.3,
        'symbol-sort-key': ['get', 'priority'],
      },
      paint: {
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
        'text-color': 'hsl(189, 0%, 37%)',
        'text-opacity': ['case', ['==', ['feature-state', 'highlight'], true], 1, 0],
        'icon-opacity': [
          'case',
          ['==', ['feature-state', 'highlight'], true],
          1,
          ['==', ['feature-state', 'highlight'], false],
          0.2,
          1,
        ],
      },
    });
  }
}
