<template>
  <q-item
    :class="`result-heading q-pa-none q-px-xs min-height-none items-center text-caption bg-${bgColor} text-${textColor}`"
  >
    <q-icon class="q-mr-xs" :name="icon" /> {{ label }}
  </q-item>
</template>

<script>
export default {
  name: 'SearchResultHeading',
  props: {
    bgColor: {
      type: String,
      default: 'secondary',
    },
    icon: String,
    label: String,
    textColor: {
      type: String,
      default: 'dark',
    },
  },
};
</script>
