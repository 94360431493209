import _isEmpty from 'lodash/isEmpty';
import _kebabCase from 'lodash/kebabCase';
import _keyBy from 'lodash/keyBy';
import _sortBy from 'lodash/sortBy';

/**
 * Returns the collection of apps, filtered by apps user isn't denied permission to access and sorted by name.
 *
 * @param {Object} state
 * @param {Object} getters
 * @param {Object} rootState
 * @param {Object} rootGetters
 * @returns {Array}
 */
export function apps(state, getters, rootState, rootGetters) {
  const isNotDeniedPermission = rootGetters['session/isNotDeniedPermission'];
  const publishedApps = state.apps || [];
  const connectedApps = state.connectedApps || [];
  const connectedAppsObj = getters.connectedAppsByKey;

  const visibleApps = [];
  const connectionsApplied = [];

  // Add apps in main list
  publishedApps
    .filter((app) => {
      if (!state.allowDirectConnect) {
        return !isDirectConnectApp(app);
      }
      return true;
    })
    .map((app) => ({
      ...app,
      appNameUrl: _kebabCase(app.name.toLowerCase()),
    }))
    .forEach((app) => {
      const permission = `app_${app.appNameUrl}`;
      if (isNotDeniedPermission(permission)) {
        const connectionDetails = connectedAppsObj[app.appId] || {};
        const isConnected = !_isEmpty(connectionDetails);

        visibleApps.push({
          ...app,
          ...connectionDetails,
          isConnected,
        });

        // Keep track of apps that are included in the main list
        if (isConnected) {
          connectionsApplied.push(app.appId);
        }
      }
    });

  const sortedVisibleApps = _sortBy(visibleApps, 'name');

  // Add connected apps that are not in the main list
  connectedApps
    .filter(({ appId }) => !connectionsApplied.includes(appId))
    .forEach((app) => {
      sortedVisibleApps.push({
        ...app,
        appNameUrl: _kebabCase(app.name.toLowerCase()),
        isConnected: true,
      });
    });

  return sortedVisibleApps;
}

function isDirectConnectApp({ appId = '', name = '' }) {
  const directConnectPatterns = ['directconnect', 'direct connect', 'smartcar', 'smart car'];
  return [appId, name].some((prop) => directConnectPatterns.some((term) => prop.toLowerCase().includes(term)));
}

/**
 * Returns the app that is currently being viewed.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Object}
 */
export function appViewed(state, getters) {
  return getters.apps.find((app) => app.appId === state.appViewed);
}

/**
 * Returns the state\'s connected Apps, keyed by appId.
 *
 * @param {Object} state
 * @return {Object}
 */
export function connectedAppsByKey(state) {
  const { connectedApps = [] } = state;
  return _keyBy(connectedApps, 'appId');
}
