import SimpleModel from './SimpleModel';

class ServiceLog extends SimpleModel {
  props() {
    return {
      carMaintenanceActionKey: null,
      carMaintenanceActionSummary: null,
      cost: null,
      created: null,
      currencyCode: null,
      key: null,
      notes: null,
      odometerMiles: null,
      serviceProvider: null,
      servicedDate: null,
      updated: null,
    };
  }
}

export default ServiceLog;
