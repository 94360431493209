<template>
  <MapboxLayer
    :before="before"
    :clear-source="clearSource"
    :layer="layer"
    :source="source"
    @add="$emit('add', $event)"
    @adding="$emit('adding', $event)"
    @dragging="$emit('dragging', $event)"
    @dragstart="$emit('dragstart', $event)"
    @drop="$emit('drop', $event)"
    @mousedown="grabLocation"
  />
</template>

<script setup lang="ts">
import type { CustomLayerInterface, GeoJSONSourceSpecification, Map, MapEvents } from 'mapbox-gl';
import MapboxLayer from 'components/map/MapboxLayer.vue';

defineProps<{
  before?: string;
  clearSource?: boolean;
  layer: CustomLayerInterface & { source?: string };
  removeSource?: boolean;
  source: GeoJSONSourceSpecification & { data: { id: string } };
}>();

const emit = defineEmits(['add', 'adding', 'dragging', 'dragstart', 'drop']);

function grabLocation({ map, event }: { map: Map; event: MapEvents['mousedown'] }) {
  event.preventDefault();

  const canvas = map.getCanvasContainer();

  canvas.style.cursor = 'grabbing';

  emit('dragstart');

  map.on('mousemove', onMove);

  map.once('mouseup', (mouseupEvent) => {
    const coordinates = mouseupEvent.lngLat;

    canvas.style.cursor = '';

    map.off('mousemove', onMove);
    map.off('touchmove', onMove);

    emit('drop', [coordinates.lng, coordinates.lat]);
  });

  function onMove(moveEvent: MapEvents['mousemove'] | MapEvents['touchmove']) {
    const coordinates = moveEvent.lngLat;

    // Update the Point feature in `geojson` coordinates
    // and call setData to the source layer `point` on it.
    emit('dragging', [coordinates.lng, coordinates.lat]);
  }
}
</script>
