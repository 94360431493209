<template>
  <q-btn-dropdown
    class="q-px-sm map-options-button q-mr-sm q-py-none button"
    color="white"
    dense
    icon="layers"
    size="14px"
    square
    text-color="primary"
  >
    <template v-slot:label>
      <div class="q-ml-sm gt-xs">Map Options</div>
    </template>
    <MapOptions
      :cluster-assets="clusterAssets"
      :map-style="mapStyle"
      :show-hidden-places="showHiddenPlaces"
      :show-places="showPlaces"
      :show-tag-colors="showTagColors"
      :show-traffic="showTraffic"
      @set-map-style="setMapStyle"
      @toggle-cluster-assets="toggleClusterAssets"
      @toggle-places="togglePlaces"
      @toggle-tag-colors="toggleTagColors"
      @toggle-traffic="toggleTraffic"
    />
  </q-btn-dropdown>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MapOptions from 'components/map/MapOptions.vue';

export default {
  components: {
    MapOptions,
  },
  computed: {
    ...mapState('map', ['clusterAssets', 'mapStyle', 'showHiddenPlaces', 'showPlaces', 'showTagColors', 'showTraffic']),
  },
  methods: {
    ...mapActions('map', ['setMapStyle', 'toggleClusterAssets', 'togglePlaces', 'toggleTagColors', 'toggleTraffic']),
  },
};
</script>
