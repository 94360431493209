<template>
  <div />
</template>

<script setup lang="ts">
import mapboxgl from 'mapbox-gl';
import { inject, onBeforeMount, onBeforeUnmount } from 'vue';

const map = <mapboxgl.Map>inject('map');

const fullscreenControl = new mapboxgl.FullscreenControl();

const emit = defineEmits(['added']);

onBeforeMount(() => {
  map.addControl(fullscreenControl);
  emit('added');
});

onBeforeUnmount(() => {
  map.removeControl(fullscreenControl);
});
</script>
