export function selectYear(state, year) {
  state.year = year;
  state.make = null;
  state.model = null;
  state.trim = null;
  state.style = null;
  state.availableMakes = [];
  state.availableModels = [];
  state.availableTrims = [];
  state.availableStyles = [];
}
export function selectMake(state, make) {
  state.make = make;
  state.model = null;
  state.trim = null;
  state.style = null;
  state.availableModels = [];
  state.availableTrims = [];
  state.availableStyles = [];
}

export function selectModel(state, model) {
  state.model = model;
  state.trim = null;
  state.style = null;
  state.availableTrims = [];
  state.availableStyles = [];
}

export function selectTrim(state, trim) {
  state.trim = trim;
  state.style = null;
  state.availableStyles = [];
}

export function selectStyle(state, style) {
  state.style = style;
}

export function setAvailableStyles(state, styles) {
  state.availableStyles = styles;
}

export function setAvailableMakes(state, makes) {
  state.availableMakes = makes;
}

export function setAvailableModels(state, models) {
  state.availableModels = models;
}

export function setAvailableTrims(state, trims) {
  state.availableTrims = trims;
}

export function setLoading(state, loading) {
  state.loading = loading;
}

export function resetSelected(state) {
  state.year = null;
  state.make = null;
  state.model = null;
  state.trim = null;
  state.style = null;
  state.availableMakes = [];
  state.availableModels = [];
  state.availableTrims = [];
  state.availableStyles = [];
}
