import errorService from 'src/services/error';

/**
 * @property {VueRouter} router
 * @property {import('vuex').Store} store
 */
export const config = {
  router: null,
  store: null,
};

/**
 * Sets the router and store to be used by the navigation service.
 *
 * @param {Object} config
 * @param {VueRouter} config.router
 * @param {Vuex.Store} config.store
 */
export function init({ router, store }) {
  config.router = router;
  config.store = store;
}

/**
 * Opens a window to Portal V2 or navigates to a given app route.
 *
 * @param {Object|String} url
 * @returns {Boolean}
 */
export function navigateToUrl(url) {
  config.router.push(url).catch((error) => {
    // Ignore nav duplicated errors
    if (error && error.name !== 'NavigationDuplicated') {
      errorService.log(error);
    }
  });
  return false;
}

/**
 * Returns the current route info.
 *
 * @returns {Object}
 */
export function current() {
  return config.router?.currentRoute || {};
}
