<template>
  <div>
    <div class="column">
      <div
        class="field-container relative row items-center no-wrap"
        @mouseenter="showEditButtons = true"
        @mouseleave="showEditButtons = false"
      >
        <q-tooltip v-if="!readonly && !disable" anchor="top middle" :offset="[0, -10]" self="bottom middle">
          Choose your own image/avatar for this {{ assetTypeLower }}.
        </q-tooltip>
        <div class="row justify-center" :class="{ 'q-mr-md': isTouchOnly }">
          <q-img
            v-if="imageUrl"
            class="image"
            :class="{ 'light-dimmed': !isTouchOnly && showEditButtons && !readonly }"
            data-t="image"
            spinner-color="primary"
            :spinner-size="`${size}px`"
            :src="cloudinaryUrl"
            :style="{ height: `${size}px`, width: `${size}px` }"
          />
          <div
            v-else
            class="image row justify-center items-center"
            :class="{
              'light-dimmed': !isTouchOnly && showEditButtons && !disable && !readonly,
            }"
            :style="{ height: `${size}px`, width: `${size}px` }"
          >
            <VehicleDefaultImage v-if="isVehicleType" :size="size * 0.9" />
            <AssetDefaultImage v-else :size="size * 0.9" />
          </div>
        </div>
        <q-file
          ref="imageInput"
          v-model="newImage"
          class="hidden"
          clearable
          filled
          @update:model-value="onImageUpdate"
        />
        <div
          v-if="!disable && !readonly && (isTouchOnly || showEditButtons)"
          :class="`action-buttons row justify-center items-center full-height z-index-1 ${
            !isTouchOnly ? 'full-width absolute' : ''
          }`"
          style="top: 0; left: 0"
        >
          <div
            class="column justify-center"
            :style="{ margin: '0 auto', marginTop: imageUrl && !isTouchOnly ? '10px' : 0 }"
          >
            <q-btn
              class="button no-btn-wrapper-padding text-no-wrap"
              color="white"
              dense
              label="Choose Image"
              square
              text-color="primary"
              type="button"
              @click="$refs.imageInput.pickFiles()"
            />
            <q-btn
              v-if="imageUrl"
              class="button no-btn-wrapper-padding"
              dense
              flat
              label="Remove"
              square
              text-color="negative"
              type="button"
              @click="
                newImage = null;
                $emit('remove');
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _toLower from 'lodash/toLower';
import { mapActions, mapState } from 'vuex';
import AssetDefaultImage from 'components/assets/AssetDefaultImage.vue';
import VehicleDefaultImage from 'components/vehicle/VehicleDefaultImage.vue';
import { fit } from 'src/services/cloudinary';
import { ASSET_TYPE_NAMES, ASSET_TYPES } from 'src/services/constants';

export default {
  name: 'AssetPicture',
  props: {
    disable: Boolean,
    imageUrl: String,
    readonly: Boolean,
    size: {
      type: Number,
      default: 100,
    },
    type: String,
  },
  components: {
    AssetDefaultImage,
    VehicleDefaultImage,
  },
  computed: {
    ...mapState('env', ['browserSupport']),
    assetType() {
      return ASSET_TYPE_NAMES[this.type];
    },
    assetTypeLower() {
      return _toLower(this.assetType);
    },
    cloudinaryUrl() {
      return fit(this.imageUrl, { width: this.size, height: this.size });
    },
    isTouchOnly() {
      return this.browserSupport.isTouchOnly;
    },
    screenIsXmIsh() {
      return this.$q.screen.width < 450;
    },
    isVehicleType() {
      return this.type === ASSET_TYPES.VEHICLE;
    },
  },
  data() {
    return {
      newImage: null,
      showEditButtons: false,
    };
  },
  methods: {
    ...mapActions('image', ['uploadImage']),
    /** @param {File | undefined} image */
    async onImageUpdate(image) {
      if (image) {
        const response = await this.uploadImage({ data: image });
        this.$emit('update', response.secureUrl);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$breakpoint-xm-ish: 450px;

.light-dimmed:after {
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9) !important;
}

.field-container {
  justify-content: center;
}

.image {
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.action-buttons {
  margin: map-get($space-sm, 'y') 0 0;

  @media (min-width: $breakpoint-xm-ish) {
    margin: 0;
  }
}

.button {
  padding: 5px 10px;

  @media (min-width: $breakpoint-xm-ish) {
    padding: 0 5px;
  }
}
</style>
