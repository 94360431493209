import GeoJsonLayer from 'src/services/geoJson/GeoJsonLayer';
import NearbyIsoSource from 'src/services/geoJson/sources/NearbyIsoSource';

export default class NearbyIsoOutlineLayer extends GeoJsonLayer {
  /** @type {String} */
  static id = 'nearby-iso-outline';

  /**
   * @returns {Object} A GeoJson layer config object.
   */
  getConfig() {
    return Object.freeze({
      id: this.getId(),
      type: 'line',
      source: NearbyIsoSource.id,
      paint: {
        'line-color': { type: 'identity', property: 'outlineColor' },
        'line-width': 1,
      },
    });
  }
}
