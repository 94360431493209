import { Notify } from 'quasar';
import { SHARE_KEYS_DELIMITER, SHARE_TYPES } from 'src/services/constants';
import { dayjs } from 'src/services/date';
import { trackEvent } from 'src/services/intercom';
import Transformer from 'src/transformers/Transformer';

const SHARE_TYPE_TO_INTERCOM_EVENT_MAP = Object.freeze({
  'location-share': 'vehicle_location_shared',
  'recovery-mode': 'vehicle_recovery_mode_enabled',
  'paid-repossession': 'vehicle_paid_repossession_requested',
});

/**
 * @param {import('src/types/shares-store').SharesStoreActionContext} context
 */
export async function getAccountShares(context) {
  if (!context.rootState.session.account.id) {
    return;
  }
  context.dispatch('getShares', { type: 'account', id: context.rootState.session.account.id });
}

/**
 * @param {import('src/types/shares-store').SharesStoreActionContext} context
 * @param {string} id
 */
export async function getAssetShares(context, id) {
  context.dispatch('getShares', { type: 'asset', id });
}

/**
 * @param {import('src/types/shares-store').SharesStoreActionContext} context
 * @param {string} id
 */
export async function getVehicleShares(context, id) {
  context.dispatch('getShares', { type: 'vehicle', id });
}

/**
 * @param {import('src/types/shares-store').SharesStoreActionContext} context
 * @param {{ id: string; type: 'account' | 'vehicle' | 'asset' }} payload
 */
export async function getShares(context, { id, type }) {
  context.commit('setSharesLoading', true);

  /** @type {{ shares: import('src/types/shares-store').ApiLocationShare[] }} */
  const shareData = await context.rootState.app.broker.getLocationShares({
    id,
    type,
    limit: 999,
  });

  context.commit(
    'insertShares',
    shareData.map((share) => {
      const { assetKey = '', expirationTimestamp, vehicleKey = '' } = share;

      delete share.assetKey;
      delete share.vehicleKey;

      return {
        ...share,
        doesNotExpire: dayjs().add(50, 'years').valueOf() < expirationTimestamp,
        assetKeys: assetKey.split(SHARE_KEYS_DELIMITER).filter((key) => key),
        vehicleKeys: vehicleKey.split(SHARE_KEYS_DELIMITER).filter((key) => key),
      };
    })
  );

  context.commit('setSharesLoading', false);
}

/**
 * @param {import('src/types/shares-store').SharesStoreActionContext} context
 * @param {import('src/types/shares-store').LocationShare} share
 */
export async function saveShare(context, share) {
  const {
    assetKeys = [],
    doesNotExpire,
    expirationTimestamp,
    hideParkedVehicles,
    id,
    image,
    message,
    source,
    vehicleKeys = [],
  } = share;

  const body = {
    id,
    assetKey: assetKeys.join(SHARE_KEYS_DELIMITER),
    vehicleKey: vehicleKeys.join(SHARE_KEYS_DELIMITER),
    expirationTimestamp: doesNotExpire ? dayjs().add(100, 'years').valueOf() : expirationTimestamp,
    hideParkedVehicles,
    image,
    message,
    source: source || SHARE_TYPES.DEFAULT,
  };

  let fn = 'createLocationShare';
  if (id) {
    // Existing share modified
    trackEvent('vehicle_location_share_updated');
    fn = 'updateLocationShare';
  } else {
    // New share
    const intercomEvent = SHARE_TYPE_TO_INTERCOM_EVENT_MAP[body.source];
    if (intercomEvent) {
      trackEvent(intercomEvent);
    }
  }

  const data = Transformer.snakeCaseKeysDeep(body);

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn,
    params: data,
    transformationFn: 'camelCaseKeysDeep',
  });

  const error = response?.metadata?.body || '';
  if (error) {
    Notify.create({
      icon: 'error',
      color: 'negative',
      message: error,
    });
  } else {
    // TODO: maybe not get all of the shares?
    context.dispatch('getAccountShares');
  }

  return response;
}

/**
 * Immediately ends a location share.
 *
 * @param {import('src/types/shares-store').SharesStoreActionContext} context
 * @param {{ id: string; vehicleKeys: string[]; assetKeys: string[] }} payload
 */
export async function stopShare(context, { id, vehicleKeys = [], assetKeys = [] }) {
  const response = await context.rootState.app.broker.stopLocationShare({ id, vehicleKeys, assetKeys });

  const error = response?.metadata?.body || '';
  if (error) {
    Notify.create({
      icon: 'error',
      color: 'negative',
      message: error,
    });
  } else {
    context.commit('removeShare', id);
    Notify.create({
      color: 'positive',
      icon: 'location_off',
      message: `Location share stopped`,
    });
  }

  return response;
}
