import _cloneDeep from 'lodash/cloneDeep';
import errorService from 'src/services/error';
import storage from 'src/services/storage';

export default () => {
  let filters = {
    /** @type {string[]} */
    assetType: [],
    /** @type {string[]} */
    group: [],
    /** @type {string[]} */
    health: [],
    /** @type {string[]} */
    status: [],
    /** @type {string[]} */
    tag: [],
  };

  storage.onReady(async (store) => {
    // Wait for IDB to be ready before applying saved filter settings
    await store.state.app.onIdbReady;

    const filtersString = storage.get('filtering/filters');

    let savedFilters = {};
    if (filtersString) {
      try {
        savedFilters = JSON.parse(filtersString);
      } catch {
        errorService.log(new Error(`Failed to load cached map filters with value: ${filtersString}`));
      }
    }

    let filterShared = storage.get('filtering/filterShared');
    if (filterShared === 'true') {
      filterShared = true;
    } else if (filterShared === 'false') {
      filterShared = false;
    } else {
      filterShared = null;
    }

    filters = {
      ...filters,
      ...savedFilters,
    };

    // Initialize live map filtering
    store.dispatch('filtering/initFilters', {
      filters,
      filterShared,
    });

    if (storage.get('filtering/filterOutOfBounds') === 'true') {
      // Only update if "true"
      store.dispatch('filtering/setOutOfBoundsFilter', true);
    }

    store.commit('filtering/setTrackedFilters', filters);
  });

  return {
    filteringOccurred: false,
    /** @type {boolean} */
    filterOutOfBounds: false, // default to false
    /** @type {boolean | null} */
    filterShared: null,
    /** @type {typeof filters} */
    filters: _cloneDeep(filters),
    filtersInitialized: false,
    filtersTracked: _cloneDeep(filters),
    /** @type {string[]} */
    searchTerms: [],
  };
};
