import { toRaw } from 'vue';
import { healthStatusValueToLabel, statusValueToLabel } from 'src/services/constants';
import errorService from 'src/services/error';
import { trackEvent } from 'src/services/intercom';
import storage from 'src/services/storage';

/**
 * Clears out the group, health, status, and tag filters.
 *
 * @param {ActionContext} context
 */
export function clearFilters(context) {
  context.dispatch('updateFilters', {
    filters: {
      assetType: [],
      group: [],
      health: [],
      status: [],
      tag: [],
    },
  });
  context.dispatch('setSharedFilter', false);
}

/**
 * Sets the filters initialized flag (to true).
 *
 * @param {ActionContext} context
 */
export function filtersInitialized(context) {
  context.commit('filtersInitialized');
}

/**
 * Initializes filters with the backend.
 *
 * @param {ActionContext} context
 */
export function initFilters(context, { filters, filterShared }) {
  context.dispatch('updateFilters', { filters, init: true });
  context.dispatch('setSharedFilter', filterShared);
}

/**
 * Sets the initial out-of-bounds filter on/off flag.
 *
 * @param {ActionContext} context
 * @param {Boolean} filterOutOfBounds
 */
export async function setDefaultOutOfBoundsFilter(context, filterOutOfBounds) {
  // Only set default out of bounds if it is null (nothing set as a preference)
  if (context.state.filterOutOfBounds === null) {
    context.commit('setOutOfBoundsFilter', filterOutOfBounds);
  } else {
    // Update the DataBroker with the user preference
    await context.rootState.app.broker.setOutOfBoundsFilter(context.state.filterOutOfBounds);
  }
}

/**
 * Sets the term to filter by.
 *
 * @param {ActionContext} context
 * @param {String} term
 */
export async function setFilteredTerms(context, term) {
  context.commit('setSearchTerms', term);
}

/**
 * Turns out-of-bounds filtering on/off.
 *
 * @param {ActionContext} context
 * @param {Boolean} filterOutOfBounds
 */
export async function setOutOfBoundsFilter(context, filterOutOfBounds) {
  try {
    await context.rootState.app.broker.setOutOfBoundsFilter(filterOutOfBounds);
    context.commit('setOutOfBoundsFilter', filterOutOfBounds);
    storage.set('filtering/filterOutOfBounds', filterOutOfBounds);
  } catch (error) {
    errorService.notifyUnexpected(error, {
      brokerCall: {
        method: 'setOutOfBoundsFilter',
        payload: filterOutOfBounds,
      },
    });
  }
}

/**
 * Turns shared/recovery filtering on/off.
 *
 * @param {ActionContext} context
 * @param {Boolean} filterShared
 */
export async function setSharedFilter(context, filterShared) {
  try {
    await context.rootState.app.broker.setSharedFilter(filterShared);
    context.commit('setSharedFilter', filterShared);
    storage.set('filtering/filterShared', filterShared);
  } catch (error) {
    errorService.notifyUnexpected(error, {
      brokerCall: {
        method: 'setSharedFilter',
        payload: filterShared,
      },
    });
  }
}

/**
 * Updates the filters using the given filters collection.
 *
 * @param {ActionContext} context
 * @param {Object} filters
 */
export function updateFilters(context, { filters, init = false }) {
  const { assetType = [], group = [], health = [], status = [], tag = [] } = filters || {};

  context.commit('setFilters', filters);
  context.commit('filteringOccurred');

  context.rootState.app.broker.updateFilters({
    filters: {
      assetType: toRaw(assetType),
      group: toRaw(group),
      health: toRaw(health),
      status: toRaw(status),
      tag: toRaw(tag),
    },
    init,
  });
}

/**
 * Filters by the given search term.
 *
 * @param {ActionContext} context
 * @param {String} term
 */
export async function filterByTerm(context, term) {
  context.commit('filteringOccurred');
  context.rootState.app.broker.filterVehiclesByTerm(term);
}

/**
 * Resets all filters (except out-of-bounds), search terms, and resets map bounds.
 *
 * @param {ActionContext} context
 */
export async function resetAll(context) {
  context.dispatch('clearFilters');
  context.dispatch('filterByTerm', '');
  context.dispatch('setOutOfBoundsFilter', false);
  // This will tell app to fit-to-ideal bounds with the next ideal bounds update
  context.commit('map/setPendingFitToIdeal', true, { root: true });
}

/**
 * Tracks filters in Intercom if those filters have been added to since
 * the last filter change.
 *
 * @param {ActionContext} context
 */
export function trackFilterChange(context) {
  const { filters, filtersTracked } = context.state;

  // // Check location filters
  if (filters.status.length > filtersTracked.status.length) {
    trackEvent('location_filter_changed', {
      selections: filters.status.map((value) => statusValueToLabel[value]).join(','),
    });
  }

  // Check health filters
  if (filters.health.length > filtersTracked.health.length) {
    trackEvent('health_filter_changed', {
      selections: filters.health.map((value) => healthStatusValueToLabel[value]).join(','),
    });
  }

  context.commit('setTrackedFilters', filters);
}
