import _isArray from 'lodash/isArray';
import _sortBy from 'lodash/sortBy';
import { isProxy, toRaw } from 'vue';
import store from 'src/store';

/**
 * Retrieves keywords for the given key from the proper store using its type.
 *
 * @param {string} key
 * @param {SearchResultType} type
 * @returns {SearchKeyWords | null}
 */
export function getKeywordsByKey(key, type) {
  const typeToStore = {
    asset: store().state.assets.assets,
    place: store().state.places.places,
    user: store().state.users.users,
    vehicle: store().state.vehicles.vehicles,
  };

  let data = typeToStore[type][key];
  if (data) {
    // Clean data
    let cleanData = {};
    Object.keys(data).forEach((property) => {
      if (_isArray(data[property])) {
        cleanData[property] = data[property].map((item) => toRaw(item));
      } else if (isProxy(data[property])) {
        cleanData[property] = toRaw(data[property]);
      } else {
        cleanData[property] = data[property];
      }
    });

    return store().state.app.broker.getKeywordsByType({ type, data: cleanData });
  }

  return null;
}

/**
 * Sorts the given results by priority.
 *
 * @param {SearchItem[]} results
 * @param {SearchResultType} priority
 */
export function sortResults(results, priority) {
  let sortOrder = ['vehicle', 'asset', 'place', 'user'];

  if (priority) {
    // Reorder based on priority
    const priorityType = sortOrder.splice(sortOrder.indexOf(priority), 1);
    sortOrder = [...priorityType, ...sortOrder];
  }

  // Assign priority to each result (based on sort order index)
  const resultsWithPriority = [...results].map((item) => ({
    ...item,
    priority: sortOrder.indexOf(item.type),
  }));

  return _sortBy(resultsWithPriority, 'priority');
}
