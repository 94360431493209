import storage from 'src/services/storage';

/**
 * @param {MapStoreState} state
 */
export function clearSearchResults(state) {
  state.searchResults = [];
}

export function fittingTo(state, bounds) {
  state.fittingTo = bounds;
}

export function lockToIdealBounds(state, lock = true) {
  state.lockToIdealBounds = lock;
}

export function mapStyle(state, style) {
  state.mapStyle = style;
  storage.set('map/mapStyle', style);
}

export function matchesIdealBounds(state, matches = true) {
  state.matchesIdealBounds = matches;
}

export function reserveOnLoadFocus(state, id) {
  state.reservingOnLoadFocus = id;
}

export function setSelectedDrawerShouldUpdate(state, shouldUpdate) {
  state.selectedDrawerShouldUpdate = shouldUpdate;
}

export function setClusterableAssetsGeoJson(state, featuresByKey) {
  state.clusterableAssetsGeoJson = Object.freeze({ ...featuresByKey });
}

export function setClusteredBounds(state, clusteredBounds) {
  state.clusteredBounds = clusteredBounds;
}

export function setClusterEnabled(state, clusters) {
  state.clusterAssets = clusters;
  storage.set('map/clusterAssets', clusters);
}

export function setContextualMenuOpen(state, isOpen) {
  state.contextualMenuOpen = isOpen;
}

export function setIdealBounds(state, idealBounds) {
  state.idealBounds = idealBounds;
}

export function setInViewportFencesHash(state, hash) {
  state.inViewportFencesHash = hash;
}

export function setLegendOpened(state, isOpen) {
  state.legendOpened = isOpen;
  storage.set('map/legendOpened', isOpen);
}

export function setListDialogOpen(state, isOpen) {
  state.listDialogOpen = isOpen;
}

export function setPendingFitToIdeal(state, pendingFitToIdeal) {
  state.pendingFitToIdeal = pendingFitToIdeal;
}

export function setSelectedAssetGeoJson(state, featuresByKey) {
  state.selectedAssetGeoJson = Object.freeze({ ...featuresByKey });
}

/**
 * @param {MapStoreState} state
 * @param {number} index
 */
export function setSelectedSearchResultIndex(state, index) {
  state.selectedSearchResultIndex = index;
}

/**
 * @param {MapStoreState} state
 * @param {string} terms
 */
export function setSearchTerms(state, terms) {
  state.searchTerms = terms;
}

export function setShowDriverNameInList(state, show) {
  storage.set('map/showDriverNameInList', show);
  state.showDriverNameInList = show;
}

export function setShowNicknameInList(state, show) {
  storage.set('map/showNicknameInList', show);
  state.showNicknameInList = show;
}

export function setShowAddressInList(state, show) {
  storage.set('map/showAddressInList', show);
  state.showAddressInList = show;
}

export function setNearbyMapStyle(state, style) {
  state.nearbyMapStyle = style;
  storage.set('map/nearbyMapStyle', style);
}

export function setNearbyVehiclesFiltered(state, isFiltered) {
  state.nearbyVehiclesFiltered = isFiltered;
}

/**
 * @param {MapStoreState} state
 * @param {FormattedAddressObject[]} results
 */
export function setSearchResults(state, results) {
  state.searchResults = results;
}

/**
 * @param {MapStoreState} state
 * @param {boolean} isLoading
 */
export function setSearchResultsLoading(state, isLoading) {
  state.searchResultsLoading = Boolean(isLoading);
}

export function setTripsMapStyle(state, style) {
  state.tripsMapStyle = style;
  storage.set('map/tripsMapStyle', style);
}

export function setZoomLevel(state, zoomLevel) {
  state.zoomLevel = zoomLevel;
}

export function showAddPlace(state, show) {
  state.showAddPlace = show;
}

export function showPlaces(state, show) {
  state.showPlaces = show;
  storage.set('map/showPlaces', show);
}

export function showTagColors(state, show) {
  state.showTagColors = show;
  storage.set('map/showTagColors', show);
}

export function showTraffic(state, show) {
  state.showTraffic = show;
  storage.set('map/showTraffic', show);
}

export function updateInProgress(state, inProgress) {
  state.updateInProgress = inProgress;
}

export function setAssetRecentMapStyle(state, style) {
  state.assetRecentMapStyle = style;
  storage.set('map/assetRecentMapStyle', style);
}

export function setLiveMapInitialized(state, initialized) {
  state.liveMapInitialized = initialized;
}

export function setPlacesLayerReady(state, isReady) {
  state.placesLayerReady = isReady;
}

export function setTripEndDetailsMinimized(state, isMinimized) {
  state.tripEndDetailsMinimized = isMinimized;
  storage.set('map/trip-end-details-minimized', isMinimized);
}

export function setTripEndHasMedia(state, hasMedia) {
  state.tripEndHasMedia = hasMedia;
}
