<template>
  <div>
    <q-select
      v-model="schedule"
      v-capacitor-native-select
      display-value-sanitize
      emit-value
      filled
      :for="forId"
      :hide-dropdown-icon="readonly"
      label="Permitted Driving Hours"
      map-options
      optionLabel="label"
      :options="scheduleOptions"
      options-sanitize
      optionValue="value"
      :readonly="readonly"
      @update:model-value="onScheduleSelect"
    />
    <NewScheduleDialog
      v-if="showNewScheduleDialog"
      @cancel="onCancel"
      @hide="showNewScheduleDialog = false"
      @update:model-value="$emit('update:model-value', $event)"
    />
  </div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import { mapActions, mapGetters, mapState } from 'vuex';
import NewScheduleDialog from 'components/vehicles-page/NewScheduleDialog.vue';

export default {
  props: {
    forId: {
      required: true,
      type: String,
    },
    modelValue: {
      type: String,
      default: '',
    },
    readonly: Boolean,
  },
  components: {
    NewScheduleDialog,
  },
  emits: ['update:model-value'],
  computed: {
    ...mapGetters('session', ['hasPermission']),
    ...mapState('vehicles', ['schedules']),
    isAddNewSelected() {
      return this.schedule === 'Add a new schedule';
    },
    scheduleOptions() {
      const schedules = [
        {
          label: 'No schedule',
          value: '',
        },
      ];

      _sortBy(this.schedules, 'name').forEach(({ name, key }) => {
        schedules.push({
          label: name,
          value: key,
        });
      });

      if (this.hasPermission('vehicleScheduleCreate')) {
        schedules.push({
          label: 'Add a new schedule',
          value: 'Add a new schedule',
        });
      }

      return schedules;
    },
  },
  data() {
    return {
      schedule: null,
      showNewScheduleDialog: false,
    };
  },
  methods: {
    ...mapActions('vehicles', ['getSchedules']),
    onCancel() {
      if (this.isAddNewSelected) {
        this.setValue();
      }
    },
    onScheduleSelect() {
      if (this.isAddNewSelected) {
        this.showNewScheduleDialog = true;
      } else {
        this.$emit('update:model-value', this.schedule);
      }
    },
    setValue() {
      this.schedule = this.modelValue;
    },
  },
  async mounted() {
    if (_isEmpty(this.schedules)) {
      await this.getSchedules();
    }
    this.setValue();
  },
  watch: {
    modelValue() {
      this.setValue();
    },
  },
};
</script>
