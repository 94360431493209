<template>
  <q-toolbar class="map-toolbar q-pl-xs bg-secondary relative-position z-index-4 shadow-4">
    <div class="map-filters row items-center no-wrap" :class="{ 'filters-active': filtersActive }">
      <!-- <div class="q-mr-sm text-subtitle1 text-uppercase">Filters:</div> -->
      <div class="relative">
        <MapFiltersButton ref="mapFiltersButton" class="map-filters-button" />
        <FiltersBadge />
      </div>
      <MapFiltersActive v-if="filtersActive" class="q-ml-sm" @show-all="show" />
    </div>
    <q-toolbar-title class="row items-center content-stretch"></q-toolbar-title>
    <MapOptionsButton class="map-options-button" />
  </q-toolbar>
</template>

<script>
import { mapGetters } from 'vuex';
import FiltersBadge from 'components/map/FiltersBadge.vue';
import MapFiltersActive from 'components/map/MapFiltersActive.vue';
import MapFiltersButton from 'components/map/MapFiltersButton.vue';
import MapOptionsButton from 'components/map/MapOptionsButton.vue';

export default {
  components: {
    FiltersBadge,
    MapFiltersActive,
    MapFiltersButton,
    MapOptionsButton,
  },
  data() {
    return {
      mobileShown: false,
    };
  },
  computed: {
    ...mapGetters('filtering', ['filtersActive']),
  },
  methods: {
    show(event) {
      this.$refs.mapFiltersButton.show(event);
    },
    toggleMobileSearch() {
      this.mobileShown = !this.mobileShown;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-filters {
  padding: 5px 8px 5px;
  border: 1px solid $secondary;
  transition: border-color 0.5s ease-in;
  transition: background 0.5s ease-in;
  background: $secondary;

  &.filters-active {
    padding: 2px 2px 2px 8px;
    border-color: $dark;
    background: $info;
  }
}

.ios-notch-right .map-toolbar {
  padding-right: env(safe-area-inset-right);
}

.ios-notch-left .map-filters-button {
  margin-left: calc(env(safe-area-inset-left) - 10px);
}

.search-button {
  padding: 3px;
}

:deep(.q-btn-dropdown .q-btn__wrapper) {
  min-height: 0;
  padding: 1px 0;
}
</style>
