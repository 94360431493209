<template>
  <div class="alert-icons no-wrap column items-center" :class="{ multiple: alertIconsCount > 1 }">
    <q-icon
      v-if="alerts.length > 0"
      aria-label="Vehicle has alerts"
      class="alert-icon"
      color="negative"
      data-t="alertIcon"
      name="warning"
      size="18px"
    />
    <q-icon
      v-if="maintenanceDue"
      aria-label="Maintenance due"
      class="alert-icon q-ml-xs"
      :class="{ 'q-mt-xs': alertIconsCount === 2 && isVehicle && showDriver }"
      color="negative"
      data-t="maintenanceIcon"
      name="build"
      size="18px"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type AssetAlert from 'src/services/assetAlerts/AssetAlert';

const props = defineProps<{ alerts: AssetAlert[]; isVehicle: boolean; maintenanceDue: boolean; showDriver: boolean }>();

const alertIconsCount = computed(() => {
  let count = 0;
  if (props.alerts.length > 0) {
    count += 1;
  }
  if (props.maintenanceDue) {
    count += 1;
  }
  return count;
});
</script>
