import { userRoles } from 'src/services/constants';
import SimpleModel from './SimpleModel';

class CurrentUser extends SimpleModel {
  /** @type {string | null} */
  accountId = null;

  /** @type {string | null} */
  accountKey = null;

  /** @type {string | null} */
  accountRole = null;

  /** @type {string | null} */
  created = null;

  /** @type {string | null} */
  email = null;

  /** @type {string | null} */
  firstName = null;

  /** @type {{ key: string }[] | null} */
  groups = [];

  /** @type {string | null} */
  key = null;

  /** @type {string | null} */
  lastName = null;

  /** @type {string | null} */
  preferredLocale = null;

  constructor(data = {}) {
    super();
    this.insert(data);
  }

  isAllowedToLogin() {
    return this.accountRole && (this.isAdmin() || this.isViewer() || this.isIndividual());
  }

  isAdmin() {
    return this.accountRole === userRoles.ADMIN;
  }

  isViewer() {
    return this.accountRole === userRoles.VIEWER;
  }

  isIndividual() {
    return this.accountRole === userRoles.INDIVIDUAL;
  }

  isDriverWithAccess() {
    return this.isIndividual();
  }
}

export default CurrentUser;
