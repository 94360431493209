<template>
  <q-item class="q-py-none">
    <q-item-section>
      <div class="flex items-center no-wrap text-no-wrap" data-t="label">
        <q-img v-if="icon" class="inline-block q-mr-md" data-t="icon" :src="icon" width="40px" />
        <slot>{{ label }}</slot>
      </div>
    </q-item-section>
    <q-item-section side>
      <div class="row items-center no-wrap">
        <q-toggle
          v-model="expectedValue"
          checked-icon="check"
          color="primary"
          data-t="toggle"
          unchecked-icon="clear"
          @update:model-value="$emit('update:model-value')"
        />
      </div>
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  props: {
    debounce: Boolean,
    icon: String,
    label: String,
    modelValue: Boolean,
    subToggle: Boolean,
  },
  data() {
    return {
      expectedValue: false,
    };
  },
  created() {
    this.expectedValue = this.modelValue;
  },
  watch: {
    modelValue() {
      this.expectedValue = this.modelValue;
    },
  },
};
</script>
