<template>
  <ZubieDialog
    :action-button-text="actionButtonText"
    content-class="min-width:300px"
    :processing="saveInProgress"
    :title="`${assetTypeKey ? `Edit ${assetType.name} Asset Type` : 'Add New Asset Type'}`"
    @hide="$emit('hide')"
    @submit="onSubmit"
  >
    <q-input
      ref="nameInput"
      v-model="name"
      :disable="saveInProgress"
      :error="!isValidName"
      error-message="Please specify a name."
      filled
      hide-bottom-space
      label="Name"
      @update:model-value="isValidName = true"
    />
    <IconChooser
      class="q-mt-md"
      :defaultIcon="defaultIcon"
      :icons="icons"
      :selected="iconUri"
      @select="iconUri = $event"
    />
    <q-banner v-if="error" class="q-mt-md text-white bg-negative">{{ error }}</q-banner>
  </ZubieDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import IconChooser from 'components/IconChooser.vue';
import ErrorPayload from 'src/models/ErrorPayload';
import { ASSET_ICONS, ASSET_TYPES, ASSET_ICONS_SET } from 'src/services/constants';

export default {
  props: {
    assetTypeKey: String,
  },
  components: {
    IconChooser,
  },
  emits: ['hide', 'update:model-value'],
  computed: {
    ...mapState('assets', ['assetTypes']),
    actionButtonText() {
      if (this.assetTypeKey) {
        return this.saveInProgress ? 'Saving' : 'Save';
      }
      return this.saveInProgress ? 'Adding' : 'Add';
    },
    assetType() {
      return this.assetTypes[this.assetTypeKey];
    },
    defaultIcon() {
      return ASSET_ICONS[ASSET_TYPES.ASSET];
    },
    icons() {
      return ASSET_ICONS_SET;
    },
  },
  data() {
    return {
      error: null,
      iconUri: '',
      isValidName: true,
      name: null,
      saveInProgress: false,
    };
  },
  methods: {
    ...mapActions('assets', ['saveAssetType']),
    async onSubmit(event) {
      let isValid = true;

      this.saveInProgress = true;

      // Validation
      if (!this.name) {
        isValid = false;
        this.isValidName = false;
        this.$refs.nameInput.focus();
      }

      let assetTypeResult;
      if (isValid) {
        assetTypeResult = await this.saveAssetType({
          key: this.assetTypeKey,
          name: this.name,
          iconUri: this.iconUri,
        });

        if (assetTypeResult instanceof ErrorPayload) {
          this.error = assetTypeResult.error;
          isValid = false;
        }
      }

      this.saveInProgress = false;

      if (isValid) {
        this.$q.notify({
          color: 'positive',
          icon: 'check_circle',
          message: `Successfully ${this.assetTypeKey ? 'updated' : 'created new asset type'} new asset type: ${
            assetTypeResult.name
          }`,
        });
        this.$emit('update:model-value', event, assetTypeResult);
        this.$emit('hide');
      }
    },
  },
  created() {
    if (this.assetTypeKey) {
      this.iconUri = this.assetType.iconUri;
      this.name = this.assetType.name;
    }
  },
};
</script>

<style lang="scss">
.q-field--floating-bottom .q-field__bottom {
  position: absolute;
  top: 100%;
}
</style>
