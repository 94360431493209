<template>
  <div class="relative" :style="{ height: `${height}px` }">
    <ZubieMap
      ref="map"
      :center="coordinates"
      :map-images="mapImages"
      :map-style="nearbyMapStyle"
      @load="onMapLoad"
      @set-map-style="setNearbyMapStyle"
    >
      <MapboxLayer
        :clear-source="false"
        :layer="vehiclesLayer"
        :source="vehiclesSource"
        @mouseout="onVehicleHover"
        @mouseover="onVehicleHover"
      />

      <MapboxLayer
        v-if="showIsochrones"
        ref="isoLayer"
        :before="vehiclesLayer.id"
        :clear-source="false"
        :layer="isoLayer"
        :source="isoSource"
      />

      <MapboxLayer
        v-if="showIsochrones"
        ref="isoOutlineLayer"
        :before="vehiclesLayer.id"
        :clear-source="false"
        :layer="isoOutlineLayer"
        :source="isoSource"
      />

      <DraggableGeoJsonLayer
        ref="locationLayer"
        :layer="locationLayer"
        :source="locationSource"
        @add="draggableLayerAdded = true"
        @adding="draggableLayerAdded = false"
        @dragging="locationCoordinates = $event"
        @dragstart="showIsochrones = false"
        @drop="
          showIsochrones = true;
          $emit('location-change', $event);
        "
      />

      <MapLegend :symbols="mapLegendSymbols" />
      <MapError v-if="Boolean(error)" message="Could not determine any reachable areas around the given location." />
    </ZubieMap>
    <div
      v-if="isLoading"
      class="absolute row justify-center items-center full-width z-index-2"
      :style="{
        height: `${height}px`,
        top: 0,
        background: 'rgba(255,255,255,0.9)',
      }"
    >
      <q-spinner-ball color="primary" size="5em" />
    </div>
  </div>
</template>

<script>
import _isEqual from 'lodash/isEqual';
import { mapActions, mapState } from 'vuex';

import DraggableGeoJsonLayer from 'components/map/DraggableGeoJsonLayer.vue';
import MapboxLayer from 'components/map/MapboxLayer.vue';
import MapError from 'components/map/MapError.vue';
import MapLegend from 'components/map/MapLegend.vue';
import ZubieMap from 'components/map/ZubieMap.vue';
import APinSvg from 'src/assets/a-pin.svg?raw';
import BPinSvg from 'src/assets/b-pin.svg?raw';
import CPinSvg from 'src/assets/c-pin.svg?raw';
import DPinSvg from 'src/assets/d-pin.svg?raw';
import EPinSvg from 'src/assets/e-pin.svg?raw';
import PlaceSvg from 'src/assets/place.svg?raw';
import GeoJsonFeature from 'src/models/GeoJsonFeature';
import MapImage from 'src/models/MapImage';
import { CONTOUR_COLORS, CONTOUR_LEGEND_OPACITY, CONTOUR_OUTLINE_COLORS, CONTOURS } from 'src/services/constants';
import NearbyIsoLayer from 'src/services/geoJson/layers/NearbyIsoLayer';
import NearbyIsoOutlineLayer from 'src/services/geoJson/layers/NearbyIsoOutlineLayer';
import NearbyLocationLayer from 'src/services/geoJson/layers/NearbyLocationLayer';
import NearbyVehiclesLayer from 'src/services/geoJson/layers/NearbyVehiclesLayer';

import NearbyIsoSource from 'src/services/geoJson/sources/NearbyIsoSource';
import NearbyLocationSource from 'src/services/geoJson/sources/NearbyLocationSource';
import NearbyVehiclesSource from 'src/services/geoJson/sources/NearbyVehiclesSource';




export default {
  name: 'NearbyVehiclesMap',
  props: {
    address: String,
    bounds: Array,
    coordinates: Array,
    error: String,
    height: Number,
    highlightedVehicle: String,
    isLoading: Boolean,
    iso: Array,
    vehiclesList: Array,
  },
  components: {
    DraggableGeoJsonLayer,
    MapboxLayer,
    MapError,
    MapLegend,
    ZubieMap,
  },
  computed: {
    ...mapState('env', ['mapboxAccessToken']),
    ...mapState('map', ['nearbyMapStyle']),
    isoLayer() {
      const nearbyIsoLayer = new NearbyIsoLayer();
      return nearbyIsoLayer.getConfig();
    },
    isoOutlineLayer() {
      const nearbyIsoOutlineLayer = new NearbyIsoOutlineLayer();
      return nearbyIsoOutlineLayer.getConfig();
    },
    isoSource() {
      const nearbyIsoSource = new NearbyIsoSource();
      nearbyIsoSource.features = this.iso.map((feature, index) => ({
        ...feature,
        properties: {
          ...feature.properties,
          color: CONTOUR_COLORS[this.nearbyMapStyle][index],
          outlineColor: CONTOUR_OUTLINE_COLORS[this.nearbyMapStyle][index],
        },
      }));
      return nearbyIsoSource.getConfig();
    },
    locationLayer() {
      const nearbyLocationLayer = new NearbyLocationLayer();
      return nearbyLocationLayer.getConfig();
    },
    locationSource() {
      const nearbyLocationSource = new NearbyLocationSource();
      nearbyLocationSource.features = [
        new GeoJsonFeature(this.locationCoordinates, {
          address: this.address,
        }),
      ];
      return nearbyLocationSource.getConfig();
    },
    mapImages() {
      return [
        new MapImage('nearby-location', PlaceSvg, { height: 60, width: 60 }),
        new MapImage('a-pin', APinSvg, { height: 91, width: 60 }),
        new MapImage('b-pin', BPinSvg, { height: 91, width: 60 }),
        new MapImage('c-pin', CPinSvg, { height: 91, width: 60 }),
        new MapImage('d-pin', DPinSvg, { height: 91, width: 60 }),
        new MapImage('e-pin', EPinSvg, { height: 91, width: 60 }),
      ];
    },
    mapLegendSymbols() {
      return CONTOURS.map((contour, index) => ({
        label: `${contour} minutes`,
        tooltip: `Reachable within ${contour} minutes`,
        bgColorHex: [...CONTOUR_COLORS[this.nearbyMapStyle]].reverse()[index],
        opacity: CONTOUR_LEGEND_OPACITY[this.nearbyMapStyle],
      }));
    },
    vehiclesLayer() {
      const nearbyVehiclesLayer = new NearbyVehiclesLayer();
      return nearbyVehiclesLayer.getConfig();
    },
    vehiclesSource() {
      const nearbyVehiclesSource = new NearbyVehiclesSource();

      const features = this.vehiclesList.map(
        ({ coordinates, key, letterId, nickname }) =>
          new GeoJsonFeature(coordinates, {
            key,
            letterId: letterId.toLowerCase(),
            nickname: nickname || 'Unnamed Vehicle',
          })
      );

      // Reverse features so closer vehicles are above further ones
      const sortedFeatures = features.reverse().filter(({ properties }) => properties.key !== this.highlightedVehicle);

      if (this.highlightedVehicle) {
        // Add highlighted feature last so it stacks above others
        sortedFeatures.push(features.find(({ properties }) => properties.key === this.highlightedVehicle));
      }

      // Add priority based on current sort
      nearbyVehiclesSource.features = sortedFeatures.map((feature, index) => ({
        ...feature,
        priority: index,
      }));

      return nearbyVehiclesSource.getConfig();
    },
  },
  data() {
    return {
      draggableLayerAdded: false,
      locationCoordinates: [],
      mapStyleChooserAdded: false,
      showIsochrones: true,
    };
  },
  methods: {
    ...mapActions('map', ['setNearbyMapStyle']),
    fitBounds() {
      if (this.map?.style && this.bounds) {
        this.map.fitBounds(this.bounds, {
          padding: 75,
        });
      }
    },
    handleRoute() {
      if (this.$route.name === 'nearby') {
        // Re-fit bounds if we already have results for these coordinates
        if (_isEqual(this.coordinates, this.lastLookup)) {
          this.fitBounds();
        }
      }
    },
    onMapLoad({ map }) {
      this.map = map;
      this.fitBounds();
    },
    onVehicleHover({ mapboxEvent }) {
      const features = mapboxEvent.features || [];
      if (features.length) {
        const [feature] = features;
        this.$emit('highlight-vehicle', feature.properties.key);
      } else {
        this.$emit('highlight-vehicle', null);
      }
    },
  },
  watch: {
    bounds() {
      this.fitBounds();
    },
    coordinates() {
      this.locationCoordinates = this.coordinates;
    },
    highlightedVehicle() {
      if (this.map?.style && this.draggableLayerAdded) {
        if (this.highlightedVehicle) {
          // Highlight vehicle
          this.map.setFeatureState(
            { source: NearbyVehiclesSource.id, id: this.highlightedVehicle },
            { highlight: true }
          );

          // Turn off all non-highlighted
          this.vehiclesList.forEach((vehicle) => {
            if (vehicle.key !== this.highlightedVehicle) {
              this.map.setFeatureState({ source: NearbyVehiclesSource.id, id: vehicle.key }, { highlight: false });
            }
          });
        } else {
          // Show all vehicles
          this.vehiclesList.forEach((vehicle) => {
            this.map.setFeatureState({ source: NearbyVehiclesSource.id, id: vehicle.key }, { highlight: null });
          });
        }
      } else {
        this.$emit('highlight-vehicle', null);
      }
    },
    $route() {
      this.handleRoute();
    },
  },
  created() {
    this.locationCoordinates = this.coordinates;
    this.handleRoute();
  },
};
</script>
