import _isEmpty from 'lodash/isEmpty';
import { Dialog, Notify } from 'quasar';
import NetworkConnectionRequired from 'components/NetworkConnectionRequired.vue';
import { setTimeoutPromise } from 'src/services/setTimeout';

export const state = {
  constants: Object.freeze({
    OFFLINE_TIMEOUT: 10000,
  }),
  dialog: null,
  dialogCanceled: false,
  dismissNotification: null,
  offlineTimeout: null,
  router: null,
  store: null,
};

/**
 * Initializes the network service.
 *
 * @param {Object} params
 * @param {VueRouter} params.router
 * @param {import('node_modules/vuex/types/index').Store} params.store
 */
export function init({ router, store }) {
  state.router = router;
  state.store = store;
  window.addEventListener('offline', onOffline);
  state.router.beforeEach(onRouteChange);
}

/**
 * Returns true if currently online.
 *
 * @returns {Boolean}
 */
export function isOnline() {
  return window.navigator.onLine;
}

/**
 * Shows a "disconnected" notification after a period of time.
 */
export async function onOffline() {
  state.offlineTimeout = setTimeoutPromise(state.constants.OFFLINE_TIMEOUT);
  window.addEventListener('online', onOnline);

  try {
    await state.offlineTimeout;
  } catch (error) {
    if (!error.isCanceled) {
      throw error;
    }
    return;
  }

  showNotification();
}

/**
 * Resets disconnection notices and restores to a good state.
 */
export function onOnline() {
  // Close any open dialog
  if (state.dialog) {
    if (state.dialog.showing) {
      state.dialog.hide();
    }
    state.dialog = null;
  }

  // Cancel timeout
  if (state.offlineTimeout) {
    state.offlineTimeout.cancel();
    state.offlineTimeout = null;
  }

  // Dismiss notification
  if (state.dismissNotification) {
    state.dismissNotification();
    state.dismissNotification = null;
  }

  // Remove this handler
  window.removeEventListener('online', onOnline);
}

/**
 * Checks network connection on route changes, displaying a dialog
 * if disconnected.
 *
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 */
export function onRouteChange(to, from, next) {
  state.dialogCanceled = false;

  if (!isOnline()) {
    state.dialog = Dialog.create({
      component: NetworkConnectionRequired,
    })
      .onOk(() => {
        if (_isEmpty(state.store.state.session.currentUser)) {
          window.location.reload();
        }
        next(false);
        state.dialogCanceled = true;
      })
      .onDismiss(() => {
        if (!state.dialogCanceled) {
          next();
        }
      });

    window.addEventListener('online', onOnline);
    return;
  }
  next();
}

/**
 * Displays a disconnection notification.
 */
export function showNotification() {
  state.dismissNotification = Notify.create({
    icon: 'error',
    color: 'negative',
    timeout: 0,
    message: 'Connection lost. Please check your internet connection.',
    actions: [
      {
        label: 'Dismiss',
        color: 'white',
        rounded: true,
        handler() {
          if (state.dialog) {
            return;
          }
          state.dialog = Dialog.create({
            component: NetworkConnectionRequired,
          });
        },
      },
    ],
  });
}
