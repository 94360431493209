export default () => ({
  batteryLevelHistory: {},
  editDialog: null,
  followedVehicleKey: null,
  /** @type {{ [key: string]: import('src/models/Vehicle').default }} */
  vehicles: {},
  hasInitialized: false,
  vehiclesLoading: false,
  vehiclesLitePromise: false,
  detailedVehicles: {},
  detailedVehiclePromise: null,
  detailedVehiclesLoading: false,
  schedules: {},
  schedulesLoading: false,
  thirtyDayStats: {},
  thirtyDayStatsLoading: false,
  // Maintenance
  accountMaintenanceCategories: [],
  maintenanceHistory: {},
  maintenanceHistoryLoading: false,
  maintenanceReminders: [],
  maintenanceRemindersLoading: false,
});
