import store from 'src/store';

export const state = {
  /** @type {Function[]} */
  callbacks: [],
  /** @type {String} */
  exitFullscreenMethod: null,
};

/**
 * Adds fullscreen change listeners and determines appropriate exit method.
 */
export function init() {
  ['fullscreenchange', 'mozfullscreenchange', 'webkitfullscreenchange', 'msfullscreenchange'].forEach((eventName) => {
    document.addEventListener(eventName, () => fullscreenChange(), false);
  });
  state.exitFullscreenMethod = [
    'exitFullscreen',
    'mozCancelFullScreen',
    'webkitExitFullscreen',
    'msExitFullscreen',
  ].find(
    (methodName) => Boolean(document[methodName]) // this comment here to appease linter
  );
}

/**
 * Applies/removes styles related to notches in fullscreen mode.
 */
export function avoidNotches() {
  const notchAdjustments = isFullscreen()
    ? store().state.app.notchSizes
    : { top: undefined, bottom: undefined, left: undefined, right: undefined };
  const { bottom, left, right, top } = notchAdjustments;
  document.querySelector('.mapboxgl-ctrl-top-left').style.top = top ? `${top + 50}px` : '';
  document.querySelector('.mapboxgl-ctrl-top-left').style.left = left ? `${left}px` : '';
  document.querySelector('.mapboxgl-ctrl-top-right').style.top = top ? `${top}px` : '';
  document.querySelector('.mapboxgl-ctrl-top-right').style.right = right ? `${right}px` : '';
  document.querySelector('.mapboxgl-ctrl-top-right-secondary').style.top = top ? `${top}px` : '';
  document.querySelector('.mapboxgl-ctrl-top-right-secondary').style.right = right ? `${right + 50}px` : '';
  document.querySelector('.mapboxgl-ctrl-bottom-left').style.bottom = bottom ? `${bottom}px` : '';
  document.querySelector('.mapboxgl-ctrl-bottom-left').style.left = left ? `${left}px` : '';
  document.querySelector('.mapboxgl-ctrl-bottom-right').style.bottom = bottom ? `${bottom}px` : '';
}

/**
 * Exits fullscreen mode (if fullscreen).
 */
export function exitFullscreen() {
  if (isFullscreen()) {
    document[state.exitFullscreenMethod]();
  }
}

/**
 * Determines if fullscreen mode is active and notifies listeners.
 * - triggered when a fullscreen change occurs
 * - also adds a "fullscreen" class to the body
 */
export function fullscreenChange() {
  // Add/remove fullscreen class
  const action = isFullscreen() ? 'add' : 'remove';
  document.body.classList[action]('fullscreen');

  // Apply notch avoidances
  avoidNotches();

  // Call each callback listening for fullscreen change
  state.callbacks.forEach((callback) => {
    callback(isFullscreen());
  });
}

/**
 * Detects if browser is in fullscreen mode.
 *
 * @returns {boolean}
 */
export function isFullscreen() {
  return Boolean(
    document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitCurrentFullScreenElement ||
      document.msFullscreenElement
  );
}

/**
 * Adds a listener for the fullscreen change event.
 *
 * @param {Function} callback
 */
export function onFullscreenChange(callback) {
  state.callbacks.push(callback);
}

/**
 * Removes the given fullscreen change event listener.
 *
 * @param {Function} callback
 */
export function offFullscreenChange(callback) {
  const index = state.callbacks.findIndex(callback);
  state.callbacks.splice(index, 1);
}
