import Bugsnag from '@bugsnag/js';
import { version } from 'app/package.json';
import utc from 'dayjs/plugin/utc';
import _isEmpty from 'lodash/isEmpty';
import platform from 'platform';
import { Platform } from 'quasar';
import { dayjs } from 'src/services/date';
import errorService from 'src/services/error';
import { update as intercomUpdate } from 'src/services/intercom';
import { updateRecurly } from 'src/services/recurly';
import storage from 'src/services/storage';
import Transformer from 'src/transformers/Transformer';

dayjs.extend(utc);

async function getProfile(context) {
  const promise = context.rootState.app.broker.fetchAndTransform({
    fn: 'getAuthProfile',
  });

  context.commit('profilePromise', promise);
  const response = await promise;

  if (_isEmpty(response) || response.error) {
    context.commit('profile', null);
    return null;
  }

  context.commit('profile', response);

  return response;
}

export async function profile(context) {
  if (context.state.profilePromise === null) {
    return getProfile(context);
  }
  return context.state.profile;
}

export function setProfile(context, data) {
  context.commit('profile', data);
}

/**
 * Retrieves permission info from the API.
 *
 * @param {ActionContext} context
 * @returns {Object}
 */
export async function permissions(context) {
  if (context.state.permissionsPromise !== null) {
    return context.state.permissionsPromise;
  }

  let permissionsData = null;

  const promise = context.rootState.app.broker.fetchAndTransform({
    fn: 'getPermissions',
  });
  context.commit('permissionsPromise', promise);
  permissionsData = await promise;

  if (!permissionsData.error) {
    context.commit('permissions', permissionsData);
  }

  return permissionsData;
}

/**
 * Updates the account with the given data.
 *
 * @param {import('src/types/session-store').SessionStoreActionContext} context
 * @param {{ billingUserKey: string }} data
 */
export async function updateAccount(context, data) {
  /** @type {import('src/types/session-store').AccountData | { errors: string }} */
  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'updateAccount',
    params: { body: Transformer.snakeCaseKeysDeep(data) },
  });

  if (!response.errors && !response.error) {
    context.commit('setAccount', response);
  }

  return response;
}

/**
 * Retrieves account info for the current user from the API.
 *
 * @param {ActionContext} context
 * @param {Boolean} forceRefresh
 * @returns {Object}
 */
export async function account(context, forceRefresh = false) {
  if (forceRefresh) {
    context.commit('accountPromise', null);
  }

  if (context.state.accountPromise) {
    return context.state.accountPromise;
  }

  const promise = context.rootState.app.broker.fetchAndTransform({
    fn: 'getAccount',
  });

  context.commit('accountPromise', promise);
  const response = await promise;

  if (response.error) {
    context.commit('setAccount', null);
    return null;
  }

  context.commit('setAccount', response);

  storage.setAccountPrefix(response.key);

  return response;
}

/**
 * Retrieves current user info from the API.
 *
 * @param {ActionContext} context
 * @returns {Object}
 */
export async function currentUser(context) {
  if (context.state.currentUserPromise !== null) {
    return context.state.currentUserPromise;
  }

  const promise = context.rootState.app.broker.fetchAndTransform({
    fn: 'getCurrentUser',
  });
  context.commit('currentUserPromise', promise);

  const response = await promise;

  if (response.error) {
    context.commit('currentUser', null);
    return null;
  }

  context.commit('currentUser', response);

  storage.setUserPrefix(response.key);

  return response;
}

/**
 * Reloads the current user information.
 *
 * @param {ActionContext} context
 * @returns {Object}
 */
export async function reloadCurrentUser(context) {
  context.commit('currentUserPromise', null);
  return context.dispatch('currentUser');
}

export async function partner(context) {
  if (context.state.partnerPromise === null) {
    return getPartner(context);
  }
  return context.state.partnerPromise;
}

/**
 * @param {import('src/types/session-store').SessionStoreActionContext} context
 */
async function getPartner(context) {
  const promise = context.rootState.app.broker.fetchAndTransform({
    fn: 'getPartner',
  });

  context.commit('partnerPromise', promise);

  /** @type {import('src/types/session-store').PartnerData | import('src/models/ErrorPayload').default} */
  const response = await promise;

  if ('error' in response) {
    // clear account info if the user is unauthenticated
    context.commit('partner', {});
    return null;
  }

  context.commit('partner', response);

  return response;
}

export async function init(context, accountId) {
  if (!accountId) {
    const error = new Error('You must call init with an accountId');
    error.name = 'AccountIdMissingError';
    throw error;
  }

  await context.rootState.app.broker.setAccountIdHeader(accountId);

  if (context.rootState.env.eventStreamEnabled === 'yes') {
    await context.rootState.app.broker.initEventStream({ url: context.rootState.env.eventStreamUrl });
  }

  /** @type {[AccountData]} */
  const [accountData, userData] = await Promise.all([
    context.dispatch('account'),
    context.dispatch('currentUser'),
    context.dispatch('partner'),
    context.dispatch('permissions'),
    context.dispatch('profile'),
  ]);

  let subscriptionData;
  if (accountData.subscription) {
    subscriptionData = await context.dispatch('subscription');
  }

  context.dispatch('getDashcamFleet'); // needs account ID

  const sku = accountData.subscription?.plan?.sku || 'Unknown';

  if (window.dataLayer && window.dataLayer.push) {
    const data = {
      accountId: accountData.id,
      accountKey: accountData.key,
      accountPartner: accountData.partnerName,
      accountRole: userData.accountRole,
      accountTier: accountData.partnerTier?.name || 'Unknown',
      appName: 'Web App',
      appVersion: version,
      browser: platform.name,
      browserVersion: platform.version,
      event: 'appBoot',
      os: platform.os.family,
      osVersion: platform.os.version,
      viewportHeight: window.innerHeight,
      viewportWidth: window.innerWidth,
      userAgent: platform.ua,
      userCreated: dayjs.utc(userData.created).unix(),
      userEmail: userData.email,
      userFirst: userData.firstName,
      userHash: userData.intercomHash,
      userKey: userData.key,
      userLast: userData.lastName,
    };
    if (platform.product) {
      data.device = platform.product;
    }
    window.dataLayer.push(data);
  }

  const intercomData = {
    account_created: dayjs.utc(accountData.created).unix(),
    account_role: userData.accountRole,
    account_tier: accountData.partnerTier?.name || 'Unknown',
    app_id: context.rootState.env.intercomAppId,
    created_at: dayjs.utc(userData.created).unix(),
    email: userData.email,
    name: `${userData.firstName} ${userData.lastName}`,
    partner: accountData.partnerName,
    user_hash: userData.intercomHash,
    user_id: userData.key,
    subscription_sku: sku,
    term_end_date: subscriptionData?.termEndDate,
  };

  if (navigator && navigator.languages && navigator.languages.length > 0) {
    intercomData['Browser Languages'] = navigator.languages.join(', ');
  }

  if (Platform.is.capacitor) {
    intercomData.ios_user_hash = userData.intercomIosHash;
  }

  await intercomUpdate(intercomData);

  if (window.delighted) {
    window.delighted.survey({
      email: userData.email,
      name: `${userData.firstName} ${userData.lastName}`,
      properties: {
        user_key: userData.key,
        account_role: userData.accountRole,
        account_partner: accountData.partnerName,
        account_tier: accountData.partnerTier?.name || 'Unknown',
      },
    });
  }

  if (window.ChurnZero) {
    window.ChurnZero.push([
      'setContact',
      accountData.id,
      userData.email,
      accountData.churnZeroHash,
      userData.churnZeroEmailHash,
    ]);
  }

  Bugsnag.setUser(userData.id, userData.email, `${userData.firstName} ${userData.lastName}`);
  Bugsnag.addMetadata('user', {
    key: userData.key,
    firstName: userData.firstName,
    lastName: userData.lastName,
    role: userData.accountRole,
  });
  Bugsnag.setUser({
    id: userData.id,
    email: userData.email,
  });

  Bugsnag.addMetadata('account', {
    id: accountData.id,
    key: accountData.key,
    nickname: accountData.nickname,
    partnerName: accountData.partnerName,
    partnerTier: accountData.partnerTier?.name || 'Unknown',
  });

  context.dispatch('notifications/init', null, { root: true });
  context.commit('ready');
}

/**
 * Retrieves accounts that belong to the logged in user.
 *
 * @param {ActionContext} context
 * @returns {Object[]}
 */
export async function getAccounts(context) {
  const accounts = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getAccounts',
    transformationFn: 'transformArray',
  });

  if (accounts.error) {
    return accounts;
  }

  context.commit('accounts', accounts);
  return accounts;
}

/**
 * Updates the billing info in Recurly and then provides a token (returned by Recurly) to the Data Broker.
 *
 * @param {ActionContext} context
 * @param {Component} recurlyForm
 * @returns {Object}
 */
export async function updateBillingInfo(context, recurlyForm) {
  context.commit('setBillingErrors', {});

  let tokenId;
  try {
    tokenId = await updateRecurly(recurlyForm);
  } catch (recurlyError) {
    if (recurlyError.details) {
      const billingErrors = {};
      recurlyError.details.forEach((error) => {
        billingErrors[error.field] = error.messages;
      });
      context.commit('setBillingErrors', billingErrors);
    } else {
      errorService.log(recurlyError, {
        metaData: {
          recurlyForm,
        },
      });
    }
    return;
  }

  const billingInfo = await context.rootState.app.broker.fetchAndTransform({
    fn: 'updateBillingInfo',
    params: { tokenId },
  });

  if (!billingInfo.error) {
    context.commit('setBillingInfo', billingInfo);
  }
}

/**
 * Retrieves the account's coupon via the Data Broker.
 *
 * @param {ActionContext} context
 */
export async function getCoupon(context) {
  if (context.state.couponLoading === true) {
    return;
  }

  context.commit('setCouponLoading', true);

  const coupon = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getCoupon',
  });

  if (!coupon.error) {
    context.commit('setCoupon', coupon);
  }

  context.commit('setCouponLoading', false);
}

/**
 * Clears the billing errors.
 *
 * @param {ActionContext} context
 */
export function clearBillingErrors(context) {
  context.commit('setBillingErrors', {});
}

/**
 * Removes the coupon currently associated with the account.
 *
 * @param {ActionContext} context
 */
export async function removeCouponCode(context) {
  const response = await context.rootState.app.apiWorker.removeCoupon();

  if (!response.error) {
    context.commit('setCoupon', {});
  }
}

/**
 * Applies the given coupon code to the account;
 *
 * @param {ActionContext} context
 * @param {String} code
 * @returns {Object}
 */
export async function updateCouponCode(context, code) {
  let coupon = await context.rootState.app.broker.fetchAndTransform({
    fn: 'updateCoupon',
    params: code,
  });

  if (coupon.errors || coupon.error) {
    coupon = {
      error: coupon.errors || coupon.error,
    };
  } else {
    context.commit('setCoupon', coupon);
  }

  return coupon;
}

/**
 * Initializes SSO for the current user.
 *
 * @param {ActionContext} context
 * @returns {Promise<Object>}
 */
export async function sso(context) {
  return context.rootState.app.broker.fetchAndTransform({ fn: 'sso' });
}

/**
 * Retrieves the Driver ID QR code info from Raven.
 *
 * @param {ActionContext} context
 * @param {string} userKey
 */
export async function createDashcamDriverId(context, userKey) {
  const { id: accountId } = context.state.account;

  /** @type {import('axios').AxiosResponse<DriverIdData>} */
  const { data: driverIdData, status } = await context.rootState.app.apiWorker.getExistingDriverId({ userKey });
  const { public_id: publicId } = driverIdData;
  let { code, url } = driverIdData;

  if (status === 404) {
    const { qr_codes: qrCodes } = await context.rootState.app.apiWorker.createDashcamDriverId({ accountId, userKey });

    const qrCodeData = qrCodes?.[0];
    if (!qrCodeData) {
      throw new Error('QR code not found');
    }

    const { secureUrl } = await context.dispatch(
      'image/uploadImage',
      { metadata: { ravenQrCode: qrCodeData.code }, publicId, url: qrCodeData.url },
      { root: true }
    );

    url = secureUrl;
    code = qrCodeData.code;
  }

  return {
    code,
    url,
  };
}

/**
 * Retrieves Fleet info, verifying account has dashcam devices.
 */
export async function getDashcamFleet(context) {
  const { id: accountId } = context.state.account;
  context.commit('setLoadingRavenFleet', true);
  const data = await context.rootState.app.apiWorker.getDashcamFleet({ accountId });
  context.commit('setLoadingRavenFleet', false);
  context.commit('setHasDashcam', !data.error);
}

/**
 * Changes the preferred & selected check-in type.
 *
 * @param {SessionStoreActionContext} context
 * @param {'MANUAL' | 'DRIVER_ID'} type
 */
export function setCheckInType(context, type) {
  context.commit('setCheckInType', type);
}

/**
 * Retrieves the current account's subscription.
 *
 * @param {SessionStoreActionContext} context
 */
export async function subscription(context) {
  const subscription = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getSubscription',
    params: context.state.account.subscription?.id,
  });

  if (subscription.error) {
    errorService.log(new Error(subscription.error));
  }

  return subscription;
}
