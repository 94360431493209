<template>
  <div>
    <q-chip
      v-bind="$attrs"
      class="row items-center q-ma-none line-height-1"
      :class="{ 'no-icon': !icon, 'no-outline': !outline, 'transparent-outline': transparentOutline }"
      :clickable="clickable"
      :color="color"
      :dense="dense"
      :outline="outline"
      :removable="removable"
      :ripple="clickable"
      :selected="undefined"
      :square="square"
      :tabindex="tabindex"
      @click="onClick"
      @remove="$emit('remove', $event)"
    >
      <slot name="icon">
        <q-icon v-if="icon" :class="{ 'q-mr-xs': !dense, 'icon--dense': dense }" :name="icon" :size="iconSize" />
      </slot>
      <div :class="contentClass">
        <text-highlight ref="text" :query="termsString" split-by-space>
          <slot name="default" />
        </text-highlight>
      </div>
      <slot name="append" />
      <ToolTip class="bg-primary" :model-value="copied" no-parent-event :offset="[0, 5]" transition-hide="fade"
        >copied to clipboard</ToolTip
      >
    </q-chip>
  </div>
</template>

<script setup lang="ts">
import type TextHighlight from 'vue-word-highlighter';
import { computed, ref, useTemplateRef } from 'vue';
import ToolTip from 'components/ToolTip.vue';
import { copy } from 'src/services/clipboard';

const props = withDefaults(
  defineProps<{
    clickable?: boolean;
    color?: string;
    contentClass?: string | object;
    dense?: boolean;
    icon?: string;
    iconSize?: string;
    noCopy?: boolean;
    outline?: boolean;
    removable?: boolean;
    searchTerms?: string[];
    square?: boolean;
    tabindex?: number;
    transparentOutline?: boolean;
  }>(),
  {
    iconSize: '1em',
    outline: true,
    searchTerms() {
      return [];
    },
    tabindex: -1,
  }
);

const emit = defineEmits(['click', 'remove']);

const copied = ref(false);

const termsString = computed(() => props.searchTerms.join(' '));

const text = useTemplateRef<typeof TextHighlight>('text');
function onClick(event) {
  if (!props.noCopy) {
    copy(text.value.$el.wholeText)
      .then(() => {
        copied.value = true;
        setTimeout(() => (copied.value = false), 1000);
      })
      .catch(() => {});
  }
  emit('click', event);
}
</script>

<style lang="scss" scoped>
.no-icon {
  padding-left: 0.5em;
}

.no-outline {
  line-height: 1.3em;
}

.transparent-outline {
  border-color: transparent !important;
}

:deep(.icon--dense) {
  margin-right: 2px;
}
</style>
