<template>
  <q-btn
    color="primary"
    :dense="dense"
    :disable="disable"
    :icon="icon"
    :label="label"
    :loading="loading"
    :no-caps="noCaps"
    :padding="padding"
    :size="size"
    square
    text-color="white"
    :type="type"
  >
    <template v-if="$slots.loading" #loading>
      <slot name="loading" />
    </template>
    <slot />
  </q-btn>
</template>

<script>
export default {
  name: 'PrimaryButton',
  props: {
    dense: Boolean,
    disable: Boolean,
    icon: String,
    label: String,
    loading: Boolean,
    noCaps: Boolean,
    padding: String,
    size: String,
    type: String,
  },
};
</script>
