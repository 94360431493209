import GeoJsonLayer from 'src/services/geoJson/GeoJsonLayer';
import AssetsSource from 'src/services/geoJson/sources/AssetsSource';

export default class AssetsClusterCountLayer extends GeoJsonLayer {
  /** @var {String} id */
  static id = 'assets-cluster-count';

  /**
   * @returns {Object} A GeoJson layer config object.
   */
  getConfig() {
    return Object.freeze({
      id: this.getId(),
      type: 'symbol',
      source: AssetsSource.id,
      filter: ['has', 'point_count'],
      layout: {
        'text-allow-overlap': true,
        'text-field': '{point_count_abbreviated}',
        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
      paint: {
        'text-color': '#373737',
      },
    });
  }
}
