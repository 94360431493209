/**
 * @type {RegisteredListenersMap}
 */
export const registeredListeners = {};

/**
 * @param {string} id
 * @param {(event: Event) => void} callback
 */
export function addOutsideClickListener(id, callback) {
  if (registeredListeners[id]) {
    const error = new Error(`Outside click listener already registered with id: ${id}`);
    error.name = 'OutsideClickListenerRegisterError';
    throw error;
  }

  registeredListeners[id] = (event) => handleOutsideClick(event, callback);

  document.body.addEventListener('click', registeredListeners[id]);
}

/**
 * @param {string} id
 */
export function removeOutsideClickListener(id) {
  document.body.removeEventListener('click', registeredListeners[id]);
  delete registeredListeners[id];
}

/**
 * Handles outside-click event and triggers given callback if valid.
 *
 * @param {Event} event
 * @param {(event: Event) => void} callback
 */
export function handleOutsideClick(event, callback) {
  // Determine if target is—or is within—a dialog or menu
  let shouldTrigger = true;

  let currentElement = event.target;
  while (currentElement) {
    const { classList } = currentElement;
    if (classList?.contains('q-menu') || classList?.contains('q-dialog')) {
      // We should not trigger "outside click" event
      shouldTrigger = false;
      break;
    }
    currentElement = currentElement.parentNode;
  }

  if (shouldTrigger) {
    callback(event);
  }
}
