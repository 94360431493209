import compact from 'lodash/compact';
import differenceWith from 'lodash/differenceWith';
import filter from 'lodash/filter';
import find from 'lodash/find';
import first from 'lodash/first';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import reduce from 'lodash/reduce';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

export const defaultChainableFunctions = {
  compact,
  differenceWith,
  filter,
  find,
  first,
  flatten,
  groupBy,
  isEmpty,
  keyBy,
  map,
  orderBy,
  reduce,
  reverse,
  sort: sortBy,
  sortBy,
  uniq,
  uniqBy,
};

export default function chain(input, chainables = defaultChainableFunctions) {
  let value = input;

  const wrapper = {
    ...mapValues(chainables, (callback) => (...args) => {
      value = callback(value, ...args);
      return wrapper;
    }),
    value: () => value,
  };
  return wrapper;
}
