<template>
  <div class="container absolute">
    <div class="row no-wrap items-center q-pa-md bg-white text-subtitle1 text-grey-8 shadow-2">
      <div class="row q-mr-sm"><q-icon color="negative" name="warning" size="20px" /></div>
      <div class="q-mr-sm text-no-wrap">No items match the selected filters</div>
      <div>
        <q-btn color="white" dense padding="xs sm" size="12px" square text-color="primary" @click="resetAll">
          <div class="no-wrap text-no-wrap"><q-icon class="q-mr-xs" name="filter_list_off" /> Clear Filters</div>
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'NoItemsVisibleMessage',
  methods: {
    ...mapActions('filtering', ['resetAll']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
