<template>
  <ZubieChip
    :content-class="{ 'text-grey-7 text-italic': !group.name }"
    :dense="dense"
    icon="supervised_user_circle"
    :removable="removable"
    @remove="$emit('remove', $event)"
  >
    {{ group.name || 'Unnamed Group' }}
  </ZubieChip>
</template>

<script>
import ZubieChip from 'components/chips/ZubieChip.vue';

export default {
  name: 'GroupChip',
  components: {
    ZubieChip,
  },
  props: {
    dense: Boolean,
    group: {
      default: () => ({}),
      type: Object,
    },
    removable: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
