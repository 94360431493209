<template>
  <ZubieDialog
    card-class="mapped-list-dialog__card"
    content-class="mapped-list-dialog__content q-px-none"
    :inner-dialog-height-offset="58"
    :maximized="true"
    no-focus
    no-refocus
    ok-only
    seamless
    title="Vehicles &amp; Assets"
    title-color="secondary"
    title-dense
    title-style="padding: 2px 0; font-size: 20px; letter-spacing: 0.005em; font-weight: 400"
    title-text-color="black"
    transition-hide="slide-down"
    transition-show="slide-up"
    :visible="listDialogOpen"
    @before-hide="onBeforeHide"
    @before-show="onBeforeShow"
    @show="onShow"
  >
    <MappedAssetsList @click.stop @item-select="closeListDialog" />
  </ZubieDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MappedAssetsList from 'components/map/MappedAssetsList.vue';
import { addOutsideClickListener, removeOutsideClickListener } from 'src/services/outside-click';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  name: 'MappedAssetsListDialog',
  components: {
    MappedAssetsList,
  },
  computed: {
    ...mapState('map', ['listDialogOpen']),
  },
  methods: {
    ...mapActions('map', ['closeListDialog', 'openListDialog']),
    onBeforeHide() {
      this.removeListDialog();
    },
    async onBeforeShow() {
      document.body.classList.add('map-list-dialog-backdrop');
      await setTimeoutPromise(100);

      const dialog = document.querySelector('.q-dialog');
      dialog.classList.add('map-list-dialog');

      const innerDialog = document.querySelector('.q-dialog__inner');
      innerDialog.addEventListener('click', () => {
        // Make sure "outside click" is triggered when clicking backdrop area
        document.body.dispatchEvent(new Event('click'));
      });
    },
    onShow() {
      this.mappedListDialogCard = document.querySelector('.mapped-list-dialog__card');
      this.searchBar = document.querySelector('.universal-search');
      this.mappedListDialogCard?.addEventListener('click', this.modalClickStopper);
      this.searchBar?.addEventListener('click', this.modalClickStopper);
      addOutsideClickListener('mappedAssetsListDialog', () => this.removeListDialog());
    },
    async removeListDialog() {
      this.closeListDialog();
      document.body.classList.remove('map-list-dialog-backdrop');
      this.mappedListDialogCard?.removeEventListener('click', this.modalClickStopper);
      this.searchBar?.removeEventListener('click', this.modalClickStopper);
      removeOutsideClickListener('mappedAssetsListDialog');
    },
  },
  created() {
    this.modalClickStopper = (event) => {
      event.stopPropagation();
    };
  },
  beforeUnmount() {
    this.removeListDialog();
  },
};
</script>

<style lang="scss">
.map-list-dialog {
  .q-dialog__inner {
    top: 58px;
    pointer-events: all !important;
    transition: all 0.3s ease-out;

    > div {
      max-height: 100% !important;
    }
  }

  .dialog--body {
    padding: 8px 0 0;
  }

  .dialog--footer .q-btn {
    padding: 8px 0;
  }

  .dialog--footer .q-btn__wrapper {
    min-height: 0;
  }
}

body.q-ios-padding .map-list-dialog .q-dialog__inner > div {
  max-height: 100% !important;
}

.map-list-dialog-backdrop {
  .q-page {
    opacity: 0.3;

    @media (min-width: $breakpoint-sm) {
      opacity: 1;
    }
  }
}
</style>
