<template>
  <q-tooltip
    :anchor="anchor"
    :content-class="tooltipClasses"
    :offset="offset"
    :self="self"
    :transition-hide="transitionHide"
    :transition-show="transitionShow"
    ><slot
  /></q-tooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    anchor: {
      type: String,
      default: 'top middle',
    },
    contentClass: String,
    dark: Boolean,
    noBorder: Boolean,
    offset: {
      type: Array,
      default() {
        return [0, 0];
      },
    },
    self: {
      type: String,
      default: 'bottom middle',
    },
    transitionHide: {
      type: String,
      default: 'jump-down',
    },
    transitionShow: {
      type: String,
      default: 'jump-up',
    },
  },
  computed: {
    tooltipClasses() {
      const classes = [];

      if (this.dark) {
        classes.push('tooltip--dark text-white');
      } else {
        classes.push('text-black bg-silver');
      }

      if (!this.noBorder) {
        classes.push('tooltip-border');
      }

      return classes.join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-border {
  border: 1px solid #d3d3d3;
}

.tooltip--dark {
  background: #757575;
}
</style>
