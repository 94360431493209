<template>
  <q-toolbar class="justify-between q-my-xs">
    <div class="logo-title row justify-start">
      <q-toolbar-title
        v-if="$q.screen.gt.xs || (!mobileTitleDisplayed && !showSearchOnMobile)"
        class="title row items-center no-wrap q-pr-none flex-unset"
      >
        <q-btn borderless class="q-mr-md" dense flat :to="{ name: 'map' }">
          <q-img
            v-if="accountImgSrc && !showDefaultLogo"
            contain
            data-t="logo"
            height="32px"
            position="left"
            spinner-color="white"
            spinner-size="30px"
            :src="accountImgSrc"
            width="150px"
            @error="showDefaultLogo = true"
          ></q-img>
          <ZubieLogo v-else class="logo" height="32px" title="Zubie" />
        </q-btn>
        <div v-if="appName && showDefaultLogo" class="zubie-font app-name col-grow">
          {{ appName }}
        </div>
        <q-separator
          v-if="!showSearchOnMobile && mobileTitleDisplayed && appName && showDefaultLogo && $q.screen.gt.md"
          class="q-mx-md q-my-sm bg-white"
          vertical
        />
      </q-toolbar-title>
      <q-toolbar-title v-if="mobileTitleDisplayed && !showSearchOnMobile" class="row items-center q-pl-none">
        <q-skeleton v-if="mobileTitlePending" height="100%" type="QBadge" width="200px" />
        <div v-else class="mobile-title line-height-1">{{ mobileTitle }}</div>
      </q-toolbar-title>
    </div>
    <div class="row no-wrap full-width" :class="{ 'justify-end': !$q.screen.gt.sm || !isAuthenticated }">
      <div
        v-if="((!mobileTitleDisplayed && $q.screen.gt.sm) || showSearchOnMobile) && isAuthenticated"
        class="full-width row justify-center"
        :class="{ 'q-pr-sm q-pl-md': $q.screen.gt.xs }"
        isAuthenticated
      >
        <UniversalSearch v-if="!isLoadingPage && !isIndividual" :autofocus="showSearchOnMobile" @blur="onSearchBlur" />
      </div>
      <div class="row items-center">
        <div class="row no-wrap">
          <q-btn
            v-if="!showSearchOnMobile && !isIndividual && !$q.screen.gt.sm"
            dense
            flat
            icon="search"
            @click.stop="showSearchOnMobile = true"
          />
          <div>
            <PrimaryButton
              v-if="$q.screen.gt.xs && isAuthenticated"
              aria-label="Contact Support"
              class="intercom-trigger q-px-sm"
              flat
              href="https://help.zubiecar.com/hc/en-us/requests/new"
              icon="contact_support"
              label="Get Help"
              no-wrap
              square
              target="_blank"
              type="a"
              @click="closeListDialog"
            />
          </div>
          <NotificationsButton v-if="isAuthenticated && canViewNotifications && $q.screen.gt.xs" class="q-ml-xs" />
          <q-btn aria-label="Menu" class="q-ml-sm" dense flat square @click.stop="onMenuClick">
            <NotificationsBadge
              v-if="isAuthenticated && canAccessRoute('/notifications') && !$q.screen.gt.xs && areRecentUnread"
            />
            <q-icon name="menu" />
          </q-btn>
        </div>
      </div>
    </div>
  </q-toolbar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PrimaryButton from 'components/buttons/PrimaryButton.vue';
import NotificationsBadge from 'components/notifications/NotificationsBadge.vue';
import NotificationsButton from 'components/notifications/NotificationsButton.vue';
import UniversalSearch from 'components/universal-search/UniversalSearch.vue';
import ZubieLogo from 'src/assets/zubie-logo.svg?component';
import mobileTitle from 'src/mixins/mobileTitle';
import { canAccessRoute } from 'src/services/router';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  mixins: [mobileTitle],
  components: { NotificationsBadge, NotificationsButton, PrimaryButton, UniversalSearch, ZubieLogo },
  data() {
    return {
      isLoadingPage: false,
      showDefaultLogo: false,
      showSearchOnMobile: false,
    };
  },
  computed: {
    ...mapGetters('notifications', ['areRecentUnread']),
    ...mapGetters('session', ['account', 'currentUser', 'hasPermission', 'isAuthenticated']),
    ...mapState('session', ['partner']),
    accountImgSrc() {
      if (this.account.key) {
        return `https://res.cloudinary.com/zubie/image/upload/f_png/if_ih_gt_36,h_36/accounts/${this.account.key}/logo`;
      }
      return '';
    },
    appName() {
      if (this.partner && this.partner.appName) {
        return this.partner.appName.replace('Zubie', '');
      }
      return '';
    },
    canViewNotifications() {
      return this.hasPermission('notificationsView');
    },
    isIndividual() {
      return this.currentUser.isIndividual();
    },
  },
  methods: {
    ...mapActions('app', ['toggleMainNav']),
    ...mapActions('map', ['closeListDialog']),
    canAccessRoute(path) {
      return canAccessRoute(path);
    },
    checkPage() {
      this.isLoadingPage = this.$route.name === 'loading';
    },
    onMenuClick() {
      this.toggleMainNav();
      this.closeListDialog();
    },
    async onSearchBlur() {
      await setTimeoutPromise(500); // Delay "hide" so events can propagate
      this.showSearchOnMobile = false;
    },
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.showDefaultLogo = true;
    }
    this.checkPage();
  },
  watch: {
    $route() {
      this.checkPage();
    },
  },
};
</script>

<style lang="scss" scoped>
.logo g {
  height: 100%;
}

.app-name {
  @media (max-width: $breakpoint-md) {
    display: none;
  }
}

.mobile-title {
  width: calc(100vw - 114px);
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 600px) {
    width: calc(100vw - 181px);
  }
}

:deep(.notification-badge) {
  left: 9px;
  bottom: 7px;
}
</style>

<style lang="scss">
.ios-notch-left .q-toolbar {
  padding-left: 0;
}

.ios-notch-right .q-toolbar {
  padding-right: 0;
}
</style>
