import _random from 'lodash/random';
import { TAGS_COLOR_PALETTE } from 'src/services/constants';

export function setTags(state, tags) {
  state.tags = tags;
}

export function setTagsLoading(state, value) {
  state.tagsLoading = value;
}

export function addTag(state, tag) {
  state.tags.push(tag);
}

export function clearTagBeingEdited(state) {
  const random = _random(TAGS_COLOR_PALETTE.length - 1);
  const randomColor = TAGS_COLOR_PALETTE[random];
  state.tagBeingEdited = {
    value: '',
    type: null,
    color: randomColor,
  };
}

export function setTagBeingEdited(state, tag) {
  state.tagBeingEdited = { ...tag };
}

export function setTagsError(state, error) {
  if (!error) {
    state.tagsError = {};
    state.tagsErrorMessage = false;
    return;
  }

  if (typeof error === 'object') {
    state.tagsError = error;
  } else {
    state.tagsErrorMessage = error;
  }
}

export function setSavingTag(state, savingTag) {
  state.savingTag = savingTag;
}

export function removeTag(state, tagKey) {
  state.tags = state.tags.filter((tag) => tag.key !== tagKey);
}
