import pinStoppedSvg from 'src/assets/pin-stopped.svg?raw';
import { COLOR_VALUES } from 'src/services/constants';
import SvgMarker from './SvgMarker';

export default class StaleSvgMarker extends SvgMarker {
  constructor() {
    super(pinStoppedSvg, {
      height: 91,
      width: 60,
      innerColor: COLOR_VALUES.WHITE,
      outerColor: COLOR_VALUES.DARK,
    });
  }
}
