import WebGeoLocation from './WebGeolocation';

/**
 * @property {GeoLocationHandler} geoLocationHandler
 */
export const config = {
  geoLocationHandler: WebGeoLocation,
};

/**
 * Gets the current GPS location of the device.
 *
 * @param {String} text
 * @returns {Promise<GeolocationPosition>}
 */
export function getCurrentPosition() {
  return config.geoLocationHandler.getCurrentPosition();
}

/**
 * Sets the geolocation handler.
 *
 * @param {GeoLocationHandler} handler
 */
export function setGeolocationHandler(handler) {
  config.geoLocationHandler = handler;
}
