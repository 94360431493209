<template>
  <q-btn
    class="add-button full-height"
    :color="color"
    icon="add"
    square
    text-color="primary"
    @click="$emit('click', $event)"
  >
    <ToolTip :offset="[0, 5]">Add New Asset Type</ToolTip>
  </q-btn>
</template>

<script>
import ToolTip from 'components/ToolTip.vue';

export default {
  name: 'AddButton',
  components: {
    ToolTip,
  },
  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
};
</script>
