import SimpleModel from './SimpleModel';

class Coupon extends SimpleModel {
  /** @type {String} */
  code = null;

  /** @type {String} */
  name = null;

  /** @type {Boolean} */
  isSingleUse = null;

  /** @type {Number} */
  discountPercent = null;

  /** @type {Number} */
  discountCents = null;

  /** @type {String} */
  discountCurrency = null;

  /** @type {String} */
  appliedAt = null;

  constructor(data) {
    super();
    this.insert(data);
  }
}

export default Coupon;
