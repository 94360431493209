<template>
  <q-avatar
    :class="{ border: tag.isVeryBright }"
    icon="label"
    :size="size"
    :style="{ 'background-color': tag.color }"
    :text-color="tag.isVeryBright ? 'grey-7' : 'white'"
  />
</template>

<script>
export default {
  name: 'TagAvatar',
  props: {
    size: {
      type: String,
      default: 'md',
    },
    tag: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.q-avatar.border {
  border: 1px solid $grey-4;
}
</style>
