import GeoJsonSource from 'src/services/geoJson/GeoJsonSource';

export default class NearbyLocationSource extends GeoJsonSource {
  /** @type {String} */
  static id = 'nearby-location';

  /** @type {Object[]} */
  features = [];

  /**
   * @returns {Object} A GeoJson source config object.
   */
  getConfig() {
    return Object.freeze({
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        id: this.getId(),
        features: this.features,
      },
    });
  }
}
