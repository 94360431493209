import Address from 'src/models/Address';
import { toDecimalPlaces } from 'src/services/number';
import SimpleModel from './SimpleModel';

export default class Trip extends SimpleModel {
  /** @type {string | null} */
  accountKey = null;

  /** @type {string | null} */
  deviceKey = null;

  /** @type {string | null} */
  distanceUm = null;

  /** @type {Distraction[]} */
  distractedDriving = [];

  /** @type {number | null} */
  durationSeconds = null;

  /** @type {string | null} */
  encodedPolyline = null;

  /** @type {TripPointEdge | null} */
  endPoint = null;

  /** @type {number | null} */
  fuelConsumed = null;

  /** @type {string | null} */
  fuelConsumedUm = null;

  /** @type {number | null} */
  fuelCost = null;

  /** @type {string | null} */
  fuelCostCurrencyCode = null;

  /** @type {string | null} */
  fuelCostCurrencySymbol = null;

  /** @type {number | null} */
  fuelPpg = null;

  /** @type {string | null} */
  fuelType = null;

  /** @type {number | null} */
  gpsDistance = null;

  /** @type {number} */
  hardAccelCount = null;

  /** @type {number} */
  hardBrakeCount = null;

  /** @type {number} */
  idleSeconds = null;

  /** @type {number} */
  idleStopSeconds = null;

  /** @type {string | null} */
  key = null;

  /** @type {Media[]} */
  media = [];

  /** @type {string | null} */
  notes = null;

  /** @type {number | null} */
  obdDistance = null;

  /** @type {number | null} */
  pointsCityCount = null;

  /** @type {number | null} */
  pointsHwyCount = null;

  /** @type {string | null} */
  speedUm = null;

  /** @type {number} */
  speedingCityMajorCount = null;

  /** @type {number} */
  speedingCityMinorCount = null;

  /** @type {number} */
  speedingHwyMajorCount = null;

  /** @type {number} */
  speedingHwyMinorCount = null;

  /** @type {TripPointEdge | null} */
  startPoint = null;

  /** @type {string | null} */
  staticMapUrl = null;

  /** @type {Tag[]} */
  tags = [];

  /** @type {number | null} */
  tripSegments = null;

  /** @type {number | null} */
  topSpeed = null;

  /** @type {TripUser | null} */
  user = null;

  /** @type {TripVehicle} */
  vehicle = {};

  /**
   * @param {TripData} data
   */
  constructor(data) {
    super();

    let endPoint = null;
    if (data.endPoint) {
      endPoint = {
        ...data.endPoint,
        address: new Address(data.endPoint.address),
      };
    }

    let startPoint = null;
    if (data.startPoint) {
      startPoint = {
        ...data.startPoint,
        address: new Address(data.startPoint.address),
      };
    }

    this.insert({
      ...data,
      endPoint,
      startPoint,
    });
  }

  get distance() {
    return this.gpsDistance;
  }

  get speeding() {
    const {
      speedingCityMajorCount = 0,
      speedingCityMinorCount = 0,
      speedingHwyMajorCount = 0,
      speedingHwyMinorCount = 0,
    } = this;
    return speedingCityMajorCount + speedingCityMinorCount + speedingHwyMajorCount + speedingHwyMinorCount;
  }

  /**
   * Title for this trip (primarily for events feed)
   */
  get title() {
    let title = '';

    if (this.distance) {
      title += `${toDecimalPlaces(this.distance, 2)} Mi`;
    }

    return `${title} Trip Complete`.trim();
  }
}
