import _snakeCase from 'lodash/snakeCase';
import router from 'src/router';
import errorService from 'src/services/error';
import { trackEvent } from 'src/services/intercom';
import { logout as performLogout } from 'src/services/logout';
import storage from 'src/services/storage';

/**
 * Indicates a Zubie dialog has been closed.
 *
 * @param {ActionContext} context
 */
export function decrementOpenZubieDialogCount(context) {
  let newOpenDialogCount = context.state.openDialogCount;
  if (newOpenDialogCount > 0) {
    newOpenDialogCount -= newOpenDialogCount;
  }
  context.commit('setOpenZubieDialogCount', newOpenDialogCount);
}

/**
 * Indicates a Zubie dialog has been opened.
 *
 * @param {ActionContext} context
 */
export function incrementOpenZubieDialogCount(context) {
  const newOpenDialogCount = context.state.openDialogCount + 1;
  context.commit('setOpenZubieDialogCount', newOpenDialogCount);
}

/**
 * @param {AppStoreActionContext} context
 * @param {Object} payload
 * @param {string} payload.username
 * @param {string} payload.password
 * @param {string} payload.invite
 */
export async function login(context, { username, password, invite }) {
  /** @type {{ data: ZAuthProfile; status: number } & { error: string; data: { message: string } }} */
  const result = await context.state.broker.fetchAndTransform({
    fn: 'logIn',
    params: {
      body: {
        username,
        password,
      },
    },
  });

  if (result.status === 401 || result.error) {
    throw new Error(result.data?.message || result.error || 'Access denied');
  }

  trackEvent('log_in');

  await context.dispatch('session/setProfile', result.data, { root: true });

  let routeName = 'default';

  // Handle invitations
  if (invite) {
    await context.dispatch('users/acceptInvite', invite.token, { root: true });
    routeName = 'inviteWelcome';
  }

  try {
    await router().push({ name: routeName });
  } catch (error) {
    // Route change was aborted (always aborts after redirect)
    if (error) {
      throw error;
    }
  }
}

/**
 * Logs user out of the app via the API and reloads the page.
 *
 * @param {AppStoreActionContext} context
 */
export async function logout() {
  trackEvent('log_out');
  await performLogout();
}

/**
 * Updates the stroed current account id and reloads the page to use the new account.
 *
 * @param {AppStoreActionContext} context
 * @param {String} accountId
 */
export async function switchAccount(_context, accountId) {
  trackEvent('switch_account');
  storage.set('session/accountId', accountId);
  storage.remove('session/redirectTo');
  window.location.reload();
}

/**
 * Sets the app's state to "ready".
 *
 * @param {AppStoreActionContext} context
 */
export function ready(context) {
  context.commit('ready');
}

/**
 * Sets the app's router ready state to "ready".
 *
 * @param {AppStoreActionContext} context
 */
export function routerReady(context) {
  context.commit('routerReady');
}

/**
 * Sets the app account settings dialog state to the given value.
 *
 * @param {AppStoreActionContext} context
 * @param {boolean} isShown
 */
export function setAccountSettingsDialogState(context, isShown) {
  context.commit('setAccountSettingsDialogState', isShown);
}

/**
 * Sets the app device info.
 *
 * @param {AppStoreActionContext} context
 * @param {import('@capacitor/core').DeviceInfo} deviceInfo
 */
export function setDeviceInfo(context, deviceInfo) {
  context.commit('setDeviceInfo', deviceInfo);
}

export function setDrawerOpen() {
  /**
   * This is a stub
   * - the action is handled in a boot file (Capacitor specific)
   */
}

/**
 * Sets the app Live Update info.
 *
 * @param {AppStoreActionContext} context
 * @param {{ buildId: number | null }} liveUpdateInfo
 */
export function setLiveUpdateInfo(context, liveUpdateInfo) {
  context.commit('setLiveUpdateInfo', liveUpdateInfo);
}

/**
 * Sets the main nav open state.
 *
 * @param {AppStoreActionContext} context
 * @param {boolean} isOpen
 */
export function setMainNavOpen(context, isOpen) {
  context.dispatch('setDrawerOpen', isOpen);
  context.commit('setMainNavOpen', isOpen);
}

/**
 * Sets mobile title.
 *
 * @param {AppStoreActionContext} context
 * @param {string} title
 */
export function setMobileTitle(context, title) {
  context.commit('setMobileTitle', title);
}

/**
 * Sets "mobile title pending" flag.
 *
 * @param {AppStoreActionContext} context
 * @param {boolean} title
 */
export function setMobileTitlePending(context, isPending) {
  context.commit('setMobileTitlePending', isPending);
}

/**
 * Sets the size of the iOS notch.
 *
 * @param {AppStoreActionContext} context
 * @param {number} size
 */
export function setNotchSizes(context, sizes) {
  context.commit('setNotchSizes', sizes);
}

/**
 * Sets the orientation of the device.
 *
 * @param {AppStoreActionContext} context
 * @param {number} orientation
 */
export function setOrientation(context, orientation) {
  context.commit('setOrientation', orientation);
}

/**
 * Toggles the main nav open state.
 *
 * @param {AppStoreActionContext} context
 */
export function toggleMainNav(context) {
  context.dispatch('setMainNavOpen', !context.state.mainNavOpen);
}

/**
 * Sets flags based on the type of route.
 * - e.g. filterable route, Live Map route
 *
 * @param {AppStoreActionContext} context
 * @param {import('vue-router').Router} router
 */
export function watchNavigation(context, router) {
  router.afterEach(({ matched }) => {
    const routeMatches = matched || [];

    const isAuthRoute = routeMatches.filter(({ meta: { auth } = {} }) => auth === true);
    if (routeMatches.length > 0 && isAuthRoute) {
      const routesToNotLog = ['default', 'loading'];
      const { name: currentName = '' } = routeMatches[routeMatches.length - 1];
      if (currentName && !routesToNotLog.includes(currentName)) {
        trackEvent(`pageview_${_snakeCase(currentName)}`);
      }
    }

    const isFilterRoute = routeMatches.some(
      ({ meta: { hasUniversalSearchFiltering } = {} }) => hasUniversalSearchFiltering
    );
    const isLiveMapRoute = routeMatches.some(({ path }) => path === '');

    const vehicleAssetPages = [
      'asset',
      'vehicle',
      'vehicleTrips',
      'vehicleHealth',
      'vehicleMaintenance',
      'assetLocations',
      'assetTrips',
    ];
    const isOnDetailsPage = routeMatches.some(({ name }) => vehicleAssetPages.includes(name));

    context.commit('setFilterableRoute', isFilterRoute);
    context.commit('setLiveMapRoute', isLiveMapRoute);
    context.commit('setIsOnDetailsPage', isOnDetailsPage);
  });
}

/**
 * Sets the app's workers ready state to "ready".
 *
 * @param {AppStoreActionContext} context
 */
export function workersReady(context) {
  context.commit('workersReady');
}

/**
 * Sets the app's IDB ready state to "ready".
 *
 * @param {ActionContext} context
 */
export function idbReady(context) {
  context.commit('idbReady');
}

/**
 * Displays the "polling error" notification.
 *
 * @param {AppStoreActionContext} context
 */
export function showPollingError(context) {
  if (context.state.pollingErrorDismiss) {
    return;
  }

  const dismiss = errorService.notify({
    message: 'Unable to refresh vehicles/assets',
    onDismiss() {
      dismiss();
      context.commit('clearPollingError');
    },
  });
  context.commit('pollingError', dismiss);
}

/**
 * Clears the "polling error" notification.
 *
 * @param {AppStoreActionContext} context
 */
export function clearPollingError(context) {
  if (context.state.pollingErrorDismiss) {
    context.state.pollingErrorDismiss();
    context.commit('clearPollingError');
  }
}

/**
 * Updates the observed height of the main header (mostly for page layout/positioning)
 *
 * @param {AppStoreActionContext} context
 * @param {number} height
 */
export function updateMainHeaderHeight(context, height) {
  context.commit('setMainHeaderHeight', height);
}
