<template>
  <MapboxControl
    class="map-legend"
    :class="{ condensed: !legendOpened, 'no-tooltips': !interactionsAllowed, hide: hidden }"
    custom-size
    position="bottom-left"
    @click="openLegend"
  >
    <div>
      <q-tooltip v-if="!legendOpened" anchor="top middle" :offset="[0, 8]" self="bottom middle"> Map Legend </q-tooltip>
      <q-btn
        class="minimize-button absolute z-index-5"
        flat
        icon="south_west"
        padding="sm"
        size="sm"
        text-color="grey-7"
        @click.stop="closeLegend"
      />
      <div class="legend-items column">
        <span
          v-for="(symbol, index) in symbols"
          :key="index"
          :class="`legend-item inline row items-center text-grey-7 ${symbol.statusClass}`"
        >
          <span :class="getClasses(symbol)" :style="getStyle(symbol)">
            <q-icon v-if="symbol.icon" class="sample-circle__icon flex" color="white" :name="symbol.icon" size="14px" />
          </span>
          <span class="legend-item__label">{{ symbol.label }}</span>
          <q-tooltip anchor="center right" content-class="text-caption" max-width="200px" self="center left">{{
            symbol.tooltip
          }}</q-tooltip>
        </span>
      </div>
    </div>
  </MapboxControl>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MapboxControl from 'components/map/MapboxControl.vue';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  props: {
    hidden: {
      type: Boolean,
      default: false,
    },
    symbols: Array,
  },
  components: {
    MapboxControl,
  },
  computed: {
    ...mapState('map', ['legendOpened']),
  },
  data() {
    return {
      interactionsAllowed: true,
    };
  },
  methods: {
    ...mapActions('map', ['closeLegend', 'openLegend']),
    getClasses(symbol) {
      const classes = ['sample-circle', 'row', 'justify-center', 'items-center', 'circle-radius'];

      if (symbol.bgColor) {
        classes.push(`bg-${symbol.bgColor}`);
      }

      return classes;
    },
    getStyle(symbol) {
      const styles = {};

      if (symbol.bgColorHex) {
        styles.backgroundColor = symbol.bgColorHex;
      }

      if (symbol.opacity) {
        styles.opacity = symbol.opacity;
      }

      return styles;
    },
  },
  created() {
    this.interactionsAllowed = this.legendOpened;
  },
  watch: {
    async legendOpened() {
      if (this.legendOpened) {
        await setTimeoutPromise(1000);
      }
      this.interactionsAllowed = this.legendOpened;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-legend {
  min-width: 108px;
  overflow: hidden;
  transition: all 0.5s ease-in;

  :deep(button) {
    &:hover {
      background: none;
    }

    @media (max-height: 390px) {
      max-height: 90px;
      overflow-y: auto;
    }
  }
}

.hide {
  opacity: 0;
}

.ios-notch-left {
  .map-legend {
    margin-left: env(safe-area-inset-left);
    margin-bottom: -20px;
  }
}

.fullscreen.ios-notch-left {
  .map-legend {
    margin-bottom: 10px;
  }
}

.minimize-button {
  top: -2px;
  right: -2px;
}

.legend-item {
  position: relative;
  z-index: 4;
  height: 30px;
  font-size: 13px;
  line-height: 1rem;
  transition: all 0.5s ease-in;

  &:nth-child(n + 4) {
    margin-top: 0;
    opacity: 1;
  }
}

.legend-item__label {
  opacity: 1;
  max-width: 100px;
  overflow: hidden;
  transition: opacity 0.3s ease-in, max-width 0.3s ease-in;
}

.no-tooltips {
  .legend-items {
    pointer-events: none;
  }
}

.condensed {
  min-width: 0;

  .minimize-button {
    display: none;
  }

  .legend-item {
    margin-right: -2px;

    &:nth-child(2) {
      z-index: 3;
      margin-left: 9px;
      margin-top: -30px;
    }

    &:nth-child(3) {
      z-index: 2;
      margin-left: 18px;
      margin-top: -30px;

      // Fix for 1px difference
      &.no-device,
      &.no-gps,
      &.powered-down {
        margin-top: -31px;
      }
    }

    &:nth-child(n + 4) {
      margin-top: -30px;
      opacity: 0;
    }
  }

  .legend-item__label {
    opacity: 0;
    max-width: 0;
  }
}

.sample-circle {
  height: 1rem;
  margin-right: 3px;
  width: 1rem;
}
</style>
