<template>
  <PrimaryInlineButton
    v-if="!compact"
    v-bind="$attrs"
    class="roboto"
    dense
    :padding="padding"
    :size="size"
    @click="showDialog = true"
  >
    <q-icon class="q-mr-xs" name="sms" /> Message Driver
    <slot />
  </PrimaryInlineButton>
  <PrimaryButton v-else v-bind="$attrs" dense icon="sms" :padding="padding" :size="size" @click="showDialog = true">
    <slot />
  </PrimaryButton>
  <DashcamMessageDialog v-if="showDialog" :vehicle-key="vehicleKey" @hide="showDialog = false" />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import PrimaryButton from 'components/buttons/PrimaryButton.vue';
import PrimaryInlineButton from 'components/buttons/PrimaryInlineButton.vue';
import DashcamMessageDialog from 'components/dashcam/DashcamMessageDialog.vue';

withDefaults(defineProps<{ compact?: boolean; padding?: string; size?: string; vehicleKey: string }>(), {
  padding: 'xs sm 2px',
  size: undefined,
});

const showDialog = ref(false);
</script>
