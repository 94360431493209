import _camelCase from 'lodash/camelCase';
import { statuses } from 'src/services/constants';
import { getHeading, getStatus } from 'src/services/vehicles';

/**
 * Retrieves the GeoJSON marker status and heading.
 *
 * @param {Object} data
 * @param {Number} data.batteryVolts
 * @param {Object[]} data.devices
 * @param {Boolean} data.isConnected
 * @param {String} data.key
 * @param {Object} data.lastTrip
 * @param {Object} data.location
 * @param {String} data.majorType
 * @returns {Object}
 */
export function getHeavyGeoJson(data) {
  const { location } = data;

  // Get vehicle heading & status
  const markerStatus = getMarkerStatus.call(data);
  return {
    heading: getHeading(location),
    icon: `marker-${markerStatus}`,
    motionStatus: markerStatus,
  };
}

/**
 * Returns the marker status based on the bound data.
 *
 * @returns {String}
 */
export function getMarkerStatus() {
  /** @type {import('src/models/Vehicle').default} */
  const { location = {} } = this;
  const { heading, speedMph } = location;
  const status = getStatus.call(this);

  if (status === statuses.MOVING && speedMph === 0) {
    return 'idle';
  }

  if (status === statuses.MOVING && !Number.isFinite(heading)) {
    return 'movingNoHeading';
  }

  return _camelCase(status) || null;
}
