import { convertDistance } from 'src/services/locale';
import SimpleModel from './SimpleModel';

class MaintenanceReminder extends SimpleModel {
  props() {
    return {
      category: null,
      created: null,
      description: null,
      key: null,
      lastServicedDate: null,
      lastServicedMiles: null,
      maintenanceActionKey: null,
      milesInterval: null,
      monthsInterval: null,
      nextServiceDate: null,
      nextServiceMiles: null,
      offerCategoryId: null,
      summary: null,
      updated: null,
    };
  }

  get editable() {
    return Boolean(this.key && this.key !== 'miscellaneous');
  }

  get lastServicedOdometer() {
    return Math.round(convertDistance(this.lastServicedMiles, 'mi'));
  }

  get loggable() {
    return this.show;
  }

  get nextServiceOdometer() {
    return Math.round(convertDistance(this.nextServiceMiles, 'mi'));
  }

  get show() {
    return Boolean(this.key);
  }
}

export default MaintenanceReminder;
