<template>
  <div
    class="search-type-chooser__container relative full-height"
    :class="{ 'in-focus': hasFocus, 'keyword-only': isKeywordOnly }"
  >
    <q-btn-group class="search-type-chooser full-height" :class="{ 'bg-silver': hasFocus }">
      <q-btn
        class="search-type__button full-height"
        flat
        icon="filter_list"
        padding="xs sm"
        square
        text-color="dark"
        @click="toggleSearchType"
      >
        <q-tooltip content-class="text-body2">{{ tooltipFilter }}</q-tooltip>
      </q-btn>
      <q-btn
        class="search-type__button full-height"
        flat
        icon="search"
        padding="xs sm"
        square
        text-color="dark"
        @click="toggleSearchType"
      >
        <q-tooltip content-class="text-body2">{{ tooltipKeyword }}</q-tooltip>
      </q-btn>
    </q-btn-group>
    <q-btn
      class="search-type-chooser__highlight absolute-left full-height bg-info"
      :class="{
        // 'bg-info': !hasFocus,
        // 'bg-info': hasFocus,
        'filter--selected': isFilter,
        'keyword--selected': !isFilter,
        'cursor-default': isKeywordOnly,
      }"
      color="dark"
      flat
      :icon="isFilter ? 'filter_list' : 'search'"
      padding="xs sm"
      :ripple="!isKeywordOnly"
      square
      @click="toggleSearchType"
    >
      <q-tooltip content-class="text-body2">{{ isFilter ? tooltipFilter : tooltipKeyword }}</q-tooltip>
    </q-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { SEARCH_TYPES } from 'src/services/constants';
import { trackEvent } from 'src/services/intercom';

export default {
  name: 'SearchTypeToggle',
  props: {
    hasFocus: Boolean,
    hideLabel: Boolean,
  },
  data() {
    return {
      isResizing: false,
      userToggledClosed: false,
      menuShown: false,
      menuWillShow: false,
      showLabel: false,
      tooltipFilter: 'Find in the list on this page',
      tooltipKeyword: 'Search the vehicles, users, places, etc. in your account',
    };
  },
  computed: {
    ...mapState('app', ['isFilterRoute']),
    ...mapGetters('search', ['searchType']),
    defaultButtonTextColor() {
      return this.hasFocus ? 'dark' : 'white';
    },
    isKeywordOnly() {
      return !this.isFilterRoute;
    },
    isFilter() {
      return this.searchType === SEARCH_TYPES.FILTER;
    },
  },
  methods: {
    ...mapActions('search', ['setSearchType']),
    async toggleSearchType() {
      if (this.isKeywordOnly) {
        this.$emit('not-allowed');
        return;
      }
      const newSearchType = this.isFilter ? SEARCH_TYPES.KEYWORD : SEARCH_TYPES.FILTER;
      await this.setSearchType(newSearchType);

      const eventName = newSearchType === SEARCH_TYPES.KEYWORD ? 'search_everything' : 'filter_page';
      trackEvent(`switched_to_${eventName}`, { page: this.$route.name });

      this.$emit('change');
    },
  },
};
</script>

<style lang="scss" scoped>
.search-type-chooser__container {
  min-width: 40px;
}

.search-type-chooser {
  border: 1px solid transparent;
  display: flex;
  max-width: 82px;
  overflow: hidden;
  opacity: 1;
  box-shadow: none;
}

.search-type-chooser__highlight {
  transition: all 0.4s ease;
}

.filter--selected {
  transform: translateX(0);
}

.keyword--selected {
  transform: translateX(calc(100% + 2px)); // 1px for border
}

.keyword-only .keyword--selected {
  transform: translateX(0);
}

.keyword-only {
  .search-type-chooser {
    max-width: 0;
    opacity: 0;
  }
}

.in-focus {
  .search-type-chooser {
    border: 1px solid $silver;
  }

  &.keyword-only {
    .search-type-chooser {
      border-color: transparent;
    }
  }
}

:deep(.q-btn__wrapper) {
  min-height: 0;
  padding: map-get($space-xs, 'y') map-get($space-sm, 'x');
}

:deep(.q-tooltip) {
  font-size: 1.2em;
}
</style>
