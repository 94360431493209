import { Platform } from 'quasar';
import storage from 'src/services/storage';

export default () => {
  let readyResolve;
  const onReady = new Promise((resolve) => {
    readyResolve = resolve;
  });

  storage.onReady(
    /** @param {import('src/types/store').RootStore} store **/ (store) => {
      let checkInType = storage.get('session/checkInType');
      if (!Platform.is.mobile) {
        checkInType = 'MANUAL';
      }
      store.dispatch('session/setCheckInType', checkInType || 'MANUAL');

      if (!store.state.session.partner.appName) {
        store.commit('session/partner', {
          ...store.state.session.partner,
          appName: storage.get('session/partnerName'),
        });
      }
    }
  );

  return {
    /** @type {AccountData} */
    account: {},
    accountPromise: null,
    accounts: [],
    billingErrors: {},
    /** @type {'DRIVER_ID' | 'MANUAL'} */
    checkInType: 'MANUAL',
    coupon: null,
    couponLoading: false,
    currentUser: {},
    currentUserPromise: null,
    /** @type {boolean | null} */
    hasDashcam: null,
    isLoadingDriverId: false,
    isLoadingFleet: false,
    isReady: false,
    onReady,
    /** @type {import('src/types/session-store').PartnerData} */
    partner: {},
    partnerPromise: null,
    permissions: {},
    permissionsPromise: null,
    profile: {},
    profilePromise: null,
    readyResolve,
  };
};
