/**
 * Updates the billing information in Recurly and uses the token to update the billing info in our business systems.
 *
 * @param {HTMLElement} recurlyForm
 * @returns {Promise<String>} Token returned by recurly after submitting the form
 */
export async function updateRecurly(recurlyForm) {
  return new Promise((resolve, reject) => {
    window.recurly.token(recurlyForm, (error, token) => {
      if (error) {
        reject(error);
        return;
      }

      resolve(token.id);
    });
  });
}
