<template>
  <MapboxControl
    ref="control"
    class="map-search-control"
    :class="{ 'in-focus': isInFocus }"
    custom-size
    position="top-right-secondary"
    tooltip="Search for a location / point of interest"
  >
    <div class="map-search-control__content row justify-center items-center">
      <q-icon class="text-weight-bold" color="dark" name="search" size="20px" @click="onClick" />
      <div ref="terms" class="map-search-control__terms-display absolute no-pointer-events">{{ terms }}</div>
      <div ref="inputWrapper" class="map-search-control__input-wrapper">
        <q-input
          ref="input"
          v-model="terms"
          autofucus
          borderless
          class="full-width cursor-pointer"
          clearable
          dense
          placeholder="Search for a location / point of interest"
          square
          standout="bg-grey-4 text-white"
          type="search"
          @blur="onBlur"
          @clear="onClear"
          @focus="onFocus"
          @keydown="onKeyDown"
          @update:model-value="searchMap"
        />
        <SearchControlResults :show="isInFocus" />
      </div>
    </div>
  </MapboxControl>
</template>

<script>
import _debounce from 'lodash/debounce';
import { mapActions, mapState } from 'vuex';
import MapboxControl from 'components/map/MapboxControl.vue';
import SearchControlResults from 'components/map/search/SearchControlResults.vue';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  name: 'SearchControl',
  components: {
    MapboxControl,
    SearchControlResults,
  },
  computed: {
    ...mapState('map', ['matchesIdealBounds', 'selectedSearchResultIndex', 'searchResultsLoading', 'searchTerms']),
    termsHaveChanged() {
      return this.termsTrimmed !== this.previousTerms;
    },
    termsTrimmed() {
      return (this.terms || '').trim();
    },
  },
  data() {
    return {
      isHiding: false,
      isInFocus: false,
      previousTerms: '',
      terms: '',
      wasClearedOnMobile: false,
    };
  },
  methods: {
    ...mapActions('map', [
      'addressSearch',
      'clearSearchResults',
      'selectSearchResult',
      'setSearchResultsLoading',
      'setSelectedSearchResultIndex',
    ]),
    async onBlur() {
      if (this.wasClearedOnMobile) {
        this.$refs.input.focus();
        return;
      }

      this.isHiding = true;
      await setTimeoutPromise(300); // let input hide animation finish
      this.isInFocus = false;
      this.isHiding = false;
      this.$refs.control.$el.querySelector('button').focus();
    },
    onClear() {
      this.searchMap();
      if (this.$q.platform.is.mobile) {
        // Fix issue with "clear" event triggering "blur" on mobile
        this.wasClearedOnMobile = true;
      }
    },
    async onClick() {
      if (!this.isInFocus) {
        this.$refs.input.focus();
      }
    },
    onFocus() {
      this.isInFocus = true;
      this.wasClearedOnMobile = false;
      this.setControlWidth(this.$q.screen.gt.md ? '300px' : 'calc(100vw - 133px)');
      this.searchMap();
    },
    onKeyDown(event) {
      if (!this.termsHaveChanged && !this.searchResultsLoading) {
        switch (event.key) {
          case 'Enter':
            this.selectSearchResult(this.selectedSearchResultIndex);
            this.$refs.input.blur();
            break;
          case 'ArrowDown':
            this.setSelectedSearchResultIndex(this.selectedSearchResultIndex + 1);
            event.preventDefault();
            break;
          case 'ArrowUp':
            this.setSelectedSearchResultIndex(this.selectedSearchResultIndex - 1);
            event.preventDefault();
            break;
          default:
        }
      }
    },
    reset() {
      this.previousTerms = '';
      this.clearSearchResults();
    },
    async searchMap() {
      if (!this.termsHaveChanged) {
        return null;
      }

      this.previousTerms = this.termsTrimmed;

      if (!this.termsTrimmed) {
        this.reset();
        return null;
      }

      this.setSearchResultsLoading(true);
      this.clearSearchResults();

      return this.debouncedAddressSearch(this.termsTrimmed);
    },
    searchFromStore() {
      if (this.searchTerms) {
        this.onClick();
        this.terms = this.searchTerms;
        this.searchMap();
      }
    },
    setControlWidth(maxWidth) {
      this.$refs.inputWrapper.style.maxWidth = maxWidth;
    },
  },
  mounted() {
    this.setControlWidth(0);
  },
  created() {
    this.debouncedAddressSearch = _debounce((terms) => this.addressSearch(terms), 500);
  },
  watch: {
    isInFocus() {
      if (!this.isInFocus) {
        const maxWidth = (this.terms || '').trim() ? this.$refs.terms.offsetWidth : 0;
        this.setControlWidth(`${maxWidth}px`);
      }
    },
    searchTerms() {
      this.searchFromStore();
    },
  },
};
</script>

<style lang="scss" scoped>
.map-search-control__content {
  height: auto;
}

.map-search-control__input-wrapper {
  overflow: hidden;
  width: 300px;
  transition: all 0.3s ease-out;
}

.map-search-control__terms-display {
  right: 6px;
  padding: 0 7px 0 16px;
  font-size: 14px;
  opacity: 0;
}

:deep(button) {
  padding: 5px 4px 4px 5px;
}

:deep(.q-field__control) {
  height: auto;
  margin: 0;
  padding: 0;
}

:deep(.q-field__native) {
  padding: 2px 0;
  line-height: 16px;
  transition: padding 0.4s ease;
  pointer-events: none;
}

:deep(.q-field__append) {
  height: auto;
  padding-left: 0;

  button {
    height: auto;
    padding: 0;
    width: auto;
    line-height: 1;
    font-size: 16px;
  }

  .q-spinner {
    height: 16px;
  }
}

.in-focus {
  :deep(.q-field__native) {
    padding: 2px 3px;
    pointer-events: all;
  }

  :deep(.map-search-control__input-wrapper) {
    margin-left: 4px;
  }
}
</style>
