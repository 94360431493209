<template>
  <div class="bg-white">
    <div class="header row q-col-gutter-sm q-mb-md q-px-md q-pt-md no-wrap">
      <div
        class="start-end-container col flex items-center justify-center q-gutter-sm"
        :class="{ 'q-pa-none': !$q.screen.gt.xs, 'q-ma-none': !$q.screen.gt.xs }"
      >
        <q-input v-model="zFromUs" class="start-end__input" dense label="From" mask="##-##-####" outlined>
          <template #prepend>
            <q-icon color="primary" name="calendar_today" />
          </template>
        </q-input>
        <div class="start-end__separator">
          <hr style="width: 10px" />
        </div>
        <!-- TODO: from and to should only be from past to future -->
        <q-input
          ref="dateTo"
          v-model="zToUs"
          class="start-end__input"
          :class="{ 'animate__animated animate__fadeIn': emphasizeEndDate }"
          dense
          label="To"
          mask="##-##-####"
          outlined
        >
          <template #prepend>
            <q-icon color="primary" name="calendar_today" />
          </template>
        </q-input>
      </div>
    </div>
    <q-separator />
    <div :class="{ col: !$q.screen.gt.xs, row: $q.screen.gt.xs }">
      <div class="col-auto">
        <q-list
          class="time-frames"
          :class="{ row: !$q.screen.gt.xs, 'q-my-sm': !$q.screen.gt.xs, 'q-my-md': $q.screen.gt.xs }"
          dense
        >
          <q-item
            v-for="(timeFrame, key) in timeFrames"
            :id="key"
            :key="key"
            :active="activeItemSelection === key"
            class="time-frame col-6"
            clickable
            @click="setRange(key)"
          >
            <q-item-section>{{ timeFrame.label }}</q-item-section>
          </q-item>
        </q-list>
      </div>
      <div class="col-auto">
        <q-separator class="calendar__separator" :vertical="$q.screen.gt.xs" />
      </div>
      <div class="col column">
        <div class="col">
          <q-date
            v-model="calendarRange"
            class="full-width calendar"
            :event-color="eventColor"
            :events="events"
            flat
            mask="YYYY-MM-DD"
            minimal
            :navigation-max-year-month="navigationLimits.max"
            :navigation-min-year-month="navigationLimits.min"
            :options="options"
            range
            @navigation="
              $emit('navigation', $event);
              emphasizeEndDate = false;
            "
            @range-end="
              $emit('range-end', $event);
              emphasizeEndDate = false;
            "
            @range-start="
              $emit('range-start', $event);
              emphasizeEndDate = true;
            "
            @update:model-value="onRangeSelected"
          />
        </div>
        <div :class="{ 'fixed-bottom': $q.screen.xs }">
          <q-separator class="q-mb-md" />
          <div class="row items-center q-mb-md q-ml-md">
            <div v-if="emphasizeEndDate" class="col-auto animate__animated animate__fadeIn">
              <q-icon color="info" name="info" size="sm" /> Please choose an end date
            </div>
            <div v-else class="col-auto">Range: {{ range }}</div>
            <div class="col">
              <slot />
            </div>
            <div v-if="!$q.screen.gt.xs">
              <CancelButton v-close-popup class="q-px-sm q-mr-md" label="Close" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import _isEqual from 'lodash/isEqual';
import _startCase from 'lodash/startCase';
import { todayStart, todayEnd } from 'src/services/date';

export default {
  props: {
    eventColor: {
      type: String,
      default: 'positive',
    },
    events: {
      type: Function,
      default: () => false,
    },
    navigationLimits: {
      type: Object,
      default() {
        const format = 'YYYY/MM';
        return {
          max: todayStart().format(format),
          min: todayStart().subtract(2, 'year').format(format),
        };
      },
    },
    options: {
      type: Function,
      default: () => true,
    },
    modelValue: {
      default: () => [todayStart(), todayEnd()],
      validator(input) {
        if (input) return Array.isArray(input) && input.length === 2;
        return true;
      },
    },
  },
  data() {
    const from = dayjs(this.modelValue[0]).format('YYYY-MM-DD');
    const to = dayjs(this.modelValue[1]).format('YYYY-MM-DD');

    return {
      emphasizeEndDate: false,
      zFrom: from,
      zTo: to,
      calendarRange: {
        from,
        to,
      },
      timeFrames: {
        today: { from: todayStart(), to: todayEnd() },
        yesterday: { from: todayStart().add(-1, 'day'), to: todayEnd().add(-1, 'day') },
        thisWeek: { from: todayStart().startOf('w'), to: todayEnd() },
        lastWeek: {
          from: todayStart().add(-1, 'w').startOf('w'),
          to: todayEnd().add(-1, 'w').endOf('w'),
        },
      },
    };
  },
  computed: {
    activeItemSelection() {
      const from = dayjs(this.zFrom, 'YYYY-MM-DD');
      const to = dayjs(this.zTo, 'YYYY-MM-DD');

      const matches = Object.entries(this.timeFrames).find(
        ([key]) => to.isSame(this.timeFrames[key].to, 'date') && from.isSame(this.timeFrames[key].from, 'date')
      );

      const active = matches ? matches[0] : '';

      return active;
    },
    timeFrameLabels() {
      return Object.keys(this.timeFrames).map((timeFrame) => ({
        label: _startCase(timeFrame),
        key: timeFrame,
      }));
    },
    range() {
      const days = Math.abs(dayjs(this.zFrom, 'YYYY-MM-DD').diff(this.zTo, 'day')) + 1;
      return `${days} ${days === 1 ? 'Day' : 'Days'}`;
    },
    zFromUs: {
      get() {
        return dayjs(this.zFrom, 'YYYY-MM-DD').format('MM-DD-YYYY');
      },
      set(date) {
        if (dayjs(date, 'MM-DD-YYYY').isValid()) this.zFrom = dayjs(date).format('YYYY-MM-DD');
      },
    },
    zToUs: {
      get() {
        return dayjs(this.zTo, 'YYYY-MM-DD').format('MM-DD-YYYY');
      },
      set(date) {
        if (dayjs(date, 'MM-DD-YYYY').isValid()) this.zTo = dayjs(date).format('YYYY-MM-DD');
      },
    },
    startEndDates() {
      const dates = [];
      if (this.zFromUnix !== false && this.zToUnix !== false) {
        if (this.zFromUnix <= this.zToUnix) {
          dates.push(this.zFrom, this.zTo);
        } else {
          dates.push(this.zTo, this.zFrom);
        }
      }
      return dates;
    },
    tomorrow() {
      return todayEnd().add(1, 'day').format('YYYY-MM-DD');
    },
    zFromUnix() {
      if (this.zFrom !== '') {
        return dayjs(this.zFrom, 'YYYY-MM-DD').unix();
      }
      return false;
    },
    zToUnix() {
      if (this.zTo !== '') {
        return dayjs(this.zTo, 'YYYY-MM-DD').unix();
      }
      return false;
    },
  },
  watch: {
    modelValue() {
      if (
        _isEqual(
          this.modelValue.map((d) => dayjs(d).format('YYYY-MM-DD')),
          this.startEndDates
        )
      ) {
        return;
      }
      const from = dayjs(this.modelValue[0]).format('YYYY-MM-DD');
      const to = dayjs(this.modelValue[1]).format('YYYY-MM-DD');

      this.zFrom = from;
      this.zTo = to;
    },
  },
  created() {
    Object.keys(this.timeFrames).forEach((timeFrame) => {
      this.timeFrames[timeFrame].label = _startCase(timeFrame);
    });
  },
  methods: {
    onRangeSelected(value) {
      if (value === null) {
        return;
      }

      const { from, to } = typeof value === 'string' ? { from: value, to: value } : value;

      this.zFrom = from;
      this.zTo = to;

      const startEndISO = [
        dayjs(from, 'YYYY-MM-DD').startOf('day').toISOString(),
        dayjs(to, 'YYYY-MM-DD').endOf('day').toISOString(),
      ];

      this.$emit('update:model-value', startEndISO);
    },
    setRange(when) {
      const doWhen = this.timeFrames[when];
      this.onRangeSelected({
        from: doWhen.from.format('YYYY-MM-DD'),
        to: doWhen.to.format('YYYY-MM-DD'),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  max-width: 410px;
}

.header {
  margin: 0 0 map-get($space-sm, 'y');
  padding: 0;

  @media (min-width: $breakpoint-xs) {
    margin: -8px 0 map-get($space-md, 'y') -8px;
    padding: map-get($space-md, 'y') map-get($space-md, 'x') 0 map-get($space-md, 'x');
  }
}

.start-end__input {
  margin-left: 0;
  width: 116px;

  @media (min-width: $breakpoint-xs) {
    margin-left: map-get($space-sm, 'x');
    width: 140px;
  }
}

.animate__animated {
  --animate-duration: 1s !important;
}

.start-end__separator {
  margin: map-get($space-sm, 'y') map-get($space-xs, 'x') 0;

  @media (min-width: $breakpoint-xs) {
    margin: map-get($space-sm, 'y') 0 0 map-get($space-sm, 'x');
  }
}

:deep(.time-frame:nth-child(2n-1)) {
  text-align: right;
}

.calendar__separator {
  width: 100%;

  @media (min-width: $breakpoint-xs) {
    height: 100%;
    width: 1px;
  }
}

/* range start/end field control */
:deep(.start-end-container .q-field__control) {
  padding: 0 5px;

  @media (min-width: $breakpoint-xs) {
    padding: 0 12px;
  }
}

:deep(.q-date__view) {
  min-height: 0;
}

:deep(.fixed-bottom) {
  padding-bottom: env(safe-area-inset-bottom) !important;
}
</style>
