<template>
  <q-input
    ref="input"
    v-model="terms"
    :autofocus="autofocus"
    bg-color="white"
    :class="{
      'full-width': true,
      'keyword-only': !isFilterRoute,
    }"
    clearable
    color="transparent"
    data-1p-ignore
    dense
    input-class="q-pl-sm"
    :label="!(inputHasFocus || trimmedTerms) ? label : undefined"
    :placeholder="label"
    square
    @blur="onBlur"
    @clear="onClear"
    @click="$emit('click', trimmedTerms)"
    @focus="onFocus"
    @keydown="$emit('keydown', $event)"
    @update:model-value="$emit('update:model-value', trimmedTerms)"
  >
    <template #prepend>
      <SearchTypeToggle
        ref="searchTypeToggle"
        :has-focus="inputHasFocus"
        :hide-label="Boolean(trimmedTerms)"
        :keyword-only="!isFilterRoute"
        @change="onSearchTypeChange"
        @not-allowed="$refs.input.focus()"
      />
    </template>
  </q-input>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SearchTypeToggle from 'components/universal-search/SearchTypeToggle.vue';
import Asset from 'src/models/Asset';
import Vehicle from 'src/models/Vehicle';
import { SEARCH_TYPES } from 'src/services/constants';

export default {
  components: {
    SearchTypeToggle,
  },
  props: {
    autofocus: Boolean,
  },
  emits: ['blur', 'clear', 'click', 'focus', 'keydown', 'update:model-value'],
  computed: {
    ...mapState('app', ['isFilterRoute', 'isLiveMapRoute']),
    ...mapState('assets', ['assets']),
    ...mapState('map', ['listDialogOpen', 'showPlaces']),
    ...mapState('places', ['places']),
    ...mapState('search', ['filterTerms', 'history', 'searchedTerms', 'searchItems']),
    ...mapState('users', ['users']),
    ...mapState('vehicles', ['vehicles']),
    ...mapGetters('app', ['isZubieDialogOpen']),
    ...mapGetters('assets', ['vehiclesByDriver']),
    ...mapGetters('places', ['hiddenPlacesByKey']),
    ...mapGetters('search', ['searchType']),
    onLiveMap() {
      return ['map', 'map-selected'].includes(this.$route.name);
    },
    asset() {
      return this.assets[this.$route.params.key] || new Asset();
    },
    filterLabel() {
      return 'Find on this page';
    },
    isAsset() {
      return this.$route.matched?.[0]?.path?.startsWith('/asset/');
    },
    isFilter() {
      return this.searchType === SEARCH_TYPES.FILTER;
    },
    isVehicle() {
      return this.$route.matched?.[0]?.path?.startsWith('/vehicle/');
    },
    keywordType() {
      return SEARCH_TYPES.KEYWORD;
    },
    label() {
      return this.isFilter ? this.filterLabel : 'Search vehicles, assets, drivers, places...';
    },
    trimmedTerms() {
      return (this.terms || '').trim();
    },
    vehicle() {
      return this.vehicles[this.$route.params.key] || new Vehicle();
    },
  },
  data() {
    return {
      inputHasFocus: false,
      terms: '',
      wasClearedOnMobile: false,
    };
  },
  methods: {
    ...mapActions('filtering', ['filterByTerm']),
    ...mapActions('map', ['closeListDialog', 'focusOnCoords', 'togglePlaces']),
    ...mapActions('map', {
      setMapSearchTerms: 'setSearchTerms',
    }),
    ...mapActions('search', [
      'mapPlacesSearch',
      'prepareMapForSearch',
      'saveHistory',
      'setSearchItems',
      'setSearchType',
      'updateSearchTerms',
    ]),
    ...mapActions('assets', ['selectAsset']),
    // Universal search keyboard shortcut
    keyPressed(event) {
      if (event.key === 'k' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault(); // Prevent default browser shortcut from being triggered
        if (!this.isZubieDialogOpen) {
          if (document.activeElement === this.$refs?.input?.getNativeElement()) {
            this.$refs?.searchTypeToggle?.toggleSearchType();
            return;
          }
          this.$refs.input.focus();
        }
      }
    },
    async onBlur() {
      if (this.wasClearedOnMobile) {
        this.refocus();
        return;
      }

      this.inputHasFocus = false;
      this.$emit('blur');
    },
    async onClear() {
      this.terms = ''; // clearing sets terms to null, but it needs to be a string
      if (this.$q.platform.is.mobile) {
        // Fix issue with "clear" event triggering "blur" on mobile
        this.wasClearedOnMobile = true;
      }
      this.$emit('clear');
    },
    onFocus() {
      this.inputHasFocus = true;
      this.wasClearedOnMobile = false;

      if (this.isFilter && this.isLiveMapRoute) {
        this.prepareMapForSearch();
      }

      this.$emit('focus', this.trimmedTerms);
    },
    onSearchTypeChange() {
      this.$refs.input.focus();
      this.onFocus(); // not triggered from type change
    },
    refocus() {
      this.$refs.input.focus();
    },
  },
  // Leaving keyListener in created & destroyed for now because beforeUnmount isn't being called.
  created() {
    this.keyListener = this.keyPressed.bind(this);
    document.addEventListener('keydown', this.keyListener);
  },
  unmounted() {
    document.removeEventListener('keydown', this.keyListener);
  },
  watch: {
    filterTerms(terms) {
      if (this.isFilterRoute && this.isFilter) {
        this.terms = terms || '';
      }
    },
    $route(route, oldRoute) {
      const rootPath = route?.matched?.[0]?.path;
      const previousRootPath = oldRoute?.matched?.[0]?.path;
      if (rootPath && rootPath !== previousRootPath) {
        this.terms = '';
      }
    },
    searchedTerms() {
      if (this.trimmedTerms !== this.searchedTerms) {
        this.terms = this.searchedTerms;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$font-size: 15px;

:deep(.q-field__prepend),
:deep(.q-field__append) {
  height: auto;
}

:deep(.q-field__prepend) {
  margin: 2px 0 2px 2px;
  padding-right: 0;
}

:deep(.q-field__append) {
  padding-right: map-get($space-sm, 'x');

  .q-icon {
    color: $grey-7;
  }
}

:deep(.q-field__native) {
  padding: 0 map-get($space-sm, 'x') !important;
  font-size: $font-size;
  color: $grey-7;
}

:deep(.q-field__control) {
  margin-left: 1px;
  border: 1px solid $grey-7;
  border-radius: 28px;
  height: auto !important;
  transition: border-radius 0.4s ease;

  &:hover {
    border-color: $silver;

    &:before {
      border: 0;
    }
  }

  &:after {
    display: none;
  }
}

:deep(.q-field__label) {
  top: 50%;
  padding-left: map-get($space-sm, 'x');
  padding-right: map-get($space-sm, 'x');
  transform: translateY(-50%);
  color: $grey-7;
  transition: 0.4s opacity ease;
  font-size: $font-size;
}

.q-field--focused {
  :deep(.q-field__control) {
    margin-left: 0;
    border-width: 2px;
    border-color: $silver;
    // border-radius: 8px;
    // background: $grey-3 !important;

    &:before {
      border: 0;
    }
  }

  :deep(.q-field__label) {
    opacity: 0;
    transform: translateY(-50%);
  }

  :deep(.q-field__native) {
    color: black;
  }
}
</style>
