import _isEmpty from 'lodash/isEmpty';
import { ASSET_TYPES, ASSET_ICONS } from 'src/services/constants';
import { celsiusToFahreheit, fahreheitToCelsius } from 'src/services/locale';
import { getStatus } from 'src/services/vehicles';
import Address from './Address';
import AssetType from './AssetType';
import Device from './Device';
import GeoJsonFeature from './GeoJsonFeature';
import SimpleModel from './SimpleModel';

class Asset extends SimpleModel {
  /** @type {Number} */
  ambientTemperature = null;

  /** @type {String} */
  assetTypeKey = null;

  /** @type {String} */
  deviceKey = null;

  /** @type {Device[]} */
  devices = [];

  /** @type {Device} */
  firstConnectedDevice = new Device();

  /** @type {GeoJsonFeature} */
  geoJson = null;

  /** @type {String[]} */
  groupKeys = [];

  /** @type {Array} */
  groups = [];

  /** @type {String} */
  imageUrl = null;

  /** @type {Boolean} */
  isConnected = null;

  /** @type {Boolean} */
  isMappable = null;

  /** @type {Boolean} */
  isOutOfBounds = null;

  /** @type {Boolean} */
  isVisible = null;

  /** @type {String} */
  key = null;

  /** @type {Address} */
  lastAddress = new Address();

  /** @type {Object} */
  location = {};

  /** @type {String} */
  nickname = null;

  /** @type {String[]} */
  tagKeys = [];

  /** @type {Array} */
  tags = [];

  /** @type {AssetType} */
  type = new AssetType();

  /** @type {String} */
  majorType = ASSET_TYPES.ASSET;

  constructor(data = {}) {
    super();

    if (_isEmpty(data)) {
      return;
    }

    const {
      firstConnectedDevice = {},
      groupKeys = [],
      groups = [],
      devices = [],
      customImageUrl,
      lastAddress,
      location = {},
      tagKeys = [],
      tags = [],
      type,
    } = data;

    this.insert({
      ...data,
      devices: devices.map((device) => new Device(device)),
      firstConnectedDevice: new Device(firstConnectedDevice || devices[0] || {}),
      groupKeys,
      groups,
      imageUrl: customImageUrl,
      lastAddress: new Address(lastAddress),
      location,
      tagKeys,
      tags,
      type: type || new AssetType(),
    });

    // Update GeoJSON after data is inserted
    if (!_isEmpty(location)) {
      this.geoJson = new GeoJsonFeature([location.longitude, location.latitude], {
        key: this.key,
        nickname: this.nickname || 'Unnamed Asset',
        tagColor: this.firstTagColor,
        icon: this.iconId,
        majorType: this.majorType,
      });
    }
  }

  /**
   * Returns the first tag color that matches the tag presets.
   *
   * @returns {String} Tag hex color value or "none".
   */
  get firstTagColor() {
    return this.tags?.[0]?.color || 'none';
  }

  /**
   * Returns the asset's iconId.
   *
   * @returns {String}
   */
  get geoJsonMarkerId() {
    return this.iconId;
  }

  /**
   * Returns true if asset has a device.
   *
   * @returns {Boolean}
   */
  get hasDevice() {
    return this.devices && Boolean(this.devices.length);
  }

  /**
   * Returns true if the asset uses trips vs locations.
   */
  get hasTripsDevice() {
    return Boolean(this.firstConnectedDevice.serial?.startsWith('QW'));
  }

  /**
   * Returns ambientTemperature in degrees farhenheit as a string
   *
   * @returns {String}
   */
  get ambientTemperatureFahreheit() {
    return Number.isFinite(this.ambientTemperature)
      ? celsiusToFahreheit(this.ambientTemperature).toFixed(0)
      : this.ambientTemperature;
  }

  /**
   * Sets ambientTemperature in degrees farhenheit
   */
  set ambientTemperatureFahreheit(degreesF) {
    this.ambientTemperature = fahreheitToCelsius(degreesF);
  }

  /**
   * Returns the SVG icon ID.
   *
   * @returns {String}
   */
  get iconId() {
    return `asset-${this.type.iconUri || ASSET_ICONS[ASSET_TYPES.ASSET]}`;
  }

  /**
   * Returns battery_charge_level of the first/only connected device
   *
   * @returns {Number}
   */
  get deviceBatteryLevel() {
    const level = this.devices?.[0]?.batteryChargeLevel;
    return !level && level !== 0 ? null : level;
  }

  /**
   * Returns correct locations/trips route.
   */
  get tripsRoute() {
    return this.hasTripsDevice ? 'assetTrips' : 'assetLocations';
  }

  /**
   * Determines and retrieves appropriate status for the vehicle/device.
   *
   * @returns {String}
   */
  get status() {
    /**
     * Exract this function so that StatusFilter can reuse it and execute it in a worker)
     * Including the Tag model breaks worker compilation b/c it includes the whole store, which usees browser globals
     */
    return getStatus.call(this);
  }

  /**
   * Generates a GeoJSON "Feature" to be used in a GeoJSON layer.
   *
   * @returns {Object} GeoJSON Feature
   */
  toGeoJson() {
    return this.geoJson;
  }

  /**
   * Returns the name of the route for this type of entity that corresponds to the currently active tab in the detailed assets/vehicles UI
   *
   * @param {String} currentRoute
   * @param {String} tripsRoute
   * @returns {String}
   */
  static getTabRoute(currentRoute, tripsRoute) {
    switch (currentRoute) {
      case 'asset':
      case 'vehicle':
        return 'asset';
      case 'assetLocations':
      case 'assetTrips':
      case 'vehicleTrips':
        return tripsRoute;
      default:
        return currentRoute;
    }
  }
}

export default Asset;
