<template>
  <div v-if="total !== totalUnfiltered" class="row no-wrap items-center q-my-xs">
    <div class="text-caption">({{ totalUnfiltered - total }} results hidden)</div>
    <q-btn class="q-ml-xs" color="warning" dense padding="2px 9px 2px 4px" square @click="clearFilters">
      <q-icon name="close" size="xs" /> Clear Filter
    </q-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { SEARCH_TYPES } from 'src/services/constants';

export default {
  name: 'TableBottomClearButton',
  props: {
    total: Number,
    totalUnfiltered: Number,
  },
  computed: {
    ...mapState('app', ['isFilterRoute']),
    ...mapState('search', ['searchType']),
  },
  methods: {
    ...mapActions('search', ['updateFilterTerms']),
    clearFilters(event) {
      if (this.isFilterRoute && this.searchType === SEARCH_TYPES.FILTER) {
        this.updateFilterTerms('');
      }
      this.$emit('click', event);
    },
  },
};
</script>
