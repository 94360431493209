import pinNoDeviceSvg from 'src/assets/pin-no-device.svg?raw';
import { COLOR_VALUES } from 'src/services/constants';
import SvgMarker from './SvgMarker';

export default class NoDeviceSvgMarker extends SvgMarker {
  constructor() {
    super(pinNoDeviceSvg, {
      height: 91,
      width: 60,
      innerColor: COLOR_VALUES.WHITE,
      outerColor: COLOR_VALUES.DARK,
    });
  }
}
