<template>
  <div class="no-items column items-center full-height q-pa-lg text-center text-dark">
    <div>
      <q-icon class="warning-icon" color="negative" name="warning" />
    </div>
    <div class="text-h5" :class="{ 'q-mb-lg': $q.screen.height > 549 }">No items match<br />the selected filters</div>
    <div class="button">
      <q-btn color="white" padding="sm 16px sm 12px" square text-color="primary" @click="clear"
        ><q-icon class="q-mr-sm" name="filter_list_off" />Clear Filters</q-btn
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'MappedAssetsListNoItems',
  methods: {
    ...mapActions('filtering', ['resetAll']),
    clear() {
      this.resetAll();
    },
  },
};
</script>

<style lang="scss" scoped>
.no-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.warning-icon {
  font-size: 60px;
}

/**
 * NOTE: using "min-height"
 */
@media (min-height: 400px) and (max-height: 549px) {
  .no-items {
    justify-content: center;
    align-content: center;
  }

  .warning-icon {
    margin-right: map-get($space-md, 'x');
    font-size: 60px;
  }

  .button {
    margin-top: map-get($space-lg, 'y');
    width: 100%;
  }
}

@media (min-height: 550px) {
  .no-items {
    display: block;
    margin-bottom: map-get($space-lg, 'y');
  }

  .warning-icon {
    margin-bottom: map-get($space-lg, 'y');
    font-size: 100px;
  }
}
</style>
