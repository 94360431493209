import { isFullscreen } from 'src/services/fullscreen';
import AssetsBaseLayer from './AssetsBaseLayer';

export default class AssetsTextLayer extends AssetsBaseLayer {
  /** @var {String} id */
  static id = 'assets-text';

  isUsingTextbox = false;

  /**
   * @returns {Object} A GeoJson layer config object.
   */
  getConfig() {
    const parentConfig = super.constructor.getConfig();
    let offsetY = 6;
    let textSizeRatio = 1;

    if (isFullscreen()) {
      offsetY = 2;
      textSizeRatio = 3;
    }

    return Object.freeze({
      id: this.getId(),
      ...parentConfig,
      layout: {
        'icon-image': this.isUsingTextbox ? 'text-box' : '',
        'icon-padding': 10,
        'icon-text-fit': 'both',
        'text-anchor': 'top',
        'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
        'text-field': ['get', 'nickname'],
        'text-max-width': 50,
        'text-offset': ['get', 'textOffset'],
        'text-size': ['interpolate', ['linear'], ['zoom'], 12, 12 * textSizeRatio, 16, 14 * textSizeRatio],
      },
      paint: {
        'text-color': 'hsl(0, 0%, 14%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 2,
        'text-translate': this.isUsingTextbox ? [0, offsetY] : [0, 0],
      },
    });
  }

  /**
   * Tells the layer if it will be using the text-box image
   * @param {boolean} isUsingTextbox
   * @returns {boolean} True if a layer refresh is required for changes to be reflected
   */
  setUsingTextbox(isUsingTextbox) {
    const refreshRequired = this.isUsingTextbox !== isUsingTextbox;
    this.isUsingTextbox = isUsingTextbox;
    return refreshRequired;
  }
}
