export const handlers = {
  /**
   * The code scan handler.
   *
   * @returns {Promise<string>}
   */
  codeScan: () => {},
  /**
   * The text scan handler.
   *
   * @param {string} imageDataUrl
   * @returns {Promise<{ status: 'canceled' | 'error' | 'success', textBlocks: string[] }>}
   */
  textScan: () => {},
};

/**
 * Assigns the code scan handler.
 *
 * @param {() => Promise<string>} handler
 */
export function setCodeScanHandler(handler) {
  handlers.codeScan = handler;
}

/**
 * Assigns the text scan handler.
 *
 * @param {() => Promise<string>} handler
 */
export function setTextScanHandler(handler) {
  handlers.textScan = handler;
}

/**
 * Executes the code scan handler.
 */
export function codeScan() {
  return handlers.codeScan();
}

/**
 * Executes the text scan handler.
 *
 * @param {string} imageDataUrl
 */
export function textScan(imageDataUrl) {
  return handlers.textScan(imageDataUrl);
}
