import MapboxControl from 'src/services/map/MapboxControl';

export default {
  inject: ['map'],
  props: {
    position: {
      type: String,
      default: 'top-right',
    },
  },
  mounted() {
    if (!this.control) {
      this.control = new MapboxControl(this.$el);
      this.map.addControl(this.control, this.position);
      this.$emit('added', this.control);
    }
  },
  beforeDestroy() {
    if (this.map && this.control) {
      this.map.removeControl(this.control);
    }
  },
};
