<template>
  <div class="node-wrapper" :class="getNodeClass()" @click="$attrs.itemProps.onClick">
    <div class="node" :style="{ paddingLeft: node.treeLevel * 15 + 'px' }">
      <q-btn
        v-if="node.children.length > 0"
        dense
        :disable="node.disable"
        flat
        :icon="getNodeIcon()"
        round
        @click="
          (event) => {
            event.stopPropagation();
            $emit('toggle-expanded-state', node);
          }
        "
      />
      <div v-if="node.children.length === 0" class="no-icon" />
      <span
        ><q-checkbox
          :disable="selectionForbidden"
          :model-value="getCheckboxState()"
          @update:model-value="$attrs.itemProps.onClick"
        />
        {{ node[nodeLabelKey] }}</span
      >
    </div>
  </div>
</template>
<script>
export default {
  name: 'TreeNode',
  props: {
    expanded: Boolean,
    node: {
      required: true,
      type: Object,
    },
    nodeLabelKey: {
      type: String,
      default: null,
    },
    selected: Boolean,
    selectionForbidden: Boolean,
  },
  emits: ['toggle-expanded-state'],
  methods: {
    getNodeClass() {
      if (this.selectionForbidden) {
        return 'root';
      }
      if (this.node.selectedChildrenCount > 0) {
        return 'selected-child';
      }
      if (this.selected) {
        return 'selected';
      }
      return '';
    },
    getCheckboxState() {
      if ((!this.selected && this.node.selectedChildrenCount > 0) || this.selectionForbidden) return null;
      return this.selected;
    },
    getNodeIcon() {
      if (this.node.children === 0) {
        return '';
      }
      return this.expanded ? 'expand_less' : 'expand_more';
    },
  },
};
</script>
<style lang="scss" scoped>
.node-wrapper {
  padding: 5px 5px 5px 10px;
  height: 44px;
  line-height: 34px;
  cursor: pointer;
}
.node-wrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.selected {
  color: $primary;
}
.selected-child {
  color: $silver;
}
.root {
  cursor: not-allowed;
  color: $silver;
}

.no-icon {
  display: inline-block;
  width: 34px;
}
</style>
