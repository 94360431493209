<template>
  <svg
    class="icon"
    :height="`${size}px`"
    version="1.1"
    viewBox="0 0 24 24"
    :width="`${size}px`"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
defineProps<{ size: number }>();
</script>

<style lang="scss" scoped>
.icon {
  position: relative;
  top: -6%;
}
</style>
