import AuthLayout from 'layouts/Auth.vue';
import DefaultLayout from 'layouts/Default.vue';

import LoginPage from 'src/pages/Login.vue';
import MapPage from 'src/pages/Map.vue';

const routes = [
  {
    name: 'default',
    path: '/default', // should never get used
  },
  {
    path: '/app',
    redirect: '/',
  },
  {
    path: '/dl',
    redirect: '/',
  },
  {
    path: '/support/:accountId',
    name: 'support',
  },
  {
    path: '/driver-id',
    redirect: '/check-in',
  },
  {
    path: '',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'map',
        component: MapPage,
        meta: { title: 'Live Map' },
      },
      {
        path: 'selected/:key',
        name: 'map-selected',
        component: MapPage,
      },
      {
        path: 'nearby',
        name: 'nearby',
        component: MapPage,
        meta: {
          title: 'Nearby Vehicles - Live Map',
        },
      },
    ],
    meta: {
      auth: true,
      hasUniversalSearchFiltering: true,
      permissions: ['vehicleListView'],
      label: 'Live Map',
      searchContext: ['live-map'],
    },
  },
  {
    path: '/vehicle/:key',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'vehicle',
        component: () => import('pages/Vehicle.vue'),
        meta: { permission: ['vehicleDetailsView'], subTitle: 'Overview' },
      },
      {
        path: 'trips/:date?',
        name: 'vehicleTrips',
        component: () => import('pages/Vehicle.vue'),
        meta: { permissions: ['livemapTripsView'], subTitle: 'Trips' },
      },
      {
        path: 'health',
        name: 'vehicleHealth',
        component: () => import('pages/Vehicle.vue'),
        meta: { permissions: ['vehicleDetailsView'], subTitle: 'Health' },
      },
      {
        path: 'maintenance',
        name: 'vehicleMaintenance',
        component: () => import('pages/Vehicle.vue'),
        meta: { mobileTitle: 'Maintenance', permissions: ['vehicleMaintenanceView'], subTitle: 'Maintenance' },
      },
      {
        path: 'edit',
        name: 'vehicleEdit',
        component: () => import('pages/Vehicle.vue'),
        meta: { auth: true, subTitle: 'Edit Vehicle' },
      },
    ],
    meta: {
      auth: true,
      permissions: [],
      searchPriority: 'vehicle',
      searchContext: ['vehicle', 'asset'],
    },
  },
  {
    path: '/asset/:key',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'asset',
        component: () => import('pages/Asset.vue'),
        meta: { permission: ['vehicleDetailsView'], subTitle: 'Overview' },
      },
      {
        path: 'locations/:date?',
        name: 'assetLocations',
        component: () => import('pages/Asset.vue'),
        meta: { permission: ['livemapTripsView'], subTitle: 'Locations' },
      },
      {
        path: 'trips/:date?',
        name: 'assetTrips',
        component: () => import('pages/Asset.vue'),
        meta: { permission: ['livemapTripsView'], subTitle: 'Trips' },
      },
    ],
    meta: {
      auth: true,
      permissions: ['assetView'],
      searchPriority: 'asset',
      searchContext: ['asset', 'vehicle'],
    },
  },
  { path: '/vehicle', redirect: '/vehicles-assets' },
  { path: '/vehicles', redirect: '/vehicles-assets' },
  { path: '/asset', redirect: '/vehicles-assets' },
  { path: '/assets', redirect: '/vehicles-assets' },
  {
    path: '/vehicles-assets',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'vehiclesAssets',
        component: () => import('pages/VehiclesAssets.vue'),
      },
    ],
    meta: {
      auth: true,
      hasUniversalSearchFiltering: true,
      permissions: ['vehicleListView'],
      searchContext: ['vehicle', 'asset'],
      title: 'Vehicles & Assets',
    },
  },
  {
    path: '/places',
    component: DefaultLayout,
    children: [
      { path: '', name: 'places', meta: {}, component: () => import('pages/Places.vue') },
      {
        path: ':key',
        name: 'editPlace',
        component: () => import('pages/Places.vue'),
      },
      {
        path: ':key/visits',
        name: 'placeVisits',
        component: () => import('pages/Places.vue'),
      },
    ],
    meta: {
      auth: true,
      hasUniversalSearchFiltering: true,
      permissions: ['placeListView'],
      searchPriority: 'place',
      searchContext: ['place'],
    },
  },
  {
    path: '/tags',
    component: DefaultLayout,
    children: [{ path: '', name: 'tags', meta: {}, component: () => import('pages/Tags.vue') }],
    meta: {
      auth: true,
      hasUniversalSearchFiltering: true,
      permissions: ['tagListView'],
      title: 'Tags',
    },
  },
  {
    path: '/groups',
    component: DefaultLayout,
    children: [{ path: '', name: 'groups', meta: {}, component: () => import('pages/Groups.vue') }],
    meta: {
      auth: true,
      hasUniversalSearchFiltering: true,
      permissions: ['groupListView'],
      title: 'Groups',
    },
  },
  {
    path: '/subscriptions',
    component: DefaultLayout,
    children: [
      {
        path: '',
        meta: { mobileTitle: 'Billing & Subscriptions', title: 'Billing & Subscriptions' },
        name: 'subscriptions',
        component: () => import('pages/Subscriptions.vue'),
      },
      {
        path: ':subId/change-auto-renew',
        name: 'changeAutoRenew',
        component: () => import('pages/Subscriptions.vue'),
        meta: { title: 'Change Auto-Renew - Billing & Subscriptions' },
      },
    ],
    meta: {
      auth: true,
      permissions: ['accountView', 'isAccountAdmin'],
    },
  },
  {
    path: '/users',
    component: DefaultLayout,
    children: [
      {
        path: ':user?',
        name: 'users',
        meta: {},
        component: () => import('pages/Users.vue'),
      },
    ],
    meta: {
      auth: true,
      hasUniversalSearchFiltering: true,
      permissions: ['userListView'],
      searchPriority: 'user',
      searchContext: ['user'],
      title: 'Users',
    },
  },
  {
    path: '/notifications',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'notifications',
        meta: {},
        component: () => import('pages/Notifications.vue'),
      },
    ],
    meta: {
      auth: true,
      hasUniversalSearchFiltering: true,
      permissions: ['notificationsView'],
      title: 'Notifications',
    },
  },
  {
    path: '/reports/dashboard/crew',
    redirect: '/reports/dashboard/106',
  },
  {
    path: '/reports',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'metabase-full-app',
        component: () => import('pages/Metabase.vue'),
        meta: {
          auth: true,
          permissions: ['reportListView'],
        },
      },
      {
        path: ':type/:id',
        name: 'metabase-single',
        component: () => import('pages/Metabase.vue'),
        meta: {
          auth: true,
        },
      },
    ],
    meta: {
      title: 'Reports',
    },
  },
  {
    path: '/reports-beta',
    redirect: '/reports',
  },
  {
    path: '/dashboard',
    redirect: '/reports',
  },
  {
    path: '/apps',
    component: DefaultLayout,
    children: [
      { path: '', meta: { mobileTitle: 'Apps Marketplace' }, name: 'apps', component: () => import('pages/Apps.vue') },
      {
        path: ':appNameUrl',
        name: 'appDetails',
        component: () => import('pages/Apps.vue'),
        meta: {
          permissions: ['appEdit'],
        },
      },
    ],
    meta: {
      auth: true,
      permissions: ['appsListView'],
    },
  },
  {
    path: '/frame',
    name: 'iframes',
    component: () => import('layouts/MinimalTransparent.vue'),
    children: [
      {
        path: 'app-configure-capacitor/:appNameUrl',
        name: 'appConfigureCapacitor',
        component: () => import('pages/ConfigureClientAppCapacitor.vue'),
        meta: {
          auth: false,
          permissions: [],
        },
      },
      {
        path: 'app-configure-web/:appNameUrl',
        name: 'appConfigureWeb',
        component: () => import('pages/ConfigureClientAppWeb.vue'),
        meta: {
          auth: false,
          permissions: [],
        },
      },
    ],
    meta: {
      auth: false,
    },
  },
  {
    path: '/devices',
    component: DefaultLayout,
    children: [
      { path: '', name: 'devices', meta: {}, component: () => import('pages/Devices.vue') },
      {
        path: 'asset',
        name: 'assetDevices',
        meta: {},
        component: () => import('pages/Devices.vue'),
        children: [
          {
            path: 'connect/:serial',
            name: 'deviceConnect',
            component: () => import('pages/Devices.vue'),
            meta: { permissions: [] },
          },
        ],
      },
      { path: 'dashcam', name: 'dashcamDevices', meta: {}, component: () => import('pages/Devices.vue') },
      { path: 'direct-connect', name: 'directConnectDevices', meta: {}, component: () => import('pages/Devices.vue') },
    ],
    meta: {
      auth: true,
      hasUniversalSearchFiltering: true,
      permissions: ['deviceListView'],
    },
  },
  {
    path: '/serial/:serial', // from qr.zubie.com
    redirect: { name: 'deviceConnect' },
  },
  {
    path: '/asset-recovery',
    component: DefaultLayout,
    children: [
      {
        path: '',
        meta: { mobileTitle: 'Recover Your Missing/Stolen Vehicle' },
        name: 'assetRecovery',
        component: () => import('pages/AssetRecovery.vue'),
      },
    ],
    meta: {
      auth: true,
      permissions: ['assetRecoveryView'],
      title: 'Asset Recovery',
    },
  },
  {
    path: '',
    component: AuthLayout,
    children: [
      {
        path: '/select-account',
        name: 'selectAccount',
        component: () => import('pages/SelectAccount.vue'),
        meta: { auth: true, title: 'Select Account' },
      },
      { path: '/login', name: 'login', component: LoginPage, meta: { title: 'Login' } },
      {
        path: '/logout',
        name: 'logout',
        component: () => import('pages/Logout.vue'),
        meta: {
          auth: false,
        },
      },
      {
        path: '/reset-password',
        name: 'resetPassword',
        component: () => import('pages/ResetPassword.vue'),
        meta: {
          title: 'Reset Password',
        },
      },
      {
        path: '/create-account',
        name: 'createAccount',
        component: () => import('pages/CreateAccount.vue'),
        meta: {
          title: 'Create Account',
        },
      },
      {
        path: '/setup-account',
        name: 'setupAccount',
        component: () => import('pages/SetupAccount.vue'),
        meta: {
          auth: true,
          title: 'Setup Account',
        },
      },
      {
        path: '/invite',
        name: 'invite',
        component: () => import('pages/Invite.vue'),
        meta: { title: 'Accept Invite' },
      },
      {
        path: '/invite/welcome',
        name: 'inviteWelcome',
        component: () => import('pages/InviteWelcome.vue'),
        meta: {
          auth: true,
          title: 'Welcome',
        },
      },
    ],
  },
  {
    path: '/attributions',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'attributions',
        component: () => import('pages/Attributions.vue'),
        meta: {
          mobileTitle: 'Attributions',
          auth: false,
          permissions: [],
          title: 'Attributions',
        },
      },
    ],
  },
  { path: '/asset-type', redirect: '/asset-types' },
  {
    path: '/asset-types',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'assetTypes',
        meta: {},
        component: () => import('pages/AssetTypes.vue'),
      },
    ],
    meta: {
      auth: true,
      hasUniversalSearchFiltering: true,
      permissions: ['assetView'],
      title: 'Asset Types',
    },
  },
  {
    path: '/video-download',
    component: () => import('layouts/Minimal.vue'),
    children: [
      {
        path: '/',
        name: 'video-download',
        component: () => import('pages/VideoDownload.vue'),
        meta: {
          auth: false,
        },
      },
    ],
    meta: {
      auth: false,
      title: 'Video Download',
    },
  },
  {
    path: '/video-stream/:serial',
    component: () => import('layouts/Minimal.vue'),
    children: [
      {
        path: '',
        name: 'video-stream',
        component: () => import('pages/VideoStream.vue'),
      },
    ],
    meta: {
      auth: false,
      title: 'Video Stream',
    },
  },
  {
    path: '/check-in',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'checkIn',
        component: () => import('pages/CheckIn.vue'),
        meta: {
          mobileTitle: 'Check In',
          title: 'Check In',
          permissions: ['isAllowedToCheckIn'],
          pageContainerClass: 'bg-white',
        },
      },
    ],
    meta: {
      auth: true,
    },
  },
  {
    path: '/feed',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'feed',
        component: () => import('pages/Feed.vue'),
        meta: {
          mobileTitle: 'Feed',
        },
        children: [
          {
            path: 'trip/:key',
            name: 'feedTrip',
            component: () => import('pages/Feed.vue'),
            meta: {
              mobileTitle: 'Trip - Feed',
            },
          },
        ],
      },
    ],
    meta: {
      auth: true,
    },
  },
  {
    path: '/shares',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'shares',
        meta: {
          hasUniversalSearchFiltering: true,
        },
        component: () => import('pages/Shares.vue'),
      },
    ],
    meta: {
      auth: true,
      permissions: ['isAccountAdmin'],
    },
  },
  {
    component: DefaultLayout,
    path: '',
    children: [
      {
        name: 'app-boot-failure',
        path: '',
        component: () => import('pages/AppBootFailure.vue'),
        meta: {
          auth: false,
          permissions: [],
          title: 'Unable to Load Your Account',
        },
      },
      {
        name: 'incompatible',
        path: '',
        component: () => import('pages/Incompatible.vue'),
        meta: {
          auth: false,
          permissions: [],
          title: 'Incompatible Browser',
        },
      },
      {
        name: 'loading',
        path: '/loading',
        component: () => import('pages/Loading.vue'),
        meta: {
          auth: false,
          permissions: [],
        },
      },
    ],
  },
  {
    path: '',
    component: () => import('layouts/Minimal.vue'),
    children: [
      {
        name: 'invalid-session',
        path: '/invalid-session',
        component: () => import('pages/InvalidSession.vue'),
        meta: {
          auth: false,
          permissions: [],
          title: 'Invalid Session',
        },
      },
    ],
  },
  {
    path: '',
    component: () => import('layouts/Minimal.vue'),
    children: [
      {
        name: 'no-accounts',
        path: 'no-accounts',
        component: () => import('pages/NoAccounts.vue'),
        meta: {
          auth: false,
          permissions: [],
          title: 'No Accounts',
        },
      },
    ],
  },
  {
    path: '',
    component: DefaultLayout,
    children: [
      {
        path: 'release-notes',
        name: 'releaseNotes',
        component: () => import('pages/ReleaseNotes.vue'),
        meta: {
          mobileTitle: 'Release Notes',
          title: 'Release Notes',
        },
      },
    ],
  },
  {
    path: '/denied',
    component: DefaultLayout,
    children: [{ path: '', name: 'denied', component: () => import('pages/Denied.vue'), meta: { title: 'Denied' } }],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
