import { Notify } from 'quasar';

export async function init(context) {
  const debugLib = await import('debug');
  const { Debugger } = await import('./Debugger');
  const debug = new Debugger(debugLib, Notify.create.bind(Notify));
  context.commit('enable');
  context.commit('setDebugger', debug);
  context.dispatch('log', 'Debugging enabled.');
  context.dispatch('notify', {
    icon: 'bug_report',
    color: 'primary',
    message: 'Debugging enabled.',
  });
  context.dispatch('notify', {
    icon: 'check_circle',
    color: 'positive',
    message: 'Successfully loaded environment configuration.',
  });
  context.dispatch('displayActiveSettings');
}

export function log(context, message) {
  if (context.state.enabled) {
    context.state.debug.log(message);
  }
}

export function notify(context, notification) {
  if (context.state.enabled) {
    context.state.debug.notify(notification);
  }
}

export function displayActiveSettings(context) {
  context.dispatch('notify', {
    icon: 'check_circle',
    color: 'positive',
    message: 'Vue Performance Devtool is enabled.',
  });
}
