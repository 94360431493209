import SimpleModel from './SimpleModel';

class AnimationInstruction extends SimpleModel {
  props() {
    return {
      key: null,
      from: [null, null],
      to: [null, null],
    };
  }
}

export default AnimationInstruction;
