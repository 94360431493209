import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "-1 -1 18 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M15.985 11.379A3.13 3.13 0 0 0 12.946 9h-.12l-1.668-6H13.5a.5.5 0 0 0 0-1h-11a.5.5 0 0 0-.5.5V7H.986A.99.99 0 0 0 0 7.991V12a1 1 0 0 0 1 1h1.092a1.491 1.491 0 0 0 2.816 0h6.184a1.491 1.491 0 0 0 2.816 0H15a1 1 0 0 0 1-1v-.5a.5.5 0 0 0-.015-.121M3.5 13a.5.5 0 1 1 .5-.5.5.5 0 0 1-.5.5m1-6H3V3h7.12l1.461 5.255-1.813-1.316.586-.586a.5.5 0 0 0-.707-.707l-2 2a.5.5 0 1 0 .707.707l.7-.7L10.906 9H5V7.5a.5.5 0 0 0-.5-.5m8 6a.5.5 0 1 1 .5-.5.5.5 0 0 1-.5.5"
    }, null, -1)
  ])))
}
export default { render: render }