<template>
  <div v-if="mapboxAccessToken && browserSupport.webGl" class="relative full-width full-height">
    <MapboxMap
      :access-token="mapboxAccessToken"
      :attribution-control="false"
      :bounds="bounds"
      :center="center"
      :class="{ 'non-interactive': !this.interactiveMap }"
      :interactive="interactive"
      :map-style="mapStylePath"
      :max-zoom="maxZoom"
      :min-zoom="minZoom"
      :preserve-drawing-buffer="true"
      @mb-click="onMapClick"
      @mb-created="onMapLoad"
      @mb-mousemove="onMouseMove"
      @mousemove.stop
    >
      <MapboxNavigationControl
        v-if="isLoaded && (mapStyleControlAdded || !chooserControl)"
        position="top-right"
        @added="
          navigationControlAdded = true;
          onControlAdded();
        "
      />
      <MapboxAttributionControl
        v-if="isLoaded"
        position="top-left"
        @added="
          attributionControlAdded = true;
          onControlAdded();
        "
      />
      <MapStyleControl
        v-if="chooserControl"
        :mapStyle="mapStyle"
        @added="
          mapStyleControlAdded = true;
          onControlAdded();
        "
        @set-map-style="$emit('set-map-style', $event)"
      />
      <MapboxFullscreenControl
        v-if="
          fullscreenControl &&
          navigationControlAdded &&
          !$q.platform.is.capacitor &&
          (mapStyleControlAdded || !chooserControl)
        "
        @added="
          fullscreenControlAdded = true;
          onControlAdded();
        "
      />
      <slot v-if="baseControlsAdded" />
      <slot name="alt-control-group" />
    </MapboxMap>
    <div class="inner-map">
      <slot name="inner-map" />
    </div>
    <div v-if="!isLoaded && showLoader" class="placeholder absolute-center full-width full-height">
      <slot name="loader">
        <div class="absolute-center">
          <q-spinner color="primary" size="280px" :thickness="5" />
        </div>
      </slot>
    </div>
    <slot name="after" />
    <q-resize-observer @resize="onResize" />
  </div>
  <div v-else-if="!browserSupport.webGl" class="full-height row justify-center items-center relative">
    <div style="max-width: 400px">
      <q-card>
        <q-card-section>
          <div class="text-h6 row no-wrap q-mb-md">
            <div class="full-width">Unable to load interactive map</div>
            <div class="q-mr-xs">
              <q-icon color="warning" name="warning" size="md" />
            </div>
          </div>
          <div>
            <p>
              Unfortunately, a feature called WebGL2 is disabled on your browser and we cannot display our map without
              it. If you have disabled this yourself, please re-enable it to use our maps.
            </p>
            <div>
              Otherwise, try:
              <ul>
                <li>Updating and restarting your browser</li>
                <li>Updating your graphics drivers</li>
                <li>
                  Visit <a href="https://get.webgl.org/webgl2/" target="_blank">https://get.webgl.org/webgl2/</a> for
                  more info
                </li>
              </ul>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import { MapboxMap } from '@studiometa/vue-mapbox-gl';
import _debounce from 'lodash/debounce';
import { LngLatBounds } from 'mapbox-gl';
import { provide } from 'vue';
import { mapState } from 'vuex';
import MapboxAttributionControl from 'components/map/controls/MapboxAttributionControl.vue';
import MapboxFullscreenControl from 'components/map/controls/MapboxFullscreenControl.vue';
import MapboxNavigationControl from 'components/map/controls/MapboxNavigationControl.vue';
import MapStyleControl from 'components/map/controls/MapStyleControl.vue';
import Bounds from 'src/models/Bounds';
import MapImage from 'src/models/MapImage';
import { CONTINENTAL_US_BOUNDS, mapStyles } from 'src/services/constants';
import { offFullscreenChange, onFullscreenChange } from 'src/services/fullscreen';
import MapService from 'src/services/map';
import Mapbox from 'src/services/map/Mapbox';

import 'mapbox-gl/dist/mapbox-gl.css';

const DEFAULT_BOUNDS = new LngLatBounds(CONTINENTAL_US_BOUNDS.southWest, CONTINENTAL_US_BOUNDS.northEast);

export default {
  name: 'ZubieMap',
  props: {
    center: {
      type: Array,
      required: false,
    },
    chooserControl: {
      type: Boolean,
      default: true,
    },
    fullscreenControl: {
      type: Boolean,
      default: false,
    },
    initialBounds: {
      type: Bounds,
      default: null,
    },
    interactive: {
      type: Boolean,
      default: true,
    },
    interactiveMap: {
      type: Boolean,
      default: true,
    },
    mapImages: {
      type: Array,
      default() {
        return [];
      },
      validator(value) {
        return value.every((item) => item instanceof MapImage);
      },
    },
    mapStyle: String,
    maxZoom: {
      type: Number,
      default: MapService.MAX_ZOOM,
    },
    minZoom: {
      type: Number,
      default: MapService.MIN_ZOOM,
    },
    navigationControl: {
      type: Boolean,
      default: true,
    },
    showLoader: Boolean,
    showPlaces: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: 17,
    },
  },
  components: {
    MapboxAttributionControl,
    MapboxFullscreenControl,
    MapboxMap,
    MapboxNavigationControl,
    MapStyleControl,
  },
  emits: ['click', 'load', 'mousemove', 'set-map-style'],
  computed: {
    ...mapState('env', ['browserSupport', 'mapboxAccessToken']),
    mapStylePath() {
      return mapStyles[this.mapStyle];
    },
  },
  data() {
    return {
      attributionControlAdded: false,
      baseControlsAdded: false,
      bounds: DEFAULT_BOUNDS,
      fullscreenControlAdded: false,
      isFullScreen: false,
      isLoaded: false,
      mapStyleControlAdded: false,
      navigationControlAdded: false,
    };
  },
  methods: {
    /**
     * Adds custom control positions beyond the standard positions.
     * Note: this may break with future changes to Mapbox GL.
     *
     * @param {Mapbox} map
     */
    addControlPositions(map) {
      // top-right-secondary
      const div = document.createElement('div');
      div.setAttribute('class', 'mapboxgl-ctrl-top-right-secondary');
      map.getContainer().querySelector('.mapboxgl-control-container').appendChild(div);
      map._controlPositions['top-right-secondary'] = div;
    },
    /**
     * Adds elements within the map area so they are included in fullscreen view.
     *
     * @param {HTMLElement} element
     */
    addInnerMapElement(element) {
      this.map.getContainer().querySelector('.mapboxgl-control-container').appendChild(element);
    },
    async loadImages() {
      const map = this.map;

      const images = await Promise.all(this.mapImages.map((mapImage) => mapImage.load(map)));

      images.forEach(({ id, image }) => {
        if (!map.hasImage(id)) {
          map.addImage(id, image);
        }
      });
    },
    onControlAdded() {
      // Check if all base controls are added
      const attributionAdded = this.attributionControlAdded;
      const fullscreenAdded = this.fullscreenControlAdded || !this.fullscreenControl || this.$q.platform.is.capacitor;
      const mapStyleAdded = this.mapStyleControlAdded || !this.chooserControl;
      const navigationAdded = this.navigationControlAdded || !this.navigationControl;

      if (attributionAdded && fullscreenAdded && mapStyleAdded && navigationAdded) {
        this.baseControlsAdded = true;
      }
    },
    onMapClick(event) {
      const map = this.map;
      this.$emit('click', { map, event });
    },
    async onMapLoad(map) {
      this.addControlPositions(map);

      this.map = map;

      provide('map', map);

      this.addInnerMapElement(this.$el.querySelector('.inner-map'));

      if (this.zoom) {
        this.map.setZoom(this.zoom);
      }

      map.on('render', () => {
        this.onResize();
      });

      map.on('style.load', async () => {
        await this.loadImages();
        this.isLoaded = true;
        this.$emit('load', { map });
      });

      this.preventStrayControls(map);

      onFullscreenChange(this.onResize);
    },
    onMouseMove(event) {
      const map = this.map;
      this.$emit('mousemove', { map, event });
    },
    async resizeMap() {
      await this.$nextTick();
      this.map.resize();
    },
    /**
     * Moves controls back to their correct parent after they are erroneously
     * moved to the map wrapper element.
     */
    preventStrayControls(map) {
      const mapContainer = map.getContainer();
      const mapWrapper = mapContainer.parentNode;

      const observer = new MutationObserver((mutations) => {
        mutations
          .filter(({ removedNodes }) => removedNodes.length)
          .forEach(({ removedNodes, target }) => {
            removedNodes.forEach((node) => {
              if (node.parentNode === mapWrapper) {
                // Does not have the right parent, put it back
                target.appendChild(node);
              }
            });
          });
      });

      const controlContainer = mapContainer.querySelector('.mapboxgl-control-container');
      if (!controlContainer) {
        return;
      }

      observer.observe(controlContainer, {
        subtree: true,
        childList: true,
      });
    },
  },
  created() {
    this.onResize = _debounce(() => {
      if (this.map?.style && this.isLoaded) {
        this.resizeMap();
      }
    }, Mapbox.RESIZE_DEBOUNCE);

    if (this.initialBounds) {
      this.bounds = this.initialBounds.toLngLat();
    }
  },
  unmounted() {
    offFullscreenChange(this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.placeholder {
  opacity: 0.9;
  background: rgba(117, 117, 117, 0.7);
}
</style>

<style lang="scss">
.mapboxgl-map {
  height: 100%;
}

.mapboxgl-ctrl-top-right-secondary {
  position: absolute;
  top: 0;
  right: 40px;
  z-index: 2;
  pointer-events: none;

  .mapboxgl-ctrl {
    margin: 10px 10px 0 0;
    float: right;
  }
}

.non-interactive .mapboxgl-canvas-container {
  pointer-events: none;
}

.fullscreen.ios-notch-top {
  .mapboxgl-ctrl-top-right,
  .mapboxgl-ctrl-top-right-secondary {
    .mapboxgl-ctrl:first-child {
      margin-top: 0;
    }
  }
}
</style>
