import { colors as quasarColors } from 'quasar';
import GeoJsonLayer from 'src/services/geoJson/GeoJsonLayer';
import PlaceFencesSource from 'src/services/geoJson/sources/PlaceFencesSource';

const { getPaletteColor } = quasarColors;

export default class PlaceFencesBoundaryLayer extends GeoJsonLayer {
  /** @var {String} id */
  static id = 'place-fences-boundary';

  /**
   * @returns {Object} A GeoJson layer config object.
   */
  getConfig(enableHidden = false) {
    const visibleColor = getPaletteColor('accent');
    let lineColor = visibleColor;

    if (enableHidden) {
      lineColor = ['case', ['==', ['get', 'isVisible'], true], visibleColor, getPaletteColor('dark')];
    }

    return Object.freeze({
      id: this.getId(),
      type: 'line',
      metadata: { 'mapbox:group': '3c074f28255b87214ccb0ab55fee9992' },
      source: PlaceFencesSource.id,
      filter: ['match', ['geometry-type'], ['Polygon'], true, false],
      layout: { 'line-join': 'round' },
      paint: {
        'line-width': ['interpolate', ['linear'], ['zoom'], 4, 0, 5, 1, 20, 4],
        'line-color': lineColor,
        'line-dasharray': [9, 1],
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 4, 0.3, 15, 0.7],
      },
    });
  }
}
