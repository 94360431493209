import AssetsByTagLayer from './AssetsByTagLayer';
import AssetsClusterCountLayer from './AssetsClusterCountLayer';
import AssetsClusterLayer from './AssetsClusterLayer';
import AssetsLayer from './AssetsLayer';
import AssetsTextLayer from './AssetsTextLayer';
import PlaceFencesBoundaryLayer from './PlaceFencesBoundaryLayer';
import PlaceFencesLayer from './PlaceFencesLayer';
import PlacePointsLayer from './PlacePointsLayer';
import SearchResultsLayer from './SearchResultsLayer';
import SelectedAssetByTagLayer from './SelectedAssetByTagLayer';
import SelectedAssetLayer from './SelectedAssetLayer';
import TrafficLayer from './TrafficLayer';
import VehicleDetailsLayer from './VehicleDetailsLayer';

const exported = {
  assetsLayer: new AssetsLayer(),
  assetsTextLayer: new AssetsTextLayer(),
  assetsByTagLayer: new AssetsByTagLayer(),
  assetsClusterLayer: new AssetsClusterLayer(),
  assetsClusterCountLayer: new AssetsClusterCountLayer(),
  placeFencesBoundaryLayer: new PlaceFencesBoundaryLayer(),
  placeFencesLayer: new PlaceFencesLayer(),
  placePointsLayer: new PlacePointsLayer(),
  searchResultsLayer: new SearchResultsLayer(),
  selectedAssetByTagLayer: new SelectedAssetByTagLayer(),
  selectedAssetLayer: new SelectedAssetLayer(),
  trafficLayer: new TrafficLayer(),
  vehicleDetailsLayer: new VehicleDetailsLayer(),
};

export default exported;
