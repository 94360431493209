<template>
  <q-item class="q-py-none">
    <q-item-section>
      <q-btn-toggle
        class="q-btn-group--square"
        data-t="toggleButtons"
        :model-value="mapStyle"
        no-caps
        :options="mapStyleOptions"
        size="12px"
        style="flex-wrap: wrap !important"
        toggle-color="primary"
        unelevated
        @update:model-value="$emit('set-map-style', $event)"
      >
        <template #one>
          <div class="items-center no-wrap">
            <q-img alt="Default Style" class="q-mb-xs" :src="mapIcons.DEFAULT" style="width: 40px" />
            <div class="map-style-text">Default</div>
          </div>
        </template>

        <template #two>
          <div class="items-center no-wrap">
            <q-img alt="Satellite Style" class="q-mb-xs" :src="mapIcons.SATELLITE" style="width: 40px" />
            <div class="map-style-text">Satellite</div>
          </div>
        </template>

        <template #three>
          <div class="items-center no-wrap">
            <q-img alt="Light Style" class="q-mb-xs" :src="mapIcons.LIGHT" style="width: 40px" />
            <div class="map-style-text">Light</div>
          </div>
        </template>

        <template #four>
          <div class="items-center no-wrap">
            <q-img alt="Dark Style" class="q-mb-xs" :src="mapIcons.DARK" style="width: 40px" />
            <div class="map-style-text">Dark</div>
          </div>
        </template>
      </q-btn-toggle>
    </q-item-section>
  </q-item>
</template>

<script>
import { mapIcons } from 'src/services/constants';

export default {
  name: 'MapStyleOptions',
  props: {
    mapStyle: String,
  },
  computed: {
    mapStyleOptions() {
      return [
        { value: 'DEFAULT', slot: 'one' },
        { value: 'SATELLITE', slot: 'two' },
        { value: 'LIGHT', slot: 'three' },
        { value: 'DARK', slot: 'four' },
      ];
    },
    mapIcons() {
      return mapIcons;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-style-text {
  line-height: 1.2;
}

:deep(.q-btn-toggle) {
  border: 1px solid $silver;
}

:deep(.q-btn) {
  @media (max-width: 799px) {
    width: 25%;
  }
}
</style>
