export function addAccountMaintenanceCategory(state, category) {
  state.accountMaintenanceCategories = [...state.accountMaintenanceCategories, category];
}

export function addMaintenanceHistoryForKey(state, { vehicleKey, serviceLog }) {
  state.maintenanceHistory = {
    ...state.maintenanceHistory,
    [vehicleKey]: [serviceLog, ...state.maintenanceHistory[vehicleKey]],
  };
}

export function addMaintenanceReminderForKey(state, { vehicleKey, reminder }) {
  const existingVehicleReminders = state.maintenanceReminders[vehicleKey] || [];
  state.maintenanceReminders = {
    ...state.maintenanceReminders,
    [vehicleKey]: [...existingVehicleReminders, reminder],
  };
}

export function deleteAccountMaintenanceCategory(state, categoryKey) {
  state.accountMaintenanceCategories = state.accountMaintenanceCategories.filter(
    (category) => category.maintenanceActionKey !== categoryKey
  );
}

export function deleteMaintenanceHistoryForKey(state, { vehicleKey, serviceLogKey }) {
  state.maintenanceHistory = {
    ...state.maintenanceHistory,
    [vehicleKey]: state.maintenanceHistory[vehicleKey].filter((serviceLog) => serviceLog.key !== serviceLogKey),
  };
}

export function deleteMaintenanceReminderForKey(state, { vehicleKey, reminderKey }) {
  state.maintenanceReminders = {
    ...state.maintenanceReminders,
    [vehicleKey]: state.maintenanceReminders[vehicleKey].filter((reminder) => reminder.key !== reminderKey),
  };
}

export function setAccountMaintenanceCategories(state, categories) {
  state.accountMaintenanceCategories = categories;
}

export function setMaintenanceHistoryForKey(state, { key, history }) {
  state.maintenanceHistory = {
    ...state.maintenanceHistory,
    [key]: history,
  };
}

export function setMaintenanceHistoryLoading(state, value) {
  state.maintenanceHistoryLoading = value;
}

export function setMaintenanceRemindersForKey(state, { key, reminders }) {
  state.maintenanceReminders = {
    ...state.maintenanceReminders,
    [key]: reminders,
  };
}

export function setMaintenanceRemindersLoading(state, value) {
  state.maintenanceRemindersLoading = value;
}

export function updateMaintenanceReminder(state, { vehicleKey, reminder: newReminder, byKey = 'key' }) {
  const reminders = [];

  state.maintenanceReminders[vehicleKey].forEach((existingReminder) => {
    if (existingReminder[byKey] === newReminder[byKey]) {
      reminders.push(newReminder);
    } else {
      reminders.push(existingReminder);
    }
  });

  state.maintenanceReminders = {
    ...state.maintenanceReminders,
    [vehicleKey]: reminders,
  };
}

export function updateServiceLog(state, { vehicleKey, serviceLog: newServiceLog }) {
  const history = [];

  state.maintenanceHistory[vehicleKey].forEach((existingServiceLog) => {
    if (existingServiceLog.key === newServiceLog.key) {
      history.push(newServiceLog);
    } else {
      history.push(existingServiceLog);
    }
  });

  state.maintenanceHistory = {
    ...state.maintenanceHistory,
    [vehicleKey]: history,
  };
}
