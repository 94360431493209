import storage from 'src/services/storage';
import SearchResultTransformer from 'src/transformers/SearchResultTransformer';

/**
 * @param {SearchStoreState} state
 * @param {number} index
 */
export function setActiveIndex(state, index) {
  state.activeIndex = index;
}

/**
 * @param {SearchStoreState} state
 * @param {keyof typeof import('src/services/constants').SEARCH_TYPES} type
 */
export function setSearchType(state, type) {
  state.searchType = type;
  storage.set('search/searchType', type);
}

/**
 * @param {SearchStoreState} state
 * @param {SearchItem} type
 */
export function setHistory(state, history) {
  state.history = history;

  // Only keep necessary data
  const storedHistory = history.map((item) => {
    const { assetType, fullName, hasLocation, hasTripsDevice, key, name, nickname, tripsRoute, type } = item;
    return {
      assetType,
      fullName,
      hasLocation,
      hasTripsDevice,
      key,
      name,
      nickname,
      tripsRoute,
      type,
    };
  });

  storage.set('search/history', JSON.stringify(storedHistory));
}

/**
 * @param {SearchStoreState} state
 * @param {boolean} isSearching
 */
export function setIsSearching(state, isSearching) {
  state.isSearching = Boolean(isSearching);
}

/**
 * @param {SearchStoreState} state
 * @param {SearchItem[]} type
 */
export function setSearchItems(state, items) {
  state.searchItems = SearchResultTransformer.transformArray(items);
}

/**
 * @param {SearchStoreState} state
 * @param {string} terms
 */
export function setSearchedTerms(state, terms) {
  state.searchedTerms = terms;
}

/**
 * @param {SearchStoreState} state
 * @param {SearchResultType} priority
 */
export function setSearchPriority(state, priority) {
  state.priority = priority || null;
}

/**
 * @param {SearchStoreState} state
 * @param {string} terms
 */
export function setFilterTerms(state, terms) {
  state.filterTerms = terms;
}
