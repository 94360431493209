import _isFinite from 'lodash/isFinite';
import Vehicle from 'src/models/Vehicle';
import { simpleStatuses, LOW_BATTERY_THRESHOLD, ALERT_TYPES } from 'src/services/constants';
import AlertChecks from './AlertChecks';
import AssetAlert from './AssetAlert';

const { STALE, STOPPED } = simpleStatuses;

/**
 * Produces alerts based on various attributes of a vehicle.
 *
 * @param {import('src/models/Asset').default|Vehicle} asset
 * @returns {AssetAlert[]}
 */
export function check(asset) {
  const alerts = [];

  if (asset.devices.length === 0) {
    alerts.push(new AssetAlert(ALERT_TYPES.NO_DEVICE));
  } else if (!asset.isConnected) {
    alerts.push(new AssetAlert(ALERT_TYPES.DISCONNECTED));
  }

  if (asset instanceof Vehicle) {
    if ((asset.getMarker() === STOPPED || asset.getMarker() === STALE) && asset.hasLocationMismatch) {
      alerts.push(
        new AssetAlert(ALERT_TYPES.POSSIBLE_TOW, {
          distance: Number(asset.distanceFromTripEnd.toFixed(2)),
        })
      );
    }
    if (_isFinite(asset.batteryVolts) && asset.batteryVolts < LOW_BATTERY_THRESHOLD && asset.batteryVolts > 0) {
      alerts.push(new AssetAlert(ALERT_TYPES.LOW_BATTERY));
    }
  } else if (AlertChecks.isLowBatteryLevel(asset.deviceBatteryLevel)) {
    alerts.push(new AssetAlert(ALERT_TYPES.LOW_BATTERY_LEVEL));
  }

  if (asset.hasActiveTroubleCodes) {
    alerts.push(new AssetAlert(ALERT_TYPES.TROUBLE_CODES));
  }

  return alerts;
}
