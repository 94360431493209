<template>
  <div class="column col">
    <div class="col" style="min-height: 400px">
      <ZubieMap
        ref="map"
        :center="center || undefined"
        :chooser-control="false"
        fullscreen-control
        :map-style="mapStyle"
        show-loader
        :zoom="zoom"
        @load="onMapLoad"
      >
        <template #inner-map>
          <div v-if="isCircle" class="radius-control" :style="{ top: `${radiusControlTop}px` }">
            <q-select
              ref="radiusControl"
              v-capacitor-native-select
              dense
              display-value-sanitize
              emit-value
              filled
              label="Radius"
              map-options
              :model-value="radius"
              :options="radii"
              options-sanitize
              @popup-show="onPopupShow"
              @update:model-value="updateRadius"
            />
          </div>
        </template>

        <MapboxLayer
          v-if="isCircle && circleGeoJsonData && hasLatLong"
          :clear-source="false"
          :layer="layerCircle"
          :source="circleGeoJson"
        />

        <MapboxLayer
          v-if="isCircle && circleGeoJsonData && hasLatLong"
          :clear-source="false"
          :layer="layerCircleLine"
          :source="circleLineGeoJson"
        />

        <MapboxMarker
          v-if="isCircle && hasLatLong"
          anchor="bottom"
          :color="markerColor"
          :draggable="true"
          :lng-lat="[longitude, latitude]"
          :offset="[0, 4]"
          @mb-dragend="updateCenter"
        />

        <template #loader>
          <div class="placeholder absolute-center full-width full-height">
            <q-icon class="absolute-center" name="share_location" size="300px" />
            <div class="absolute-center">
              <q-spinner color="primary" size="280px" :thickness="5" />
            </div>
          </div>
        </template>

        <template #after>
          <div
            v-if="placesInitialized && !hasLatLong && mapLoaded"
            class="place-map absolute-center full-width full-height row justify-center items-center text-h5 z-index-2"
            style="background: rgba(255, 255, 255, 0.4)"
          >
            <q-banner class="q-pa-lg" color="white">
              <a href="#" @click.prevent="$emit('search-focus')">Search for an address or location</a><br />to interact
              with the map
            </q-banner>
          </div>
        </template>
      </ZubieMap>
    </div>
    <div class="row items-center justify-between">
      <q-toggle
        v-model="showOtherPlaces"
        checked-icon="check"
        class="justify-between"
        color="primary"
        :disable="!longitude"
        label="Show other places"
        unchecked-icon="clear"
        @update:model-value="toggleOtherPlaces"
      />
      <div class="text-caption">
        Tip: {{ isCircle ? 'Drag center to relocate' : 'Use the drawing tools to edit the polygon' }}
      </div>
    </div>
  </div>
</template>

<script>
import { MapboxMarker } from '@studiometa/vue-mapbox-gl';
import centroid from '@turf/centroid';
import _isNil from 'lodash/isNil';
import { toRaw } from 'vue';
import { mapGetters, mapState } from 'vuex';
import MapboxLayer from 'components/map/MapboxLayer.vue';
import ZubieMap from 'components/map/ZubieMap.vue';
import style from 'components/places/DrawStyle';
import Place from 'src/models/Place';
import { COLOR_VALUES } from 'src/services/constants';
import { isFullscreen } from 'src/services/fullscreen';
import geoJsonLayers from 'src/services/geoJson/layers';
import sources from 'src/services/geoJson/sources';
import MapService from 'src/services/map';
import { setTimeoutPromise } from 'src/services/setTimeout';

const { placeFencesBoundaryLayer, placeFencesLayer, placePointsLayer } = geoJsonLayers;
const fencesBoundaryLayer = placeFencesBoundaryLayer.getConfig();
const fencesLayer = placeFencesLayer.getConfig();
const pointsLayer = placePointsLayer.getConfig();
const { placeFencesSource, placePointsSource } = sources;

export default {
  name: 'PlaceMap',
  props: {
    geometry: Object,
    latitude: Number,
    longitude: Number,
    placeKey: String,
    originalType: String,
    radius: Number,
    radiusUm: String,
    type: String,
  },
  components: {
    MapboxLayer,
    MapboxMarker,
    ZubieMap,
  },
  data() {
    return {
      center: null,
      circleGeoJsonData: null,
      layerCircleLine: {
        id: 'circle-line',
        type: fencesBoundaryLayer.type,
        filter: fencesBoundaryLayer.filter,
        layout: fencesBoundaryLayer.layout,
        paint: fencesBoundaryLayer.paint,
      },
      layerCircle: {
        id: 'circle',
        type: fencesLayer.type,
        filter: fencesLayer.filter,
        // no layout
        paint: { ...fencesLayer.paint, 'fill-opacity': ['interpolate', ['linear'], ['zoom'], 4, 0, 15, 0.5] },
      },
      mapStyle: 'SATELLITE',
      markerColor: COLOR_VALUES.PRIMARY,
      mapLoaded: false,
      radiusControlTop: 10,
      showOtherPlaces: true,
      zoom: 16,
      radii: [],
    };
  },
  computed: {
    ...mapState('app', ['broker', 'notchSizes']),
    ...mapState('env', ['mapboxAccessToken']),
    ...mapState('map', ['idealBounds']),
    ...mapGetters('places', ['places']),
    ...mapState('places', {
      placesInitialized: 'initialized',
    }),
    circleGeoJson() {
      const geoJson = this.circleGeoJsonData;
      geoJson.data.id = 'circleGeoJson';
      return geoJson;
    },
    circleLineGeoJson() {
      const geoJson = this.circleGeoJsonData;
      geoJson.data.id = 'circleLineGeoJson';
      return geoJson;
    },
    hasExistingPolygon() {
      const existingCoordinates = this.geometry.geometry.coordinates[0] || [];
      return this.originalType !== 'Circle' && existingCoordinates.length > 3;
    },
    hasLatLong() {
      return Boolean(this.latitude && this.longitude);
    },
    isCircle() {
      return this.type === 'Circle';
    },
    otherPlaces() {
      return this.places.filter((place) => place.key !== this.placeKey);
    },
    otherPlacesFencesGeoJson() {
      return this.otherPlaces.map((place) => place.geometry);
    },
    otherPlacesPointsGeoJson() {
      return this.otherPlaces.map((place) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [place.longitude, place.latitude],
        },
        properties: {
          name: place.name,
        },
      }));
    },
    polygonGeojson() {
      const coordinates = this.hasExistingPolygon
        ? this.geometry.geometry.coordinates
        : [Place.getDefaultPolygonCoordinates(this.longitude, this.latitude)];
      const polygon = {
        id: 'polygon',
        type: 'Feature',
        properties: {},
        geometry: {
          coordinates,
          type: 'Polygon',
        },
      };
      return polygon;
    },
  },
  methods: {
    fitBounds() {
      this.$nextTick(async () => {
        if (this.mapLoaded) {
          const geometry = this.isCircle ? await this.getCircle() : this.polygonGeojson;
          await setTimeoutPromise(250);
          fit(geometry);
        }
      });

      const fit = async (geometry) => {
        const [west, south, east, north] = await this.broker.bbox({
          ...geometry,
          geometry: {
            ...geometry.geometry,
            coordinates: toRaw(geometry.geometry.coordinates),
          },
        });
        const bounds = [
          [west, south],
          [east, north],
        ];
        this.map.fitBounds(bounds, {
          padding: 40,
          duration: 1000,
        });
      };
    },
    fitToIdealBounds() {
      this.map.fitBounds(this.idealBounds.toArray(), {
        padding: 100,
        duration: MapService.MAP_VIEW_CHANGE_DURATION,
        maxZoom: MapService.MAX_AUTO_ZOOM,
      });
    },
    async getCircle() {
      const circle = await this.broker.circle({
        center: [this.longitude, this.latitude],
        radius: this.radius,
        options: {
          steps: Math.ceil(1000 * this.radius),
          units: this.radiusUm === 'mi' ? 'miles' : 'kilometers',
        },
      });
      circle.properties.geofenceType = 'Circle';

      this.circleGeoJsonData = {
        type: 'geojson',
        data: circle,
      };

      return circle;
    },
    async onMapLoad(event) {
      /**
       * Don't put map in data or this happens b/c Vue adds a bunch of observers: https://stackoverflow.com/questions/50824353/mapbox-style-changes-breaks-on-zoom-when-a-layer-is-added
       * Even without data we can still put the map on this: https://github.com/vuejs/vue/issues/2637#issuecomment-207076744
       */
      this.map = event.map;

      const placeFences = {
        ...placeFencesSource.getConfig(),
        data: { type: 'FeatureCollection', features: this.otherPlacesFencesGeoJson },
      };
      const placePoints = {
        ...placePointsSource.getConfig(),
        data: { type: 'FeatureCollection', features: this.otherPlacesPointsGeoJson },
      };

      event.map.addSource(placeFencesSource.getId(), placeFences);
      event.map.addSource(placePointsSource.getId(), placePoints);

      event.map.addLayer({
        ...fencesBoundaryLayer,
        layout: { ...fencesBoundaryLayer.layout, ...{ visibility: 'none' } },
      });
      event.map.addLayer({ ...fencesLayer, layout: { ...fencesLayer.layout, ...{ visibility: 'none' } } });
      event.map.addLayer({ ...pointsLayer, layout: { ...pointsLayer.layout, ...{ visibility: 'none' } } });

      event.map.resize();

      event.map.once('idle', async () => {
        this.mapLoaded = true;

        if (this.latitude && this.longitude) {
          this.center = [this.longitude, this.latitude];
          this.fitBounds();
        } else {
          this.fitToIdealBounds();
        }

        if (this.placesInitialized && !this.isCircle) {
          this.setPolygonMode();
        }

        await this.$nextTick();

        this.$emit('init');
      });

      event.map.on('draw.update', this.updatePolygon);

      this.toggleOtherPlaces();
    },
    async onPopupShow() {
      await this.$nextTick();
      if (isFullscreen()) {
        this.$refs.map.addInnerMapElement(document.querySelector('.q-menu,.q-dialog:last-child'));
      }
    },
    toggleOtherPlaces() {
      const layers = ['place-fences', 'place-fences-boundary', 'place-points'];
      layers.forEach((layerId) => {
        const layer = this.map.getLayer(layerId);
        if (layer) {
          this.map.setLayoutProperty(layerId, 'visibility', this.showOtherPlaces ? 'visible' : 'none');
        }
      });
    },
    removePolygon() {
      if (this.draw?.added) {
        this.draw.added = false;
        this.draw.deleteAll();
        this.map.removeControl(this.draw);
      }
    },
    setPolygonMode() {
      if (this.mapLoaded && !this.draw?.added) {
        this.draw.added = true;
        this.map.addControl(this.draw);
        this.updateCircleLayerVisibility();
        this.draw.add(this.polygonGeojson);
        this.draw.changeMode('direct_select', { featureId: 'polygon' });
      }
    },
    setCircleMode() {
      if (this.mapLoaded) {
        this.removePolygon();
        this.updateCircleLayerVisibility();
      }
    },
    async updateCenter(event) {
      const coordinates = event.target.getLngLat();
      this.$emit('place-update', {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      });
      this.fitBounds();
    },
    updateCircleLayerVisibility() {
      const layers = ['circle', 'circle-line'];
      layers.forEach((layerId) => {
        const layer = this.map.getLayer(layerId);
        if (layer) {
          this.map.setLayoutProperty(layerId, 'visibility', this.isCircle && this.hasLatLong ? 'visible' : 'none');
        }
      });
    },
    updatePolygon(mapboxDrawEvent) {
      const [feature] = mapboxDrawEvent.features;

      if (!feature) {
        return;
      }

      const [longitude, latitude] = centroid(feature)?.geometry?.coordinates || [];

      this.$emit('place-update', {
        latitude,
        longitude,
        geometry: mapboxDrawEvent.features[0],
      });
    },
    updateRadius(radius) {
      this.$emit('place-update', { radius });
      this.fitBounds();
    },
  },
  async mounted() {
    import('@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css');
    const MapboxDraw = (await import('@mapbox/mapbox-gl-draw')).default;
    this.radii = Place.getRadiusOptions(this.radius);
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      styles: style,
    });
  },
  watch: {
    hasLatLong(_, hadLatLong) {
      if (this.hasLatLong && !hadLatLong) {
        this.fitBounds();
      }
    },
    async type() {
      const { latitude, longitude } = this;

      if (!_isNil(latitude) && !_isNil(longitude)) {
        if (this.isCircle) {
          this.setCircleMode();
        } else {
          this.setPolygonMode();
          if (!this.hasExistingPolygon) {
            this.$emit('place-update', { geometry: this.polygonGeojson });
          }
        }
        this.fitBounds();
      } else {
        this.removePolygon();
        this.updateCircleLayerVisibility();
        this.fitToIdealBounds();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.radius-control {
  position: absolute;
  top: 10px;
  right: 48px;
}

.q-field {
  background: $white;
  border-radius: 4px 4px 0 0;
}
</style>
