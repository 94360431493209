<template>
  <ZubieChip
    :color="color"
    :dense="dense"
    :no-copy="noCopy"
    :removable="removable"
    :tabindex="tabindex"
    @remove="$emit('remove', $event)"
  >
    <template #icon>
      <div class="row no-wrap items-center" :class="{ 'avatar--dense': dense }">
        <TagAvatar :size="dense ? '15px' : '20px'" :tag="tag" />
      </div>
    </template>
    {{ value }}
  </ZubieChip>
</template>

<script>
import _capitalize from 'lodash/capitalize';
import ZubieChip from 'components/chips/ZubieChip.vue';
import TagAvatar from 'components/tags/TagAvatar.vue';

export default {
  name: 'TagChip',
  components: {
    TagAvatar,
    ZubieChip,
  },
  props: {
    capitalize: Boolean,
    color: String,
    dense: {
      type: Boolean,
      default: false,
    },
    noCopy: Boolean,
    removable: {
      type: Boolean,
      default: false,
    },
    tabindex: Number,
    tag: Object,
  },
  computed: {
    value() {
      const { value } = this.tag;
      return this.capitalize ? _capitalize(value) : value;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.q-chip) {
  padding-right: 0.5em;
}

:deep(.q-avatar) {
  margin-left: -4px;
}

:deep(.avatar--dense .q-avatar) {
  margin-right: 4px;

  .q-icon {
    line-height: 15px;
  }
}

:deep(.q-chip__icon--remove) {
  line-height: 15px;
}
</style>
