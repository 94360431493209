<template>
  <MapboxControl v-if="$q.screen.xs" position="top-left" tooltip="Map Filters" @click="dialogOpen = true">
    <FiltersBadge />
    <FilterSvg class="q-ma-xs" />
    <MapFiltersDialog :visible="dialogOpen" @hide="dialogOpen = false" />
  </MapboxControl>
</template>

<script>
import FiltersBadge from 'components/map/FiltersBadge.vue';
import MapboxControl from 'components/map/MapboxControl.vue';
import MapFiltersDialog from 'components/map/MapFiltersDialog.vue';
import FilterSvg from 'src/assets/noun_filter_1411705.svg';

export default {
  name: 'MapFiltersControl',
  components: {
    FilterSvg,
    MapboxControl,
    MapFiltersDialog,
    FiltersBadge,
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
};
</script>
