import { Notify } from 'quasar';
import { toRaw } from 'vue';
import ErrorPayload from 'src/models/ErrorPayload';

/**
 * @param {import('src/types/place-store').PlacesStoreActionContext} context
 */
export async function init(context) {
  await context.dispatch('getPlaces');
  context.dispatch('map/updatePlacesLayer', null, { root: true });
  context.dispatch('search/verifyHistory', 'place', { root: true });
  await context.commit('initialized');
}

/**
 * Retrieves Places from the API.
 *
 * @param {import('src/types/place-store').PlacesStoreActionContext} context
 */
export async function getPlaces(context) {
  context.commit('incrementPendingRequests');
  const places = await context.rootState.app.broker.getPlaces();

  if (!places.error) {
    context.commit('setPlaces', places);
  }

  context.commit('decrementPendingRequests');
  return context.getters.places;
}

/**
 * Deletes a place via the API.
 *
 * @param {import('src/types/place-store').PlacesStoreActionContext} context
 * @param {string} key
 */
export async function deletePlace(context, key) {
  context.commit('incrementPendingRequests');

  const place = context.getters.places.find(({ key: placeKey }) => placeKey === key);

  const response = await context.rootState.app.apiWorker.deletePlace({
    key,
    name: place.name,
  });

  if (!response.error) {
    context.commit('removePlace', key);
    context.rootState.app.broker.clearPlacesCache();
  }

  context.commit('decrementPendingRequests');

  return !response.error;
}

/**
 * Creates or updates a place via the Data Broker.
 *
 * @param {import('src/types/place-store').PlacesStoreActionContext} context
 * @param {Place} place
 */
export async function savePlace(context, place) {
  context.commit('incrementPendingRequests');

  const params = {
    data: {
      ...place,
      geometry: toRaw(place.geometry),
    },
    name: place.name,
  };
  let fn = 'createPlace';
  let mutation = 'insertPlace';

  if (place.key) {
    fn = 'updatePlace';
    mutation = fn;
    params.key = place.key;
  }

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn,
    params,
    transformationClass: 'PlaceTransformer',
  });

  if (response && !response.error) {
    context.commit(mutation, response);
    context.dispatch('map/updatePlacesLayer', null, { root: true });
    context.rootState.app.broker.clearPlacesCache();
  }

  context.commit('decrementPendingRequests');
}

/**
 * Retrieves a list of visits for a given place via the API.
 *
 * @param {import('src/types/place-store').PlacesStoreActionContext} context
 * @param {Object} payload
 * @param {string} payload.key
 * @param {boolean} payload.followCursor
 * @param {string} payload.from
 * @param {number} payload.size
 * @param {string} payload.to
 * @returns {Promise<PlaceVisitData[]>}
 */
export async function getPlaceVisits(context, { key, followCursor, from, size = 10, to }) {
  if (!followCursor) {
    context.commit('setPlaceVisits', []);
  }

  const params = {
    key,
    from,
    size: !followCursor ? size * 2 : size, // double size so we have at least 2 pages on first load
    to,
  };

  if (followCursor) {
    params.cursor = context.state.placeVisitsCursor;
  }

  try {
    const response = await context.rootState.app.broker.fetchAndTransform({
      fn: 'getPlaceVisits',
      params,
    });

    if (response.errors || response.error) {
      return new ErrorPayload(response.errors || response.error);
    }

    let visits = followCursor ? context.state.placeVisits : [];

    visits = [...visits, ...response.visits];

    context.commit('setPlaceVisits', visits);
    context.commit('setPlaceVisitsCursor', response.cursor);

    return response.visits;
  } catch (error) {
    return new ErrorPayload(error.message);
  }
}

/**
 * Gets the best place match for a give point
 *
 * @param {import('src/types/place-store').PlacesStoreActionContext} context
 * @param {Object} payload
 * @param {number} payload.lat
 * @param {number} payload.lng
 * @returns {Promise<PlaceData | ErrorPayload>}
 */
export async function getPlaceAtPoint(context, { lat, lng }) {
  try {
    const place = await context.rootState.app.broker.getPlaceByCoords({
      lat,
      lng,
    });

    if (place && place.error) {
      return new ErrorPayload(place.error);
    }
    return place;
  } catch (error) {
    return new ErrorPayload(error.message);
  }
}

/**
 * Sets the place visibility of a given place (by key).
 *
 * @param {import('src/types/place-store').PlacesStoreActionContext} context
 * @param {Object.<string, boolean>} placeVisibility
 */
export async function setPlaceVisibility(context, placeVisibility) {
  context.commit('setPlaceVisibility', placeVisibility);
  context.dispatch('map/updatePlacesLayer', null, { root: true });
}

/**
 * Unhides all places.
 *
 * @param {import('src/types/place-store').PlacesStoreActionContext} context
 */
export async function unhideAllPlaces(context) {
  context.commit('unhideAllPlaces');
  context.dispatch('map/updatePlacesLayer', null, { root: true });
  Notify.create({
    color: 'electric-yellow-light',
    textColor: 'brown-10',
    icon: 'visibility',
    message: 'All hidden places have been un-hidden.',
  });
}
