import AssetsSource from './AssetsSource';
import PlaceFencesSource from './PlaceFencesSource';
import PlacePointsSource from './PlacePointsSource';
import SearchResultsSource from './SearchResultsSource';
import SelectedAssetSource from './SelectedAssetSource';
import VehicleDetailsSource from './VehicleDetailsSource';

const exported = {
  assetsSource: new AssetsSource(),
  placeFencesSource: new PlaceFencesSource(),
  placePointsSource: new PlacePointsSource(),
  searchResultsSource: new SearchResultsSource(),
  selectedAssetSource: new SelectedAssetSource(),
  vehicleDetailsSource: new VehicleDetailsSource(),
};

export default exported;
