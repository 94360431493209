import SimpleModel from './SimpleModel';

/**
 * @typedef AccountIdNickPartner
 * @property {String} id
 * @property {String} nickname
 * @property {String} partnerName
 */

/**
 * @typedef Invitee
 * @property {String} accountRole
 */

/**
 * @typedef Inviter
 * @property {String} firstName
 * @property {String} lastName
 */

class Invite extends SimpleModel {
  /** @type {AccountIdNickPartner} */
  account = null;

  /** @type {Invitee} */
  invitee = null;

  /** @type {Inviter} */
  inviter = null;

  /** @type {String} */
  token = null;

  constructor(data) {
    super();
    this.insert(data);
  }
}

export default Invite;
