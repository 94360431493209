<template>
  <div>
    <q-field
      clearable
      dense
      filled
      label="Search"
      :loading="isGeocoding || isLocating"
      :model-value="searchTerms"
      @blur="onBlur"
      @clear="onClear"
      @focus="onFocus"
      @update:model-value="onValueUpdate"
    >
      <template v-slot:control>
        <div id="geocoderEl" ref="geocoderEl" class="geocoder"></div>
        <ToolTip>Search for a Location</ToolTip>
      </template>
    </q-field>
    <q-card class="absolute z-index-1">
      <transition name="slideHeight">
        <q-list v-if="showResults" class="full-width bg-white">
          <q-item
            v-for="item in options"
            v-ripple
            clickable
            style="max-width: calc(100vw - 32px)"
            @click="selectResult(item)"
          >
            <q-item-section>
              <q-item-label>{{ item.text }}</q-item-label>
              <q-item-label caption>{{ item.place_name }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </transition>
    </q-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ToolTip from 'components/ToolTip.vue';
import { getCurrentPosition } from 'src/services/geolocation';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  name: 'PlaceGeocoder',
  components: {
    ToolTip,
  },
  props: {
    error: String,
  },
  data: () => ({
    blurTimeout: null,
    geocoder: null,
    isLocating: false,
    isWaitingForLocation: false,
    searchTerms: '',
    locationGiveUpTimeout: null,
    numPendingGeocodingRequests: 0,
    options: null,
    searchNearCurrent: false,
    showResults: false,
    doneGettingCurrentLocation: false,
  }),
  computed: {
    ...mapState('env', ['mapboxAccessToken']),
    isGeocoding() {
      return this.numPendingGeocodingRequests > 0;
    },
  },
  methods: {
    onBlur() {
      this.blurTimeout = setTimeout(() => {
        this.showResults = false;
      }, 300);
    },
    async onFocus() {
      clearTimeout(this.blurTimeout);
      this.getCurrentLocation();
      this.showResults = true;
    },
    onChange() {
      setTimeout(() => {
        this.$refs.input.focus();
      }, 100);
    },
    geocode(query) {
      if (this.geocoder) {
        return this.geocoder.query(query);
      }
      return Promise.reject(new Error("The geocoder wasn't successfully initialized"));
    },
    onClear() {
      this.searchTerms = '';
      this.options = [];
      if (this.geocoder) {
        this.geocoder.clear();
        this.geocoder.setInput('');
      }
    },
    /**
     * Handler used to trigger initial current location lookup.
     */
    async onValueUpdate(value) {
      this.searchTerms = value;
      this.getCurrentLocation();
    },
    /**
     * Sets a timeout for the current location lookup (each lookup).
     */
    async setLocationTimeout() {
      await setTimeoutPromise(5000);
      if (this.doneGettingCurrentLocation && !this.isLocating) {
        return; // already finished, ignore timeout
      }
      throw new Error('Timed out when getting current location');
    },
    /**
     * Sets a timeout for all subsequent current location lookups. This is
     * mainly to handle the situation where lookups never finish.
     * - is set the first time a lookup occurs
     * - will stop trying for location after timeout is reached
     */
    async setLocationGiveUpTimeout() {
      if (this.locationGiveUpTimeout || this.doneGettingCurrentLocation) {
        return; // already waiting or done
      }

      this.locationGiveUpTimeout = setTimeoutPromise(15000);
      await this.locationGiveUpTimeout;

      throw new Error('Giving up trying to get current location');
    },
    /**
     * Retrieves the current position using the Geolocation API.
     * - will set the proximity for the geocoder if successful
     * - stops trying for location once promise resolves, success/failure
     */
    async getCurrentLocation() {
      if (this.doneGettingCurrentLocation || this.isLocating) {
        return;
      }

      try {
        this.isLocating = true;

        // Set the timeout in the event that the promise never resolves
        this.setLocationTimeout();
        // Also set a timeout to give up entirely
        this.setLocationGiveUpTimeout();

        const position = await getCurrentPosition();
        this.setProximity(position);
      } catch {
        // do nothing
      } finally {
        this.isLocating = false;
        this.doneGettingCurrentLocation = true;
        clearTimeout(this.locationGiveUpTimeout);
      }

      // Trigger geocode search after getting (or not getting) current location
      if (this.searchTerms) {
        this.geocode(this.searchTerms);
      }
    },
    /**
     * Sets the geocoder's proximity to the given position.
     */
    setProximity(position) {
      const { coords } = position;
      this.geocoder.setProximity([coords.longitude, coords.latitude]);
    },
    handleGeocoderError(error) {
      this.numPendingGeocodingRequests -= 1;
      console.error(error.stack);
    },
    handleGeocoderLoading({ query }) {
      this.numPendingGeocodingRequests += 1;
      this.searchTerms = query;
    },
    handleGeocoderResults(results) {
      this.numPendingGeocodingRequests -= 1;
      this.options = results.features;
    },
    async selectResult(result) {
      this.$emit('result', result);
      await setTimeoutPromise(300);
      this.onClear();
    },
    focus() {
      this.$refs.input.focus();
    },
  },
  async mounted() {
    const MapboxGeocoder = (await import('@mapbox/mapbox-gl-geocoder')).default;

    // wait for child components to render DOM
    this.$nextTick(async () => {
      if (this.$refs.geocoderEl) {
        this.geocoder = new MapboxGeocoder({
          accessToken: this.mapboxAccessToken,
          proximity: [-98.5794797, 39.8283459], // center of U.S.
          collapsed: true,
          placeholder: '',
        });
        this.geocoder.addTo(this.$refs.geocoderEl);
        const geocoderInput = this.$refs.geocoderEl.querySelector('input');
        geocoderInput.classList.add('q-field__native');
        geocoderInput.placeholder = ''; // force placeholder (option doesn't work)
        this.geocoder.on('error', this.handleGeocoderError);
        this.geocoder.on('loading', this.handleGeocoderLoading);
        this.geocoder.on('results', this.handleGeocoderResults);
      }
    });
  },
  beforeUnmount() {
    if (this.geocoder) {
      this.geocoder.off('error', this.handleGeocoderError);
      this.geocoder.off('loading', this.handleGeocoderLoading);
      this.geocoder.off('results', this.handleGeocoderResults);
    }
  },
};
</script>

<style scoped lang="scss">
// Hide elements of geocoder that we don't want
:deep(.suggestions-wrapper),
:deep(.mapboxgl-ctrl-geocoder--icon),
:deep(.mapboxgl-ctrl-geocoder--pin-right) {
  display: none;
}

.geocoder,
:deep(.mapboxgl-ctrl-geocoder--input) {
  width: 100%;
}

.slideHeight-enter-from,
.slideHeight-leave-to {
  max-height: 0;
}

.slideHeight-leave-from,
.slideHeight-enter-to {
  max-height: 300px;
}

.slideHeight-enter-active,
.slideHeight-leave-active {
  overflow: hidden;
  transition: max-height 0.25s;
  will-change: max-height;
}
</style>
