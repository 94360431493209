/**
 * Returns a combination of first and last name
 *
 * @param {String} firstName
 * @param {String} lastName
 * @returns {String}
 */
export function getFullName(firstName, lastName) {
  const name = [];

  if (firstName) {
    name.push(firstName);
  }

  if (lastName) {
    name.push(lastName);
  }

  return name.join(' ');
}
