<template>
  <div class="row items-center">
    <div class="col-3">{{ label }}</div>
    <div class="col text-black text-cursor" @click.prevent.stop>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MappedItemDetailsRow',
  props: {
    label: {
      required: true,
      type: String,
    },
  },
};
</script>
