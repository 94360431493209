/* eslint class-methods-use-this:0 */
import polyline from '@mapbox/polyline';
import formatcoords from 'formatcoords';
import Asset from 'src/models/Asset';
import Bounds from 'src/models/Bounds';
import AssetSvgMarker from 'src/services/svg/AssetSvgMarker';
import { markersByStatus as svgMarkers } from 'src/services/svg/index';

class Map {
  MAP_VIEW_CHANGE_DURATION = 1000;

  /** @type {mapboxgl.Map | null} */
  map = null;

  onReadyCallbacks = [];

  onReadyOnceCallbacks = {};

  /**
   * Constructor for Map class.
   *
   * @param {Object} map Map instance
   */
  constructor(map) {
    if (map) {
      this.setMap(map);
    }
  }

  setMap(map) {
    this.map = map;

    if (this.onReadyCallbacks.length > 0) {
      this.onReadyCallbacks.forEach((callback) => {
        callback(map);
      });
      this.onReadyCallbacks = [];
    }

    if (Object.values(this.onReadyOnceCallbacks).length > 0) {
      Object.values(this.onReadyOnceCallbacks).forEach((callback) => {
        callback(map);
      });
      this.onReadyOnceCallbacks = {};
    }
  }

  addControl() {
    throw new Error('Map.addControl() method is not implemented!');
  }

  addLayer() {
    throw new Error('Map.addLayer() method is not implemented!');
  }

  addSource() {
    throw new Error('Map.addSource() method is not implemented!');
  }

  boundsContains() {
    throw new Error('Map.boundsContains() method is not implemented!');
  }

  easeTo() {
    throw new Error('Map.easeTo() method is not implemented!');
  }

  getBounds() {
    throw new Error('Map.getBounds() method is not implemented!');
  }

  getCenter() {
    throw new Error('Map.getCenter() method is not implemented!');
  }

  getLayer() {
    throw new Error('Map.getLayer() method is not implemented!');
  }

  getSource() {
    throw new Error('Map.getSource() method is not implemented!');
  }

  fitBounds() {
    throw new Error('Map.fitBounds() method is not implemented!');
  }

  isLoaded() {
    return Boolean(this.map);
  }

  isMoving() {
    throw new Error('Map.isMoving() method is not implemented!');
  }

  on() {
    throw new Error('Map.on() method is not implemented!');
  }

  once() {
    throw new Error('Map.once() method is not implemented!');
  }

  onLayerClick() {
    throw new Error('Map.onLayerClick() method is not implemented!');
  }

  onMapChange() {
    throw new Error('Map.onMapChange() method is not implemented!');
  }

  onReady(callback) {
    if (!this.map) {
      this.onReadyCallbacks.push(callback);
    } else {
      callback(this.map);
    }
  }

  onReadyOnce(id, callback) {
    if (!this.map) {
      this.onReadyOnceCallbacks[id] = callback;
    } else {
      callback(this.map);
    }
  }

  panTo() {
    throw new Error('Map.panTo() method is not implemented!');
  }

  removeFeatureState() {
    throw new Error('Map.removeFeatureState() method is not implemented!');
  }

  resize() {
    throw new Error('Map.resize() method is not implemented!');
  }

  setFeatureState() {
    throw new Error('Map.setFeatureState() method is not implemented!');
  }

  trigger() {
    throw new Error('Map.trigger() method is not implemented!');
  }

  /**
   * Generates and adds SVGs for each variation of vehicle/asset status/tag color.
   *
   * @param {Map} map
   * @param {Array<Asset|Vehicle>} assets
   * @param {Boolean} includeTagColors
   */
  static async addSvgMarkerImages(map, assets, includeTagColors = false) {
    if (!map) {
      return;
    }

    const imageIds = [];

    const assetsWithImagesToAdd = assets.filter((asset) => {
      const id = this.getAssetMarkerId(asset, includeTagColors);

      /**
       * Ensure map doesn't already have the ID and that the only unique IDs
       * are created from the current collection.
       */
      if (map.hasImage(id) === true || imageIds.includes(id)) {
        return false;
      }

      imageIds.push(id);

      return true;
    });

    const images = [];
    await Promise.all(
      assetsWithImagesToAdd.map(async (asset) => {
        let tagColor;
        if (includeTagColors) {
          const { firstTagColor } = asset;
          // Get first tag color
          tagColor = firstTagColor;
        }

        images.push({
          id: this.getAssetMarkerId(asset, includeTagColors),
          image: await this.makeAssetSvg(asset, tagColor),
        });

        images.push({
          id: `${this.getAssetMarkerId(asset, includeTagColors)}-outline`,
          image: await this.makeAssetSvg(asset, tagColor, true),
        });
      })
    );

    images.forEach(({ image, id }) => {
      if (image === null) {
        // Do not add invalid images or images that have already been added
        return;
      }
      map.addImage(id, image);
    });
  }

  /**
   * Generates a marker ID for the given asset.
   *
   * @param {Asset|Vehicle} asset
   * @param {Boolean} includeTagColors
   * @returns {String}
   */
  static getAssetMarkerId(asset, includeTagColors) {
    let idSuffix = '';
    if (includeTagColors) {
      const { firstTagColor } = asset;
      idSuffix += `-${firstTagColor}`;
    }
    return `${asset.geoJsonMarkerId}${idSuffix}`;
  }

  /**
   * Calculates longitude & latitude boundaries that encompass on a given set of points.
   *
   * @param {Array} points
   * @returns {Array|null}
   */
  static calculateBoundsByPoints(points) {
    if (points.length === 0) {
      return null;
    }

    const firstPoint = points[0];
    const idealBounds = points.reduce(
      (bounds, [longitude, latitude]) => {
        const longMin = longitude < bounds.longMin ? longitude : bounds.longMin;
        const longMax = longitude > bounds.longMax ? longitude : bounds.longMax;
        const latMin = latitude < bounds.latMin ? latitude : bounds.latMin;
        const latMax = latitude > bounds.latMax ? latitude : bounds.latMax;
        return {
          longMin,
          longMax,
          latMin,
          latMax,
        };
      },
      {
        longMin: firstPoint[0],
        longMax: firstPoint[0],
        latMin: firstPoint[1],
        latMax: firstPoint[1],
      }
    );

    return [
      [idealBounds.longMin, idealBounds.latMin],
      [idealBounds.longMax, idealBounds.latMax],
    ];
  }

  /**
   * Returns the bounds that encompasses all points in the given encoded polyline.
   *
   * @param {Object} encodedPolyline
   * @returns {Bounds}
   */
  static calculateBoundsByPolyline(encodedPolyline) {
    const geoJson = polyline.toGeoJSON(encodedPolyline);
    return new Bounds(Map.calculateBoundsByPoints(geoJson.coordinates));
  }

  /**
   * Converts the given coordinates ([long, lat]) to a degrees, minutes, and seconds (DMS) string.
   *
   * @param {number[]} coordinates
   * @returns {string}
   */
  static dmsCoordinates(coordinates) {
    if (!coordinates) {
      return '';
    }

    // Assume coordinates are in long,lat format and reverse it
    try {
      const coordsObj = formatcoords(...[...coordinates].reverse());
      return coordsObj.format();
    } catch {
      return '';
    }
  }

  /**
   * Generates an SVG for the given vehicle.
   *
   * @param {Asset|import('src/models/Vehicle').default} asset
   * @param {String} tagColor
   * @param {Boolean} outline
   * @returns {Promise<HtmlImageElement>}
   */
  static async makeAssetSvg(asset, tagColor = null, outline = false) {
    // Return a null image so it is excluded
    const { iconId } = asset;

    /** @type {import('src/services/svg/SvgMarker').default} */
    let svgMarker;
    if (asset instanceof Asset) {
      svgMarker = new AssetSvgMarker(asset.type.iconUri);
    } else {
      svgMarker = svgMarkers[iconId] || svgMarkers.stopped;
    }

    return svgMarker.toImage(tagColor, outline);
  }
}

export default Map;
