import GeoJsonLayer from 'src/services/geoJson/GeoJsonLayer';
import NearbyIsoSource from '../sources/NearbyIsoSource';

export default class NearbyIsoLayer extends GeoJsonLayer {
  /** @type {String} */
  static id = 'nearby-iso';

  /**
   * @returns {Object} A GeoJson layer config object.
   */
  getConfig() {
    return Object.freeze({
      id: this.getId(),
      type: 'fill',
      source: NearbyIsoSource.id,
      layout: {},
      paint: {
        'fill-color': { type: 'identity', property: 'color' },
        'fill-opacity': 0.2,
      },
    });
  }
}
