<template>
  <q-popup-proxy
    :breakpoint="599"
    class="notifications-menu"
    :model-value="show"
    no-parent-event
    :offset="offset"
    transition-hide="jump-up"
    transition-show="jump-down"
    @before-show="onBeforeShow"
    @hide="onHide"
  >
    <q-list class="bg-white">
      <q-item>
        <q-item-section>
          <div class="row justify-between">
            <div class="zubie-font text-subtitle3">Notifications</div>
            <q-btn
              v-if="notificationsDeferred || (notificationsDenied && $q.platform.is.capacitor)"
              class="text-weight-regular"
              color="white"
              dense
              flat
              :label="`Enable ${$q.platform.is.capacitor ? 'push' : 'web'} notifications?`"
              no-caps
              padding="none sm"
              text-color="primary"
              @click="requestPermission"
            />
          </div>
        </q-item-section>
      </q-item>
      <q-separator class="separator" color="grey-5"></q-separator>
      <div v-if="hasNotifications" class="notifications-list" data-t="notifications-list">
        <NotificationsMenuRow
          v-for="(notification, index) in notifications"
          :key="notification.key"
          :last-update="lastUpdate"
          :notification="notification.allProperties"
          :separator="index !== 0"
        />
      </div>
      <div v-else class="flex justify-center align-center" data-t="notifications-loading">
        <q-spinner class="q-ma-md" color="primary" size="3em" />
      </div>
      <q-separator class="separator" color="grey-5"></q-separator>
      <q-item class="q-px-none q-py-sm">
        <q-item-section>
          <div class="q-pr-xs row justify-end">
            <q-btn
              class="view-more"
              color="white"
              flat
              icon-right="arrow_forward"
              square
              text-color="primary"
              to="/notifications"
            >
              View More
            </q-btn>
          </div>
        </q-item-section>
      </q-item>
    </q-list>
  </q-popup-proxy>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import { mapActions, mapGetters } from 'vuex';
import NotificationsMenuRow from 'components/notifications/NotificationsMenuRow.vue';
import { now } from 'src/services/date';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  name: 'NotificationsMenuItem',
  props: {
    offset: {
      type: Array,
      default: () => [0, 0],
    },
    show: Boolean,
  },
  components: {
    NotificationsMenuRow,
  },
  computed: {
    ...mapGetters('notifications', {
      notificationsDeferred: 'deferred',
      notificationsDenied: 'denied',
      notifications: 'recent',
    }),
    hasNotifications() {
      return !_isEmpty(this.notifications);
    },
  },
  data() {
    return {
      isShown: false,
      lastUpdate: null,
      leftStyle: 0,
      maxWidthStyle: '100%',
    };
  },
  methods: {
    ...mapActions('notifications', ['markAllRecentAsRead', 'requestPermission']),
    checkForUread() {
      if (this.isShown) {
        // Check if any notifications are unread
        if (this.notifications.some(({ messageRead }) => !messageRead)) {
          this.markAllRecentAsRead();
        }
      }
    },
    onBeforeShow() {
      document.body.classList.add('notifications-menu-open');
      this.isShown = true;
      this.lastUpdate = now();
      this.checkForUread();
    },
    async onHide() {
      this.isShown = false;
      this.$emit('hide');

      await setTimeoutPromise(300);
      // Make sure the menu is still closed/hidden
      if (!this.isShown) {
        document.body.classList.remove('notifications-menu-open');
      }
    },
  },
  watch: {
    notifications() {
      this.checkForUread();
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications-list {
  max-width: 100%;
  min-width: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width: 500px) {
  .notifications-list {
    min-width: 100%;
  }
}

:deep(.fade-leave) {
  opacity: 1;
}

:deep(.fade-leave-active) {
  transition: opacity 0.5s;
  opacity: 0;
}

.view-more {
  text-decoration: none;
}
</style>

<style lang="scss">
.notifications-menu-open .q-menu {
  z-index: 2001 !important;
  max-width: 500px !important;
  min-width: 500px;
  overflow: hidden !important;

  @media (min-width: $breakpoint-sm) {
    right: 46px !important;
  }
}

@media (max-width: 500px) {
  .notifications-menu-open .q-menu {
    left: 0 !important;
    right: 0 !important;
    max-width: 100% !important;
    min-width: 0;
  }
}

.notifications-list {
  max-height: calc(65vh - (48px + 102px)); // 48px from Quasar
}

.fullscreen .notifications-list {
  max-height: calc(100vh - (48px + 102px)); // 48px from Quasar
}
</style>
