<template>
  <q-card v-show="share?.source === source" :class="cardClasses" :flat="flat">
    <div v-if="!sharesLoading">
      <q-card-section :class="cardSectionClasses">
        <CopyShareField v-if="url" :link="url" />
        <div class="text-caption q-my-sm">{{ message }}</div>
        <q-btn
          class="q-mb-sm"
          color="primary"
          :href="url"
          icon-right="open_in_new"
          :label="buttonText"
          size="md"
          squard
          target="_blank"
          type="a"
        />
        <slot name="after" />
      </q-card-section>
    </div>
  </q-card>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import CopyShareField from 'components/shares/CopyShareField.vue';
import { copy } from 'src/services/clipboard';
import { SHARE_TYPES } from 'src/services/constants';
import { dayjs, utcToLocalDateTime } from 'src/services/date';

export default {
  name: 'ExistingShareCard',
  props: {
    buttonText: {
      default: 'View Location Link',
      type: String,
    },
    cardClasses: {
      default: 'q-px-sm q-py-none',
      type: String,
    },
    cardSectionClasses: {
      default: 'q-pt-none',
      type: String,
    },
    flat: {
      default: false,
      type: Boolean,
    },
    id: {
      reqired: true,
      type: String,
    },
    limit: {
      default: 1,
      type: Number,
    },
    source: {
      reqired: true,
      validator(value) {
        return Object.values(SHARE_TYPES).includes(value);
      },
    },
    type: {
      reqired: true,
      validator(value) {
        return ['asset', 'account', 'user', 'source', 'vehicle'].includes(value);
      },
    },
  },
  components: {
    CopyShareField,
  },
  computed: {
    ...mapState('app', ['broker']),
    ...mapState('env', ['locatorUrl']),
    ...mapState('shares', ['sharesLoading']),
    ...mapState('shares', {
      allShares: 'shares',
    }),
    ...mapState('users', ['users']),
    expiration() {
      const { share } = this;
      if (share) {
        return utcToLocalDateTime(dayjs(share.expirationTimestamp));
      }
      return '';
    },
    message() {
      const { expiration, user } = this;
      if (expiration) {
        return user ? `Shared until ${expiration} by ${user}` : `Shared until ${expiration}`;
      }
      return user ? `Shared by ${user}` : '';
    },
    shares() {
      return this.allShares.filter(
        ({ assetKeys, vehicleKeys, source }) =>
          source === this.source && (vehicleKeys.includes(this.id) || assetKeys.includes(this.id))
      );
    },
    share() {
      return this.shares[0];
    },
    url() {
      const { id } = this.share || {};
      return id ? `${this.locatorUrl}/${id}` : undefined;
    },
    user() {
      const { share, users } = this;
      if (share && share.creatorUserKey) {
        const user = users[share.creatorUserKey];
        return `${user?.firstName || ''} ${user?.lastName || ''}`;
      }
      return '';
    },
  },
  methods: {
    ...mapActions('shares', ['getShares']),
    copy() {
      copy(this.url).catch(() => {});
    },
    async getShare() {
      const { id, limit, type } = this;

      await this.getShares({
        id,
        type,
        limit,
      });

      this.$emit('loaded', this.shares);
    },
  },
  mounted() {
    const { getShare, id, type } = this;
    if (id && type) {
      getShare();
    }
  },
  watch: {
    id() {
      this.getShare();
    },
    type() {
      this.getShare();
    },
  },
};
</script>
