import _get from 'lodash/get';
import { dayjs } from 'src/services/date';
import Plan from './Plan';
import SimpleModel from './SimpleModel';

export default class Subscription extends SimpleModel {
  /** @prop {Boolean} */
  autoRenew = null;

  /** @prop {String} */
  currentPeriodEndsAt = null;

  /** @prop {String} */
  currentPeriodStartedAt = null;

  /** @prop {String[]} */
  devices = [];

  /** @prop {String} */
  id = null;

  /** @prop {Object} */
  pendingPlan = null;

  /** @prop {Plan} */
  plan = null;

  /** @prop {Number} */
  quantity = null;

  /** @prop {String[]} */
  recurringDevices = [];

  /** @prop {Number} */
  recurringQuantity = null;

  /** @prop {String} */
  startedAt = null;

  /** @prop {String} */
  status = null;

  /** @prop {String} */
  termEndDate = null;

  /** @prop {String} */
  trialEndsAt = null;

  /** @prop {String} */
  trialStartedAt = null;

  /** @prop {String} */
  url = null;

  constructor(data) {
    super();
    this.insert({
      ...data,
      pendingPlan: data.pendingPlan ? new Plan(data.pendingPlan) : null,
      plan: new Plan(data.plan),
    });
  }

  /**
   * Returns true if the subscription requires billing info.
   *
   * @returns {Boolean}
   */
  get hasRolloverPlan() {
    return _get(this, 'plan.requireBillingInfo') || false;
  }

  /**
   * Returns true if the subscription's plan is an EZ Pay plan.
   *
   * @returns {Boolean}
   */
  get isEzPay() {
    const sku = _get(this, 'plan.sku') || '';
    return sku.toLowerCase().startsWith('ez');
  }

  /**
   * Returns a formatted, user-friendly date.
   *
   * @returns {String}
   */
  get termEndDateFriendly() {
    return this.termEndDate ? dayjs(this.termEndDate).format('M/D/YYYY') : null;
  }
}
