import _omitBy from 'lodash/omitBy';
import storage from 'src/services/storage';

/**
 * @param {PlacesStoreState} state
 * @param {PlaceData[]} places
 */
export function setPlaces(state, places) {
  state.places = places;
}

/**
 * @param {PlacesStoreState} state
 */
export function incrementPendingRequests(state) {
  state.pendingRequestsCount += 1;
}

/**
 * @param {PlacesStoreState} state
 */
export function decrementPendingRequests(state) {
  state.pendingRequestsCount -= 1;
}

/**
 * @param {PlacesStoreState} state
 * @param {string} keyToRemove
 */
export function removePlace(state, keyToRemove) {
  const clone = { ...state.places };
  delete clone[keyToRemove];
  state.places = clone;
}

/**
 * @param {PlacesStoreState} state
 * @param {PlaceData} place
 */
export function insertPlace(state, place) {
  state.places = { ...state.places, [place.key]: place };
}

/**
 * @param {PlacesStoreState} state
 * @param {PlaceData} place
 */
export function updatePlace(state, place) {
  state.places = {
    ...state.places,
    [place.key]: place,
  };
}

/**
 * @param {PlacesStoreState} state
 * @param {PlaceVisitData[]} visits
 */
export function setPlaceVisits(state, visits) {
  state.placeVisits = visits;
}

/**
 * @param {PlacesStoreState} state
 * @param {string} cursor
 */
export function setPlaceVisitsCursor(state, cursor) {
  state.placeVisitsCursor = cursor;
}

/**
 * @param {PlacesStoreState} state
 */
export function initialized(state) {
  state.initialized = true;
  state.initResolve();
}

/**
 * @param {PlacesStoreState} state
 * @param {PlaceVisibilitySetting} placeVisibility
 */
export function setPlaceVisibility(state, placeVisibility) {
  state.placeVisibility = _omitBy(
    {
      ...state.placeVisibility,
      ...placeVisibility,
    },
    (value) => value
  );
  storage.set('places/placeVisibility', JSON.stringify(state.placeVisibility));
}

/**
 * @param {PlacesStoreState} state
 */
export function unhideAllPlaces(state) {
  state.placeVisibility = {};
  storage.remove('places/placeVisibility');
}
