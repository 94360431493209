import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Driver from 'src/models/Driver';
import Place from 'src/models/Place';
import PlaceVisit from 'src/models/PlaceVisit';
import { getFullName } from 'src/services/users';

/**
 * Returns all places as an array of Place objects.
 *
 * @param {Object} state
 * @returns {Place[]}
 */
export function places(state) {
  return Object.keys(state.places).map((key) => {
    const isVisible = state.placeVisibility[key];
    return new Place({ key, isVisible, ...state.places[key] });
  });
}

/**
 * Returns all hidden places.
 *
 * @param {Object} state
 * @param {Object} getters
 * @param {Place[]} getters.places
 */
export function hiddenPlaces(_, getters) {
  return getters.places.filter(({ isVisible }) => !isVisible);
}

/**
 * Returns all hidden places in key => boolean format.
 *
 * @param {Object} _
 * @param {Object} getters
 * @param {Place[]} getters.places
 * @returns {KeyBooleanObject}
 */
export function hiddenPlacesByKey(_, getters) {
  const placesByKey = {};

  getters.hiddenPlaces.forEach((place) => {
    const { key } = place;
    placesByKey[key] = place;
  });

  return placesByKey;
}

/**
 * Returns true if there are hidden places.
 *
 * @param {Object} _
 * @param {Object} getters
 * @param {KeyBooleanObject} getters.hiddenPlacesByKey
 * @returns {Boolean}
 */
export function hasHiddenPlaces(_, getters) {
  return !_isEmpty(getters.hiddenPlacesByKey);
}

/**
 * Returns true if there are more place visits to load (cursor has a value).
 *
 * @param {Object} state
 * @returns {boolean}
 */
export function hasMorePlaceVisits(state) {
  return Boolean(state.placeVisitsCursor);
}

/**
 * Returns true if the number of pending requests is greater than 0.
 *
 * @param {Object} state
 * @returns {Boolean}
 */
export function isLoading(state) {
  return state.pendingRequestsCount > 0;
}

/**
 * @typedef PlacesGeoJsonObj
 * @property {Object} fences
 * @property {Object} points
 */

/**
 * Returns the GeoJson used for place fences and points.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {PlacesGeoJsonObj}
 */
export function placesGeoJson(_, getters) {
  const fenceFeatures = { fences: [], points: [] };

  getters.places.forEach((place) => {
    const { geometry, key, isVisible, longitude, latitude, name } = place;
    fenceFeatures.fences.push({
      ...geometry,
      properties: {
        ..._get(geometry, 'properties'),
        key,
        name,
        isVisible,
      },
    });
    fenceFeatures.points.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [longitude, latitude],
      },
      properties: {
        key,
        name,
        isVisible,
      },
    });
  });

  return {
    fences: {
      type: 'FeatureCollection',
      features: fenceFeatures.fences,
    },
    points: {
      type: 'FeatureCollection',
      features: fenceFeatures.points,
    },
  };
}

/**
 * Returns a list of place visits as PlaceVisit objects.
 *
 * @param {Object} state
 * @param {Object} getters
 * @param {Object} rootState
 * @param {Object} rootGetters
 * @returns {PlaceVisit[]}
 */
export function placeVisits(state, getters, rootState, rootGetters) {
  return (
    state.placeVisits
      .filter(({ carKey }) => carKey)
      .map((visit) => ({
        ...visit,
        vehicle: rootGetters['assets/vehicles'].find(({ key }) => key === visit.carKey),
      }))
      // Only include visits with vehicles
      .filter(({ vehicle }) => vehicle)
      .map((visit) => {
        const { entryDriverKey, exitDriverKey } = visit;
        let driverArrival = rootState.users.users[entryDriverKey] || null;
        if (driverArrival) {
          const { firstName, key, lastName } = driverArrival;
          driverArrival = new Driver({ firstName, fullName: getFullName(firstName, lastName), key, lastName });
        }
        let driverDeparture = rootState.users.users[exitDriverKey] || null;
        if (driverDeparture) {
          const { firstName, key, lastName } = driverDeparture;
          driverDeparture = new Driver({ firstName, fullName: getFullName(firstName, lastName), key, lastName });
        }

        const placeVisit = new PlaceVisit({
          ...visit,
          driverArrival,
          driverDeparture,
        });

        return placeVisit;
      })
  );
}
