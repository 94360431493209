import storage from 'src/services/storage';

export default () => {
  storage.onReady((store) => {
    const placeVisibility = storage.get('places/placeVisibility') || '{}';
    store.dispatch('places/setPlaceVisibility', JSON.parse(placeVisibility));
  });

  /** @type {(value: unknown) => void} */
  let initResolve;
  const onInit = new Promise((resolve) => {
    initResolve = resolve;
  });

  return {
    initialized: false,
    initResolve,
    /** @type {Promise<void>} */
    onInit,
    pendingRequestsCount: 0,
    /** @type {PlaceVisibilitySetting} */
    placeVisibility: {},
    /** @type {PlaceVisitData[]} */
    placeVisits: [],
    placeVisitsCursor: '',
    /** @type {PlaceDataObject} */
    places: {},
  };
};
