/* eslint class-methods-use-this:0 */

import { copyToClipboard } from 'quasar';
import ClipboardHandler from './ClipboardHandler';

export default class QuasarClipboard extends ClipboardHandler {
  /**
   * Copies the given text to the Navigator's clipboard (via Quasar).
   *
   * @param {String} text
   * @returns {Promise<void>}
   */
  static copy(text) {
    return copyToClipboard(text);
  }
}
