import { Platform } from 'quasar';
import errorService from 'src/services/error';
import { hasVideoDevice } from 'src/services/media-devices';

const { ZError } = errorService;

/**
 * @param {EnvStoreActionContext} context
 */
export async function init(context) {
  try {
    const configMethod = Platform.is.capacitor ? 'getConfigMobile' : 'getConfig';
    const promise = context.rootState.app.broker.fetchAndTransform({
      fn: configMethod,
    });

    context.commit('set', { promise });
    const response = await promise;

    context.commit('set', response);

    if (response.error) {
      throw new ZError(response.error, response.metadata);
    }

    if (response && response.DEBUG && response.DEBUG.includes('browser')) {
      context.dispatch('debug/init', null, { root: true });
    }

    await context.rootState.app.apiWorker.setMapboxAccessToken(response.mapboxAccessToken);
  } catch (error) {
    errorService.notifyUnexpectedBoot(error, error.metadata);
  }
}

/**
 * Sets the browser support values.
 *
 * @param {EnvStoreActionContext} context
 * @param {{ [string: key]: unknown }} browserSupport
 */
export function setBrowserSupport(context, browserSupport) {
  context.commit('set', {
    browserSupport,
  });
}

/**
 * Detects device support and listens for devicechange events.
 *
 * @param {EnvStoreActionContext} context
 */
export async function detectDeviceSupport(context) {
  const onDeviceChange = async () => {
    const canUseCamera = await hasVideoDevice();
    context.commit('setCanUseCamera', canUseCamera);
  };
  await onDeviceChange();
  navigator.mediaDevices.addEventListener('devicechange', onDeviceChange);
}
