<template>
  <vue-easy-lightbox :imgs="items" :index="index" overlay-color="#424242" :visible="index !== null" @hide="close">
    <template #close-btn="{ close }">
      <div class="absolute close">
        <q-btn aria-label="close image button" color="white" dense flat icon="close" size="lg" @click="close" />
      </div>
    </template>
    <template #prev-btn>
      {{ index }}
      <div class="absolute prev">
        <q-btn
          aria-label="previous image button"
          color="white"
          dense
          :disable="index === 0"
          flat
          icon="skip_previous"
          size="lg"
          @click="prev"
        />
      </div>
    </template>
    <template #next-btn>
      <div class="absolute next">
        <q-btn
          aria-label="next image button"
          color="white"
          dense
          :disable="index === items.length - 1"
          flat
          icon="skip_next"
          size="lg"
          @click="next"
        />
      </div>
    </template>
  </vue-easy-lightbox>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import VueEasyLightbox from 'vue-easy-lightbox';
import { useStore } from 'vuex';

const store = useStore();

const index = computed(() => store.state.lightbox.index);
const items = computed(() => store.state.lightbox.items);

function prev() {
  store.dispatch('lightbox/prev');
}

function next() {
  store.dispatch('lightbox/next');
}

function close() {
  store.dispatch('lightbox/close');
}
</script>

<style scoped lang="scss">
.close {
  right: 8px;
  top: 8px;
}

.prev {
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.next {
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
