import SimpleModel from 'src/models/SimpleModel';
import { eventIconMap } from 'src/services/constants';
import { dayjs } from 'src/services/date';
import { NOTIFICATION_DATE_FORMAT } from 'src/services/notification';

class Notification extends SimpleModel {
  /** @type {string} */
  key = null;

  /** @type {string} */
  created = null;

  /** @type {boolean} */
  emailMessageSent = null;

  /** @type {boolean} */
  isToday = null;

  /** @type {string} */
  kind = null;

  /** @type {boolean} */
  messageRead = null;

  /** @type {string} */
  messageSummary = null;

  /** @type {string} */
  payload = null;

  /** @type {boolean} */
  pushMessageSent = null;

  /** @type {string} */
  occurrenceTimestamp = null;

  /** @type {TransformedNotification['action']} */
  action = null;

  constructor(data = {}) {
    super();
    this.insert(data);
  }

  /**
   * Returns the occurrenceTimestamp as a Dayjs object.
   *
   * @returns {Dayjs}
   */
  get occurrenceTime() {
    return dayjs(this.occurrenceTimestamp, NOTIFICATION_DATE_FORMAT);
  }

  /**
   * Returns the icon URL associated with the defined "kind".
   *
   * @returns {string}
   */
  get icon() {
    return eventIconMap[this.kind] || eventIconMap.Default;
  }
}

export default Notification;
