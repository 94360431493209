import { ORIENTATION } from 'src/services/constants';

export default () => {
  let readyResolve;
  const onReady = new Promise((resolve) => {
    readyResolve = resolve;
  });

  let routerReadyResolve;
  const onRouterReady = new Promise((resolve) => {
    routerReadyResolve = resolve;
  });

  let workersReadyResolve;
  const onWorkersReady = new Promise((resolve) => {
    workersReadyResolve = resolve;
  });

  let idbReadyResolve;
  const onIdbReady = new Promise((resolve) => {
    idbReadyResolve = resolve;
  });

  return {
    accountSettingsDialogOpen: false,
    /** @type {import('src/types/app-store').ApiWorker | null} */
    apiWorker: null,
    /** @type {import('src/workers/DataBroker').default | null} */
    broker: null,
    /** @type {import('@capacitor/core').DeviceInfo} */
    deviceInfo: null,
    idbReadyResolve,
    isFilterRoute: false,
    isOnDetailsPage: false,
    isLiveMapRoute: false,
    mainHeaderHeight: 50,
    mainNavOpen: false,
    mobileTitle: '',
    mobileTitlePending: false,
    /** @type {IosNotchSizes} */
    notchSizes: {
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
    },
    onIdbReady,
    onReady,
    onRouterReady,
    onWorkersReady,
    openDialogCount: 0,
    orientation: ORIENTATION['portrait-primary'],
    /** @type {Function | null} */
    pollingErrorDismiss: null,
    readyResolve,
    routerReadyResolve,
    takeOff: false,
    workersReadyResolve,
  };
};
