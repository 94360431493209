import Address from 'src/models/Address';

export function reverseGeocode(context, coordinates) {
  if (!coordinates || !coordinates.latitude || !coordinates.longitude) {
    return null;
  }
  const latitude = coordinates.latitude.toFixed(5);
  const longitude = coordinates.longitude.toFixed(5);
  const key = `${latitude},${longitude}`;

  if (Object.prototype.hasOwnProperty.call(context.state.reverseLookups, key)) {
    return context.state.reverseLookups[key];
  }

  let resolvePromise;

  const promise = new Promise((resolve) => {
    resolvePromise = resolve;
  });

  context.commit('setReverseLookups', { coordinates: key, address: promise });

  context.rootState.app.broker
    .getAddressByCoords({
      latitude,
      longitude,
    })
    .then((addressData) => {
      const address = new Address({ ...addressData, latitude, longitude });
      resolvePromise(address);
    })
    .catch(() => {
      // show lat/lng if geocoding isn't supported
      resolvePromise(key);
    });

  return promise;
}
