import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 80 29"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"currentColor\" fill-rule=\"evenodd\"><path d=\"M1.67.711 28.587.673 13 23.469h6.582s7.042 5.13 8.088 5.41H.792l15.55-22.643H1.67V.71z\"></path><path d=\"m27.217 4.61 15.701 10.926-3.477 4.97-1.955-1.378s1.226 4.232-2.257 7.907c-1.765 1.505-2.791 1.957-5.156 1.786-1.923-.36-3.26-1.32-4.008-1.786-.75-.467-9.93-6.808-9.93-6.808l3.604-4.938s8.305 5.793 9.627 6.692c.985.639 2.235 1.07 3.13.824 1.241-.41 2.969-2.67 1.2-6.25C26.965 11.93 23.64 9.693 23.64 9.693z\"></path><path d=\"M38.11 27.511c.017-.47 0-4.415 0-4.415s.314-.546.314-2.434c.157.107 1.256.764 1.256.764l4.252-6.07-5.806-3.93.001-10.753h6.12l.016 10.297s.58-.905 3.122-1.56c2.542-.654 5.844-.139 7.973 1.576 2.448 2.028 3.328 5.164 3.328 8.035 0 1.888-.118 2.956-.88 4.603-.614 1.328-1.751 2.387-2.809 3.276-4.174 3.51-13.637 1.702-16.887.611m9.573-13.668c-1.747-.007-3.421 1.263-3.421 1.263v8.051s.897 1.172 3.58 1.25c3.2 0 4.47-2.73 4.47-5.295 0-3.568-2.304-5.276-4.629-5.27z\"></path><path d=\"m55.46 9.486 6.567-.026v19.455h-6.541l-.053-1.623s3.983-2.387 3.983-8.102c0-5.716-3.93-8.889-3.93-8.889zm3.347-2.613a3.356 3.356 0 0 1-3.366-3.346A3.356 3.356 0 0 1 58.807.181a3.356 3.356 0 0 1 3.365 3.346 3.356 3.356 0 0 1-3.365 3.346m3.879 5.045-.027 14.209s2.874 2.84 7.933 2.745c4.177 0 6.218-1.04 8.54-2.426 0-2.17.037-4.029 0-3.909-.257.383-4.363 2.57-7.692 2.442-2.466.003-5.313-1.132-5.36-4.895 5.023.023 9.698 0 13.46 0 0-.04.796-9.93-8.707-10.85-6.694 0-8.147 2.684-8.147 2.684m3.548 5.252h7.382s-.16-3.863-3.63-3.863-3.752 3.863-3.752 3.863\"></path></g>", 1)
  ])))
}
export default { render: render }