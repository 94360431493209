export default () => ({
  appUrl: '',
  browserSupport: {
    isTouchOnly: false,
    webGl: true,
  },
  canUseCamera: false,
  /** @type {string | null} */
  churnzeroAppKey: null,
});
