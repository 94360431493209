<template>
  <MappedItemButton
    :bg-color="isActive ? 'positive' : undefined"
    :class="{ 'bg-positive': isActive }"
    label="Follow"
    :size="size"
    :text-color="isActive ? 'white' : 'primary'"
    @click.stop="toggleFollow(vehicle)"
  >
    <template #icon
      ><q-icon class="q-mr-xs" :class="{ 'animated-icon': isActive }" name="my_location" :size="`${size + 1}px`"
    /></template>
  </MappedItemButton>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MappedItemButton from 'components/map/MappedItemButton.vue';
import Vehicle from 'src/models/Vehicle';

export default {
  props: {
    size: {
      type: Number,
      default: undefined,
    },
    vehicle: {
      required: true,
      type: Vehicle,
    },
  },
  components: {
    MappedItemButton,
  },
  computed: {
    ...mapState('vehicles', ['followedVehicleKey']),
    isActive() {
      return this.followedVehicleKey !== null && this.followedVehicleKey === this.vehicle.key;
    },
  },
  methods: {
    ...mapActions('vehicles', ['toggleFollow']),
  },
};
</script>

<style lang="scss" scoped>
.animated-icon {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
