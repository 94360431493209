<template>
  <div class="q-table__control row no-wrap full-width justify-end items-center">
    <TableBottomClearButton
      v-if="totalUnfiltered !== undefined"
      :class="{ 'q-mr-lg': showPageControls }"
      :total="total"
      :total-unfiltered="totalUnfiltered"
      @click="$emit('clear-filters')"
    />
    <div v-if="showPageControls" class="column justify-end items-center">
      <div v-if="showAllRecords && moreResults" class="col q-mt-sm">
        <LoadMoreButton :label="loadMoreLabel" @click="$emit('request')" />
      </div>
      <div class="row items-center">
        <span class="q-table__bottom-item">Records per page:</span>
        <q-select
          v-capacitor-native-select
          borderless
          class="q-table__bottom-item"
          :class="{ 'q-mr-none': !hasPages }"
          dense
          :display-value="showAllRecords ? 'All' : rowsPerPage"
          emit-value
          hide-bottom-space
          map-options
          :model-value="rowsPerPage"
          :options="rowsPerPageOptions"
          @update:model-value="update('rowsPerPage', $event)"
        />
        <div v-if="hasPages" class="q-table__control">
          <span class="q-table__bottom-item"
            >{{ firstItemIndex }}-{{ lastItemIndex }} of {{ total }}{{ moreResults ? '+' : '' }}</span
          >
          <q-btn dense :disable="page === 1" flat icon="chevron_left" round @click="update('page', page - 1)" />
          <q-btn dense :disable="isLastPage" flat icon="chevron_right" round @click="update('page', page + 1)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadMoreButton from 'components/LoadMoreButton.vue';
import TableBottomClearButton from 'components/table/TableBottomClearButton.vue';
import { ROWS_PER_PAGE_OPTIONS } from 'src/services/constants';

export default {
  name: 'TableBottom',
  components: {
    LoadMoreButton,
    TableBottomClearButton,
  },
  props: {
    loadMoreLabel: String,
    moreResults: Boolean,
    page: Number,
    rowsPerPage: Number,
    total: Number,
    totalUnfiltered: Number,
  },
  computed: {
    firstItemIndex() {
      return (this.page - 1) * this.rowsPerPage + 1;
    },
    /**
     * Has pages if:
     * - the last item is less than the total or we're on a page other than the 1st
     * - and: we're not displaying all records
     * - and: the total is greater than the lowest rows-per-page option
     */
    hasPages() {
      return (this.lastItemIndex < this.total || this.page > 1) && this.rowsPerPage > 0;
    },
    isLastPage() {
      return this.firstItemIndex + this.rowsPerPage > this.total;
    },
    lastItemIndex() {
      const lastIndex = this.firstItemIndex + this.rowsPerPage - 1;
      return lastIndex > this.total ? this.total : lastIndex;
    },
    rowsPerPageOptions() {
      return this.options.map((value) => ({
        label: value === 0 ? 'All' : value,
        value,
      }));
    },
    showAllRecords() {
      return this.rowsPerPage === 0;
    },
    showPageControls() {
      return this.total > this.options[0];
    },
  },
  data() {
    return {
      options: [],
      scrollableParent: null,
    };
  },
  methods: {
    async update(prop, value) {
      // Get current position in viewport
      const { top: previousTop } = this.$el.getBoundingClientRect();
      this.$emit(`update:${prop}`, value);
      await this.$nextTick();
      let currentParent = this.$el;
      let scrolledElement;
      for (let i = 0; i < 10; i += 1) {
        // Determine if we're within a dialog (up to 10 nodes up)
        currentParent = currentParent?.parentNode;
        if (currentParent?.classList?.contains('q-panel') || currentParent?.classList?.contains('dialog--body')) {
          scrolledElement = currentParent;
          break;
        }
      }
      // Determine which parent to scroll
      const scrollableParent = scrolledElement || window;
      // Get new position in viewport
      const { top } = this.$el.getBoundingClientRect();
      // Figure out how far the scrollable parent has scrolled
      const parentScrollTop = scrollableParent.scrollTop || scrollableParent.scrollY;
      // Scroll element back to a matching position in the viewport
      scrollableParent.scrollTo(0, parentScrollTop + (top - previousTop));
    },
  },
  created() {
    this.options = [...ROWS_PER_PAGE_OPTIONS];
    if (!this.options.includes(this.rowsPerPage)) {
      this.options.push(this.rowsPerPage);
    }
    this.options.sort((current, next) => (current > next ? 1 : -1));
    const [all] = this.options.splice(0, 1);
    this.options.push(all); // Make sure "All" is last option
  },
  watch: {
    isLastPage() {
      if (this.isLastPage && this.moreResults) {
        this.$emit('request');
      }
    },
  },
};
</script>
