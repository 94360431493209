<template>
  <q-expansion-item
    ref="fuelEconomy"
    :class="{ 'readonly-field': readonly }"
    :disable="disable || readonly"
    expand-icon="arrow_drop_down"
    expand-separator
    :header-class="`q-px-none ${!readonly ? 'bg-grey-3' : ''}`"
    @after-show="$refs.fuelEconomy.$el.scrollIntoView()"
  >
    <template #header>
      <div class="q-item__section column q-item__section--main justify-center" style="padding: 3px 12px">
        <div class="q-item__label q-item__label--caption text-caption">Fuel Economy</div>
        <div class="q-item__label">
          <span v-if="!formData.economy && !formData.type">Not set</span>
          <span v-if="formData.economy">{{ economyLocalized }}</span>
          <span v-if="formData.economy && formData.type"> with </span>
          <span v-if="formData.type">{{ typeLabel }}</span>
        </div>
      </div>
    </template>
    <q-card>
      <q-card-section>
        <q-input
          v-model="formData.economy"
          autofocus
          class="q-mb-sm"
          filled
          label="Fuel Economy Value"
          @update:model-value="onChange"
        />
        <q-select
          v-model="formData.economyUm"
          v-capacitor-native-select
          class="q-mb-sm"
          display-value-sanitize
          emit-value
          filled
          label="Fuel Economy Unit"
          :options="availableUms"
          options-sanitize
          @update:model-value="onChange"
        />
        <q-select
          v-model="formData.type"
          v-capacitor-native-select
          display-value-sanitize
          emit-value
          filled
          label="Fuel Type"
          :options="avialableTypes"
          options-sanitize
          @update:model-value="onChange"
        />
      </q-card-section>
    </q-card>
  </q-expansion-item>
</template>

<script>
import _capitalize from 'lodash/capitalize';
import { mapGetters } from 'vuex';
import { fuelEconomyLocalized } from 'src/services/locale';

export default {
  props: {
    disable: Boolean,
    economy: {
      type: Number,
      default: 0,
    },
    economyUm: {
      type: String,
      default: '',
    },
    readonly: Boolean,
    type: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['update:model-value'],
  computed: {
    ...mapGetters('session', ['fuelTypes']),
    avialableTypes() {
      const options = this.fuelTypes.map((type) => ({
        label: type,
        value: type,
      }));
      if (this.type && !this.fuelTypes.includes(this.type)) {
        options.push({
          label: this.type,
          value: this.type,
        });
      }
      options.push({
        label: 'None selected',
        value: '',
      });
      return [...options.sort(({ label: labelA = '' }, { label: labelB = '' }) => (labelA <= labelB ? -1 : 1))];
    },
    availableUms() {
      return [
        {
          label: 'None Selected',
          value: '',
        },
        {
          label: 'mpg',
          value: 'mpg',
        },
        {
          label: 'km/L',
          value: 'km/L',
        },
        {
          label: 'L/100 km',
          value: 'L/100 km',
        },
      ];
    },
    economyLocalized() {
      return fuelEconomyLocalized(this.formData.economy, this.formData.economyUm);
    },
    typeLabel() {
      return _capitalize(this.formData.type);
    },
  },
  data() {
    return {
      formData: {
        economy: null,
        economyUm: '',
        type: '',
      },
    };
  },
  methods: {
    onChange() {
      this.$emit('update:model-value', {
        ...this.formData,
      });
    },
  },
  mounted() {
    const { economy, economyUm, type } = this;
    this.formData = { economy: economy || '', economyUm: economyUm || '', type: type || '' };
  },
  watch: {
    economy(economy) {
      this.formData.economy = economy;
    },
    economyUm(economyUm) {
      this.formData.economyUm = economyUm;
    },
    type(type) {
      this.formData.type = type;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.q-item) {
  padding: 8px 12px 8px 0px;
}
</style>
