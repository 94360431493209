import errorService from 'src/services/error';

/**
 * Retreives events for the current user.
 *
 * @param {EventsStoreActionContext} context
 * @param {Object} payload
 * @param {boolean} payload.init
 */
export async function getEvents(context, payload = {}) {
  const { init = false } = payload;

  context.commit('setIsLoading', true);

  if (init) {
    context.commit('setEvents', []);
    context.commit('setEventsCursor', '');
    context.commit('setHasLoadedAll', false);
    context.commit('setIsDoneLoading', false);
  }

  /** @type {EventsFeedData | import('src/models/ErrorPayload').default} */
  const data = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getEvents',
    params: {
      cursor: context.state.eventsCursor,
    },
  });

  if (data.error) {
    errorService.log(new Error(data.error));
    return [];
  }

  const { cursor = '', events = [] } = data;

  if (cursor === '') {
    context.commit('setHasLoadedAll', true);
  }

  context.commit('insertEvents', events);
  context.commit('setEventsCursor', cursor);
  context.commit('setIsLoading', false);
  context.commit('setIsDoneLoading', true);

  return events;
}
