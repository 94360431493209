<template>
  <ZubieMap
    :chooser-control="false"
    :class="mapStateStyles"
    data-t="map"
    fullscreen-control
    :initial-bounds="initialBounds"
    :mapImages="mapImages"
    :mapStyle="mapStyle"
    :zoom="minZoom"
    @load="onMapLoad"
  >
    <MapboxLayer
      v-if="liveMapInitialized && placesLayerReady"
      :before="placeFencesBoundaryLayerId"
      :layer="tripDirectionArrowsLayer"
      :source="tripsGeoJson"
    />
    <MapboxLayer
      v-if="liveMapInitialized && placesLayerReady"
      :before="tripDirectionArrowsLayer.id"
      :layer="tripLineLayer"
      :source="tripsGeoJson"
    />
    <MapboxMarker
      v-if="startPoint && currentZoomLevel > 10"
      :anchor="startPoint.anchor"
      :lng-lat="startPoint.coordinates"
      :offset="startPoint.offset"
    >
      <TripMarker :point="startPoint" :showCamera="false" />
    </MapboxMarker>
    <MapFiltersControl />
    <SelectedAssetPopup v-if="selectedAssetVisible && $q.screen.gt.sm" data-t="selectedVehiclePopup" />
    <ContextualPopup
      @open="navigateToUrl({ name: 'map' })"
      @place-visit-vehicle-select="navigateToUrl({ name: 'map-selected', params: { key: $event } })"
    />
    <IdealFitControl />
    <SearchControl />
    <MapLegend :hidden="selectedDrawerOpen && $q.screen.xs" :symbols="legendSymbols" />
    <SelectedAssetDrawer v-if="!$q.screen.gt.sm" @open="selectedDrawerOpen = $event" />
    <template v-slot:alt-control-group>
      <MapOptionsControl
        v-if="!$q.screen.gt.xs"
        :cluster-assets="clusterAssets"
        :map-style="mapStyle"
        position="top-right-secondary"
        :show-hidden-places="showHiddenPlaces"
        :show-places="showPlaces"
        :show-tag-colors="showTagColors"
        :show-traffic="showTraffic"
        @set-map-style="setMapStyle"
        @toggle-cluster-assets="toggleClusterAssets"
        @toggle-places="togglePlaces"
        @toggle-tag-colors="toggleTagColors"
        @toggle-traffic="toggleTraffic"
      />
    </template>
    <template v-slot:inner-map>
      <transition appear name="fade">
        <NoItemsVisibleMessage v-if="allItemsFiltered && $q.screen.gt.xs" />
      </transition>
    </template>
  </ZubieMap>
</template>

<script>
import polyline from '@mapbox/polyline';
import { MapboxMarker } from '@studiometa/vue-mapbox-gl';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _kebabCase from 'lodash/kebabCase';
import { colors } from 'quasar';
import { mapActions, mapGetters, mapState } from 'vuex';
import ContextualPopup from 'components/map/ContextualPopup.vue';
import IdealFitControl from 'components/map/IdealFitControl.vue';
import MapboxLayer from 'components/map/MapboxLayer.vue';
import MapFiltersControl from 'components/map/MapFiltersControl.vue';
import MapLegend from 'components/map/MapLegend.vue';
import MapOptionsControl from 'components/map/MapOptionsControl.vue';
import NoItemsVisibleMessage from 'components/map/NoItemsVisibleMessage.vue';
import SearchControl from 'components/map/search/SearchControl.vue';
import SelectedAssetDrawer from 'components/map/SelectedAssetDrawer.vue';
import SelectedAssetPopup from 'components/map/SelectedAssetPopup.vue';
import ZubieMap from 'components/map/ZubieMap.vue';
import TripMarker from 'components/vehicle/TripMarker.vue';
import navigateToUrl from 'src/mixins/navigateToUrl';
import Bounds from 'src/models/Bounds';
import MapImage from 'src/models/MapImage';
import Vehicle from 'src/models/Vehicle';
import { simpleStatuses, statuses, statusColorClasses, statusDisplays } from 'src/services/constants';
import { dayjs } from 'src/services/date';
import PlaceFencesBoundaryLayer from 'src/services/geoJson/layers/PlaceFencesBoundaryLayer';
import { tripDirectionArrows, tripLine, tripLineInProgress } from 'src/services/geoJson/layers/TripsMapLayers';
import MapService from 'src/services/map';
import { tripMarkerConfig } from 'src/services/markers';
import storage from 'src/services/storage';

const { getPaletteColor } = colors;

export default {
  mixins: [navigateToUrl],
  components: {
    ContextualPopup,
    IdealFitControl,
    MapFiltersControl,
    MapLegend,
    MapOptionsControl,
    MapboxLayer,
    MapboxMarker,
    NoItemsVisibleMessage,
    SelectedAssetDrawer,
    SelectedAssetPopup,
    SearchControl,
    TripMarker,
    ZubieMap,
  },
  computed: {
    ...mapState('app', ['notchSizes']),
    ...mapState('trips', ['selectedVehicleTrip']),
    ...mapGetters('assets', [
      'selectedAsset',
      'hasDisconnectedVehicles',
      'hasNoDeviceVehicles',
      'hasNoGpsVehicles',
      'hasObsoleteDeviceVehicles',
      'hasOfflineVehicles',
      'hasPoweredDownVehicles',
    ]),
    ...mapState('env', ['mapboxAccessToken']),
    ...mapState('map', [
      'clusterAssets',
      'contextualMenuOpen',
      'idealBounds',
      'liveMapInitialized',
      'mapStyle',
      'placesLayerReady',
      'showHiddenPlaces',
      'showPlaces',
      'showTagColors',
      'showTraffic',
      'zoomLevel',
    ]),
    ...mapGetters('filtering', ['allItemsFiltered']),
    ...mapGetters('places', ['hasHiddenPlaces']),
    legendSymbols() {
      const symbols = [
        {
          statusClass: _kebabCase(statuses.MOVING),
          label: statusDisplays[statuses.MOVING],
          tooltip: "The vehicle's engine is on",
          bgColor: statusColorClasses.MOVING,
        },
        {
          statusClass: _kebabCase(statuses.STOPPED),
          label: statusDisplays[statuses.STOPPED],
          tooltip: "The vehicle's engine is off",
          bgColor: statusColorClasses.STOPPED,
        },
      ];
      if (this.hasDisconnectedVehicles) {
        symbols.push({
          statusClass: _kebabCase(statuses.DISCONNECTED),
          label: statusDisplays[statuses.DISCONNECTED],
          tooltip: 'The Zubie device has been disconnected from the vehicle',
          bgColor: statusColorClasses.DISCONNECTED,
          icon: 'block',
        });
      }
      if (this.hasOfflineVehicles) {
        symbols.push({
          statusClass: _kebabCase(statuses.OFFLINE),
          label: statusDisplays[statuses.OFFLINE],
          tooltip:
            "The device in this vehicle hasn't sent data in over 24 hours. This can be caused by hardware failure, the device being loose/unplugged, or being in an area with poor cellular signal",
          bgColor: statusColorClasses.OFFLINE,
          icon: 'signal_cellular_off',
        });
      }
      if (this.hasNoDeviceVehicles) {
        symbols.push({
          statusClass: _kebabCase(statuses.NO_DEVICE),
          label: statusDisplays[statuses.NO_DEVICE],
          tooltip: 'The vehicle does not have a Zubie device',
          bgColor: statusColorClasses.NO_DEVICE,
          icon: 'check_box_outline_blank',
        });
      }
      if (this.hasPoweredDownVehicles) {
        symbols.push({
          statusClass: _kebabCase(statuses.POWERED_DOWN),
          label: statusDisplays[statuses.POWERED_DOWN],
          tooltip:
            "The vehicle's battery is low, so the device has powered down and is not currently sending data; this should resolve itself once the vehicle's battery returns to a healthy level",
          bgColor: statusColorClasses.POWERED_DOWN,
          icon: 'power_settings_new',
        });
      }
      if (this.hasNoGpsVehicles) {
        symbols.push({
          statusClass: _kebabCase(statuses.NO_GPS),
          label: statusDisplays[statuses.NO_GPS],
          tooltip:
            'The vehicle has taken a trip in an area with poor GPS signal, so the location currently displayed may not be accurate. It will automatically be updated once a reliable GPS location is available, which may only happen after the vehicle moves to a location with better coverage',
          bgColor: statusColorClasses.NO_GPS,
          icon: 'gps_off',
        });
      }
      if (this.hasObsoleteDeviceVehicles) {
        symbols.push({
          statusClass: _kebabCase(statuses.OBSOLETE),
          label: statusDisplays[statuses.OBSOLETE],
          tooltip: '3G networks are no longer available. This device can be replaced at no charge.',
          bgColor: statusColorClasses.OBSOLETE,
          icon: 'delete_forever',
        });
      }
      return symbols;
    },
    mapStateStyles() {
      const classes = ['live-map'];

      if (this.selectedAsset.key !== null) {
        classes.push('selected-marker');
        classes.push('selected-non-clustered-marker');
      }

      if (this.contextualMenuOpen) {
        classes.push('contextual-menu-open');
      }

      if (this.selectedAsset instanceof Vehicle) {
        switch (this.selectedAsset.getMarker()) {
          case simpleStatuses.MOVING:
          case 'idle':
            classes.push('selected-moving-marker');
            break;
          case simpleStatuses.STOPPED:
          case simpleStatuses.STALE:
          case 'warning':
            classes.push('selected-stopped-marker');
            break;
          default:
          // do nothing
        }
      }

      switch (true) {
        case this.zoomLevel >= 5 && this.zoomLevel < 11:
          classes.push('zoom-level-5');
          break;
        case this.zoomLevel >= 11 && this.zoomLevel < 13:
          classes.push('zoom-level-11');
          break;
        case this.zoomLevel >= 13:
          classes.push('zoom-level-13');
          break;
        default:
      }

      return classes;
    },
    minZoom() {
      return MapService.MIN_ZOOM;
    },
    selectedAssetVisible() {
      return this.selectedAsset.key !== null && this.selectedAsset.isVisible;
    },
    startPoint() {
      const lastTrip = this.selectedVehicleTrip;

      if (!lastTrip || !lastTrip.startPoint) {
        return null;
      }

      const direction = 4; // 4 = up
      const { anchor, offset } = tripMarkerConfig[direction];
      const { startPoint } = lastTrip;

      return {
        ...startPoint,
        anchor,
        coordinates: [startPoint.longitude, startPoint.latitude],
        departure: startPoint,
        direction,
        offset,
      };
    },
    tripDirectionArrowsLayer() {
      return tripDirectionArrows;
    },
    tripLineLayer() {
      return this.selectedVehicleTrip.endPoint ? tripLine : tripLineInProgress;
    },
    lastTripFeatures() {
      const lastTrip = this.selectedVehicleTrip;

      if (!lastTrip.encodedPolyline) {
        return [];
      }

      return [
        {
          type: 'Feature',
          geometry: polyline.toGeoJSON(lastTrip.encodedPolyline),
          properties: {
            color: getPaletteColor('positive'),
          },
        },
      ];
    },
    mapImages() {
      return [new MapImage('trip-direction-arrow', 'img:trip-direction-arrow.png')];
    },
    tripsGeoJson() {
      return {
        type: 'geojson',
        lineMetrics: true,
        promoteId: 'tripKey',
        data: {
          type: 'FeatureCollection',
          features: this.lastTripFeatures,
          id: 'trips',
        },
      };
    },
    placeFencesBoundaryLayerId() {
      return PlaceFencesBoundaryLayer.id;
    },
  },
  data() {
    return {
      initialBounds: null,
      lastLocationTimestamp: 0,
      lastLocationVehicleKey: null,
      currentZoomLevel: MapService.MIN_ZOOM,
      selectedDrawerOpen: false,
    };
  },
  methods: {
    ...mapActions('assets', ['selectAsset']),
    ...mapActions('map', [
      'init',
      'setMapStyle',
      'toggleClusterAssets',
      'togglePlaces',
      'toggleTagColors',
      'toggleTraffic',
    ]),
    ...mapActions('trips', ['updateSelectedVehicleTrip']),
    isSelectedAsset(assetKey) {
      return this.selectedAsset.key && assetKey === this.selectedAsset.key;
    },
    onMapLoad({ map }) {
      this.map = map;

      map.on(
        'zoom',
        _debounce(() => {
          this.currentZoomLevel = map.getZoom();
        }, 250)
      );

      this.init(map);

      this.$emit('load');
    },
  },
  created() {
    const initialBounds = storage.get('vehicles-map/idealBounds') || undefined;
    if (initialBounds) {
      this.initialBounds = new Bounds(initialBounds.split(','));
    }
  },
  watch: {
    idealBounds() {
      storage.set('vehicles-map/idealBounds', this.idealBounds);
    },
    selectedAsset() {
      const timestamp = _get(this.selectedAsset, 'location.timestamp');

      if (this.lastLocationVehicleKey !== this.selectedAsset.key) {
        /**
         * Save timestamp, but do not update trip data
         * - already happening from the select
         */
        this.lastLocationVehicleKey = this.selectedAsset.key;
        this.lastLocationTimestamp = timestamp;
      } else if (
        // Update trip data if timestamp is after last location timestamp
        timestamp &&
        dayjs(timestamp).isAfter(this.lastLocationTimestamp)
      ) {
        this.lastLocationTimestamp = timestamp;
        this.updateSelectedVehicleTrip();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-vehicle {
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-leave,
.fade-enter-to {
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

:deep(.mapboxgl-popup) {
  z-index: 2;
  display: inline-flex;
  // Note: maxWidth option not implemented in MglPopup, need to force it here
  max-width: none !important;
  min-width: 150px;
  white-space: nowrap;
}

:deep(.mapboxgl-ctrl-icon.mapboxgl-ctrl-fullscreen) {
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%3E%3Cpath%20d%3D%22M10%2021H7v8h8v-3h-5v-5zm-3-6h3v-5h5V7H7v8zm19%2011h-5v3h8v-8h-3v5zM21%207v3h5v5h3V7h-8z%22%2F%3E%3C%2Fsvg%3E');
  background-position: center;
  background-size: 100%;
}

:deep(.mapboxgl-ctrl-icon.mapboxgl-ctrl-shrink) {
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%3E%3Cpath%20d%3D%22M7%2024h5v5h3v-8H7v3zm5-12H7v3h8V7h-3v5zm9%2017h3v-5h5v-3h-8v8zm3-17V7h-3v8h8v-3h-5z%22%2F%3E%3C%2Fsvg%3E');
  background-position: center;
  background-size: 100%;
}
</style>

<style lang="scss">
.selected-marker .mapboxgl-ctrl-bottom-left {
  bottom: 25px;

  @media (min-width: 470px) {
    bottom: 0;
  }
}

.ios-notch-left .selected-marker .mapboxgl-ctrl-bottom-left {
  left: env(safe-area-inset-left);
}
</style>
