<template>
  <div
    :class="['border', 'text-center', `border-${this.statusClass}`]"
    :style="{ height: `${size + 8}px`, width: `${size + 8}px` }"
  >
    <q-img
      v-if="url"
      class="image"
      data-t="image"
      spinner-color="primary"
      :spinner-size="`${size}px`"
      :src="url"
      :style="{ height: `${size}px`, width: `${size}px` }"
    >
      <template #error>
        <DefaultImage :size="size" :type="type" />
      </template>
    </q-img>
    <DefaultImage v-else :size="size" :type="type" />
  </div>
</template>

<script>
import DefaultImage from 'components/DefaultImage.vue';
import { fit } from 'src/services/cloudinary';
import { ASSET_TYPES, statusColorClasses } from 'src/services/constants';

export default {
  props: {
    size: Number,
    status: {
      type: String,
      default: 'STOPPED',
    },
    type: String,
    url: String,
  },
  components: {
    DefaultImage,
  },
  computed: {
    cloudinaryUrl() {
      return fit(this.url, { width: this.size * 2, height: this.size * 2 });
    },
    statusClass() {
      return statusColorClasses[this.status];
    },
    isVehicle() {
      return this.type === ASSET_TYPES.VEHICLE;
    },
  },
};
</script>

<style lang="scss" scoped>
.border {
  border-width: 3px;
  border-style: solid;
  padding: 1px;
  border-radius: 100%;
  background: white;
}

.image {
  max-height: 100%;
  border-radius: 50%;
}
</style>
