<template>
  <!-- TODO: determine better way to give height (needed for map render) -->
  <q-page class="live-map-page">
    <MapToolbar v-if="$q.screen.gt.xs" />

    <!-- keep this outer <div> here to prevent VehiclesMap from being destroyed -->
    <div>
      <div
        v-if="$q.screen.gt.sm"
        class="vehicles-list fixed-top full-height z-index-3 shadow-2"
        data-t="vehiclesListContainer"
      >
        <MappedAssetsList />
      </div>
    </div>

    <div :class="mapClasses" data-t="vehiclesMapContainer">
      <div v-if="!$q.screen.gt.sm" class="drawer-buttons fixed-bottom z-index-3 row q-pa-xs bg-secondary">
        <div class="list-button-container drawer-button-container col row justify-center bg-secondary">
          <PrimaryButton
            class="list-button drawer-button"
            icon="view_list"
            label="List"
            size="md"
            @click="openListDialog"
          >
            <q-badge v-if="allItemsFiltered" color="warning" floating><q-icon name="warning" /></q-badge>
          </PrimaryButton>
        </div>
      </div>
      <VehiclesMap @load="onMapLoad" />
    </div>
    <MappedAssetsListDialog v-if="!$q.screen.gt.sm && isLiveMapRoute" />
    <NearbyVehiclesDialog />
  </q-page>
</template>

<script>
import _get from 'lodash/get';
import { TouchSwipe } from 'quasar';
import { mapActions, mapGetters, mapState } from 'vuex';
import MappedAssetsList from 'components/map/MappedAssetsList.vue';
import MappedAssetsListDialog from 'components/map/MappedAssetsListDialog.vue';
import MapToolbar from 'components/map/MapToolbar.vue';
import NearbyVehiclesDialog from 'components/map/nearby-vehicles/NearbyVehiclesDialog.vue';
import VehiclesMap from 'components/map/VehiclesMap.vue';
import MapService from 'src/services/map';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  name: 'PageMap',
  components: {
    MappedAssetsList,
    MappedAssetsListDialog,
    MapToolbar,
    NearbyVehiclesDialog,
    VehiclesMap,
  },
  directives: {
    TouchSwipe,
  },
  computed: {
    ...mapState('app', ['isLiveMapRoute']),
    ...mapGetters('assets', ['selectedAsset']),
    ...mapGetters('filtering', ['allItemsFiltered']),
    listClasses() {
      const classes = [];
      return classes;
    },
    mapClasses() {
      const classes = ['vehicles-map'];

      if (_get(this.selectedAsset, ['location', 'motionStatus'])) {
        classes.push(`selected-marker-${this.selectedAsset.location.motionStatus}`);
      }

      return classes;
    },
  },
  data() {
    return {
      observer: null,
    };
  },
  methods: {
    ...mapActions('map', ['openListDialog']),
    onMapLoad() {
      // Hacky way of determining when map is actually present in the DOM
      this.observer = new MutationObserver(async ([firstMutation]) => {
        const addedNodes = Array.from(firstMutation.addedNodes);
        const liveMapPageAdded = addedNodes.some((node) => node.classList?.contains('live-map-page'));
        if (liveMapPageAdded) {
          MapService.once('idle', async () => {
            await setTimeoutPromise(250); // Give map some time to settle
            MapService.trigger('focus');
          });
        }
      });
      this.observer.observe(document.querySelector('.q-page-container'), { childList: true });
    },
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style lang="scss" scoped>
$vehicles-list-width: 375px;

:deep(.q-drawer) {
  max-width: 90%;
  background: none;

  @media (min-width: $breakpoint-sm) {
    min-width: $vehicles-list-width;
  }
}

.vehicles-list {
  max-width: 100%;

  @media (min-width: $breakpoint-sm) {
    margin-top: 108px;
    padding-bottom: 108px;
    width: $vehicles-list-width;
  }
}

.vehicles-map {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  width: 100%;

  @media (min-width: $breakpoint-sm-min) {
    top: 50px; // 50px for toolbar
  }

  @media (min-width: calc($breakpoint-sm + 1px)) {
    padding-bottom: 0;
    padding-left: $vehicles-list-width;
  }

  @media (max-width: 799px) {
    padding-left: 0;
  }
}

.drawer-buttons {
  padding: map-get($space-md, 'x');
}

.vehicles-map {
  padding-bottom: calc(53px + env(safe-area-inset-bottom));

  @media (min-width: $breakpoint-sm) {
    padding-bottom: 0;
  }
}

.ios-notch-bottom {
  .drawer-buttons {
    padding: 16px 32px env(safe-area-inset-bottom);
  }
}

.drawer-button {
  padding: map-get($space-xs, 'y') 0;
  width: 100%;

  :deep(.q-btn__wrapper) {
    min-height: 0;
  }
}
</style>
