/* eslint class-methods-use-this:0 */

/**
 * @typedef UnitLocalOptions
 * @property {Boolean} long
 */

export default class UnitLocale {
  /** @type {String} */
  FUEL_ECONOMY_UNITS = '';

  /** @type {String} */
  VELOCITY_UNITS = '';

  /**
   * @throws {Error}
   */
  convertDistance() {
    throw new Error('UnitLocale.convertDistance() method is not implemented!');
  }

  /**
   * @throws {Error}
   */
  convertFuelEconomy() {
    throw new Error('UnitLocale.convertFuelEconomy() method is not implemented!');
  }

  /**
   * @throws {Error}
   */
  convertVelocity() {
    throw new Error('UnitLocale.convertVelocity() method is not implemented!');
  }

  /**
   * @throws {Error}
   */
  getDistanceUnits() {
    throw new Error('UnitLocale.getDistanceUnits() method is not implemented!');
  }
}
