<template>
  <div>
    <q-btn class="q-px-xs" dense :size="size">
      <div class="row items-center">
        <div class="q-mr-sm text-caption text-grey-7" style="text-transform: none">Icon:</div>
        <AssetTypeIcon :icon="selected || defaultIcon" size="md" />
      </div>
      <q-popup-proxy anchor="center middle" self="center middle" transition-hide="scale" transition-show="scale">
        <q-card class="card">
          <q-card-section class="row q-pa-md bg-primary text-white text-h6 zubie-font">
            <div class="col-grow">Select an icon</div>
            <q-btn v-close-popup dense flat icon="close" round />
          </q-card-section>
          <q-card-section :style="{ height: $q.screen.xs ? 'auto' : '50vh', overflow: 'auto' }">
            <div class="row items-center zubie-font">
              Use default:
              <q-btn
                v-close-popup
                class="q-ml-md"
                :color="!selected ? 'primary' : undefined"
                :icon="defaultIcon"
                @click="$emit('select', '')"
              />
            </div>
            <div v-for="(icons, sectionName) in icons" :key="sectionName">
              <q-separator class="q-my-md" />
              <div class="q-mb-sm zubie-font">{{ _startCase(sectionName) }}:</div>
              <div class="row">
                <q-btn
                  v-for="icon in icons"
                  :key="icon"
                  v-close-popup
                  class="col-3"
                  :color="selected === icon ? 'primary' : undefined"
                  @click="$emit('select', icon)"
                >
                  <AssetTypeIcon :icon="icon" size="md" />
                </q-btn>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-popup-proxy>
    </q-btn>
  </div>
</template>

<script>
import _startCase from 'lodash/startCase';
import AssetTypeIcon from 'components/assets/AssetTypeIcon.vue';

export default {
  name: 'IconChooser',
  props: {
    defaultIcon: String,
    icons: {
      type: Object,
      validator(groups) {
        return Object.values(groups).every((group) => Array.isArray(group));
      },
    },
    selected: String,
    size: {
      type: String,
      default: 'md',
    },
  },
  components: { AssetTypeIcon },
  methods: {
    _startCase,
  },
};
</script>

<style lang="scss" scoped>
.card {
  @media (min-width: $breakpoint-xs) {
    max-width: 300px;
  }
}
</style>
