<template>
  <div class="mapboxgl-ctrl" :class="{ 'mapboxgl-ctrl-group': !customSize, 'mapboxgl-ctrl-group-custom': customSize }">
    <button
      :aria-label="tooltip"
      :class="buttonClass"
      :disabled="disable"
      :flat="flat"
      :loading="loading"
      :size="size"
      :title="tooltip"
      @mouseover="$emit('mouseover')"
    >
      <span aria-hidden="true" class="mapboxgl-ctrl-icon">
        <slot />
      </span>
    </button>
  </div>
</template>

<script>
import mapboxControl from 'src/mixins/mapboxControl';

export default {
  name: 'MapboxControl',
  mixins: [mapboxControl],
  props: {
    buttonClass: {
      type: String,
      default: '',
    },
    customSize: {
      type: Boolean,
      default: false,
    },
    disable: Boolean,
    flat: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: String,
    tooltip: String,
  },
};
</script>

<style lang="scss" scoped>
.mapboxgl-ctrl-group-custom {
  background: #fff;

  &:not(:empty) {
    -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);

    @media (-ms-high-contrast: active) {
      box-shadow: 0 0 0 2px ButtonText;
    }
  }

  button {
    display: block;
    outline: none;
    border: 0;
    box-sizing: border-box;
    background-color: transparent;
    cursor: pointer;

    &:focus {
      box-shadow: 0 0 2px 2px #0096ff;

      &:not(:focus-visible) {
        box-shadow: none;
      }

      &:only-child {
        border-radius: inherit;
      }
    }

    & + button {
      border-top: 1px solid #ddd;

      @media (-ms-high-contrast: active) {
        border-top: 1px solid ButtonText;
      }
    }
  }
}
</style>
