import _sortBy from 'lodash/sortBy';
import Tag from 'src/models/Tag';
import { tagTypes } from 'src/services/constants';

/**
 * Returns only "generic" tags.
 *
 * @param {Object} state
 * @returns {Tag[]}
 */
export function genericTags(state) {
  return state.tags.filter((tag) => !tag.type).map((tag) => new Tag(tag));
}

/**
 * Returns only "trip" tags that actually exist in the account (none of the fake placeholder trip tags)
 *
 * @param {Object} state
 * @returns {Tag[]}
 */
export function tripTags(state) {
  return state.tags.filter(({ key, type }) => type === tagTypes.TRIP && Boolean(key)).map((tag) => new Tag(tag));
}

/**
 * Returns all tags that may be applied to trips
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Tag[]}
 */
export function tripEligibleTags(_, getters) {
  return _sortBy([...getters.tripTags, ...getters.genericTags], 'value');
}

/**
 * Returns only "user" tags.
 *
 * @param {Object} state
 * @returns {Tag[]}
 */
export function userTags(state) {
  return state.tags.filter((tag) => tag.type === tagTypes.USER).map((tag) => new Tag(tag));
}

/**
 * Returns only "vehicle" tags.
 *
 * @param {Object} state
 * @returns {Tag[]}
 */
export function vehicleTags(state) {
  return state.tags.filter((tag) => tag.type === tagTypes.CAR).map((tag) => new Tag(tag));
}

/**
 * Returns all tags that are allowed to be used for vehicles.
 *
 * @param {Object} state
 * @param {Object} getters
 * @returns {Tag[]}
 */
export function vehicleTagsTaggable(_, getters) {
  return [...getters.vehicleTags, ...getters.genericTags];
}

/**
 * Retrieves all non-trip tags as Tag objects.
 *
 * @param {Object} state
 * @returns {Tag[]}
 */
export function allTags(state, getters) {
  // Note: using combo of other getters to take advantage of caching
  return [...getters.genericTags, ...getters.vehicleTags, ...getters.userTags, ...getters.tripTags];
}
