<template>
  <div class="q-mt-sm q-mr-sm" data-t="quickStats">
    <div v-if="isVehicle" class="row justify-between">
      <div v-if="isGasFuel" class="row items-center">
        <q-icon color="grey-7" name="local_gas_station" size="24px" style="margin-left: -3px" />
        {{ fuelLevel || '--' }}%
        <q-tooltip anchor="top middle" :offset="[0, 7]" self="bottom middle">Fuel Level</q-tooltip>
      </div>
      <div v-if="isElectric" class="row items-center">
        <EVGasStationIcon class="text-grey-7" size="24px" style="margin-left: -5px; margin-right: -2px" />
        {{ batteryChargeLevel || '--' }}%
        <q-tooltip>Battery Charge Level</q-tooltip>
      </div>
      <div class="row items-center" data-t="batteryVolts">
        <q-icon color="grey-7" name="battery_charging_full" size="24px" style="margin-left: -4px; margin-right: -3px" />
        {{ batteryVolts ? `${batteryVolts}v` : '--' }}
      </div>
      <div class="row items-center relative">
        <q-icon
          color="grey-7"
          size="24px"
          style="margin-right: 4px"
          v-html="odometer && odometerIsActual ? icons.trueOdometer : icons.calculatedOdometer"
        ></q-icon>
        <span v-if="odometer" data-t="mileage">{{ odometerWithCommas }} {{ odometerUm }}</span>
        <span v-else data-t="mileage">--</span>
        <q-tooltip anchor="top middle" :offset="[14, 7]" self="bottom middle">
          {{ odometerIsActual ? 'Vehicle-reported Odometer' : 'Calculated Odometer' }}
        </q-tooltip>
      </div>
    </div>
    <div v-else>
      <span>
        <q-icon :color="batteryLevelLow ? 'negative' : 'grey-7'" name="battery_charging_full" size="24px"></q-icon>
        <span v-if="Number.isFinite(batteryLevel)" :class="{ 'text-negative': batteryLevelLow }">
          {{ batteryLevel }}%
        </span>
        <span v-else>--</span>
        <q-tooltip v-if="batteryLevelLow" anchor="top middle" :offset="[0, 7]" self="bottom middle">
          Low Battery Level
        </q-tooltip>
        <q-tooltip v-if="!Number.isFinite(batteryLevel)"> The battery level has not been reported. </q-tooltip>
      </span>
    </div>
  </div>
</template>

<script>
import EVGasStationIcon from 'components/icons/EVGasStationIcon.vue';
import TrueOdometerSvg from 'src/assets/odometer_actual_noun_Speedometer_3059831.svg?raw';
import CalculatedOdometerSvg from 'src/assets/odometer_calculated_noun_Speedometer_3059831.svg?raw';
import AlertChecks from 'src/services/assetAlerts/AlertChecks';
import { ASSET_TYPES } from 'src/services/constants';
import { thousandsComma } from 'src/services/number';

export default {
  components: { EVGasStationIcon },
  props: {
    batteryChargeLevel: {
      type: Number,
      default: null,
    },
    batteryLevel: {
      type: Number,
      default: null,
    },
    batteryVolts: {
      type: Number,
      default: null,
    },
    fuelLevel: {
      type: Number,
      default: null,
    },
    odometer: {
      type: Number,
      default: null,
    },
    odometerIsActual: Boolean,
    odometerUm: {
      type: String,
      default: null,
    },
    type: {
      required: true,
      type: String,
    },
  },
  computed: {
    batteryLevelLow() {
      return AlertChecks.isLowBatteryLevel(this.batteryLevel);
    },
    isElectric() {
      return this.batteryChargeLevel !== null;
    },
    isGasFuel() {
      // Is gas by default, but may be included for EVs if a fuel level exists
      return !this.isElectric || this.fuelLevel !== null;
    },
    isVehicle() {
      return this.type === ASSET_TYPES.VEHICLE;
    },
    odometerWithCommas() {
      return thousandsComma(this.odometer);
    },
  },
  data() {
    return {
      icons: {
        trueOdometer: TrueOdometerSvg,
        calculatedOdometer: CalculatedOdometerSvg,
      },
    };
  },
};
</script>
