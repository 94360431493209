import { Dialog, Platform } from 'quasar';
import { clearSession } from 'src/services/cookie';
import { logout as intercomLogout } from 'src/services/intercom';
import { disable, getStatus } from 'src/services/notification';
import { setTimeoutPromise } from 'src/services/setTimeout';
import storage from 'src/services/storage';

export const state = {
  api: null,
};

/**
 * @param {import('src/workers/Api').default} api
 */
export function setApi(api) {
  state.api = api;
}

export async function logout({ silent = false, redirect, logoutRedirect = '/login' } = {}) {
  if (!silent) {
    Dialog.create({
      message: 'Logging out...',
      persistent: true,
      progress: true,
      ok: false,
    });
  }

  // Clear session-specific storage
  storage.remove('session/accountId');
  storage.remove('session/redirectTo');

  if (redirect) {
    storage.set('session/redirectTo', redirect);
  }

  if (!Platform.is.capacitor) {
    if (!silent) {
      await setTimeoutPromise(1800);
    }

    let redirectQuery = '';
    if (logoutRedirect) {
      redirectQuery = `?redirect=${logoutRedirect}`;
    }

    window.location.href = `${window.location.origin}/auth/logout${redirectQuery}`;
    return;
  }

  /**
   * CAPACITOR BUILD ONLY AFTER THIS POINT
   */

  await intercomLogout();

  if (logoutRedirect) {
    storage.set('session/logoutRedirect', logoutRedirect);
  }

  if (silent) {
    // Need to show something changed after logout process begins
    document.body.classList.add('hide-all');
  }

  if ((await getStatus()) === 'GRANTED') {
    // Disable push notifications for this device
    await disable();
  }

  // Ensure that the logout happens
  setTimeoutPromise(1800).then(async () => {
    await clearSession();
    window.location.reload();
  });

  let success;

  const response = await state.api.logout();
  if (!response.error) {
    success = response.success;
    if (!silent) {
      await setTimeoutPromise(500);
    }
  } else {
    // Try native logout
    success = await clearSession();
  }

  if (success) {
    window.location.reload();
  }
}
