<template>
  <q-item clickable data-t="menuItem" style="padding-right: 22px" @click="onClick">
    <q-item-section :class="{ 'slightly-light-dimmed': isRead }" data-t="section">
      <div class="row items-center no-wrap" data-t="content">
        <div>
          <q-icon class="q-mr-sm" data-t="icon" :name="icon" size="22px" />
        </div>
        <div class="row full-width justify-start no-wrap">
          <div class="q-ml-xs" data-t="message">{{ messageSummary }}</div>
        </div>
        <div v-if="occurrenceTime" class="q-ml-sm text-body2 column justify-center text-no-wrap" data-t="time">
          <div class="text-grey-9">{{ time }}</div>
        </div>
      </div>
    </q-item-section>
    <div v-if="isDoingAction" class="absolute-full light-dimmed row justify-center items-center">
      <q-spinner size="sm" />
    </div>
  </q-item>
</template>

<script>
import navigateToUrl from 'src/mixins/navigateToUrl';
import { dayjs } from 'src/services/date';
import { trackEvent } from 'src/services/intercom';
import { doAction } from 'src/services/notification';

export default {
  name: 'NotificationsMenuItem',
  props: ['action', 'icon', 'isToday', 'lastUpdate', 'messageRead', 'messageSummary', 'occurrenceTime'],
  mixins: [navigateToUrl],
  data() {
    return {
      isDoingAction: false,
    };
  },
  computed: {
    isRead() {
      return this.lastUpdate && this.messageRead && dayjs(`${this.messageRead}-0000`).isBefore(this.lastUpdate);
    },
    time() {
      return this.isToday
        ? this.occurrenceTime.local().format('h:mm A')
        : this.occurrenceTime.local().format('M/D/YYYY');
    },
  },
  methods: {
    async onClick() {
      trackEvent('notifications_menu_item_clicked');
      this.isDoingAction = true;
      await doAction(this.action);
      this.isDoingAction = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  transition: opacity 0.5s;
  opacity: 1;
}

.slightly-light-dimmed:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.2) !important;
}
</style>
