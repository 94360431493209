import _sortBy from 'lodash/sortBy';
import { GL300_REGEX, GL500_REGEX } from 'src/services/constants';
import { devicesMatchingTerms } from 'src/services/device';

/**
 * Returns all asset (non-vehicle) devices.
 *
 * @param {DevicesStoreState} state
 * @param {DevicesStoreGetters} getters
 */
export function assetDevices(_, getters) {
  return getters.devices.filter(({ serial }) => serial.startsWith('QT') || serial.startsWith('QW'));
}

/**
 * Returns all dashcam/Raven devices.
 *
 * @param {DevicesStoreState} state
 * @param {DevicesStoreGetters} getters
 */
export function dashcamDevices(_, getters) {
  return getters.devices.filter(({ serial }) => serial.startsWith('1RV') || serial.startsWith('2RV'));
}

/**
 * Returns all dashcam/Raven devices.
 *
 * @param {DevicesStoreState} state
 * @param {DevicesStoreGetters} getters
 */
export function directConnectDevices(_, getters) {
  return getters.devices.filter(({ model }) => model?.includes('Direct Connect'));
}

/**
 * Returns all asset (non-vehicle) devices that match the current search filter.
 *
 * @param {DevicesStoreState} state
 * @param {DevicesStoreGetters} getters
 * @param {RootStoreState} rootState
 * @param {RootStoreGetters} rootGetters
 */
export function filteredAssetDevices(_, getters, __, rootGetters) {
  return devicesMatchingTerms(getters.assetDevices, rootGetters['search/processedFilterTerms']);
}

/**
 * Returns all Dashcam devices that match the current search filter.
 *
 * @param {DevicesStoreState} state
 * @param {DevicesStoreGetters} getters
 * @param {RootStoreState} rootState
 * @param {RootStoreGetters} rootGetters
 */
export function filteredDashcamDevices(_, getters, __, rootGetters) {
  return devicesMatchingTerms(getters.dashcamDevices, rootGetters['search/processedFilterTerms']);
}

/**
 * Returns all Direct Connect devices that match the current search filter.
 *
 * @param {DevicesStoreState} state
 * @param {DevicesStoreGetters} getters
 * @param {RootStoreState} rootState
 * @param {RootStoreGetters} rootGetters
 */
export function filteredDirectConnectDevices(_, getters, __, rootGetters) {
  return devicesMatchingTerms(getters.directConnectDevices, rootGetters['search/processedFilterTerms']);
}

/**
 * Returns all OBDII devices that match the current search filter.
 *
 * @param {DevicesStoreState} state
 * @param {DevicesStoreGetters} getters
 * @param {RootStoreState} rootState
 * @param {RootStoreGetters} rootGetters
 */
export function filteredObd2Devices(_, getters, __, rootGetters) {
  return devicesMatchingTerms(getters.obd2Devices, rootGetters['search/processedFilterTerms']);
}

/**
 * Returns all OBDII devices.
 *
 * @param {DevicesStoreState} state
 * @param {DevicesStoreGetters} getters
 */
export function obd2Devices(_, getters) {
  return getters.devices.filter(
    ({ model, serial }) =>
      !serial.startsWith('QT') &&
      !serial.startsWith('QW') &&
      !serial.startsWith('1RV') &&
      !serial.startsWith('2RV') &&
      !model?.includes('Direct Connect')
  );
}

/**
 * Returns all devices as an array, sorted by serial, with asset info included.
 *
 * @param {DevicesStoreState} state
 * @param {DevicesStoreGetters} getters
 * @param {RootStoreState} rootState
 * @param {RootStoreGetters} rootGetters
 */
export function devices(state, _, __, rootGetters) {
  /** @type {Object.<string, import('src/models/Asset').default|import('src/models/Vehicle').default>} */
  const assetsByDeviceKey = rootGetters['assets/assetsByDeviceKey'];
  const devs = Object.values(state.devices);
  return _sortBy(devs, 'serial').map((device) => {
    const asset = assetsByDeviceKey[device.key];
    const { serial } = device;
    return {
      ...device,
      asset,
      assetName: asset?.nickname || '',
      obsolete: GL300_REGEX.test(serial) || GL500_REGEX.test(serial),
    };
  });
}

/**
 * Returns true if devices are loading.
 *
 * @param {DevicesStoreState} state
 */
export function devicesLoading(state) {
  return Boolean(state.devicesPromise);
}
