import { sortByLower } from 'src/services/sort';
import Transformer from './Transformer';

class UserTransformer extends Transformer {
  static transform(user) {
    const groups = sortByLower(user?.groups || [], 'name');
    const groupKeys = groups.map((group) => group.key);

    const tags = sortByLower(user?.tags || [], 'value');
    const tagKeys = tags.map((tag) => tag.key);

    return {
      ...user,
      groups,
      groupKeys,
      tags,
      tagKeys,
    };
  }

  static transformForApi(user) {
    const sms_phone_number = user.smsPhoneNumber?.replace(/\(|\)|-| /gi, '');

    // Must submit an empty string if we want to remove all tags
    const tag_keys = user.tagKeys?.length === 0 ? '' : user.tagKeys;

    return {
      ...this.snakeCaseKeysDeep(user),
      sms_phone_number,
      tag_keys,
    };
  }
}

export default UserTransformer;
