/**
 * Returns true if filters have been applied and no items are visible.
 *
 * @param {FilteringStoreState} state
 * @param {FilteringStoreGetters} getters
 * @param {RootStoreState} rootState
 * @param {RootStoreGetters} rootGetters
 */
export function allItemsFiltered(_, getters, __, rootGetters) {
  const visibleAssets = rootGetters['assets/visibleAssets'];
  return getters.filtersActive && !visibleAssets.length;
}

/**
 * Returns true if any filters have been applied.
 *
 * @param {FilteringStoreState} state
 * @param {FilteringStoreGetters} getters
 */
export function filtersActive(_, getters) {
  return Boolean(getters.filterCount);
}

/**
 * Returns the number of filters that are active.
 *
 * @param {FilteringStoreState} state
 */
export function filterCount(state) {
  let count = 0;

  count += state.searchTerms.length ? 1 : 0;
  count += state.filterOutOfBounds ? 1 : 0;
  count += state.filterShared ? 1 : 0;

  Object.values(state.filters).forEach((value) => {
    count += value.length;
  });

  return count;
}
