<template>
  <q-btn-dropdown
    ref="dropdown"
    class="q-px-sm q-py-none"
    color="white"
    dense
    icon="filter_list"
    size="14px"
    square
    text-color="primary"
    @before-show="menuOpen = true"
    @hide="onClose"
  >
    <MapFilters @hide="$refs.dropdown.hide()" />
    <template v-slot:label>
      <div class="q-ml-sm gt-xs">Filters</div>
    </template>
  </q-btn-dropdown>
</template>

<script>
import _flatten from 'lodash/flatten';
import _identity from 'lodash/identity';
import _map from 'lodash/map';
import { mapActions, mapState } from 'vuex';
import MapFilters from 'components/map/MapFilters.vue';

export default {
  components: {
    MapFilters,
  },
  computed: {
    ...mapState('filtering', ['filterOutOfBounds', 'filterShared']),
    ...mapState('filtering', {
      mapFilters: 'filters',
    }),
    filterCount() {
      const allSelected = _map(this.mapFilters, _identity).filter((value) => value.length > 0);
      let count = [..._flatten(allSelected)].length;

      // Include the out-of-bounds and shared filters in the count
      if (this.filterOutOfBounds === true) {
        count += 1;
      }
      if (this.filterShared === true) {
        count += 1;
      }

      return count > 0 ? ` (${count})` : '';
    },
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    ...mapActions('filtering', ['trackFilterChange']),
    onClose() {
      this.trackFilterChange();
      this.menuOpen = false;
    },
    show(event) {
      this.$refs.dropdown.show(event);
    },
  },
};
</script>
