import EventEmitter from 'events';
import raf from 'raf';

function rafChunk(obj, chunkSize = 2) {
  const allKeys = Object.keys(obj);
  const allKeysLength = allKeys.length;
  let currentIndex = 0;

  const eventEmitter = new EventEmitter();

  function processChunk() {
    // Create chunk
    const chunk = {};
    for (let i = 0; i < chunkSize && currentIndex < allKeysLength; i += 1) {
      const key = allKeys[currentIndex];
      chunk[key] = obj[key];
      currentIndex += 1;
    }

    // Pass chunk to forEach
    eventEmitter.emit('chunk', chunk);

    // Process the next chunk, if there's more
    if (currentIndex < allKeysLength) {
      raf(processChunk);
    } else {
      eventEmitter.emit('finish');
    }
  }

  raf(processChunk);

  return eventEmitter;
}

/**
 * Executes a given function and resursively executes on every animation frame until a given condition is met.
 *
 * @param {Object} options
 * @param {Function} options.onFrame Callback to be executed on each frame request (will execute this immediately when the function is first called.)
 * @param {Function} options.doneCondition Callback that is used to determine whether another frame should be requested.
 */
async function rafSequence({ onFrame, doneCondition }) {
  const rafSteps = {
    [Symbol.asyncIterator]() {
      return this;
    },
    next() {
      return new Promise((resolve) => {
        raf(async () => {
          resolve({ done: doneCondition() });
        });
      });
    },
  };

  for await (const result of rafSteps) {
    await onFrame(result);
  }
}

export { raf, rafChunk, rafSequence };
