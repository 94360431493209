import QuasarClipboard from './QuasarClipboard';

/**
 * @property {import('./ClipboardHandler').default} clipboardHandler
 */
export const config = {
  clipboardHandler: QuasarClipboard,
};

/**
 * Copies the given text to the clipboard.
 *
 * @param {String} text
 * @returns {Promise}
 */
export function copy(text) {
  return config.clipboardHandler.copy(text);
}

/**
 * Sets the clipboard handler for the clipboard service.
 *
 * @param {ClipboardHandler} handler
 */
export function setClipboardHandler(handler) {
  config.clipboardHandler = handler;
}
