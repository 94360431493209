<template>
  <div class="footer-links row text-white justify-around content-between full-width">
    <q-btn
      align="left"
      dense
      flat
      href="https://zubie.com/"
      label="Zubie.com"
      size="sm"
      target="_blank"
      type="a"
      unelevated
    />
    <q-separator vertical />
    <q-btn
      align="left"
      dense
      flat
      href="https://help.zubiecar.com/"
      label="Help Center"
      size="sm"
      target="_blank"
      type="a"
      unelevated
    />
    <q-separator vertical />
    <q-btn
      align="left"
      dense
      flat
      href="https://zubie.com/terms-of-use/"
      label="Terms of Use"
      size="sm"
      target="_blank"
      type="a"
      unelevated
    />
  </div>
</template>
