<template>
  <div class="relative row items-center justify-between">
    <div v-if="!sendSuccess" class="send-in-progress absolute-center full-width q-px-lg z-index-1">
      <q-linear-progress indeterminate />
    </div>
    <div class="relative z-index-2 bg-white">
      <q-icon
        :color="sendSuccess ? 'grey-7' : 'dark'"
        :name="Platform.is.mobile ? 'smartphone' : 'computer'"
        size="70px"
      />
    </div>
    <transition appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <q-banner
        v-if="sendSuccess"
        class="absolute-center z-index-3 row items-center no-wrap q-pl-md q-pr-lg text-no-wrap text-white bg-positive"
        :class="{ 'q-mr-sm': !$q.screen.gt.xs }"
        dense
      >
        <q-icon name="check" /> Sent "{{ message }}"
      </q-banner>
    </transition>
    <div
      v-if="!sendSuccess"
      class="message-icon z-index-1 absolute bg-white"
      :class="{ 'message-icon--sending': sendStatus === 'sending', 'message-icon--sent': sendStatus === 'sent' }"
    >
      <q-icon name="description" size="50px" />
    </div>
    <div class="relative z-index-2 bg-white">
      <q-icon :color="sendSuccess ? 'grey-7' : 'dark'" name="directions_car" size="70px" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Platform } from 'quasar';

defineProps<{
  message: string;
  sendInProgress: boolean;
  sendSuccess: boolean;
  sendStatus: 'not sent' | 'sending' | 'sent';
}>();
</script>

<style scoped lang="scss">
.send-in-progress {
  padding-left: 40px;
  padding-right: 60px;
}

.message-icon {
  left: 0;
  transition: all 0.5s ease-in;
}

.message-icon--sending {
  left: 50%;
  transform: translateX(-50%);
}

.message-icon--sent {
  left: 100%;
  transform: translateX(-100%);
}
</style>
