import pinMovingSvg from 'src/assets/pin-moving.svg?raw';
import { COLOR_VALUES } from 'src/services/constants';
import SvgMarker from './SvgMarker';

export default class MovingSvgMarker extends SvgMarker {
  constructor() {
    super(pinMovingSvg, {
      height: 60,
      width: 60,
      innerColor: COLOR_VALUES.DARK,
      outerColor: COLOR_VALUES.POSITIVE,
    });
  }
}
