<template>
  <ZubieDialog max-width="100%" :maximized="$q.screen.xs" ok-only width="800px">
    <template #header>
      <div class="col-sm-grow row no-wrap justify-start text-h6">
        Disconnected Device Location
        <span class="q-ml-sm" style="opacity: 0.8">
          <span v-if="device.serial">(Serial #{{ device.serial }})</span>
          <span v-else>(No Device)</span>
        </span>
      </div>
    </template>

    <div class="row q-pt-none">
      <div class="col-12 col-sm-6">
        <TrackDeviceMap
          :height="$q.screen.gt.sm ? 350 : 150"
          :selected-location="selectedLocation"
          :vehicle="vehicle"
        />
      </div>
      <div class="col-12 col-sm-6 row" :class="{ 'q-pl-md': $q.screen.gt.xs }">
        <div class="q-pt-md col-grow">
          <q-btn
            class="float-right"
            color="grey"
            dense
            flat
            icon="help_outline"
            size="12px"
            @click="openURL('https://help.zubiecar.com/hc/en-us/articles/360023097051')"
          />
          <strong class="block q-mb-md text-weight-medium">Last Known Vehicle Location</strong>
          <p class="text-grey-9">
            <strong class="text-weight-medium" :class="{ 'text-grey-7 text-italic': !nickname }">{{
              nickname || 'Unnamed Vehicle'
            }}</strong>
            <br />
            <AddressString :latitude="location.latitude" :longitude="location.longitude" />
            {{ lastLocationTimestamp }}
          </p>
          <q-separator class="q-mb-md" />
          <p>
            <strong class="text-weight-medium">Device Location History (since most recent unplug)</strong>
          </p>

          <div class="text-grey-9">
            <div v-if="isLoading" class="text-caption">
              Loading location history...
              <q-linear-progress class="q-mt-sm" color="primary" dark indeterminate square />
            </div>
            <div v-else-if="!hasLocationHistory">No Location History Available</div>
          </div>

          <div class="overflow-auto" :style="{ maxHeight: $q.screen.gt.sm ? '180px' : 'auto' }">
            <DeviceLocationHistory :device="device" @load="isLoading = false" @select="onHistorySelect" />
          </div>
        </div>
      </div>
    </div>
  </ZubieDialog>
</template>

<script setup lang="ts">
import { openURL, useQuasar } from 'quasar';
import { computed, ref, type Ref } from 'vue';
import { useStore } from 'vuex';
import AddressString from 'components/AddressString.vue';
import DeviceLocationHistory from 'components/track-device/DeviceLocationHistory.vue';
import TrackDeviceMap from 'components/track-device/TrackDeviceMap.vue';
import Vehicle from 'src/models/Vehicle';
import { dayjs } from 'src/services/date';
import type { DisconnectedLocationHistory } from 'src/types/devices-store';

const props = defineProps<{ vehicle: Vehicle }>();

const store = useStore();

const $q = useQuasar();

const isLoading = ref(false);

const disconnectedLocationHistory = computed(() => store.state.devices.disconnectedLocationHistory);

const device = computed(() => props.vehicle.firstDisconnectedDevice);

const hasLocationHistory = computed(() => {
  const history = disconnectedLocationHistory.value?.[device.value?.serial]?.history || [];
  return history.length > 0;
});

const location = computed(() => <DisconnectedLocationHistory>props.vehicle.location);

const lastLocationTimestamp = computed(() =>
  location.value?.timestamp ? dayjs(location.value?.timestamp).format('M/D/YY H:m A') : ''
);

const nickname = computed(() => props.vehicle.nickname);

// Load default center coordinates
const selectedLocation: Ref<DisconnectedLocationHistory> = ref({
  latitude: location.value.latitude,
  longitude: location.value.longitude,
  timestamp: location.value.timestamp,
});
function onHistorySelect(historyData: DisconnectedLocationHistory) {
  selectedLocation.value = historyData;
}
</script>
