import { Cloudinary } from '@cloudinary/url-gen';
import { pad, scale as cloudinaryScale } from '@cloudinary/url-gen/actions/resize';
import { center } from '@cloudinary/url-gen/qualifiers/compass';
import { compass } from '@cloudinary/url-gen/qualifiers/gravity';

export const state = {
  /** @type {Cloudinary} */
  cloudinary: null,
};

/**
 * Initializes Cloudinary
 */
export function init() {
  state.cloudinary = new Cloudinary({ cloud: { cloudName: 'zubie' }, url: { secure: true } });
}

/**
 * Pads/fits the given image URL to the given height/width.
 *
 * @param {string} url
 * @param {{ height: number; width: number }} size
 */
export function fit(url, { height, width }) {
  if (!url) {
    return '';
  }
  const cloudinaryImage = imageFromUrl(url);
  cloudinaryImage.resize(pad(width, height).gravity(compass(center())));
  return cloudinaryImage.toURL();
}

/**
 * Creates a Cloudinary image using the given arguments.
 *
 * @param {string} publicId
 */
export function image(publicId) {
  return state.cloudinary.image(publicId);
}

/**
 * Creates a Cloudinary image using the given URL.
 *
 * @param {string} url
 */
export function imageFromUrl(url) {
  /**
   * cloudinary.url requires the image's public ID as the first argument
   * For uploaded images, the public ID is everything after /image/upload/ in the URL
   * For fetched images, the public ID is the original URL without the protocol
   */
  const fetchBase = 'res.cloudinary.com/zubie/image/fetch/';
  const uploadBase = 'res.cloudinary.com/zubie/image/upload/';

  // Ensure all URLs have a protocol
  const urlWithProtocol = url.startsWith('//') ? `https:${url}` : url;
  /**
   * Strip the fetch/upload base protocol+domain_path to get the public ID.
   *
   * Supports:
   * - https://
   * - http://
   */
  const publicId = urlWithProtocol.replace(new RegExp(`(?:https?:)//(?:${fetchBase}|${uploadBase})`), '');
  const cloudinaryImage = state.cloudinary.image(publicId);

  if (urlWithProtocol.includes(fetchBase)) {
    cloudinaryImage.setDeliveryType('fetch');
  }

  return cloudinaryImage;
}

/**
 * Scales the given image URL to the given height/width.
 *
 * @param {string} url
 * @param {{ height: number; width: number; }} size
 */
export function scale(url, { height, width }) {
  if (!url) {
    return '';
  }

  const image = imageFromUrl(url);

  const imageScale = cloudinaryScale();
  if (height) {
    imageScale.height(height);
  }
  if (width) {
    imageScale.width(width);
  }

  image.resize(imageScale);
  return image.toURL();
}
