<template>
  <div class="text-grey"><q-spinner-rings class="locating-spinner" color="grey" size="2em" />locating...</div>
</template>

<style lang="scss" scoped>
.locating-spinner {
  position: relative;
  top: -1px;
  margin-left: -2px;
}
</style>
