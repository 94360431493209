<template>
  <div>
    <slot name="before" />
    <q-input v-if="link" :bg-color="inputBgColor" filled label="Zubie Location Link" :model-value="link" readonly>
      <template v-slot:after>
        <q-btn
          :class="{
            [`bg-${buttonColor}`]: buttonColor,
            [`text-${buttonTextColor}`]: buttonTextColor,
          }"
          dense
          filled
          flat
          icon="file_copy"
          square
          @click="copyUrl(link)"
        >
          <ToolTip :class="tooltipClasses" :model-value="copied" no-parent-event :offset="[0, 8]">Copied!</ToolTip>
        </q-btn>
      </template>
    </q-input>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import ToolTip from 'components/ToolTip.vue';
import { copy } from 'src/services/clipboard';
import { setTimeoutPromise } from 'src/services/setTimeout';

defineProps<{
  buttonColor?: string;
  buttonTextColor?: string;
  inputBgColor?: string;
  link: string;
  tooltipClasses?: string;
}>();

const copied = ref(false);
async function copyUrl(url) {
  copied.value = true;
  copy(url).catch(() => {});
  await setTimeoutPromise(1000);
  copied.value = false;
}
</script>
