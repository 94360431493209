import { SEARCH_TYPES } from 'src/services/constants';
import storage from 'src/services/storage';

export default () => {
  storage.onReady((store) => {
    const historyString = storage.get('search/history');
    let history;

    if (historyString) {
      history = JSON.parse(historyString);
    }

    if (!Array.isArray(history)) {
      history = [];
    }

    store.commit(
      'search/setHistory',
      history.map((item) => ({
        ...item,
        unverified: true,
      }))
    );

    const searchType = storage.get('search/searchType');
    store.commit('search/setSearchType', searchType || SEARCH_TYPES.FILTER);
  });

  return {
    activeIndex: -1,
    filterTerms: '',
    /** @type {SearchItem[]} */
    history: [],
    isSearching: false,
    /** @type {SearchResultType | null} */
    priority: null,
    searchedTerms: '',
    /** @type {keyof typeof SEARCH_TYPES} */
    searchType: SEARCH_TYPES.FILTER,
    /** @type {SearchItem[]} */
    searchItems: [],
  };
};
