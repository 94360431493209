<template>
  <svg viewBox="0 0 100 100" :width="size">
    <title>17_Charging station_2</title>
    <path
      d="M70.81,10.35H39.93a4.54,4.54,0,0,0-4.53,4.53V28A12.9,12.9,0,0,0,24.66,40.47V77c0,7,6,12.68,13.39,12.68H75.34V14.88A4.54,4.54,0,0,0,70.81,10.35ZM58,67h5.06a.45.45,0,0,1,.33.75L49.82,82.51a.45.45,0,0,1-.76-.43L53.3,67.52a.44.44,0,0,0-.43-.57H47.81a.45.45,0,0,1-.33-.75L61.11,51.4a.44.44,0,0,1,.75.43L57.62,66.38A.45.45,0,0,0,58,67ZM43.41,18.24H67.33a2.06,2.06,0,0,1,2.06,2.06V40.36a2.06,2.06,0,0,1-2.06,2.06H43.41a2.06,2.06,0,0,1-2.06-2.06v-9h6.22a8.1,8.1,0,1,0,0-3.56H41.35V20.3A2.06,2.06,0,0,1,43.41,18.24ZM56.51,29V24.77a.58.58,0,0,1,1.17,0V29h1.7v1.91a4,4,0,0,1-3.66,4A3.91,3.91,0,0,1,51.55,31V29h1.7V24.77a.58.58,0,1,1,1.17,0V29ZM28.22,77V40.47a9.27,9.27,0,0,1,7.18-8.78V81.92a7.73,7.73,0,0,0,1.14,4A9.37,9.37,0,0,1,28.22,77Z"
      fill="currentColor"
      style="fill-rule: evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'EVGasStationIcon',
  props: {
    size: String,
  },
};
</script>
