<template>
  <q-btn :disable="disable" :label="time" @click="showClock = true">
    <q-popup-proxy ref="clock" transition-hide="scale" transition-show="scale" @hide="showClock = false">
      <div class="column">
        <q-time
          v-model="timeInput"
          landscape
          mask="h:mm A"
          :minute-options="minuteOptions"
          @update:model-value="$emit('update:model-value', $event)"
        >
          <div class="row items-center justify-end">
            <CancelButton v-close-popup label="Close" />
          </div>
        </q-time>
      </div>
    </q-popup-proxy>
  </q-btn>
</template>

<script>
export default {
  props: {
    disable: {
      type: Boolean,
      value: false,
    },
    time: String,
  },
  emits: ['update:model-value'],
  data() {
    return {
      minuteOptions: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
      timeInput: null,
    };
  },
  mounted() {
    this.timeInput = this.time;
  },
};
</script>
