import svg from 'src/assets/pin-disconnected.svg?raw';
import { COLOR_VALUES } from 'src/services/constants';
import SvgMarker from 'src/services/svg/SvgMarker';

export default class DisconnectedSvgMarker extends SvgMarker {
  constructor() {
    super(svg, {
      height: 91,
      width: 60,
      innerColor: COLOR_VALUES.WHITE,
      outerColor: COLOR_VALUES.NEGATIVE,
    });
  }
}
