<template>
  <q-item class="zubie-font text-white text-weight-bold" :clickable="clickable" :href="href" :to="to">
    <slot name="icon">
      <q-icon v-if="icon" class="q-mr-sm" :name="icon" size="1.5rem" />
    </slot>
    <div v-if="label" class="label">{{ label }}</div>
    <slot />
  </q-item>
</template>

<script setup lang="ts">
import type { QItemProps } from 'quasar';

withDefaults(
  defineProps<{ clickable?: boolean; href?: string; icon?: string; label?: string; to?: QItemProps['to'] }>(),
  {
    clickable: true,
  }
);
</script>

<style scoped lang="scss">
.label {
  margin-top: -3px;
}
</style>
