class ErrorPayload {
  error = null;

  metadata = null;

  constructor(error, metadata = {}) {
    this.error = error;
    this.metadata = { ...metadata };
  }
}

export default ErrorPayload;
