import GeoJsonSource from 'src/services/geoJson/GeoJsonSource';

let clusterEnabled = true;

export default class AssetsBaseSource extends GeoJsonSource {
  /**
   * @returns {Object} A GeoJson source config object.
   */
  getConfig() {
    return Object.freeze({
      type: 'geojson',
      cluster: clusterEnabled,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
  }

  /**
   * Sets the "cluster enabled" flag.
   *
   * @param {Boolean} enable
   */
  setClusterEnabled(value) {
    clusterEnabled = value;
  }
}
