import _get from 'lodash/get';
import ErrorPayload from 'src/models/ErrorPayload';
import * as tripsService from 'src/services/trips';

/**
 * Retrieves the trip with the given trip key.
 *
 * @param {TripsStoreActionContext} context
 * @param {string} key
 */
export async function fetchTrip(context, key) {
  const trip = await tripsService.fetchTrip(key);
  context.commit('setTrip', trip);
  return trip;
}

export async function fetchTrips(context, selectedVehicleKey) {
  context.commit('clearTrips');

  let trips = [];

  const promise = context.rootState.app.broker.getTrips(selectedVehicleKey);
  context.commit('fetchingTrips', promise);
  trips = await promise;

  if (!trips.error) {
    context.dispatch('updateTrips', { vehicleKey: selectedVehicleKey, trips });
  }

  context.dispatch('finishTripFetch', selectedVehicleKey);

  return trips;
}

export async function finishTripFetch(context, vehicleKey) {
  if (vehicleKey === context.rootState.vehicles.selectedVehicleKey) {
    context.commit('tripsFetched');
  }
}

export async function updateTrips(context, { vehicleKey, trips }) {
  /**
   * Ignore theses results if the selected key for this fetch
   * no longer matches the currently selected key
   */
  if (vehicleKey !== context.rootState.vehicles.selectedVehicleKey) {
    return context.state.tripsPromise;
  }

  context.commit('setTrips', trips);
  context.dispatch('finishTripFetch', vehicleKey);
  return trips;
}

/**
 * Updates the given trip via the API.
 *
 * @param {TripsStoreActionContext} context
 * @param {{ key: string; notes?: string, user_key?: string }} tripData
 */
export async function saveTrip(context, { key, notes, user_key }) {
  /** @type {TripData} */
  const trip = await context.rootState.app.broker.fetchAndTransform({
    fn: 'saveTrip',
    params: {
      key,
      body: {
        notes,
        user_key,
      },
    },
    transformationClass: 'TripTransformer',
  });

  context.commit('setTrip', trip);

  return trip;
}

export async function selectTrip(context, tripKey) {
  context.commit('selectTrip', tripKey);
}

/**
 * Retrieves trip points for the given trip key.
 *
 * @param {TripsStoreActionContext} context
 * @param {string} tripKey
 */
export async function fetchPoints(context, tripKey) {
  const points = await tripsService.fetchPoints({ key: tripKey });
  context.commit('setPoints', { key: tripKey, points });
  return points;
}

/**
 * Updates the selected vehicle trip with a full set of data (or no data if unselected).
 *
 * @param {ActionContext} context
 * @throws {ErrorPayload}
 */
export async function updateSelectedVehicleTrip(context, focusOnAsset = false) {
  const selectedAsset = context.rootGetters['assets/selectedAsset'];

  if (selectedAsset.isEmpty) {
    context.commit('setSelectedVehicleTrip', {});
    return;
  }

  // Try to get last trip info (e.g. polyline)
  const lastTripKey = _get(selectedAsset, 'lastTrip.key');

  if (lastTripKey) {
    const lastTrip = await tripsService.fetchTrip(lastTripKey);

    // Make sure last trip is still selected
    if (selectedAsset.key !== context.rootGetters['assets/selectedAsset'].key) {
      return;
    }

    if (lastTrip.errors || lastTrip.error) {
      throw new ErrorPayload(lastTrip.errors || lastTrip.error);
    }

    context.commit('setSelectedVehicleTrip', lastTrip);
  } else {
    context.commit('setSelectedVehicleTrip', {});
  }

  if (focusOnAsset) {
    context.dispatch(
      'map/focusOnAsset',
      { asset: context.rootGetters['assets/selectedAsset'], zoom: true },
      { root: true }
    );
  }
}

/**
 * Updates viewedVehicleTripKey and viewedVehicleTrip, which are used on the /vehicle/ routes
 *
 * @param {ActionContext} context
 * @param {String} tripKey
 */
export async function updateViewedVehicleTrip(context, tripKey) {
  if (context.state.viewedVehicleTripKey !== tripKey) {
    context.commit('setViewedVehicleTripKey', tripKey);

    let trip = {};
    if (context.state.viewedVehicleTripKey) {
      trip = await tripsService.fetchTrip(context.state.viewedVehicleTripKey);

      if (trip.errors || trip.error) {
        throw new ErrorPayload(trip.errors || trip.error);
      }
    }
    context.commit('setViewedVehicleTrip', trip);
  }
}
