<template>
  <ZubieDialog
    ref="dialog"
    :action-button-disable="isLoading"
    :action-button-text="saveInProgress ? 'Saving' : 'Save'"
    :dirty="isDirty"
    :max-width="$q.screen.xs ? 'auto' : '80vw'"
    :maximized="$q.screen.xs"
    :processing="saveInProgress"
    width="1000px"
    @hide="onHide()"
    @submit="save()"
  >
    <template #header>
      <div class="row justify-between">
        <div class="text-h6">{{ isNew ? 'Add' : 'Edit' }} Place</div>
        <q-btn
          color="white"
          dense
          flat
          href="https://help.zubiecar.com/hc/en-us/articles/208179296-Setting-Up-Using-Places-Geofences-"
          icon="info_outline"
          round
          style="color: rgba(0, 0, 0, 0.54); text-decoration: none"
          target="_blank"
          type="a"
          @update:model-value="isDirty = true"
        />
      </div>
    </template>
    <div class="row q-col-gutter-lg">
      <div class="col-md-6 col-sm-12 col-xs-12">
        <h6 v-if="!isNew || $q.screen.gt.sm" class="zubie-font">Place Details</h6>
        <q-list v-if="isNew && showSearch" bordered class="q-mb-sm rounded-borders">
          <q-expansion-item
            class="search-box"
            dense
            :hide-expand-icon="mustSearchForPlace"
            :model-value="expandSearch"
            @update:model-value="onToggleSearch"
          >
            <template #header>
              <div class="row items-center full-width">
                <h6 class="line-height-1 text-subtitle1 q-my-none">
                  {{ isNew && showSearch ? 'Search for an address or location' : 'Place Details' }}
                </h6>
              </div>
            </template>
            <PlaceGeocoder ref="placeGeocoder" :error="placeLocationError" @result="handleGeocoderResult" />
          </q-expansion-item>
        </q-list>
        <div v-if="!mustSearchForPlace || $q.screen.gt.sm">
          <q-input
            ref="nameInput"
            v-model.trim="name"
            autocomplete="off"
            :autofocus="!showSearch"
            :bottom-slots="true"
            dense
            :disable="mustSearchForPlace"
            filled
            label="Place Name"
            :rules="[(val) => !!val || 'Name is required']"
            @update:model-value="isDirty = true"
          />
          <q-input
            v-model.trim="address"
            autocomplete="off"
            :bottom-slots="true"
            :class="{ 'q-mb-sm': !isNew }"
            dense
            :disable="mustSearchForPlace"
            filled
            :hide-hint="isNew"
            hint="To move a place, drag the point(s) on the map"
            label="Address"
            @update:model-value="isDirty = true"
          />
          <q-select
            v-model="category"
            v-capacitor-native-select
            dense
            :disable="mustSearchForPlace"
            display-value-sanitize
            emit-value
            filled
            label="Category"
            map-options
            :options="categories"
            options-sanitize
            @update:model-value="isDirty = true"
          />

          <h6 class="text-h6 zubie-font">Event Tracking and Notifications</h6>
          <q-input
            autocomplete="off"
            class="q-mb-md"
            dense
            :disable="mustSearchForPlace"
            filled
            hint="Trip activity is recorded based on your business schedule"
            label="Record Visits"
            :model-value="humanizedActiveDaysOfWeek"
            readonly
            @update:model-value="isDirty = true"
          >
            <q-menu auto-close>
              <q-list style="min-width: 100px">
                <q-item clickable>
                  <q-item-section>
                    <q-checkbox
                      v-for="day in days"
                      :key="day.label"
                      v-model="day.value"
                      :label="day.label"
                      @update:model-value="
                        updateActiveWeekdays();
                        isDirty = true;
                      "
                    />
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-input>
          <TimeInput
            :disable="mustSearchForPlace"
            label="From"
            :model-value="fromTime"
            @update:model-value="
              fromTime = $event;
              isDirty = true;
            "
          />
          <TimeInput
            :disable="mustSearchForPlace"
            label="To"
            :model-value="toTime"
            @update:model-value="
              toTime = $event;
              isDirty = true;
            "
          />
          <div class="column">
            <q-toggle
              v-model="notifyArrival"
              checked-icon="check"
              class="justify-between"
              color="positive"
              :disable="mustSearchForPlace"
              label="Arrival Notifications"
              left-label
              unchecked-icon="clear"
              @update:model-value="isDirty = true"
            />
            <q-toggle
              v-model="notifyLeaving"
              checked-icon="check"
              class="justify-between"
              color="positive"
              :disable="mustSearchForPlace"
              label="Departure Notifications"
              left-label
              unchecked-icon="clear"
              @update:model-value="isDirty = true"
            />
          </div>
        </div>
      </div>
      <div v-if="!mustSearchForPlace || $q.screen.gt.sm" class="col-md-6 col-sm-12 col-xs-12 column">
        <h6 class="text-h6 zubie-font">Geofence Setup</h6>
        <div class="row items-center">
          <div class="q-toggle__label q-pr-md">Geofence Type</div>
          <div class="q-gutter-sm row">
            <q-radio
              v-model="type"
              :disable="mustSearchForPlace"
              label="Circle"
              val="Circle"
              @update:model-value="isDirty = true"
            />
            <q-radio
              v-model="type"
              :disable="mustSearchForPlace"
              label="Polygon"
              val="Polygon"
              @update:model-value="isDirty = true"
            />
          </div>
        </div>
        <PlaceMap
          :geometry="geometry"
          :latitude="latitude"
          :longitude="longitude"
          :original-type="originalType"
          :place-key="place.key"
          :radius="radius"
          :radius-um="radiusUm"
          :type="type"
          @init="mapInitialized = true"
          @place-update="handleMapUpdate($event)"
          @search-focus="onSearchFocus"
        />
      </div>
    </div>
  </ZubieDialog>
</template>

<script>
import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import { useMeta } from 'quasar';
import { mapActions, mapGetters, mapState } from 'vuex';
import PlaceGeocoder from 'components/places/PlaceGeocoder.vue';
import PlaceMap from 'components/places/PlaceMap.vue';
import TimeInput from 'components/TimeInput.vue';
import Place from 'src/models/Place';
import { placeCategories } from 'src/services/constants';
import { getCurrentPosition } from 'src/services/geolocation';

export default {
  name: 'EditPlace',
  props: {
    place: {
      required: true,
      type: Object,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['edit-place-closed'],
  components: {
    PlaceGeocoder,
    PlaceMap,
    TimeInput,
  },
  data() {
    const placeDefaults = Place.getNewPlaceDefaults();
    return {
      activeWeekdays: placeDefaults.activeWeekdays,
      address: placeDefaults.address,
      category: placeDefaults.category,
      days: [
        {
          label: 'Monday',
          value: false,
        },
        {
          label: 'Tuesday',
          value: false,
        },
        {
          label: 'Wednesday',
          value: false,
        },
        {
          label: 'Thursday',
          value: false,
        },
        {
          label: 'Friday',
          value: false,
        },
        {
          label: 'Saturday',
          value: false,
        },
        {
          label: 'Sunday',
          value: false,
        },
      ],
      expandSearch: true,
      fromTime: placeDefaults.fromTime,
      geometry: placeDefaults.geometry,
      isDirty: false,
      isNew: false,
      latitude: placeDefaults.latitude,
      longitude: placeDefaults.longitude,
      mapInitialized: false,
      name: placeDefaults.name,
      notifyArrival: placeDefaults.notifyArrival,
      notifyLeaving: placeDefaults.notifyLeaving,
      placeLocationError: null,
      radius: placeDefaults.radius,
      radiusUm: placeDefaults.radiusUm,
      saveInProgress: false,
      toTime: placeDefaults.toTime,
      type: placeDefaults.type,
    };
  },
  computed: {
    ...mapGetters('places', ['isLoading']),
    ...mapGetters('session', ['partnerName']),
    ...mapState('app', ['broker']),
    ...mapState('places', {
      placesInitialized: 'initialized',
    }),
    categories() {
      return placeCategories.map((category) => ({
        value: category,
        label: _capitalize(category),
      }));
    },
    hasNameError() {
      const input = this.$refs.nameInput;
      const isDirty = input && Boolean(input.disable || (input.isDirty && input.isDirty()));
      return this.name.length === 0 && isDirty;
    },
    humanizedActiveDaysOfWeek() {
      return Place.getHumanizedDaysOfWeek(this.activeWeekdays);
    },
    mustSearchForPlace() {
      return this.isNew && _isNil(this.latitude);
    },
    updatedPlace() {
      return new Place({
        activeWeekdays: this.activeWeekdays,
        address: this.address,
        category: this.category,
        fromTime: this.fromTime,
        geometry: this.geometry,
        key: this.key,
        latitude: this.latitude,
        longitude: this.longitude,
        name: this.name,
        notifyArrival: this.notifyArrival,
        notifyLeaving: this.notifyLeaving,
        type: this.type,
        radius: this.radius,
        radiusUm: this.radiusUm,
        toTime: this.toTime,
      });
    },
  },
  methods: {
    ...mapActions('places', ['savePlace']),
    ...mapActions('geocoding', ['reverseGeocode']),
    getFormattedAddress(geocodingResult) {
      const result = {
        address: geocodingResult.id.includes('poi')
          ? _get(geocodingResult, 'properties.address', '')
          : `${geocodingResult.address || ''} ${geocodingResult.text || ''}`.trim(),
        city: '',
        postcode: '',
        region: '',
      };
      geocodingResult.context.forEach((parent) => {
        // "place" in a MapBox geocoding result seems to mean locality
        if (parent.id.startsWith('place')) {
          result.city = parent.text;
        } else if (parent.id.startsWith('postcode')) {
          result.postcode = parent.text;
        } else if (parent.id.startsWith('region')) {
          result.region = parent.text;
        }
      });
      const address = `${result.address}${result.address && (result.city || result.region) ? ', ' : ''}${result.city}${
        result.city && result.region ? ', ' : ''
      }${result.region} ${result.postcode}`;
      return address.replace(/\s+/g, ' ');
    },
    async handleGeocoderResult(result) {
      this.placeLocationError = null;

      if (result) {
        this.isDirty = true;
        this.name = result.id.includes('poi') ? result.text : `${result.address || ''} ${result.text || ''}`.trim();
        this.address = this.getFormattedAddress(result);
        const [longitude, latitude] = result.center;
        this.longitude = longitude;
        this.latitude = latitude;
        this.expandSearch = false;
      } else {
        // result was cleared, so reset the new place
        this.isDirty = false;
        this.name = '';
        this.address = '';
        this.longitude = null;
        this.latitude = null;
        // avoid name input validation error message
        await this.$nextTick();
        this.$refs.nameInput.resetValidation();
      }
    },
    async handleMapUpdate(update) {
      const { geometry, radius, latitude, longitude } = update;

      if (geometry) {
        this.geometry = geometry;
      }
      if (radius) {
        this.radius = radius;
      }
      if (latitude) {
        this.latitude = latitude;
      }
      if (longitude) {
        this.longitude = longitude;
      }

      if (latitude && longitude) {
        // circle place marker was dragged, so update the address field to match the new center
        const hasNewCoordinates = latitude !== this.latitude || longitude !== this.longitude;
        const isReverseGeocodeable = Boolean(
          _get(geometry, 'properties.geofenceType') === 'Circle' && radius && latitude && longitude
        );
        if (hasNewCoordinates && isReverseGeocodeable) {
          const address = await this.reverseGeocode({
            latitude,
            longitude,
          });
          this.address = address.toString();
        }
      }

      if (this.mapInitialized) {
        this.isDirty = true;
      }
    },
    onHide() {
      this.$emit('edit-place-closed');
    },
    onSearchFocus() {
      document.body.querySelector('#geocoderEl input')?.focus();
    },
    async onToggleSearch(value) {
      if (this.mustSearchForPlace) {
        return;
      }

      this.expandSearch = value;

      if (value === true) {
        await this.$nextTick();
        this.onSearchFocus();
      }
    },
    async save() {
      if (this.mustSearchForPlace) {
        this.placeLocationError = 'Please choose a location for this Place.';
        this.$refs.placeGeocoder.focus();
        return;
      }

      const data = this.updatedPlace.getPostBody();
      this.saveInProgress = true;
      await this.savePlace(data);
      this.$refs.dialog.hide();
      // for some reason calling hide() doesn't trigger the "hide" event, so emit edit-place-closed
      this.$emit('edit-place-closed');
      this.saveInProgress = false;
    },
    setInitialValues() {
      this.key = this.place.key;
      this.isNew = this.place.isNew;
      this.activeWeekdays = this.place.activeWeekdays;
      this.address = this.place.address;
      this.category = this.place.category;
      this.fromTime = this.place.fromTime;
      this.geometry = this.place.geometry;
      this.name = this.place.name;
      this.longitude = this.place.longitude;
      this.latitude = this.place.latitude;
      this.notifyArrival = this.place.notifyArrival;
      this.notifyLeaving = this.place.notifyLeaving;
      this.radius = this.place.radius || Place.getNewPlaceDefaults().radius;
      this.toTime = this.place.toTime;
      this.type = this.place.type;
      this.originalType = this.place.type;
    },
    updateActiveWeekdays() {
      const enabledDays = [];
      this.days.forEach((day, index) => {
        if (day.value === true) {
          enabledDays.push(index);
        }
      });
      this.activeWeekdays = enabledDays;
    },
  },
  async created() {
    if (!this.placesInitialized) {
      const doneInitializing = this.$watch('place', () => {
        this.setInitialValues();
        doneInitializing();
      });
    }

    this.setInitialValues();

    this.place.activeWeekdays.forEach((activeDayInteger) => {
      // -1 is a magic number since app engine doesn't like to accept an empty list or string
      if (activeDayInteger >= 0) {
        this.days[activeDayInteger].value = true;
      }
      return undefined;
    });

    // Trigger the location permissions
    getCurrentPosition();

    useMeta({
      title: `Edit Place - ${this.place.name} - ${this.partnerName}`,
    });
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  max-width: 80vw;
  width: 1000px;
}

.q-card__actions {
  border-top: 1px solid $dark;
}

:deep(.q-field--filled.q-field--readonly .q-field__control::before) {
  border-bottom-style: none;
}

.search-box :deep(.q-expansion-item__container > .q-item) {
  padding: 0 8px;
}
</style>
