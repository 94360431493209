<template>
  <ZubieDialog
    :action-button-disable="processing"
    :action-button-text="actionButtonText"
    cancel-button-text="Cancel"
    content-class="row justify-center"
    :max-width="!$q.screen.gt.sm ? '100%' : undefined"
    :maximized="!$q.screen.gt.sm"
    :ok-only="!noTextFound"
    :title="title"
    :visible="visible"
    @submit="onSubmit"
  >
    <div v-if="processing" class="row justify-center items-center full-width">
      <q-img class="image full-width light-dimmed" :src="imageDataUrl" />
      <q-spinner-grid class="absolute rotate-180" color="primary" size="50%" />
    </div>
    <div v-else-if="!textBlocks.length" class="row justify-center text-center text-grey-9">
      <div>
        <q-icon color="grey-4" name="search_off" size="300px" style="margin-right: -34px" />
        <div>No text was found in the captured image.</div>
        <div>Press "Try Again" to take a new photo.</div>
      </div>
    </div>
    <div v-else>
      <p>Below is a list of text blocks found from the scan. Please select the text block to use.</p>
      <q-list bordered separator>
        <q-item v-for="text in textBlocks" v-ripple clickable @click="onSelect(text)">
          <q-item-section>{{ text }}</q-item-section>
        </q-item>
      </q-list>
    </div>
  </ZubieDialog>
</template>

<script setup lang="ts">
import { useQuasar } from 'quasar';
import { computed, ref } from 'vue';
import ZubieDialog from 'components/ZubieDialog.vue';
import { setTimeoutPromise } from 'src/services/setTimeout';

const props = defineProps<{ imageDataUrl: string; processing: boolean; textBlocks: string[] }>();

const emit = defineEmits(['ok', 'retry']);

const $q = useQuasar();

const noTextFound = computed(() => !props.textBlocks.length);

const title = computed(() => {
  if (props.processing) {
    return 'Evaluating Photo...';
  }
  return !noTextFound.value ? 'Choose Scanned Text' : 'No Text Found In Scan';
});

const actionButtonText = computed(() => {
  if (props.processing) {
    return 'Please wait...';
  }
  return noTextFound.value ? 'Try Again' : undefined;
});

const visible = ref(true);
async function onSelect(text: string) {
  emit('ok', { text, valid: true });
  await setTimeoutPromise(250); // wait for ripple
  visible.value = false;
  await setTimeoutPromise(250); // wait for dialog close animation
}

function onSubmit() {
  if (noTextFound.value) {
    visible.value = false;
    emit('retry');
  }
}
</script>

<style scoped lang="scss">
.image {
  width: 100%;
}
</style>
