import chain from 'src/services/chain';
import { NOTIFICATION_SUPPORT } from 'src/services/notification';

/**
 * Returns true if there are any recent unread notifications.
 *
 * @param {NotificationsStoreState} state
 * @param {NotificationsStoreGetters} getters
 */
export function areRecentUnread(state, getters) {
  return getters.recentUnread.length > 0;
}

/**
 * Retrieves all unique "kind"s, sorted alphabetically.
 *
 * @param {NotificationsStoreState} state
 */
export function allKinds(state) {
  return chain(state.notifications)
    .map(({ kind }) => kind)
    .uniq()
    .sort()
    .value();
}

/**
 * Returns true if web notifications were deferred.
 *
 * @param {NotificationsStoreState} state
 */
export function deferred(state) {
  return state.notificationSupport === NOTIFICATION_SUPPORT.DEFERRED;
}

/**
 * Returns true if native notifications are denied.
 *
 * @param {NotificationsStoreState} state
 */
export function denied(state) {
  return state.notificationSupport === NOTIFICATION_SUPPORT.DENIED;
}

/**
 * Retreives recent notifications.
 *
 * @param {NotificationsStoreState} getters
 * @param {import('src/models/Notification').default[]} getters.sortedNotifications
 */
export function recent(_, getters) {
  return getters.sortedNotifications.slice(0, 9);
}

/**
 * Returns all recent unread messages.
 *
 * @param {NotificationsStoreState} state
 * @param {NotificationsStoreGetters} getters
 */
export function recentUnread(state, getters) {
  return getters.recent.filter(({ messageRead }) => !messageRead);
}

/**
 * Returns all notifications sorted by the occurrence timestamp.
 *
 * @param {NotificationsStoreState} state
 */
export function sortedNotifications(state) {
  return chain(state.notifications).sortBy('occurrenceTimestamp').reverse().value();
}
