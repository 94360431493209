<template>
  <div class="full-width q-pa-xs">
    <q-card bordered class="full-width bg-grey-2" flat>
      <q-item>
        <q-item-section>
          <q-item-label class="text-weight-bold">
            <a
              :class="{ 'text-grey-7 text-italic': !visit.vehicle.nickname }"
              href="#"
              @click="$emit('vehicle-click', $event, visit.vehicle.key)"
              >{{ visit.vehicle.nickname || 'Unnamed Vehicle' }}</a
            >
          </q-item-label>
          <q-item-label caption class="q-mt-xs">
            <DriverChip :dense="true" :driver="visit.vehicle.driver" />
          </q-item-label>
        </q-item-section>
      </q-item>

      <q-separator />

      <q-card-section class="justify-between bg-white" horizontal>
        <q-card-section class="col q-pa-sm">
          <q-list dense>
            <q-item dense>
              <q-item-section>
                <q-item-label>Arrival</q-item-label>
                <q-item-label caption>{{ entryTimestamp }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item dense>
              <q-item-section>
                <q-item-label>Departure</q-item-label>
                <q-item-label v-if="visit.exitTimestamp" caption>
                  {{ exitTimestamp }}
                </q-item-label>
                <q-item-label v-else caption>Still here</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-separator vertical />

        <q-card-section class="col q-pa-sm">
          <q-list class="full-height" dense>
            <q-item class="full-height" dense>
              <q-item-section class="items-center">
                <q-item-label>Dwell Time</q-item-label>
                <q-item-label caption>{{ visit.dwellTime }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import DriverChip from 'components/chips/DriverChip.vue';
import PlaceVisit from 'src/models/PlaceVisit';
import { utcToLocalDateTime } from 'src/services/date';

export default {
  name: 'PlaceVisitsCard',
  props: {
    visit: PlaceVisit,
  },
  components: {
    DriverChip,
  },
  computed: {
    entryTimestamp() {
      return utcToLocalDateTime(this.visit.entryTimestamp);
    },
    exitTimestamp() {
      return utcToLocalDateTime(this.visit.exitTimestamp);
    },
    hasDriver() {
      return Boolean(this.visit.vehicle.driver.fullName);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.q-item) {
  padding: 4px 8px;
}
</style>
