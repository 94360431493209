import Bugsnag from '@bugsnag/js';
import { version } from 'app/package.json';
import { Platform } from 'quasar';

export const WORKER_ACTION_NAME = 'bugsnagNotify';

export const state = {
  started: false,
};

export const baseConfig = {
  apiKey: '44660beadec5a0a947c10282902f0d75',
  redactedKeys: [
    'password',
    'newPassword',
    'currentPassword',
    'credit_card_token',
    'authorization',
    'accessToken',
    'apiKey',
  ],
};

/**
 * The idea here was to be able to initialize bugsnag in multiple contexts,
 * e.g. the ui thread and our various workers, but bugsnag doesn't work in
 * workers yet, so this code could be simpler. They say it is on the roadmap,
 * though, so I'll leave it
 *
 * @param {String} executionContext
 * @param {Array} plugins
 */
export function init(executionContext, plugins = []) {
  if (!state.started) {
    const config = {
      apiKey: '44660beadec5a0a947c10282902f0d75',
      releaseStage: getReleaseStage(getHost()),
      appVersion: `${version}${getVersionModifier(getHost())}`,
      redactedKeys: [
        'password',
        'newPassword',
        'currentPassword',
        'credit_card_token',
        'authorization',
        'accessToken',
        'apiKey',
      ],
      plugins,
      onError,
    };
    if (executionContext) {
      config.appType = executionContext;
    }
    Bugsnag.start(config);
    state.started = true;
  }
}

/**
 * Subscribes to the Bugsnag notification messages.
 *
 * @param {ProxiedWorker} proxiedWorker
 */
export function initWorker(proxiedWorker) {
  proxiedWorker.subscribe(WORKER_ACTION_NAME, ({ message, metaData }) => {
    Bugsnag.notify(message, (event) => {
      Object.keys(metaData).forEach((key) => {
        event.addMetadata(key, metaData[key]);
      });
    });
  });
}

/**
 * Returns Bugsnag if UI thread or a worker client if in a Worker thread.
 *
 * @returns {Bugsnag|Object}
 */
export function getBugsnagClient() {
  if (typeof self !== 'undefined' && self.WORKER_NAME) {
    return workerThreadClient();
  }
  return Bugsnag;
}

/**
 * Creates a Bugsnag client to be used on the Worker thread.
 *
 * @returns {Object}
 */
export function workerThreadClient() {
  return {
    /**
     * Posts a message to the UI thread to trigger a Bugsnag notification.
     *
     * @param {String} error
     * @param {Function} onError
     */
    notify(error, onError = () => {}) {
      // Simple implementation of Bugsnag's onError argument
      const eventObj = {
        metaData: {},
        addMetadata(key, value) {
          this.metaData[key] = value;
        },
      };

      onError(eventObj);

      self.postMessage({
        action: WORKER_ACTION_NAME,
        payload: { message: error.message, metaData: eventObj.metaData },
      });
    },
  };
}

export function getVersionModifier(host = '') {
  if (!host.includes('mobile-app.zubie.com') || process.env.APP_ENV === 'production') {
    return '';
  }

  let platform = 'mobile'; // if "mobile" is used, something is wrong
  if (Platform.is.android) {
    platform = 'android';
  }
  if (Platform.is.ios) {
    platform = 'ios';
  }
  return `-${platform}.${process.env.APP_ENV}`;
}

export function getReleaseStage(host = '') {
  switch (true) {
    case host.includes('mobile-app.zubie.com'):
      // eslint-disable-next-line no-case-declarations
      let platform = 'mobile'; // if "mobile" is used, something is wrong

      if (Platform.is.android) {
        platform = 'android';
      }
      if (Platform.is.ios) {
        platform = 'ios';
      }

      return `${platform} ${process.env.APP_ENV}`;
    case host.includes('-l.zubie.com'):
      return 'development';
    case host.includes('-qa.zubie.com'):
      return 'qa';
    case host.includes('-beta.zubie.com'):
      return 'beta';
    case host.includes('-support.zubiecar.com'):
      return 'support';
    default:
      return 'production';
  }
}

/**
 * Handle each error, detect unactionable errors.
 *
 * @param {Event} event
 */
export function onError(event) {
  if (event.unhandled) {
    const actionable = event.errors?.some((error) => error.stacktrace?.length);
    if (!actionable) {
      return false;
    }
  }
}

/**
 * Retrieves the window/self location host.
 *
 * @returns {String}
 */
function getHost() {
  const global = typeof window !== 'undefined' ? window : self;
  const { host } = global.location;
  return host;
}
