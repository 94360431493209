import _first from 'lodash/first';
import _last from 'lodash/last';
import { dayjs } from 'src/services/date';
import { celsiusToFahreheit, fahreheitToCelsius } from 'src/services/locale';
import GeoJsonFeature from './GeoJsonFeature';
import SimpleModel from './SimpleModel';

class AssetHistoryCluster extends SimpleModel {
  /** @type {Array<Number>} */
  ambientTemperatures = [];

  /** @type {Object} */
  center = null;

  /** @type {Number} */
  count = null;

  /** @type {Array<Number>} */
  deviceBatteryChargeLevels = [];

  /** @type {Array<String>} */
  keys = [];

  /** @type {Array<Object>} */
  points = [];

  /** @type {Boolean} */
  selected = true;

  /** @type {Array<Date>} */
  timestamps = [];

  /** @type {Array<String>} */
  tripKeys = [];

  constructor(data = {}) {
    super();
    const { timestamps = [] } = data;
    this.insert({
      ...data,
      timestamps: timestamps.map((ts) => dayjs(ts)),
    });
  }

  /**
   * Returns ambientTemperature in degrees farhenheit
   *
   * @returns {Boolean}
   */
  get ambientTemperatureFahreheit() {
    return Number.isFinite(_first(this.ambientTemperatures))
      ? celsiusToFahreheit(_first(this.ambientTemperatures)).toFixed(0)
      : null;
  }

  /**
   * Sets ambientTemperature in degrees farhenheit
   */
  set ambientTemperatureFahreheit(degreesF) {
    this.ambientTemperatures[0] = fahreheitToCelsius(degreesF);
  }

  get deviceBatteryChargeLevel() {
    return _first(this.deviceBatteryChargeLevels);
  }

  get key() {
    return _first(this.keys);
  }

  get point() {
    const [lon, lat] = this.center;
    return {
      lat,
      lon,
    };
  }

  get timestamp() {
    return _first(this.timestamps);
  }

  get timestampEarliest() {
    return _last(this.timestamps);
  }

  get timestampLatest() {
    return _first(this.timestamps);
  }

  get tripKey() {
    return _first(this.tripKeys);
  }

  /**
   * Generates a GeoJSON "Feature" to be used in a GeoJSON layer.
   *
   * @returns {Object} GeoJSON Feature
   */
  toGeoJson() {
    const { key, point, timestamp, tripKey } = this;
    const { lat, lon } = point;

    return new GeoJsonFeature([lon, lat], {
      key,
      timestamp,
      tripKey,
    });
  }
}

export default AssetHistoryCluster;
