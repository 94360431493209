<template>
  <div />
</template>

<script lang="ts" setup>
import type { ControlPosition, Map } from 'mapbox-gl';
import mapboxgl from 'mapbox-gl';
import { inject, onBeforeMount, onBeforeUnmount } from 'vue';

const props = defineProps<{ position: ControlPosition }>();

const map: Map = <Map>inject('map');

const attributionControl = new mapboxgl.AttributionControl({ compact: true });
const emits = defineEmits(['added']);

onBeforeMount(() => {
  map.addControl(attributionControl, props.position);
  emits('added');
});

onBeforeUnmount(() => {
  map.removeControl(attributionControl);
});
</script>
