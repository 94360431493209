<template>
  <q-select
    ref="zubieSelect"
    :autocomplete="autocomplete"
    :autofocus="autofocus"
    :behavior="behavior"
    :bg-color="popupShown ? 'white' : bgColor"
    :bottom-slots="bottomSlots"
    :clearable="clearable"
    :color="color"
    :dense="dense"
    :disable="disable"
    :display-value-sanitize="displayValueSanitize"
    :emit-value="emitValue"
    :error="error"
    :error-message="errorMessage"
    :fill-input="fillInput"
    :filled="filled"
    :for="forInput"
    :hide-bottom-space="hideBottomSpace"
    :hide-dropdown-icon="hideDropdownIcon"
    :hide-selected="!$q.screen.gt.xs && popupShown ? true : hideSelected"
    :hint="hint"
    :input-debounce="inputDebounce"
    :label="label"
    :label-color="!$q.screen.gt.xs && popupShown ? 'dark' : labelColor"
    :loading="loading"
    :map-options="mapOptions"
    :menu-anchor="!$q.screen.gt.xs ? 'center middle' : undefined"
    :menu-self="!$q.screen.gt.xs ? 'center middle' : undefined"
    :model-value="modelValue"
    :multiple="multiple"
    :option-label="optionLabel"
    :option-value="optionValue"
    :options="options"
    :options-sanitize="optionsSanitize"
    :readonly="readonly"
    :rules="rules"
    :stack-label="stackLabel"
    :transition-hide="!$q.screen.gt.xs ? 'scale' : transitionHide"
    :transition-show="!$q.screen.gt.xs ? 'scale' : transitionShow"
    :use-chips="useChips"
    :use-input="useInput"
    :virtual-scroll-slice-size="virtualScrollSliceSize"
    @popup-hide="onPopupHide"
    @popup-show="onPopupShow"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <template v-if="$slots.label" #label="scope">
      <slot v-bind="scope" name="label" />
    </template>
    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend" />
    </template>
    <template v-if="$slots['selected-item']" v-slot:selected-item="scope">
      <slot v-bind="scope" name="selected-item" />
    </template>
    <template v-if="$slots.option" v-slot:option="scope">
      <slot v-bind="scope" name="option">
        <q-item :class="{ 'bg-blue-2': useInput && scope.selected }" clickable @click="onItemClick(scope)">
          <q-item-section v-if="multiple" side>
            <q-checkbox :model-value="scope.selected" @update:model-value="onItemClick(scope)" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ getLabel(scope) }}</q-item-label>
          </q-item-section>
        </q-item>
      </slot>
    </template>
    <template v-if="$slots['no-option']" v-slot:no-option="scope">
      <slot v-bind="scope" name="no-option" />
    </template>
    <template #after>
      <slot name="after" />
    </template>
    <template #after-options>
      <slot name="after-options" />
      <div
        ref="actionButtons"
        class="action-buttons fixed-bottom full-width bg-white"
        :style="{ paddingBottom: actionButtonPadding }"
      >
        <q-separator />
        <div class="row justify-evenly items-center">
          <CancelButton v-close-popup class="action-button action-button__close q-py-sm q-px-sm" label="Close" />
          <q-btn
            v-if="actionButtonText"
            class="action-button q-py-none q-px-sm"
            color="white"
            :disable="disableActionButton"
            :label="actionButtonText"
            :loading="loading"
            square
            text-color="primary"
            @click="performAction"
          />
        </div>
        <q-resize-observer @resize="onActionButtonsResize" />
      </div>
    </template>
    <ToolTip v-if="tooltip">{{ tooltip }}</ToolTip>
  </q-select>
</template>

<script>
import _debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import ToolTip from 'components/ToolTip.vue';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  name: 'ZubieSelect',
  components: {
    ToolTip,
  },
  props: {
    actionButtonText: String,
    autocomplete: String,
    autofocus: Boolean,
    behavior: String,
    bgColor: String,
    bottomSlots: Boolean,
    clearable: Boolean,
    closeOnInput: {
      type: Boolean,
      default: true,
    },
    color: String,
    dense: Boolean,
    disable: Boolean,
    disableActionButton: Boolean,
    displayValueSanitize: Boolean,
    emitValue: Boolean,
    error: {
      type: Boolean,
      default: null,
    },
    errorMessage: String,
    forceAutofocus: Boolean,
    filled: Boolean,
    fillInput: Boolean,
    for: String,
    hideBottomSpace: Boolean,
    hideDropdownIcon: Boolean,
    hideSelected: Boolean,
    hint: String,
    inputDebounce: Number,
    label: String,
    labelColor: String,
    loading: Boolean,
    mapOptions: Boolean,
    modelValue: [Array, Object, String, Number],
    multiple: Boolean,
    optionLabel: [String, Function],
    options: Array,
    optionValue: String,
    optionsSanitize: Boolean,
    tooltip: String,
    readonly: Boolean,
    rules: Array,
    stackLabel: Boolean,
    transitionHide: String,
    transitionShow: String,
    useChips: Boolean,
    useInput: Boolean,
    virtualScrollSliceSize: Number,
  },
  emits: ['action', 'popup-hide', 'popup-show', 'update:model-value'],
  computed: {
    ...mapState('app', ['notchSizes']),
    actionButtonPadding() {
      let padding = this.notchSizes.bottom;
      if (padding > 20) {
        padding -= 20;
      }
      return `${padding}px`;
    },
    forInput() {
      return this.for;
    },
  },
  data() {
    return {
      closeAfterLoaded: false,
      popupShown: false,
    };
  },
  methods: {
    focus() {
      this.$refs.zubieSelect.focus();
    },
    filter(...args) {
      return this.$refs.zubieSelect.filter(...args);
    },
    getLabel({ opt: option }) {
      return this.$refs.zubieSelect?.getOptionLabel(option);
    },
    async onItemClick({ opt: option }) {
      const optionValue = this.emitValue ? option[this.optionValue || 'value'] : option;

      let newValue;
      if (this.multiple) {
        newValue = this.value ? [...this.value] : [];
        const itemIndex = newValue.indexOf(optionValue);
        if (itemIndex > -1) {
          newValue.splice(itemIndex, 1);
        } else {
          newValue.push(optionValue);
        }
      } else {
        newValue = optionValue;
      }

      this.$emit('update:model-value', newValue);

      if (this.useInput && !this.multiple && this.closeOnInput) {
        this.delayedClose();
      }
    },
    onPopupHide(event) {
      this.popupShown = false;
      if (!this.$q.screen.gt.xs) {
        document.body.classList.remove('zubie-select', 'zubie-select--shown');
      }
      this.closeAfterLoaded = false;
      this.$emit('popup-hide', event);
    },
    async onPopupShow(event) {
      this.popupShown = true;

      if (!this.$q.screen.gt.xs) {
        document.body.classList.add('zubie-select');
        await setTimeoutPromise(250);
        document.body.classList.add('zubie-select--shown');
      }

      this.$emit('popup-show', event);
    },
    async performAction() {
      this.$emit('action');
      await setTimeoutPromise(1);
      if (this.loading) {
        this.closeAfterLoaded = true;
      } else {
        this.delayedClose();
      }
    },
    async delayedClose() {
      await setTimeoutPromise(200);
      this.$refs.zubieSelect.hidePopup();
    },
    updateInputValue(value) {
      this.$refs.zubieSelect.updateInputValue(value);
    },
    hidePopup() {
      this.$refs.zubieSelect.hidePopup();
    },
    showPopup() {
      this.$refs.zubieSelect.showPopup();
    },
    updateActionButtonsSpace() {
      const { actionButtons } = this.$refs;

      if (!actionButtons) {
        return;
      }

      const actionButtonsHeight = actionButtons.offsetHeight + 8; // 8 = padding
      const scrollElement = document.querySelector('.q-dialog.fullscreen .scroll');
      if (scrollElement) {
        scrollElement.style.paddingBottom = `${actionButtonsHeight || 0}px`;
      }
    },
  },
  created() {
    this.onActionButtonsResize = _debounce(() => this.updateActionButtonsSpace());
  },
  async mounted() {
    if (this.forceAutofocus) {
      await this.$nextTick();
      this.$refs.zubieSelect.focus();
    }
    this.updateActionButtonsSpace();
  },
  watch: {
    loading(isLoading, wasLoading) {
      if (this.closeAfterLoaded && wasLoading && !isLoading) {
        this.delayedClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.action-button__close .q-btn__content) {
  color: $dark;
}

.action-buttons {
  display: none;
}
</style>

<style lang="scss">
.zubie-select {
  .q-select__dialog {
    margin-top: env(safe-area-inset-top);
  }
}

/* Note: selector usage is super specific on purpose */
body.zubie-select.zubie-select--shown {
  .fullscreen .action-buttons {
    display: block;
  }
}
</style>
