import DisconnectedSvgMarker from './DisconnectedSvgMarker';
import IdleSvgMarker from './IdleSvgMarker';
import MovingNoHeadingSvgMarker from './MovingNoHeadingSvgMarker';
import MovingSvgMarker from './MovingSvgMarker';
import NoDeviceSvgMarker from './NoDeviceSvgMarker';
import NoGpsSvgMarker from './NoGpsSvgMarker';
import ObsoleteSvgMarker from './ObsoleteSvgMarker';
import OfflineSvgMarker from './OfflineSvgMarker';
import PoweredDownSvgMarker from './PoweredDownSvgMarker';
import StaleSvgMarker from './StaleSvgMarker';
import StoppedSvgMarker from './StoppedSvgMarker';
import WarningSvgMarker from './WarningSvgMarker';

export const markersByStatus = {
  idle: new IdleSvgMarker(),
  moving: new MovingSvgMarker(),
  movingNoHeading: new MovingNoHeadingSvgMarker(),
  noDevice: new NoDeviceSvgMarker(),
  stale: new StaleSvgMarker(),
  stopped: new StoppedSvgMarker(),
  warning: new WarningSvgMarker(),
  disconnected: new DisconnectedSvgMarker(),
  noGps: new NoGpsSvgMarker(),
  obsolete: new ObsoleteSvgMarker(),
  offline: new OfflineSvgMarker(),
  poweredDown: new PoweredDownSvgMarker(),
};
