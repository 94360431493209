import { colors as quasarColors } from 'quasar';
import GeoJsonLayer from 'src/services/geoJson/GeoJsonLayer';
import PlaceFencesSource from 'src/services/geoJson/sources/PlaceFencesSource';

const { getPaletteColor } = quasarColors;

export default class PlaceFencesLayer extends GeoJsonLayer {
  /** @var {String} id */
  static id = 'place-fences';

  /**
   * @returns {Object} A GeoJson layer config object.
   */
  getConfig(enableHidden = false) {
    const visibleColor = getPaletteColor('accent');
    let fillColor = visibleColor;

    if (enableHidden) {
      fillColor = ['case', ['==', ['get', 'isVisible'], true], visibleColor, getPaletteColor('secondary')];
    }

    return Object.freeze({
      id: this.getId(),
      type: 'fill',
      metadata: { 'mapbox:group': '3c074f28255b87214ccb0ab55fee9992' },
      source: PlaceFencesSource.id,
      filter: ['match', ['geometry-type'], ['Polygon'], true, false],
      paint: {
        'fill-color': fillColor,
        'fill-opacity': ['interpolate', ['linear'], ['zoom'], 1, 0.01, 15, 0.2],
        'fill-outline-color': getPaletteColor('accent'),
      },
    });
  }
}
