<template>
  <ZubieDialog
    ref="dialog"
    content-class="row justify-center"
    :content-style="`max-width: ${!$q.screen.gt.sm ? 'auto' : '500px'};`"
    :dirty="isDirty"
    :maximized="$q.screen.xs"
    :processing="!isReady"
    :title="title"
  >
    <transition name="slide">
      <VehicleForm
        v-if="!isLoading"
        ref="vehicleForm"
        class="q-pa-none q-pb-sm full-width"
        :vehicle-key="vehicleKey"
        @change="isDirty = true"
        @ready="isReady = true"
        @saved="onSave"
        @saving="isSaving = $event"
      />
    </transition>
    <transition name="fade">
      <div v-if="!isReady" class="row justify-center">
        <q-spinner color="primary" size="3em" />
      </div>
    </transition>

    <template #actions>
      <CancelButton
        v-close-popup
        class="q-px-sm"
        data-t="editVehicleCancel"
        :disable="isSaving"
        :label="allowEditVehicle || isViewerAndDriver ? 'Cancel' : 'Close'"
      />
      <PrimaryButton
        v-if="allowEditVehicle || isViewerAndDriver"
        class="q-px-sm"
        data-t="editVehicleSave"
        :disable="isSaving"
        @click.prevent="$refs.vehicleForm.save($event)"
      >
        <q-spinner v-if="isSaving" class="q-mr-sm"></q-spinner>
        {{ isSaving ? 'Saving' : 'Save' }}
      </PrimaryButton>
    </template>
  </ZubieDialog>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import VehicleForm from 'components/vehicle/VehicleForm.vue';

export default {
  name: 'EditVehicleDialog',
  components: {
    VehicleForm,
  },
  props: {
    vehicleKey: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('session', ['allowEditVehicle', 'currentUser', 'hasPermission']),
    ...mapState('vehicles', ['detailedVehicles']),
    isViewerAndDriver() {
      return this.currentUser.isViewer() && this.driverKey === this.currentUser.key;
    },
    driverKey() {
      return this.detailedVehicles[this.vehicleKey]?.primaryDriverKey;
    },
    originalVehicle() {
      if (this.vehicleKey) {
        return this.detailedVehicles[this.vehicleKey];
      }
      return null;
    },
    title() {
      if (this.allowEditVehicle || this.isViewerAndDriver) {
        return this.vehicleKey ? 'Edit Vehicle' : 'Add Vehicle';
      }
      return 'Vehicle Settings';
    },
  },
  data() {
    return {
      isDirty: false,
      isLoading: false,
      isReady: false,
      isSaving: false,
    };
  },
  methods: {
    ...mapActions('vehicles', ['getDetailedVehicle']),
    onSave() {
      this.$refs.dialog.ok();
    },
  },
  async mounted() {
    this.isDirty = false;
    if (this.vehicleKey && !this.detailedVehicles[this.vehicleKey]) {
      this.isLoading = true;
      await this.getDetailedVehicle(this.vehicleKey);
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.fade-leave-active {
  transition: opacity 0.5s;
  opacity: 0;
}

.fade-leave,
.fade-enter-to {
  opacity: 1;
}

.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.5s;
  max-height: calc(100vh - 100px);
}

.slide-enter,
.slide-leave-to {
  max-height: 0;
}
</style>
