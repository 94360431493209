import GeoJsonSource from 'src/services/geoJson/GeoJsonSource';

export default class SearchResultsSource extends GeoJsonSource {
  /** @var {String} id */
  static id = 'search-results';

  /**
   * @returns {Object} A GeoJson source config object.
   */
  getConfig() {
    return Object.freeze({
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
  }
}
