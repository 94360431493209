<template>
  <MapboxNavigationControl :position="position" />
</template>

<script setup lang="ts">
import { MapboxNavigationControl } from '@studiometa/vue-mapbox-gl';
import { onMounted } from 'vue';

const emit = defineEmits(['added']);

onMounted(() => {
  emit('added');
});

defineProps<{ position: string }>();
</script>
