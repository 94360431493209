<template>
  <div class="col">
    <q-img v-show="showImage" spinner-color="white" :src="src" transition="fade" @load="onLoad">
      <span class="absolute-bottom text-center q-pb-sm">
        <q-btn
          v-if="showButton"
          class="no-outline min-height-none"
          color="white"
          dense
          :label="buttonLabel"
          size="12px"
          square
          text-color="primary"
          @click="onClick"
        ></q-btn>
      </span>
    </q-img>
    <q-skeleton v-if="!showImage" class="full-height" type="rect">
      <span class="absolute-bottom text-center q-pb-sm">
        <q-btn
          v-if="showButton"
          class="no-outline min-height-none"
          color="white"
          dense
          :label="buttonLabel"
          size="12px"
          square
          text-color="primary"
          @click="onClick"
        ></q-btn>
      </span>
    </q-skeleton>
  </div>
</template>

<script>
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  name: 'DashcamPlaceholder',
  props: {
    imageUrl: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: 'View Live Feed',
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showImage: false,
    };
  },
  computed: {
    src() {
      return this.imageUrl;
    },
  },
  methods: {
    async onClick(event) {
      this.$emit('click', event);
    },
    async onLoad() {
      await setTimeoutPromise(500);
      this.showImage = true;
      this.$emit('resize');
    },
  },
  mounted() {
    this.$emit('resize');
  },
};
</script>

<style lang="scss" scoped>
:deep(.q-btn) {
  margin-bottom: 2px;
  padding: 0px 5px;
  font-family: 'Roboto', sans-serif;
  line-height: 1;
}
</style>
