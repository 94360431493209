<template>
  <ZubieDialog ref="dialog" :maximized="$q.screen.xs" ok-only :visible="dialogOpen" @hide="onHide">
    <MapFilters :hide-close-button="true" @hide="delayedHide" />
  </ZubieDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MapFilters from 'components/map/MapFilters.vue';
import { exitFullscreen } from 'src/services/fullscreen';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  name: 'MapFiltersDialog',
  props: {
    visible: Boolean,
  },
  components: {
    MapFilters,
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
  computed: {
    ...mapGetters('filtering', ['allItemsFiltered']),
  },
  methods: {
    ...mapActions('filtering', ['trackFilterChange']),
    ...mapActions('map', ['openListDialog']),
    async delayedHide(event) {
      // Delayed so you can see the button click animation
      await setTimeoutPromise(500);
      this.$refs.dialog.hide(event);
    },
    onHide() {
      this.trackFilterChange();
      if (this.allItemsFiltered) {
        this.openListDialog();
      }
      this.$emit('hide');
    },
    onOpen() {
      this.dialogOpen = true;
      exitFullscreen();
    },
  },
  mounted() {
    this.dialogOpen = this.visible;
  },
  watch: {
    visible() {
      this.dialogOpen = this.visible;
    },
  },
};
</script>
