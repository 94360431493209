<template>
  <q-btn class="q-px-xs q-py-xs calendar-trigger no-btn-wrapper-padding" color="white" flat text-color="dark">
    <div class="row no-wrap text-no-wrap">
      <q-icon class="q-mr-sm" name="calendar_today" />
      {{ label || dateRangeFormatted }}
    </div>
    <q-popup-proxy
      ref="popup"
      v-model="showDatepicker"
      anchor="bottom middle"
      :maximized="$q.screen.xs"
      self="top middle"
      transition-hide="jump-up"
      transition-show="jump-down"
      @hide="$emit('range-end')"
    >
      <DateRange
        :events="events"
        :model-value="modelValue"
        :options="options"
        @navigation="$emit('navigation', $event)"
        @range-end="$emit('range-end')"
        @range-start="$emit('range-start', $event)"
        @update:model-value="
          $emit('update:model-value', $event);
          $refs.popup.hide();
        "
      />
    </q-popup-proxy>
  </q-btn>
</template>

<script>
import DateRange from 'components/DateRange.vue';
import { dayjs, todayStart, todayEnd } from 'src/services/date';

export default {
  name: 'DateRangeButton',
  components: {
    DateRange,
  },
  props: {
    events: {
      type: Function,
      default: () => false,
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Function,
      default: () => true,
    },
    modelValue: {
      default: () => [todayStart(), todayEnd()],
      validator(value) {
        if (value) return Array.isArray(value) && value.length === 2;
        return true;
      },
    },
  },
  emits: ['navigation', 'range-end', 'range-start', 'update:model-value'],
  computed: {
    dateRangeFormatted() {
      const start = dayjs(this.modelValue[0]);
      const end = dayjs(this.modelValue[1]);
      const formatPattern = 'M/D/YY';
      if (start.isSame(end, 'day')) {
        return start.format('ddd M/D/YY');
      }
      return `${start.format(formatPattern)} - ${end.format(formatPattern)}`;
    },
  },
  data() {
    return {
      showDatepicker: false,
    };
  },
};
</script>
