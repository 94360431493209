import _first from 'lodash/first';
import _isEmpty from 'lodash/isEmpty';
import { AppVisibility, Platform } from 'quasar';
import Notification from 'src/models/Notification';
import router from 'src/router';
import { eventIconMap } from 'src/services/constants';
import { dayjs, now } from 'src/services/date';
import {
  NOTIFICATION_SUPPORT,
  doAction,
  getNotificationAction,
  initNativeNotifications,
  requestPermission as requestPermissionFromService,
  enableViaSettings,
} from 'src/services/notification';
import { setTimeoutPromise } from 'src/services/setTimeout';
import storage from 'src/services/storage';

/**
 * Initializes the initial and regular retrieval of notifications.
 *
 * @param {NotificationsStoreActionContext} context
 */
export async function init(context) {
  setInterval(() => {
    if (AppVisibility.appVisible) {
      context.dispatch('getRecentNotifications');
    }
  }, 60000);

  if (!Platform.is.capacitor) {
    context.dispatch('getRecentNotifications', { initializing: true });
  }

  initNativeNotifications(context);
}

/**
 * Canceles the "get all notifications" process.
 *
 * @param {NotificationsStoreActionContext} context
 */
export async function cancelGetAllNotifications(context) {
  context.commit('setCancellingGetAll', true);
  await context.rootState.app.apiWorker.cancelGetAllNotifications(context.state.getAllRequestId);
  context.commit('setCancellingGetAll', false);
}

/**
 * Creates a web notification using the given parameters.
 *
 * @param {NotificationsStoreActionContext} context
 * @param {Object} message
 * @param {string} message.body
 * @param {string} message.key
 * @param {string} message.icon
 * @param {string} message.title
 */
export async function displayWebNotification(context, message) {
  if (context.state.notificationSupport !== NOTIFICATION_SUPPORT.GRANTED) {
    return;
  }

  const { body = '', icon = '', key = '', title = '' } = message;

  const webNotification = new window.Notification(title, {
    body,
    icon,
    tag: 'zubieWebNotification',
  });

  const isIndividual = context.rootGetters['session/currentUser'].isIndividual();
  const fullListRoute = isIndividual ? 'feed' : 'notifications';

  webNotification.onclick = function notificationClick() {
    if (key) {
      context.dispatch('open', key);
    } else if (router().history.current.name !== fullListRoute) {
      router().push({ name: fullListRoute });
    }

    // TODO: add analytics for clicking notifications
    webNotification.close();
  };

  // Calling close() removes it from the system notification center in os x and win10
  await setTimeoutPromise(8000);
  webNotification.close();
}

/**
 * Retrieves all notifications, stores them, and dispatches web notifications.
 *
 * @param {NotificationsStoreActionContext} context
 * @param {boolean} fromUserInput
 */
export async function getAllNotifications(context, fromUserInput = false) {
  context.commit('loadingAllNotifications');

  await context.rootState.app.broker.getAllNotifications(context.state.sinceDate.toISOString());

  if (!fromUserInput && !context.state.notifications.length) {
    // Try getting most recent date
    /** @type {Notification[]} */
    const [lastNotification] = await context.rootState.app.broker.getRecentNotifications(1);

    if (lastNotification) {
      const dayStart = dayjs(lastNotification.created).subtract(2, 'day').startOf('day').utc();
      const isSame = dayStart.isSame(context.state.sinceDate, 'day');

      if (!isSame) {
        context.dispatch('setNotificationsSinceDate', { dateString: dayStart });
        return;
      }
    }
  }

  context.commit('finishedLoading');
}

/**
 * Retrieves recent notifications from the DataBroker and merges the results.
 *
 * @param {NotificationsStoreActionContext} context
 * @param {Object} options
 * @param {boolean} options.initializing
 */
export async function getRecentNotifications(context, { initializing = false } = {}) {
  /** @type {TransformedNotification[]} */
  const notifications = await context.rootState.app.broker.getRecentNotifications();

  if (notifications.error) {
    return;
  }

  // Keep track of key indexes so we can update existing notifications that come through
  /** @type {string[]} */
  const existingKeys = context.state.notifications.map(({ key }) => key);

  // Filter out notifications that already exist
  const newNotifications = notifications
    .filter(({ key }) => !existingKeys.includes(key))
    .map((notification) => new Notification(notification));

  if (newNotifications.length) {
    context.commit('addNotifications', newNotifications);

    if (!initializing && !Platform.is.capacitor) {
      // Show notification if we're not initializing and there are new items
      context.dispatch('notifyWebOfNewItems');
    }
  }
}

/**
 * Marks all notifications in state as read and updates the state with the changes.
 *
 * @param {NotificationsStoreActionContext} context
 */
export async function markAllRecentAsRead(context) {
  const updatedNotifications = [...context.state.notifications];

  const recent = updatedNotifications.splice(0, 10).reverse();

  recent.forEach(
    /** @param {TransformedNotification} notification */ (notificationData) => {
      const notification = { ...notificationData };

      if (!notification.messageRead) {
        // Preemptively set the messageRead time (will be update on next API call)
        notification.messageRead = now().utc().format('YYYY-MM-DD HH:mm:ss');

        // Mark notification as read in the background
        context.rootState.app.broker.markNotificationAsRead(notification.key);
      }

      updatedNotifications.unshift(new Notification(notification));
    }
  );

  context.commit('setNotifications', updatedNotifications);
}

/**
 * Generates the web notification data to display in the browser.
 *
 * @param {NotificationsStoreActionContext} context
 */
export async function notifyWebOfNewItems(context) {
  const unread = context.getters.recent.filter(({ messageRead }, index) => !messageRead || index === 0);

  // Do nothing if there are no recent unread notifications
  if (_isEmpty(unread)) {
    return;
  }

  const isIndividual = context.rootGetters['session/currentUser'].isIndividual();

  /**
   * To avoid showing a notification for the exact same set of unread notifications,
   * create a hash of the keys and check against the last hash stored
   */
  const notificationHash = unread.map(({ key }) => key).join('');
  if (notificationHash !== context.state.lastWebNotificationHash) {
    if (unread.length === 1) {
      const { icon, kind: title, key, messageSummary: body } = _first(unread);
      context.dispatch('displayWebNotification', {
        title,
        body,
        icon,
        key,
      });
    } else {
      context.dispatch('displayWebNotification', {
        title: `${unread.length} New Zubie Notifications`,
        body: `View them on the ${isIndividual ? 'Feed' : 'Notifications'} page`,
        icon: eventIconMap.Default,
      });
    }

    context.commit('setLastWebNotificationHash', notificationHash);
  }
}

/**
 * Marks notification as read and navigates to the associated view.
 *
 * @param {NotificationsStoreActionContext} context
 * @param {string} key
 */
export async function open(context, key) {
  const notification = await context.rootState.app.broker.markNotificationAsRead(key);

  if (notification.error) {
    return;
  }

  const notifications = [...context.state.notifications];

  const existingIndex = notifications.findIndex(({ key: existingKey }) => existingKey === notification.key);
  if (existingIndex > -1) {
    // Update existing notification if present
    notifications[existingIndex] = new Notification(notification);
    context.commit('setNotifications', notifications);
  }

  const action = getNotificationAction(notification, context.rootGetters['session/currentUser'].isIndividual());

  doAction(action);
}

/**
 * Requests permission to receive native notifications.
 *
 * @param {NotificationsStoreActionContext} context
 */

export function requestPermission(context) {
  if (Platform.is.capacitor && context.state.notificationSupport === NOTIFICATION_SUPPORT.DENIED) {
    enableViaSettings();
    return;
  }
  requestPermissionFromService(true);
}

/**
 * Sets the "get all notifications" request ID.
 *
 * @param {NotificationsStoreActionContext} context
 * @param {string} id
 */
export async function setGetAllRequestId(context, id) {
  if (id !== context.state.getAllRequestId) {
    context.commit('setGetAllRequestId', id);
  }
}

/**
 * Converts the given notification data into notifications and saves them to the store.
 *
 * @param {NotificationsStoreActionContext} context
 * @param {TransformedNotification[]} notifications
 */
export async function setNotifications(context, notifications) {
  context.commit(
    'setNotifications',
    notifications.map((notification) => new Notification(notification))
  );
}

/**
 * Sets the state of native notification support.
 *
 * @param {NotificationsStoreActionContext} context
 * @param {String} state
 */
export function setNotificationSupport(context, state) {
  if (state === NOTIFICATION_SUPPORT.DEFERRED) {
    storage.set('notifications/deferred', 'true');
  } else if (state !== NOTIFICATION_SUPPORT.PROMPT) {
    // Remove "deferred" for statuses other than "prompt"
    storage.remove('notifications/deferred');
  }
  context.commit('setNotificationSupport', state);
}

/**
 * Sets the notification "since" date and retrieves all notifications.
 *
 * @async
 * @param {NotificationsStoreActionContext} context
 * @param {Object} payload
 * @param {String} payload.dateString
 * @param {Boolean} payload.userInput
 */
export async function setNotificationsSinceDate(context, { dateString, userInput = false }) {
  const date = dayjs(dateString).utc();
  context.commit('setNotificationsSinceDate', date);
  await context.dispatch('cancelGetAllNotifications'); // wait for any getAll requests to be cancelled
  context.dispatch('getAllNotifications', userInput);
}
