import SimpleModel from './SimpleModel';

export default class Plan extends SimpleModel {
  /** @prop {Boolean} */
  active = null;

  /** @prop {String} */
  description = null;

  /** @prop {Number} */
  intervalCount = null;

  /** @prop {String} */
  intervalType = null;

  /** @prop {String} */
  marketingDescription = null;

  /** @prop {Number} */
  minimumQuantity = null;

  /** @prop {String} */
  name = null;

  /** @prop {Object} */
  partnerTier = null;

  /** @prop {String} */
  prepaidDescription = null;

  /** @prop {String} */
  prepaidMarketing = null;

  /** @prop {Number} */
  prepaidPriceCents = null;

  /** @prop {Number} */
  priceCents = null;

  /** @prop {String} */
  purchaseUrl = null;

  /** @prop {String} */
  renewalConfirmationText = null;

  /** @prop {String} */
  renewalPricePromo = null;

  /** @prop {String} */
  renewalPromoText = null;

  /** @prop {Boolean} */
  requireBillingInfo = null;

  /** @prop {String} */
  sku = null;

  /** @prop {String} */
  termsOfService = null;

  /** @prop {Number} */
  trialDays = null;

  constructor(data) {
    super();
    this.insert(data);
  }
}
