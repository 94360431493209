export default () => ({
  allowDirectConnect: false,
  appConnectDetails: null,
  apps: null,
  appViewed: null,
  configDetails: null,
  connectedApps: null,
  isLoading: false,
  isLoadingConnectedApps: false,
  smartcarVins: null,
});
