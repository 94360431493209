<template>
  <div class="row justify-center">
    <div class="full-width">
      <q-checkbox
        label="Only include vehicles that match current filter"
        :model-value="nearbyVehiclesFiltered"
        @update:model-value="setNearbyVehiclesFiltered"
      />
    </div>
    <q-table
      v-if="!error && (isLoading || (!isLoading && hasVehicles))"
      :class="{ 'q-mt-md': true, row: true, inline: true, 'full-width': !$q.screen.lt.md, scroll: true }"
      color="primary"
      :columns="[
        {
          name: 'letterId',
          field: (row) => row.letterId,
          align: 'center',
          sortable: true,
        },
        {
          name: 'nickname',
          label: 'Vehicle / Location',
          field: (row) => row.nickname,
          align: 'left',
          sortable: true,
        },
        {
          name: 'driver',
          label: 'Driver',
          field: (row) => row.driver,
          align: 'left',
          sortable: true,
        },
        {
          name: 'duration',
          required: true,
          label: 'Driving Time',
          field: (row) => row.duration,
          sortable: true,
        },
        {
          name: 'distance',
          required: true,
          label: 'Driving Distance',
          field: (row) => row.distance,
          sortable: true,
        },
        {
          name: 'select',
          field: (row) => row.coordinates,
        },
      ]"
      hide-bottom
      :loading="isLoading"
      :pagination="pagination"
      :rows="tableData"
      style="height: calc(100% - 56px)"
    >
      <template #body="props">
        <q-tr
          v-if="!error"
          :class="{ highlighted: highlightedVehicle === props.row.key }"
          :props="props"
          @mouseout="onRowHover(null)"
          @mouseover="onRowHover(props.row.key)"
        >
          <q-td key="letterId" :props="props">
            <div class="text-h6">{{ props.row.letterId }}</div>
          </q-td>
          <q-td key="nickname" :props="props">
            <div>
              <strong class="q-mr-xs text-body1 text-weight-bold">{{ props.row.nickname || 'Unnamed Vehicle' }}</strong>
              <span v-if="props.row.statusDisplay" :style="{ opacity: 0.8, color: getColorByStatus(props.row.status) }"
                >({{ props.row.statusDisplay }})</span
              >
            </div>
            <AddressString
              v-if="props.row.coordinates.length"
              class="text-grey-9"
              :latitude="props.row.coordinates[1]"
              :longitude="props.row.coordinates[0]"
            />
          </q-td>
          <q-td key="driver" :props="props">
            <DriverChip :dense="true" :driver="props.row.driver" />
          </q-td>
          <q-td key="duration" :props="props">
            <span v-if="props.row.duration">{{ secondsToHoursMinutes(props.row.duration) }}</span>
            <span v-else class="text-grey-7">--</span>
          </q-td>
          <q-td key="distance" :props="props">
            <span v-if="props.row.distance"
              >{{ localeString(toDecimalPlaces(metersToMiles(props.row.distance), 1)) }} mi</span
            >
            <span v-else class="text-grey-7">--</span>
          </q-td>
          <q-td key="select" class="full-width" :props="props">
            <PrimaryInlineButton
              v-if="props.row.key"
              color="white"
              dense
              icon-right="open_in_new"
              label="Directions"
              text-color="primary"
              @click="getDirections(props.row.coordinates, coordinates)"
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <div v-if="!vehiclesList.length" class="q-my-md text-center">
      <div class="text-h6 q-mb-md text-weight-bold">There are no vehicles to find based on the current filters.</div>
      <div class="q-mb-md text-body1" style="max-width: 50em">
        There are
        <strong>{{ mappableAssets.length }}</strong> vehicles available that do not match these filters. Use the button
        below to ignore all filters and refresh or close this modal and adjust the filters to find nearby vehicles.
      </div>
      <q-btn
        color="white"
        label="Ignore Filters &amp; Refresh"
        text-color="primary"
        @click="setNearbyVehiclesFiltered(false)"
      />
    </div>
    <div v-if="error">
      <div v-if="error === 'NO_ROUTE'" class="text-body2 column items-center text-center">
        <div class="q-py-lg">
          <q-icon name="img:no-route.svg" size="100px" />
        </div>
        <div style="max-width: 50em">
          There are no routes to the selected location. Please drag the location marker to a different location or close
          this dialog and choose another location on the Live Map.
        </div>
      </div>
      <div v-else class="q-mt-md q-pa-md text-body2 text-white bg-negative text-center">
        Unable to determine driving durations to the selected location. Please contact support if you continue to
        experience this issue.
      </div>
    </div>
  </div>
</template>

<script>
import { colors, openURL } from 'quasar';
import { mapActions, mapGetters, mapState } from 'vuex';
import AddressString from 'components/AddressString.vue';
import PrimaryInlineButton from 'components/buttons/PrimaryInlineButton.vue';
import DriverChip from 'components/chips/DriverChip.vue';
import Driver from 'src/models/Driver';
import { ROWS_PER_PAGE_LOWEST, statuses } from 'src/services/constants';
import { secondsToHoursMinutes } from 'src/services/date';
import { localeString, metersToMiles } from 'src/services/locale';
import { toDecimalPlaces } from 'src/services/number';

const { getPaletteColor } = colors;

export default {
  name: 'NearbyVehiclesTable',
  props: {
    coordinates: Array,
    error: String,
    highlightedVehicle: String,
    isLoading: Boolean,
    vehiclesList: Array,
  },
  components: {
    AddressString,
    DriverChip,
    PrimaryInlineButton,
  },
  computed: {
    ...mapState('map', ['nearbyVehiclesFiltered']),
    ...mapGetters('assets', ['mappableAssets']),
    hasVehicles() {
      return this.vehiclesList.length > 0;
    },
    tableData() {
      const defaultList = [...new Array(5)].map(() => ({
        driver: new Driver(),
        coordinates: [],
      }));
      return this.hasVehicles ? this.vehiclesList : defaultList;
    },
  },
  data() {
    return {
      pagination: {
        sortBy: 'duration',
        descending: false,
        page: 1,
        rowsPerPage: ROWS_PER_PAGE_LOWEST,
      },
    };
  },
  methods: {
    ...mapActions('map', ['setNearbyVehiclesFiltered']),
    ...mapActions('vehicles', ['selectVehicle']),
    getColorByStatus(status) {
      switch (status) {
        case statuses.MOVING:
          return getPaletteColor('positive');
        case statuses.STOPPED:
          return getPaletteColor('primary');
        default:
          return getPaletteColor('silver');
      }
    },
    getDirections(from, to) {
      const reversedFrom = [...from].reverse().join(',');
      const reversedTo = [...to].reverse().join(',');
      openURL(`https://www.google.com/maps/dir/${reversedFrom}/${reversedTo}/`);
    },
    goToVehicle(key) {
      this.selectVehicle(key);
      this.$router.push('/');
    },
    localeString,
    metersToMiles,
    onRowHover(key) {
      this.$emit('highlight-vehicle', key);
    },
    secondsToHoursMinutes,
    toDecimalPlaces,
  },
};
</script>

<style>
.highlighted td:before {
  content: '';
  background: rgba(0, 0, 0, 0.03);
}
.highlighted td:before,
.highlighted td:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
</style>
