import { emailRegex } from 'src/services/constants';

function hasValueFactory(errorMessage = 'This field is required') {
  return (input) => Boolean(input) || errorMessage;
}

function isAcceptableLengthFactory(errorMessage = 'This field must be 6 to 50 characters long') {
  return (input) => (input.length >= 6 && input.length <= 50) || errorMessage;
}

function isValidEmailFactory(errorMessage = 'This field requires a valid e-mail address') {
  return (input) => emailRegex.test(input) || errorMessage;
}

export { hasValueFactory, isAcceptableLengthFactory, isValidEmailFactory };
