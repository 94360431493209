<template>
  <q-separator v-if="separator" class="separator" color="grey-4" />
  <NotificationsMenuItem v-bind="notification" :last-update="lastUpdate" />
</template>

<script setup lang="ts">
import type { Dayjs } from 'dayjs';
import NotificationsMenuItem from 'components/notifications/NotificationsMenuItem.vue';
import type Notification from 'src/models/Notification';

withDefaults(defineProps<{ lastUpdate: Dayjs | null; notification: Notification; separator?: boolean }>(), {
  // Add separator unless explicitly told not to
  separator: true,
});
</script>
