import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { toRaw } from 'vue';
import AssetHistory from 'src/models/AssetHistory';
import ErrorPayload from 'src/models/ErrorPayload';
import GeoJsonFeature from 'src/models/GeoJsonFeature';
import { navigateToUrl } from 'src/services/navigation';

/**
 * Closes the Add/Edit Asset Dialog and clears it from the store.
 *
 * @param {ActionContext} context
 */
export function closeEditAssetDialog(context) {
  context.commit('closeEditDialog');
}

/**
 * Connects a given device to the given asset.
 *
 * @param {ActionContext} context
 * @param {Object} payload
 * @param {string} payload.key
 * @param {string} payload.serial
 * @param {'asset' | 'vehicle'} payload.type
 */
export async function connectDevice(context, { key, serial, type }) {
  const result = await context.rootState.app.broker.updateDevicePairing({
    action: 'pair',
    key,
    serial,
    type,
  });

  context.commit('addRecentConnection', key);

  if (result.error) {
    return new ErrorPayload('An unexpected error occurred while pairing the device.');
  }

  context.dispatch('devices/updateDeviceAsset', { assetKey: key, serial }, { root: true });

  return result;
}

/**
 * Disconnects the device from a given asset.
 *
 * @param {ActionContext} context
 * @param {Object} payload
 * @param {string} payload.key
 * @param {string} payload.serial
 * @param {'asset' | 'vehicle'} payload.type
 * @returns {Object|ErrorPayload}
 */
export async function disconnectDevice(context, { key, serial, type }) {
  const asset = await context.rootState.app.broker.updateDevicePairing({
    action: 'unpair',
    key,
    serial,
    type,
  });

  if (asset.error) {
    return new ErrorPayload('An unexpected error occurred while unpairing the device.');
  }

  context.dispatch('devices/updateDeviceAsset', { assetKey: null, serial }, { root: true });

  return asset;
}

/**
 * Deletes the given asset.
 *
 * @param {ActionContext} context
 * @param {String} assetKey
 * @returns {Object|ErrorPayload}
 */
export async function deleteAsset(context, assetKey) {
  const response = await context.rootState.app.broker.deleteAsset(assetKey);

  if (response.error) {
    return new ErrorPayload(response.error);
  }

  return response;
}

/**
 * Deletes the given asset type.
 *
 * @param {ActionContext} context
 * @param {String} assetTypeKey
 * @returns {Object|ErrorPayload}
 */
export async function deleteAssetType(context, assetTypeKey) {
  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'deleteAssetType',
    params: assetTypeKey,
  });

  if (response.error || response.errors) {
    return new ErrorPayload('An unexpected error occurred while deleting the asset type.');
  }

  context.commit('deleteAssetType', assetTypeKey);

  return response;
}

/**
 * Retrieves asset types from the Data Broker and commits them to the store.
 *
 * @param {ActionContext} context
 */
export async function getAssetTypes(context) {
  context.commit('setAssetTypesLoading', true);

  const assetTypes = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getAssetTypes',
    transformationFn: 'transformArrayToObject',
  });

  if (!assetTypes.error) {
    context.commit('setAssetTypes', assetTypes);
    context.rootState.app.broker.setUniversalSearchAssetTypes(assetTypes);
  }

  context.commit('setAssetTypesLoading', false);
}

/**
 * Opens the Add/Edit Asset Dialog.
 *
 * @param {AssetsStoreActionContext} context
 * @param {string} key
 */
export function openEditAssetDialog(context, key) {
  context.commit('openEditDialog', key);
}

export function updateAssets(context, updates) {
  context.commit('updateAssets', updates);

  /**
   * Handle special situations after filtering occurs.
   */
  // if (context.rootState.filtering.filteringOccurred) {
  // TODO: Determine if these things should happen on asset updates
  // Expand bounding box if all vehicles are out of view.
  // if (context.getters.vehiclesShowOnList.length === 0 && context.getters.vehiclesVisible.length > 0) {
  //   context.dispatch(
  //     'map/fitToIdealBounds',
  //     {},
  //     {
  //       root: true,
  //     }
  //   );
  // }
  // Unselect selected vehicle if it is now filtered out
  // if (context.state.selectedVehicleKey !== null && !context.getters.selectedVehicle.isVisible) {
  //   context.dispatch('unselectAsset');
  // }
  // context.commit('filtering/filteringOccurred', false, {
  //   root: true,
  // });
  // }
}

/**
 * Creates/updates the given asset.
 *
 * @param {ActionContext} context
 * @param {Object} asset
 * @param {String} asset.assetTypeKey
 * @param {String} asset.customImageUrl
 * @param {String} asset.group
 * @param {String} asset.nickname
 * @param {String[]} asset.tagKeys
 * @returns {Object|ErrorPayload}
 */
export async function saveAsset(context, { key, assetTypeKey, imageUrl, nickname, groupKeys, tagKeys }) {
  const data = {
    assetTypeKey,
    imageUrl,
    nickname,
    groupKeys: toRaw(groupKeys),
    tagKeys: toRaw(tagKeys),
  };

  const asset = await context.rootState.app.broker.saveAsset({
    key,
    data,
  });

  if (asset.error) {
    return new ErrorPayload(`An unexpected error occurred while ${key ? 'updating' : 'creating'} the asset.`);
  }

  return asset;
}

/**
 * Creates/updates an asset via the Data Broker using the given update.
 *
 * @param {ActionContext} context
 * @param {Object} update
 * @param {String} update.key
 * @param {String} update.name
 */
export async function saveAssetType(context, { key, iconUri, name }) {
  const apiFn = key ? 'updateAssetType' : 'createAssetType';

  /** @type {import('src/workers/api/assets').AssetTypeResponse} */
  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: apiFn,
    params: {
      key,
      data: {
        icon_uri: iconUri,
        name,
      },
    },
    transformationFn: 'camelCaseKeysDeep',
  });

  if (response.error || response.errors) {
    return new ErrorPayload(`An unexpected error occurred while ${key ? 'updating' : 'creating'} the asset type.`);
  }

  if (key) {
    context.commit('updateAssetType', response);
    // Update vehicles layer so icon changes are reflected immediately
    context.dispatch('map/updateAssetsLayer', context.rootGetters['assets/visibleAssets'], { root: true });
  } else {
    context.commit('insertAssetType', response);
  }

  return response;
}

/**
 * Selects an asset and dispatches related actions.
 *
 * @param {ActionContext} context
 * @param {String} assetKey
 */
export async function selectAsset(context, assetKey) {
  context.commit('selectAsset', assetKey);

  // Do this as early as possible
  context.dispatch('trips/updateSelectedVehicleTrip', true, { root: true });

  if (
    context.rootState.vehicles.followedVehicleKey !== null &&
    context.rootState.vehicles.followedVehicleKey !== assetKey
  ) {
    context.dispatch('vehicles/toggleFollow', null, { root: true });
  }

  context.dispatch('map/lockToIdealBounds', false, { root: true });
  context.dispatch('map/updateAssetsLayer', context.rootGetters['assets/visibleAssets'], { root: true });
  context.dispatch('map/setContextualMenuOpen', false, { root: true });
  context.dispatch(
    'map/onDoubleClickFocus',
    () => {
      const { selectedAsset } = context.getters;

      if (!selectedAsset) {
        return null;
      }

      const { location } = selectedAsset;
      return {
        lat: location.latitude,
        lng: location.longitude,
      };
    },
    { root: true }
  );
}

/**
 * Toggles the selected key (value vs. null).
 *
 * @param {ActionContext} context
 * @param {String} assetKey
 */
export function toggleSelectAsset(context, assetKey) {
  const route = { name: 'map' };

  if (assetKey && context.state.selectedKey !== assetKey) {
    route.name = 'map-selected';
    route.params = { key: assetKey };
  }

  navigateToUrl(route);
}

/**
 * Unselects the selected asset.
 *
 * @param {ActionContext} context
 */
export function unselectAsset(context) {
  context.commit('selectAsset', null);
  context.dispatch('vehicles/toggleFollow', null, { root: true });
  context.dispatch('map/updateAssetsLayer', context.rootGetters['assets/visibleAssets'], {
    root: true,
  });
  context.dispatch('trips/updateSelectedVehicleTrip', null, { root: true });
  context.dispatch('map/unsetDoubleClickFocus', null, { root: true });
}

/**
 * Toggles whether a location is selected.
 *
 * @param {ActionContext} context
 * @param {String} id
 */
export function toggleSelectOneLocation(context, id) {
  const index = context.getters.locations.findIndex((loc) => loc.key === id);
  if (index >= 0) {
    const item = context.state.locationHistory[index];
    const isSelected = item.selected !== false;
    context.commit('setOneLocationSelection', { index, value: !isSelected });
  }
}

/**
 * Toggles whether a location is selected.
 *
 * @param {ActionContext} context
 */
export function toggleSelectAllLocations(context) {
  const isSelected = context.getters.areAllLocationsSelected;
  context.commit('setAllLocationsSelection', !isSelected);
}

/**
 * Sets the location history selection to include only the passed item
 *
 * @param {ActionContext} context
 * @param {String} id
 */
export function selectSingleLocation(context, id) {
  const index = context.getters.locations.findIndex((loc) => loc.key === id);
  if (index >= 0) {
    context.commit('setLocationSelectionToOneLocation', index);
  }
}

/**
 * Clears the hovered location history item id
 *
 * @param {ActionContext} context
 */
export function clearHoveredLocation(context) {
  context.commit('clearHoveredLocation');
}

/**
 * Sets the hovered location history item (key).
 *
 * @param {ActionContext} context
 * @param {Object} payload
 * @param {String} payload.key
 * @param {Boolean} payload.fromList
 */
export function setHoveredLocation(context, { key, fromList = false }) {
  context.commit('setHoveredFromList', fromList);
  context.commit('setHoveredLocation', key);
}

/**
 * Fetch asset sensor history
 *
 * @param {ActionContext} context
 * @param {Object} requestOptions
 */
export async function fetchSensorHistory(context, { requestOptions, loadingMutation, dataMutation }) {
  if (loadingMutation) {
    context.commit(loadingMutation, true);
  }

  const results = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getAssetHistory',
    params: requestOptions,
    transformationFn: 'transformArray',
  });

  if (loadingMutation) {
    context.commit(loadingMutation, false);
  }

  if (!results.error) {
    const history = results.map((item) => new AssetHistory(item));
    if (dataMutation) {
      context.commit(dataMutation, { key: requestOptions.key, value: history });
    }
  }
}

export async function fetchAggregatedLocationHistory(context, { requestOptions }) {
  context.commit('setLocationHistoryLoading', true);

  const results = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getAssetHistory',
    params: requestOptions,
    transformationFn: 'transformArray',
  });

  if (results.error) {
    return;
  }

  const features = results.map(
    ({ ambientTemperature, deviceBatteryChargeLevel, key, point, timestamp, tripKey }) =>
      new GeoJsonFeature([point.lon, point.lat], {
        ambientTemperature,
        deviceBatteryChargeLevel,
        key,
        timestamp,
        tripKey,
      })
  );
  const collection = {
    type: 'FeatureCollection',
    features,
  };

  const aggregated = await context.rootState.app.broker.getAggregatedAssetLocationHistory(collection);

  context.commit('setLocationHistory', { value: aggregated });

  context.commit('setLocationHistoryLoading', false);
}

/**
 * Updates tags on vehicles/assets using the provided update.
 *
 * @param {ActionContext} context
 * @param {Object} update
 * @param {String} update.action
 * @param {String[]} update.members
 * @param {String[]} update.tagKeys
 * @param {Object[]} update.tags
 */
export function updateAssetTags(context, update) {
  const assets = _cloneDeep(_get(context.rootState, 'assets.assets') || {});
  const vehicles = _cloneDeep(_get(context.rootState, 'vehicles.vehicles') || {});

  update.members.forEach((key) => {
    const item = vehicles[key] || assets[key];

    if (!item) {
      return;
    }

    // Ensure tags & tagKeys array exists
    item.tags = [...(item.tags || [])];
    item.tagKeys = [...(item.tagKeys || [])];

    if (update.action === 'remove') {
      item.tags = item.tags.filter((tag) => !update.tagKeys.includes(tag.key));
      item.tagKeys = item.tagKeys.filter((tagKey) => !update.tagKeys.includes(tagKey));
    } else {
      update.tagKeys.forEach((tagKey) => {
        if (!item.tagKeys.includes(tagKey)) {
          item.tagKeys.push(tagKey);
          item.tags.push(update.tags[tagKey]);
        }
      });
    }
  });

  context.commit('setAssets', assets);
  context.dispatch('vehicles/updateVehicles', vehicles, { root: true });
}

/**
 * Removes the given assets (by key) from the store.
 *
 * @param {ActionContext} context
 * @param {String[]} assetKeys
 */
export async function unsetAssets(context, assetKeys) {
  const assets = { ...context.state.assets };
  assetKeys.forEach((key) => {
    delete assets[key];
  });
  context.commit('setAssets', assets);
}
