<template>
  <VehicleDefaultImage v-if="type === 'VEHICLE'" class="relative" data-t="errorImage" :size="size" style="top: -2px" />
  <AssetDefaultImage v-else :size="size" />
</template>

<script setup lang="ts">
import AssetDefaultImage from 'components/assets/AssetDefaultImage.vue';
import VehicleDefaultImage from 'components/vehicle/VehicleDefaultImage.vue';
import { ASSET_TYPES } from 'src/services/constants';

type AssetTypes = typeof ASSET_TYPES;

defineProps<{ size: number; type: keyof AssetTypes }>();
</script>
