<template>
  <ZubieChip
    :clickable="!hasNoDevice"
    :dense="dense"
    :icon="iconImg"
    :no-copy="hasNoDevice"
    :search-terms="searchTerms"
    :transparent-outline="hasNoDevice"
  >
    {{ serial }}
  </ZubieChip>
</template>

<script>
import ZubieChip from 'components/chips/ZubieChip.vue';
import Obd2Svg from 'src/assets/obdii.svg?raw';
import Device from 'src/models/Device';
import SvgMarker from 'src/services/svg/SvgMarker';
import { svgToBase64 } from 'src/services/svg/tools';

export default {
  name: 'DeviceChip',
  components: {
    ZubieChip,
  },
  props: {
    dense: {
      default: false,
      type: Boolean,
    },
    device: {
      required: true,
      type: Device,
    },
    icon: {
      default: '',
      type: String,
    },
    searchTerms: {
      default: () => [],
      type: Array,
    },
  },
  computed: {
    iconImg() {
      const { icon } = this;
      if (icon) {
        return icon;
      }
      const svg = svgToBase64(new SvgMarker(Obd2Svg, { outerColor: 'rgba(0,0,0,0.54)' }).colorize());
      // check_box_outline_blank is an empty square, like used for the no device live map pin
      return this.device.serial ? `img:${svg}` : 'check_box_outline_blank';
    },
    serial() {
      const { serial = '' } = this.device;
      return serial || 'No Device';
    },
    hasNoDevice() {
      const { serial = '' } = this.device;
      return !serial;
    },
  },
};
</script>
