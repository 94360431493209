<template>
  <svg
    height="39.69"
    preserveAspectRatio="xMidYMid meet"
    version="1.1"
    viewBox="0 0 41.575 39.685"
    width="41.58"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path id="bcshyxmIf" d />
      <path id="bRgbipS53" d="M19.17 33.84L39.92 0L3.17 0L19.17 33.84Z" />
    </defs>
    <g>
      <g>
        <g>
          <g>
            <use
              fill-opacity="0"
              opacity="1"
              stroke="#000000"
              stroke-opacity="1"
              stroke-width="1"
              xlink:href="#bcshyxmIf"
            />
          </g>
        </g>
        <g>
          <g>
            <filter
              id="shadow4583678"
              filterUnits="userSpaceOnUse"
              height="96.75"
              primitiveUnits="userSpaceOnUse"
              width="92.1"
              x="-24.51"
              y="-27.68"
            >
              <feFlood />
              <feComposite in2="SourceAlpha" operator="in" />
              <feGaussianBlur stdDeviation="2.75" />
              <feOffset dx="0" dy="7.56" result="afterOffset" />
              <feFlood flood-color="#000000" flood-opacity="0.2" />
              <feComposite in2="afterOffset" operator="in" />
              <feMorphology operator="dilate" radius="0" />
              <feComposite in2="SourceAlpha" operator="out" />
            </filter>
            <path
              id="e6BBtLxBXF"
              d="M19.17 33.84L39.92 0L3.17 0L19.17 33.84Z"
              fill="white"
              fill-opacity="1"
              filter="url(#shadow4583678)"
            />
          </g>
          <use fill="#ffffff" fill-opacity="1" opacity="1" xlink:href="#bRgbipS53" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
