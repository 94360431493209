<template>
  <q-dialog ref="dialogRef" :maximized="$q.screen.xs" transition-hide="fade" transition-show="fade">
    <q-card class="q-dialog-plugin q-pa-sm column justify-between">
      <q-card-section class="column justify-center" style="flex: 1">
        <div>
          <div class="text-h6 text-center q-mb-md">Network Connection Required</div>
          <div class="text-center">
            <q-icon class="q-my-md" color="dark" name="wifi_off" size="150px" />
          </div>
          <div>A network connection is required to continue. Please make sure you are connected to the internet.</div>
        </div>
      </q-card-section>
      <q-card-actions class="column justify-end">
        <q-btn class="q-px-sm full-width" color="white" label="OK" square text-color="primary" @click="onDialogOK" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { useDialogPluginComponent, useQuasar } from 'quasar';

const { dialogRef, onDialogOK } = useDialogPluginComponent();

defineEmits([...useDialogPluginComponent.emits]);

const $q = useQuasar();
</script>
