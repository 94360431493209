<template>
  <q-dialog
    ref="dialogRef"
    :full-height="fullHeight"
    :full-width="fullWidth"
    :hide-scroll-indicator="hideScrollIndicator"
    :maximized="maximized"
    :model-value="visible"
    :no-esc-dismiss="noEscDismiss"
    :no-focus="noFocus"
    :no-refocus="noRefocus"
    :no-route-dismiss="noRouteDismiss"
    :persistent="!dirtyBypass && (persistent || processing || dirty)"
    :seamless="seamless"
    :transition-hide="transitionHide"
    :transition-show="transitionShow"
    @before-hide="$emit('before-hide')"
    @before-show="$emit('before-show')"
    @hide="onHide"
    @shake="onShake"
    @show="onShow"
  >
    <q-card
      ref="mainCard"
      class="main-card column"
      :class="allCardClasses"
      :style="{ maxWidth: cardMaxWidth, width, maxHeight }"
    >
      <q-form
        ref="form"
        class="column col no-wrap zd-form"
        :class="{ 'window-height full-width': maximized }"
        @submit="$emit('submit', $event)"
      >
        <q-card-section
          v-if="title || $slots.header || $slots.title"
          ref="header"
          :class="`bg-${titleColor} text-${titleTextColor} roboto dialog--header relative zubie-font ${
            titleDense ? 'q-py-xs q-px-md' : ''
          } ${noCaps ? 'no-caps' : ''}`"
        >
          <div :class="titleDense ? 'text-subtitle1 text-weight-medium' : 'text-h6'" :style="titleStyle">
            <slot name="title">
              {{ title }}
            </slot>
          </div>
          <slot name="header" />
        </q-card-section>
        <q-card-section ref="body" class="overflow-auto dialog--body" :class="contentClass" :style="allContentStyles">
          <slot />
          <q-scroll-observer @scroll="onScroll" />
          <q-resize-observer @resize="onScroll" />
        </q-card-section>
        <q-card-actions
          v-if="!hideActions"
          ref="footer"
          :align="$slots['actions-prepend'] ? 'between' : 'right'"
          class="q-pa-md dialog--footer has-scroll full-width content-center bg-white"
          :class="{ 'fixed-bottom': maximized, relative: !maximized, 'has-scroll': !hideScrollIndicator }"
          :style="{ paddingBottom: notchSizes.bottom && !noIosPaddingBottom ? `${notchSizes.bottom}px` : undefined }"
        >
          <slot name="actions">
            <slot name="actions-prepend" />
            <div
              class="row"
              :class="{
                'full-width': !$slots['actions-prepend'],
                'justify-around': !$slots['actions-prepend'] && $q.screen.xs,
                'justify-end': !$q.screen.xs,
              }"
            >
              <CancelButton
                v-if="okOnly"
                :class="{ 'full-width': !$q.screen.gt.sm }"
                :data-t="actionButtonDataT"
                :disable="processing || actionButtonDisable"
                :label="cancelText"
                padding="sm md"
                @click="onDialogOK"
              />
              <CancelButton
                v-if="!okOnly && !forceAction"
                class="q-mr-xs"
                :data-t="cancelButtonDataT"
                :disable="processing || cancelButtonDisable"
                :label="cancelText"
                padding="sm md"
                @click="onCancel"
              />
              <component
                :is="actionButtonComponent"
                v-if="!okOnly"
                :data-t="actionButtonDataT"
                :disable="processing || actionButtonDisable"
                padding="sm md"
                type="submit"
                @click.stop
              >
                <q-spinner v-if="processing" class="q-mr-sm" />
                {{ actionButtonText }}
              </component>
            </div>
          </slot>
          <q-resize-observer @resize="onFooterResize" />
        </q-card-actions>
      </q-form>
    </q-card>

    <ZubieDialog
      action-button-text="Discard Changes"
      action-button-type="negative"
      cancel-button-text="Keep Editing"
      title="Unsaved Changes"
      :visible="showDirtyConfirm"
      @hide="showDirtyConfirm = false"
      @submit="onConfirmDirtyDismiss"
    >
      Your changes haven't been saved. Are you sure you want to leave without saving?
    </ZubieDialog>
  </q-dialog>
</template>

<script setup lang="ts">
import parseStyle, { type Declaration } from 'inline-style-parser';
import _debounce from 'lodash/debounce';
import { QCard, QCardActions, QCardSection, QDialog, QForm, useDialogPluginComponent, useQuasar } from 'quasar';
import { computed, nextTick, onMounted, ref, useTemplateRef, watchEffect } from 'vue';
import { useStore } from 'vuex';
import NegativeButton from 'components/buttons/NegativeButton.vue';
import PrimaryButton from 'components/buttons/PrimaryButton.vue';
import type { AppStoreState } from 'src/types/app-store';

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent();

const $q = useQuasar();

const props = withDefaults(
  defineProps<{
    actionButtonColor?: string;
    actionButtonDataT?: string;
    actionButtonDisable?: boolean;
    actionButtonText?: string;
    actionButtonType?: string;
    cancelButtonDataT?: string;
    cancelButtonDisable?: boolean;
    cancelButtonText?: string;
    cardClass?: string;
    closeOnCancel?: boolean;
    contentClass?: string;
    contentStyle?: string | object;
    dirty?: boolean;
    forceAction?: boolean;
    fullHeight?: boolean;
    fullWidth?: boolean;
    hideActions?: boolean;
    hideScrollIndicator?: boolean;
    innerDialogHeightOffset?: number;
    maxWidth?: string | number;
    noCaps?: boolean;
    noEscDismiss?: boolean;
    noIosPaddingBottom?: boolean;
    noRouteDismiss?: boolean;
    okButtonFlat?: boolean;
    okOnly?: boolean;
    maximized?: boolean;
    noFocus?: boolean;
    noRefocus?: boolean;
    persistent?: boolean;
    processing?: boolean;
    seamless?: boolean;
    title?: string;
    titleDense?: boolean;
    titleColor?: string;
    titleStyle?: string;
    titleTextColor?: string;
    transitionHide?: string;
    transitionShow?: string;
    visible?: boolean;
    width?: string;
  }>(),
  {
    actionButtonColor: 'primary',
    actionButtonText: 'OK',
    actionButtonType: 'primary',
    closeOnCancel: true,
    innerDialogHeightOffset: 0,
    maxWidth: '560px',
    titleColor: 'primary',
    titleTextColor: 'white',
    transitionHide: 'scale',
    transitionShow: 'scale',
    visible: true,
  }
);

const emit = defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits,
  'cancel',
  'show',
  'before-hide',
  'before-show',
  'submit',
]);

defineExpose({
  hide: () => onHide(),
  ok: () => onDialogOK(),
});

const store = useStore();

const notchSizes = computed(() => (<AppStoreState>store.state.app).notchSizes);

const actionButtonComponent = computed(() => {
  if (props.actionButtonType === 'negative') {
    return NegativeButton;
  }
  return PrimaryButton;
});

const allCardClasses = computed(() => {
  const classes = [props.cardClass];
  return classes.join(' ');
});

const allContentStyles = computed(() => {
  const style = {} as { [key: string]: string };
  const contentStyle = props.contentStyle || {};

  if (typeof contentStyle === 'string') {
    const styles = <Declaration[]>parseStyle(contentStyle);
    styles.forEach(({ property, value }) => {
      style[property] = value;
    });
  }

  if (props.maximized) {
    style.marginBottom = `${footerHeight || 0}px`;
  }

  return style;
});

const cardMaxWidth = computed(() => (Number.isFinite(props.maxWidth) ? `${props.maxWidth}px` : props.maxWidth));

const dirtyBypass = ref(false);
const showDirtyConfirm = ref(false);
async function onConfirmDirtyDismiss() {
  showDirtyConfirm.value = false;
  dirtyBypass.value = true;
  await nextTick();
  onHide();
}

function onCancel() {
  onDialogCancel();
  if (props.closeOnCancel) {
    onHide();
  }
}

function onHide() {
  showDirtyConfirm.value = false;
  store.dispatch('app/decrementOpenZubieDialogCount');
  onDialogHide();
}

const mainCard = useTemplateRef<QCard>('mainCard');
const maxHeight = ref();
function onShow() {
  // Update inner dialog height to allow space for action buttons
  const actionsNavHeight = mainCard.value?.$el.querySelector('.q-card__actions').offsetHeight;
  maxHeight.value = `calc(100vh - env(safe-area-inset-top) - ${actionsNavHeight}px - ${props.innerDialogHeightOffset}px) !important`;

  store.dispatch('app/incrementOpenZubieDialogCount');
  emit('show');
}

function onShake() {
  // Looking for "shakes" that aren't due to processing/outside persistence
  if (!props.processing && !props.persistent && props.dirty && !showDirtyConfirm.value) {
    showDirtyConfirm.value = true;
  }
}

const body = ref<QCardSection>();
const form = ref<QForm>();
const header = ref<QCardSection>();
const footer = ref<QCardActions>();
function onScrollImmediate() {
  const bodyEl = <HTMLDivElement>body.value?.$el;
  const formEl = <HTMLDivElement>form.value?.$el;
  const headerEl = <HTMLDivElement>header.value?.$el;
  const footerEl = <HTMLDivElement>footer.value?.$el;

  if (!props.hideScrollIndicator && bodyEl) {
    const { offsetHeight, offsetWidth, scrollHeight, scrollTop, scrollWidth } = bodyEl;
    // account for scrollbar width
    const scrollbarWidth = offsetWidth - scrollWidth;
    const scrollIndicatorWidth = `calc(100% - ${scrollbarWidth}px)`;
    if (formEl?.style) {
      formEl.style.setProperty('--scroll-indicator-width', scrollIndicatorWidth);
    }
    // header
    if (scrollTop > 0 && headerEl?.classList) {
      headerEl.classList.add('has-scroll');
    } else if (headerEl) {
      headerEl.classList.remove('has-scroll');
    }
    // footer
    const remainingBottom = scrollHeight - offsetHeight - scrollTop;
    if (remainingBottom <= 0 && footerEl?.classList) {
      footerEl.classList.remove('has-scroll');
    } else if (footerEl) {
      footerEl.classList.add('has-scroll');
    }
  }
}

// use this instead of the debounce attribute on both observers so calls from both go into the same debounce queue
const onScroll = _debounce(onScrollImmediate);

const cancelText = ref<string | undefined>('');
function setCancelText() {
  cancelText.value = props.cancelButtonText;
  if (!cancelText.value) {
    cancelText.value = props.okOnly ? 'Close' : 'Cancel';
  }
}
setCancelText();

const footerHeight = ref<number>();
function updateFooterSpace() {
  footerHeight.value = footer.value?.$el?.offsetHeight;
}
const onFooterResize = _debounce(() => updateFooterSpace());

onMounted(() => {
  updateFooterSpace();
});

watchEffect(() => {
  if (props.cancelButtonText) {
    setCancelText();
  }
});
</script>

<style lang="scss" scoped>
.zd-form {
  --scroll-indicator-width: 100%;
}

.main-card {
  @media (min-width: 400px) {
    min-width: 400px;
  }
}

.q-card {
  overflow: hidden;
}

.q-form {
  max-height: inherit;
}

.dialog--footer {
  border-top: 1px solid $grey-5;
}

.dialog--header {
  text-transform: capitalize;
}

.dialog--header.no-caps {
  text-transform: none;
}

.dialog--header:after,
.dialog--footer:before {
  content: '';
  position: absolute;
  left: 0;
  height: 50px;
  width: var(--scroll-indicator-width);
  opacity: 0;
  transition: opacity ease-in-out 0.25s;
  pointer-events: none;
}

.dialog--header:after {
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8) 80%),
    linear-gradient(to top, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.8));
  top: 100%;
  z-index: 1;
}

.dialog--body {
  flex: 1;
}

.dialog--footer:before {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8) 80%),
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.8));
  top: -51px; // height + 1px for the border
}

.has-scroll:before,
.has-scroll:after {
  opacity: 1;
}
</style>

<style lang="scss">
.q-dialog__inner--maximized {
  align-items: flex-start !important;
}
</style>
