/**
 * @param {EventsStoreState} state
 * @param {boolean} hasLoadedAll
 */
export function setHasLoadedAll(state, hasLoadedAll) {
  state.hasLoadedAll = hasLoadedAll;
}

/**
 * @param {EventsStoreState} state
 * @param {EventsFeedItem[]} events
 */
export function insertEvents(state, events) {
  state.events = [...state.events, ...events];
}

/**
 * @param {EventsStoreState} state
 * @param {EventsFeedItem[]} events
 */
export function setEvents(state, events) {
  state.events = events;
}

/**
 * @param {EventsStoreState} state
 * @param {string} eventsCursor
 */
export function setEventsCursor(state, eventsCursor) {
  state.eventsCursor = eventsCursor;
}

/**
 * @param {EventsStoreState} state
 * @param {boolean} isDoneLoading
 */
export function setIsDoneLoading(state, isDoneLoading) {
  state.isDoneLoading = isDoneLoading;
}

/**
 * @param {EventsStoreState} state
 * @param {boolean} isLoading
 */
export function setIsLoading(state, isLoading) {
  state.isLoading = isLoading;
}
