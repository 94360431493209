<template>
  <MapboxControl :position="position" tooltip="Map Options" @click="onClick">
    <q-icon color="dark" name="layers" size="20px" />
    <q-popup-proxy
      v-model="showMenu"
      anchor="bottom left"
      :maximized="$q.screen.xs"
      no-parent-event
      self="top left"
      transition-hide="jump-up"
      transition-show="jump-down"
    >
      <q-card class="bg-white column full-height">
        <MapOptions
          class="col overflow-auto"
          :cluster-assets="clusterAssets"
          :hide-hidden-places-button="hideHiddenPlacesButton"
          :hide-map-styles="hideMapStyles"
          :hide-markers="hideMarkers"
          :map-style="mapStyle"
          :show-hidden-places="showHiddenPlaces"
          :show-places="showPlaces"
          :show-tag-colors="showTagColors"
          :show-traffic="showTraffic"
          @set-map-style="$emit('set-map-style', $event)"
          @toggle-cluster-assets="$emit('toggle-cluster-assets')"
          @toggle-hidden-places="$emit('toggle-hidden-places')"
          @toggle-places="$emit('toggle-places')"
          @toggle-tag-colors="$emit('toggle-tag-colors')"
          @toggle-traffic="$emit('toggle-traffic')"
        />
        <q-card-actions
          v-if="!$q.screen.gt.xs"
          :align="$q.screen.xs ? 'around' : 'right'"
          class="q-pa-md relative content-center"
          :style="{ paddingBottom: notchSizes.bottom ? `${notchSizes.bottom}px` : undefined }"
        >
          <CancelButton v-close-popup class="q-px-sm" :class="{ 'full-width': !$q.screen.gt.sm }" label="Close" />
        </q-card-actions>
      </q-card>
    </q-popup-proxy>
  </MapboxControl>
</template>

<script>
import { mapState } from 'vuex';
import MapboxControl from 'components/map/MapboxControl.vue';
import MapOptions from 'components/map/MapOptions.vue';

export default {
  name: 'MapOptionsControl',
  props: {
    clusterAssets: Boolean,
    hideHiddenPlacesButton: Boolean,
    hideMapStyles: Boolean,
    hideMarkers: Boolean,
    mapStyle: String,
    position: String,
    showHiddenPlaces: Boolean,
    showPlaces: Boolean,
    showTagColors: Boolean,
    showTraffic: Boolean,
  },
  components: {
    MapboxControl,
    MapOptions,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState('app', ['notchSizes']),
  },
  methods: {
    onClick() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(button .mapboxgl-ctrl-icon) {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
