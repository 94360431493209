import _capitalize from 'lodash/capitalize';
import router from 'src/router';
import { ASSET_ICONS, ASSET_TYPES } from 'src/services/constants';
import Transformer from './Transformer';

class SearchResultTransformer extends Transformer {
  static transform(data) {
    const isVehicleAsset = ['asset', 'vehicle'].includes(data.type);

    const option = {
      assetType: data.assetType || null,
      bounds: data.bounds || null,
      description: this.getDescription(data),
      fullName: data.fullName || null,
      hasLocation: Boolean(data.hasLocation),
      hasTripsDevice: Boolean(data.hasTripsDevice),
      icon: this.getIcon(data),
      isMappable: isVehicleAsset || data.type === 'place',
      isVehicleAsset, // must be done early
      key: data.key || null,
      label: this.getLabel(data),
      liveMapKey: isVehicleAsset ? data.key : null,
      name: data.name || null,
      nickname: data.nickname || null,
      tripsRoute: data.tripsRoute,
      type: data.type || null,
    };

    // // DO NOT USE "data" AFTER THIS POINT
    // // `isVehicleAsset` must be set
    option.isCurrentRoute = this.checkIfCurrentRoute(option);
    // // `liveMapKey` must be set
    option.isOnMap = Boolean(option.hasLocation && (option.liveMapKey || option.bounds));

    return option;
  }

  /**
   * @param {ReturnType<typeof SearchResultTransformer.transform>} result
   */
  static checkIfCurrentRoute(result) {
    const { name, params } = router().currentRoute.value;

    if (
      result.type === 'vehicle' &&
      params.key === result.key &&
      ['vehicle', 'vehicleTrips', 'vehicleHealth', 'vehicleMaintenance'].includes(name)
    ) {
      return true;
    }

    if (
      result.type === 'asset' &&
      params.key === result.key &&
      ['asset', 'assetLocations', 'assetTrips'].includes(name)
    ) {
      return true;
    }

    return Boolean(result.isVehicleAsset && name === 'map-selected' && params.key === result.liveMapKey);
  }

  /**
   * @param {ReturnType<typeof SearchResultTransformer.transform>} result
   */
  static getDescription(result) {
    switch (result.type) {
      case 'asset':
        return result.assetType || 'Asset';
      case 'place':
        return 'Place';
      case 'user':
        return 'User / Driver';
      case 'vehicle':
        return 'Vehicle';
      default:
        return '';
    }
  }

  /**
   * @param {ReturnType<typeof SearchResultTransformer.transform>} result
   */
  static getIcon(result) {
    switch (result.type) {
      case 'asset':
        return ASSET_ICONS[ASSET_TYPES.ASSET];
      case 'place':
        return 'place';
      case 'user':
        return 'person';
      case 'vehicle':
        return 'directions_car';
      default:
        return 'search';
    }
  }

  /**
   * @param {ReturnType<typeof SearchResultTransformer.transform>} result
   */
  static getLabel(result) {
    switch (result.type) {
      case 'asset':
      case 'vehicle':
        return result.nickname || `Unnamed ${_capitalize(result.type)}`;
      case 'place':
        return result.name;
      case 'user':
        return result.fullName;
      default:
        return '';
    }
  }
}

export default SearchResultTransformer;
