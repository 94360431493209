<template>
  <div class="universal-search full-width" @click.stop>
    <div ref="inputBlur" class="q-focus-helper" tabindex="-1"></div>
    <SearchInput
      :autofocus="autofocus"
      @blur="onBlur"
      @clear="onClear"
      @click="performSearch"
      @focus="onFocus"
      @keydown="onInputKeyDown"
      @update:model-value="performSearch"
    />
    <SearchResultsList
      :show="isInFocus && (!isFilter || !isFilterRoute)"
      @click="handleClickedResult"
      @details-click="handleClickedResult"
      @live-map-click="onLiveMapClick"
      @overview-click="onOverviewClick"
    />
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import { mapActions, mapGetters, mapState } from 'vuex';
import SearchInput from 'components/universal-search/SearchInput.vue';
import SearchResultsList from 'components/universal-search/SearchResultsList.vue';
import navigateToUrl from 'src/mixins/navigateToUrl';
import Bounds from 'src/models/Bounds';
import Vehicle from 'src/models/Vehicle';
import { SEARCH_TYPES } from 'src/services/constants';
import MapService from 'src/services/map';
import { getEquivalentRoute } from 'src/services/router';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  components: {
    SearchResultsList,
    SearchInput,
  },
  mixins: [navigateToUrl],
  props: {
    autofocus: Boolean,
  },
  computed: {
    ...mapState('app', ['isFilterRoute', 'isLiveMapRoute', 'isOnDetailsPage']),
    ...mapState('assets', ['assets']),
    ...mapState('map', ['showPlaces']),
    ...mapState('places', ['places']),
    ...mapState('search', ['filterTerms', 'history', 'searchedTerms', 'searchType', 'searchItems']),
    ...mapState('users', ['users']),
    ...mapState('vehicles', ['vehicles']),
    ...mapGetters('assets', ['vehiclesByDriver']),
    ...mapGetters('search', ['allOptionsIndexed', 'selectedResult']),
    onLiveMap() {
      return ['map', 'map-selected'].includes(this.$route.name);
    },
    isFilter() {
      return this.searchType === SEARCH_TYPES.FILTER;
    },
    vehicle() {
      return this.vehicles[this.$route.params.key] || new Vehicle();
    },
  },
  data() {
    return {
      isInFocus: false,
      previousTerm: null,
    };
  },
  methods: {
    ...mapActions('filtering', ['filterByTerm']),
    ...mapActions('map', ['closeListDialog', 'fitToBounds', 'focusOnCoords', 'togglePlaces']),
    ...mapActions('map', {
      setMapSearchTerms: 'setSearchTerms',
    }),
    ...mapActions('search', [
      'mapPlacesSearch',
      'prepareMapForSearch',
      'resetResultSelection',
      'saveHistory',
      'selectNextResult',
      'selectPreviousResult',
      'setIsSearching',
      'setSearchItems',
      'setSearchType',
      'updateFilterTerms',
      'updateSearchTerms',
    ]),
    ...mapActions('search', {
      setKeywordSearchTerms: 'setSearchedTerms',
    }),
    ...mapActions('assets', ['selectAsset']),
    /**
     * @param {"live-map" | "live-map-feature" | "overview" | "place" | "user" | "asset" | "vehicle" | "address"} type
     * @param {SearchItem} result
     */
    async executeAction(type, result) {
      if (!type) {
        return;
      }

      const { liveMapKey } = result;

      switch (type) {
        case 'live-map':
          if (liveMapKey) {
            this.navigateToUrl(`/selected/${liveMapKey}`);
          } else if (!this.onLiveMap) {
            // Navigate to Live Map
            this.navigateToUrl('/');
          }
          break;
        case 'live-map-feature':
          this.showOnLiveMap(result);
          return; // do not continue
        case 'overview':
          this.navigateToUrl({ name: result.type, params: { key: result.key } });
          break;
        case 'place':
          this.navigateToUrl(`/places/${result.key}`);
          break;
        case 'user':
          this.navigateToUrl(`/users/${result.key}`);
          break;
        case 'asset':
        case 'vehicle':
          if (this.isOnDetailsPage) {
            const routeName = getEquivalentRoute(this.$route.name, result.type, result.tripsRoute);
            this.navigateToUrl({ name: routeName, params: { key: result.key } });
          } else {
            this.navigateToUrl({ name: result.tripsRoute, params: { key: result.key } });
          }
          break;
        case 'address':
          // First, clear the terms and wait to re-trigger the search
          this.navigateToUrl('/');
          this.setMapSearchTerms('');
          await setTimeoutPromise(500);
          this.setMapSearchTerms(this.searchedTerms);
          this.setKeywordSearchTerms('');
          return; // do not continue
        default:
      }

      // Save result to history (as long as it's not the address one)
      await this.setSearchType(SEARCH_TYPES.KEYWORD);
      this.saveHistory(result);
      this.$refs.inputBlur.focus();
    },
    handleClickedResult(result) {
      this.executeAction(result.type, result);
    },
    async onBlur() {
      await setTimeoutPromise(200);
      this.isInFocus = false;
      this.resetResultSelection();
    },
    onClear() {
      this.performSearch('');
    },
    async onFocus(terms) {
      this.previousTerm = '';
      this.performSearch(terms);
      await setTimeoutPromise(200);
      this.isInFocus = true;
    },
    onInputKeyDown(event) {
      const result = this.selectedResult || this.allOptionsIndexed[0];

      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          this.selectPreviousResult();
          break;
        case 'ArrowDown':
          event.preventDefault();
          this.selectNextResult();
          break;
        case 'Enter':
          if (this.isFilter) {
            return;
          }
          if (result) {
            event.preventDefault();
            if (this.isLiveMapRoute && result.isMappable) {
              this.onLiveMapClick(result);
              return;
            }
            this.handleClickedResult(result);
          }
          break;
        default:
      }
    },
    onLiveMapClick(result) {
      this.executeAction('live-map-feature', result);
    },
    onOverviewClick(result) {
      this.executeAction('overview', result);
    },
    /** @param {string} rawTerms */
    async performSearch(rawTerms) {
      const terms = rawTerms.toLowerCase();

      if (this.isFilterRoute && this.isFilter) {
        this.updateFilterTerms(terms);
        return;
      }

      if (terms === this.previousTerm) {
        return; // do nothing if the terms haven't changed
      }

      this.previousTerm = terms;
      this.setKeywordSearchTerms(terms);

      if (terms.length >= 2) {
        this.setIsSearching(true);
        this.debouncedSearch(terms);
        return;
      }

      await this.setSearchItems([]);
    },
    /**
     * @param {SearchItem} result
     */
    showOnLiveMap(result) {
      const { bounds, hasLocation, type } = result;

      if (!hasLocation) {
        this.$q.notify({
          color: 'electric-yellow-light',
          textColor: 'brown-10',
          icon: 'location_off',
          message: 'Cannot select this vehicle because it does not have a location',
        });
        return;
      }

      const focusIn = () => {
        if (bounds) {
          // TODO: determine if we should notify the user that these prefs are changing
          if (type === 'place') {
            // Turn on "Show Places" if not showing
            if (!this.showPlaces) {
              this.togglePlaces();
            }
          }
          this.fitToBounds(new Bounds(bounds));
        }
      };

      if (this.onLiveMap) {
        focusIn();
      } else {
        MapService.onReady(() => {
          MapService.once('focus', () => {
            focusIn();
          });
        });
      }

      this.executeAction('live-map', result);
    },
  },
  watch: {
    searchType() {
      // Get current term
      const terms = this.previousTerm || this.filterTerms || '';
      this.previousTerm = null;

      if (!this.isFilter) {
        // Undo any filtering
        this.updateFilterTerms('');
      }

      this.performSearch(terms);
    },
  },
  created() {
    this.debouncedSearch = _debounce(async (terms) => {
      await this.updateSearchTerms(terms);
      this.setIsSearching(false);
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.universal-search {
  max-width: 600px;
}
</style>
