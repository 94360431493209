import _isNil from 'lodash/isNil';

export default class GeoJsonFeature {
  type = 'Feature';

  geometry = {
    type: 'Point',
    coordinates: [null, null],
  };

  properties = {};

  /**
   * Constructor for the GeoJsonFeature class.
   *
   * @param {Number[]} coordinates
   * @param {Object} properties
   */
  constructor(coordinates, properties = {}) {
    this.coordinates = coordinates;

    Object.keys(properties).forEach((key) => {
      const property = properties[key];
      this.properties[key] = property;
    });
  }

  /**
   * @returns {Array<Number>}
   */
  get coordinates() {
    return this.geometry.coordinates || [];
  }

  /**
   * Sets the features coordinates.
   *
   * @param {Array<Number>} coordinates
   */
  set coordinates(coordinates) {
    this.geometry.coordinates = coordinates;
  }

  /**
   * Determines if this feature has changed position compared to another given feature.
   * - will only return true if both features have coordinates
   *
   * @param {GeoJsonFeature} currentState Current state of the feature used for a location comparison.
   * @param {GeoJsonFeature} previousState Previous state of the same feature.
   */
  static hasMovedFrom(currentState = {}, previousState = {}) {
    const { coordinates: currentCoords } = currentState?.geometry || {};
    const { coordinates: previousCoords } = previousState?.geometry || {};

    // Return false if feature being compared is not the same feature
    if (currentState.properties.key !== previousState?.properties?.key && currentCoords) {
      return false;
    }

    const [existingLong, existingLat] = currentCoords || [];
    const [updatedLong, updatedLat] = previousCoords || [];
    const hasMoved = existingLat !== updatedLat || existingLong !== updatedLong;

    // Return false if anything is missing
    if (_isNil(existingLat) || _isNil(existingLong) || _isNil(updatedLat) || _isNil(updatedLong)) {
      return false;
    }

    return hasMoved;
  }
}
