import SimpleModel from './SimpleModel';

class Driver extends SimpleModel {
  checkInExpiration = null;

  firstName = null;

  key = null;

  lastName = null;

  fullName = null;

  constructor(data = {}) {
    super();
    this.insert(data);
  }
}

export default Driver;
