import pinMovingNoHeadingSvg from 'src/assets/pin-moving-no-heading.svg?raw';
import { COLOR_VALUES } from 'src/services/constants';
import SvgMarker from './SvgMarker';

export default class MovingNoHeadingSvgMarker extends SvgMarker {
  constructor() {
    super(pinMovingNoHeadingSvg, {
      height: 60,
      width: 60,
      innerColor: COLOR_VALUES.DARK,
      outerColor: COLOR_VALUES.POSITIVE,
    });
  }
}
