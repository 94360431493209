/**
 * @param {EnvStoreState} state
 * @param {{ [key: string]: unknown }} envConfig
 */
export function set(state, envConfig) {
  Object.assign(state, envConfig);
}

/**
 * @param {EnvStoreState} state
 * @param {boolean} canUse
 */
export function setCanUseCamera(state, canUse) {
  state.canUseCamera = canUse;
}
