<template>
  <MapboxPopup
    :anchor="anchor"
    :close-button="false"
    :close-on-click="closeOnClick"
    :lng-lat="coordinates"
    @mb-close="onClose"
    @mb-open="onOpen"
  >
    <div class="popup-bg">
      <div class="contents">
        <!-- Not using MglPopup button - it was buggy -->
        <button
          aria-label="Close popup"
          class="popup-close-button q-pb-sm text-dark"
          data-t="closeButton"
          type="button"
          @click="onClose($event, true)"
        >
          ×
        </button>

        <slot />

        <div v-if="customTip" class="popup-tip-container absolute flex justify-center">
          <VehiclePopupTip class="popup-tip relative" :style="{ bottom: '10px', left: `-80px` }" />
        </div>
      </div>
    </div>
  </MapboxPopup>
</template>

<script>
import { MapboxPopup } from '@studiometa/vue-mapbox-gl';
import _isEqual from 'lodash/isEqual';
import raf from 'raf';
import { inject } from 'vue';
import VehiclePopupTip from 'components/map/VehiclePopupTip.vue';

export default {
  components: {
    MapboxPopup,
    VehiclePopupTip,
  },
  props: {
    anchor: {
      type: String,
      default: null,
    },
    anchorOffsets: {
      type: Object,
      default() {
        return {};
      },
    },
    closeOnClick: {
      type: Boolean,
      default: true,
    },
    coordinates: {
      type: Array,
      default() {
        return [0, 0];
      },
    },
    customTip: {
      type: Boolean,
      default: true,
    },
    shown: {
      type: Boolean,
      default: false,
    },
    // used mostly for debugging/testing
    useDebouncedAnchorUpdate: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      currentAnchor: null,
      currentCoords: [0, 0],
      currentZoom: 0,
      minMaxZoomDiff: 0,
      minZoom: 0,
      popup: null,
      popupElement: null,
      updateAnchor: () => {},
    };
  },
  methods: {
    onClose(_, buttonClicked) {
      const mapInstance = this.$noObserve.get('mapInstance');

      // If map instance is still around, remove the "move" event handler
      if (mapInstance) {
        mapInstance.off('move', this.updateAnchor);
      }

      if (!this.customTip) {
        document.body.classList.remove('no-custom-popup-tip');
      }

      this.$emit('close', buttonClicked);
    },
    onOpen() {
      const map = inject('map');
      this.$noObserve.set('mapInstance', map); // store map in memory, but don't let Vue see it (destroys the map style)
      this.popupElement = document.querySelector('.mapboxgl-popup');

      if (!this.customTip) {
        document.body.classList.add('no-custom-popup-tip');
      }

      if (map) {
        this.minMaxZoomDiff = map.getMaxZoom() - map.getMinZoom();
        this.minZoom = map.getMinZoom();
        map.on('move', this.updateAnchor);
      }

      this.updateAnchor();
    },
  },
  created() {
    const component = this;

    const updateAnchor = () => {
      if (!component.$q.screen.gt.sm || !this.customTip) {
        // Do not adjust position of popup on smaller devices or non-custom tip popups
        // - anchor position won't change
        return;
      }

      const {
        anchorOffsets: allAnchorOffsets,
        coordinates,
        currentAnchor,
        currentCoords,
        currentZoom,
        popupElement,
      } = component;
      const mapInstance = this.$noObserve.get('mapInstance');

      if (popupElement && mapInstance) {
        const anchorClass = [...popupElement.classList].find((name) => name.includes('mapboxgl-popup-anchor-'));
        const anchor = anchorClass.replace('mapboxgl-popup-anchor-', '');
        const zoom = mapInstance.getZoom();

        if (
          (anchor !== currentAnchor || zoom !== currentZoom || !_isEqual(coordinates, currentCoords)) &&
          mapInstance
        ) {
          component.currentAnchor = anchor;
          component.currentZoom = zoom;
          component.currentCoords = coordinates;
          mapInstance.fire('moveend');
          const anchorOffsets = allAnchorOffsets[anchor] || {};
          let offsetLeft = anchorOffsets.left || 0;
          let offsetTop = anchorOffsets.top || 0;
          const { maxLeft, minLeft, maxTop, minTop } = anchorOffsets;

          // Adjust offset based on zoom level
          if ((minTop !== undefined && maxTop !== undefined) || (minLeft !== undefined && maxLeft !== undefined)) {
            const zoomDiff = zoom - this.minZoom;
            const zoomRatio = zoomDiff / this.minMaxZoomDiff;

            if (minTop) {
              const offsetTopAdjustment = zoomRatio * (maxTop - minTop);
              offsetTop += minTop + offsetTopAdjustment;
            }

            if (minLeft) {
              const offsetLeftAdjustment = zoomRatio * (maxLeft - minLeft);
              offsetLeft += minLeft + offsetLeftAdjustment;
            }
          }

          popupElement.style.left = offsetLeft ? `${offsetLeft}px` : 0;
          popupElement.style.top = offsetTop ? `${offsetTop}px` : 0;
        }
      }
    };

    this.updateAnchor = this.useDebouncedAnchorUpdate ? () => raf(updateAnchor) : updateAnchor;
  },
  unmounted() {
    this.$noObserve.remove('mapInstance'); // cleanup, just in case
  },
  watch: {
    coordinates() {
      this.updateAnchor();
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-close-button {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  border-radius: 0 3px 0 0;
  height: 24px;
  background-color: transparent;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.contents {
  min-width: 175px;
  line-height: 1;
}

.popup-tip-container {
  display: none;
}
</style>

<style lang="scss">
.mapboxgl-popup-close-button {
  padding: 0 8px 4px;
  color: $grey-7;
}

.mapboxgl-popup {
  transition: top 0.1s ease-in;

  .mapboxgl-popup-content {
    padding: 8px;
  }

  .popup-tip {
    left: 1px;
    bottom: 19px;
    width: 24px;
  }
}

/**
 * Custom styled "bottom" anchor popup.
 */
.vehicles-map .mapboxgl-popup-anchor-bottom {
  left: 0;

  .mapboxgl-popup-content {
    padding: 0;
    background: none;
    box-shadow: none;
  }

  .popup-bg {
    position: relative;
    left: 80px;
    padding: 8px;
    border-radius: 4px;
    background: white;
    box-shadow:
      0 1px 5px rgba(0, 0, 0, 0.2),
      0 2px 2px rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12);
  }

  .popup-tip-container {
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
  }

  .mapboxgl-popup-tip {
    visibility: hidden;
  }
}

.no-custom-popup-tip .mapboxgl-popup-tip {
  top: -1px;
  position: relative;
}
</style>
