import { route } from 'quasar/wrappers';
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import routes from './routes';

const createHistory = process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory;

const Router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      const position = savedPosition || {};
      if (!savedPosition) {
        if (to.matched.some((m) => m.meta.scrollToTop)) {
          position.x = 0;
          position.y = 0;
        }
      }
      resolve(position);
      // TODO: figure out how this worked before
      // Router.app.$root.$once('triggerScroll', () => {
      //   Router.app.$nextTick(() => resolve(position));
      // });
    });
  },
  routes,

  // Leave this as is and make changes in quasar.config.cjs instead!
  // quasar.config.cjs -> build -> vueRouterMode
  // quasar.config.cjs -> build -> publicPath
  history: createHistory(process.env.VUE_ROUTER_BASE),
});

export default route(() => Router);
