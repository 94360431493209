<template>
  <Popup v-if="shown" :coordinates="[longitude, latitude]" @close="onClose">
    <div class="contents">
      <GeocodedAddressFormatted
        class="address"
        data-t="address"
        :latitude="latitude"
        :longitude="longitude"
        @load="onAddressLoad"
      />
      <div v-if="isLoaded" class="buttons-group q-mt-sm column">
        <div v-if="placesAtPoint.length" class="places q-mx-xs q-mb-sm q-py-xs">
          <div
            v-for="placeAtPoint in placesAtPoint"
            :key="placeAtPoint.properties.key"
            class="place row justify-between items-center q-pr-xs q-pl-sm"
          >
            <div class="col row items-center no-wrap">
              <q-icon class="q-mr-xs text-grey-7" name="place" />
              <div class="place__name col q-pr-sm text-grey-7">
                {{ placeAtPoint.properties.name }}
              </div>
            </div>
            <div>
              <PrimaryInlineButton
                data-t="editPlaceButton"
                flat
                label="Edit"
                padding="sm"
                size="11px"
                @click="editPlace(placeAtPoint)"
              />
              <PrimaryInlineButton
                data-t="placeVisitsButton"
                flat
                label="Visits"
                padding="sm"
                size="11px"
                @click="openVisits(placeAtPoint)"
              />
              <PrimaryInlineButton
                :color="hiddenPlacesByKey[placeAtPoint.properties.key] ? 'grey-4' : 'white'"
                data-t="placeVisitsButton"
                flat
                :icon="hiddenPlacesByKey[placeAtPoint.properties.key] ? 'visibility_off' : 'visibility'"
                padding="sm"
                size="11px"
                :text-color="hiddenPlacesByKey[placeAtPoint.properties.key] ? 'dark' : 'primary'"
                @click="showHidePlace(placeAtPoint)"
              >
                <q-tooltip>
                  <div class="text-body2">
                    {{ hiddenPlacesByKey[placeAtPoint.properties.key] ? 'Show' : 'Hide' }}
                  </div>
                </q-tooltip>
              </PrimaryInlineButton>
            </div>
          </div>
        </div>
        <q-btn
          v-if="canCreatePlaces"
          align="left"
          class="popup min-height-none q-mr-xs"
          color="white"
          data-t="saveAsPlaceButton"
          flat
          label="Save As New Place"
          size="11px"
          square
          text-color="primary"
          @click="addPlace"
        />
        <q-btn
          v-if="mappableVehicles.length && showFindNearby"
          align="left"
          class="popup min-height-none q-mr-xs"
          color="white"
          flat
          label="Find Nearby Vehicles"
          size="11px"
          square
          text-color="primary"
          :to="{ name: 'nearby', query: { location: `${longitude},${latitude}` } }"
        />
        <q-btn
          v-if="showOpenInMaps"
          align="left"
          class="popup min-height-none"
          color="white"
          data-t="openInGoogleMaps"
          flat
          icon-right="arrow_forward"
          label="Open in Google Maps"
          size="11px"
          square
          text-color="primary"
          @click="openURL(`https://www.google.com/maps/search/?api=1&amp;query=${latitude},${longitude}`)"
        />
      </div>
    </div>
    <EditPlace
      v-if="showEditPlace"
      data-t="addPlaceModal"
      :place="place"
      :show-search="false"
      @edit-place-closed="
        showEditPlace = false;
        setContextualMenuOpen(false);
      "
    />
    <PlaceVisitsDialog
      v-if="showVisits"
      :place="place"
      @hide="showVisits = false"
      @vehicle-select="
        showVisits = false;
        $emit('place-visit-vehicle-select', $event);
      "
    />
  </Popup>
</template>

<script>
import _get from 'lodash/get';
import { openURL } from 'quasar';
import { mapActions, mapGetters, mapState } from 'vuex';
import PrimaryInlineButton from 'components/buttons/PrimaryInlineButton.vue';
import GeocodedAddressFormatted from 'components/GeocodedAddressFormatted.vue';
import Popup from 'components/map/Popup.vue';
import EditPlace from 'components/places/EditPlace.vue';
import PlaceVisitsDialog from 'components/places/PlaceVisitsDialog.vue';
import Place from 'src/models/Place';
import PlaceFencesLayer from 'src/services/geoJson/layers/PlaceFencesLayer';
import PlacePointsLayer from 'src/services/geoJson/layers/PlacePointsLayer';

export default {
  props: {
    showFindNearby: {
      type: Boolean,
      default: true,
    },
    showOpenInMaps: {
      type: Boolean,
      default: true,
    },
  },
  inject: ['map'],
  components: {
    EditPlace,
    GeocodedAddressFormatted,
    PlaceVisitsDialog,
    Popup,
    PrimaryInlineButton,
  },
  emits: ['open', 'place-visit-vehicle-select'],
  computed: {
    ...mapState('map', {
      shown: 'contextualMenuOpen',
    }),
    ...mapState('session', ['permissions']),
    ...mapGetters('assets', ['mappableVehicles']),
    ...mapGetters('places', ['places', 'hiddenPlacesByKey']),
    canCreatePlaces() {
      return this.permissions.permissions.livemapPlaceCreate;
    },
    placesAtPoint() {
      const uniqueKeys = [];
      return (
        this.editPlaceFeatures
          // Only include unique Places
          .filter(({ properties }) => {
            const { key } = properties;
            if (!uniqueKeys.includes(key)) {
              uniqueKeys.push(key);
              return true;
            }
            return false;
          })
      );
    },
  },
  data() {
    return {
      address: '',
      contextMenuHandler: null,
      editPlaceFeatures: [],
      isLoaded: false,
      latitude: null,
      longitude: null,
      place: null,
      placeContextMenuHandler: null,
      showEditPlace: false,
      showNearbyVehicles: false,
      showVisits: false,
    };
  },
  methods: {
    ...mapActions('map', ['addPlace', 'setContextualMenuOpen']),
    ...mapActions('places', ['setPlaceVisibility']),
    openURL,
    addPlace() {
      this.place = Place.getNewPlaceDefaults();
      this.place.longitude = this.longitude;
      this.place.latitude = this.latitude;
      this.place.address = this.address;
      this.place.category = 'other';

      this.showEditPlace = true;
    },
    /**
     * Opens the Edit Place dialog for the given place.
     *
     * @param {Place} place
     */
    editPlace(place) {
      this.selectPlace(place);
      this.showEditPlace = true;
    },
    onAddressLoad(address) {
      this.isLoaded = true;
      this.address = address.toString();
    },
    onClose() {
      this.setContextualMenuOpen(false);
    },
    onContextMenu({ lng, lat }) {
      this.setEditPlaceFeatures([]);
      // Load Place layers
      this.longitude = lng;
      this.latitude = lat;
      this.setContextualMenuOpen(true);
      this.$emit('open');
    },
    selectPlace(place) {
      const placeKey = _get(place, 'properties.key');
      this.place = this.places.find(({ key }) => key === placeKey);
    },
    openVisits(place) {
      this.selectPlace(place);
      this.showVisits = true;
    },
    setEditPlaceFeatures(features, placeKey) {
      if (placeKey) {
        this.editPlaceFeatures = [features.find(({ properties }) => properties.key === placeKey)];
        return;
      }
      this.editPlaceFeatures = features;
    },
    showHidePlace(option) {
      const place = this.places.find(({ key }) => key === option.properties.key);
      this.setPlaceVisibility({ [place.key]: !place.isVisible });
    },
  },
  beforeUnmount() {
    if (this.map) {
      // Remove context menu event handlers
      this.map.off('contextmenu', this.contextMenuHandler);
      this.map.off('contextmenu', PlaceFencesLayer.id, this.placeContextMenuHandler);
      this.map.off('contextmenu', PlacePointsLayer.id, this.placeContextMenuHandler);
    }
  },
  created() {
    // Store handlers so they can be removed later
    this.contextMenuHandler = ({ lngLat }) => this.onContextMenu(lngLat);
    this.placeContextMenuHandler = ({ features = [], placeKey }) => {
      this.setEditPlaceFeatures(features, placeKey);
    };

    this.map.on('contextmenu', this.contextMenuHandler);
    this.map.on('contextmenu', PlaceFencesLayer.id, this.placeContextMenuHandler);
    this.map.on('contextmenu', PlacePointsLayer.id, this.placeContextMenuHandler);
  },
  watch: {
    latitude() {
      this.isLoaded = false;
    },
    longitude() {
      this.isLoaded = false;
    },
    $route() {
      if (this.$route.name === 'nearby') {
        this.showNearbyVehicles = true;
      } else {
        this.showNearbyVehicles = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.address {
  font-size: 14px;
}

.popup-button {
  font-family: 'Roboto', sans-serif;
  line-height: 1;
}

.buttons-group {
  margin-left: -5px;
}

.places {
  border: 1px solid $grey-4;
}

.place:first-child,
.place:not(:first-child):not(:last-child) {
  padding-bottom: map-get($space-xs, 'y');
}

.place:not(:first-child) {
  border-top: 1px solid $grey-3;
}

.place:last-child,
.place:not(:first-child):not(:last-child) {
  padding-top: map-get($space-xs, 'y');
}

.place__name {
  max-width: 150px;
  white-space: normal;
  line-height: 1.2;
}

:deep(.q-btn__wrapper) {
  min-height: 0;
  padding: 2px 7px;
}

:deep(.geocodedStreet) {
  margin-right: 15px;
}
</style>
