import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import {
  getTripEndCabinImage,
  getTripEndRoadImage,
  getTripStartCabinImage,
  getTripStartRoadImage,
  shouldUseTripEndPoint,
  shouldUseTripStartPoint,
} from 'src/services/trips.js';

export function tripsGroupedByDate(state) {
  return _groupBy(state.trips, 'dateGroup');
}

export function selectedTrip(state) {
  return state.trips[state.selectedTripKey] || {};
}

export function lastTripCabinImageUrl(state) {
  const key = getImageKey(state.selectedVehicleTrip);
  return key ? `${process.env.API_BASE_URL}/api/v1/public/media/${key}/fetch` : '';
}

export function lastTripRoadImageUrl(state) {
  const key = getImageKey(state.selectedVehicleTrip, 'road');
  return key ? `${process.env.API_BASE_URL}/api/v1/public/media/${key}/fetch` : '';
}

export function viewedVehicleLastTripCabinImageUrl(state) {
  const key = getImageKey(state.viewedVehicleTrip);
  return key ? `${process.env.API_BASE_URL}/api/v1/public/media/${key}/fetch` : '';
}

export function viewedVehicleLastTripRoadImageUrl(state) {
  const key = getImageKey(state.viewedVehicleTrip, 'road');
  return key ? `${process.env.API_BASE_URL}/api/v1/public/media/${key}/fetch` : '';
}

function getImageKey(trip, camera = 'cabin') {
  const { media = [] } = trip;
  let fn = () => ({});
  if (shouldUseTripEndPoint(trip)) {
    fn = camera === 'cabin' ? getTripEndCabinImage : getTripEndRoadImage;
  } else if (shouldUseTripStartPoint(trip)) {
    fn = camera === 'cabin' ? getTripStartCabinImage : getTripStartRoadImage;
  }
  const match = fn(media);
  let key = '';
  if (match) {
    key = _get(match, 'key', '');
  }
  return key;
}
