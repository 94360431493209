import _camelCase from 'lodash/camelCase';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Account from 'src/models/Account';
import Coupon from 'src/models/Coupon';
import CurrentUser from 'src/models/CurrentUser';
import { DASHCAM_MEDIA_ACCESS_MODES, FUEL_TYPES_STANDARD, FUEL_TYPES_O2 } from 'src/services/constants';

export function isAuthenticated(state) {
  return _isEmpty(state.profile) === false;
}

export function hasPermission(state) {
  return (permission, defaultValue = false) => {
    if (permission.includes('_')) {
      permission = _camelCase(permission);
    }
    return _get(state, ['permissions', 'permissions', permission], defaultValue);
  };
}

export function isNotDeniedPermission(state) {
  return (permission) => hasPermission(state)(permission, true);
}

export function hasSelectedAccount(state) {
  return _isEmpty(state.account) === false;
}

export function account(state) {
  return new Account(state.account);
}

/**
 * @param {SessionStoreState} state
 */
export function accountIsDunning(state) {
  return state.account?.subscription?.status === 'dunning';
}

export function accounts(state) {
  return Object.values(state.accounts).map((acc) => new Account(acc));
}

/**
 * Returns true if user is allowed to delete assets.
 *
 * @param {import('src/types/session-store').SessionStoreState} _
 * @param {import('src/types/session-store').SessionStoreGetters} getters
 * @returns {Boolean}
 */
export function allowDeleteAsset(_, getters) {
  return getters.hasPermission('assetEdit');
}

/**
 * Returns true if user is allowed to delete vehicles.
 *
 * @param {import('src/types/session-store').SessionStoreState} _
 * @param {import('src/types/session-store').SessionStoreGetters} getters
 * @returns {Boolean}
 */
export function allowDeleteVehicle(_, getters) {
  return getters.hasPermission('vehicleDelete');
}

/**
 * Returns true if user is allowed to edit assets.
 *
 * @param {import('src/types/session-store').SessionStoreState} _
 * @param {import('src/types/session-store').SessionStoreGetters} getters
 * @returns {Boolean}
 */
export function allowEditAsset(_, getters) {
  return getters.hasPermission('assetEdit');
}

/**
 * Returns true if user is allowed to edit vehicles.
 *
 * @param {import('src/types/session-store').SessionStoreState} _
 * @param {import('src/types/session-store').SessionStoreGetters} getters
 * @returns {Boolean}
 */
export function allowEditVehicle(_, getters) {
  return getters.hasPermission('vehicleEdit');
}

/**
 * Returns the coupon as a Coupon object.
 *
 * @param {import('src/types/session-store').SessionStoreState} state
 * @returns {Coupon}
 */
export function coupon(state) {
  return new Coupon(state.coupon || {});
}

export function currentUser(state) {
  return new CurrentUser(state.currentUser);
}

/**
 * Returns true if user is the account admin.
 *
 * @param {import('src/types/session-store').SessionStoreState} state
 * @param {import('src/types/session-store').SessionStoreGetters} getters
 * @returns {Boolean}
 */
export function isAccountAdmin(state, getters) {
  return getters.currentUser.isAdmin() && getters.currentUser.groups.length === 0;
}

export function isRental(state) {
  return state.partner.appName === 'Zubie Rental Connect';
}

export function isFleet(state) {
  return state.partner.appName === 'Zubie Fleet Connect';
}

export function isConsumer(state) {
  return state.partner.appName === 'Zubie';
}

export function mayAccessDashcamMedia(state, getters) {
  const mode = state.account?.preferences?.dashcamMediaAccessMode || DASHCAM_MEDIA_ACCESS_MODES.DEFAULT;
  if (mode === DASHCAM_MEDIA_ACCESS_MODES.DISABLED) {
    return false;
  }
  switch (mode) {
    case DASHCAM_MEDIA_ACCESS_MODES.DISABLED:
      return false;
    case DASHCAM_MEDIA_ACCESS_MODES.ADMIN:
      return getters.currentUser.isAdmin();
    case DASHCAM_MEDIA_ACCESS_MODES.ACCOUNT_ADMIN:
      return getters.isAccountAdmin;
    default:
      return true;
  }
}

/**
 * Returns the user that has the account owner's key.
 *
 * @param {import('src/types/session-store').SessionStoreState} state
 * @param {import('src/types/session-store').SessionStoreGetters} getters
 * @param {Object} rootState
 * @returns {Object|null}
 */
export function accountOwner(state, getters, rootState) {
  const owner = _get(rootState, ['users', 'users', state.account.ownerKey]);
  return owner || null;
}

/**
 * Returns the user that has the billing user key.
 *
 * @param {import('src/types/session-store').SessionStoreState} state
 * @param {import('src/types/session-store').SessionStoreGetters} getters
 * @param {import('src/types/store').RootStoreState} rootState
 */
export function primaryBillingContact(state, _, rootState) {
  return rootState.users?.users?.[state.account.billingUserKey] || null;
}

/**
 * Returns available fuel types by partner.
 *
 * @param {import('src/types/session-store').SessionStoreState} state
 * @returns {String[]}
 */
export function fuelTypes(state) {
  let types;

  if (state.partner.name.toLowerCase() === 'o2') {
    types = FUEL_TYPES_O2;
  } else {
    types = FUEL_TYPES_STANDARD;
  }

  return types;
}

/**
 * Returns where all the info to render the app UI has been loaded
 *
 * @param {import('src/types/session-store').SessionStoreState} state
 * @returns {Boolean}
 */
export function isCriticalDataLoaded(state) {
  const hasAccount = !_isEmpty(state.account);
  const hasCurrentUser = !_isEmpty(state.currentUser);
  const hasPartner = !_isEmpty(state.partner);
  const hasPermissions = !_isEmpty(state.permissions);
  const hasProfile = !_isEmpty(state.profile);
  return hasAccount && hasCurrentUser && hasPartner && hasPermissions && hasProfile;
}

/**
 * @param {import('src/types/session-store').SessionStoreState} state
 */
export function partnerName(state) {
  const partnerName = state.partner?.appName;
  return partnerName || 'Zubie';
}
