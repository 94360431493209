<template>
  <q-menu
    ref="menu"
    :auto-close="false"
    content-class="search-results-menu"
    fit
    :model-value="show"
    no-focus
    no-parent-event
    no-refocus
    :offset="[xOffset, 0]"
    persistent
    @before-hide="$emit('before-hide')"
    @keydown="
      $emit('keydown', $event);
      $event.preventDefault();
    "
  >
    <q-list
      ref="list"
      class="search-results-list"
      :style="{ minWidth: `${minWidth}px`, maxHeight: '250px' }"
      @click.stop
    >
      <q-item v-if="noResults" class="no-results q-pb-md text-dark bg-white shadow-4">
        <div class="column full-width">
          <div v-if="searchedTerms.length > 1">
            <div class="q-pt-sm q-pb-md">No results include that text.</div>
            <SearchResult
              v-for="(option, index) in additionalOptions"
              :key="option.key"
              :class="{ 'action-additional full-width': true, 'search-result--first': index === 0 }"
              :data-id="`additional-option-${index}`"
              nav-arrow
              :result="option"
              @click="
                option.onClick($event);
                $emit('click', $event);
              "
            />
          </div>
          <div v-else class="q-pt-sm">Type more to begin search</div>
        </div>
      </q-item>
      <q-item v-if="isSearching" class="loading-result items-center q-mb-xs text-dark bg-white shadow-4">
        <q-spinner class="q-mr-sm" /> Loading...
      </q-item>
      <SearchResultHeading
        v-if="currentlyViewing.length"
        bg-color="secondary"
        icon="visibility"
        :label="isLiveMapRoute ? 'Selected' : 'Viewing'"
        text-color="dark"
      />
      <SearchResult
        v-for="(option, index) in currentlyViewing"
        :key="option.key"
        class="search-result--current q-px-md"
        :result="option"
        @click="$emit('click', $event)"
        @details-click="$emit('details-click', $event)"
        @hover="$emit('hover', $event, index)"
        @live-map-click="$emit('live-map-click', $event)"
        @overview-click="$emit('overview-click', $event)"
      />
      <SearchResultHeading v-if="recentOptionsWithoutCurrent.length" icon="history" label="Recently Viewed" />
      <SearchResult
        v-for="(option, index) in recentOptionsWithoutCurrent"
        :key="option.key"
        class="q-pa-none"
        :class="{ 'search-result--first': index === 0 }"
        :data-id="`result-${index}`"
        :result="option"
        @click="$emit('click', $event)"
        @details-click="$emit('details-click', $event)"
        @hover="$emit('hover', $event, index)"
        @live-map-click="$emit('live-map-click', $event)"
        @overview-click="$emit('overview-click', $event)"
      />
      <SearchResultHeading
        v-if="uniqueOptions.length && (recentOptionsWithoutCurrent.length || currentlyViewing.length)"
        icon="search"
        label="More Results"
      />
      <SearchResult
        v-for="(option, index) in uniqueOptions"
        :key="option.key"
        :class="{ 'search-result--first': index === 0 }"
        :data-id="`result-${index}`"
        :result="option"
        @click="$emit('click', $event)"
        @details-click="$emit('details-click', $event)"
        @hover="$emit('hover', $event, index)"
        @live-map-click="$emit('live-map-click', $event)"
        @overview-click="$emit('overview-click', $event)"
      />
    </q-list>
  </q-menu>
</template>

<script>
import _debounce from 'lodash/debounce';
import { mapGetters, mapState } from 'vuex';
import SearchResult from 'components/universal-search/SearchResult.vue';
import SearchResultHeading from 'components/universal-search/SearchResultHeading.vue';

export default {
  name: 'SearchResultsList',
  props: {
    minWidth: {
      type: Number,
      default: 300,
    },
    show: Boolean,
  },
  components: {
    SearchResult,
    SearchResultHeading,
  },
  computed: {
    ...mapState('app', ['isFilterRoute', 'isLiveMapRoute']),
    ...mapState('search', ['activeIndex', 'isSearching', 'searchedTerms']),
    ...mapGetters('search', [
      'allOptionsIndexed',
      'additionalOptions',
      'currentlyViewing',
      'nonRecentOptions',
      'noResults',
      'options',
      'recentOptions',
      'recentOptionsWithoutCurrent',
      'uniqueOptions',
    ]),
    isOnlyKeywordSearch() {
      return !this.isFilterRoute;
    },
    xOffset() {
      const screenWidth = this.$q.screen.width;

      if (screenWidth > 799) {
        return this.isOnlyKeywordSearch ? -45 : -87;
      }

      if (screenWidth > 599) {
        return -6;
      }

      return 0;
    },
  },
  created() {
    this.debouncedUpdatePosition = _debounce(async () => {
      await this.$nextTick();
      this.$refs.menu?.updatePosition();
    }, 100);
  },
  watch: {
    async recentOptions() {
      this.debouncedUpdatePosition();
    },
    async show() {
      if (this.show) {
        this.debouncedUpdatePosition();
      }
    },
    async searchedTerms() {
      this.debouncedUpdatePosition();
    },
  },
};
</script>

<style lang="scss" scoped>
.no-results {
  border-bottom: 1px solid $silver;
}

.search-results-list {
  @media (min-width: 600px) {
    width: 95vw;
  }

  @media (min-width: $breakpoint-xs) {
    width: auto;
  }
}

.search-result--current {
  border: 4px solid $secondary;
  border-top: 0;
}

:deep(.result-heading) {
  z-index: 100;
}

:deep(.action-additional) {
  border-top: 1px solid $silver;
}
</style>

<style lang="scss">
.search-results-menu {
  background: none;

  @media (max-width: 419px) {
    left: 4px !important;
    min-width: 0 !important;
    max-width: calc(100vw - 8px) !important;
    width: calc(100vw - 8px) !important;
  }

  @media (min-width: 420px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  @media (min-width: 420px) and (max-width: 599px) {
    left: 24px !important;
    min-width: 0 !important;
  }
}
</style>
