import { toRaw } from 'vue';

/**
 * Retrieves tags from the API.
 *
 * @param {ActionContext} context
 * @returns {Object[]}
 */
export async function getTags(context) {
  if (context.state.tagsLoading === true) {
    return context.state.tags;
  }

  context.commit('setTagsLoading', true);

  const tags = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getTags',
    transformationFn: 'transformArray',
  });

  context.commit('setTagsLoading', false);

  if (tags.error) {
    return [];
  }

  context.commit('setTags', tags);

  return tags;
}

/**
 * Creates a new tag.
 *
 * @param {ActionContext} context
 * @returns {Object}
 */
export async function createNewTag(context) {
  context.commit('setSavingTag', true);

  const { color, type, value } = context.state.tagBeingEdited;

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'createNewTag',
    params: {
      value,
      type: type ? type.value : null,
      color,
    },
  });

  context.commit('setSavingTag', false);

  let error = response.error || response.errors;
  if (error) {
    const existError = response.metadata?.body?.errors?.[0]?.includes('already exists');
    if (existError) {
      error = `A tag with the name "${value}" already exists.`;
    }

    context.commit('setTagsError', error);
    return null;
  }

  context.commit('addTag', response);
  context.commit('clearTagBeingEdited');
  return response;
}

/**
 * Sets the tag currently being modified.
 *
 * @param {ActionContext} context
 * @param {Object} group
 */
export async function selectTagToEdit(context, tag) {
  context.commit('setTagBeingEdited', tag);
}

/**
 * Clears the tag currently being modified and clears tags errors.
 *
 * @param {ActionContext} context
 */
export async function resetEditingState(context) {
  context.commit('clearTagBeingEdited');
  context.commit('setTagsError', false);
}

/**
 * Updates the given tag with the edited tag properties.
 *
 * @param {ActionContext} context
 * @param {String} key
 * @returns {Object}
 */
export async function updateTag(context, key) {
  context.commit('setSavingTag', true);

  const existingTag = context.getters.allTags.find((tag) => tag.key === key);

  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'updateTag',
    params: {
      key,
      value: context.state.tagBeingEdited.value,
      type: context.state.tagBeingEdited.type,
      color: context.state.tagBeingEdited.color,
      existingValue: existingTag.value,
    },
  });

  context.commit('setSavingTag', false);

  if (response.errors || response.error) {
    context.commit('setTagsError', response.errors || response.error);
    return null;
  }

  context.commit('removeTag', response.key);
  context.commit('addTag', response);
  context.commit('clearTagBeingEdited');
  return response;
}

/**
 * Deletes the given tag.
 *
 * @param {ActionContext} context
 * @param {String} key
 * @returns {Object}
 */
export async function deleteTag(context, key) {
  context.commit('setSavingTag', true);

  const existingTag = context.getters.allTags.find((tag) => tag.key === key);

  const response = await context.rootState.app.apiWorker.deleteTag({
    key,
    value: existingTag.value,
  });

  context.commit('setSavingTag', false);

  if (response.errors || response.error) {
    context.commit('setTagsError', response.errors || response.error);
    return null;
  }

  context.commit('removeTag', key);
  return response;
}

/**
 * Bulk applies tag updates to the given entities.
 *
 * @param {ActionContext} context
 * @param {Object} payload
 * @returns {Object}
 */
export async function bulkApplyTags(context, payload) {
  return context.rootState.app.apiWorker.bulkApplyTags({
    tagKeys: toRaw(payload.tagKeys),
    entityKeys: payload.entityKeys,
    action: payload.action,
  });
}
