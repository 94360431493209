export const config = {
  cookieHandler: {
    clearSession() {
      console.warn('No cookie handler defined.');
    },
  },
};

/**
 * Sets the cookie handler for the cookies service.
 *
 * @param {Function} handler
 */
export function setCookieHandler(handler) {
  config.cookieHandler = handler;
}

/**
 * Clears the session cookies and returns true on success.
 *
 * @returns {Boolean}
 */
export function clearSession() {
  return config.cookieHandler.clearSession();
}
