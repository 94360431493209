import { userRoles } from 'src/services/constants';

/**
 * Returns all of the users with the accountRole of "admin".
 *
 * @param {import('src/types/users-store').UsersStoreState} state
 * @param {import('src/types/users-store').UsersStoreGetters} getters
 */
export function admins(_, getters) {
  return getters.usersAsArray.filter(({ accountRole }) => accountRole === userRoles.ADMIN);
}

/**
 * Returns all users as an Array.
 *
 * @param {import('src/types/users-store').UsersStoreState} state
 */
export function usersAsArray(state) {
  return Object.values(state.users);
}

/**
 * Returns all users with groups and tags populated.
 */
export function usersWithGroupsAndTags(_, getters, __, rootGetters) {
  return getters.usersAsArray.map((user) => ({
    ...user,
    groups: user.groupKeys
      .map((groupKey) => {
        const group = rootGetters['groups/getFlatGroups'][groupKey];
        if (!group) {
          return null;
        }
        return {
          key: groupKey,
          name: group?.name,
        };
      })
      .filter((group) => group),
    tags: user.tagKeys
      .map((tagKey) => {
        const tag = [...rootGetters['tags/genericTags'], ...rootGetters['tags/userTags']].find(
          ({ key }) => key === tagKey
        );
        if (!tag) {
          return null;
        }
        return {
          ...tag,
          key: tagKey,
        };
      })
      .filter((tag) => tag),
  }));
}
