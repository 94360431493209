<template>
  <q-expansion-item
    ref="expansionItem"
    :class="{ 'readonly-field': readonly }"
    :disable="readonly"
    expand-icon="arrow_drop_down"
    expand-separator
    :header-class="`q-pl-none ${!readonly ? 'bg-grey-3' : ''}`"
  >
    <template #header>
      <div class="q-item__section column q-item__section--main justify-center" style="padding: 3px 12px">
        <div class="q-item__label q-item__label--caption text-caption">License Plate</div>
        <div class="q-item__label">
          <div v-if="plateExpiration || plateJurisdiction || plateNumber">
            {{ plateNumber }}
            <span v-if="plateJurisdiction"> ({{ plateJurisdiction }})</span>
            <span v-if="plateExpiration"> — Expires {{ plateExpiration }}</span>
          </div>
          <div v-else>No license plate information provided.</div>
        </div>
      </div>
    </template>
    <q-card>
      <q-card-section>
        <q-input
          v-model="plateNumber"
          class="q-mb-sm"
          :error="Boolean(errors.plateNumber)"
          :error-message="cleanErrors.plateNumber"
          filled
          :hide-bottom-space="!Boolean(errors.plateNumber)"
          label="Plate Number"
          @update:model-value="onChange('plateNumber')"
        />
        <q-input
          v-model="plateJurisdiction"
          class="q-mb-sm"
          :error="Boolean(errors.plateJurisdiction)"
          :error-message="cleanErrors.plateJurisdiction"
          filled
          :hide-bottom-space="!Boolean(errors.plateNumber)"
          label="Plate Jurisdiction"
          @update:model-value="onChange('plateJurisdiction')"
        >
          <template #append>
            <q-icon class="cursor-pointer" name="manage_search">
              <q-popup-proxy
                ref="jurisdictionProxy"
                anchor="bottom right"
                content-style="min-width: 320px"
                self="top right"
                transition-hide="scale"
                transition-show="scale"
              >
                <PlateJurisdictionHelperField
                  :value="plateJurisdiction"
                  @update:model-value="
                    (event, value) => {
                      plateJurisdiction = value;
                      onChange('plateJurisdiction');
                      $refs.jurisdictionProxy.hide(event);
                    }
                  "
                />
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-input
          v-model="plateExpiration"
          :error="Boolean(errors.plateExpiration)"
          :error-message="cleanErrors.plateExpiration"
          filled
          hint="Enter the expiration date in YYYY-MM format."
          label="Plate Expiration Date"
          mask="####-##"
          placeholder="YYYY-MM"
          @update:model-value="onChange('plateExpiration')"
        >
          <template #append>
            <q-icon class="cursor-pointer" name="event">
              <q-popup-proxy
                ref="expirationProxy"
                anchor="center middle"
                self="center middle"
                transition-hide="scale"
                transition-show="scale"
                @show="!plateExpiration ? onExpirationDateChange($event) : () => {}"
              >
                <q-card>
                  <q-card-section class="row">
                    <ZubieSelect
                      v-model="expirationYear"
                      class="q-mr-sm"
                      emit-value
                      label="Year"
                      map-options
                      :options="yearOptions"
                      style="min-width: 60px"
                      @update:model-value="onExpirationDateChange"
                    />
                    <ZubieSelect
                      v-model="expirationMonth"
                      emit-value
                      label="Month"
                      map-options
                      :options="monthOptions"
                      style="min-width: 80px"
                      @update:model-value="onExpirationDateChange"
                    />
                  </q-card-section>
                </q-card>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </q-card-section>
    </q-card>
  </q-expansion-item>
</template>

<script>
import PlateJurisdictionHelperField from 'components/vehicles-page/PlateJurisdictionHelperField.vue';
import ZubieSelect from 'components/ZubieSelect.vue';
import { dayjs } from 'src/services/date';
import { validateLicensePlateExpiration } from 'src/services/vehicles';

export default {
  name: 'VehiclePlateField',
  components: {
    PlateJurisdictionHelperField,
    ZubieSelect,
  },
  props: {
    errors: {
      required: true,
      type: Object,
    },
    vehicle: {
      required: true,
      type: Object,
    },
    readonly: Boolean,
  },
  emits: ['clear-error', 'update:model-value'],
  data() {
    return {
      expirationMonth: '01',
      expirationYear: '',
      plateExpiration: '',
      plateJurisdiction: null,
      plateNumber: null,
    };
  },
  computed: {
    cleanErrors() {
      const { plateExpiration, plateJurisdiction, plateNumber } = this.errors || {};
      return {
        plateExpiration: plateExpiration ? plateExpiration[0] : null,
        plateJurisdiction: plateJurisdiction ? plateJurisdiction[0] : null,
        plateNumber: plateNumber ? plateNumber[0] : null,
      };
    },
    monthOptions() {
      return [
        {
          label: 'January',
          value: '01',
        },
        {
          label: 'February',
          value: '02',
        },
        {
          label: 'March',
          value: '03',
        },
        {
          label: 'April',
          value: '04',
        },
        {
          label: 'May',
          value: '05',
        },
        {
          label: 'June',
          value: '06',
        },
        {
          label: 'July',
          value: '07',
        },
        {
          label: 'August',
          value: '08',
        },
        {
          label: 'September',
          value: '09',
        },
        {
          label: 'October',
          value: '10',
        },
        {
          label: 'November',
          value: '11',
        },
        {
          label: 'December',
          value: '12',
        },
      ];
    },
    yearOptions() {
      const options = [];

      const startingYear = parseInt(dayjs().startOf('month').format('YYYY'), 10);

      for (let i = 0; i < 10; i += 1) {
        options.push(startingYear + i);
      }

      return options;
    },
  },
  methods: {
    async initData() {
      if (this.vehicle.plateExpiration) {
        const expiration = dayjs(this.vehicle.plateExpiration, 'YYYY-MM-DD');
        if (expiration.isValid()) {
          this.plateExpiration = expiration.format('YYYY-MM');
          this.expirationYear = expiration.format('YYYY');
          this.expirationMonth = expiration.format('MM');
        }
      }

      this.plateJurisdiction = this.vehicle.plateJurisdiction;
      this.plateNumber = this.vehicle.plateNumber;
    },
    onExpirationDateChange() {
      this.plateExpiration = `${this.expirationYear}-${this.expirationMonth}`;
      this.onChange('plateExpiration');
    },
    onChange(changedProperty) {
      let { plateExpiration } = this;

      if (changedProperty === 'plateExpiration') {
        const { isValid, expiration } = !validateLicensePlateExpiration(plateExpiration, 'YYYY-MM');
        if (isValid) {
          plateExpiration = expiration.endOf('month').format('YYYY-MM-DD');
        }
      }

      this.$emit('update:model-value', new Event('update:model-value'), {
        plateExpiration,
        plateJurisdiction: this.plateJurisdiction,
        plateNumber: this.plateNumber,
      });

      this.$emit('clear-error', changedProperty);
    },
    show() {
      this.$refs.expansionItem.show();
    },
  },
  created() {
    this.expirationYear = dayjs().add(1, 'year').format('YYYY');
  },
  async mounted() {
    this.initData();
  },
  watch: {
    vehicle() {
      this.initData();
    },
  },
};
</script>
