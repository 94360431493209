/**
 * @param {import('./lightbox-store').LightboxStoreActionContext} context
 */
export function close(context) {
  context.commit('setIndex', null);
}

/**
 * @param {import('./lightbox-store').LightboxStoreActionContext} context
 * @param {{ items: string[]; index: number; }}
 */
export function openItems(context, { items = [], index = 0 }) {
  context.commit('setItems', items);
  context.commit('setIndex', index);
}

/**
 * @param {import('./lightbox-store').LightboxStoreActionContext} context
 */
export function prev(context) {
  if (context.state.index === null || context.state.index <= 0) {
    return; // do nothing
  }
  context.commit('setIndex', context.state.index - 1);
}

/**
 * @param {import('./lightbox-store').LightboxStoreActionContext} context
 */
export function next(context) {
  if (context.state.index === null || context.state.index >= context.state.items.length) {
    return; // do nothing
  }
  context.commit('setIndex', context.state.index + 1);
}
