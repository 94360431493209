<template>
  <div class="mapped-assets-list column full-height bg-white">
    <MappedAssetsListHeader />
    <div ref="listContainer" class="col full-height">
      <MappedAssetsListNoItems v-if="allItemsFiltered" />
      <q-virtual-scroll
        ref="virtualList"
        :items="sortedVisibleAssets"
        :style="{ maxHeight: `${virtualScrollHeight}px` }"
        :virtual-scroll-item-size="itemSize"
        @virtual-scroll="onScroll"
      >
        <template v-slot="{ item, index }">
          <MappedAssetsListItem
            :key="item.key"
            :ref="`item${item.key}`"
            :asset="item"
            class="item"
            :class="{ 'item--selected': item.key === selectedAsset.key }"
            data-t="item"
            :index="index"
            :scroll-parent="$refs.virtualList.$el"
            :searchTerms="searchTerms"
            @resize-finished="scrollToSelected"
            @select="$emit('item-select', $event)"
          />
        </template>
      </q-virtual-scroll>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';
import MappedAssetsListHeader from 'components/map/MappedAssetsListHeader.vue';
import MappedAssetsListItem from 'components/map/MappedAssetsListItem.vue';
import MappedAssetsListNoItems from 'components/map/MappedAssetsListNoItems.vue';
import { ASSET_TYPES } from 'src/services/constants';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  name: 'MappedAssetsList',
  components: {
    MappedAssetsListHeader,
    MappedAssetsListItem,
    MappedAssetsListNoItems,
  },
  computed: {
    ...mapState('app', ['isLiveMapRoute']),
    ...mapGetters('assets', ['hasVisibleAssetsWithYmm', 'selectedAsset', 'visibleAssets']),
    ...mapState('filtering', ['searchTerms']),
    ...mapGetters('filtering', ['allItemsFiltered']),
    ...mapState('map', [
      'lockToIdealBounds',
      'matchesIdealBounds',
      'showAddressInList',
      'showDriverNameInList',
      'showNicknameInList',
    ]),
    ...mapState('search', ['filterTerms']),
    headerHeight() {
      return 60;
    },
    itemSize() {
      let itemSize = 33;

      if (this.hasVisibleAssetsWithYmm) {
        itemSize += 20;
      }

      if (this.showDriverNameInList) {
        itemSize += 24; // confirmed
      }

      if (this.showNicknameInList) {
        itemSize += 23; // confirmed
      }

      if (this.showAddressInList) {
        itemSize += 15; // confirmed
      }

      if (itemSize < 78) {
        itemSize = 78;
      }

      return itemSize;
    },
    selectedIndex() {
      return this.sortedVisibleAssets.findIndex((asset) => asset.key === this.selectedAsset.key);
    },
    selectedRef() {
      const { key } = _get(this.sortedVisibleAssets, [this.selectedIndex]) || {};
      return this.$refs[`item${key}`];
    },
    selectedRefInView() {
      const { offsetHeight, scrollTop: viewStart } = this.$refs.virtualList.$el;
      const { offsetTop: elementStart, offsetHeight: elementHeight } = this.selectedRef.$el;
      const viewEnd = viewStart + offsetHeight;
      const elementEnd = elementStart + elementHeight;
      return elementStart >= viewStart && elementEnd <= viewEnd;
    },
    sortedVisibleAssets() {
      if (this.showDriverNameInList && !this.showNicknameInList) {
        const sorted = [...this.visibleAssets].sort((a1, a2) => {
          const type1 = a1.majorType;
          const type2 = a2.majorType;
          // sort vehicles before assets
          if (type1 !== type2) {
            return type1 === ASSET_TYPES.ASSET ? 1 : -1;
          }
          // sort assets by type name
          if (type1 === ASSET_TYPES.ASSET) {
            return _get(a1, 'type.name.toLowerCase()') < _get(a2, 'type.name.toLowerCase()') ? -1 : 1;
          }
          // sort vehicles by driver name
          // _get only uses the default value if the specifed path is undefined, so it will return an empty string for vehicles without drivers
          const d1 = (_get(a1, 'driver.fullName') || 'unassigned').toLowerCase();
          const d2 = (_get(a2, 'driver.fullName') || 'unassigned').toLowerCase();
          return d1 < d2 ? -1 : 1;
        });
        return sorted;
      }
      return this.visibleAssets;
    },
  },
  data() {
    return {
      autoScroll: false,
      scrollingTo: false,
      lastAssetCount: 0,
      lastSelectedKey: null,
      listResizeSensor: null,
      virtualScrollHeight: 300,
    };
  },
  methods: {
    ...mapActions('filtering', ['filterByTerm']),
    ...mapActions('map', ['fitToIdealBounds']),
    onScroll() {
      if (!this.scrollingTo) {
        // Turn off auto-scrolling flag if a scroll event occurs when not auto-scrolling
        this.autoScroll = false;
      }
    },
    async scrollToSelected(skipAnimation = false) {
      if (!this.$refs.virtualList) {
        return;
      }

      this.scrollingTo = true;

      if (this.selectedRef) {
        // Selected item exists in the DOM, so we can scroll to it
        this.$refs.virtualList.$el.scrollTo({
          top: this.selectedRef.$attrs.index * this.itemSize,
          behavior: skipAnimation ? 'auto' : 'smooth',
        });
      } else if (!this.selectedAsset.isEmpty) {
        // Selected item is not in the DOM yet, but we can scroll to it virtually
        const index = this.$refs.virtualList.items.findIndex(({ key }) => key === this.selectedAsset.key);
        this.$refs.virtualList.scrollTo(index, 'top');
      }
      await setTimeoutPromise(500);

      this.scrollingTo = false;
    },
    updateItemSizeCss() {
      // Sync CSS variable with this value
      document.documentElement.style.setProperty('--mapped-item-size', `${this.itemSize}px`);
    },
  },
  mounted() {
    if (!this.listResizeSensor) {
      this.listResizeSensor = new ResizeObserver(
        _debounce(() => {
          if (this.$refs.listContainer) {
            this.virtualScrollHeight = this.$refs.listContainer.offsetHeight;
          }
        }, 100)
      );
      this.listResizeSensor.observe(this.$refs.listContainer);
    }
    this.updateItemSizeCss();
  },
  watch: {
    filterTerms() {
      if (this.isLiveMapRoute) {
        this.filterByTerm(this.filterTerms);
      }
    },
    selectedAsset() {
      if (!this.selectedAsset.isEmpty && this.selectedAsset.key !== this.lastSelectedKey) {
        this.autoScroll = true;
      }
      this.lastSelectedKey = this.selectedAsset.key;
    },
    async visibleAssets() {
      if (this.autoScroll && this.lastAssetCount !== this.visibleAssets.length) {
        this.lastAssetCount = this.visibleAssets.length;
        if (!this.selectedAsset.isEmpty) {
          await setTimeoutPromise(500);
          this.scrollToSelected(true);
        }
      }
    },
    async itemSize() {
      await setTimeoutPromise(100);
      this.updateItemSizeCss();
    },
  },
};
</script>

<style lang="scss" scoped>
.q-list {
  overflow: auto;
}

.item {
  min-height: var(--mapped-item-size);
  max-height: var(--mapped-item-size);
  justify-content: center;

  &.has-icons {
    justify-content: flex-start;
  }
}

.item--selected {
  max-height: 100%;
}
</style>

<style lang="scss">
.mapped-assets-list {
  @media (min-width: $breakpoint-sm) {
    margin-top: env(safe-area-inset-top);
  }
}

.ios-notch-bottom .mapped-assets-list .q-virtual-scroll__content {
  @media (min-width: $breakpoint-sm) {
    padding-bottom: calc(env(safe-area-inset-bottom) + 13px);
  }
}
</style>
