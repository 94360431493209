export default () => ({
  groups: [],
  groupsLoading: false,
  groupBeingEdited: {
    name: '',
    parentGroupKey: '',
  },
  groupsError: {},
  groupsErrorMessage: false,
  savingGroup: false,
});
