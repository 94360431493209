<template>
  <q-list class="live-map-filters q-pt-xs bg-white" @click.stop>
    <q-item class="justify-between">
      <q-btn color="white" data-t="clearFilters" flat label="Reset All" square text-color="primary" @click="resetAll" />
      <CancelButton v-if="!hideCloseButton" data-t="close" label="Close" @click="$emit('hide', $event)" />
    </q-item>
    <q-item v-for="(filter, index) in filterFields" :key="index" class="q-px-sm">
      <ZubieTree
        v-if="filter.model === 'group'"
        class="full-width"
        :default-expand-all="groupsExpandedByDefault"
        :expanded="groupsExpanded"
        header-label="Groups"
        label-key="name"
        :model-value="mapFilters.group"
        node-key="key"
        :options="filter.options"
        :tooltip="filter.tooltip"
        @before-hide="groupMenuOpen = false"
        @before-show="groupMenuOpen = true"
        @update:expanded="onExpandedChange"
        @update:model-value="applyTreeFilter(filter.model, $event)"
      >
        <template #prepend>
          <q-icon class="q-mr-md" name="filter_list" size="24px" style="color: rgba(0, 0, 0, 0.54)" />
        </template>
        <template #append>
          <q-icon :class="{ 'rotate-180': groupMenuOpen }" name="arrow_drop_down" style="transition: transform 0.28s" />
        </template>
      </ZubieTree>
      <ZubieSelect
        v-else
        bg-color="white"
        :data-t="filter.model"
        dense
        display-value-sanitize
        filled
        :label="filter.label"
        :model-value="selectedValuesAsOptions[filter.model]"
        multiple
        :options="filter.options"
        options-sanitize
        style="min-width: 275px"
        :tooltip="filter.tooltip"
        use-chips
        @update:model-value="applyFilter(filter.model, $event)"
      >
        <template #prepend>
          <q-icon class="q-mr-md" name="filter_list" />
        </template>
      </ZubieSelect>
    </q-item>
    <q-item class="q-px-sm">
      <q-toggle
        checked-icon="check"
        color="primary"
        data-t="toggle"
        label="Only show items in view on map"
        :model-value="filterOutOfBounds === true"
        unchecked-icon="clear"
        @update:model-value="toggleOutOfBoundsFilter"
      />
    </q-item>
    <q-item class="q-px-sm">
      <q-toggle
        checked-icon="check"
        color="primary"
        data-t="toggle"
        label="Only show shared/recovery items"
        :model-value="filterShared === true"
        unchecked-icon="clear"
        @update:model-value="toggleSharedFilter"
      />
    </q-item>
  </q-list>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import { mapActions, mapGetters, mapState } from 'vuex';
import TreeViewMultiSelect from 'components/TreeViewMultiSelect.vue';
import ZubieSelect from 'components/ZubieSelect.vue';
import ZubieTree from 'components/ZubieTree.vue';
import { healthStatusOptions, statusOptions } from 'src/services/constants';
import { trackEvent } from 'src/services/intercom';
import storage from 'src/services/storage';

const MAX_GROUPS_FOR_DEFAULT_EXPANDED = 20;

export default {
  components: {
    TreeViewMultiSelect,
    ZubieSelect,
    ZubieTree,
  },
  props: {
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('assets', ['assetTypes', 'tags']),
    ...mapState('filtering', ['filterOutOfBounds', 'filterShared']),
    ...mapState('filtering', {
      mapFilters: 'filters',
    }),
    ...mapGetters('groups', ['groups']),
    ...mapGetters('session', ['hasPermission']),
    assetTypeOptions() {
      return [
        ...this.assetTypes.map((type) => ({
          label: type.name,
          value: type.key,
        })),
        {
          label: 'Vehicle',
          value: 'vehicle',
        },
      ];
    },
    buttonStyles() {
      const styles = {};

      if (this.$q.screen.lt.sm) {
        if (this.menuOpen) {
          styles.background = 'rgba(0, 193, 222, .25) !important';
        }
      }

      return styles;
    },
    filterFields() {
      const fields = [];

      if (this.groups.length) {
        fields.push({
          label: 'Groups',
          model: 'group',
          options: this.groups,
          tooltip: 'Only Show Selected Groups',
        });
      }

      if (!_isEmpty(this.assetTypes) && this.hasPermission('assetView')) {
        fields.push({
          label: 'Asset Types',
          model: 'assetType',
          options: this.assetTypeOptions,
          tooltip: 'Only Show Selected Asset Types',
        });
      }

      if (this.tagOptions.length) {
        fields.push({
          label: 'Tags',
          model: 'tag',
          options: this.tagOptions,
          tooltip: 'Only Show Selected Tags',
        });
      }

      if (this.statusOptions.length) {
        fields.push({
          label: 'Status',
          model: 'status',
          options: this.statusOptions,
          tooltip: 'Only Show Selected Statuses',
        });
      }

      if (this.healthStatusOptions.length) {
        fields.push({
          label: 'Health',
          model: 'health',
          options: this.healthStatusOptions,
          tooltip: 'Only Show Selected Health Statuses',
        });
      }

      return fields;
    },
    healthStatusOptions() {
      return healthStatusOptions;
    },
    /**
     * Uses the values given to the selectedValues property to create the <select> options equivalent.
     * - the selectedValues are only values, we need to map them to { label, value } objects defined in the
     *   "filters" computed property
     *
     * @returns {Object}
     */
    selectedValuesAsOptions() {
      const valuesAsOptions = {};

      Object.keys(this.mapFilters).forEach((key) => {
        const values = this.mapFilters[key];
        // Find the matching filter
        const filterField = this.filterFields.find((filter) => filter.model === key);

        if (filterField) {
          const filterOptions = this.filterFields.find((filter) => filter.model === key).options;
          // Find the actual option for each of the selected values
          const matchingOptions = values.map((value) => filterOptions.find((option) => option.value === value));
          valuesAsOptions[key] = matchingOptions;
        }
      });

      return valuesAsOptions;
    },
    statusOptions() {
      return statusOptions;
    },
    tagOptions() {
      return this.tags.map((tag) => ({
        label: tag.value,
        value: tag.key,
      }));
    },
  },
  data() {
    return {
      groupsExpanded: undefined,
      groupsExpandedByDefault: undefined,
      groupMenuOpen: false,
      menuOpen: false,
    };
  },
  methods: {
    ...mapActions('filtering', ['clearFilters', 'setOutOfBoundsFilter', 'setSharedFilter', 'updateFilters']),
    applyFilter(filterName, selectedOptions) {
      const filters = { ...this.mapFilters };
      filters[filterName] = selectedOptions.map((option) => option.value);
      this.updateFilters({ filters });
    },
    applyTreeFilter(filterName, selectedOptions) {
      const filters = { ...this.mapFilters };
      filters[filterName] = selectedOptions;
      this.updateFilters({ filters });
    },
    resetAll(event) {
      this.clearFilters();
      this.$emit('hide', event);
    },
    onExpandedChange(expanded) {
      storage.set('map-filters/expandedGroups', JSON.stringify(expanded));
    },
    openMenu() {
      this.menuOpen = true;
      this.$nextTick(() => {
        this.$refs.menu.$parent.$el.classList.add('q-menu--responsive');
      });
    },
    toggleOutOfBoundsFilter() {
      const wasActive = this.filterOutOfBounds === true;
      const isActive = !wasActive;
      trackEvent('map_bounds_filter_changed', { state: isActive ? 'on' : 'off' });
      this.setOutOfBoundsFilter(isActive);
    },
    toggleSharedFilter() {
      const isActive = this.filterShared === true;
      this.setSharedFilter(!isActive);
    },
  },
  created() {
    let hasSavedExpanded = false;

    try {
      const expandedString = storage.get('map-filters/expandedGroups');
      if (expandedString) {
        this.groupsExpanded = JSON.parse(expandedString);
        hasSavedExpanded = true;
      }
    } catch {
      // do nothing
    }

    if (!hasSavedExpanded) {
      this.groupsExpandedByDefault = Object.keys(this.groups).length < MAX_GROUPS_FOR_DEFAULT_EXPANDED;
    }
  },
};
</script>

<style lang="scss" scoped>
.live-map-filters {
  max-width: 400px;
}

:deep(.q-field) {
  width: 100%;
}

:deep(.q-field__label) {
  font-size: 15px;
  color: black;
}

:deep(.q-field--float .q-field__label) {
  color: $grey-7;
}

:deep(.q-field--focused .q-field__label) {
  color: $primary;
}
</style>

<style lang="scss">
.ios-notch-bottom .live-map-filters {
  margin-bottom: calc(env(safe-area-inset-bottom) + 13px);
}
</style>
