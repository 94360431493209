import { CHECK_IN_MODES } from 'src/services/constants';
import storage from 'src/services/storage';
import { account as getAccount, currentUser as getCurrentUser, isAccountAdmin } from 'src/store/session/getters';

export function profile(state, profileData) {
  state.profile = profileData;
}

export function profilePromise(state, promise) {
  state.profilePromise = promise;
}

export function permissions(state, permissionsData) {
  if (permissionsData.permissions) {
    Object.defineProperties(permissionsData.permissions, {
      // add custom permissions to simplify routeGuard OR logic checking
      dashboardFleetOrRentalView: {
        get() {
          return Boolean(this.dashboardFleetView || this.dashboardRentalView);
        },
      },
      isAccountAdmin: {
        get() {
          // pass fake getters object to the isAccountAdmin getter
          return isAccountAdmin(state, { currentUser: getCurrentUser(state) });
        },
      },
      isAllowedToCheckIn: {
        get() {
          return isAllowedToCheckinViaDashcam(state) || isAllowedToCheckInManually(state);
        },
      },
      isAllowedToCheckinViaDashcam: {
        get() {
          return isAllowedToCheckinViaDashcam(state);
        },
      },
      isAllowedToCheckInManually: {
        get() {
          return isAllowedToCheckInManually(state);
        },
      },
      isAllowedToLogin: {
        get() {
          return true;
        },
      },
    });
  }
  state.permissions = permissionsData;
}

function isAllowedToCheckInManually(state) {
  const account = getAccount(state);
  const isAdmin = getCurrentUser(state).isAdmin();
  const { driverCheckInMode } = account.preferences;
  if (driverCheckInMode === CHECK_IN_MODES.UNRESTRICTED) {
    return true;
  }
  if (driverCheckInMode === CHECK_IN_MODES.RESTRICTED && isAdmin) {
    return true;
  }
  return false;
}

function isAllowedToCheckinViaDashcam(state) {
  const account = getAccount(state);
  const { driverCheckInMode } = account.preferences;
  return driverCheckInMode === CHECK_IN_MODES.UNRESTRICTED || driverCheckInMode === CHECK_IN_MODES.RESTRICTED;
}

export function permissionsPromise(state, promise) {
  state.permissionsPromise = promise;
}

export function ready(state) {
  state.isReady = true;
  state.readyResolve();
}

export function setAccount(state, data) {
  state.account = data;
}

export function accountPromise(state, promise) {
  state.accountPromise = promise;
}

export function accounts(state, data) {
  state.accounts = data;
}

export function currentUser(state, user) {
  state.currentUser = user;
}

export function currentUserPromise(state, promise) {
  state.currentUserPromise = promise;
}

/**
 * @param {import('src/types/session-store').SessionStoreState} state
 * @param {import('src/types/session-store').PartnerData} partnerData
 */
export function partner(state, partnerData) {
  storage.set('session/partnerName', partnerData.appName);
  state.partner = partnerData;
}

export function partnerPromise(state, promise) {
  state.partnerPromise = promise;
}

export function setCoupon(state, coupon) {
  state.coupon = coupon;
}

export function setCouponLoading(state, isLoading) {
  state.couponLoading = isLoading;
}

export function setBillingErrors(state, billingErrors) {
  state.billingErrors = billingErrors;
}

export function setBillingInfo(state, billingInfo) {
  state.account.billingInfo = billingInfo;
}

export function setHasDashcam(state, hasDashcam) {
  state.hasDashcam = hasDashcam;
}

/**
 * @param {SessionStoreState} state
 * @param {'DRIVER_ID' | 'MANUAL'} type
 */
export function setCheckInType(state, type) {
  state.checkInType = type;
  storage.set('session/checkInType', type);
}

/**
 * @param {SessionStoreState} state
 * @param {boolean} isLoading
 */
export function setLoadingRavenFleet(state, isLoading) {
  state.isLoadingFleet = Boolean(isLoading);
}
