import _get from 'lodash/get';
import { Notify } from 'quasar';

/**
 * Clears the config details.
 *
 * @param {ActionContext} context
 */
export function clearConfig(context) {
  context.commit('setAppConfigDetails', null);
}

/**
 * Authorizes an app using the given appId and body.
 *
 * @param {ActionContext} context
 * @param {Object} payload
 * @param {String} payload.appId
 * @param {Object} payload.body
 * @param {String} payload.installLevel
 * @returns {Object}
 */
export async function connectApp(context, { appId, body, installLevel = 'USER' }) {
  const appAuthResult = await context.rootState.app.broker.fetchAndTransform({
    fn: 'authorizeApp',
    params: { appId, body, installLevel },
  });
  context.dispatch('getConnectedApps');
  return appAuthResult;
}

/**
 * Disconnects the app with the given appId.
 *
 * @param {ActionContext} context
 * @param {Object} payload
 * @param {String} payload.appId
 * @param {String} payload.deactivateLabel
 * @param {String} payload.installLevel
 * @param {String} payload.name
 */
export async function disconnect(context, { id, name, deactivateLabel = 'deactivated', installLevel = 'USER' }) {
  const response = await context.rootState.app.broker.fetchAndTransform({
    fn: 'disconnectApp',
    params: { id, installLevel, name },
  });
  await context.dispatch('getConnectedApps');

  if (response.error) {
    return;
  }

  Notify.create({
    color: 'positive',
    icon: 'power_off',
    message: `${name} app ${deactivateLabel}.`,
  });
}

/**
 * Retrieves partner apps available to this account.
 *
 * @param {ActionContext} context
 */
export async function getPublishedApps(context) {
  if (context.state.isLoading === true) {
    return;
  }

  context.commit('setLoading', true);

  const apps = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getPublishedApps',
    transformationFn: 'transformArray',
  });

  if (!apps.error) {
    context.commit('setApps', apps);
  }

  context.commit('setLoading', false);
}

/**
 * Retrieves app connection details for a given app (by app ID).
 *
 * @param {ActionContext} context
 * @param {Object} payload
 * @param {String} payload.appId
 * @param {String} payload.installLevel
 */
export async function getAppConnectDetails(context, { appId, installLevel = 'USER' }) {
  const appConnectDetails = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getAppConnectDetails',
    params: { appId, installLevel },
  });

  if (!appConnectDetails.error) {
    context.commit('setAppConnectDetails', appConnectDetails);
  }

  return appConnectDetails;
}

/**
 * Retrieves app configuration for the given app (by ID).
 *
 * @param {ActionContext} context
 * @param {Object} payload
 * @param {String} payload.appId
 * @param {String} payload.installLevel
 */
export async function getConfig(context, { appId, installLevel = 'USER' }) {
  const appConfigDetails = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getAppConfig',
    params: {
      appId,
      errorMessage: 'Failed to get app configuration.',
      installLevel,
    },
  });

  if (!appConfigDetails.error) {
    context.commit('setAppConfigDetails', appConfigDetails);
  }

  return appConfigDetails;
}

/**
 * Retrieves apps connected to this account.
 *
 * @param {ActionContext} context
 */
export async function getConnectedApps(context) {
  if (context.state.isLoadingConnectedApps === true) {
    return;
  }

  context.commit('setLoadingConnectedApps', true);

  const userApps = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getConnectedApps',
    params: {
      installLevel: 'USER',
    },
    transformationFn: 'transformArray',
  });

  const accountApps = await context.rootState.app.broker.fetchAndTransform({
    fn: 'getConnectedApps',
    params: {
      installLevel: 'ACCOUNT',
    },
    transformationFn: 'transformArray',
  });

  if (!userApps.error && !accountApps.error) {
    context.commit('setConnectedApps', [...userApps, ...accountApps]);
    context.dispatch('getSmartcarConfiguredVins');
  }

  context.commit('setLoadingConnectedApps', false);
}

/**
 * Sets the currently viewed app.
 *
 * @param {ActionContext} context
 * @param {String} appId
 */
export function setAppViewed(context, appId) {
  context.commit('setAppViewed', appId);
}

/**
 * Sets the configuration for the given app (by ID) using the given config.
 *
 * @param {ActionContext} context
 * @param {String} appId
 * @param {Object} config
 * @param {String} installLevel
 */
export async function setConfig(context, { appId, config, installLevel = 'USER' }) {
  const appAuthResult = await context.rootState.app.broker.fetchAndTransform({
    fn: 'setAppConfig',
    params: { appId, config, installLevel },
  });
  context.dispatch('getConnectedApps');
  return appAuthResult;
}

/**
 * Gets the configuration for all connected Smartcar apps and stores all associated VINs
 *
 * @param {ActionContext} context
 */
export async function getSmartcarConfiguredVins(context) {
  const apps = (context.state.connectedApps || []).filter(
    ({ appId, authorizationNeeded, configurationNeeded }) =>
      appId.startsWith('smartcar') && authorizationNeeded === false && configurationNeeded === false
  );
  const promises = apps.map(({ appId, installLevel }) => {
    const request = context.rootState.app.broker.fetchAndTransform({
      fn: 'getAppConfig',
      params: {
        appId,
        errorMessage: 'Failed to retrieve Smartcar configured VINs.',
        installLevel,
      },
    });
    return request; // for formatting purposes
  });
  const configs = await Promise.all(promises);
  const vins = new Set();
  configs.forEach((config) => {
    const configVins = Object.keys(_get(config, 'config.value.vinMapping', {}));
    configVins.forEach((vin) => vins.add(vin.toLowerCase()));
  });
  context.commit('setSmartcarVins', vins);
}

/**
 * Configures the app to show Direct Connect apps for this session
 *
 * @param {ActionContext} context
 */
export async function allowDirectConnect(context) {
  if (!context.state.allowDirectConnect) {
    context.commit('setAllowDirectConnect', true);
  }
}
