import Trip from 'src/models/Trip';

/**
 * @param {TripsStoreState} state
 * @param {Promise<unknown>} promise
 */
export function fetchingTrips(state, promise) {
  state.tripsLoading = true;
  state.tripsPromise = promise;
}

/**
 * @param {TripsStoreState} state
 * @param {TripData} tripData
 */
export function setTrip(state, tripData) {
  state.trips = Object.freeze({ ...state.trips, [tripData.key]: new Trip(tripData) });
}

/**
 * @param {TripsStoreState} state
 * @param {TripData[]} trips
 */
export function setTrips(state, trips) {
  state.trips = Object.freeze({ ...trips });
}

/**
 * @param {TripsStoreState} state
 */
export function tripsFetched(state) {
  state.tripsLoading = false;
  state.tripsPromise = null;
}

/**
 * @param {TripsStoreState} state
 * @param {string} tripKey
 */
export function selectTrip(state, tripKey) {
  state.selectedTripKey = tripKey;
}

/**
 * @param {TripsStoreState} state
 */
export function clearTrips(state) {
  state.trips = [];
}

/**
 * @param {TripsStoreState} state
 * @param {Object} payload
 * @param {string} payload.key
 * @param {TripPoint[]} payload.points
 */
export function setPoints(state, { key, points }) {
  state.points = Object.freeze({ ...state.points, [key]: points });
}

/**
 * @param {TripsStoreState} state
 * @param {TripData} tripData
 */
export function setSelectedVehicleTrip(state, tripData) {
  state.selectedVehicleTrip = Object.freeze({ ...tripData });
}

/**
 * @param {TripsStoreState} state
 * @param {string} tripKey
 */
export function setViewedVehicleTripKey(state, tripKey) {
  state.viewedVehicleTripKey = tripKey;
}

/**
 * @param {TripsStoreState} state
 * @param {TripData} trip
 */
export function setViewedVehicleTrip(state, trip) {
  state.viewedVehicleTrip = Object.freeze({ ...trip });
}
