<template>
  <div>
    <KebabMenu :btn-padding="menuBtnPadding" class="options-icon" round size="14">
      <q-item v-if="showOverview" clickable :to="overview">
        <q-item-section>Overview</q-item-section>
      </q-item>
      <q-item v-if="showLocations" clickable :to="locations">
        <q-item-section>
          {{ asset.hasTripsDevice ? 'Trips' : 'Locations' }}
        </q-item-section>
      </q-item>
      <q-item v-if="isVehicle" clickable :to="{ name: 'vehicleHealth', params: { key: assetKey } }">
        <q-item-section>Health</q-item-section>
      </q-item>
      <q-item v-if="isVehicle" clickable :to="{ name: 'vehicleMaintenance', params: { key: assetKey } }">
        <q-item-section>Maintenance</q-item-section>
      </q-item>
      <q-item v-if="allowEditVehicle || isViewerAndDriver" clickable @click="editAsset">
        <q-item-section>Edit</q-item-section>
      </q-item>
      <q-item v-if="showRecoveryButton" clickable @click="showRecoveryModeDialog = true">
        <q-item-section>Enable Recovery Mode</q-item-section>
      </q-item>
      <q-item v-if="canDelete" :clickable="isAccountAdmin" :disable="!isAccountAdmin" @click="showDeleteDialog = true">
        <q-item-section class="text-negative">Delete</q-item-section>
        <q-tooltip v-if="!isAccountAdmin"
          >Only admins with access to the entire account (i.e. not in groups) may delete
          {{ isVehicle ? 'vehicles' : 'assets' }}</q-tooltip
        >
      </q-item>
    </KebabMenu>
    <DeleteAssetDialog
      v-if="showDeleteDialog"
      :asset-key="assetKey"
      @error="onDeleteError"
      @hide="showDeleteDialog = false"
    />
    <RecoveryModeDialog
      v-if="showRecoveryModeDialog"
      :vehicle-key="assetKey"
      :visible="true"
      @hide="showRecoveryModeDialog = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RecoveryModeDialog from 'components/vehicle/RecoveryModeDialog.vue';
import DeleteAssetDialog from 'components/vehicles-page/DeleteAssetDialog.vue';
import Asset from 'src/models/Asset';
import { ASSET_TYPES } from 'src/services/constants';

export default {
  props: {
    asset: [Asset, Object],
    menuBtnPadding: String,
    showLocations: {
      type: Boolean,
      default: true,
    },
    showOverview: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DeleteAssetDialog,
    RecoveryModeDialog,
  },
  computed: {
    ...mapGetters('session', ['allowEditVehicle', 'currentUser', 'hasPermission', 'isAccountAdmin', 'isRental']),
    assetKey() {
      return this.asset?.key || '';
    },
    canDelete() {
      return this.isVehicle ? this.hasPermission('vehicleDelete') : this.hasPermission('assetEdit');
    },
    isVehicle() {
      const { majorType } = this.asset;
      return majorType === ASSET_TYPES.VEHICLE;
    },
    isViewerAndDriver() {
      return this.currentUser.isViewer() && this.asset.primaryDriverKey === this.currentUser.key;
    },
    locations() {
      return { name: this.asset.tripsRoute, params: { key: this.assetKey } };
    },
    overview() {
      const name = this.isVehicle ? 'vehicle' : 'asset';
      return { name, params: { key: this.assetKey } };
    },
    showRecoveryButton() {
      const { firstConnectedDevice, isConnected } = this.asset;
      return this.isVehicle && this.isRental && isConnected && firstConnectedDevice.isXirgo;
    },
  },
  data() {
    return {
      showDeleteDialog: false,
      showRecoveryModeDialog: false,
    };
  },
  methods: {
    ...mapActions('assets', ['openEditAssetDialog']),
    ...mapActions('vehicles', ['openEditVehicleDialog']),
    editAsset() {
      if (this.isVehicle) {
        this.openEditVehicleDialog(this.assetKey);
      } else {
        this.openEditAssetDialog(this.assetKey);
      }
    },
    onDeleteError({ error }) {
      this.$q.notify({
        icon: 'error',
        color: 'negative',
        message: error,
      });
      this.showDeleteDialog = false;
    },
  },
};
</script>
