<template>
  <q-card class="card" flat>
    <q-card-section class="q-pa-none">
      <div class="row items-center q-mb-md">
        <div v-if="assetKey" class="row justify-center items-center" :class="{ 'full-width q-mb-md': isTouchOnly }">
          <AssetPicture
            :class="{ 'q-mr-md': $q.screen.width >= 450 }"
            :disable="isSaving || disable"
            :imageUrl="asset.imageUrl"
            :size="75"
            :type="assetType"
            @remove="
              asset.imageUrl = '';
              onChange($event);
            "
            @update="
              asset.imageUrl = $event;
              onChange($event);
            "
          />
        </div>
        <q-input
          v-model="asset.nickname"
          class="col-grow"
          :disable="isSaving || disable"
          filled
          hide-bottom-space
          label="Name"
          :rules="[(value) => Boolean(value) || 'Please enter a name.']"
          @update:model-value="onChange"
        >
          <template #after>
            <ScannerButton @scan="(event, value) => onScan(event, 'nickname', value)" />
          </template>
        </q-input>
      </div>
      <div data-t="assetTypeField">
        <q-select
          v-if="typeOptions.length > 0"
          v-model="asset.assetTypeKey"
          v-capacitor-native-select
          class="q-mb-sm"
          :disable="isSaving || disable"
          display-value-sanitize
          emit-value
          filled
          hide-bottom-space
          label="Type"
          map-options
          :options="typeOptions"
          options-sanitize
          :rules="[(value) => Boolean(value) || 'Please choose a type.']"
          @update:model-value="onChange"
        >
          <template v-slot:after>
            <AddButton @click="showEditAssetTypeDialog = true" />
          </template>
        </q-select>
      </div>

      <q-banner v-if="typeOptions.length === 0" class="q-mb-sm bg-info text-dark"
        >Please create a new type for this asset.</q-banner
      >
      <div v-if="assetTypeError" class="q-field__bottom q-ml-sm text-negative">{{ assetTypeError.message }}</div>
      <div v-if="hasPermission('tagListView')">
        <ZubieSelect
          v-model="asset.tagKeys"
          class="q-my-sm"
          data-t="assetTagsField"
          :disable="isSaving || disable || availableTags.length === 0"
          display-value-sanitize
          emit-value
          filled
          hide-bottom-space
          label="Tags"
          map-options
          multiple
          option-label="value"
          option-value="key"
          :options="availableTags"
          options-sanitize
          tooltip="Selected Tags"
          use-chips
          @update:model-value="onChange"
        >
          <template v-slot:selected-item="scope">
            <TagChip
              dense
              :removable="true"
              :tabindex="scope.tabindex"
              :tag="scope.opt"
              @remove="asset.tagKeys.splice(scope.index, 1)"
            />
          </template>
        </ZubieSelect>
        <q-tooltip v-if="availableTags.length === 0">There are no tags to choose from.</q-tooltip>
      </div>
      <div v-if="hasPermission('groupEdit')">
        <TreeViewMultiSelect
          class="q-mb-sm"
          data-t="assetGroupField"
          :dataTree="groups"
          :disable="isSaving || disable || groups.length === 0"
          filled
          label="Group"
          nodeLabelKey="name"
          nodeValueKey="key"
          :singleSelection="true"
          :value="asset.groupKeys"
          @change="
            asset.groupKeys = $event;
            onChange($event);
          "
        />
        <q-tooltip v-if="groups.length === 0">There are no groups to choose from.</q-tooltip>
      </div>
      <q-banner v-if="error" class="q-mt-md text-white bg-negative">{{ error }}</q-banner>
    </q-card-section>
    <EditAssetTypeDialog
      v-if="showEditAssetTypeDialog"
      @hide="showEditAssetTypeDialog = false"
      @update:model-value="selectAssetType"
    />
  </q-card>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapGetters, mapState } from 'vuex';
import AddButton from 'components/AddButton.vue';
import AssetPicture from 'components/assets/AssetPicture.vue';
import EditAssetTypeDialog from 'components/assets/EditAssetTypeDialog.vue';
import TagChip from 'components/chips/TagChip.vue';
import ScannerButton from 'components/scanner/ScannerButton.vue';
import TreeViewMultiSelect from 'components/TreeViewMultiSelect.vue';
import ZubieSelect from 'components/ZubieSelect.vue';
import Asset from 'src/models/Asset';
import { ASSET_TYPES } from 'src/services/constants';

export default {
  name: 'AssetForm',
  props: {
    assetKey: String,
    flat: Boolean,
    disable: Boolean,
  },
  components: {
    EditAssetTypeDialog,
    AddButton,
    AssetPicture,
    ScannerButton,
    TagChip,
    TreeViewMultiSelect,
    ZubieSelect,
  },
  computed: {
    ...mapState('assets', ['assetTypes']),
    ...mapState('env', ['browserSupport']),
    ...mapState('groups', ['groups']),
    ...mapGetters('assets', ['assets']),
    ...mapGetters('session', ['hasPermission']),
    ...mapGetters('tags', {
      availableTags: 'genericTags',
    }),
    assetType() {
      return ASSET_TYPES.ASSET;
    },
    isTouchOnly() {
      return this.browserSupport.isTouchOnly;
    },
    typeOptions() {
      return Object.values(this.assetTypes).map(({ key, name }) => ({
        label: name,
        value: key,
      }));
    },
  },
  data() {
    return {
      asset: new Asset(),
      assetTypeError: null,
      error: null,
      isSaving: false,
      showEditAssetTypeDialog: false,
    };
  },
  methods: {
    ...mapActions('assets', ['saveAsset']),
    ...mapActions('groups', ['getGroups']),
    ...mapActions('tags', ['getTags']),
    onChange(event) {
      this.$emit('change', event, this.asset);
    },
    notSaving() {
      this.isSaving = false;
      this.$emit('saving', false);
    },
    onScan(event, property, value) {
      this.asset = {
        ...this.asset,
        [property]: value,
      };
      this.onChange(event);
    },
    async save() {
      this.assetTypeError = null;
      this.error = null;

      this.isSaving = true;
      this.$emit('saving', true);

      // Show error if asset is being created without having an asset type
      if (!this.asset.assetTypeKey && Object.keys(this.assetTypes).length === 0) {
        this.assetTypeError = new Error('Please create an asset type.');
        this.notSaving();
        return null;
      }

      // Handle creation of new asset
      const response = await this.saveAsset(this.asset);
      if (response.error) {
        this.error = response.error;
        this.notSaving();
        return null;
      }

      this.notSaving();
      this.$emit('saved', response);

      return response;
    },
    selectAssetType(event, assetType) {
      this.asset.assetTypeKey = assetType.key;
      this.onChange(event);
    },
  },
  async mounted() {
    if (!this.availableTags.length) {
      await this.getTags();
    }

    if (this.groups.length === 0) {
      await this.getGroups();
    }

    if (this.assetKey) {
      this.asset = _cloneDeep(this.assets.find(({ key }) => key === this.assetKey));
    }

    this.$emit('ready');
  },
};
</script>

<style lang="scss" scoped>
.error {
  border: 2px solid $negative;
}
</style>
