import * as navigationService from 'src/services/navigation';

const navigateToUrl = {
  methods: {
    /**
     * Navigates with navigation service using the given URL.
     *
     * @param {Object|String} url
     * @returns {Boolean}
     */
    navigateToUrl(url) {
      return navigationService.navigateToUrl(url);
    },
  },
};

export default navigateToUrl;
