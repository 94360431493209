/**
 * Creates a Zubie Driver ID Google Wallet pass.
 *
 * @param {ActionContext} context
 * @param {string} qrCode
 * @returns {Promise<void>}
 */
export async function addToGoogleWallet(context, qrCode) {
  const { url } = await context.rootState.app.apiWorker.createGooglePass({
    userKey: context.rootState.session.currentUser.key,
    qrCode,
  });
  window.location.href = url;
}
