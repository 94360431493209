<template>
  <MappedItemButton label="Zoom" :size="size" @click="onClick">
    <template #icon><q-icon class="icon" name="zoom_in" :size="`${size + 1}px`" /></template>
  </MappedItemButton>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import MappedItemButton from 'components/map/MappedItemButton.vue';
import Asset from 'src/models/Asset';
import Vehicle from 'src/models/Vehicle';

const props = defineProps<{ dense?: boolean; short?: boolean; size: number; asset: Asset | Vehicle }>();

const emit = defineEmits(['click']);

const store = useStore();

function onClick(event: Event) {
  store.dispatch('map/focusOnAsset', { asset: props.asset, zoom: true, ignoreTrip: true });
  emit('click', event);
}
</script>

<style lang="scss" scoped>
.icon {
  margin-right: 2px;
}
</style>
