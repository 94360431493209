import SimpleModel from 'src/models/SimpleModel';
import { isLightOrDark } from 'src/services/color';
import { svgToImage } from 'src/services/svg/tools';

export default class SvgMarker extends SimpleModel {
  /** @type {string | null} */
  borderColor = null;

  /** @type {number} */
  height = null;

  /** @type {string | null} */
  innerColor = null;

  /** @type {string | null} */
  outerColor = null;

  /** @type {boolean} */
  outline = false;

  /** @type {string} */
  svg = null;

  /** @type {number} */
  width = null;

  /**
   * @param {string} svg
   * @param {{ height: number; width: number; innerColor?: string; outerColor?: string; borderColor?: string; outline?: boolean; }} payload
   */
  constructor(svg, { height, width, innerColor = null, outerColor = null, borderColor = null, outline = false }) {
    super();
    this.insert({ height, width, svg, innerColor, outerColor, borderColor, outline });
  }

  /**
   * Replaces the svg string with the specified innerColor and outerColor
   *
   * @param {string} colorOverride
   * @param {boolean} outline
   */
  colorize(colorOverride = 'none', outline = false) {
    let { borderColor, innerColor, outerColor, svg } = this;

    if (colorOverride === 'none') {
      colorOverride = null;
    }

    if (colorOverride) {
      outerColor = colorOverride;
      innerColor = isLightOrDark(outerColor) === 'light' ? '#000' : '#FFF';
    }

    if (!borderColor) {
      borderColor = outerColor;
    }

    const outlineDisplay = outline ? 'inline' : 'none';

    svg = svg
      .replace(/\{\{ borderColor \}\}/g, borderColor)
      .replace(/\{\{ outerColor \}\}/g, outerColor)
      .replace(/\{\{ innerColor \}\}/g, innerColor)
      .replace(/\{\{ outlineDisplay \}\}/g, outlineDisplay);

    return svg;
  }

  /**
   * Creates and loads an HTML Image from the SVG.
   *
   * @param {string | null} colorOverride
   * @param {boolean} outline
   */
  async toImage(colorOverride = null, outline = false) {
    const { height, width } = this;

    const svg = this.colorize(colorOverride, outline);

    const image = await svgToImage(svg, width, height);

    return image;
  }
}
