<template>
  <div v-if="vehiclesLoading" class="q-pt-lg">
    <q-linear-progress color="primary" indeterminate />
  </div>
  <div v-else class="heading row q-pb-sm text-dark bg-white" :class="{ 'q-pt-sm': $q.screen.gt.sm }">
    <div class="row full-width justify-between text-subtitle3 no-wrap" data-t="content">
      <div class="row items-center no-wrap">
        <div v-if="!$q.screen.gt.xs">
          <MappedAssetsListFiltersButton />
        </div>
        <div class="mapped-count zubie-font">{{ mappedCount }}/{{ totalCount }} Mapped</div>
      </div>
      <div class="row no-wrap">
        <q-btn v-if="$q.screen.gt.sm" data-t="resetButton" flat square text-color="dark" @click="resetAll">Reset</q-btn>
        <q-btn class="q-mr-sm" color="dark" dense flat icon="settings" square>
          <q-menu>
            <q-list class="toggle-list">
              <q-item class="column">
                <q-item-section>
                  <div class="toggle-list__header text-subtitle3 zubie-font">Display</div>
                  <div class="column">
                    <q-toggle
                      label="Driver"
                      :model-value="showDriverNameInList"
                      @update:model-value="toggleDriverNameInList"
                    />
                    <q-toggle
                      label="Name"
                      :model-value="showNicknameInList"
                      @update:model-value="toggleNicknameInList"
                    />
                    <q-toggle
                      label="Address"
                      :model-value="showAddressInList"
                      @update:model-value="toggleAddressInList"
                    />
                  </div>
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MappedAssetsListFiltersButton from 'components/map/MappedAssetsListFiltersButton.vue';

export default {
  props: {
    headerHeight: Number,
  },
  components: {
    MappedAssetsListFiltersButton,
  },
  computed: {
    ...mapGetters('assets', ['mappableAssets', 'visibleAssetsInBounds']),
    ...mapGetters('assets', ['tags']),
    ...mapState('filtering', ['filterOutOfBounds', 'filters', 'filterShared', 'searchTerms']),
    ...mapState('map', ['showDriverNameInList', 'showAddressInList', 'showNicknameInList']),
    ...mapState('vehicles', ['vehiclesLoading']),
    mappedCount() {
      return this.visibleAssetsInBounds.length;
    },
    totalCount() {
      return this.mappableAssets.length;
    },
  },
  methods: {
    ...mapActions('filtering', ['resetAll']),
    ...mapActions('map', ['toggleDriverNameInList', 'toggleAddressInList', 'toggleNicknameInList']),
  },
};
</script>

<style lang="scss" scoped>
.heading {
  border-bottom: 1px solid $grey-5;
}

.mapped-count {
  margin-left: 12px;
}

.car-icon {
  margin: 0 12px 0 6px;
  font-size: 1.5rem;
}

:deep(.filtered-by span:hover) {
  color: $grey-9;
  cursor: help;
}
</style>
