import GeoJsonSource from 'src/services/geoJson/GeoJsonSource';

export default class VehicleDetailsSource extends GeoJsonSource {
  /** @var {String} id */
  static id = 'vehicle-details-source';

  /** @var {import('src/models/Vehicle').default} vehicle */
  vehicle = null;

  /**
   * @returns {Object} A GeoJson source config object.
   */
  getConfig() {
    return Object.freeze({
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [this.vehicle.toGeoJson()] },
    });
  }

  /**
   * Sets the vehicle to be used as a data point in the source configuration.
   *
   * @param {import('src/models/Vehicle').default} vehicle
   */
  setVehicle(vehicle) {
    this.vehicle = vehicle;
  }
}
