<template>
  <MainNavigationItem :disable="this.connectedApps === null" label="Smart Maintenance" @click="navigate">
    <template #icon>
      <q-icon class="q-mr-sm" size="1.5rem"> <EngineeringSvg /> </q-icon>
    </template>
  </MainNavigationItem>
</template>

<script>
import { openURL } from 'quasar';
import { mapActions, mapGetters, mapState } from 'vuex';
import MainNavigationItem from 'components/MainNavigationItem.vue';
import EngineeringSvg from 'src/assets/engineering-24px.svg';
import { trackEvent } from 'src/services/intercom';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  components: {
    EngineeringSvg,
    MainNavigationItem,
  },
  computed: {
    ...mapGetters('session', ['hasPermission']),
    ...mapState('env', ['carAdviseLogin']),
    ...mapState('apps', ['connectedApps']),
    hasConnectedSmartMaintenance() {
      return (this.connectedApps || []).find(({ name }) => name === 'Zubie Smart Maintenance');
    },
  },
  methods: {
    ...mapActions('apps', ['getConnectedApps']),
    async navigate() {
      trackEvent('smart_maintenance_clicked');

      if (this.hasPermission('smartMaintenanceView')) {
        if (this.hasConnectedSmartMaintenance) {
          openURL('http://zubie.fleetadvise.com/dashboard');
        } else {
          openURL(this.carAdviseLogin);
          await setTimeoutPromise(10000);
          this.getConnectedApps();
        }
      } else {
        openURL('https://zubie.com/zubie-fleet-connect/zubie-smart-maintenance/');
      }
    },
  },
};
</script>
