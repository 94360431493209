/* eslint class-methods-use-this:0 */
import { isFullscreen } from 'src/services/fullscreen';
import GeoJsonLayer from 'src/services/geoJson/GeoJsonLayer';
import AssetsSource from 'src/services/geoJson/sources/AssetsSource';

export default class AssetsBaseLayer extends GeoJsonLayer {
  /**
   * @returns {Object} A GeoJson layer config object.
   */
  static getConfig() {
    let iconSizeRatio = 1;

    if (isFullscreen()) {
      iconSizeRatio = 3;
    }

    return Object.freeze({
      type: 'symbol',
      source: AssetsSource.id,
      filter: ['all', ['!=', 'selected', true], ['!has', 'point_count']],
      layout: {
        'text-optional': true,
        'symbol-avoid-edges': true,
        'icon-allow-overlap': true,
        'icon-anchor': ['match', ['get', 'motionStatus'], ['idle', 'moving', 'movingNoHeading'], 'center', 'bottom'],
        'icon-ignore-placement': true,
        'icon-padding': 0,
        'icon-rotate': ['case', ['to-boolean', ['get', 'heading']], ['get', 'heading'], 0],
        'icon-size': ['interpolate', ['linear'], ['zoom'], 5, 0.3 * iconSizeRatio, 13, 0.5 * iconSizeRatio],
      },
    });
  }
}
