<template>
  <ZubieDialog title="Please Verify VIN" :visible="visible" @submit="onVerify">
    <p>Please verify that the following text is accurate and make any corrections if necessary.</p>
    <q-input v-model="text" filled label="VIN" />
  </ZubieDialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import ZubieDialog from 'components/ZubieDialog.vue';
import { setTimeoutPromise } from 'src/services/setTimeout';

const props = defineProps<{ vin?: string }>();

const emit = defineEmits(['ok']);

const text = ref(props.vin);

const visible = ref(true);
async function onVerify() {
  visible.value = false;
  await setTimeoutPromise(250);
  emit('ok', { text, valid: true });
}
</script>
