/** @implements {GeoLocationHandler} */
export default class WebGeolocation {
  static getCurrentPosition() {
    if (!navigator.geolocation) {
      throw new Error('Geolocation not available');
    }
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }
}
