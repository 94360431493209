<template>
  <MapboxControl custom-size tooltip="Change map style" @touch-down="showOptions = true">
    <div ref="focusHelper" tabindex="-1"></div>
    <div class="row items-center" style="height: 36px" @mouseover="showOptions = true">
      <q-img :alt="`${startCaseStyles[mapStyle]} Style`" :src="mapIcons[mapStyle]" style="width: 20px" />
      <span v-if="$q.screen.gt.xs" class="q-ml-sm text-subtitle2 roboto">{{ startCaseStyles[mapStyle] }}</span>
    </div>
    <q-menu
      ref="menu"
      anchor="top right"
      content-style="margin-top: -1px !important; margin-left: 6px !important;"
      cover
      :model-value="showOptions"
      no-parent-event
      self="top right"
      transition-hide="jump-up"
      transition-show="jump-down"
      @hide="onHide"
      @show="onShow"
    >
      <q-list @mouseout="onMouseOut" @mouseover="clearLeaveTimeout">
        <q-item
          v-for="style in mapStyles"
          :key="style"
          class="option q-py-sm"
          :clickable="clickReady"
          @click="onSelectStyle($event, style, !$q.screen.gt.sm)"
        >
          <q-item-section>
            <div class="row items-center">
              <q-img :alt="startCaseStyles[style]" class="q-mr-sm" :src="mapIcons[style]" style="width: 20px" />
              <span class="text-subtitle2 text-weight-regular">{{ startCaseStyles[style] }}</span>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </MapboxControl>
</template>

<script>
import _startCase from 'lodash/startCase';
import MapboxControl from 'components/map/MapboxControl.vue';
import { mapIcons, mapStyles } from 'src/services/constants';
import { setTimeoutPromise } from 'src/services/setTimeout';

export default {
  name: 'MapStyleControl',
  props: {
    mapStyle: String,
  },
  components: {
    MapboxControl,
  },
  emits: ['set-map-style'],
  computed: {
    mapIcons() {
      return mapIcons;
    },
    mapStyles() {
      return Object.keys(mapStyles);
    },
    startCaseStyles() {
      const startCaseStyles = {};
      this.mapStyles.forEach((style) => {
        startCaseStyles[style] = _startCase(style.toLowerCase());
      });
      return startCaseStyles;
    },
  },
  data() {
    return {
      clickReady: false,
      leaveTimeout: null,
      showOptions: false,
    };
  },
  methods: {
    clearLeaveTimeout() {
      if (this.leaveTimeout && !this.leaveTimeout.isCanceled) {
        this.leaveTimeout.cancel();
      }
      this.leaveTimeout = null;
    },
    onHide() {
      this.clearLeaveTimeout();
      this.showOptions = false;
      this.clickReady = false;
    },
    async onMouseOut() {
      try {
        this.leaveTimeout = setTimeoutPromise(500);
        await this.leaveTimeout;
        this.showOptions = false;
      } catch {
        // do nothing
      }
    },
    async onSelectStyle(_, style, closeMenu) {
      this.$emit('set-map-style', style);

      if (closeMenu) {
        // brief delay to show selection highlight
        await setTimeoutPromise(150);
        this.showOptions = false;
      }
    },
    async onShow() {
      if (this.$q.platform.is.capacitor) {
        // Wait to prevent click from triggering immediately on a style
        await setTimeoutPromise(500);
      }
      this.clickReady = true;
    },
  },
  beforeDestory() {
    this.clearLeaveTimeout();
  },
};
</script>

<style lang="scss" scoped>
.option {
  min-height: auto;
}
</style>
