/**
 * Returns all devices that match the current filter terms.
 * - returns all devices if no terms are set
 *
 * @param {import('src/models/Device').default[]} devices
 * @param {string} terms
 */
export function devicesMatchingTerms(devices, terms) {
  if (terms.length === 0) {
    return devices;
  }

  return devices.filter(({ assetName = '', serial = '', model = '' }) => {
    const deviceProps = [assetName, serial, model].map((property) => property.toLowerCase());
    return deviceProps.some((property) => terms.some((term) => property.includes(term)));
  });
}
