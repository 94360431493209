export function setDisconnectedLocationHistory(state, { serial, lastTransmission, history }) {
  state.disconnectedLocationHistory = {
    ...state.disconnectedLocationHistory,
    [serial]: {
      lastTransmission,
      history,
    },
  };
}

export function setDevicesPromise(state, promise) {
  state.devicesPromise = promise;
}

export function setDevices(state, devices) {
  state.devices = devices;
}

export function setDevice(state, device) {
  state.devices = {
    ...state.devices,
    [device.serial]: device,
  };
}
