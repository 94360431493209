/**
 * Turns 1.200000001 into 1.2
 *
 * @param {number} num
 */
export function dropRightZeros(num) {
  // don't mess with whole numbers
  if (Number.isInteger(num)) {
    return num;
  }
  return parseFloat(num.toFixed(5));
}

/**
 * Adds a comma after the thousands place if given number is >= 1,000.
 *
 * @param {unknown} value
 * @returns {string} Number (as string) with comma or the original value
 */
export function thousandsComma(value) {
  const intValue = parseInt(value, 10);

  if (Number.isNaN(intValue) || intValue < 1000) {
    return value;
  }

  const valueString = value.toString();
  const decimalSplit = valueString.split('.');
  const integer = decimalSplit[0];
  const decimal = decimalSplit[1] ? `.${decimalSplit[1]}` : '';

  const lastThreeDigits = integer.length - 3;

  const thousandsComma = `${integer.substring(0, lastThreeDigits)},${integer.substring(lastThreeDigits)}`;

  return thousandsComma + decimal;
}

/**
 * Converts a given number to a fixed decimal (string) or '--' for non-finite inputs
 *
 * @param {number} num
 * @param {number} places
 */
export function toDecimalPlaces(num, places = 0) {
  if (Number.isFinite(num)) {
    return num.toFixed(places);
  }
  return '--';
}
