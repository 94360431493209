<template>
  <div class="absolute row justify-center full-width q-mt-sm" style="top: 0">
    <div class="absolute q-pa-md bg-negative text-white">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MapError',
  props: {
    message: String,
  },
};
</script>
