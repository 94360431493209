import getUserLocale from 'get-user-locale';
import { dropRightZeros } from 'src/services/number';
import ImperialUnitLocale from './ImperialUnitLocale';
import MetricUnitLocale from './MetricUnitLocale';

let userLocale = null;

/**
 * Initializes the user's locale.
 */
export default function init() {
  userLocale = getUnitLocale();
}

/**
 * Converts degrees celsius to fahrenheit
 *
 * @param {number} degreesC
 */
export function celsiusToFahreheit(degreesC) {
  return (degreesC * 9) / 5 + 32;
}

/**
 * Converts degrees fahrenheit to celsius
 *
 * @param {number} degreesF
 */
export function fahreheitToCelsius(degreesF) {
  return ((degreesF - 32) * 5) / 9;
}

/**
 * Converts the given fuel economy to the appropriate value + units based on the current locale.
 *
 * @param {number} economy
 * @param {string} fromUnit
 */
export function fuelEconomyLocalized(economy, fromUnit) {
  const roundedEconomy = convertFuelEconomy(economy, fromUnit);
  return roundedEconomy ? `${roundedEconomy.toFixed(1)} ${getFuelEconomyUnits()}` : '';
}

/**
 * Returns the determined user locale.
 *
 * @returns {String}
 */
export function getUserLocaleValue() {
  return userLocale;
}

/**
 * Take a distance in miles/km as input
 * Return in feet/meters for distances less than a 0.1 miles/km; else in miles/km
 * TODO: write tests for this
 */
export function humanizedDistanceUnits(num) {
  const trimmedValue = dropRightZeros(num);
  const largeUnit = getDistanceUnits();
  const smallUnit = largeUnit === 'km' ? 'm' : 'ft';
  const isMetric = largeUnit === 'km';
  if (trimmedValue >= 0.1) {
    return `${trimmedValue} ${largeUnit}`;
  }
  if (trimmedValue) {
    const smallUnitValue = isMetric ? getMeters(trimmedValue) : getFeet(trimmedValue);
    return `${smallUnitValue} ${smallUnit}`;
  }
  return '--';

  function getFeet(miles) {
    return Math.round(miles * 5280);
  }

  function getMeters(km) {
    return Math.round(km * 1000);
  }
}

/**
 * Returns a string with a language-sensitive representation of the given number.
 *
 * @param {number} value
 */
export function localeString(value) {
  return Number(value).toLocaleString();
}

/**
 * Converts the given meters to miles.
 *
 * @param {number} meters
 */
export function metersToMiles(meters) {
  return meters * 0.000621371192;
}

/**
 * Converts the given miles to km.
 *
 * @param {number} miles
 */
export function milesToKm(miles = 0) {
  return MetricUnitLocale.miToKm(miles);
}

/**
 * Converts the given km to miles.
 *
 * @param {number} km
 */
export function kmToMiles(km = 0) {
  return ImperialUnitLocale.kmToMiles(km);
}

/**
 * Returns the determined user locale.
 *
 * @returns {String}
 */
export function setUserLocaleValue(locale) {
  userLocale = locale;
}

/**
 * Converts the given value to the appropriate value based on the current locale.
 *
 * @param {Number} value
 * @param {String} fromUnit
 * @returns {Number}
 */
export function convertDistance(value, fromUnit) {
  return userLocale.convertDistance(value, fromUnit);
}

/**
 * Converts the given fuel economy to the appropriate value based on the current locale.
 *
 * @param {Number} economy
 * @param {String} fromUnit
 * @returns {Number}
 */
export function convertFuelEconomy(economy, fromUnit) {
  return Number(userLocale.convertFuelEconomy(economy, fromUnit));
}

/**
 * Converts the given velocity to the appropriate value based on the current locale.
 *
 * @param {Number} velocity
 * @param {String} fromUnit
 * @returns {Number}
 */
export function convertVelocity(velocity, fromUnit) {
  return userLocale.convertVelocity(velocity, fromUnit);
}

/**
 * Returns the appropriate unit of measurement based on the current locale.
 *
 * @param {Number} value
 * @returns {String}
 */
export function getDistanceUnits(value, long) {
  return userLocale.getDistanceUnits(value, long);
}

/**
 * Converts the given distance to the distance appropriate for the given locale.
 *
 * @param {number} distance
 * @param {string} fromUnit
 * @param {boolean} long
 */
export function distanceLocalized(distance, fromUnit, long = false) {
  const roundedDistance = Math.round(convertDistance(distance, fromUnit));
  return roundedDistance ? `${numberFormat(roundedDistance)} ${getDistanceUnits(roundedDistance, long)}` : '';
}

/**
 * Returns the appropriate unit for fuel economy based on the current locale.
 *
 * @returns {String}
 */
export function getFuelEconomyUnits() {
  return userLocale.FUEL_ECONOMY_UNITS;
}

/**
 * Returns the appropriate unit of measurement based on the current locale.
 *
 * @returns {String}
 */
export function getVelocityUnits() {
  return userLocale.VELOCITY_UNITS;
}

/**
 * Returns the UnitLocale associated with the current locale.
 *
 * @returns {import('./UnitLocale').default}
 */
export function getUnitLocale() {
  switch (getUserLocale()) {
    case 'en-US':
    case 'en-LR':
    case 'my-MM':
      return new ImperialUnitLocale();
    default:
      return new MetricUnitLocale();
  }
}

/**
 * Returns the locale string for the user
 *
 * @returns {String}
 */
export function getUserLocaleString() {
  return getUserLocale();
}

/**
 * Formats the given number based on Intl.NumberFormat.
 *
 * @param {number} value
 */
export function numberFormat(value) {
  const numberFormat = new Intl.NumberFormat();
  return numberFormat.format(value);
}

/**
 * Converts the given velocity to the appropriate value + units based on the current locale.
 *
 * @param {number} velocity
 * @param {string} fromUnit
 */
export function speedLocalized(velocity, fromUnit) {
  const roundedVelocity = Math.round(convertVelocity(velocity, fromUnit));
  return roundedVelocity ? `${roundedVelocity} ${getVelocityUnits()}` : '';
}
