<template>
  <q-item :class="itemClasses" clickable @click="onClick">
    <MappedItem
      ref="content"
      :asset="asset"
      :is-expanded="isSelectedAsset && $q.screen.gt.sm"
      :searchTerms="searchTerms"
      :show-address="showAddressInList"
      :show-driver="showDriverNameInList"
      :show-name="showNicknameInList"
      @resize-finished="$emit('resize-finished')"
    />
  </q-item>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MappedItem from 'components/map/MappedItem.vue';
import Asset from 'src/models/Asset';
import Vehicle from 'src/models/Vehicle';

export default {
  props: {
    asset: [Vehicle, Asset],
    searchTerms: Array,
  },
  components: {
    MappedItem,
  },
  data() {
    return {
      wasSelected: false,
    };
  },
  computed: {
    ...mapState('map', ['showDriverNameInList', 'showAddressInList', 'showNicknameInList']),
    ...mapGetters('assets', ['selectedAsset']),
    isSelectedAsset() {
      return this.asset.key === this.selectedAsset.key;
    },
    itemClasses() {
      const classes = ['asset', 'column'];

      if (this.isSelectedAsset) {
        classes.push('selected');
      }

      if (this.asset.share || this.asset.hasDashcam) {
        classes.push('has-icons');
      }

      return classes;
    },
  },
  methods: {
    ...mapActions('assets', ['toggleSelectAsset']),
    onClick() {
      if (this.$q.screen.gt.sm || !this.isSelectedAsset) {
        this.toggleSelectAsset(this.asset.key);
      }
      this.$emit('select', this.asset);
    },
  },
};
</script>

<style lang="scss" scoped>
.asset {
  padding: 8px 8px 12px;
  border-bottom: 1px solid $grey-5;
  background: white;
}

.selected {
  position: relative;
  z-index: 1;
  box-shadow: 0px 0 10px #888;
}
</style>

<style lang="scss">
.ios-notch-left .asset {
  @media (min-width: $breakpoint-sm) {
    padding-left: env(safe-area-inset-left);
  }
}
</style>
