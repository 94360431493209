<template>
  <rc-live-preview-viewer
    ref="player"
    :activecamera="camera"
    :apidomain="ravenApiHost"
    :audiosupported="isAudioSupported"
    class="col"
    :ravenid="serialNumber"
    :sessiontoken="token"
  />
</template>

<script>
import { mapState } from 'vuex';
import { dayjs } from 'src/services/date';
import { trackEvent, trackEventByApi } from 'src/services/intercom';

export default {
  name: 'DashcamLivePreview',
  props: {
    camera: {
      type: String,
      default: 'road',
    },
    serialNumber: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      token: '',
    };
  },
  computed: {
    ...mapState('app', ['broker']),
    ...mapState('env', ['ravenApiHost']),
    isAudioSupported() {
      // always enable for now; may vary by device config later
      return true;
    },
  },
  methods: {
    async getData(event) {
      const isInitialization = event === true;
      const isForbiddenEvent = event?.detail?.subtype === 'forbidden';
      const isSessionExpiredEvent = event?.type === 'sessionExpired';
      if (!isInitialization && !isForbiddenEvent && !isSessionExpiredEvent) {
        return;
      }

      const response = await this.broker.fetchAndTransform({
        fn: 'getDashcamToken',
        params: {
          serialNumber: this.serialNumber,
          videoType: 'live',
        },
      });

      if (response.error) {
        return;
      }

      const {
        dashcam: {
          raven: { accessToken },
        },
      } = response;
      this.token = accessToken;
    },
  },
  async mounted() {
    this.$emit('mounted');
    this.getData(true);
    await this.$nextTick();
    const { player } = this.$refs;
    player.addEventListener('sessionExpired', this.getData);
    player.addEventListener('authError', this.getData);
    trackEvent('raven_live_preview_viewed');
    this.mountedTimestamp = dayjs();
  },
  beforeDestroy() {
    const { player } = this.$refs;
    if (player) {
      player.removeEventListener('sessionExpired', this.getData);
      player.removeEventListener('authError', this.getData);
    }
    const seconds = dayjs().diff(this.mountedTimestamp, 's');
    trackEventByApi('raven_live_preview_seconds', {
      seconds,
    });
  },
};
</script>

<style lang="scss">
rc-live-preview-viewer {
  // --font-family: cursive;
  // --primary-font-color: green;
  --border-radius: 4px;
  --app-background-color: $grey-5;
  // --message-overlay-background-color: purple;
  // --media-control-background-color: red;
  // --media-control-active-background-color: orange;
  --media-control-border-radius: 50%;
}
</style>
