import { GL300_REGEX, GL500_REGEX, SIMULATED_GL_REGEX, XIRGO_REGEX } from 'src/services/constants.js';

export const local = {
  /** @type {onIntercomUpdateCallback[]} */
  onIntercomUpdateCallbacks: [],
  /** @type {Array<() => Promise<void>>} */
  onIntercomLogoutCallbacks: [],
  /** @type {import('src/types/store').RootStore} */
  store: null,
};

/**
 * @param {import('src/types/store').RootStore} store
 */
export function init(store) {
  local.store = store;
}

/**
 * @param {() => void} callback
 */
export function onIntercomLogout(callback) {
  if (!local.onIntercomLogoutCallbacks.includes(callback)) {
    local.onIntercomLogoutCallbacks.push(callback);
  }
}

/**
 * @param {import('src/types/intercom').onIntercomUpdateCallback} callback
 */
export function onIntercomUpdate(callback) {
  if (!local.onIntercomUpdateCallbacks.includes(callback)) {
    local.onIntercomUpdateCallbacks.push(callback);
  }
}

export function logout() {
  if (local.onIntercomLogoutCallbacks.length) {
    return Promise.all(local.onIntercomLogoutCallbacks.map((callback) => callback()));
  }
  return Promise.resolve();
}

/**
 * Logs an event (with optional metadata) to Intercom.
 *
 * @param {String} name
 * @param {Object} metaData
 */
export function trackEvent(name, metaData) {
  const { id: accountId } = local.store.getters?.['session/account'] || {};
  const { churnzeroAppKey: churnZeroAppKey } = local.store.state?.env || {};
  const { email } = local.store.getters?.['session/currentUser'] || {};
  let churnZeroMetaData = {};
  if (!local.store.getters?.['session/isConsumer']) {
    churnZeroMetaData = {
      account_external_id: accountId,
      app_key: churnZeroAppKey,
      contact_external_id: email,
    };
  }
  return window.Intercom && window.Intercom('trackEvent', name, { ...churnZeroMetaData, ...metaData });
}

/**
 * Updates user data in Intercom.
 *
 * @param {{ [key: string]: unknown }} data
 */
export async function update(data) {
  if (local.onIntercomUpdateCallbacks.length) {
    await Promise.all(local.onIntercomUpdateCallbacks.map((callback) => callback(data)));
  }
  return window.Intercom && window.Intercom('update', data);
}

/**
 * Generates counts of various types of devices in the account and updates Intercom
 *
 * @param {Object} data
 */
export async function updateDeviceCounts({ vehicles = [], assets = [] }) {
  const vehicleDevices = new Set();
  const assetDevices = new Set();
  const counts = {
    gl300: 0,
    gl500: 0,
    queclink: 0,
    raven: 0,
    xirgo: 0,
    unknown: [],
  };
  vehicles.forEach(({ devices = [] }) => {
    devices.forEach(({ serial }) => {
      if (serial && !vehicleDevices.has(serial)) {
        vehicleDevices.add(serial);
        if (GL300_REGEX.test(serial)) {
          counts.gl300 += 1;
        } else if (GL500_REGEX.test(serial)) {
          counts.gl500 += 1;
        } else if (serial.toLowerCase().startsWith('q')) {
          counts.queclink += 1;
        } else if (serial.toLowerCase().startsWith('1rv') || serial.toLowerCase().startsWith('2rv')) {
          counts.raven += 1;
        } else if (XIRGO_REGEX.test(serial)) {
          counts.xirgo += 1;
        } else if (!SIMULATED_GL_REGEX.test(serial)) {
          counts.unknown.push(serial);
        }
      }
    });
  });
  assets.forEach(({ devices = [] }) => {
    devices.forEach(({ serial }) => {
      if (serial && !assetDevices.has(serial)) {
        assetDevices.add(serial);
      }
    });
  });
  counts.assettrak = assetDevices.size;
  const payload = {
    account_assets: assets.length,
    account_devices: vehicleDevices.size + assetDevices.size,
    account_vehicles: vehicles.length,
    asset_track_devices: assetDevices.size,
    gl300_devices: counts.gl300,
    gl500_devices: counts.gl500,
    queclink_vehicle_devices: counts.queclink,
    raven_devices: counts.raven,
    xirgo_devices: counts.xirgo,
  };

  await update(payload);

  trackEvent('session-start');
}

/**
 * Logs an event (with optional metadata) to Intercom via the Contacts API.
 *
 * @param {String} name
 * @param {Object} metaData
 */
export function trackEventByApi(name, metaData) {
  const currentUser = local.store.getters['session/currentUser'];

  local.store.state.app.apiWorker.logEvent({
    data: metaData,
    event: name,
    type: 'intercom',
    userKey: currentUser.key,
  });
}
