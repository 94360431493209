import SimpleModel from './SimpleModel';

class Address extends SimpleModel {
  /** @var {String} */
  city = '';

  /** @var {Number} */
  latitude = null;

  /** @var {Number} */
  longitude = null;

  /** @var {String} */
  state = '';

  /** @var {String} */
  street = '';

  /** @var {String} */
  zipCode = '';

  constructor(data = {}) {
    super();
    const preparedData = {
      ...data,
    };

    // Handle empty addresses and unknown address (which happen)
    if (data?.street) {
      if (data.street.trim() === '' || data.street.toLowerCase().includes('address unassigned')) {
        preparedData.street = '';
      }
    }

    // Convert "zipcode" prop to "zipCode"
    if (data?.zipcode) {
      preparedData.zipCode = data.zipcode;
    }

    this.insert(preparedData);
  }

  /**
   * Returns a comma prefix if both street and city exist, or an empty string.
   *
   * @returns {String}
   */
  get cityPrefix() {
    const { street, city } = this;
    return street && city ? ', ' : '';
  }

  /**
   * Returns the lat/long as [long, lat] coordinates.
   *
   * @returns {Number[]}
   */
  get coordinates() {
    return [this.longitude, this.latitude];
  }

  /**
   * Returns a comma prefix if state and either street/city exist, or an empty string.
   *
   * @returns {String}
   */
  get statePrefix() {
    const { street, city, state } = this;
    return state && (street || city) ? ', ' : '';
  }

  /**
   * Returns a space prefix if zip code and either street/city/state exist, or an empty string.
   *
   * @returns {String}
   */
  get zipCodePrefix() {
    const { street, city, state, zipCode } = this;
    return zipCode && (street || city || state) ? ' ' : '';
  }

  /**
   * Generates a punctuated representation of line 2 of the address (i.e. city, state, zip)
   *
   * @returns {String}
   */
  get line2() {
    const { city = '', state = '', zipCode = '' } = this;
    return `${city}${this.statePrefix}${state}${this.zipCodePrefix}${zipCode}`;
  }

  /**
   * Generates a punctuated representation of the address as a string.
   *
   * @returns {String}
   */
  toString() {
    const { street = '', city = '', state = '', zipCode = '' } = this;
    return `${street}${this.cityPrefix}${city}${this.statePrefix}${state}${this.zipCodePrefix}${zipCode}`;
  }
}

export default Address;
