import chain from 'src/services/chain';

/**
 * Returns the followed vehicle (based on followed vehicle key).
 *
 * @param {Object} state
 * @returns {Vehicle}
 */
export function followedVehicle(state) {
  return state.vehicles[state.followedVehicleKey];
}

/**
 * Returns a function that retrieves the vehicle that matches the given key when called.
 *
 * @param {Object} state
 * @returns {Vehicle}
 */
export function detailedVehicle(state) {
  return (vehicleKey) => state.detailedVehicles[vehicleKey];
}

/**
 * Returns all groups associated with vehicles.
 *
 * @param {Object} state
 * @returns {Object[]}
 */
export function groups(state) {
  return chain(state.vehicles)
    .filter((vehicle) => vehicle.groups)
    .map('groups')
    .flatten()
    .filter(({ name }) => name)
    .uniqBy('name')
    .sortBy('name')
    .value();
}
