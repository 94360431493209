import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _uniq from 'lodash/uniq';
import { getDistanceUnits, humanizedDistanceUnits } from 'src/services/locale/index';
import { dropRightZeros } from 'src/services/number';
import SimpleModel from './SimpleModel';

// TODO: write tests for all this
class Place extends SimpleModel {
  /** @type {number[]} */
  activeWeekdays = [];

  /** @type {string} */
  address = '';

  /** @type {string} */
  category = '';

  /** @type {string} */
  fromTime = '';

  /** @type {import('@turf/helpers').Geometry | null} */
  geometry = null;

  /** @type {boolean} */
  isVisible = true;

  /** @type {string} */
  key = '';

  /** @type {number | null} */
  latitude = null;

  /** @type {number | null} */
  longitude = null;

  /** @type {string} */
  name = '';

  /** @type {boolean | null} */
  notifyArrival = null;

  /** @type {boolean | null} */
  notifyLeaving = null;

  /** @type {string} */
  radiusUm = 'mi';

  /** @type {number | null} */
  radius = null;

  /** @type {string[] | null} */
  tags = null;

  /** @type {string} */
  toTime = '';

  constructor(data = {}) {
    super();
    this.insert({
      ...data,
      isVisible: _isNil(data.isVisible) ? true : data.isVisible,
    });
  }

  get center() {
    return `${this.latitude.toFixed(5)}, ${this.longitude.toFixed(5)}`;
  }

  get isNew() {
    return Boolean(this.key) === false;
  }

  get type() {
    return _get(this, 'geometry.properties.geofenceType');
  }

  set type(type) {
    this.geometry.properties.geofenceType = type;
  }

  get isCircle() {
    return this.type === 'Circle';
  }

  get isPolygon() {
    return this.type === 'Polygon';
  }

  get fromTime12Hour() {
    return getFormatted12HourTime(this.fromTime);
  }

  set fromTime12Hour(time) {
    this.fromTime = getTimeWithoutMeridem(time);
  }

  get toTime12Hour() {
    return getFormatted12HourTime(this.toTime);
  }

  set toTime12Hour(time) {
    this.toTime = getTimeWithoutMeridem(time);
  }

  // undo transformations from PlaceTransformer
  getPostBody() {
    const data = {
      active_weekdays: convertActiveWeekdays(this.activeWeekdays),
      address: this.address,
      category: this.category,
      from_time: this.fromTime,
      geometry: covertGeoJson(this),
      name: this.name,
      notify_arrival: this.notifyArrival,
      notify_leaving: this.notifyLeaving,
      to_time: this.toTime,
    };
    if (this.isNew === false) {
      data.key = this.key;
    }
    if (this.isCircle) {
      data.radius = this.radius;
      data.radius_um = this.radiusUm;
    }
    return data;

    function convertActiveWeekdays(daysArray) {
      const validDays = daysArray.filter((day) => day >= 0 && day < 7);
      if (validDays.length === 0) {
        return '-1';
      }
      return validDays.join(',');
    }

    function covertGeoJson(place) {
      if (_get(place, 'geometry.properties.geofenceType') === 'Circle') {
        return {
          type: 'Point',
          coordinates: [place.longitude, place.latitude],
        };
      }
      return _get(place, 'geometry.geometry', {});
    }
  }

  get humanizedActiveWeekdays() {
    return Place.getHumanizedDaysOfWeek(this.activeWeekdays);
  }

  static getNewPlaceDefaults() {
    return new Place({
      activeWeekdays: [0, 1, 2, 3, 4, 5, 6],
      address: '',
      category: 'other',
      fromTime: '00:00',
      geometry: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [],
        },
        properties: {
          geofenceType: 'Circle',
        },
      },
      latitude: null,
      longitude: null,
      name: '',
      notifyArrival: true,
      notifyLeaving: true,
      radius: 0.1,
      radiusUm: 'mi',
      toTime: '00:00',
    });
  }

  /**
   * Generates radius options
   *
   * @param {number} radius
   * @returns
   */
  static getRadiusOptions(radius) {
    const isMetric = getDistanceUnits() === 'km';

    const ONE_HUNDRED_FEET_IN_MILES = 0.01893939394;
    const TWO_HUNDRED_FIFTY_FEET_IN_MILES = 0.04734848485;
    const KILOMETERS_PER_MILE = 1.609344;

    const oneHundredFt = isMetric ? ONE_HUNDRED_FEET_IN_MILES * KILOMETERS_PER_MILE : ONE_HUNDRED_FEET_IN_MILES;
    const twoFiftyFt = isMetric
      ? TWO_HUNDRED_FIFTY_FEET_IN_MILES * KILOMETERS_PER_MILE
      : TWO_HUNDRED_FIFTY_FEET_IN_MILES;

    const values = [dropRightZeros(radius || 0), oneHundredFt, twoFiftyFt];

    let option = 0.1;
    const twentyFiveMi = isMetric ? 40 : 25;
    const max = isMetric ? 243 : 151;
    while (option < max) {
      if (option < 1.0) {
        values.push(parseFloat(option.toFixed(1)));
        option = parseFloat((option + 0.1).toFixed(1));
      } else if (option < twentyFiveMi) {
        values.push(parseFloat(option.toFixed(0)));
        option += 1;
      } else {
        values.push(parseFloat(option.toFixed(0)));
        option += 25;
      }
    }

    // Remove duplicates & sort from least to greatest
    const uniqueValues = _uniq(values);
    uniqueValues.sort((a, b) => (Number(a) < Number(b) ? -1 : 1));

    return uniqueValues.map((value) => ({
      label: humanizedDistanceUnits(value),
      value,
    }));
  }

  /**
   * Converts the given set of numerical weekday values into a human readable representation.
   *
   * @param {Array<0 | 1 | 2 | 3 | 4 | 5 | 6>} daysOfWeek
   */
  static getHumanizedDaysOfWeek(daysOfWeek = []) {
    // clean up -1 values, which is a magic number meant to set the length to 0
    const days = daysOfWeek.filter((day) => day >= 0);

    if (days.length === 7) {
      return 'Every day';
    }

    if (days.length === 0) {
      return 'None';
    }

    return days
      .map((dayInteger) => {
        switch (dayInteger) {
          case 0:
            return 'M';
          case 1:
            return 'T';
          case 2:
            return 'W';
          case 3:
            return 'Th';
          case 4:
            return 'F';
          case 5:
            return 'Sa';
          case 6:
            return 'Su';
          default:
            return '';
        }
      })
      .join('/');
  }

  /**
   * Generates default polygon coordinates for a place.
   *
   * @param {number} longitude
   * @param {number} latitude
   */
  static getDefaultPolygonCoordinates(longitude = 0, latitude = 0) {
    const offsets = [
      [0.0025, 0.00125],
      [-0.0025, 0.00125],
      [0, -0.0018],
    ];
    // first and last points should match
    offsets.push(offsets[0]);
    return offsets.map(([offsetLng, offsetLat]) => [longitude + offsetLng, latitude + offsetLat]);
  }
}

export default Place;

function getMeridiem(time) {
  const hours = time.split(':')[0];
  return Number(hours) < 12 ? 'AM' : 'PM';
}

function getTimeWithoutMeridem(time) {
  const [hoursText = '00', minutesText = '00'] = time.replace(/ [AP]M/i, '').split(':');
  let hours = Number(hoursText);
  if (time.includes('PM')) {
    hours += 12;
  }
  const formattedHours = hours > 10 ? hours : `0${hours}`;
  return `${formattedHours}:${minutesText}`;
}

function getFormatted12HourTime(time) {
  const [h = '00', m = '00'] = time.split(':');
  const meridiem = getMeridiem(time);
  const hNum = Number(h);
  let hours = hNum;
  if (hNum > 12) {
    hours -= 12;
  }
  const hoursFormatted = hours < 10 ? `0${hours - 12}` : `${hours}`;
  return `${hoursFormatted}:${m} ${meridiem}`;
}
