<template>
  <div class="row">
    <div class="media-container column col" :class="{ 'fallback row': $q.platform.is.ios || $q.platform.is.safari }">
      <DashcamLivePreview v-if="show" :serial-number="asset.firstConnectedDashcam.serial" @mounted="$emit('mounted')" />
      <DashcamPlaceholder
        v-else
        :class="{ col: $q.platform.is.ios || $q.platform.is.safari }"
        :imageUrl="imageUrl"
        @click="onClick"
        @resize="$emit('resize')"
      />
    </div>
  </div>
</template>

<script>
import DashcamLivePreview from 'components/dashcam/DashcamLivePreview.vue';
import DashcamPlaceholder from 'components/dashcam/DashcamPlaceholder.vue';

export default {
  name: 'DashcamLivePreviewWithPlaceholder',
  props: ['asset', 'imageUrl'],
  components: {
    DashcamLivePreview,
    DashcamPlaceholder,
  },
  data() {
    return {
      serialNumber: '',
      show: false,
    };
  },
  methods: {
    onClick() {
      this.show = true;
    },
  },
  watch: {
    asset() {
      const { serialNumber: existingSerial } = this;
      const { serial } = this?.asset?.firstConnectedDashcam || {};
      if (this.show && serial && serial !== existingSerial) {
        this.serialNumber = serial;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.media-container {
  aspect-ratio: 16/9;
}

.media-container.fallback {
  flex-direction: row !important;
}

.media-container.fallback::before {
  content: '';
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: calc(9 / 16 * 100%);
}

.media-container.fallback::after {
  content: '';
  display: table;
  clear: both;
}
</style>
