<template>
  <div class="absolute z-index-2">
    <q-badge
      class="notification-badge"
      color="atomic-tangerine"
      :style="{ height: size, width: size, minHeight: size }"
    ></q-badge>
  </div>
</template>

<script>
export default {
  name: 'NotificationsBadge',
  props: {
    size: {
      type: String,
      default: '9px',
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-badge {
  position: relative;
  z-index: 2;
  border: 1px solid white;
  border-radius: 50%;
  padding: 0;
}
</style>
