import SelectedAssetSource from 'src/services/geoJson/sources/SelectedAssetSource';
import AssetsBaseLayer from './AssetsBaseLayer';

export default class SelectedAssetByTagLayer extends AssetsBaseLayer {
  /** @var {String} id */
  static id = 'selected-asset-by-tag';

  /**
   * @returns {Object} A GeoJson layer config object.
   */
  getConfig() {
    const parentConfig = super.constructor.getConfig();

    return Object.freeze({
      id: this.getId(),
      ...parentConfig,
      source: SelectedAssetSource.id,
      layout: {
        ...parentConfig.layout,
        'icon-image': '{icon}-{tagColor}',
        'icon-size': ['interpolate', ['linear'], ['zoom'], 3, 0.6, 17, 1],
      },
    });
  }
}
