<template>
  <q-table
    class="text-grey-9"
    :columns="[
      {
        name: 'address',
        label: 'Location',
        align: 'left',
        field: 'address',
      },
      { name: 'timestamp', label: 'Date/Time', align: 'right', field: 'timestamp' },
    ]"
    flat
    hide-bottom
    hide-header
    row-key="name"
    :rows="locationHistory"
  >
    <template #body="props">
      <q-tr @mouseover="$emit('select', props.row)">
        <q-td
          :class="[`text-${props.colsMap.address.align}`, 'cursor-crosshair']"
          :style="{ paddingLeft: 0, whiteSpace: 'normal' }"
        >
          <AddressString
            :address="props.row.address"
            :latitude="props.row.latitude"
            :longitude="props.row.longitude"
            @load="onAddressResolved"
          />
        </q-td>
        <q-td :class="[`text-${props.colsMap.timestamp.align}`, 'cursor-crosshair']" style="padding-right: 0">
          {{ props.row.timestamp }}
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AddressString from 'components/AddressString.vue';
import Device from 'src/models/Device';
import { dayjs, utcToLocalDateTime } from 'src/services/date';

export default {
  props: {
    device: Device,
  },
  components: {
    AddressString,
  },
  computed: {
    ...mapState('devices', ['disconnectedLocationHistory']),
    locationHistory() {
      const history = this.disconnectedLocationHistory?.[this.device.serial]?.history || [];
      return history
        .filter(({ latitude, longitude }) => latitude && longitude)
        .map(({ address, latitude, longitude, timestamp }) => ({
          address,
          latitude,
          longitude,
          timestamp: utcToLocalDateTime(timestamp),
        }));
    },
  },
  methods: {
    ...mapActions('devices', ['getDisconnectedLocationHistory', 'updateDisconnectedLocationHistory']),
    /**
     * @param {import('src/models/Address').default} address
     */
    onAddressResolved(address) {
      this.updateDisconnectedLocationHistory({
        serial: this.device.serial,
        address,
      });
    },
  },
  async created() {
    const { lastTransmission, serial } = this.device;
    const existingHistoryTimestamp = this.disconnectedLocationHistory?.[serial]?.lastTransmission || null;

    // Only load new history if the last transmission timestamp is different from existing/last load
    if (existingHistoryTimestamp && dayjs(existingHistoryTimestamp).isSame(lastTransmission)) {
      return;
    }

    await this.getDisconnectedLocationHistory({ serial, lastTransmission });

    this.$emit('load');
  },
};
</script>
