/**
 * Class used to render svgs/images on a ZubieMap.
 */
class MapImage {
  /** @type {String} */
  id = null;

  /** @type {String|Image} */
  content = null;

  /** @type {Object} */
  options = {
    height: 0,
    type: 'svg+xml',
    width: 0,
  };

  /**
   * Constructor for a MapImage.
   *
   * @param {String} id
   * @param {String|Image} content
   * @param {Object} options
   * @param {Number} options.height
   * @param {String} options.type
   * @param {Number} options.width
   */
  constructor(id, content, options) {
    this.id = id;
    this.content = content;
    this.options = {
      ...this.options,
      ...options,
    };
  }

  /**
   * Returns the substring of the content (assumes it beings with "base64:").
   *
   * @returns {String}
   */
  get base64String() {
    return this.content.substring(7);
  }

  /**
   * Returns the options->height
   *
   * @returns {Number}
   */
  get height() {
    return this.options.height;
  }

  /**
   * Returns the substring of the content (assumes it beings with "img:").
   *
   * @returns {String}
   */
  get imageUrl() {
    return `/${this.content.substring(4)}`;
  }

  /**
   * Used to identify Base64 encoded images.
   *
   * @returns {Boolean} Returns true if the content of the image starts with "base64:".
   */
  get isBase64() {
    return this.content.startsWith('base64:');
  }

  /**
   * Used to identify image URLs.
   *
   * @returns {Boolean} Returns true if the content of the image starts with "img:".
   */
  get isImageUrl() {
    return this.content.startsWith('img:');
  }

  /**
   * Returns the options->width
   *
   * @returns {Number}
   */
  get width() {
    return this.options.width;
  }

  /**
   * @typedef MapImageLoadResponse
   * @prop {String} id
   * @prop {Image} image
   */

  /**
   * Loads the content (as needed) and returns a Promise that resolves with
   * the image ID and the created Image.
   *
   * @param {Mapbox} mapInstance
   * @returns {Promise<MapImageLoadResponse>}
   */
  load(mapInstance) {
    const { id, content } = this;

    return new Promise((resolve, reject) => {
      if (this.isImageUrl) {
        // Handle image URLs
        mapInstance.loadImage(this.imageUrl, (error, image) => {
          if (error) {
            reject(error);
          } else {
            resolve({ id, image });
          }
        });
      } else {
        // Handle Base64/SVG markup
        const { width, height } = this;
        const image = new Image(width, height);
        const imageData = this.isBase64 ? this.base64String : window.btoa(content);
        image.src = `data:image/${this.options.type};base64,${imageData}`;
        image.onload = () => {
          resolve({ id, image });
        };
      }
    });
  }
}

export default MapImage;
