<template>
  <!-- DO NOT REMOVE "search-result" class -->
  <q-item
    :active="isActive"
    active-class="search-result--active text-black"
    class="search-result relative bg-white q-pa-none"
    :class="{ 'search-result--current': result.isCurrentRoute }"
    :style="`height: ${height}`"
    @mouseover="$emit('hover', $event)"
  >
    <PrimarySearchButton class="z-index-1" :nav-arrow="navArrow" :result="result" @click="onClick" />
    <div v-if="secondaryAction" class="row items-center no-wrap">
      <div class="row items-center full-height">
        <SecondarySearchButton
          v-if="secondaryAction"
          :action="secondaryAction"
          :result="result"
          @click.stop="onSecondaryClick"
        />
      </div>
    </div>
  </q-item>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PrimarySearchButton from 'components/universal-search/PrimarySearchButton.vue';
import SecondarySearchButton from 'components/universal-search/SecondarySearchButton.vue';
import { trackEvent } from 'src/services/intercom';

export default {
  name: 'SearchResult',
  props: {
    height: {
      type: String,
      default: 'auto',
    },
    navArrow: Boolean,
    result: Object,
  },
  components: {
    PrimarySearchButton,
    SecondarySearchButton,
  },
  emits: ['click', 'details-click', 'hover', 'live-map-click'],
  computed: {
    ...mapState('app', ['isLiveMapRoute']),
    ...mapState('search', ['activeIndex']),
    ...mapGetters('search', ['allOptionsIndexed']),
    isActive() {
      return this.allOptionsIndexed[this.activeIndex] === this.result;
    },
    secondaryAction() {
      if (this.isLiveMapRoute) {
        if (this.result.isMappable) {
          return 'details-click';
        }
        return null;
      }
      return this.result.isMappable ? 'live-map-click' : null;
    },
  },
  methods: {
    /**
     * @param {'click' | 'details-click' | 'live-map-click'} action
     */
    onClick(action) {
      trackEvent('univ_search_result_clicked', this.result);
      this.$emit(action, this.result);
    },
    onSecondaryClick() {
      trackEvent('univ_search_secondary_result_clicked', this.result);
      this.$emit(this.secondaryAction, this.result);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.action__label) {
  font-weight: normal;
}

.search-result {
  border-bottom: 1px solid $grey-4;
  min-height: 0;

  &--active :deep(.action),
  :deep(.action:hover) {
    .q-focus-helper {
      opacity: 1 !important;
      background: $primary !important;

      &:before,
      &:after {
        display: none;
      }
    }

    .q-item__label,
    .q-item__section--avatar {
      color: white;
    }

    .inner-btn {
      color: $dark !important;

      .q-focus-helper {
        background: white !important;
      }

      .q-btn__wrapper:before {
        box-shadow: none !important;
      }
    }

    &.action--disabled {
      .q-focus-helper {
        background: $grey-2 !important;
      }

      .q-item__label {
        max-width: calc(100vw - 142px);
      }

      .q-item__label,
      .q-item__section--avatar {
        color: $dark !important;
      }
    }
  }

  :deep(.secondary-action) {
    width: 90px;
  }

  &--current {
    padding: 0;

    .action:hover,
    .action.action--disabled:hover {
      :deep(.q-focus-helper) {
        background: white !important;
      }

      :deep(.q-item__label),
      :deep(.q-item__section--avatar) {
        color: $dark !important;
      }
    }

    :deep(.secondary-action) {
      width: 86px;
    }
  }
}
</style>
