import { Notify } from 'quasar';
import { getBugsnagClient } from 'src/services/bugsnag';
import { logout } from 'src/services/logout';

const exported = {};

export const UNEXPECTED_ERROR_MESSAGE = 'An unexpected error occurred. Please contact support if it continues.';
export const UNEXPECTED_ERROR_ON_BOOT =
  'An unexpected error occurred while starting the app. Please contact support if it continues.';

/**
 * Logs the given error to the console and notifies Bugsnag of the error (with option meta info).
 *
 * @param {Error} error
 * @param {Object} meta
 * @param {Object} options
 * @param {String} options.severity
 */
exported.log = function log(error, meta = {}, options = {}) {
  console.error(error);
  getBugsnagClient().notify(error, (event) => {
    Object.keys(meta).forEach((key) => {
      event.addMetadata(key, meta[key]);
    });

    if (options.severity) {
      event.severity = options.severity;
    }
  });
};

exported.notify = function notify({ error, errorMeta = {}, logoutAction, message, onDismiss, refreshAction }) {
  let dismiss;

  if (error) {
    exported.log(error, errorMeta);
  }

  const defaultHandler = () => {
    dismiss();
  };

  const actions = [
    {
      label: 'Dismiss',
      color: 'white',
      rounded: true,
      handler: onDismiss || defaultHandler,
    },
  ];

  if (logoutAction) {
    actions.push({
      label: 'Log Out',
      color: 'white',
      rounded: true,
      handler() {
        logout();
      },
    });
  }

  if (refreshAction) {
    actions.unshift({
      label: 'Reload',
      color: 'white',
      rounded: true,
      handler() {
        window.location.reload();
      },
    });
  }

  dismiss = Notify.create({
    color: 'negative',
    closeBtn: false,
    icon: 'warning',
    message,
    timeout: 0,
    actions,
  });

  return dismiss;
};

/**
 * Notifies the user (and logs) that an unexpected error occurred.
 *
 * @param {Error} error
 * @param {Object} errorMeta
 */
exported.notifyUnexpected = function notify(error, errorMeta) {
  exported.notify({ message: UNEXPECTED_ERROR_MESSAGE, error, errorMeta });
};

/**
 * Notifies the user (and logs) that an unexpected boot error occurred.
 *
 * @param {Error} error
 * @param {Object} errorMeta
 */
exported.notifyUnexpectedBoot = function notify(error, errorMeta) {
  exported.notify({
    message: UNEXPECTED_ERROR_ON_BOOT,
    refreshAction: true,
    error,
    errorMeta,
  });
};

/**
 * Returns the "unexpected" message and logs that an error occurred.
 *
 * @param {Error} error
 * @param {Object} meta
 * @returns {String}
 */
exported.text = function text(error, meta) {
  exported.log(error, meta);
  return UNEXPECTED_ERROR_MESSAGE;
};

exported.ZError = class ZError extends Error {
  constructor(message, metadata) {
    super(message);
    this.metadata = metadata;
  }
};

export default exported;
