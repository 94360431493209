import GeoJsonLayer from 'src/services/geoJson/GeoJsonLayer';
import AssetsSource from 'src/services/geoJson/sources/AssetsSource';

export default class AssetsClusterLayer extends GeoJsonLayer {
  /** @var {String} id */
  static id = 'assets-cluster';

  /**
   * @returns {Object} A GeoJson layer config object.
   */
  getConfig() {
    return Object.freeze({
      id: this.getId(),
      type: 'circle',
      source: AssetsSource.id,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': '#fff',
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        'circle-stroke-width': 2,
        'circle-stroke-color': '#a5a5a5',
      },
    });
  }
}
