<template>
  <ZubieDialog
    content-class="full-width row items-center q-pa-none"
    :max-width="$q.screen.gt.lg ? '100%' : '1000px'"
    ok-only
    :processing="isUpdatingLocation"
    :visible="visible"
    @hide="navigateToUrl('/')"
    @show="onShow"
  >
    <template #header>
      <div class="full-width">
        <div class="text-body1">{{ isLoading ? `Finding vehicles nearest to` : `Vehicles nearest to` }}</div>
        <div class="row justify-between items-end">
          <div class="text-white text-h5">
            <AddressString
              v-if="coordinates && address != ''"
              :latitude="coordinates[1]"
              :longitude="coordinates[0]"
              @load="address = $event.toString()"
            />
            <div v-else-if="coordinates">{{ coordinatesInDMS }}</div>
          </div>
          <span v-if="lastLookupTime">
            <span v-if="!isLoading">(as of {{ lastLookupTimeDisplay }})</span>
            <q-btn
              bg-color="white"
              class="q-px-sm roboto q-ml-sm"
              color="white"
              dense
              :disable="isLoading"
              :label="isLoading ? 'Refreshing' : 'Refresh'"
              square
              text-color="primary"
              @click="findNearbyVehicles(true)"
            />
          </span>
        </div>
      </div>
    </template>

    <div
      :class="{
        row: $q.screen.gt.lg,
        column: !$q.screen.gt.lg,
        reverse: !$q.screen.gt.lg,
        'no-wrap': true,
        'full-width': true,
      }"
    >
      <NearbyVehiclesTable
        class="col-6 q-pa-md"
        :coordinates="coordinates"
        :error="drivingError"
        :highlighted-vehicle="highlightedVehicle"
        :is-loading="isLoading"
        :vehicles-list="vehiclesList"
        @highlight-vehicle="highlightedVehicle = $event"
      />
      <NearbyVehiclesMap
        :address="address"
        :bounds="bounds"
        class="col-6 q-pa-md"
        :coordinates="coordinates"
        :error="isoError"
        :height="$q.screen.gt.lg ? 600 : 420"
        :highlighted-vehicle="highlightedVehicle"
        :is-loading="isLoading"
        :iso="iso"
        :vehicles-list="vehiclesList"
        @highlight-vehicle="highlightedVehicle = $event"
        @location-change="$router.replace({ name: 'nearby', query: { location: $event.join(',') } })"
      />
    </div>
  </ZubieDialog>
</template>

<script>
import _isEqual from 'lodash/isEqual';
import { mapActions, mapGetters, mapState } from 'vuex';

import AddressString from 'components/AddressString.vue';
import NearbyVehiclesMap from 'components/map/nearby-vehicles/NearbyVehiclesMap.vue';
import NearbyVehiclesTable from 'components/map/nearby-vehicles/NearbyVehiclesTable.vue';
import navigateToUrl from 'src/mixins/navigateToUrl';
import chain from 'src/services/chain';

import { CONTOURS } from 'src/services/constants';
import { dayjs } from 'src/services/date';
import errorService from 'src/services/error';
import { trackEvent } from 'src/services/intercom';
import MapService from 'src/services/map';

const LETTER_IDS = ['A', 'B', 'C', 'D', 'E'];

export default {
  name: 'NearbyVehiclesDialog',
  mixins: [navigateToUrl],
  components: {
    AddressString,
    NearbyVehiclesMap,
    NearbyVehiclesTable,
  },
  computed: {
    ...mapState('filtering', ['filtersInitialized']),
    ...mapState('map', ['nearbyVehiclesFiltered']),
    ...mapGetters('assets', ['mappableAssets', 'visibleVehiclesWithoutTerm']),
    coordinatesInDMS() {
      return MapService.dmsCoordinates(this.coordinates);
    },
    vehiclesConsidered() {
      return this.nearbyVehiclesFiltered ? this.visibleVehiclesWithoutTerm : this.mappableAssets;
    },
  },
  data() {
    return {
      address: null,
      bounds: null,
      coordinates: null,
      destination: null,
      drivingError: null,
      highlightedVehicle: null,
      isLoading: false,
      iso: [],
      isoError: null,
      isUpdatingLocation: false,
      lastLookup: [],
      lastLookupTime: null,
      lastLookupTimeDisplay: '',
      lookupTimeInterval: null,
      vehiclesList: [],
      visible: false,
    };
  },
  methods: {
    ...mapActions('map', ['getDistancesFromLocation', 'getIsochrone', 'getTravelTimes']),
    async findNearbyVehicles(force = false) {
      if (!force && _isEqual(this.coordinates, this.lastLookup)) {
        return; // Do nothing
      }

      if (this.coordinates) {
        this.address = null; // Must clear or address may not update (if it becomes empty)
        this.lastLookup = this.coordinates;
        this.lastLookupTime = dayjs();
        this.isLoading = true;

        const [segments, distances] = await Promise.all([this.getDistanceSegments(), this.getDrivingDistance()]);

        if (segments || distances) {
          // Start a ticker to keep track of the staleness
          clearInterval(this.lookupTimeInterval);
          this.lookupTimeInterval = setInterval(() => {
            this.lastLookupTimeDisplay = dayjs(this.lastLookupTime).fromNow();
          }, 60);
        }

        this.isLoading = false;
      }
    },
    /**
     * Retrieves driving times & distances for the closest vehicles to the selected location.
     *
     * @returns {Promise<Boolean>}
     */
    async getDrivingDistance() {
      this.vehiclesList = []; // clear list of vehicles
      this.drivingError = null; // clear errors

      if (!this.vehiclesConsidered.length) {
        return false;
      }

      // Get all necessary information from vehicles listed
      const vehicles = this.vehiclesConsidered.map((vehicle) => ({
        key: vehicle.key,
        nickname: vehicle.nickname,
        driver: vehicle.driver,
        status: vehicle.status,
        statusDisplay: vehicle.statusDisplay,
        coordinates: [vehicle.location.longitude, vehicle.location.latitude],
      }));

      // Get the distance as-the-crow-flies for each vehicle to the location
      const vehicleDistances = await this.getDistancesFromLocation({
        location: this.coordinates,
        coordinates: vehicles.map(({ coordinates }) => coordinates),
      });

      // Join the distance results with vehicles, sort, and get the closest 9
      const maybeClosestVehicles = chain(vehicleDistances)
        .map((distance, index) => ({
          ...vehicles[index],
          distance,
        }))
        .sortBy('distance')
        .value()
        .slice(0, 9);

      const params = {
        location: this.coordinates,
        coordinates: maybeClosestVehicles.map(({ coordinates }) => coordinates),
      };

      let bbox;
      let coordinatesMeasured;
      try {
        const nearbyData = await this.getTravelTimes(params);
        bbox = nearbyData.bbox;
        coordinatesMeasured = nearbyData.coordinates;
      } catch (error) {
        if (error.message === 'NO_ROUTE') {
          this.drivingError = 'NO_ROUTE';
        } else {
          this.drivingError = error.message;
          errorService.log(error, {
            metaData: {
              'getTravelTimes Call': params,
            },
          });
        }
      }

      if (coordinatesMeasured) {
        // Include only the (actually) closest vehicles
        this.vehiclesList = coordinatesMeasured.map((item, index) => ({
          ...maybeClosestVehicles[item.originalIndex],
          letterId: LETTER_IDS[index],
          distance: item.distance,
          duration: item.duration,
        }));

        this.bounds = bbox;

        // Highlight closest vehicle
        const [firstVehicle] = this.vehiclesList;
        this.highlightedVehicle = firstVehicle.key;
      }

      return Boolean(coordinatesMeasured);
    },
    /**
     * Retrieves isochrone segments for the selected location.
     *
     * @returns {Promise<Boolean>}
     */
    async getDistanceSegments() {
      this.iso = []; // empty current segment
      this.isoError = null; // clear errors

      const params = {
        location: this.coordinates,
        contours: CONTOURS,
      };

      let iso;
      try {
        iso = await this.getIsochrone(params);
        if (iso.code) {
          const error = new Error(iso.message);
          error.code = iso.code;
          throw error;
        }
      } catch (error) {
        this.isoError = error.message;
        errorService.log(error, {
          metaData: {
            'getIsochrone Call': params,
          },
        });
      }

      if (iso?.features) {
        this.iso = iso.features;
      }

      return Boolean(iso && !iso.error);
    },
    handleRoute() {
      if (this.filtersInitialized === true && this.$route.name === 'nearby') {
        this.isUpdatingLocation = true;
        this.$nextTick(async () => {
          this.visible = true;
          this.coordinates = this.$route.query.location.split(',').map((value) => parseFloat(value));
          await this.findNearbyVehicles();
          this.isUpdatingLocation = false;
        });
      } else {
        this.visible = false;
      }
    },
    onShow() {
      trackEvent('nearby_vehicles_opened');
    },
  },
  watch: {
    $route() {
      this.handleRoute();
    },
    filtersInitialized() {
      this.handleRoute();
    },
    nearbyVehiclesFiltered() {
      this.findNearbyVehicles(true);
    },
    visible() {
      if (this.visible === false) {
        clearInterval(this.lookupTimeInterval);
      }
    },
  },
  created() {
    this.handleRoute();
  },
  unmounted() {
    clearInterval(this.lookupTimeInterval);
  },
};
</script>
