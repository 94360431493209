import SimpleModel from './SimpleModel';

/**
 * @typedef SchedulePeriod
 * @prop {string} start
 * @prop {string} end
 * @prop {string} rrule
 */

class Schedule extends SimpleModel {
  /** @type {String} */
  name = null;

  /** @type {String} */
  category = null;

  /** @type {String} */
  key = null;

  /** @type {SchedulePeriod[]} */
  periods = [];

  constructor(data) {
    super();
    this.insert(data);
  }
}

export default Schedule;
