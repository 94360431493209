import md5 from 'blueimp-md5';
import safeLocalStorage from 'safe-localstorage';
import errorService from 'src/services/error';

export const STORAGE_PREFIX = 'zubie';

export const ACCOUNT_SCOPED_KEYS = [
  'assets/recentConnections',
  'dunning/snooze',
  'subscriptions/estimatedSubCount',
  'trips-list/showNotes',
  'vehicles-map/idealBounds',
  'map-filters/expandedGroups',
  'session/partnerName',
];

export const USER_SCOPED_KEYS = [
  'check-in/duration',
  'check-in/vehicles',
  'check-in/users',
  'filtering/filterOutOfBounds',
  'filtering/filterShared',
  'filtering/filters',
  'map/assetRecentMapStyle',
  'map/clusterAssets',
  'map/mapStyle',
  'map/nearbyMapStyle',
  'map/showPlaces',
  'map/showTagColors',
  'map/showTraffic',
  'map/tripsMapStyle',
  'map/showDriverNameInList',
  'map/showNicknameInList',
  'map/showAddressInList',
  'places/placeVisibility',
  'search/history',
  'session/checkInType',
  'trips-map/showPlaces',
  'trips-map/showTraffic',
  'vehicle-chooser/recentVehicles',
];

// For concise efficiency-ness
const SCOPED_KEYS = [...ACCOUNT_SCOPED_KEYS, ...USER_SCOPED_KEYS];

export const config = {
  accountPrefix: '',
  onReadyCallbacks: [],
  /** @type {RootStore | null} */
  store: null,
  userPrefix: '',
};

/**
 * Retrives the given key from local storage.
 *
 * @param {string} keyName
 * @returns {string}
 */
export function get(keyName) {
  if (!isReady() && SCOPED_KEYS.includes(keyName)) {
    errorService.log(new Error(`Trying to get ${keyName} from local storage before storage service is ready`));
  }
  const storageKey = getStorageKeyName(keyName);
  return safeLocalStorage.get(storageKey);
}

/**
 * Retrieves the actual key name used for localStorage.
 *
 * @param {string} keyName
 */
export function getStorageKeyName(keyName) {
  let prefix = `${STORAGE_PREFIX}/`;

  if (ACCOUNT_SCOPED_KEYS.includes(keyName)) {
    prefix += `${config.accountPrefix}/`;
  }

  if (USER_SCOPED_KEYS.includes(keyName)) {
    prefix += `${config.userPrefix}/`;
  }

  return `${prefix}${keyName}`;
}

/**
 * Executes all onReady callbacks if service is ready.
 */
export function handleOnReadyCallbacks() {
  if (isReady()) {
    config.onReadyCallbacks.forEach((callback) => {
      callback(config.store);
    });
    config.onReadyCallbacks = [];
  }
}

/**
 * Returns true if storage services is ready to be used.
 */
export function isReady() {
  return Boolean(config.accountPrefix && config.userPrefix);
}

/**
 * Registers an onReady callback or executes the callback immediately if already ready.
 *
 * @param {(store: RootStore) => void} callback
 */
export function onReady(callback) {
  if (isReady()) {
    callback(config.store);
    return;
  }
  config.onReadyCallbacks.push(callback);
}

/**
 * Removes the given key from local storage.
 *
 * @param {string} keyName
 */
export function remove(keyName) {
  const key = getStorageKeyName(keyName);
  safeLocalStorage.remove(key);
}

/**
 * Sets the given key + value in local storage.
 *
 * @param {string} keyName
 * @param {string} value
 */
export function set(keyName, value) {
  const storageKey = getStorageKeyName(keyName);
  safeLocalStorage.set(storageKey, value);
}

/**
 * Sets the account prefix to use for scoped local storage entries.
 *
 * @param {string} prefix
 */
export function setAccountPrefix(prefix) {
  const hashedPrefix = md5(prefix).toString();
  config.accountPrefix = hashedPrefix;
  handleOnReadyCallbacks();
}

/**
 * Sets the Vuex Store to be used for onReady callbacks.
 *
 * @param {RootStore} store
 */
export function setStore(store) {
  config.store = store;
}

/**
 * Sets the user prefix to use for scoped local storage entries.
 *
 * @param {string} prefix
 */
export function setUserPrefix(prefix) {
  const hashedPrefix = md5(prefix).toString();
  config.userPrefix = hashedPrefix;
  handleOnReadyCallbacks();
}

export default {
  get,
  handleOnReadyCallbacks,
  isReady,
  onReady,
  remove,
  set,
  setAccountPrefix,
  setStore,
  setUserPrefix,
};
