import _cloneDeep from 'lodash/cloneDeep';
import storage from 'src/services/storage';

export function filteringOccurred(state, occurred = true) {
  state.filteringOccurred = occurred;
}

export function filtersInitialized(state) {
  state.filtersInitialized = true;
}

export function setFilters(state, filters) {
  state.filters = filters;
  storage.set('filtering/filters', JSON.stringify(filters));
}

export function setOutOfBoundsFilter(state, filterOutOfBounds) {
  state.filterOutOfBounds = filterOutOfBounds;
}

export function setSharedFilter(state, filterShared) {
  state.filterShared = filterShared;
}

export function setSearchTerms(state, term) {
  state.searchTerms = term;
}

export function setTrackedFilters(state, filters) {
  state.filtersTracked = _cloneDeep(filters);
}
