export function addVideoIconToTripSvg(svg, VideoSvg) {
  const regex = /([\s\n]*<\/g>[\s\n]*<\/svg>)$/;
  return svg.replace(regex, `${VideoSvg}$1`);
}

/**
 * Converts a given SVG (string) to a Base64 string.
 *
 * @param {String} svg
 */
export function svgToBase64(svg) {
  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

/**
 * Converts a given SVG to an Image element.
 *
 * @param {String} svg
 * @param {Number} width
 * @param {Number} height
 * @returns {Promise<HTMLImageElement>}
 */
export function svgToImage(svg, width, height) {
  return new Promise((resolve) => {
    const image = new Image(width, height);
    image.onload = () => {
      resolve(image);
    };
    image.src = svgToBase64(svg);
  });
}
