<template>
  <q-card>
    <q-card-section class="q-pb-none">
      <ZubieSelect
        v-model="selectedCountry"
        class="q-mb-sm"
        dense
        emit-value
        filled
        hide-bottom-space
        label="Country"
        option-label="code"
        option-value="code"
        :options="countries"
        @update:model-value="onCountrySelected"
      />
      <ZubieSelect
        v-if="hasSubdivisions"
        v-model="selectedSubdivision"
        class="q-mb-sm"
        dense
        emit-value
        filled
        hide-bottom-space
        label="Subdivision"
        map-options
        option-label="name"
        option-value="code"
        :options="subdivisionsOrJurisdictions"
      />
      <ZubieSelect
        v-model="selectedJurisdiction"
        dense
        :disable="hasSubdivisions && !selectedSubdivision"
        emit-value
        filled
        hide-bottom-space
        label="Province / State"
        map-options
        option-label="name"
        option-value="code"
        :options="jurisdictionOptions"
      />
    </q-card-section>
    <q-card-actions align="right" class="q-pt-sm q-px-md q-pb-md">
      <PrimaryButton icon="north_west" label="Insert" @click="onChange" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { iso31662 } from 'iso-3166';
import ZubieSelect from 'components/ZubieSelect.vue';

export default {
  name: 'PlateJurisdictionHelperField',
  components: {
    ZubieSelect,
  },
  props: {
    value: {
      required: true,
      type: String,
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      selectedCountry: 'US',
      selectedJurisdiction: null,
      selectedSubdivision: null,
    };
  },
  computed: {
    countries() {
      return Object.values(this.hierarchy);
    },
    hasSubdivisions() {
      return Object.values(this.subdivisionsOrJurisdictions).some((entry) => entry.children);
    },
    hierarchy() {
      // Get all entries
      const entries = {};
      iso31662.forEach((entry) => {
        const { code } = entry;
        entries[code] = entry;
      });

      // Inject non-top-level entries
      const subdivisions = {};
      Object.entries(entries).forEach(([code, entry]) => {
        const { parent } = entry;
        if (entries[parent]) {
          if (!subdivisions[parent]) {
            subdivisions[parent] = {
              ...entries[parent],
              children: {},
            };
          }
          subdivisions[parent].children[code] = entry;
        }
      });

      // Determine top-level parents
      const hierarchy = {};
      Object.entries(entries).forEach(([code, entry]) => {
        const { parent } = entry;
        if (!entries[parent]) {
          if (!hierarchy[parent]) {
            hierarchy[parent] = {
              code: parent,
              children: {},
            };
          }
          hierarchy[parent].children[code] = subdivisions[code] || entry;
        }
      });

      return hierarchy;
    },
    jurisdictionOptions() {
      let options;

      if (this.hasSubdivisions && !this.selectedSubdivision) {
        return [];
      }

      if (this.hasSubdivisions) {
        // Determine if we're pulling from a subdivision with children
        const subdivision = this.hierarchy[this.selectedCountry].children[this.selectedSubdivision];
        const subChildren = subdivision.children;
        if (!subChildren) {
          return [subdivision];
        }
        options = Object.values(subChildren);
      } else {
        options = this.subdivisionsOrJurisdictions;
      }

      options.sort((option1, option2) => {
        if (option1.name < option2.name) {
          return -1;
        }
        if (option2.name < option1.name) {
          return 1;
        }
        return 0;
      });

      return options;
    },
    subdivisionsOrJurisdictions() {
      return Object.values(this.hierarchy[this.selectedCountry].children);
    },
  },
  methods: {
    onCountrySelected() {
      this.selectedJurisdiction = null;
    },
    onChange(event) {
      this.$emit('update:model-value', event, this.selectedJurisdiction);
    },
  },
  created() {
    const entry = iso31662.find(({ code }) => code === this.value);

    if (entry) {
      if (!this.hierarchy[entry.parent]) {
        // Non-top-level parent indicates a subdivision
        const subdivision = iso31662.find(({ code }) => code === entry.parent);
        if (subdivision) {
          this.selectedSubdivision = subdivision.code;
          this.selectedCountry = subdivision.parent;
        }
      } else {
        this.selectedCountry = entry.parent;
      }

      // Only set jurisdiction if a country was found
      if (this.selectedCountry) {
        this.selectedJurisdiction = entry.code;
      }
    }
  },
};
</script>
