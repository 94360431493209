import centroid from '@turf/centroid';
import Bounds from 'src/models/Bounds';

/**
 * Retrieves the selected search result.
 *
 * @param {MapStoreState} state
 */
export function selectedSearchResult(state) {
  return state.searchResults[state.selectedSearchResultIndex];
}

/**
 * Returns the center coordinates of the ideal bounds.
 *
 * @param {MapStoreState} state
 */
export function idealBoundsCenter(state) {
  if (state.idealBounds instanceof Bounds === false) {
    return [0, 0];
  }
  const centerGeoJson = centroid(state.idealBounds.toPolygon());
  return centerGeoJson.geometry.coordinates;
}
