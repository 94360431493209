<template>
  <q-btn v-if="canUseCamera" :color="color" :outline="outline" :text-color="textColor" @click="onClick">
    <div class="row items-center no-wrap">
      <div><q-icon :class="{ 'q-mr-xs': label }" :name="icon" /></div>
      <div v-if="label">{{ label }}</div>
    </div>
    <q-popup-proxy v-if="$q.platform.is.capacitor" breakpoint="0" :model-value="showScanOptions" no-parent-event>
      <q-btn-group>
        <PrimaryInlineButton padding="sm sm xs" @click="onCodeScanPress">
          <div class="column items-center">
            <q-icon class="q-my-xs" name="qr_code_scanner" />
            <div>QR/Bar Code</div>
          </div>
        </PrimaryInlineButton>
        <q-separator vertical />
        <PrimaryInlineButton padding="sm sm xs" @click="onTextScanPress">
          <div class="column items-center">
            <q-icon class="q-my-xs" name="document_scanner" />
            <div>Text Scan</div>
          </div>
        </PrimaryInlineButton>
      </q-btn-group>
    </q-popup-proxy>
    <ScanDialog
      v-if="showScanDialog"
      :default-scan-mode="defaultScanMode"
      :validation="validation"
      @hide="showScanDialog = false"
      @ok="onTextScanned"
    />
    <VerifyVINDialog v-if="showVerifyVinDialog" :vin="vin" @hide="showVerifyVinDialog = false" @ok="onOk" />
    <ScannedTextDialog
      v-if="showScannedTextDialog"
      :image-data-url="scannedImageDataUrl"
      :processing="processingScannedText"
      :text-blocks="scannedTextBlocks"
      @hide="showScannedTextDialog = false"
      @ok="onOk"
      @retry="onTextScanPress"
    />
  </q-btn>
</template>

<script setup lang="ts">
import { useQuasar } from 'quasar';
import { computed, ref, type Ref } from 'vue';
import { useStore } from 'vuex';
import PrimaryInlineButton from 'components/buttons/PrimaryInlineButton.vue';
import VerifyVINDialog from 'components/scanner/VerifyVINDialog.vue';
import { codeScan, textScan } from 'src/services/scanner';
import { sanitizeVin } from 'src/services/vin';
import ScannedTextDialog from './ScannedTextDialog.vue';

const props = withDefaults(
  defineProps<{
    color?: string;
    defaultScanMode?: string;
    icon?: string;
    label?: string;
    outline?: boolean;
    textColor?: string;
    validation?: string;
  }>(),
  {
    color: 'white',
    icon: 'photo_camera',
    textColor: 'black',
  }
);

const emit = defineEmits(['scan']);

const showScanDialog = ref(false);
const showScanOptions = ref(false);
const showVerifyVinDialog = ref(false);

const store = useStore();

const canUseCamera = computed(() => store.state.env.canUseCamera);

const $q = useQuasar();

function onClick() {
  if ($q.platform.is.capacitor) {
    showScanOptions.value = true;
  } else {
    showScanDialog.value = true;
  }
}

const vin = ref('');
function onOk({ text, valid }: { text: string; valid: boolean }) {
  if (!valid && props.validation === 'vin') {
    vin.value = text;
    showVerifyVinDialog.value = true;
    return;
  }
  emit('scan', new Event('ScanEvent'), text);
}

async function onCodeScanPress() {
  showScanOptions.value = false;

  const text = await codeScan();

  if (props.validation === 'vin') {
    onOk(sanitizeVin(text));
    return;
  }

  onOk({ text, valid: true });
}

function onTextScanPress() {
  showScanOptions.value = false;
  showScanDialog.value = true;
}

const showScannedTextDialog = ref(false);
const processingScannedText = ref(false);
const scannedTextBlocks: Ref<string[]> = ref([]);
const scannedImageDataUrl = ref('');
async function onTextScanned(imageDataUrl: string, foundTextBlocks?: string[]) {
  scannedImageDataUrl.value = imageDataUrl;
  showScanDialog.value = false;

  let status;
  let textBlocks = foundTextBlocks;
  if (!textBlocks) {
    processingScannedText.value = true;
    const results = await textScan(imageDataUrl);
    textBlocks = results.textBlocks;
    status = results.status;
    processingScannedText.value = false;
  }

  // User canceled the photo
  if (status === 'canceled') {
    return;
  }

  if (textBlocks.length === 1) {
    // Return the valid text block found
    onOk({ text: textBlocks[0], valid: true });
    return;
  }

  showScannedTextDialog.value = true;

  if (props.validation === 'vin') {
    // Validate each text block to see if it's a VIN
    const likelyVins = [] as string[];
    const otherTextBlocks = [] as string[];
    textBlocks.forEach((textBlock) => {
      const { text, valid } = sanitizeVin(textBlock);
      if (valid) {
        likelyVins.push(text);
      } else {
        otherTextBlocks.push(text);
      }
    });
    // Put probably VINs at the top
    scannedTextBlocks.value = [...likelyVins, ...textBlocks];
    return;
  }

  // Have user select from the text blocks found
  scannedTextBlocks.value = [...textBlocks];
}
</script>
