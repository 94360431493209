<template>
  <q-btn class="fliters-control" color="white" padding="0" @click="filtersDialogOpen = true">
    <FiltersBadge />
    <FilterSvg class="q-ma-xs" height="29" width="29" />
    <MapFiltersDialog :visible="filtersDialogOpen" @hide="filtersDialogOpen = false" />
  </q-btn>
</template>

<script>
import FiltersBadge from 'components/map/FiltersBadge.vue';
import MapFiltersDialog from 'components/map/MapFiltersDialog.vue';
import FilterSvg from 'src/assets/noun_filter_1411705.svg';

export default {
  name: '',
  components: {
    FilterSvg,
    MapFiltersDialog,
    FiltersBadge,
  },
  data() {
    return {
      filtersDialogOpen: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.fliters-control {
  height: 29px;
  margin-left: 11px;
  margin-right: 5px;
  width: 29px;
}
</style>
