import svg from 'src/assets/pin-obsolete.svg?raw';
import { COLOR_VALUES } from 'src/services/constants';
import SvgMarker from './SvgMarker';

export default class ObsoleteSvgMarker extends SvgMarker {
  constructor() {
    super(svg, {
      height: 91,
      width: 60,
      innerColor: COLOR_VALUES.WHITE,
      outerColor: COLOR_VALUES.NEGATIVE,
    });
  }
}
