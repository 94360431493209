import { colors as quasarColors } from 'quasar';
import GeoJsonLayer from 'src/services/geoJson/GeoJsonLayer';
import PlacePointsSource from 'src/services/geoJson/sources/PlacePointsSource';


const { getPaletteColor } = quasarColors;

export default class PlacePointsLayer extends GeoJsonLayer {
  /** @var {String} id */
  static id = 'place-points';

  /**
   * @returns {Object} A GeoJson layer config object.
   */
  getConfig() {
    return Object.freeze({
      id: this.getId(),
      type: 'symbol',
      metadata: { 'mapbox:group': '3c074f28255b87214ccb0ab55fee9992' },
      source: PlacePointsSource.id,
      layout: {
        'text-optional': true,
        'text-size': ['interpolate', ['linear'], ['zoom'], 4.9, 0, 5, 10, 16, 13],
        'text-radial-offset': ['interpolate', ['linear'], ['zoom'], 5, 0.5, 15, 1.2],
        'text-variable-anchor': ['bottom', 'top'],
        'icon-image': ['case', ['==', ['get', 'isVisible'], true], 'location_on', 'location_off'],
        'symbol-avoid-edges': true,
        'text-font': ['Open Sans SemiBold', 'Arial Unicode MS Regular'],
        'icon-size': ['interpolate', ['linear'], ['zoom'], 4.9, 0, 5, 0.1, 12, 0.7, 15, 1],
        'text-anchor': 'bottom',
        'text-field': '{name}',
        'icon-padding': 0,
        'icon-ignore-placement': true,
      },
      paint: {
        'text-halo-color': getPaletteColor('white'),
        'text-halo-width': 1,
        'text-color': getPaletteColor('dark'),
      },
    });
  }
}
