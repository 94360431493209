import Subscription from 'src/models/Subscription';
import storage from 'src/services/storage';

/**
 * @param {SubscriptionsStoreState} state
 * @param {number} estimate
 */
export function setEstimatedSubCount(state, estimate) {
  state.estimatedSubCount = estimate || 1;
  storage.set('subscriptions/estimatedSubCount', state.estimatedSubCount);
}

/**
 * @param {SubscriptionsStoreState} state
 * @param {boolean} isLoading
 */
export function setLoading(state, isLoading) {
  state.subscriptionsLoading = isLoading;
}

/**
 * @param {SubscriptionsStoreState} state
 * @param {SubscriptionData[]} subscriptions
 */
export function setSubscriptions(state, subscriptions) {
  state.subscriptions = subscriptions.map((subscription) => new Subscription(subscription));
  storage.set('subscriptions/estimatedSubCount', subscriptions.length);
}

/**
 * @param {SubscriptionsStoreState} state
 * @param {string} error
 */
export function setSubUpdateError(state, error) {
  state.subUpdateError = error;
}

/**
 * @param {SubscriptionsStoreState} state
 * @param {boolean} isLoading
 */
export function setPlansLoading(state, isLoading) {
  state.plansLoading = isLoading;
}

/**
 * @param {SubscriptionsStoreState} state
 * @param {SubscriptionPlan[]} plans
 */
export function setPlans(state, plans) {
  state.plans = plans;
}

/**
 * @param {SubscriptionsStoreState} state
 */
export function subscriptionsLoaded(state) {
  state.subscriptionsLoaded = true;
}

/**
 * @param {SubscriptionsStoreState} state
 * @param {Object} payload
 * @param {string} payload.id
 * @param {SubscriptionData} payload.subscription
 */
export function updateSubscriptionById(state, { id, subscription }) {
  /** @type {SubscriptionData[]} */
  const subscriptions = [];

  state.subscriptions.forEach((existingSubscription) => {
    if (existingSubscription.id === id) {
      subscriptions.push(new Subscription({ ...subscription }));
    } else {
      subscriptions.push(existingSubscription);
    }
  });

  state.subscriptions = subscriptions;
}
